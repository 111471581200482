import { useAuthStore } from "@/stores/auth.store";
import UserService from "@/services/user.service";
import storeCache from "@/js/storeCache";

class AuthHelper {
  /**
   * Create authorization bearer token to all ajax request.
   * @returns {{Authorization: string}}
   */
  authHeader() {
    let token = storeCache.get("token");
    return { Authorization: "Bearer " + token };
  }

  /**
   * Verification user session time. If session is less than 5 minutes auto refresh time.
   */
  verificationToken() {
    const authStore = useAuthStore();
    const currenDate = new Date(),
      userTokenExpirationDate = new Date(authStore.user.tokenExpirationTime),
      userService = new UserService();

    //Check session time in minues.
    //@ts-ignore
    const session = Math.floor((userTokenExpirationDate - currenDate) / (1000 * 60));
    console.log("SESSION", session);
    userService.checkToken();
    //refresh token on user.
    if (session < 5) {
      userService.refreshToken();
    }

    //Logout user when session runs out.
    if (session <= 0 || Number.isNaN(session)) {
      userService.logout();
    }
  }
}

export default new AuthHelper();
