export const isTrailer = (name: string) => {
  return ["BDF_SYSTEM_PRZYCZEPA", "PRZYCZEPA", "NISKOPODWOZIOWY_PRZYCZEPA", "DLUZYCA_PRZYCZEPA"].includes(name);
};
export const isSemitrailer = (name: string) => {
  return [
    "BDF_SYSTEM_NACZEPA",
    "PLATFORMA",
    "CYSTERNA_PALIWOWA",
    "CYSTERNA_GAZOWA",
    "CYSTERNA_CHEMICZNA",
    "PLANDEKA_BDE",
    "PLANDEKA_COILMULDE",
    "PLANDEKA_JOLODA",
    "PLANDEKA_JUMBO",
    "PLANDEKA_MEGA",
    "PLANDEKA_STANDARD",
    "CHLODNIA",
    "IZOTERMA",
    "DLUZYCA_NACZEPA",
    "NISKOPODWOZIOWY_NACZEPA",
    "SZTYWNA_ZABUDOWA",
    "SZTYWNA_ZABUDOWA_SKRZYNIA",
    "CHLODNIA_HAKOWKA",
    "CYSTERNA_INNA",
    "CYSTERNA_SILOS",
    "PODKONTENEROWA_20_CYSTERNA",
    "PODKONTENEROWA_40_CYSTERNA",
    "PODKONTENEROWA_20_STANDARD",
    "PODKONTENEROWA_40_STANDARD",
    "PODKONTENEROWA_45_STANDARD",
    "PODKONTENEROWA_SWAPBODY",
    "PLANDEKA_FIRANKA",
  ].includes(name);
};
export type CarType = "SAMOCHOD" | "NACZEPA" | "PRZYCZEPA" | "ALL";
export function isCarType(str: string): str is CarType {
  return (str as CarType) === str;
}
export function isCarTypeCar(str: string): boolean {
  return str === "SAMOCHOD";
}
