import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import AlertHelper from "@/helpsers/alert.helper";
import { Company } from "./company.store";
import { Department } from "./department.store";
import i18n from "@/i18n";
import { parseError } from "@/js/error";
import storeCache from "@/js/storeCache";
import { inject, ref } from "vue";

export interface Authorization {
  id: string;
  department: object;
  status: string;
  createdAt?: string;
  modifiedAt?: string;
  departments?: Array<Department>;
}

export interface AuthorizationRequest {
  alert?: unknown;
  departmentToBeAddedId: string;
  departmentId: string;
}

export interface ListFetchRequest {
  id: string;
  status?: string;
  onlyReturn?: boolean;
  sorting?: string;
  search?: string;
}

export interface AuthorizationHandler {
  id: string;
  type: string;
  alert?: unknown;
}

export const useAuthorizationStore = defineStore("authorization", () => {
  let alert = inject("alert");
  const authorizations = ref<Authorization[]>([]);
  const authorizationsCounter = ref<Authorization[]>([]);
  const allAuths = ref<Authorization[]>([]);
  const authorizedDepartments = ref<Department[]>([]);
  const page = ref<number>(1);
  const onPage = ref<number>(3);
  const total = ref<number>(0);

  async function authorizeDepartment(obj: AuthorizationRequest) {
    let { alert, ...object } = obj;
    try {
      await axios.post(ApiHelper.authorizeDepartment(), object);
      AlertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("authorizations.authorization_request_send"), "success");
    } catch (err) {
      AlertHelper.show(alert, i18n.global.t("generals.error"), parseError(err), "error");
    }
  }

  async function listAuthorizations(obj: ListFetchRequest, onPageC: string, pageC: string) {
    let { status, id } = obj;
    try {
      const res = await axios.get(
        ApiHelper.listAuthorizations(id, status) +
          `${status === "ALL" ? "?" : "&"}limit=${onPageC}&page=${pageC}` +
          `${obj.sorting && obj.sorting !== "" ? obj.sorting : status === "ALL" ? "&sort_by=status_ASC" : ""}` +
          `${obj.search && obj.search !== "" ? `&query=${obj.search}` : ""}`
      );
      authorizations.value = res.data.data;
      total.value = res.data.meta.total;
      onPage.value = res.data.meta.per_page;
      page.value = res.data.meta.current_page;
      return res;
    } catch (err) {
      AlertHelper.show(alert, parseError(err), "", "error");
    }
  }

  async function listAuthorizationsForCompany(obj: ListFetchRequest, onPageC: string, pageC: string) {
    let { status, id } = obj;
    try {
      const res = await axios.get(
        ApiHelper.listAuthorizationsForCompany(id, status) +
          `${status === "ALL" ? "?" : "&"}limit=${onPageC}&page=${pageC}` +
          `${obj.sorting && obj.sorting !== "" ? obj.sorting : status === "ALL" ? "&sort_by=status_ASC" : ""}` +
          `${obj.search && obj.search !== "" ? `&query=${obj.search}` : ""}`
      );
      authorizations.value = res.data.data;
      total.value = res.data.meta.total;
      onPage.value = res.data.meta.per_page;
      page.value = res.data.meta.current_page;
    } catch (err) {
      AlertHelper.show(alert, parseError(err), "", "error");
    }
  }

  async function listAuthorizationsforCounter(obj: ListFetchRequest, onPage: string, page: string) {
    let { status, id } = obj;
    try {
      const res = await axios.get(
        ApiHelper.listAuthorizations(id, status) +
          `${status === "ALL" ? "?" : "&"}limit=${onPage}&page=${page}` +
          `${obj.sorting && obj.sorting !== "" ? obj.sorting : status === "ALL" ? "&sort_by=status_ASC" : ""}`
      );
      authorizationsCounter.value = res.data.data;
    } catch (err) {
      AlertHelper.show(alert, parseError(err), "", "error");
    }
  }

  async function listAuthorizationsWithCompanies(id: string) {
    try {
      const res = await axios.get(ApiHelper.listAllAuthorizationsWithCompanies(id));
      authorizations.value = res.data.data.map((company: Company) => {
        if (company?.addresses && company?.addresses[0]) {
          company.companyCity = company.addresses ? company.addresses[0].companyCity : "";
          company.companyAddress = company.addresses ? company.addresses[0].companyAddress : "";
          company.companyZipCode = company.addresses ? company.addresses[0].companyZipCode : "";
          delete company.addresses;
        }
        return company;
      });
    } catch (err) {
      AlertHelper.show(alert, parseError(err), "", "error");
    }
  }

  async function listAuthorizedDepartments(obj: ListFetchRequest) {
    let { status, id } = obj;
    try {
      const res = await axios.get(ApiHelper.listAuthorizations(id, status));
      authorizations.value = res.data.data.map((a: Authorization) => {
        return a.department;
      });
      authorizedDepartments.value = res.data.data.map((a: Authorization) => {
        return a.department;
      });
    } catch (err) {
      AlertHelper.show(alert, parseError(err), "", "error");
    }
  }

  async function listAuthorizedDepartmentsForCompany(obj: ListFetchRequest) {
    let { status, id } = obj;
    try {
      const res = await axios.get(ApiHelper.listAuthorizationsForCompany(id, status));
      authorizations.value = res.data.data.map((a: Authorization) => {
        return a.department;
      });
      authorizedDepartments.value = res.data.data.map((a: Authorization) => {
        return a.department;
      });
    } catch (err) {
      AlertHelper.show(alert, parseError(err), "", "error");
    }
  }

  async function listAllAuthorizations(obj: ListFetchRequest) {
    let { status, id } = obj;
    try {
      const res = await axios.get(ApiHelper.listAllAuthorizations(id, status));
      if (!obj.onlyReturn) authorizations.value = res.data.data;
      return res.data.data;
    } catch (err) {
      AlertHelper.show(alert, parseError(err), "", "error");
    }
  }

  async function listAllAuthorizationsForCounter(obj: ListFetchRequest) {
    let { id } = obj;
    if (id && storeCache.get("token")) {
      try {
        const res = await axios.get(ApiHelper.listAllAuthorizationsCounter(id));
        console.log(res);
        if (res.data && res.data && res.data?.authorizationsNumber) {
          return res.data?.authorizationsNumber;
        }
      } catch (err) {
        AlertHelper.show(alert, parseError(err), "", "error");
      }
    }
  }

  async function handleAuthorization(obj: AuthorizationHandler) {
    let { type, id, alert } = obj;
    let url =
      type === "accept"
        ? ApiHelper.acceptAuthorization(id)
        : type === "reject"
        ? ApiHelper.rejectAuthorization(id)
        : ApiHelper.retreatAuthorization(id);

    try {
      await axios.put(url);
      if (alert) {
        AlertHelper.show(
          alert,
          i18n.global.t("generals.success"),
          type === "accept" ? i18n.global.t("authorizations.authorization_success") : i18n.global.t("authorizations.authorization_rejected"),
          "success"
        );
      }
    } catch (err) {
      AlertHelper.show(alert, i18n.global.t("generals.error"), parseError(err), "error");
    }
  }

  return {
    authorizations,
    authorizationsCounter,
    allAuths,
    authorizedDepartments,
    page,
    onPage,
    total,
    authorizeDepartment,
    listAuthorizations,
    listAuthorizationsForCompany,
    listAuthorizationsforCounter,
    listAuthorizationsWithCompanies,
    listAuthorizedDepartments,
    listAuthorizedDepartmentsForCompany,
    listAllAuthorizations,
    listAllAuthorizationsForCounter,
    handleAuthorization,
  };
});
