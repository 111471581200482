import MyProfile from "@/views/admin/profile/MyProfile.vue";
import MyProfileSettings from "@/views/admin/profile/MyProfileSettings.vue";
import UserView from "@/views/admin/profile/UserView.vue";
import AdminUserView from "@/views/admin/users/UserView.vue";
import HomePage from "@/views/admin/HomePage.vue";
import Logiplanner from "@/views/admin/users/Logiplanner.vue";
import Companies from "@/views/admin/companies/index.vue";
import CompanyDetails from "@/views/admin/companies/CompanyDetails.vue";
import TimeWindow from "@/views/admin/timeWindow/TimeWindow.vue";
import Workers from "@/views/admin/workers/Workers.vue";
import Invoices from "@/views/admin/invoices/Invoices.vue";
import Tenders from "@/views/admin/tenders/Tenders.vue";
import Documents from "@/views/admin/documents/Documents.vue";
import Bank from "@/views/admin/bank/Bank.vue";
import Price from "@/views/admin/price/Price.vue";
import DepartmentDetailsAdmin from "@/views/admin/departments/DepartmentDetailsAdmin.vue";
import SendMultipleMessageView from "@/views/admin/chat/SendMultipleMessageView.vue";
import AdminChatsView from "@/views/admin/chat/AdminChatsView.vue";
import AdminChatView from "@/views/admin/chat/AdminChatView.vue";
import UserChatsView from "@/views/admin/chat/UserChatsView.vue";
import Subscriptions from "@/views/admin/price/Subscriptions.vue";
import InvoiceSubs from "@/views/admin/invoices/InvoiceSubs.vue";
import InvoicesAll from "@/views/admin/invoices/InvoicesAll.vue";
import ActionRaports from "@/views/admin/raports/ActionRaports.vue";
import AddWorkerToCompanyAdmin from "@/views/admin/addings/AddWorkerToCompanyAdmin.vue";
import AddWorkerToDepartmentAdmin from "@/views/admin/addings/AddWorkerToDepartmentAdmin.vue";
import AddVehicleToDepartment from "@/views/admin/addings/AddVehicleToDepartment.vue";
import AddDocumentToCompanyAdmin from "@/views/admin/addings/AddDocumentToCompanyAdmin.vue";
import AddDepartmentToCompanyAdmin from "@/views/admin/addings/AddDepartmentToCompanyAdmin.vue";
import Factoring from "@/views/admin/bank/Factoring.vue";
import AdminAuctionsView from "@/views/admin/auctions/AdminAuctionsView.vue";
import AdminNotificationView from "@/views/admin/AdminNotificationView.vue";

import AdminLayout from "@/layouts/AdminLayout.vue";
import { Role } from "@/helpsers/role.helper";
import { createService } from "@/js/utils";
import i18n from "@/i18n";
import AdminAdvertsView from "@/views/admin/adverts/AdminAdvertsView.vue";
import AuctionPayment from "@/views/admin/price/AuctionPayment.vue";
import VerificationPaymentsList from "@/views/admin/price/VerificationPaymentsList.vue";
const routes = [
  {
    path: "/admin",
    component: Companies,
    name: "admin.homepage",
    beforeEnter: () => createService(),
    meta: {
      title: "Firmy",
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/powiadomienia",
    name: "admin.notifications",
    component: AdminNotificationView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.notifications"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/my-profile",
    component: UserView,
    beforeEnter: () => createService(),
    children: [
      {
        path: "",
        name: "admin.profile",
        component: MyProfile,
        meta: {
          title: i18n.global.t("routes.admin.profile"),
          authorize: [Role.Admin],
          layout: AdminLayout,
        },
      },
      {
        path: "settings",
        name: "admin.settings",
        component: MyProfileSettings,
        meta: {
          title: i18n.global.t("routes.admin.profile_settings"),
          authorize: [Role.Admin],
          layout: AdminLayout,
        },
      },
    ],
  },

  {
    path: "/admin/users",
    component: AdminUserView,
    beforeEnter: () => createService(),
    children: [
      {
        path: "logiplanner",
        name: "admin.users.logiplanner",
        component: Logiplanner,
        meta: {
          title: i18n.global.t("routes.admin.users"),
          authorize: [Role.Admin],
          parent: "admin.users",
          layout: AdminLayout,
        },
      },
    ],
  },

  {
    path: "/admin/companies",
    component: AdminUserView,
    beforeEnter: () => createService(),
    children: [
      {
        path: "",
        name: "admin.companies",
        component: Companies,
        meta: {
          title: i18n.global.t("routes.admin.companies"),
          authorize: [Role.Admin],
          parent: "admin.companies",
          layout: AdminLayout,
        },
      },
    ],
  },
  {
    path: "/admin/timeWindow",
    component: AdminUserView,
    beforeEnter: () => createService(),
    children: [
      {
        path: "",
        name: "admin.timeWindow",
        component: TimeWindow,
        meta: {
          title: i18n.global.t("routes.admin.time_window"),
          authorize: [Role.Admin],
          layout: AdminLayout,
        },
      },
    ],
  },
  {
    path: "/admin/workers",
    component: AdminUserView,
    beforeEnter: () => createService(),
    children: [
      {
        path: "",
        name: "admin.workers",
        component: Workers,
        meta: {
          title: i18n.global.t("routes.admin.workers"),
          authorize: [Role.Admin],
          layout: AdminLayout,
        },
      },
    ],
  },
  {
    path: "/admin/bank",
    component: AdminUserView,
    beforeEnter: () => createService(),
    children: [
      {
        path: "",
        name: "admin.bank",
        component: Bank,
        meta: {
          title: "Bank",
          authorize: [Role.Admin],
          layout: AdminLayout,
        },
      },
    ],
  },

  {
    path: "/admin/documents",
    component: Documents,
    beforeEnter: () => createService(),
    children: [
      {
        path: "",
        name: "admin.documents",
        component: Documents,
        meta: {
          title: i18n.global.t("routes.admin.documents"),
          authorize: [Role.Admin],
          layout: AdminLayout,
        },
      },
    ],
  },
  {
    path: "/admin/invoices",
    component: AdminUserView,
    beforeEnter: () => createService(),
    children: [
      {
        path: "",
        name: "admin.invoices",
        component: Invoices,
        meta: {
          title: i18n.global.t("routes.admin.invoices"),
          authorize: [Role.Admin],
          layout: AdminLayout,
        },
      },
      {
        path: "",
        name: "admin.invoices.subs",
        component: InvoiceSubs,
        meta: {
          title: i18n.global.t("routes.admin.invoices"),
          authorize: [Role.Admin],
          layout: AdminLayout,
        },
      },
      {
        path: "",
        name: "admin.invoices.all",
        component: InvoicesAll,
        meta: {
          title: i18n.global.t("routes.admin.invoices"),
          authorize: [Role.Admin],
          layout: AdminLayout,
        },
      },
    ],
  },
  {
    path: "/admin/tenders",
    component: AdminUserView,
    beforeEnter: () => createService(),
    children: [
      {
        path: "",
        name: "admin.tenders",
        component: Tenders,
        meta: {
          title: i18n.global.t("routes.admin.tenders"),
          authorize: [Role.Admin],
          layout: AdminLayout,
        },
      },
    ],
  },
  {
    path: "/admin/price",
    component: AdminUserView,
    beforeEnter: () => createService(),
    children: [
      {
        path: "/admin/price/time-windows",
        name: "admin.price",
        component: Price,
        meta: {
          title: i18n.global.t("routes.admin.price"),
          authorize: [Role.Admin],
          layout: AdminLayout,
        },
      },
      {
        path: "/admin/price/sub",
        name: "admin.subscriptions",
        component: Subscriptions,
        meta: {
          title: i18n.global.t("routes.admin.subscription"),
          authorize: [Role.Admin],
          layout: AdminLayout,
        },
      },
      {
        path: "/admin/price/auction",
        name: "admin.auctionPayment",
        component: AuctionPayment,
        meta: {
          title: i18n.global.t("routes.admin.auctionPayment"),
          authorize: [Role.Admin],
          layout: AdminLayout,
        },
      },
    ],
  },
  {
    path: "/admin/company-details",
    name: "admin.company",
    component: CompanyDetails,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.company"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/oddzial",
    name: "department-admin",
    component: DepartmentDetailsAdmin,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.department"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/masowa-wiadomosc",
    name: "admin.chat",
    component: SendMultipleMessageView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.group_chat"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/wiadomosci",
    name: "admin.chatlist",
    component: AdminChatsView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.group_chats"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/chat",
    name: "admin.singlechat",
    component: AdminChatView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.chat"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/chaty-uzytkownikow",
    name: "admin.userschats",
    component: UserChatsView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.group_chats"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/raporty",
    name: "admin.raports",
    component: ActionRaports,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.raports"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/dodaj-uzytkownika",
    name: "admin.add.user",
    component: AddWorkerToCompanyAdmin,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.add_worker"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/dodaj-uzytkownika-do-oddzialu",
    name: "admin.add.user.department",
    component: AddWorkerToDepartmentAdmin,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.add_wroker_to_department"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/dodaj-pojazd",
    name: "admin.add.vehicle",
    component: AddVehicleToDepartment,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.add_vehicle"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/dodaj-dokument",
    name: "admin.add.documents",
    component: AddDocumentToCompanyAdmin,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.add_documents"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/dodaj-oddzial",
    name: "admin.add.department",
    component: AddDepartmentToCompanyAdmin,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.add_department"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/faktoring",
    name: "admin.factoring",
    component: Factoring,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.factoring"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/aukcje",
    name: "admin.auctions",
    component: AdminAuctionsView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.auctions"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/reklamy",
    name: "admin.adverts",
    component: AdminAdvertsView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.adverts"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/dokumenty",
    name: "admin.documents",
    component: Documents,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.documents"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
  {
    path: "/admin/przelewy-weryfikacyjne",
    name: "admin.verifications",
    component: VerificationPaymentsList,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.admin.verificationPayments"),
      authorize: [Role.Admin],
      layout: AdminLayout,
    },
  },
];

export default routes;
