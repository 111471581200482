import router from "@/router";
import { useStockStore } from "@/stores/stock.store";
import { typeOfCompany } from "./utils";

export const redirectFromWidgetRow = async (
  row: {
    id: string;
    email?: string;
    carName?: string;
    fullName?: string;
    name?: string;
    originalname?: string;
    carrier: { id: string };
    warehouse: { id: string };
    monitoryNoteNumberInWarehouse?: number;
    departments?: { company?: { id: string; companyName: string } }[];
    groupDepartments?: { companyName: string }[];
  },
  type: string
) => {
  const stockStore = useStockStore();
  switch (type) {
    case "AUCTION":
    case "AUCTION_FACTORY":
    case "AUCTION_FACTORY_CANCELLED":
    case "CANCELLED_AUCTIONS":
    case "LOST_AUCTIONS":
    case "INCLUDED_AUCTIONS":
    case "SURCHARGES":
    case "FACTORING":
    case "AUCTION_ARCHIVE":
      if (!row.id) return;

      const auction = await stockStore.getSingleAuction(row.id);

      switch (type) {
        case "CANCELLED_AUCTIONS":
          router.push(`/anulowane-transporty?id=${auction?.subId}`);
          break;
        case "LOST_AUCTIONS":
          router.push(`/przegrane-aukcje?id=${auction?.subId}`);
          break;
        case "INCLUDED_AUCTIONS":
          router.push(`/uczestniczace-aukcje?id=${auction?.subId}`);
          break;
        case "AUCTION_ARCHIVE":
          router.push(`/archiwum-aukcji?id=${auction?.subId}`);
          break;
        case "AUCTION_FACTORY":
          router.push(`/moje-aukcje?id=${auction?.subId}`);
          break;
        case "AUCTION_FACTORY_CANCELLED":
          router.push(`/anulowane-aukcje?id=${auction?.subId}`);
          break;
        case "SURCHARGES":
          router.push(`/doplaty?id=${auction.subId}`);
          break;
        case "FACTORING":
          router.push(`/faktoring?id=${auction.subId}`);
          break;
        default:
          if (auction.type === "PREDETERMINED") {
            router.push(`/wygrane-aukcje?id=${auction?.subId}`);
          } else {
            router.push(`/moje-oferty?id=${auction?.subId}`);
          }
      }
      break;

    case "MONITORY_ARCHIVE":
      if (!row.monitoryNoteNumberInWarehouse) return;
      router.push(
        `/archiwum-awizacji?id=${row?.monitoryNoteNumberInWarehouse}&departmentId=${typeOfCompany() === "DRIVER" ? row.carrier.id : row.warehouse.id}`
      );
      break;

    case "GROUPS":
      if (row?.groupDepartments && row?.groupDepartments?.length > 0) {
        router.push(`/moje-grupy?id=${row?.groupDepartments[0]?.companyName}`);
      }
      break;

    case "DOCUMENT":
      router.push(`/dokumenty?query=${row.originalname}`);
      break;

    case "AUCTION_PARTICIPANTS":
      router.push(`/oddzial?id=${row.id}`);
      break;

    case "WORKERS":
      router.push(`/pracownicy?query=${row.email}`);
      break;

    case "CAR_STATUS":
      router.push(`/statusy-aut?query=${row.carName}`);
      break;

    case "DEPARTMENTS":
      router.push(`/list-warehouses?query=${row.name}`);
      break;

    case "DRIVERS":
      router.push(`/kierowcy?query=${row.fullName}`);
      break;

    case "OUTSIDE_DEPARTMENTS":
      console.log(row);
      router.push(`/magazyny-zewnetrzne?query=${row.name}`);
      break;

    default:
      break;
  }
};
