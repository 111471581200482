import { MonitaryNote } from "./monitary.store";
import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import AlertHelper from "@/helpsers/alert.helper";
import i18n from "@/i18n";
import { RegularErrorResponse, RegularGetResponse } from "./utils/interfaces";
import { parseError } from "@/js/error";
import { inject, ref } from "vue";

export interface Day {
  availabilities?: { startHour: string; endHour: string }[];
  startHour: string;
  endHour: string;
  slotsAll?: string;
  slotsTaken?: string;
  slots?: string;
  interval?: string;
  dayId: string;
  date?: string;
  loadingLimit?: string;
  unloadingLimit?: string;
  noMonitoryNotePossibility: number;
  availabilitiesNumber?: number;
  unlimitedMonitoryNotes: number;
  monitory_notes?: Array<MonitaryNote>;
  time_windows?: Array<TimeWindow>;
}
export interface TimeWindow {
  startHour: string;
  endHour: string;
  slots: string;
  slotsTaken?: number;
  slotsAll?: number;
}
export interface UpdateTimeWindows {
  date: string;
  availabilities: Array<TimeWindow>;
  alert?: any;
  departmentId?: string;
  rampId?: string;
}

export interface MonthlyCalendarFillReq {
  alert: any;
  departmentId: string;
  month: number;
  year: number;
  limits: {
    day: number;
    loadingLimit: number;
    unloadingLimit: number;
  }[];
  ramps: {
    rampId: string;
    availabilities: {
      day: number;
      startHour: string;
      endHour: string;
      interval: number;
      unlimitedMonitoryNotes: boolean;
      noMonitoryNotePossibility: boolean;
    }[];
  }[];
}

export const useCalendarStore = defineStore("calendar", () => {
  let alert = inject("alert");
  let isLoading = ref(false);
  let calendarData = ref<Day[]>([]);
  let timeWindows = ref<Day[]>([]);
  async function getAvailableTimewindowsFor6Months(departmentId: string) {
    try {
      const response = await axios.get(ApiHelper.getAvailableTimewindowsFor6Months(departmentId));
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function addMonthlyCalendar(obj: MonthlyCalendarFillReq) {
    let { alert, ...object } = obj;
    try {
      await axios.post(ApiHelper.addMonthlyCalendar(), object);
      AlertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("profile.calendar.add_succes"), "success");
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
      throw e;
    }
  }
  async function updateDailyTimeWindows(obj: UpdateTimeWindows) {
    let { alert, departmentId, ...object } = obj;
    try {
      await axios.put(ApiHelper.updateTimeWindows(departmentId), object);
      AlertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("profile.calendar.edit_succes"), "success");
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
      throw e;
    }
  }
  async function getCalendarMonthly(id: string, month: number, year: number, showPrevious?: boolean) {
    if (Number.isNaN(month)) month = 1;
    if (id === "NEW_DEPARTMENT") return;
    if (id !== undefined) {
      return await axios
        .get(ApiHelper.getCalendarMonthly(id, month, year))
        .then((res: { data: Day[] }) => {
          if (showPrevious) return res.data;
          else {
            calendarData.value = res.data;
          }
        })
        .catch((e: RegularErrorResponse) => {
          AlertHelper.show(alert, parseError(e), "", "error");
        });
    }
  }
  async function getTimeWindows(dayId: string, departmentId: string) {
    const res = await axios
      .get(ApiHelper.listTimeWindows(dayId, departmentId))
      .then((res: RegularGetResponse<Day>) => {
        timeWindows.value = res.data.data
          .map((window: Day) => {
            window.monitory_notes = [];
            return window;
          })
          .sort((window: Day, nextWindow: Day) => {
            var atime = Date.parse("9/19/2014 " + window.startHour);
            var btime = Date.parse("9/19/2014 " + nextWindow.startHour);
            if (atime < btime) {
              return -1;
            }
            if (atime > btime) {
              return 1;
            }
            return 0;
          });
      })
      .catch((err: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(err), "", "error");
      });
    return res;
  }
  async function checkIfDayFree(dayId: string, departmentId: string) {
    try {
      const response = await axios.get(ApiHelper.checkIfDayFree(dayId, departmentId));
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function requestDayFill(departmentId: string, body: any) {
    try {
      const response = await axios.post(ApiHelper.requestDayFill(), { departmentId, ...body });
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function getAvailableTimewindowsForADayForRamp(rampId: string, day: string) {
    try {
      const response = await axios.get(ApiHelper.getAvailableTimewindowsForADayForRamp(rampId, day));
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function getAvailableTimewindowsForADay(departmentId: string, day: string, carrierId: string) {
    try {
      const response = await axios.get(ApiHelper.getAvailableTimewindowsForADay(departmentId, day, carrierId));
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function requestMonthFill(departmentId: string, body: any) {
    try {
      const response = await axios.post(ApiHelper.requestMonthFill(), { departmentId, ...body });
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function copyCalendar(departmentId: string, body: { monthFrom: string; monthTo: string }) {
    try {
      const response = await axios.post(ApiHelper.copyCalendar(), { departmentId, ...body });
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  return {
    isLoading,
    calendarData,
    timeWindows,
    getAvailableTimewindowsFor6Months,
    addMonthlyCalendar,
    updateDailyTimeWindows,
    getCalendarMonthly,
    getTimeWindows,
    checkIfDayFree,
    getAvailableTimewindowsForADay,
    requestDayFill,
    requestMonthFill,
    copyCalendar,
    getAvailableTimewindowsForADayForRamp,
  };
});
