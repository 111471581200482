const infoIcons = {
  department: {
    title: "Moje oddziały",
    body: "Oddziały są podstawowym elementem LOGIplanner. Wszystkie najważniejsze czynności w aplikacji wykonujesz, przypisując oddział, którego dotyczyć będzie planowana akcja. ",
    button: "Dodaj oddział",
    missing: "Brak oddziałów",
  },
  "outside-department": {
    title: "Magazyny zewnętrzne",
    body: "Magazyny zewnętrzne mogą zostać użyte jako miejsce rozładunku w licytacjach. Nie możesz przypisywać do nich pracowników, ani uzupełniać kalendarzy.",
    button: "Dodaj magazyn zewn.",
    missing: "Brak magazynów zewnętrznych",
  },
  warehouse: {
    title: "Moje magazyny",
    body: "Magazyny są podstawowym elementem LOGIplanner. Wszystkie najważniejsze czynności w aplikacji wykonujesz, przypisując magazyn, którego dotyczyć będzie planowana akcja. ",
    button: "Dodaj magazyn",
    missing: "Brak magazynów",
  },
  workers: {
    title: "Pracownicy",
    body: "Zapraszaj pracowników do korzystania z aplikacji. Możesz dodać nowych użytkowników, wybierając jedną z trzech roli: Administrator, Kierownik lub Pracownik. Każdy z nich posiada inny zakres uprawnień. Po wysłaniu zaproszenia otrzymają oni mail z danymi do logowania (jeżeli nie posiadają jeszcze konta) lub zostaną dodani do Twojej firmy (jeżeli posiadają już konto LOGIplanner).",
    button: "Dodaj pracownika",
    for: "Pokaż pracowników dla",
    missing: "Brak pracowników",
  },
  drivers: {
    title: "Kierowcy",
    body: "Kierowcy są niezbędni podczas tworzenia awizacji. W przeciwieństwie do Pracowników, nie posiadają oni konta w aplikacji.",
    button: "Dodaj kierowcę",
    for: "Pokaż kierowców dla",
    missing: "Brak kierowców",
  },
  documents: {
    title: "Dokumenty",
    body: "Dodawaj dokumenty, aby inni użytkownicy mogli zapoznać się z Twoją firmą. Oprócz podstawowych dokumentów, możesz zamieścić dowolne pliki i określić, czy mają być widoczne publicznie czy tylko dla Twojej firmy.",
    button: "Dodaj dokument",
    missing: "Brak dokumentów",
  },
  cars: {
    title: "Moje auta",
    body: "Dodawaj pojazdy, aby uczestniczyć w licytacjach i awizacjach. Istnieją 3 rodzaje pojazdów: Auta, Naczepy i Przyczepy. Ustalaj datę końca przeglądu i ubezpieczenia pojazdów, a my wyślemy Ci przypomnienia o upływającym terminie",
    button: "Dodaj pojazd",
  },
  timeWindows: {
    title: "Okna czasowe",
    body: "Okna czasowe służą do rezerwacji okien czasowych podczas awizacji.",
    button: "Wykup okna czasowe",
  },
  abonament: {
    title: "Abonament",
    body: "Abonament umożliwia udział w licytacjach tworzonych przez Producentów.",
    button: "Wykup abonament",
  },
  warehouseList: {
    title: "Lista magazynów",
    body: "Oto lista magazynów. Znajdziesz tutaj firmy, z którymi możesz dokonywać autoryzacji. Przed wysłaniem prośby o autoryzację zawsze możesz sprawdzić najważniejsze informacje i dokumenty firmy w jej profilu.",
  },
  authorizations: {
    title: "Autoryzacje",
    body: "Tutaj znajdziesz listę wszystkich próśb o autoryzację, które wysłałeś innym firmom. Możesz tu również wycofać swoje prośby.",
  },
  monitaryNote: {
    title: "Awizacje",
    body: "W tej sekcji zobaczysz wszystkie zarezerwowane awizacje, ich status oraz wszystkie szczegółowe informacje. Masz tutaj również dostęp do czatu z magazynem, u którego chcesz przeprowadzić awizację, a także możliwość pobrania dokumentu transportowego. Awizacje możesz rezerwować tylko u zautoryzowanych magazynów.",
    button: "Dodaj awizację",
  },
  postedAuction: {
    title: "Wystawione licytacje",
    body: "Znajdują się tutaj licytacje wystawiane przez Producentów. Po wykupieniu abonamentu możesz składać oferty do aukcji, by zdobywać zlecenia od Producentów.",
  },
  currentAutcions: {
    title: "Uczestniczące licytacje",
    body: "Znajdują się tutaj licytacje, w których aktualnie uczestniczysz",
  },
  assignedAuctions: {
    title: "Przydzielone licytacje",
    body: "Znajdują się tutaj zlecenia przydzielone dla Twojego oddziału po wygraniu licytacji",
  },
  assignedTransports: {
    title: "Przydzielone transporty",
    body: "Są to zlecenia otrzymane od Producenta bezpośrednio, bez konieczności udziału w licytacji.",
    missing: "Brak transportów.",
  },
  canceledTransports: {
    title: "Anulowane transporty",
    body: "Są to otrzymane zlecenia, które zostały anulowane przez Producenta.",
  },
  declinedTransports: {
    title: "Nieotrzymane transporty",
    body: "Są to zlecenia pochodzące z licytacji, których Twój oddział nie wygrał.",
  },
  additionalPayment: {
    title: "Dodatkowe dopłaty do zleceń",
    body: "Jest to spis wszystkich dopłat, które zostały dołączone do zleconych transportów",
  },
  leasing: {
    title: "Leasing",
    body: "Składaj wnioski leasingowe, które następnie rozpatrzymy. Otrzymasz od nas powiadomienie o decyzji.",
    button: "Złóż wniosek",
    missing: "Brak wniosków",
  },
  factoring: {
    title: "Faktoring",
    body: "Jest to wykaz wszystkich zleceń z przyznanym faktoringiem",
    missing: "Brak aukcji z faktoringiem",
  },
  myWarehouses: {
    title: "Moje magazyny",
    body: "Magazyny są podstawowym elementem LOGIplanner. Wszystkie najważniejsze czynności w aplikacji wykonujesz, przypisując magazyn, którego dotyczyć będzie planowana akcja. ",
    button: "Dodaj magazyn",
  },
  driversGroups: {
    title: "Grupy przewoźników / Utworzone grupy",
    body: "Grupy Przewoźników możesz wykorzystać podczas wystawia licytacji, jeżeli nie chcesz, aby była dostępna publicznie dla wszystkich użytkowników. Dodając licytację, wybierz preferowaną grupę, która będzie odbiorcą Twojej licytacji. Wówczas tylko Przewoźnicy należący do grupy będą mogli zobaczyć aukcję i składać oferty. Do grup możesz dodawać tylko zautoryzowanych Przewoźników.",
    button: "Utwórz grupę",
    for: "Pokaż grupy dla",
    missing: "Brak grup dla wybranego oddziału",
  },
  createCalendar: {
    title: "Utwórz kalendarz",
    body: "Wypełnij kalendarz na dany miesiąc, aby Przewoźnicy mogli rezerwować awizacje w magazynie Twojej firmy. Po utworzeniu kalendarza możesz edytować dowolny dzień, dodając, edytując lub usuwając okna czasowe",
  },
  monitaryArchiveDriver: {
    for: "Wybierz oddział",
    missing: "Brak awizacji w archiwum dla wybranego oddziału",
  },
  monitaryArchiveWarehouse: {
    for: "Wybierz magazyn",
    missing: "Brak awizacji w archiwum dla wybranego magazynu",
  },
  auctionsArchive: {
    missing: "Brak aukcji w archiwum",
  },
  auctionsForProducer: {
    missing: "Brak aukcji ",
  },
  authorizationsProducer: {
    title: "Autoryzacje",
    body: "Tutaj znajdziesz listę wszystkich próśb o autoryzację od Przewoźników. Możesz je akceptować lub odrzucać.",
  },
  monitaryNotesProducer: {
    title: "Awizacje",
    body: "W tej sekcji zobaczysz wszystkie zarezerwowane awizacje, ich status oraz wszystkie szczegółowe informacje. Masz tutaj również dostęp do czatu z Przewoźnikiem, który zarezerwował awizację w Twoim magazynie, a także możliwość pobrania dokumentu transportowego. Tutaj decydujesz także, czy dany kierowca stawił się o umówionej godzinie.",
  },
  myAuctions: {
    title: "Moje licytacje",
    body: "Są to wszystkie licytacje utworzone przez Twoje magazyny. Licytacje możesz dodawać, edytować i anulować. Tutaj wybierasz także zwycięzcę licytacji, któremu przyznasz zlecenie. Gdy Przewoźnik zarezerwuje wszystkie okna czasowe potrzebne do wykonania zlecenia, wyświetli się również opcja pobrania Dokumentu zlecenia transportowego oraz przejścia do Dokumentu e-CMR.",
    button: "Dodaj licytację",
  },
  cancelledAuctions: {
    title: "Licytacje anulowane",
    body: "Jest to lista wszystkich wystawionych licytacji, które zostały anulowane.",
  },
  participants: {
    title: "Wykaz uczestników",
    body: "Jest to wykaz wszystkich Przewoźników, którzy biorą udział w licytacjach wystawianych przez Twój magazyn.",
    for: "Pokaż uczestników dla",
    missing: "Brak uczestników",
  },
  wallet: {
    button: "Doładuj okna",
    buttonSub: "Wykup abonament",
  },
  invoiceList: {
    missing: "Brak wystawionych faktur.",
  },
  monthlyInvoices: {
    title: "Faktury miesięczne",
    body: "W tym miejscu znajdziesz zestawienie wszystkich płatności w danym miesiącu. Zestawienie jest generowane na początku następnego miesiąca.",
  },
};
export default infoIcons;
