<template>
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7627 16.2H20.5627V18H0.762695V16.2H2.5627V0.9C2.5627 0.661305 2.65752 0.432387 2.8263 0.263604C2.99508 0.0948211 3.224 0 3.4627 0H17.8627C18.1014 0 18.3303 0.0948211 18.4991 0.263604C18.6679 0.432387 18.7627 0.661305 18.7627 0.9V16.2ZM16.9627 16.2V1.8H4.3627V16.2H16.9627ZM7.0627 8.1H9.7627V9.9H7.0627V8.1ZM7.0627 4.5H9.7627V6.3H7.0627V4.5ZM7.0627 11.7H9.7627V13.5H7.0627V11.7ZM11.5627 11.7H14.2627V13.5H11.5627V11.7ZM11.5627 8.1H14.2627V9.9H11.5627V8.1ZM11.5627 4.5H14.2627V6.3H11.5627V4.5Z"
      fill="currentColor"
      fill-opacity="0.8"
    />
  </svg>
</template>
