import { defineStore } from "pinia";
import axios from "@/axios";
import i18n from "@/i18n";
import ApiHelper from "@/helpsers/api.helper";
import authHelper from "@/helpsers/auth.helper";
import { inject, ref } from "vue";
import { parseError } from "@/js/error";
import AlertHelper from "@/helpsers/alert.helper";

export const useNotificationStore = defineStore("notification", () => {
  let alert = inject("alert");
  const counter = ref(0);
  const notifications = ref([]);
  const notificationsSmallList = ref([]);
  const onPage = ref(7);
  const page = ref(1);
  const total = ref(0);

  async function fetchNumberOfNotifications(companyId:string) {
    try {
      const response = await axios.get(ApiHelper.getNotificationsNumber(companyId), {
        headers: authHelper.authHeader(),
      });

      if (response && response.data) {
        counter.value = response.data.unreadNotificationsNumber;
      }
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function markAsRead(id: string, companyId:string) {
    try {
      await axios.put(
        ApiHelper.markAsRead(id, companyId),
        {},
        {
          headers: authHelper.authHeader(),
        }
      );
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function markAllAsRead(companyId:string) {
    try {
      await axios.put(
        ApiHelper.markAllAsRead(companyId),
        {},
        {
          headers: authHelper.authHeader(),
        }
      );
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function markAsUnread(id: string, companyId:string) {
    try {
      await axios.put(
        ApiHelper.markAsUnread(id, companyId),
        {},
        {
          headers: authHelper.authHeader(),
        }
      );
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function fetchAllNotifications(filter: string, companyId:string) {
    let url = "?limit=999&page=1&order=DESC";
    if (filter !== "ALL") url += `&isRead=${filter === "READ"}`;
    try {
      const response = await axios.get(ApiHelper.getNotifications(companyId) + url, {
        headers: authHelper.authHeader(),
      });
      if (response.data.data) {
        notificationsSmallList.value = response.data.data;
      }
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function fetchNotifications(all: boolean, companyId:string) {
    let url = "";
    if (all) url += "?limit=&order=DESC";
    else url += `?limit=${onPage.value}&page=${page.value}&order=DESC`;
    try {
      const response = await axios.get(ApiHelper.getNotifications(companyId) + url, {
        headers: authHelper.authHeader(),
      });
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      notifications.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  return {
    counter,
    notifications,
    notificationsSmallList,
    onPage,
    page,
    total,
    fetchNumberOfNotifications,
    markAsRead,
    markAllAsRead,
    markAsUnread,
    fetchAllNotifications,
    fetchNotifications,
  };
});
