import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import AlertHelper from "@/helpsers/alert.helper";
import i18n from "@/i18n";
import authHelper from "@/helpsers/auth.helper";
import storeCache from "@/js/storeCache";
import { inject, ref } from "vue";
import { parseError } from "@/js/error";

export interface GenerateInput {
  type: string;
  id?: string;
  alert?: any;
}
export interface VerifyInput {
  type: string;
  code: string;
  id?: string;
}
export const useVerificationStore = defineStore("varification", () => {
  let alert = inject("alert");
  const numbersArray = ref([] as Array<any>);
  const verificationCompleted = ref(false);
  async function generateCode(obj: GenerateInput) {
    let url = ApiHelper.returnBackendUrl();
    url += obj.type === "USER" ? "users" : obj.type === "COMPANY" ? "companies" : "departments";
    url += "/phone-number/generate-code";
    if (obj.type !== "USER") url += `/${obj.id}`;
    try {
      await axios.post(url);
    } catch (e: any) {
      AlertHelper.show(obj.alert, i18n.global.t("generals.error"), e.response.data.message, "error");
    }
  }
  async function verifyNumber(obj: VerifyInput) {
    verificationCompleted.value = false;
    let url = ApiHelper.returnBackendUrl();
    url += obj.type === "USER" ? "users" : obj.type === "COMPANY" ? "companies" : "departments";
    url += `/phone-number/confirm/${obj.code}`;
    if (obj.type !== "USER") url += `/${obj.id}`;
    await axios
      .post(url)
      .then((r: any) => {
        verificationCompleted.value = true;
      })
      .catch((e: any) => {
        verificationCompleted.value = false;
      });
  }
  async function fetchUnverifiedNumbers() {
    if (storeCache.get("token")) {
      let allCases = ["user", "department", "company"];
      let url = ApiHelper.getNumbers();
      allCases.forEach(async (c: string) => {
        url = ApiHelper.getNumbers();
        url += c === "department" ? "?department=true" : c === "company" ? "?company=true" : "";
        await axios
          .get(url, {
            headers: authHelper.authHeader(),
          })
          .then((response: any) => {
            response.data.data.forEach((number: any) => {
              if (c === "company") number.type = "COMPANY";
              if (c === "department") number.type = "DEPARTMENT";
              if (c === "user") number.type = "USER";
              number.phone = number.number;
              numbersArray.value.push(number);
            });
          })
          .catch((e: any) => {
            AlertHelper.show(alert, parseError(e), "", "error");
          });
      });
    }
  }
  return { numbersArray, verificationCompleted, generateCode, verifyNumber, fetchUnverifiedNumbers };
});
