<template>
    <page-not-found/>
</template>

<script>
import router from "@/router";
import { onMounted } from "@vue/runtime-core";
import PageNotFound from '@/views/PageNotFound.vue';
export default {
  components: { PageNotFound },
  name: "DefaultLayout",
  setup() {
    onMounted(() => {
      router.push("not-found");
    });
  },
};
</script>

<style scoped></style>
