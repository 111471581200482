import { createI18n } from "vue-i18n";
import pl from "./lang/pl";
import en from "./lang/en";
import storeCache from "../js/storeCache";
import { ref, watch } from "vue";

const messages = {
  pl,
  en,
};

const avaliableLang = {
  pl: "en",
};

let lang = ref(storeCache.get("lang"));

const cache = storeCache;

export function getLangConfig() {
  watch(lang, (newValue, oldValue) => {
    return newValue;
  });

  if (!avaliableLang.hasOwnProperty(lang.value)) {
    lang.value = "pl";
  }
  if (cache.get("lang")) lang.value = cache.get("lang");

  return lang.value;
}

export function setLangConfig(language: any) {
  cache.set("lang", language);
  // window.i18n = i18n;
  i18n.global.locale = language;
  lang.value = language;
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: getLangConfig(), // getLangConfig(),
  fallbackLocale: "pl",
  formatFallbackMessages: true,
  messages: messages,
});

export default i18n;
