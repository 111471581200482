import { computed, onMounted, onUnmounted, ref } from "vue";

export default function () {
  let windowWidth = ref(window.innerWidth);

  const onWidthChange = () => (windowWidth.value = window.innerWidth);
  onMounted(() => window.addEventListener("resize", onWidthChange));
  onUnmounted(() => window.removeEventListener("resize", onWidthChange));

  const type = computed(() => {
    if (windowWidth.value < 500) return "xs";
    if (windowWidth.value >= 500 && windowWidth.value < 767) return "s";
    if (windowWidth.value >= 767 && windowWidth.value < 991) return "md";
    if (windowWidth.value >= 991 && windowWidth.value < 1199) return "lg";
    if (windowWidth.value >= 1500) return "xl";
    return null; // This is an unreachable line, simply to keep eslint happy.
  });

  const width = computed(() => windowWidth.value);

  return { width, type };
}
