import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import { inject, ref } from "vue";
import AlertHelper from "@/helpsers/alert.helper";
import { parseError } from "@/js/error";

export interface PricePackage {
  price: number;
  monitoryNotesNumber?: number;
  amountSubscriptionDays?: number;
  type?: string;
  description?: string;
}
export const usePaymentAdminStore = defineStore("payments", () => {
  let alert = inject("alert");
  let page = ref(1);
  let onPage = ref(20);
  let total = ref(0);
  let transfers = ref();

  const deletePricePackage = async (id: string, alert: any) => {
    try {
      const res = await axios.put(ApiHelper.deletePricePackage(id));
      return res;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const addPricePackage = async (obj: PricePackage) => {
    try {
      const res = await axios.post(ApiHelper.addPricePackage(), obj);
      return res;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };
  const verifyCompany = async (id: string) => {
    try {
      const res = await axios.put(ApiHelper.verifyCompany(id));
      return res;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };
  const getVerificationTransfers = async () => {
    try {
      const res = await axios.get(ApiHelper.getVerificationTransfers(), {
        params: {
          page: page.value,
          limit: onPage.value,
        },
      });
      if (res.data) {
        transfers.value = res.data?.data;
        total.value = res.data?.meta?.total;
        onPage.value = res.data?.meta?.per_page;
        page.value = res.data?.meta?.current_page;
      }

      return res;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
      throw e;
    }
  };

  return {
    deletePricePackage,
    addPricePackage,
    getVerificationTransfers,
    transfers,
    page,
    onPage,
    total,
    verifyCompany,
  };
});
