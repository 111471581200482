import alertHelper from "@/helpsers/alert.helper";
import i18n from "@/i18n";
import storeCache from "@/js/storeCache";
import { typeOfCompany } from "@/js/utils";
import router from "@/router";
import { useCarStore } from "@/stores/cars.store";
import { useChatStore } from "@/stores/chat.store";
import { useCompanyStore } from "@/stores/company.store";
import { useMonitaryStore } from "@/stores/monitary.store";
import { useRampsStore } from "@/stores/ramps.store";
import { useStockStore } from "@/stores/stock.store";
import { storeToRefs } from "pinia";

export const handleNotificationRedirect = async (
  isAdmin: boolean,
  notification: {
    id: string;
    type: string;
    status: string;
    args: {
      auctionSubId: string;
      auctionId: string;
      departmentId: string;
      carId: string;
      departmentName: string;
      monitoryNoteId: string;
      companyId?: string;
      monitoryNoteFromAuction: boolean;
      monitoryNoteNumberInWarehouse: string;
    };
    warehouseId: string;
    date: string;
    chatId: string;
    chatUserDepartmentId: string;
  }
) => {
  let rampsStore = useRampsStore();
  const { ramps } = storeToRefs(rampsStore);
  const carStore = useCarStore();
  const chatStore = useChatStore();
  const stockStore = useStockStore();
  const companyStore = useCompanyStore();
  const monitaryStore = useMonitaryStore();
  let { note } = storeToRefs(monitaryStore);
  if (notification.type === "LEASE_RECEIVED") {
    router.push("/admin/bank");
  }
  if (notification.type === "GROUP_INVITATION") {
    router.push("/moje-grupy");
  }
  if (
    isAdmin &&
    ["VERIFICATION_TRANSFER", "CAR_POLICY", "KRS_CDiG", "TRANSPORT_LICENSE", "CARRIER_INSURANCE", "CONTRACT_TERMS"].includes(notification.type)
  ) {
    if (!notification.args.companyId) return;
    const company = await companyStore.fetchOneCompany(`${notification.args.companyId}`);
    if (notification.type === "VERIFICATION_TRANSFER") {
      router.push("/admin/przelewy-weryfikacyjne");
      return;
    }
    router.push(`/admin/dokumenty?name=${company?.companyName}`);
    return;
  }
  if (notification.type === "AUCTION" || notification.type === "AUCTION_OFFER") {
    if (typeOfCompany() === "FACTORY") {
      if (notification.status === "FINISHED") {
        router.push({ name: "archive-auctions", query: { id: notification.args?.auctionSubId } });
        return;
      }
      if (notification.status === "REJECTED") {
        router.push({ name: "cancelled-auctions" });
        return;
      }
      router.push({
        name: "my-auctions",
        query: { id: notification.args?.auctionSubId },
      });
    } else {
      if (
        notification.status === "CANCELLED" ||
        notification.status === "UPDATED" ||
        notification.status === "OUTBID" ||
        notification.status === "REJECTED" ||
        notification.status === "PENDING"
      ) {
        router.push("/gielda?id=" + notification.args.auctionId);
        return;
      }

      if (notification.status === "FINISHED") {
        router.push({ name: "archive-auctions", query: { id: notification.args?.auctionSubId } });
        return;
      }
      if (notification.status === "ACCEPTED" || notification.status === "PAID") {
        router.push({ name: "my-offers", query: { id: notification.args?.auctionSubId } });
        return;
      } else {
        router.push({ name: "included-auctions" });
        return;
      }
    }
  }
  if (notification.type === "FACTORING") {
    router.push({ name: "factoring-list" });
  }
  if (notification.type === "KRS_CDiG" || notification.type === "TRANSPORT_LICENSE" || notification.type === "CARRIER_INSURANCE") {
    router.push("/dokumenty");
  }
  if (notification.type === "CONTRACT_TERMS" && !isAdmin) {
    router.push(`/add-documents?id=${notification.args?.departmentId}&type=AGREEMENTS`);
  }
  if (notification.type === "CONTRACT_TERMS" && isAdmin) {
    router.push({ name: "department-admin", query: { id: notification.args?.departmentId } });
  }
  if (notification.type === "AVAILABILITY" && notification.status !== "MONTHLY_CALENDAR_REQUEST") {
    if (typeOfCompany() === "WAREHOUSE" || typeOfCompany() === "FACTORY") {
      await rampsStore.getRamps(notification.warehouseId as string);
      if (!ramps.value || ramps.value.length === 0) {
        router.push(`/add-warehouse?id=${notification.warehouseId}`);
        return;
      }
      router.push(
        `/edit-calendar-day/${`${notification.date.substring(6, 10)}-${notification.date.substring(3, 5)}-${notification.date.substring(
          0,
          2
        )}`}?department=${notification.warehouseId}&rampId=${ramps.value[0]?.id}`
      );
    } else {
      router.push({ name: "add-monitary" });
    }
  }
  if (notification.status === "MONTHLY_CALENDAR_REQUEST") {
    let month = notification.date.substring(3, 5);
    if (typeOfCompany() === "WAREHOUSE" || typeOfCompany() === "FACTORY") {
      router.push(`/fill-callendar?id=${notification.warehouseId}&month=${parseInt(month)}`);
    }
  }

  if (
    (notification.type === "TECHNICAL_REVIEW_EXPIRES" || notification.type === "LEASE_EXPIRES" || notification.type === "CAR_POLICY") &&
    typeOfCompany() === "DRIVER"
  ) {
    if (notification.type === "CAR_POLICY" && notification.status === "ACCEPTED") {
      router.push({ name: "list-cars" });
      return;
    }
    const id = storeCache.get("currentCompany")?.id;
    const cars = await carStore.listAllCarsForCompany(id);
    const foundCar = cars.data.data.find((car: { id: any }) => car.id === notification.args.carId);
    if (foundCar) {
      foundCar.carInsurer = foundCar.carInsurer.name;
      storeCache.set("car", foundCar);
      router.push("/dodaj-auto?edit=true");
    }
  }
  if (notification.type === "DEPARTMENT_AUTH")
    router.push({
      name: "authorizations",
      query: { status: notification.status === "WAITING_FOR_ACCEPTANCE" ? "PENDING" : notification.status, name: notification.args?.departmentName },
    });

  if (notification.type === "MONITORY_NOTE") {
    if (notification.status === "REJECTED") {
      if (notification.args.monitoryNoteFromAuction) {
        const response = await stockStore.getSingleAuction(notification.args.auctionId);
        if (response.type === "PREDETERMINED") {
          router.push(`/wygrane-aukcje?id=${response.subId}`);
        } else {
          router.push(`/moje-oferty?id=${response.subId}`);
        }
        return;
      } else {
        await monitaryStore.listSingleMonitary({
          id: notification.args.monitoryNoteId,
          departmentId: notification.args.departmentId,
        });
        const ob = {
          operationType: { value: note.value.type, name: i18n.global.t(`profile.statuses.${note.value.type?.toLowerCase()}`) },
          department: note.value.carrier,
          warehouse: note.value.warehouse,
        };
        storeCache.set("monitaryData", ob);

        let obj2 = {
          pickedHour: undefined,
          day: { id: note.value.date, month: parseInt(note.value.date.substring(5, 7)) },
        };
        storeCache.set("monitaryDate", obj2);

        let obj3 = {
          type: storeCache.get("monitaryData").operationType.value,
          date: storeCache.get("monitaryDate").day.id,
          driverFullName: note.value.driverFullName,
          driverDocument: note.value.driverDocument,
          driverPhone: note.value.driverPhone,
          driverEmail: note.value.driverEmail,
          consignmentNumber: note.value.consignmentNumber,
          departmentId: note.value.warehouse.id,
          carrierId: note.value.carrier.id,
          warehouseManagerId: storeCache.get("currentUser").id,
          car: undefined,
          trailer: undefined,
          semitrailer: undefined,
          warehouseMonitoryNoteId: "123",
          cargos: note.value.cargos,
          chosenDriver: undefined,
        };

        storeCache.set("monitaryStepThree", obj3);
        router.push("/awizacja?step=2");
      }
      return;
    }
    router.push({
      name: "list-monitary",
      query: { type: "ALL", monitoryNoteId: notification.args?.monitoryNoteNumberInWarehouse ?? "", company: -1 },
    });
  }
  if (notification.type === "LEASE_ACCEPTED") {
    router.push({ name: "leasing" });
  }

  //CHATS
  if (notification.type === "CHAT_MESSAGE" && !isAdmin) {
    if (notification.chatId) {
      const chat = await chatStore.fetchChatDetails(notification.chatUserDepartmentId, notification.chatId);
      if (chat.auctionId) {
        router.push(
          `/chat?id=${chat.id}&auctionId=${chat.auctionId}&name=${chat.otherSideName}&departmentId=${chat.departmentId}&otherSideId=${chat.otherSideId}`
        );
      } else {
        router.push(
          `/chat?id=${chat.id}&monitoryId=${chat.monitoryNoteId}&name=${
            chat.otherSideName +
            ` - Awizacja ${chat.monitoryNoteSubId ? "nr " + chat.monitoryNoteSubId : ""} na dzień (${
              chat.monitoryNoteDate ? chat.monitoryNoteDate : "-"
            })`
          }&departmentId=${chat.departmentId}&otherSideId=${chat.otherSideId}`
        );
      }
    } else {
      router.push({ name: "chat-list" }).then(() => {
        alertHelper.show(alert, i18n.global.t("snackbars.chat_redirect_error"), "", "error");
      });
    }
  }
  if (notification.type === "CHAT_MESSAGE" && isAdmin) {
    if (notification.chatId) {
      const chat = await chatStore.fetchChatDetails(notification.chatUserDepartmentId, notification.chatId);
      if (chat.auctionId) {
        router.push(
          `/chat?id=${chat.id}&auctionId=null&name=${chat.otherSideName}&departmentId=${chat.departmentId}&otherSideId=${chat.otherSideId}`
        );
      } else {
        router.push(
          `/admin/chat?id=${chat.id}&monitoryId=null&name=${chat.otherSideName}&departmentId=${chat.departmentId}&otherSideId=${chat.otherSideId}`
        );
      }
    }
  }
};
