import { defineStore } from "pinia";
import { inject, ref } from "vue";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import AlertHelper from "@/helpsers/alert.helper";
import { parseError } from "@/js/error";

export interface DepartmentBlockingBody {
  blockedDepartmentId: string;
}

export const useBlockingStore = defineStore("blocking", () => {
  let alert = inject("alert");
  const blocked = ref([]);
  const onPage = ref(5);
  const page = ref(1);
  const total = ref(0);

  async function fetchBlocked(departmentId: string) {
    const url = `?limit=${onPage.value}&page=${page.value}`;
    try {
      const response = await axios.get(ApiHelper.listBlockedDepartments(departmentId) + url);
      blocked.value = response.data.data;
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function blockDepartment(departmentId: string, body: DepartmentBlockingBody) {
    try {
      await axios.put(ApiHelper.blockDepartment(departmentId), body);
    } catch (e) {
      throw e;
    }
  }

  async function unblockDepartment(departmentId: string, body: DepartmentBlockingBody) {
    try {
      const response = await axios.put(ApiHelper.unblockDepartment(departmentId), body);
      return response;
    } catch (e) {
      throw e;
    }
  }

  return { blocked, fetchBlocked, blockDepartment, unblockDepartment, onPage, page, total };
});
