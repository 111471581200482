import axios from "axios";
import ApiHelper from "@/helpsers/api.helper";
import AuthHelper from "@/helpsers/auth.helper";
import { useAuthStore } from "@/stores/auth.store";
import authHelper from "@/helpsers/auth.helper";
import StoreCache from "@/js/storeCache";
import storeCache from "@/js/storeCache";

class UserService {
  static getUserInitials(clientData: any) {
    let initials = "";
    if (clientData.firstName && clientData.firstName.length > 0) initials += Array.from(clientData.firstName)[0];
    if (clientData.lastName && clientData.lastName.length > 0) initials += Array.from(clientData.lastName)[0];

    return initials;
  }

  getUserInfo() {
    const authStore = useAuthStore();
    authStore.isLoading = true;
    if (authStore.user) {
      axios
        .get(ApiHelper.getCurrentUrl(), { headers: AuthHelper.authHeader() })
        .then((response) => {
          authStore.isLoading = false;
          try {
            authStore.saveUserInfo(response.data);
          } catch (e) {
            console.log(e);
          }
        })
        .catch((error) => {
          authStore.logout();
          authStore.isLoading = false;
        });
    }
  }

  async setUserProfileImage(formData: any) {
    let result = null;
    const authStore = useAuthStore();
    //@ts-ignore
    const url = ApiHelper.getUploadProfileImageUrl(parseInt(authStore.userInfo?.profilePhotoId));
    result = await axios.post(url, formData, {
      headers: authHelper.authHeader(),
    });
    return result;
  }

  async getUserProfileImage(id: string) {
    let img = window.document.getElementById("profile_photo_placeholder");
    const authStore = useAuthStore();
    if (typeof id !== "object") {
      await axios
        .get(ApiHelper.getImage(id), {
          headers: authHelper.authHeader(),
          responseType: "blob",
        })
        .then((result) => {
          const objectURL = URL.createObjectURL(result.data);
          if (authStore.userInfo) {
            //@ts-ignore
            authStore.userInfo.profilePhotoBlob = objectURL;
          }
          setTimeout(() => {
            storeCache.set("profile_pic", objectURL);
            //@ts-ignore
            if (img !== null) img.src = objectURL;
            return Promise.resolve("Success get profile image");
          }, 500);
        })
        .catch((e) => {
          console.log(e);
          // return Promise.reject("Error get profile image");
        });
    }
  }
  async getUserProfileImageAndReturn(id: string) {
    let url = "";
    if (typeof id !== "object") {
      await axios
        .get(ApiHelper.getImage(id), {
          headers: authHelper.authHeader(),
          responseType: "blob",
        })
        .then((result) => {
          url = URL.createObjectURL(result.data);
        })
        .catch((e) => {
          console.log(e);
          // return Promise.reject("Error get profile image");
        });
    }
    return url;
  }
  removeUserProfileImage() {
    const authStore = useAuthStore();
    //@ts-ignore
    if (authStore.userInfo?.profilePhotoId > 0) {
      return (
        axios
          //@ts-ignore
          .delete(ApiHelper.removeProfileImageUrl(parseInt(authStore.userInfo.profilePhotoId)), {
            headers: authHelper.authHeader(),
          })
          .then((result) => {
            //@ts-ignore
            authStore.userInfo.profilePhotoBlob = null;
            //@ts-ignore
            authStore.userInfo.profilePhotoId = null;
            return Promise.resolve("Success remove profile image");
          })
          .catch((e) => {
            //@ts-ignore
            authStore.userInfo.profilePhotoBlob = null;
            //@ts-ignore
            authStore.userInfo.profilePhotoId = null;
            console.error("Błąd podczas usuwania zdjęcia");
            return Promise.resolve("Error remove profile image");
          })
      );
    }
  }

  /**
   * User logout
   */
  logout() {
    const authStore = useAuthStore();
    authStore.logout();
  }

  /**
   * Refresh token for user.
   */
  refreshToken() {
    axios
      .post(
        ApiHelper.getRefreshTokenUrl(),
        {},
        {
          headers: authHelper.authHeader(),
        }
      )
      .then((response) => {
        try {
          const user = StoreCache.get("user");
          user.tokenExpirationTime = response.data.tokenExpirationTime;
          user.accessToken = response.data.accessToken;
          StoreCache.set("user", user);
          return true;
        } catch (error) {
          //@ts-ignore
          throw new Error("Error during refresh token. Error: ".error);
        }
      })
      .catch((error) => {
        console.log("Error during refresh token. Error: ", error);
      });
  }

  checkToken() {
    axios
      .get(ApiHelper.getCheckTokenUrl(), {
        headers: authHelper.authHeader(),
      })
      .then((response) => {
        if (!response.data) this.logout();
      })
      .catch((error) => {
        this.logout();
      });
  }
}

export default UserService;
