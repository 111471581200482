<template>
    <div class="control control--input custom-select">
        <label for="customer" class="control__label">{{label}}</label>
        <input
            type="text"
            id="customer"
            class="select"
            :class="{'error': errors}"
            :placeholder="placeholder"
            @input="searchElement"
            @click="activeChooseClient = true"
            :value="selectedItemName"
            ref="clickOutside"
            autocomplete="off"
        />
        <div class="custom-select__options"
             :class="{active: activeChooseClient}"
        >
            <div class="custom-select__option"
                 v-if="result.length > 0"
                 v-for="(item,id) in result" :key="id"
                 @click="chooseItem(item)"
            >
                <div
                    v-if="withInitials"
                    class="custom-select__option-initials"
                    style="background-color: #3f8c7e"
                >
                {{userService.getUserInitials(item)}}
                </div>

                {{setCustomLabel(item)}}
            </div>

            <div class="custom-select__option no-select" v-if="result.length <= 0">
                {{emptyResult}}
            </div>

        </div>
        <div v-show="errors" class="control__error">
            <div class="control__error-inner">
                {{errors}}
            </div>
        </div>
    </div>
</template>

<script>
import {ref, watch, toRefs} from "vue";
import {useClickOutside} from "@/helpsers/click-outside.helpers";
import userService from "@/services/user.service";
export default {
    name: "selectSearch",
    props: {
        data: {
            type: Object,
            default: {},
        },
        selectedItem: {
            type: Object,
            default: {}
        },
        setCustomLabel: Function,
        setCustomSearchField: {
            type: Function,
            default: (item) => item.firstName + item.lastName
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: '',
        },
        emptyResult: {
            type: String,
            default: '',
        },
        withInitials: {
            type: Boolean,
            default: true,
        },
        errors: {
            type: String,
            default: '',
        }
    },
    setup(props,{emit}) {
        const activeChooseClient = ref(false),
              selectedItemName = ref('');

        const {data,selectedItem,label,placeholder,emptyResult} = toRefs(props),
            setCustomLabel = props.setCustomLabel,
            setCustomSearchField = props.setCustomLabel;

        let result = ref([]);

        watch(data, (curr,old) => {
            result.value = structuredClone(curr);
        })

        const clickOutside = ref('');
        useClickOutside(clickOutside,(e) => {
            activeChooseClient.value = false;
        });

        function searchElement(word) {
            const searchWord = word.target.value;
            selectedItemName.value = searchWord;

            result.value = data.value.filter(item => {
                const search = setCustomSearchField(item);
                if(search.toLowerCase().includes(searchWord.toLowerCase())) {
                    return item;
                }
            });

            emit('update:selectedItem',null);
        }

        function chooseItem(item) {
            activeChooseClient.value = false;
            emit('update:selectedItem',item);
            selectedItemName.value = setCustomLabel(item);
        }

        return {
            activeChooseClient,
            searchElement,
            result,
            chooseItem,
            clickOutside,
            selectedItemName,
            setCustomLabel,
            label,
            placeholder,
            emptyResult,
            userService
        }
    }
}
</script>

<style scoped>
.no-select {
    cursor: default;
    background-color: #e3e3e3;
}
</style>