import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import authHelper from "@/helpsers/auth.helper";
import AlertHelper from "@/helpsers/alert.helper";
import { RegularDataObject, RegularErrorResponse } from "@/stores/utils/interfaces";
import { inject, ref } from "vue";
import { parseError } from "@/js/error";

export const useWorkersLogiplannerStore = defineStore("workersAdmin", () => {
  const users = ref([]);
  const page = ref(1);
  const onPage = ref(5);
  const total = ref(0);
  const isLoading = ref(false);
  let alert = inject("alert");

  const addWorker = async (obj: any) => {
    const { alert, ...object } = obj;
    try {
      await axios.post(ApiHelper.addAdminWorker(), object, {
        headers: authHelper.authHeader(),
      });
      AlertHelper.show(alert, "Pomyślnie dodano administratora", "", "success");
      return true;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchAdminWorkers = async (pageC: string | number, onPageC: string | number, sorting: string) => {
    try {
      const result = await axios.get(ApiHelper.getAdminWorkers() + `?limit=${onPageC}&page=${pageC}${sorting && sorting !== "" ? sorting : ""}`, {
        headers: authHelper.authHeader(),
      });
      users.value = result.data.data.filter((user: { status: string }) => user.status !== "DELETED");
      total.value = result.data.meta.total;
      onPage.value = result.data.meta.per_page;
      page.value = result.data.meta.current_page;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  return {
    users,
    page,
    onPage,
    total,
    isLoading,
    addWorker,
    fetchAdminWorkers,
  };
});
