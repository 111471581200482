import { defineStore } from "pinia";
import axios from "@/axios";
import i18n from "@/i18n";
import ApiHelper from "@/helpsers/api.helper";
import { inject, ref } from "vue";
import AlertHelper from "@/helpsers/alert.helper";
import { parseError } from "@/js/error";

export interface Driver {
  fullName: string;
  email: string;
  phone: string;
  documentNumber: string;
}

export interface AddDriverReq {
  id?: string;
  alert?: any;
  fullName: string;
  email: string;
  phone: string;
  documentNumber: string;
  departmentIds: Array<string>;
}

export const useDriverStore = defineStore("driver", () => {
  let alert = inject("alert");
  const drivers = ref([] as Array<Driver>);
  const currentDriver = ref({} as Driver);
  const onPage = ref(5);
  const page = ref(1);
  const total = ref(0);

  const fetchDrivers = async (id: string, query?: string) => {
    try {
      if (!id || id == "0") return;
      let url = `?limit=${onPage.value}&page=${page.value}`;
      if (query) url += `?query=${query}`;
      const response = await axios.get(ApiHelper.listDrivers(id) + url);
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      drivers.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchDriversForCompany = async (id: string, query?: string) => {
    try {
      if (!id || id == "0") return;
      let url = `?limit=${onPage.value}&page=${page.value}`;
      if (query) url += `&query=${query}`;
      const response = await axios.get(ApiHelper.listDriversForCompany(id) + url);
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      drivers.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchDriver = async (id: string) => {
    try {
      const response = await axios.get(ApiHelper.editDriver(id));
      console.log(response);
      currentDriver.value = response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const addDriver = async (body: AddDriverReq) => {
    try {
      const response = await axios.post(ApiHelper.addDriver(), body);
      return response;
    } catch (e: any) {
      // return "409";
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const addDriverToDepartments = async (body: Array<string>, driverId: string) => {
    try {
      const response = await axios.put(ApiHelper.addDriverToDepartments(driverId), { departmentIds: body });
      return response;
    } catch (e: any) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const removeDriverFromDepartments = async (body: Array<string>, driverId: string) => {
    try {
      const response = await axios.put(ApiHelper.removeDriverFromDepartments(driverId), { departmentIds: body });
      return response;
    } catch (e: any) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const editDriver = async (body: AddDriverReq) => {
    const { id, ...data } = body;
    try {
      const response = await axios.put(ApiHelper.editDriver(id), data);
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  return {
    drivers,
    currentDriver,
    onPage,
    page,
    total,
    fetchDrivers,
    fetchDriversForCompany,
    fetchDriver,
    addDriver,
    addDriverToDepartments,
    removeDriverFromDepartments,
    editDriver,
  };
});
