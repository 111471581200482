<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6631 18H1.66309C1.39787 18 1.14352 17.8946 0.955979 17.7071C0.768443 17.5196 0.663086 17.2652 0.663086 17V9.7C0.663106 9.5576 0.693539 9.41685 0.752347 9.28716C0.811154 9.15747 0.896982 9.04184 1.00409 8.948L2.66309 7.5V1C2.66309 0.734784 2.76844 0.48043 2.95598 0.292893C3.14352 0.105357 3.39787 0 3.66309 0H17.6631C17.9283 0 18.1827 0.105357 18.3702 0.292893C18.5577 0.48043 18.6631 0.734784 18.6631 1V17C18.6631 17.2652 18.5577 17.5196 18.3702 17.7071C18.1827 17.8946 17.9283 18 17.6631 18ZM6.66309 4.329C6.90521 4.32928 7.13901 4.4174 7.32109 4.577L12.3211 8.952C12.4279 9.0453 12.5136 9.16025 12.5725 9.28921C12.6315 9.41817 12.6624 9.5582 12.6631 9.7V16H16.6631V2H4.66309V5.75L6.00409 4.576C6.18657 4.41654 6.42075 4.32876 6.66309 4.329ZM5.66309 13H7.66309V16H10.6631V10.157L6.66309 6.657L2.66309 10.157V16H5.66309V13Z"
      fill="currentColor"
      fill-opacity="0.8"
    />
  </svg>
</template>
<style scoped>
svg {
  max-width: 24px;
}
</style>
