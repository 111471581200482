import { defineStore } from "pinia";
import router from "@/router";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import storeCache from "@/js/storeCache";
import AlertHelper from "@/helpsers/alert.helper";
import UserService from "@/services/user.service";
import { useUserStore } from "./user.store";
import i18n from "@/i18n";
import { RegularErrorResponse, RegularGetResponse } from "./utils/interfaces";
import { parseError } from "@/js/error";
let token = storeCache.get("token");

export interface RemindPasswordBody {
  token: string;
  password: string;
}
export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: storeCache.get("currentUser"),
    userInfo: {} as any,
    returnUrl: null,
    isLoading: false,
  }),
  actions: {
    async login(email: string, password: string) {
      const userStore = useUserStore();
      this.isLoading = true;
      await axios
        .post(ApiHelper.loginUrl(), {
          username: email,
          password: password,
        })
        .then(async (response: any) => {
          if (response.data.message == "Sms z kodem weryfikacyjnym został wysłany") {
            this.isLoading = false;
            const code = prompt(`Wprowadź kod weryfikacyjny wysłany na twój numer telefonu`, "");
            if (code) {
              const response = await axios.post(ApiHelper.twoFactorAuth(), {
                username: email,
                code: code,
              });
              if (response) {
                storeCache.set("token", response.data.access_token);
                await userStore.fetchCurrentUser();
                storeCache.set("initial_reload", true);
                router.push("/dashboard");
              }
            }
          } else if (response) {
            storeCache.set("token", response.data.access_token);
            await userStore.fetchCurrentUser();
            storeCache.set("initial_reload", true);
            this.isLoading = false;
            router.push("/dashboard");
          }
        })
        .catch((err: RegularErrorResponse) => {
          this.isLoading = false;
          throw err.response.data.message;
        });
    },

    async register(obj: any) {
      this.isLoading = true;
      const response = await axios
        .post(ApiHelper.registerUrl(""), {
          ...obj,
        })
        .then((res: { data: boolean }) => {
          this.isLoading = false;
          return res.data;
        })
        .catch((err: RegularErrorResponse) => {
          console.log(err);
          this.isLoading = false;
          throw err.response.data.message;
        });
      return response;
    },
    async registerFromInvite(obj: any, invitationId: string) {
      this.isLoading = true;
      const response = await axios
        .post(ApiHelper.registerUrl(`?invitationId=${invitationId}`), {
          ...obj,
        })
        .then((res: { data: boolean }) => {
          this.isLoading = false;
          return res.data;
        })
        .catch((err: RegularErrorResponse) => {
          this.isLoading = false;
          throw err.response.data.message;
        });
      return response;
    },

    async remindPassword(email: string) {
      this.isLoading = true;
      await axios
        .post(ApiHelper.forgotPasswordUrl(), {
          email,
        })
        .then(() => {
          this.isLoading = false;
        })
        .catch((err: RegularErrorResponse) => {
          this.isLoading = false;

          throw err.response.data.message;
        });
    },

    async resetPassword(obj: RemindPasswordBody) {
      this.isLoading = true;
      await axios
        .post(ApiHelper.resetPasswordUrl(), {
          remindPasswordToken: obj.token,
          newPassword: obj.password,
        })
        .then(() => {
          this.isLoading = false;
        })
        .catch((err: RegularErrorResponse) => {
          this.isLoading = false;

          throw err.response.data.message;
        });
    },
    async changePassword(obj: any) {
      let { alert, ...object } = obj;
      this.isLoading = true;
      await axios
        .put(ApiHelper.getChangePasswordUrl(), object, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res: any) => {
          if (res.data === true)
            AlertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("reset_password.password_changed"), "success");
          this.isLoading = false;
        })
        .catch((err: any) => {
          this.isLoading = false;
          throw err.response.data.statusCode;
        });
    },

    logout() {
      // Get a reference to the last interval + 1
      const interval_id = window.setInterval(function () {}, Number.MAX_SAFE_INTEGER);

      // Clear any timeout/interval up to that id
      for (let i = 1; i < interval_id; i++) {
        window.clearInterval(i);
      }
      var id = window.setTimeout(function () {}, 0);

      while (id--) {
        window.clearTimeout(id); // will do nothing if no timeout with id is present
      }
      this.user = null;
      this.userInfo = null;
      storeCache.clear();
      this.isLoading = false;
      router.push("/login");
    },

    async resendConfirmation(userId: string) {
      this.isLoading = true;
      const response = await axios
        .put(`${ApiHelper.resendConfirmation()}${userId}`)
        .then((res: { data: boolean }) => res.data)
        .catch((err: RegularErrorResponse) => {
          throw err.response.data.message;
        });
      this.isLoading = false;
      return response;
    },

    async confirmRegistration(token: string) {
      this.isLoading = true;
      const response = await axios
        .put(`${ApiHelper.confirmRegistration()}${token}`)
        .then((res: { data: boolean }) => res.data)
        .catch((err: RegularErrorResponse) => {
          throw err.response.data.message;
        });
      this.isLoading = false;
      return response;
    },
    async getGusData(nip: string | number, alert: any) {
      return await axios
        .get(ApiHelper.getGUS(nip))
        .then((res: RegularGetResponse<unknown>) => {
          return res.data;
        })
        .catch((err: RegularErrorResponse) => {
          AlertHelper.show(alert, "Błąd", parseError(err), "error");
        });
    },

    saveUserInfo(user: any) {
      const userService = new UserService();
      if (user.avatar && user.avatar.id) {
        userService.getUserProfileImage(user.avatar.id);
      }
      this.userInfo = user;
    },
  },
});
