const ramps = {
  adding_title: "Ustaw godziny dostępności i długości okien czasowych dla każdej z ramp",
  your_ramps: "Twoje rampy",
  names_info: "Uwaga! Jeżeli nazwa twojej rampy przekracza długość pięciu znaków, wyświetlana będzie w formie indeksu",
  no_ramps_in_warehouse: "Brak ramp w magazynie",
  fill_ramps: "Uzupełnij rampy",
  no_ramps: "Brak ramp",
  no_ramps_error: "Musisz dodać przynajmniej jedną rampę",
  filled: "Rampa uzupełniona",
  empty: "Rampa nieuzupełniona",
  error: "Błąd uzupełniania",
  saved: "Pomyślnie zapisano zmiany.",
  current: "Aktualnie wybrana rampa",
  confirmRamps: "Potwierdź dodanie kalendarza",
  adding_ramps_title: "Wpisz nazwy ramp",
  adding_ramp_title: "Wpisz nazwę rampy",
  adding_ramps_subtitle:
    "Uwaga! Pamiętaj, aby nazwy ramp były identyczne z nazwami fizycznych ramp w Twoim magazynie. W innym przypadku Przewoźnicy mogą nie odnaleźć właściwej rampy.",
  ramp_number: "Numer rampy",
  ramp_name: "Nazwa rampy",
  ramp: "Rampa",
  ramp_adding: "Dodawanie rampy",
  ramp_name_error: "Nazwa rampy nie może być pusta",
  manage_ramps: "Zarządzaj rampami",
  pick_ramp: "Wybierz rampę",
  pick_ramp_to_accept_monitory: "Wybierz rampę, w której przewoźnik wykona awizację.",
  no_ramps_for_monitory: "Nie posiadasz wolnych ramp, na godzinę ",
  no_ramps_for_monitory_2: "Dodaj okna czasowe dla rampy, aby przyjąć kolejne awizacje. ",
  ramp_delete_confirmation:
    "Czy na pewno chcesz usunąć rampę? Wszystkie kalendarze oraz awizacje utworzone dla niej zostaną usunięte. Czy na pewno chcesz kontynuować?",
  errors: {
    empty: "Aby dodać kalendarz, musisz uzupełnić każdą rampę",
    error: "Błędnie uzupełnione rampy.",
    single_ramp: "Błędna rampa",
  },
};

export default ramps;
