<template>
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_694_2091)">
      <mask id="mask0_694_2091" style="mask-type: luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="8">
        <path
          d="M4.00131 7.33268C4.43913 7.33325 4.87275 7.24729 5.27724 7.07974C5.68173 6.91219 6.04913 6.66635 6.3583 6.35635C6.6683 6.04717 6.91414 5.67978 7.08169 5.27529C7.24924 4.87079 7.33521 4.43717 7.33464 3.99935C7.3352 3.56153 7.24923 3.12792 7.08168 2.72342C6.91413 2.31893 6.66829 1.95154 6.3583 1.64235C6.04913 1.33236 5.68173 1.08652 5.27724 0.918966C4.87275 0.751414 4.43913 0.665451 4.00131 0.666018C3.56348 0.66546 3.12987 0.751427 2.72538 0.918979C2.32088 1.08653 1.95349 1.33236 1.6443 1.64235C1.33432 1.95154 1.08848 2.31893 0.920932 2.72342C0.75338 3.12792 0.667413 3.56153 0.667972 3.99935C0.667404 4.43717 0.753367 4.87079 0.920919 5.27529C1.08847 5.67978 1.33431 6.04717 1.6443 6.35635C1.95349 6.66634 2.32088 6.91217 2.72538 7.07972C3.12987 7.24728 3.56348 7.33324 4.00131 7.33268Z"
          fill="white"
          stroke="white"
          stroke-linejoin="round"
        />
        <path d="M2.66797 4L3.66797 5L5.66797 3" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
      </mask>
      <g mask="url(#mask0_694_2091)">
        <path d="M0 0H8V8H0V0Z" fill="#36BD3E" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_694_2091">
        <rect width="8" height="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
