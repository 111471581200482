export default {
  auth: {
    register_title_button: "Kliknij, aby się zarejestrować",
    dont_have_account: "Nie masz konta?",
    sign_in_fb: "Zaloguj się przez Facebook",
    sign_in_google: "Zaloguj się przez Google",
    forgot_password_label: "Zapomniałeś hasła?",
    remember_me_label: "Zapamiętaj mnie",
    log_in_label: "Zaloguj się",
    password_label: "Hasło",
    enter_password_placeholder: "Wpisz hasło",
    enter_email_placeholder: "Wpisz e-mail",
    enter_login_or_email_label: "Wpisz login lub e-mail",
    error_wrong_login_or_password: "Podany login lub hasło jest nieprawidłowe",
    log_in_progress_label: "Logowanie...",
    server_error_try_again: "Błąd wewnętrzny serwera. Spróbuj ponownie później",
    email_is_required: "Pole adres e-mail jest wymagane",
    password_is_required: "Pole hasło jest wymagane",
    user_not_verified:
      "Konto nie zostało zweryfikowane. Na email podany przy rejestracji wysłany został link weryfikacyjny.",
    wrong_password: "Nieprawidłowe hasło.",
    regulations_acceptance_first: "Zapoznałem się z polityką prywatności",
    regulations_acceptance_second: "Zapoznałem się z regulaminem",
    regulationsAcceptance: "Musisz zaakceptować regulamin",
    privacyAcceptance: "Musisz zaakceptować politykę prywatności",
  },
  register: {
    account_type: "Typ konta",
    company_data: "Dane firmy",
    user_data: "Dane użytkownika",
    company: "Nazwa firmy",
    address: "Adres firmy",
    address_placeholder: "Wpisz adres firmy",
    city: "Miasto",
    city_placeholder: "Wpisz miasto",
    country: "Kraj",
    country_placeholder: "Wpisz nazwę kraju",
    zipCode: "Kod pocztowy",
    zipCode_placeholder: "Wpisz kod pocztowy",
    nip: "NIP",
    nip_placeholder: "Wpisz NIP",
    company_placeholder: "Wpisz nazwę firmy",
    address: "Adres firmy",
    zip_code: "Kod pocztowy",
    nip: "NIP",
    email: "Email",
    email_placeholder: "Wpisz Email",
    name: "Imię",
    name_placeholder: "Wpisz Imię",
    phone: "Numer telefonu",
    phone_placeholder: "Wpisz numer telefonu",
    surname: "Nazwisko",
    surname_placeholder: "Wpisz Nazwisko",
    user_role: "Jestem",
    password: "Hasło",
    password_placeholder: "Wpisz Hasło",
    confirm_password: "Potwierdź hasło",
    confirm_password_placeholder: "Potwierdź hasło",
    regulations_acceptance_first: "Akceptuję warunki",
    regulations_acceptance_second: "regulaminu",
    personal_data_processing_agreement:
      "Wyrażam zgodę na przechowywanie moich danych osobowych",
    cancel: "Anuluj",
    create_account: "Utwórz konto",
    creating_account_in_progress: "Tworzenie konta...",
    error_while_creating_user:
      "Podczas tworzenia konta wystąpił błąd. Sprawdź dane i spróbuj ponownie.",
    success: "Konto zostało dodane!",
    email_verification:
      "Na podany adres email został wysłany link z potwierdzeniem adresu",
    verification_sent:
      "Dziękujemy za rejestrację. Twoje konto wkrótce zostanie zweryfikowane przez administratora.",
    verification_link_sent:
      "Na Twój adres e-mail został wysłany link weryfikacyjny. Kliknij w ten link, aby potwierdzić konto i móc się zalogować.",
    validation: {
      string: "Pole musi zawierać ciąg znaków",
      required: "Pole jest wymagane",
      email: "Pole musi zawierać poprawny adres email",
      max: "Pole może zawierać maksymalnie {number} znaków",
      password:
        "Hasło powinno zawierać: min. 8 znaków, min. 1 duża litera, min. 1 mała litera, min.1 znak specjalny",
      passwordConfirmation: "Hasła muszą być takie same",
      regulationsAcceptance: "Musisz zaakceptować regulamin",
      dataProcessing: "Musisz wyrazić zgodę na przetwarzanie danych osobowych",
      userRole: "Wybrano niepoprawną role",
      phone: "Niepoprawny numer telefonu",
      name: "Imię i nazwisko nie mogą być puste",
      wrongName: "Niepoprawne imię",
      wrongSurname: "Niepoprawne nazwisko",
      wrongCity: "Niepoprawne miasto",
      wrongCountry: "Niepoprawny kraj",
      allRequired: "Wypełnij wszystkie pola",
      onlyNumberInWeight: "Waga musi się składać z samych cyfr",
      onlyNumberInVolume: "Waga musi się składać z samych cyfr",
      onlyNumberInPalltesAmount: "Waga musi się składać z samych cyfr",
    },
  },
  forgot_password: {
    reset_password_title: "Zresetuj hasło",
    text:
      " Jeśli zapomniałeś/łaś hasła do swojego konta, podaj swój e-mail.\n" +
      "                Wyślemy Ci wiadomość, aby zresetować hasło.",
    enter_email_placeholder: "Wprowadź email",
    sending: "Wysyłanie...",
    send_button: "Wyślij",
    back_button: "Wstecz",
    success_text:
      "Wysłaliśmy instrukcje dotyczące zresetowania hasła na twój adres e-mail. Jeśli nie otrzymałeś wiadomości, sprawdź folder ze spamem i upewnij się, że ten adres e-mail jest zarejestrowany na stronie internetowej Logiplanner.",
    resend_email_again: "Wyślij wiadomość ponownie",
    still_no_email: "Nadal nie masz wiadomości?",
    success_send_again: "Pomyślnie wysłano wiadomość ponownie",
    fail_send_again:
      "Wiadomość została wysłana. Jeżeli ponownie jej nie otrzymałeś, upewnij się, że jesteś zarejestrowany w systemie.",
  },
  reset_password: {
    title: "Zresetuj hasło",
    verification_code: "Kod weryfikacyjny",
    verification_code_placeholder: "Wpisz kod weryfikacyjny",
    password: "Nowe hasło",
    password_placeholder: "Wpisz nowe hasło",
    confirm_password: "Potwierdź hasło",
    confirm_password_placeholder: "Potwierdź nowe hasło",
    change_password: "Zmień hasło",
    changing_password_in_progress: "Zmiana hasła...",
    ready: "Gotowe!",
    password_changed: "Hasło zostało poprawnie zmienione.",
    click_to_go_to_login: "Kliknij, aby przejść do okna logowania",
    invalid_code:
      "Link do zmiany hasła wygasł. Wygeneruj nowy i spróbuj ponownie.",
  },
  profile: {
    my_profile_title: "Mój profil",
    close_edit_title: "Zamknij edycję",
    change_image_title: "Zmień zdjęcie",
    remove_image: "Usuń",
    specjalizations: "Specjalizacje",
    add_new_tag: "Dodaj nowy tag",
    enter_tag_placeholder: "Wpisz tag",
    my_profile_edit_title: "Edytuj profil",
    specialization_title: "Specjalizacje",
    description_title: "Opis",
    basic_company_data_title: "Dane podstawowe firmy",
    button_label: "Zaktualizuj informacje",
    updating: "Aktualizowanie",
    enter_link_placeholder: "Wpisz link do profilu",
    choose_label: "Wybierz",
    your_profile: "Twój profil",
    role: {
      client: "Klient",
      admin: "Kierownik",
      driver: "Przewoźnik",
      warehouse: "Magazyn",
      factory: "Producent",
      user: "Uzytkownik",
      owner: "Administrator",
      worker: "Pracownik",
      administrator: "Kierownik",
      auta_solowe: "Auta solowe",
      ciągniki_siodłowe: "Ciągniki siodłowe",
      tandem: "Tandem",
      samochod: "SAMOCHÓD",
      przyczepa: "PRZYCZEPA",
      naczepa: "NACZEPA",
      loading: "Załadowanie",
      reloading: "Rozładowanie",
    },
    statuses: {
      waiting_for_acceptance: "Czeka na autoryzację",
      invitation_sent: "Czeka na autoryzację",
      activated: "Zautoryzowany",
      blocked: "Zablokowany",
      deleted: "Usunięty",
      pending: "Oczekujący",
      rejected: "Odrzucony",
      accepted: "Zautoryzowany",
      all: "Wszystkie",
      finished: "Zarchiwizowany",
      multiple_blocked: "Zablokowani",
      multiple_rejected: "Odrzuceni",
    },
    company: {
      my_company: "Moja firma",
      edit: "Edytuj dane firmy",
      basic_data: "Dane podstawowe firmy",
      name: "Nazwa firmy",
      address: "Adres firmy",
      city: "Miasto",
      zip_code: "Kod pocztowy",
      nip: "NIP",
      regon: "Regon",
      province: "Województwo",
      update_success: "Pomyślnie zaktualizowano dane",
      addWorker: "Pomyślnie wysłano zaproszenie",
      avatar: "Zdjęcie profilowe firmy",
      galery: "Galeria firmy",
      showGalery: "Kliknij, aby zobaczyć całą galerię",
      emptyCompany: "Nie jesteś zapisany do żadnej firmy",
      administrator_amount: "Ilość administratorów",
      department_amount: "Ilość oddziałów",
      you_dont_own_departments: "Nie posiadasz żadnych oddziałów",
      validation: {
        compnay: "Nazwa firmy musi być ciągiem znaków",
        address: "Adres musi byc ciągiem znaków",
        zip_code: "Niepoprawny kod pocztowy. Min. ilość znaków to 5. Maks. ilość znaków to 10.",
        zip_code_pl:"Niepoprawny kod pocztowy. Poprawny format to XX-XXX.",
        max_nip: "NIP musi składać się z 10 cyfr",
        regon_only_number: "Regon musi być ciągiem cyfr",
        max_regon: "Regon musi składać się z 9 cyft",
        authorizationError: "Brak uprawnień do edycji",
      },
    },
    forgot_password: {
      reset_password_title: "Zresetuj hasło",
      text:
        " Jeśli zapomniałeś/łaś hasła do swojego konta, podaj swój e-mail,\n" +
        "                wyślemy Ci wiadomość, aby zresetować hasło.",
      enter_email_placeholder: "Wprowadź email",
      sending: "Wysyłanie...",
      send_button: "Wyślij",
      back_button: "Wstecz",
      success_text:
        "Wysłaliśmy instrukcje dotyczące zresetowania hasła na twój adres e-mail. Jeśli nie otrzymałeś wiadomości, sprawdź folder ze spamem i upewnij się, że ten adres e-mail jest zarejestrowany na stronie internetowej Logiplanner.",
      resend_email_again: "Wyślij wiadomość ponownie",
      still_no_email: "Nadal nie masz wiadomości?",
      success_send_again: "Pomyślnie wysłano wiadomość ponownie",
      fail_send_again:
        "Wiadomość została wysłana. Jeżeli ponownie jej nie otrzymałeś, upewnij się, że jesteś zarejestrowany w systemie.",
    },
    reset_password: {
      title: "Zresetuj hasło",
      verification_code: "Kod weryfikacyjny",
      verification_code_placeholder: "Wpisz kod weryfikacyjny",
      password: "Nowe hasło",
      password_placeholder: "Wpisz nowe hasło",
      confirm_password: "Potwierdź hasło",
      confirm_password_placeholder: "Potwierdź nowe hasło",
      change_password: "Zmień hasło",
      changing_password_in_progress: "Zmiana hasła...",
      ready: "Gotowe!",
      password_changed: "Hasło zostało poprawnie zmienione.",
      click_to_go_to_login: "Kliknij, aby przejść do okna logowania",
      invalid_code:
        "Link do zmiany hasła wygasł. Wygeneruj nowy i spróbuj ponownie.",
    },
    profile: {
      my_profile_title: "Mój profil",
      close_edit_title: "Zamknij edycję",
      change_image_title: "Zmień zdjęcie",
      remove_image: "Usuń",
      specjalizations: "Specjalizacje",
      add_new_tag: "Dodaj nowy tag",
      enter_tag_placeholder: "Wpisz tag",
      my_profile_edit_title: "Edytuj profil",
      specialization_title: "Specjalizacje",
      description_title: "Opis",
      basic_company_data_title: "Dane podstawowe firmy",
      button_label: "Zaktualizuj informacje",
      updating: "Aktualizowanie",
      enter_link_placeholder: "Wpisz link do profilu",
      choose_label: "Wybierz",
  
      role: {
        client: "Klient",
        admin: "Administrator",
        driver: "Przewoznik",
        warehouse: "Magazyn",
        factory: "Producent",
      },
      company: {
        my_company:"Moja firma",
        edit:"Edytuj dane firmy",
        basic_data: "Dane podstawowe firmy",
        name: "Nazwa firmy",
        address: "Adres firmy",
        city: "Miasto",
        zip_code: "Kod pocztowy",
        nip: "NIP",
        regon: "Regon",
        province: "Województwo",
        update_success: "Pomyślnie zaktualizowano dane",
        emptyCompany: "Nie jesteś zapisany do żadnej firmy",
        validation: {
          compnay: "Nazwa firmy musi być ciągiem znaków",
          address: "Adres musi byc ciągiem znaków",
          zip_code: "Niepoprawny kod pocztowy. Poprawny format to: xx-xxx",
          max_nip: "NIP musi składać się z 10 cyfr",
          regon_only_number: "Regon musi być ciągiem cyfr",
          max_regon: "Regon musi składać się z 9 cyft",
        },
      },
      personal_data: {
        basic_data: "Twoje dane podstawowe",
        firstname: "Imię",
        enter_firstname: "Wpisz imię",
        lastname: "Nazwisko",
        enter_lastname: "Wpisz nazwisko",
        email: "E-mail",
        enter_email: "Wpisz e-mail",
        phone: "Numer telefonu",
        enter_phone: "Wpisz numer telefonu",
        second_phone: "Numer telefonu 2",
        enter_second_phone: "Wpisz numer telefonu 2",
        update_info: "Zaktualizuj informacje",
        updating_info: "Aktualizowanie...",
      },
      validation: {
        wrong_url: "Niepoprawny adres url",
      },
      add_user: {
        list:"Lista pracowników",
        title: "Dodawanie nowego pracownika",
        added_worker:"Poprawnie dodano nowego pracownika",
        email_placeholder:"Wpisz adres email pracownika",
        message:"Wiadomość (opcjonalne)",
        name: "Imię i nazwisko",
        email: "Adres email",
        phone: "Numer telefonu",
        add: "Dodaj pracownika",
        adding: "Dodawanie...",
        type: "Rodzaj użytkownika",
        admin: "Administrator",
        status: "Status użytkownika",
        active: "Aktywny",
      },
      add_warehouse:{
        list:"Lista magazynów",
        added_warehouse:"Poprawnie dodano nowy magazyn",
        title:"Dodawanie nowego magazynu",
        name:"Nazwa",
        name_placeholder:"Wpisz nazwę magazynu",
        address:"Adres magazynu",
        city:"Miejscowość",
        city_placeholder:"Wpisz miasto",
        street:"Ulica i numer",
        street_placeholder:"Wpisz adres magazynu",
        zipCode:"Kod pocztowy",
        zipCode_placeholder:"Wpisz kod pocztowy",
        country_code:"Kod kraju",
        country_code_placeholder:"Wpisz kod kraju",
        email_placeholder:"Wpisz adres email magazynu",
        phone_placeholder:"Wpisz numer telefonu magazynu",
        type:"Typ autoryzacji",
        automatic:"Automatyczna",
        add:"Dodaj magazyn",
        adding:"Dodawanie magazynu..."
  
      },
      add_documents:{
        title:"Dodanie nowego dokumentu",
        select:"Wskaż plik do wysłania ( max. 4 Mb )",
        desc:"Krótki opis dokumentu ( max. 200 znaków )",
        visible:"Widoczne dla wszystkich",
        adding:"Ładowanie dokumentu...",
        add:"Dodaj dokument"
      },
      calendar:{
        warehouse:"Magazyn",
        name:"Nazwa magazynu",
        fill:"Uzupełnij kalendarz",
        taken:"Zajęte",
        time_windows:"Okna czasowe",
        slots:"Liczba udostępnionych slotów",
        from:"Magazyn dostępny od:",
        to:"Magazyn dostępny do:",
        delete:"Usuń okno czasowe",
        show_monitary:"Pokaż awizację",
        monitary_id:"ID awizacji",
        monitary_time:"Czas awizacji",
        driver:"Dane kierowcy",
        add:"Dodaj okno czasowe",
        creating:"Tworzenie kalendarza",
        for_month:"Na miesiąc:",
        weekday:"Dzień tygodnia",
        interval:"Interwał między awizacjami",
        interval_short:"Interwał (minuty)"
  
      },
      languages:{
        title:"Changing language",
        current:"Current language:"
      },
    personal_data: {
      basic_data: "Twoje dane podstawowe",
      firstname: "Imię",
      enter_firstname: "Wpisz imię",
      lastname: "Nazwisko",
      enter_lastname: "Wpisz nazwisko",
      email: "E-mail",
      enter_email: "Wpisz e-mail",
      phone: "Numer telefonu",
      enter_phone: "Wpisz numer telefonu",
      second_phone: "Numer telefonu 2",
      enter_second_phone: "Wpisz numer telefonu 2",
      update_info: "Zaktualizuj informacje",
      updating_info: "Aktualizowanie...",
    },
    validation: {
      wrong_url: "Niepoprawny adres url",
    },
    add_user: {
      list: "Lista użytkowników",
      title: "Dodawanie nowego pracownika",
      added_worker: "Poprawnie dodano nowego pracownika",
      email_placeholder: "Wpisz adres email pracownika",
      message: "Wiadomość (opcjonalne)",
      name: "Imię i nazwisko",
      email: "Adres email",
      phone: "Numer telefonu",
      add: "Dodaj pracownika",
      adding: "Dodawanie...",
      type: "Rodzaj użytkownika",
      admin: "Kierownik",
      owner: "Administrator",
      status: "Status użytkownika",
      active: "Aktywny",
      worker: "Pracownik",
    },
    add_warehouse: {
      list: "Lista magazynów",
      added_warehouse: "Poprawnie dodano nowy magazyn",
      title: "Dodawanie nowego magazynu",
      edit_title: "Edycja magazynu",
      name: "Nazwa",
      name_placeholder: "Wpisz nazwę magazynu",
      address: "Adres magazynu",
      city: "Miejscowość",
      city_placeholder: "Wpisz miasto",
      street: "Ulica i numer",
      street_placeholder: "Wpisz adres magazynu",
      zipCode: "Kod pocztowy",
      zipCode_placeholder: "Wpisz kod pocztowy",
      country_code: "Kod kraju",
      country_code_placeholder: "Wpisz kod kraju",
      email_placeholder: "Wpisz adres email magazynu",
      phone_placeholder: "Wpisz numer telefonu magazynu",
      type: "Typ autoryzacji",
      automatic: "Automatyczna",
      add: "Dodaj magazyn",
      adding: "Dodawanie magazynu...",
      delete_complete: "Poprawnie usunięto magazyn",
      edit_complete: "Pomyślnie edytowano magazyn",
      invitation_sent: "Wysłano zaproszenie",
    },
    add_department: {
      list: "Lista oddziałów",
      added_warehouse: "Poprawnie dodano nowy oddział",
      title: "Dodawanie nowego oddziału dla spedytorów",
      edit_title: "Edycja oddziału",
      name: "Nazwa",
      name_placeholder: "Wpisz nazwę oddziału",
      address: "Adres oddziału",
      city: "Miejscowość",
      city_placeholder: "Wpisz miasto",
      street: "Ulica i numer",
      street_placeholder: "Wpisz adres oddziału",
      zipCode: "Kod pocztowy",
      zipCode_placeholder: "Wpisz kod pocztowy",
      country_code: "Kod kraju",
      country_code_placeholder: "Wpisz kod kraju",
      email_placeholder: "Wpisz adres email oddziału",
      phone_placeholder: "Wpisz numer telefonu oddziału",
      type: "Typ autoryzacji",
      automatic: "Automatyczna",
      add: "Dodaj oddział dla spedytorów",
      adding: "Dodawanie oddziału...",
      delete_complete: "Poprawnie usunięto oddział",
      edit_complete: "Pomyślnie edytowano oddział",
    },
    add_car: {
      title: "Dodawanie nowego auta",
      edit_title: "Edycja auta",
      name: "Marka",
      name_placeholder: "Wpisz markę samochodu",
      trailerNumber: "Numer naczepy",
      trailerNumber_placeholder: "Wpisz numer naczepy",
      registrationNumber: "Numer rejestracyjny",
      registrationNumber_placeholder: "Wpisz numer rejestracyjny",
      type: "Typ auta",
      type_placeholder: "Wpisz typ auta",
      add: "Dodaj auto ",
      adding: "Dodawanie auta...",
      delete_complete: "Poprawnie usunięto auto",
      edit_complete: "Pomyślnie edytowano auto",
      add_complete: "Pomyślnie dodano",
      pick_department_to_add_car:
        "Wybierz oddział, do którego chcesz przypisać pojazd",
    },
    add_solo_cars: {
      title: "Dodawanie nowego auta solowego",
      edit_title: "Edycja auta solowego",
      name: "Marka",
      name_placeholder: "Wpisz markę auta solowego",
      registrationNumber: "Numer rejestracyjny",
      registrationNumber_placeholder: "Wpisz numer rejestracyjny",
      add: "Dodaj auto ",
      adding: "Dodawanie auta...",
    },
    add_truck: {
      title: "Dodawanie nowego ciągnika siodłowego",
      edit_title: "Edycja ciągnika siodłowego",
      name: "Marka",
      name_placeholder: "Wpisz markę ciągnika siodłowego",
      registrationNumber: "Numer rejestracyjny",
      registrationNumber_placeholder: "Wpisz numer rejestracyjny",
      add: "Dodaj ciągnik ",
      adding: "Dodawanie ciągnika...",
    },
    add_przyczepa: {
      title: "Dodawanie nowej przyczepy",
      edit_title: "Edycja przyczepy",
      name: "Nazwa",
      name_placeholder: "Wpisz nazwę przyczepy",
      trailerNumber: "Numer przyczepy",
      trailerNumber_placeholder: "Wpisz numer przyczepy",
      add: "Dodaj przyczepę ",
      adding: "Dodawanie przyczepy...",
      delete_complete: "Poprawnie usunięto przyczepę",
      edit_complete: "Pomyślnie edytowano przyczepę",
      add_complete: "Pomyślnie dodano przyczepę",
      registrationNumber: "Numer rejestracyjny",
      registrationNumber_placeholder: "Wpisz numer rejestracyjny",
    },
    add_naczepa: {
      title: "Dodawanie nowej naczepy",
      edit_title: "Edycja naczepy",
      name: "Nazwa",
      name_placeholder: "Wpisz nazwę naczepy",
      trailerNumber: "Numer naczepy",
      trailerNumber_placeholder: "Wpisz numer naczepy",
      add: "Dodaj naczepę ",
      adding: "Dodawanie naczepy...",
      delete_complete: "Poprawnie usunięto naczepę",
      edit_complete: "Pomyślnie edytowano naczepę",
      add_complete: "Pomyślnie dodano naczepę",
      registrationNumber: "Numer rejestracyjny",
      registrationNumber_placeholder: "Wpisz numer rejestracyjny",
    },
    list_vehicles: {
      auta_solowe: "Pokaż auta solowe dla: ",
      ciagniki_siodlowe: "Pokaż ciągniki siodłowe dla:",
      przyczepa: "Pokaż przyczepy dla: ",
      naczepa: "Pokaż naczepy dla: ",
    },
    list_auth: {
      accepted: "Zaakceptowane autoryzacje",
      pending: "Oczekujące autoryzacje",
      company: {
        name: "Nazwa firmy",
      },
      department: {
        count: "Liczba oddziałów",
        show: "Oddziały firmy",
        name: "Nazwa oddziału",
        empty_departments: "Brak oddziałów",
      },
    },
    add_documents: {
      title: "Dodanie nowego dokumentu",
      select: "Wskaż plik do wysłania ( max. 4 Mb )",
      desc: "Krótki opis dokumentu ( max. 200 znaków )",
      visible: "Widoczne dla wszystkich",
      adding: "Ładowanie dokumentu...",
      add: "Dodaj dokument",
      file_size_error: "Za duży rozmiar pliku",
      no_file_error: "Nie zamieszczono żadnego pliku bądź jego opisu",
      success: "Dokument został dodany",
      no_access: "Nie masz dostępu do tego typu dokumentu",
      file_type_error: "Nieodpowiedni format pliku",
    },
    calendar: {
      warehouse: "Magazyn",
      name: "Nazwa magazynu",
      fill: "Uzupełnij kalendarz",
      fill_department_calendar: "Uzupełnij kalendarz dla oddziału",
      taken: "Zajęte",
      time_windows: "Okna czasowe",
      monitory_notes: "Awizacje",
      authorizations: "Autoryzacje",
      archive: "Archiwum",
      calendar: "Kalendarz",
      settings: "Ustawienia",
      loads: "Giełda ładunków",
      tenders: "Przetargi",
      workers: "Pracownicy",
      documents: "Dokumenty",
      warehouses: "Magazyny",
      admin_panel: "Panel administratora",
      companies: "Firmy",
      change_company: "Zmień aktywną firmę",
      logout: "Wyloguj się",
      your_companies: "Twoje firmy",
  
      admin: {
        users: "Użytkownicy",
        users_logiplanner: "Logiplanner",
      slots: "Liczba udostępnionych slotów",
      from: "Magazyn dostępny od:",
      to: "Magazyn dostępny do:",
      delete: "Usuń okno czasowe",
      show_monitary: "Pokaż awizacje",
      monitary_id: "ID awizacji",
      company_id: "ID Firmy",
      worker_id: "ID Pracownika",
      give_additional_monitory_note_id:
        "Nadaj dodatkowe ID awizacji (opcjonalne)",
      confirm_monitory_note_rejection: "Czy na pewno chcesz odrzucić awizację?",
      monitary_time: "Czas awizacji",
      driver: "Dane kierowcy",
      add: "Dodaj okno czasowe",
      creating: "Tworzenie kalendarza",
      for_month: "Na miesiąc:",
      weekday: "Dzień tygodnia",
      interval: "Interwał między awizacjami(w minutach)",
      interval_short: "Interwał (minuty)",
      block_monitary: "Brak możliwości awizacji",
      limitless_monitary: "Nielimitowana możliwość awizacji",
      add_succes: "Poprawnie dodano kalendarz",
      edit_succes: "Poprawnie zedytowano okna czasowe",
      add_error: "Błąd podczas dodawania kalendarza",
      add_data_missing: "Błędnie uzupełnione dane",
      fill_all_boxes: "Uzupełnij wszystkie pola",
      correctly_fill_all_boxes: "Poprawnie uzupełnij wszystkie pola",
      create_calendar: "Utwórz kalendarz",
      monitory_note_calendar: "Kalendarz awizacji",
      emptyCalendar: "Brak kalendarza na dany miesiąc",
    },
    document: {
      empty_company: "Brak dokumentów firmy",
      index: "Lp.",
      originalname: "Nazwa pliku",
      description: "Opis pliku",
      createdAt: "Dodany dnia",
      addedBy: "Dodany przez",
      visible: "Widoczny",
      action: "Akcja",
      title: "Dokumenty",
    },
    stock: {
      offerType: "Typ oferty",
      offer: "Oferta",
      priceFrom: "Wyliczona cena",
      bindingFrom: "Wiążące od",
      bindingTo: "Wiążące do",
      comment: "Komentarz do oferty",
      given: "Podano",
      givenFrom: "Podano przez",
      date: "Data",
      loadingType: "Typ załadunku",
      loading: "Załadunek",
      unloading: "Rozładunek",
      companyName: "Nazwa firmy",
      location: "lokalizacja",
      loadingPoints: "Jednostki załadunkowe",
      loadingPlaces: "Poszczególne miejsca załadunku",
      price: "Cena",
      placeOfDisposal: "Miejsce dyspozycji",
      disposal: "Dysponent",
      car: "Pojazd",
      nrTransport: "nr transportu",
      lastModifiedDate: "Data ostatniej modyfikacji",
      idTransporeon: "Transporeon-ID",
      idLogiPlanner: "Id logiplanner",
      idShipper: "Id załadowcy",
      category: "Kategoria wagowa (kg)",
      shipperName: "Nazwa załadowcy",
      shipper: "Załadowca",
      users: "Wszyscy podwykonawcy",
      delivers: "Dostawy",
      typeOfOrder: "Rodzaj zlecenia",
      quot: "%quoty",
      loadingDate: "Data załadunku",
      unloadlingDate: "Data rozładunku",
      deadline: "Termin",
      lastModification: "Data ostatniej modyfikacji",
      loadingCity: "Miasto załadunku",
      postCode: "Kod pocztowy",
      country: "Kraj",
      unLoadingCity: "Miasto rozładunku",
      carType: "Typ pojazdu",
      weight: "Waga załadunku",
      weightT: " Waga załadunku (t)",
      volumeM: "Objętość załadunku m3",
      volume: "Objętość",
      length: "Długość załadunku",
      lengthM: "Długość załadunku (m)",
      remarks: "Uwagi",
      canceled_transports: "Anulowane transporty",
      cancel_order: "Anuluj zlecenie",
      end_order: "Zakończ zlecenie",
      orders_archive: "Archiwum zleceń",
      cancelled_orders: "Zlecenia anulowane",
      comment: "Komentarz do transportu",
      selected_transports: "Przydzielone transporty",
      unreceived_transports: "Nieotrzymane transporty",
      created_groups: "Utworzone grupy",
      extra_charges: "Dopłata",
      bid: {
        referencePrice: "Cena referencyjna transportu",
        takeReferencePrice: "Użyj ceny referencyjnej",
        type: "Typ oferty",
        offer: "Oferta",
        price: "Wyliczona cena",
        dateRange: "Wiążące od",
        comment: "Komentarz do oferty",
        commetPlaceholder:
          "Komentarz do oferty - widoczny dla przewoźnika i załadowcy",
        submit: "Złóż ofertę",
        cancel: "Anuluj",
      },
      deliverInfo: {
        loadingPlace: "Miejsce załadunku",
        weight: "Wymiary i wagi",
        unloadingPlace: "Miejsce rozładunku",
        other: "Inne",
        deliver: "Dostawa",
        weightWord: "Waga",
        lengthWord: "Długość",
      },
    },
    languages: {
      title: "Zmiana języka aplikacji",
      current: "Aktualnie wybrany język:",
    },
    listing_departments: {
      title: "Lista dostępnych magazynów",
      title_drivers: "Lista dostępnych przewoźników",
      authorize: "Wyślij prośbę o autoryzację",
    },
  },
  profile_settings: {
    settings: "Ustawienia",
    password_change: "Zmiana hasła",
    password_change_success: "Pomyślnie zmieniono hasło",
    current_password: "Obecne hasło",
    current_password_placeholder: "Wpisz obecne hasło",
    new_password: "Nowe hasło",
    new_password_placeholder: "Wpisz nowe hasło",
    confirm_password: "Potwierdź hasło",
    confirm_password_placeholder: "Potwierdź hasło",
    update_information: "Zaktualizuj informacje",
    two_step_verification: "Weryfikacja dwuetapowa",
    two_step_verification_desc:
      "Dodatkowo zabezpiecz swoje konto dzięki drugiemu etapowi logowania.",
    two_step_verification_active: "Włączona",
    two_step_verification_inactive: "Wyłączona",
    select_verification_method: "Wybierz metodę weryfikacji",
    privacy_settings: "Ustawienia prywatności",
    receiving_notifications_about_forum_threads:
      "Nie chcę otrzymywać powiadomień o wątkach na forum",
    receiving_notifications_about_comments:
      "Nie chcę dostawać powiadomień o komentarzach do moich inspiracji",
    commenting_user_projects_by_others:
      "Nie zezwalam na komentowanie moich inspiracji przez innych użytkowników",
    receiving_notifications_of_ratings_by_others:
      "Nie chcę dostawać powiadomień o swojej ocenie przez innych użytkowników",
    receiving_messages_from_others_users:
      "Nie chcę otrzymywać wiadomości od innych użytkowników portalu (nie dotyczy administratora)",
    profile_indexing:
      "Nie zezwalam na indeksowanie mojego profilu, projektów, inspiracji przez wyszukiwarkę",
    add_user_request: "Czy chcesz zaakceptować zaproszenie?",
    invitation_accepted: "Zaproszenie zostało zaakceptowanie",
    invitation_rejected: "Zaproszenie zostało odrzucone",
    invitation_processed: "Zaproszenie przetworzone",
    click_to_go_to_main_page: "Kliknij, aby przejść do strony głównej",
  },
  monitary_notes: {
    title: "Kreator nowej awizacji",
    step_one_title: "Wybór rodzaju i miejsca operacji",
    step_one_two: "Wybór daty i godziny podstawienia",
    step_one_three: "Podanie informacji o ładunku",
    operation: "Rodzaj operacji",
    department: "Wybierz oddział, z którego chcesz się awizować.",
    warehouse: "Wybierz magazyn, do którego chcesz się awizować",
    next: "Dalej",
    warehouse_chosen: "Wybrany magazyn",
    hour: "Wybierz godzinę podstawienia",
    save: "Zapisz awizację",
    cargoType: "Rodzaj ładunku",
    weight: "Waga ładunku (w kg)",
    capacity: "Objętość ładunku (w m³)",
    numberOfPallets: "Liczba palet",
    numberOfDocument: "Numer dokumentu",
    additionalDescription: "Dodatkowy opis",
    tractorNumber: "Numer ciągnika",
    semitrailerNumber: "Numer naczepy",
    name: "Imię i Nazwisko kierowcy",
    driverDocument: "Numer dokumentu kierowcy",
    driverPhone: "Numer telefonu kierowcy",
    pick_car: "Wybierz auto",
    pick_trailor: "Wybierz przyczepę",
    pick_semitrailor: "Wybierz naczepę",
    monitary_note_added: "Dodano awizację",
    status_changed: "Zmieniono status",
    monitary_notes_archive: "Archiwum awizacji",
    monitary_note_changed: "Edytowano awizację",
    monitory_notes_list: "Lista awizacji na dane okno czasowe",
    empty_monitory_notes: "Brak awizacji",
    show_monitary_for: "Pokaż awizacje dla",
    errors: {
      no_hour: "Zaznacz godzinę podstawienia",
    },
  },
  tableData: {
    originalname: "Nazwa pliku",
    visible: "Widoczny publicznie",
    action: "Akcje",
    email: "Adres email",
    firstName: "Imię",
    lastName: "Nazwisko",
    role: "Rola",
    admin: "Kierownik",
    worker: "Pracownik",
    owner: "Administrator",
    administrator: "Kierownik",
    modalConfirmationUser: "Wybierz akcję, jaką chcesz wykonać na użytkowniku:",
    modalConfirmationCompany: "Wybierz akcję, jaką chcesz wykonać na firmie:",
    modalConfirmationDepartment:
      "Wybierz akcję, jaką chcesz wykonać na oddziale:",
    modalConfirmationAuthorization:
      "Wybierz akcję, jaką chcesz wykonać na autoryzacji:",
    modalConfirmationCars: "Wybierz akcję, jaką chcesz wykonać na aucie:",
    nip: "Nip",
    description: "Opis",
    id: "ID",
    companyId: "ID",
    companyName: "Nazwa firmy",
    companyCity: "Miasto",
    companyAddress: "Adres",
    companyZipCode: "Kod pocztowy",
    companyNip: "NIP",
    companyPhone: "Numer telefonu",
    userPhone: "Numer telefonu",
    userCompany: "Rola w firmie",
    companyOwners: "Właściciele firmy",
    createdAt: "Utworzona",
    modifiedAt: "Ostatnia modyfikacja",
    addresses: "Adresy firmy",
    warehouse: "Magazyn",
    driver: "Przewoźnik",
    factory: "Producent",
    name: "Nazwa",
    surname: "Nazwisko",
    address: "Adres",
    city: "Miasto",
    countryCode: "Kod kraju",
    isAutomatic: "Automatyczna autoryzacja",
    zipCode: "Kod pocztowy",
    phone: "Numer telefonu",
    managers: "Kierownicy",
    avatar: "Zdjęcie profilowe",
    ownerInCompanies: "Właściciel firm:",
    status: "Status",
    type: "Typ",
    departmentPhone: "Numer telefonu",
    departmentAvatar: "Zdjęcie",
    department: "Oddział autoryzujący",
    trailerNumber: "Numer przyczepy",
    semitrailerNumber: "Numer naczepy",
    registrationNumber: "Numer rej.",
    docType: "Typ dokumentu",
    carType: "Rodzaj samochodu",
    isDeleted: "Czy usunięty",
    subId: "ID",
    incoming: "Przychodzące",
    carrier: "Przewoznik",
    monitaryDate: "Data awizacji",
    warehouseMonitoryNoteId: "Osobisty numer awizacji",
    monitoryNoteNumberInWarehouse: "Nr awizacji",
    company: "Firma",
  },
  validate: {
    email: "Podany email jest nieprawidłowy",
    required: "Pole jest wymagane",
    empty: "Pole nie może być puste.",
    wrong_city: "Błędna nazwa miasta",
    password:
      "Hasło powinno zawierać: min. 8 znaków, min. 1 duża litera, min. 1 mała litera, min.1 znak specjalny",
    passwordConfirmation: "Hasła muszą być takie same",
    min_number: "Wartość musi być większa od {number}",
  },
  error_codes: {
    "000": "Użytkownik nie istnieje",
    "001": "Nieprawidłowe dane logowania",
    "002": "Podany email istnieje już w systemie",
    "003": "Błędny token",
    "004": "Błędne zapytanie", //Bad request
    "005": "Użytkownik jest już zarejestrowany za pomocą platformy google",
    "006": "Użytkownik jest już zarejestrowany za pomocą platformy facebook",
    "007": "Błędny kod weryfikacyjny",
    "008": "Nie znaleziono koszyka",
    "009": "Konto niezweryfikowane. E-mail z kodem został wysłany.",
    "010": "Produkt został już dodany do ulubionych",
    "011": "Obiekt już istnieje",
    "012": "Błędne hasło",
    "013": "@TODO KOD DO UZUPELNIENIA",
    "014": "Na podany adres email zostało już wysłane zaproszenie",
    401: "Brak autoryzacji",
    403: "Brak uprawnień do wykonania tej akcji",
    404: "Nie znaleziono obiektu",
    default: "Podczas przetważania żądania wystąpił błąd.",
  },
  verification: {
    title: "Weryfikacja konta",
    in_progress: "Prosimy o cierpliwość, trwa weryfikacja...",
    success: "Konto zostało zweryfikowane. Możesz się zalogować.",
    error_during_verification:
      "Podczas weryfikacji wystąpił błąd. Upewnij się, że link jest poprawny.",
  },

  page_not_found: {
    desc: "Niestety nie znaleziono strony, której szukasz. Sprawdź czy wprowadziłeś poprawny adres.",
    back: "Wróć do strony głównej",
  },
  alert: {
    successfully_saved_data: "Pomyślnie zapisano dane",
    successfully_updated_data: "Pomyślnie zaktualizowano dane",
    error_during_saving_data: "Błąd zapisu danych",
    error_during_updating_data: "Błąd aktualizacji danych",
  },
  menu: {
    dashboard: "Pulpit",
    my_company: "Moja firma",
    my_profile: "Mój profil",
    my_warehouses: "Moje oddziały",
    my_factories: "Moje magazyny",
    my_cars: "Moje auta",
    time_windows: "Okna czasowe",
    monitory_notes: "Awizacje",
    authorizations: "Autoryzacje",
    archive: "Archiwum",
    calendar: "Kalendarz",
    settings: "Ustawienia",
    loads: "Giełda ładunków",
    tenders: "Przetargi",
    workers: "Użytkownicy",
    documents: "Dokumenty",
    warehouses: "Oddziały",
    warehouseList: "Lista magazynów",
    warehouse_menagers: "Kierownicy magazywnu",
    admin_panel: "Panel administratora",
    companies: "Firmy",
    change_company: "Zmień aktywną firmę",
    logout: "Wyloguj się",
    your_companies: "Twoje firmy",
    drivers: "Przewoźnicy",
    drivers_groups: "Grupy przewoźników",
    drivers_list: "Lista przewoźników",
    job_list: "Wykaz zleceń",
    factoring_list: "Wykaz faktoringu",
    participants_list: "Wykaz uczestników",
    insurances: "Ubezpieczenia",
    auctions: "Licytacje",
    offers: "Oferty",
    lack_of_other_companies: "Brak innych firm",
    return: "Wróć",
    pickCompanyToConnect:
      "Z rozwijanej listy wybierz firmę, na którą chcesz się przełączyć.",
    admin: {
      users: "Użytkownicy",
      users_logiplanner: "Logiplanner",
      company: "Dane firmy",
    },
  },
  admin: {
    general: {
      back: "Wstecz",
      button_update_data_title: "Zaktualizuj informacje",
    },

    profile: {
      my_profile_title: "Mój profil",
      my_profile_settings_title: "Ustawienia",
      personal_image: "Zdjęcie główne",
      update_success: "Pomyślnie zaktualizowano dane",
      img_update_success: "Pomyślnie zaktualizowano zdjęcie",
      img_delete_success: "Pomyślnie usunięto zdjęcie",
      showGallery: "Pokaż galerię",
      address_title: "Adres",
      name: "Imię",
      enter_name: "Wpisz imię",
      surname: "Nazwisko",
      enter_surname: "Wpisz nazwisko",
      email: "E-mail",
      enter_email: "Wpisz e-mail",
      phone_number: `Numer Telefonu`,
      enter_phone_number: `Wpisz numer Telefonu`,
      street: "Ulica",
      enter_street: "Wpisz ulica",
      premises_number: "Numer lokalu",
      enter_premises_number: "Wpisz numer lokalu",
      apartment_number: "Numer mieszkania",
      enter_apartment_number: "Wpisz numer mieszkania",
      zip_code: "Kod pocztowy",
      enter_zip_code: "Wpisz kod pocztowy",
      city: "Miasto",
      enter_city: "Wpisz miasto",
      validation: {
        zip_code: "Niepoprawny kod pocztowy. Min. ilość znaków to 5. Maks. ilość znaków to 10.",
      },
      update_data_success: "Pomyślnie zaktualizowano dane profilowe",
      update_profile_img: {
        alert_success: "Pomyślnie zaktualizowano zdjęcie profilowe",
      },
      delete_profile_img: {
        alert_success: "Pomyślnie usunięto zdjęcie profilowe",
      },
      settings: {
        back: "Wstecz",
        my_profile: "Mój profil",
        settings: "Ustawienia",
        manage_account: "Zarządzaj swoim kontem",
        change_password: "Zmień hasło",
        close_dialog: "Zamknij",
        changing_password: "Zmiana hasła",
        old_password: "Stare hasło",
        old_password_placeholder: "Wpisz stare hasło",
        new_password: "Nowe hasło",
        new_password_placeholder: "Wpisz nowe hasło",
        confirm_password: "Potwierdź nowe hasło",
        cancel: "Anuluj",
        save: "Zapisz",
        yes: "Tak",
        no: "Nie",
        saving_changes_confirmation: "Czy na pewno chcesz zapisać zmiany?",
        update_data_success: "Pomyślnie zaktualizowano dane",
      },
    },
    users: {
      logiplanner: {
        title: "Logiplanner",
        add_user: "Dodaj użytkownika",
        firstname: "Imię",
        surname: "Nazwisko",
        function: "Funkcja",
        email: "Email",
        phone: "Telefon",
        change_user_data: "Zmień dane i uprawnienia użytkownika",
        access_blocked:
          "Dostęp dla tego użytkownika jest zablokowany, kliknij aby odblokować dostęp",
        delete_user: "Usuń użytkownika",
        loading: "Ładowanie...",
        status_changed: "Poprawnie zmieniono status",
        empty_users: "Brak użytkowników",
        add_user_modal: {
          dialog_close: "Zamknij",
          edit_data: "Edytuj dane użytkownika",
          firstname: "Imię",
          firstname_placeholder: "Wpisz imię",
          email: "Email",
          email_placeholder: "Wpisz email",
          surname: "Nazwisko",
          surname_placeholder: "Wpisz nazwisko",
          phone: "Telefon",
          phone_placeholder: "Wpisz telefon",
          cancel: "Anuluj",
          save: "Zapisz",
          saving: "Zapisywanie...",
        },
      },
    },
  },

  delete_client_modal: {
    dialog_close: "Zamknij",
    deleting_client: "Usuwanie Klienta",
    deleting_client_confirmation: "Czy na pewno chcesz usunąć klienta",
    cancel: "Anuluj",
    delete: "Usuń",
    deleting: "Usuwanie...",
    deleting_success: "Pomyślnie usunięto klienta",
  },
  months: {
    JAN: "Styczeń",
    FEB: "Luty",
    MAR: "Marzec",
    APR: "Kwiecień",
    MAY: "Maj",
    JUN: "Czerwiec",
    JUL: "Lipiec",
    AUG: "Sierpień",
    SEP: "Wrzesień",
    OCT: "Październik",
    NOV: "Listopad",
    DEC: "Grudzień",
  },
  days: {
    MON: "Poniedziałek",
    TUE: "Wtorek",
    WEN: "Środa",
    THUR: "Czwartek",
    FRI: "Piątek",
    SAT: "Sobota",
    SUN: "Niedziela",
  },
  generals: {
    error: "Błąd",
    return: "Powrót",
    unfold: "Rozwiń",
    delete: "Usuń",
    edit: "Edytuj",
    loading: "Wczytywanie...",
    authorizations: "Autoryzacje",
    monitary_notes: "Awizacje",
    opening: "Otwarcie",
    closing: "Zamknięcie",
    save: "Zapisz",
    saving: "Zapisuje...",
    authorize: "Autoryzuj",
    reject: "Odrzuć",
    block: "Zablokuj",
    see_details: "Zobacz",
    change_workers: "Zmień pracowników",
    add_pics: "Dodaj zdjęcia",
    add_avatar: "Dodaj zdjęcie",
    added_avatar: "Dodano awatar",
    true: "Tak",
    false: "Nie",
    actions: "Akcje",
    action: "Akcja",
    workers: "Pracownicy",
    fullName: "Imię i nazwisko",
    true: "Prawda",
    false: "Fałsz",
    yes: "Tak",
    no: "Nie",
    success: "Sukces",
    successful_edit: "Pomyślnie edytowano",
    archive: "Archiwizuj",
    accept: "Akceptuj",
    added: "Dodano",
    step: "Krok",
    download: "Pobierz",
    go_further: "Przejdź dalej",
    closed: "Zamknięte",
    logout: "Wyloguj",
    language: "Język",
    agreement: "Umowa",
    invoices: "Faktury",
    from: "Od",
    to: "Do",
    search: "Szukaj",
    phone: "phone",
  },
  addings: {
    adding: "Dodawanie",
    worker: "Dodaj pracownika",
    docs: "Dodaj dokumenty",
    department: "Dodaj oddział",
    warehouse: "Dodaj magazyn",
    car: "Dodaj auto",
    monitary: "Dodaj awizację",
    driver: "Dodaj przewoźnika",
    group: "Utwórz grupę",
    job: "Dodaj zlecenie",
    adding_documents: "Dodawanie dokumnetów",
    add_warehouse_menagers: "Dodaj kierowników magazynu",
  },
  removings: {
    remove_documnet_question: "Czy na pewno chcesz usunąć dokument?",
    remove_warehouse_question: "Czy na pewno chcesz usunąć magazyn?",
  },
  system: {
    system: "System",
    version: "Wersja systemu",
    client: "Numer klienta",
    company_name: "Nazwa firmy",
    name_surname: "Imię i nazwisko",
    operating_system: "System operacyjny",
    browser_version: "Wersja przeglądarki",
    browser_language: "Język przeglądarki",
    date: "Data",
  },
  car_types: {
    solo_cars: "Auta solowe",
    auta_solowe: "Auta solowe",
    tractor: "Ciągnik siodłowy",
    ciagniki_siodlowe: "Ciągnik siodłowy",
    tandem: "Tandem",
    solo_car: "Auto solowe",
    plain_tractor: "Ciągnik",
  },
  document_types: {
    file: "",
    nip: "NIP",
    regon: "REGON",
    krs_cdig: "KRS/CEIDG",
    transport_license: "(Licencja transportowa)",
    carrier_insurance: "(Ubezpieczenie przewoźnika)",
    license: "Licencja",
    insurance: "Ubezpieczenie",
  },
  statuses: {
    waiting: "Oczekujące",
    rejected: "Odrzucone",
    accepted: "Zaakceptowane",
    retreated: "Wycofane",
    isWaiting: "Oczekuje",
    multiple_accepted: "Zaakceptowani",
    all: "Wszystkie",
  },
  authorizations: {
    authorization_request_send: "Wysłano prośbę o autoryzację",
    authorization_success: "Pomyślnie zautoryzowano",
    authorization_rejected: "Autoryzacja odrzucona",
    authorization_error: "Brak autoryzacji",
    authorization_status: "Status autoryzacji",
    authorized: "Zautoryzowani",
    show_authorizations_for: "Pokaż autoryzacje dla",
    pick_department_to_authorization:
      "Wybierz oddział, który chcesz autoryzować z oddziałem",
  },
  auctions: {
    cancled_auctions: "Anulowane licytacje",
    waiting_auctions: "Oczekujące licytacje",
    selected_auctions: "Przydzielone aukcje",
  },
  demand: {
    accept_demand: "Przyjmij żądanie",
    reject_demand: "Odrzuć żądanie",
    cancel_demand: "Anuluj żądanie",
    send_demand: "Wyślij żądanie",
  },
  bank: {
    bank: "Bank",
    loan: "Kredyt",
    leasing: "Leasing",
    factoring: "Faktoring",
  },
  vehicle_alerts: {
    delete_vehicle_question: "Czy na pewno chcesz usunąć pojazd?",
    no_vehicles_for_category: "Brak pojazdów w danej kategorii",
  },
  archive: {
    show_archive_for: "Pokaż archiwum dla",
  },
  filters: {
    clear_filters: "Wyczyść filtry",
  },
  languages: {
    pl: "Polski",
    en: "Angielski",
  },
}}}
