<template>
  <div id="wrapper-of-content" class="wrapper">
    <div class="content-container">
      <stepper :activeStep="currentStep" />
      <step-one v-if="currentStep === 1" @next="currentStep = 2" />
      <step-two @back="currentStep--" @scroll="scrollToView" v-if="currentStep === 2" @next="currentStep = 3" />
      <step-three @back="currentStep--" @finalised="finaliseMonitary" v-if="currentStep === 3" />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import UserLayout from "@/layouts/UserLayout";
import Menu from "@/components/Menu";
import CustomPicker from "@/components/common/CustomPicker.vue";
import Stepper from "@/components/common/Stepper.vue";
import StepOne from "@/components/monitary/StepOne";
import StepTwo from "@/components/monitary/StepTwo.vue";
import StepThree from "@/components/monitary/StepThree.vue";
import router from "@/router";
export default {
  name: "AddMonitaryNoteView",
  components: {
    Menu,
    UserLayout,
    CustomPicker,
    Stepper,
    StepOne,
    StepTwo,
    StepThree,
  },
  setup() {
    const currentStep = ref(1);
    onMounted(() => {
      if (router.currentRoute.value.query.step) {
        currentStep.value = parseInt(router.currentRoute.value.query.step);
      }
    });

    const scrollToView = (element) => {
      var myElement = element;
      if (myElement !== null) {
        var topPos = myElement.offsetTop;
        document.getElementById("wrapper-of-content").scrollTo({ top: topPos, behavior: "smooth" });
      }
    };

    const finaliseMonitary = () => {
      router.push({ name: "list-monitary" });
    };

    return {
      scrollToView,
      currentStep,
      finaliseMonitary,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/css/monitary-notes/add-monitary.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;
  @media screen and (max-width: 850px) {
    margin-top: 60px;
  }
  @media screen and (max-width: 450px) {
    padding: 50px 0;
  }
}
.content-container {
  display: grid;
  place-items: center;
  margin: auto;
}
.label {
  margin-top: 40px;
}
.step {
  margin-top: 30px;
}
.button {
  font-weight: 600;
}
.return-btn {
  color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 20px;
  padding: 5px;
}
</style>
