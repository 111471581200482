import router from "@/router";
import storeCache from "@/js/storeCache";
import UserService from "@/services/user.service";
import { useAuthorizationStore } from "@/stores/authorization.store";
import axios from "axios";
//@ts-ignore
import data from "@/assets/country_codes.json";
import { Department } from "@/stores/department.store";
import ApiHelper from "@/helpsers/api.helper";

export const redirect = (url: string) => {
  router.push({ name: url });
};

export const createService = () => {
  const userService = new UserService();
  userService.getUserInfo();
};

export const getCountryFullNameOffCode = (code: string) => {
  let result = "";
  data.forEach((country: any) => {
    if (country.code === code) result = country.name;
  });

  return result;
};

export const monthFromNumber = (num: number) => {
  if (num > 12) {
    num = num - 12;
  }
  if (num === 1) return "JAN";
  if (num === 2) return "FEB";
  if (num === 3) return "MAR";
  if (num === 4) return "APR";
  if (num === 5) return "MAY";
  if (num === 6) return "JUN";
  if (num === 7) return "JUL";
  if (num === 8) return "AUG";
  if (num === 9) return "SEP";
  if (num === 10) return "OCT";
  if (num === 11) return "NOV";
  if (num === 12) return "DEC";
};
export const dayFromNumber = (num: number) => {
  if (num === 1) return "MON";
  if (num === 2) return "TUE";
  if (num === 3) return "WEN";
  if (num === 4) return "THUR";
  if (num === 5) return "FRI";
  if (num === 6) return "SAT";
  if (num === 7) return "SUN";
};
export const addMinutes = (time: any, minsToAdd: any) => {
  function D(J: any) {
    return (J < 10 ? "0" : "") + J;
  }
  var piece = time.split(":");
  var mins = piece[0] * 60 + +piece[1] + +minsToAdd;

  return D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60);
};

export const roleInCompany = () => {
  if (storeCache.get("roleInCurrentCompany") && storeCache.get("roleInCurrentCompany") !== "undefined") {
    return storeCache.get("roleInCurrentCompany");
  }
  return "OWNER";
};
export const typeOfCompany = () => {
  try {
    if (storeCache.get("currentCompany")) {
      let c = storeCache.get("currentCompany");
      return c.type;
    } else return "ADMIN";
  } catch (e) {
    console.log(e);
  }
};

export const calculateYear = (month: number) => {
  if (month > 12) {
    month = month - 12;
  }
  return month > new Date().getMonth() ? new Date().getFullYear() : new Date().getFullYear() + 1;
};
export const calculateYearAccurate = (month: number) => {
  return month > new Date().getMonth() ? new Date().getFullYear() : new Date().getFullYear() + 1;
};

export const getDepartmentNameById = (id: string, departments: Array<Department>) => {
  let tmp = "";
  departments.forEach((department) => {
    if (id === department.id) tmp = department.name;
  });
  return tmp;
};
export function formatDate(date: any) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export async function getAddressesFromPostal(postal: string) {
  try {
    return await axios
      .get("https://kodpocztowy.intami.pl/api/" + postal)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  } catch (e) {
    throw e;
  }
}

export async function checkPostal(code: string) {
  let reg = /^[0-9]{2}-[0-9]{3}/;
  if (reg.test(code) && code.length === 6) {
    const response = await getAddressesFromPostal(code);
    return response.filter(
      (value: any, index: number, self: any) => index === self.findIndex((t: any) => t.place === value.place && t.name === value.name)
    );
  } else {
    return [];
  }
}

export function convertTo24Hour(time: any) {
  var hours = parseInt(time.substr(0, 2));

  if (time.indexOf("am") != -1 && hours == 12) {
    time = time.replace("12", "0");
  }
  if (time.indexOf("pm") != -1 && hours < 12) {
    time = time.replace(hours, hours + 12);
  }

  return time.replace(/(am|pm)/, "");
}

export const getPendingAuthorizationId = async (id: string, lookingfor: string) => {
  let auth = useAuthorizationStore();
  let authArray = await auth.listAuthorizations(
    {
      id,
      status: "PENDING",
    },
    "999999999",
    "1"
  );
  let tmp = "";
  authArray.forEach((authorization: any) => {
    if (authorization.department.id === lookingfor) tmp = authorization.id;
  });

  return tmp;
};
function dataURItoBlob(dataURI: any) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}
export function dataURItoFile(dataurl: any, name: string) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], name, { type: mime });
}

export const resize = (file: any, max_width: any, max_height: any, imageEncoding: any) => {
  var fileLoader = new FileReader(),
    canvas = document.createElement("canvas"),
    context: any = null,
    imageObj: any = new Image(),
    blob = null;

  //create a hidden canvas object we can use to create the new resized image data
  canvas.id = "hiddenCanvas";
  canvas.width = max_width;
  canvas.height = max_height;
  canvas.style.visibility = "hidden";
  document.body.appendChild(canvas);

  //get the context to use
  context = canvas.getContext("2d");

  // check for an image then
  //trigger the file loader to get the data from the image
  if (file.type.match("image.*")) {
    fileLoader.readAsDataURL(file);
  } else {
    alert("File is not an image");
  }

  // setup the file loader onload function
  // once the file loader has the data it passes it to the
  // image object which, once the image has loaded,
  // triggers the images onload function
  fileLoader.onload = function () {
    var data = this.result;
    imageObj.src = data;
  };

  fileLoader.onabort = function () {
    alert("The upload was aborted.");
  };

  fileLoader.onerror = function () {
    alert("An error occured while reading the file.");
  };

  // set up the images onload function which clears the hidden canvas context,
  // draws the new image then gets the blob data from it
  imageObj.onload = function () {
    // Check for empty images
    if (this.width == 0 || this.height == 0) {
      alert("Image is empty");
    } else {
      context.clearRect(0, 0, max_width, max_height);
      context.drawImage(imageObj, 0, 0, this.width, this.height, 0, 0, max_width, max_height);

      //dataURItoBlob function available here:
      // http://stackoverflow.com/questions/12168909/blob-from-dataurl
      // add ')' at the end of this function SO dont allow to update it without a 6 character edit
      blob = dataURItoBlob(canvas.toDataURL(imageEncoding));

      //pass this blob to your upload function
      //@ts-ignore
      upload(blob);
    }
  };

  imageObj.onabort = function () {
    alert("Image load was aborted.");
  };

  imageObj.onerror = function () {
    alert("An error occured while loading image.");
  };
};

export const downloadFile = (file: any, originalname: string) => {
  const fileUrl = URL.createObjectURL(file);

  const anchor = document.createElement("a");
  anchor.href = fileUrl;
  anchor.download = originalname;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  URL.revokeObjectURL(fileUrl);
};

export const checkFileType = (file: any) => {
  const FILE_TYPES = ["jpeg", "png", "jpg"];
  const fileType = file?.target?.files[0]?.name?.split(".")?.pop() ?? file?.name?.split(".")?.pop();
  if (FILE_TYPES.includes(fileType)) return true;
  return false;
};
export const checkFileTypeDoc = (file: any) => {
  const FILE_TYPES = ["pdf", "doc", "docx", "txt", "jpg", "jpeg", "png", "odt"];
  const fileType = file?.target?.files[0]?.name?.split(".")?.pop() ?? file?.name?.split(".")?.pop();
  if (FILE_TYPES.includes(fileType)) return true;
  return false;
};

export const parseChangedHour = (date: any, index: number, key: string, timeWindows: any[]) => {
  // change timeWindows only if date object contains HH or mm
  let newArray = [...timeWindows];
  const parsedHourData = date.displayTime
    .split(":")
    .map((val: string) => val.replace("HH", "00").replace("mm", "00"))
    .join(":");
  newArray[index] = {
    ...newArray[index],
    ...(key === "startHour"
      ? {
          startHour: parsedHourData,
        }
      : key === "endHour"
      ? {
          endHour: parsedHourData,
        }
      : {
          interval: parsedHourData,
        }),
  };
  return newArray;
};
