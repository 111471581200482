<template>
  <div class="stockDeliverBoxColumn">
    <table class="stockTable responsive-table">
      <thead>
        <tr>
          <th v-for="item in headers" :key="item">
            {{ $t("profile.stock." + item) }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
export default {
  name: "LoadingPlaces",
  components: {},
  data() {
    return {
      headers: [
        "loadingType",
        "companyName",
        "location",
        "date",
        "weight",
        "volume",
        "loadingPoints",
      ],
    };
  },
};
</script>


<style lang="scss" scoped>
@use "@/css/stock/stockComponents.scss";

</style>
