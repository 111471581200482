<template>
  <template v-if="!registrationSuccess">
    <div
      style="color: rgba(5, 24, 52, 0.6); display: flex; align-items: center; font-weight: 600; font-size: 14px; margin-top: 20px; cursor:pointer;"
      class="return-btn"
      @click="router.push({ name: 'login' })"
    >
      <svg style="margin-right: 10px" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.27519 0.300026C6.47519 0.500026 6.57119 0.741692 6.56319 1.02503C6.55453 1.30836 6.45019 1.55003 6.25019 1.75003L3.4252 4.57503L14.5752 4.57502C14.8585 4.57502 15.0962 4.67102 15.2882 4.86302C15.4795 5.05436 15.5752 5.29169 15.5752 5.57502C15.5752 5.85836 15.4795 6.09602 15.2882 6.28802C15.0962 6.47936 14.8585 6.57502 14.5752 6.57502L3.4252 6.57503L6.27519 9.42503C6.47519 9.62503 6.5752 9.86269 6.5752 10.138C6.5752 10.4127 6.47519 10.65 6.2752 10.85C6.0752 11.05 5.83753 11.15 5.5622 11.15C5.28753 11.15 5.05019 11.05 4.85019 10.85L0.275196 6.27503C0.175195 6.17503 0.104194 6.06669 0.0621944 5.95003C0.0208612 5.83336 0.000194074 5.70836 0.000194062 5.57503C0.000194051 5.44169 0.0208611 5.31669 0.0621943 5.20003C0.104194 5.08336 0.175195 4.97503 0.275196 4.87503L4.87519 0.275026C5.05853 0.0916929 5.28753 2.47412e-05 5.56219 2.47172e-05C5.83753 2.46932e-05 6.07519 0.100026 6.27519 0.300026Z"
          fill="#051834"
          fill-opacity="0.6"
        />
      </svg>

      Wróć
    </div>
    <div class="align first-align">
      <div style="margin-top: 20px" class="">
        <h1 v-if="!isInvited">{{ $t("register.account_type") }}</h1>
        <h2>{{ $t("register.pick_type") }}</h2>
        <div v-if="!isInvited" class="flex account-type-wrap">
          <div :class="chosenRole === 'DRIVER' ? 'block active' : 'block'" @click="chosenRole = 'DRIVER'">
            <img src="@/assets/icons/delivery-truck-grey.png" alt="" />
            <p>{{ $t("profile.role.driver") }}</p>
          </div>
          <div :class="chosenRole === 'FACTORY' ? 'block active' : 'block'" @click="chosenRole = 'FACTORY'">
            <img src="@/assets/icons/factory-grey.png" alt="" />
            <p>{{ $t("profile.role.factory") }}</p>
          </div>
          <div :class="chosenRole === 'WAREHOUSE' ? 'block active' : 'block'" @click="chosenRole = 'WAREHOUSE'">
            <img src="@/assets/icons/warehouse-grey.png" alt="" />
            <p>{{ $t("profile.role.warehouse") }}</p>
          </div>
        </div>
      </div>
      <form class="auth__form" @submit="onSubmit">
        <div class="auth__error-message" v-if="backendError">
          {{ backendError }}
        </div>
        <div class="align">
          <div>
            <h1 v-if="!isInvited">{{ $t("register.company_data") }}</h1>
            <h2>{{ $t("register.enter_company_data") }}</h2>
            <div class="company-part">
              <div v-if="!isInvited" class="control control--input span-column">
                <label for="nip" class="control__label">{{ $t("register.nip") }}</label>
                <input
                  type="text"
                  id="nip"
                  @keyup="nip = nip.replace(/[^0-9]+/g, '')"
                  v-model="nip"
                  :class="{ error: errors.nip, 'disabled-input': isInvited }"
                  :disabled="isInvited"
                  autofocus
                  style="height: 40px"
                />
                <div v-show="errors.nip" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.nip }}
                  </div>
                </div>
              </div>

              <div @click="getGusInfo(nip)" class="gus span-column" :class="!isInvited && nipCorrect(nip) ? '' : 'gus-disabled'">
                <p>{{ $t("auth.gus") }}</p>
              </div>

              <div v-if="!isInvited" class="control control--input span-column">
                <label for="company" class="control__label">{{ $t("register.company") }}</label>
                <input
                  type="text"
                  id="company"
                  v-model="company"
                  :class="{ error: errors.nip, 'disabled-input': isInvited }"
                  :disabled="isInvited"
                  autofocus
                  style="height: 40px"
                />
                <div v-show="errors.company" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.company }}
                  </div>
                </div>
              </div>
              <div v-if="!isInvited" class="control control--input span-column">
                <label for="address" class="control__label">{{ $t("register.address") }}</label>
                <input
                  type="text"
                  id="address"
                  v-model="address"
                  :class="{ error: errors.nip, 'disabled-input': isInvited }"
                  :disabled="isInvited"
                  autofocus
                  style="height: 40px"
                />
                <div v-show="errors.address" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.address }}
                  </div>
                </div>
              </div>
              <div v-if="!isInvited" class="control control--input">
                <label for="zipCode" class="control__label">{{ $t("register.zipCode") }}</label>
                <input
                  type="text"
                  id="zipCode"
                  v-model="zipCode"
                  :class="{ error: errors.nip, 'disabled-input': isInvited }"
                  :disabled="isInvited"
                  autofocus
                  @keyup="postalChanged"
                  style="height: 40px"
                />

                <on-input-list :data="addresses" :width="300" @onPick="newAddressPickedFromList" />
                <div v-show="errors.zipCode" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.zipCode }}
                  </div>
                </div>
              </div>

              <div v-if="!isInvited" class="control control--input">
                <label for="city" class="control__label">{{ $t("register.city") }}</label>
                <input
                  type="text"
                  id="city"
                  v-model="city"
                  :class="{ error: errors.nip, 'disabled-input': isInvited }"
                  :disabled="isInvited"
                  autofocus
                  style="height: 40px"
                />
                <div v-show="errors.city" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.city }}
                  </div>
                </div>
              </div>
              <div v-if="!isInvited" class="control control--select">
                <label for="country" class="control__label" :style="{ width: '340px', margin: '0 auto 5px auto' }">
                  {{ $t("register.country") }}
                </label>
                <custom-picker
                  :options="
                    countries.sort(function (a, b) {
                      return a.name.localeCompare(b.name);
                    })
                  "
                  :is-country="true"
                  :opposite="true"
                  :blue="true"
                  :blue-small="true"
                  :display-country-names="true"
                  @changed-option="handleNewCountryCode"
                />
                <div v-show="errors.country" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.country }}
                  </div>
                </div>
              </div>
              <div v-if="!isInvited" class="control control--input">
                <label for="phone" class="control__label">{{ $t("register.phone") }}</label>
                <input
                  type="text"
                  id="phone"
                  v-model="phone"
                  @keyup="phone = phone.replace(/[^0-9+]+/g, '')"
                  :class="{ error: errors.phone, 'disabled-input': isInvited }"
                  :disabled="isInvited"
                  autofocus
                  style="height: 40px"
                />
                <div v-show="errors.phone" class="control__error" style="bottom: 10px">
                  <div class="control__error-inner">
                    {{ errors.phone }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <h1>{{ $t("register.user_data") }}</h1>
            <h2>{{ $t("register.enter_user_data") }}</h2>
            <div class="company-part">
              <div v-if="!isInvited" class="control control--input span-column">
                <label for="email" class="control__label">{{ $t("register.email") }}</label>
                <input type="text" id="email" v-model="email" :class="{ error: errors.email }" autofocus style="height: 40px" />
                <div v-show="errors.email" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.email }}
                  </div>
                </div>
              </div>
              <div class="control control--input span-column">
                <label for="userPhone" class="control__label">{{ $t("register.phone") }}</label>
                <input
                  type="text"
                  @keyup="userPhone = userPhone.replace(/[^0-9+]+/g, '')"
                  id="userPhone"
                  v-model="userPhone"
                  :class="{ error: errors.userPhone }"
                  autofocus
                  style="height: 40px"
                />
                <div v-show="errors.userPhone" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.userPhone }}
                  </div>
                </div>
              </div>
              <div class="control control--input span-column">
                <label for="name" class="control__label">{{ $t("register.name") }}</label>
                <input type="text" id="name" v-model="name" :class="{ error: errors.name }" style="height: 40px" />
                <div v-show="errors.name" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.name }}
                  </div>
                </div>
              </div>
              <div class="control control--input span-column">
                <label for="surname" class="control__label">{{ $t("register.surname") }}</label>
                <input type="text" id="surname" v-model="surname" :class="{ error: errors.surname }" style="height: 40px" />
                <div v-show="errors.surname" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.surname }}
                  </div>
                </div>
              </div>
              <div class="control control--input control--password span-column">
                <label for="password" class="control__label">{{ $t("register.password") }}</label>
                <input type="password" id="password" v-model="password" :class="{ error: errors.password }" style="height: 40px" />
                <div class="control__eye control__eye--show" data-control-id="password" type="button" @click.prevent="showPassword">
                  <img src="@/assets/images/eye.svg" />
                </div>
                <div class="control__eye control__eye--hide hidden" data-control-id="password" type="button" @click.prevent="hidePassword">
                  <img src="@/assets/images/eye-line.svg" />
                </div>
                <div v-show="errors.password" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.password }}
                  </div>
                </div>
              </div>
              <div class="control control--input control--password span-column">
                <label for="repeatPassword" class="control__label">{{ $t("register.confirm_password") }}</label>
                <input
                  type="password"
                  id="repeatPassword"
                  v-model="passwordConfirmation"
                  :class="{ error: errors.passwordConfirmation }"
                  style="height: 40px"
                />
                <button class="control__eye control__eye--show" data-control-id="repeatPassword" type="button" @click.prevent="showPassword">
                  <img src="@/assets/images/eye.svg" />
                </button>
                <button class="control__eye control__eye--hide hidden" data-control-id="repeatPassword" type="button" @click.prevent="hidePassword">
                  <img src="@/assets/images/eye-line.svg" />
                </button>
                <div v-show="errors.passwordConfirmation" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.passwordConfirmation }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center-regulations">
          <div class="left">
            <div class="control control--check">
              <input type="checkbox" id="check3" v-model="withDepartment" :class="{ error: errors.withDepartment }" />
              <div v-show="errors.withDepartment" class="control__error" style="left: 20px">
                <div class="control__error-inner">
                  {{ errors.withDepartment }}
                </div>
              </div>
              <label for="check3" class="control__label">Dane firmy są jednocześnie danymi głównego magazynu firmy</label>
            </div>
          </div>
          <div class="right">
            <div class="control control--check">
              <input type="checkbox" id="check1" v-model="regulationsAcceptance" :class="{ error: errors.regulationsAcceptance }" />
              <div v-show="errors.regulationsAcceptance" class="control__error" style="left: 20px">
                <div class="control__error-inner">
                  {{ errors.regulationsAcceptance }}
                </div>
              </div>
              <label for="check1" class="control__label"
                >{{ $t("register.regulations_acceptance_first") }}
                <a href="https://www.logiplanner.eu/regulamin/" target="_blank">{{ $t("register.regulations_acceptance_second") }}</a>
                {{ $t("register.regulations_acceptance_third") }}
                <a href="https://www.logiplanner.eu/polityka-prywatnosci/" target="_blank">{{ $t("register.regulations_acceptance_fourth") }}</a
                >*</label
              >
            </div>
            <div class="control control--check">
              <input type="checkbox" id="dataProcessing" v-model="dataProcessing" :class="{ error: errors.dataProcessing }" />
              <div v-show="errors.dataProcessing" class="control__error" style="left: 20px">
                <div class="control__error-inner">
                  {{ errors.dataProcessing }}
                </div>
              </div>
              <label for="dataProcessing" class="control__label">{{ $t("register.personal_data_processing_agreement") }}*</label>
            </div>
          </div>
        </div>
        <div class="auth__buttons">
          <button
            class="button button--brown"
            :class="{ disabled: sendingForm }"
            type="submit"
            :disabled="sendingForm"
            @click="onSubmit"
            style="font-weight: 600"
          >
            <template v-if="!sendingForm">
              {{ $t("register.create_account") }}
            </template>
            <template v-else>
              {{ $t("register.creating_account_in_progress") }}
            </template>
          </button>
        </div>
        <div class="reminder">
          Masz już konto?
          <span style="margin-left: 4px" @click="router.push({ name: 'login' })"> Kliknij, aby się zalogować</span>
        </div>
      </form>
    </div>
  </template>
  <template v-else>
    <div class="content-wrapper">
      <img src="@/assets/images/logo.png" alt="" />
      <div class="">
        <p class="auth__text auth__text--centered bold" style="color: rgba(5, 24, 52, 0.6)">
          Typ zarejestrowanego konta:
          {{ $t(`tableData.${chosenRole.toLowerCase()}`) }}
        </p>
        <p class="auth__text auth__text--centered">
          {{ $t("register.email_verification") }}
        </p>
      </div>
      <p class="auth__forgotten auth__text--centered">
        {{ $t("forgot_password.still_no_email") }}
        <strong v-if="sendingForm">{{ $t("forgot_password.sending") }}</strong>
        <a v-else href="#" @click.prevent="resendEmail" :style="{ display: 'block' }">{{ $t("forgot_password.resend_email_again") }}</a>
      </p>
    </div>
  </template>
</template>

<script>
import GuestLayout from "@/layouts/GuestLayout";
import { ref, inject, onBeforeMount } from "vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import i18n from "@/i18n";
import { useAuthStore } from "@/stores/auth.store";
import { useI18n } from "vue-i18n";
import AlertHelper from "@/helpsers/alert.helper";
import storeCache from "@/js/storeCache";
import { useUserStore } from "@/stores/user.store";
import router from "@/router";
import { getAddressesFromPostal, checkPostal } from "@/js/utils";
import OnInputList from "@/components/common/OnInputList";
import countries from "@/assets/country_codes.json";
import CustomPicker from "@/components/common/CustomPicker.vue";
export default {
  name: "RegistrationView",
  components: { GuestLayout, OnInputList, CustomPicker },
  setup() {
    const registrationSuccess = ref(false);
    const backendError = ref("");
    const sendingForm = ref(false);
    const isInvited = ref(false);
    let alert = inject("alert");
    const { t } = useI18n();
    let chosenRole = ref("");
    const users = useUserStore();
    const userId = ref(null);
    let addresses = ref([]);
    onBeforeMount(() => {
      if (router.currentRoute.value.path === "/dodaj-do-firmy") {
        storeCache.set("invitation", true);
        isInvited.value = true;
      }
    });
    const handleValidators = () => {
      if (router.currentRoute.value.path === "/dodaj-do-firmy") {
        return {
          validationSchema: yup.object().shape({
            userPhone: yup
              .string()
              .required(i18n.global.t("register.validation.required"))
              .matches(
                /^(?=(.{1,20})$)((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/,
                i18n.global.t("register.validation.phone")
              ),
            name: yup
              .string()
              .trim()
              .required(i18n.global.t("register.validation.required"))
              .matches(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/, i18n.global.t("register.validation.string"))
              .max(50, i18n.global.t("register.validation.max", { number: 50 })),
            surname: yup
              .string()
              .trim()
              .required(i18n.global.t("register.validation.required"))
              .matches(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/, i18n.global.t("register.validation.string"))
              .max(50, i18n.global.t("register.validation.max", { number: 50 })),
            password: yup
              .string()
              .required(i18n.global.t("register.validation.required"))
              .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?.!@$%^&*-]).{8,}$/, i18n.global.t("register.validation.password")),
            passwordConfirmation: yup
              .string()
              .required(i18n.global.t("register.validation.required"))
              .oneOf([yup.ref("password"), null], i18n.global.t("register.validation.passwordConfirmation")),
            dataProcessing: yup.bool().oneOf([true], i18n.global.t("register.validation.dataProcessing")),
            regulationsAcceptance: yup.bool().oneOf([true], i18n.global.t("register.validation.regulationsAcceptance")),
            withDepartment: yup.bool(),
          }),
          initialValues: {
            regulationsAcceptance: false,
            dataProcessing: false,
            withDepartment: false,
          },
        };
      } else
        return {
          validationSchema: yup.object().shape({
            nip: yup
              .number()
              .typeError("NIP musi się składać z samych cyfr")
              .required(i18n.global.t("register.validation.required"))
              .test("len", t("profile.company.validation.max_nip"), (val) => (val !== undefined ? val.toString().length === 10 : false)),
            company: yup.string().required(i18n.global.t("register.validation.required")),
            address: yup.string().required(i18n.global.t("register.validation.required")),
            country: yup.string().required(i18n.global.t("register.validation.required")),
            city: yup
              .string()
              .matches(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+(?:[\s-][A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+)*$/, i18n.global.t("register.validation.string"))
              .required(i18n.global.t("register.validation.required")),
            zipCode: yup
              .string()
              .required(i18n.global.t("register.validation.required"))
              .matches(/^[a-zA-Z\d](?:[- ]*[a-zA-Z\d]){2,9}$/, t("profile.company.validation.zip_code"))
              .max(6, t("profile.company.validation.zip_code"))
              .trim(),
            email: yup.string().required(i18n.global.t("register.validation.required")).email(i18n.global.t("register.validation.email")),
            phone: yup
              .string()
              .required(i18n.global.t("register.validation.required"))
              .matches(
                /^(?=(.{1,20})$)((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/,
                i18n.global.t("register.validation.phone")
              ),
            userPhone: yup
              .string()
              .required(i18n.global.t("register.validation.required"))
              .matches(
                /^(?=(.{1,20})$)((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/,
                i18n.global.t("register.validation.phone")
              ),
            name: yup
              .string()
              .trim()
              .required(i18n.global.t("register.validation.required"))
              .matches(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/, i18n.global.t("register.validation.string"))
              .max(15, i18n.global.t("register.validation.max", { number: 15 })),
            surname: yup
              .string()
              .trim()
              .required(i18n.global.t("register.validation.required"))
              .matches(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/, i18n.global.t("register.validation.string"))
              .max(30, i18n.global.t("register.validation.max", { number: 30 })),
            password: yup
              .string()
              .required(i18n.global.t("register.validation.required"))
              .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?.!@$%^&*-]).{8,}$/, i18n.global.t("register.validation.password")),
            passwordConfirmation: yup
              .string()
              .required(i18n.global.t("register.validation.required"))
              .oneOf([yup.ref("password"), null], i18n.global.t("register.validation.passwordConfirmation")),
            dataProcessing: yup.bool().oneOf([true], i18n.global.t("register.validation.dataProcessing")),
            regulationsAcceptance: yup.bool().oneOf([true], i18n.global.t("register.validation.regulationsAcceptance")),
            withDepartment: yup.bool(),
          }),
          initialValues: {
            regulationsAcceptance: false,
            dataProcessing: false,
            withDepartment: false,
          },
        };
    };

    const { handleSubmit, errors } = useForm(handleValidators());

    const { value: nip } = useField("nip");
    const { value: company } = useField("company");
    const { value: address } = useField("address");
    const { value: city } = useField("city");
    const { value: zipCode } = useField("zipCode");
    const { value: email } = useField("email");
    const { value: name } = useField("name");
    const { value: phone } = useField("phone");
    const { value: userPhone } = useField("userPhone");
    const { value: surname } = useField("surname");
    const { value: password } = useField("password");
    const { value: passwordConfirmation } = useField("passwordConfirmation");
    const { value: regulationsAcceptance } = useField("regulationsAcceptance");
    const { value: dataProcessing } = useField("dataProcessing");
    const { value: country } = useField("country");
    const { value: withDepartment } = useField("withDepartment");
    country.value = "Polska";

    const onSubmit = handleSubmit(async (values) => {
      const authStore = useAuthStore();
      const {
        address,
        city,
        company,
        dataProcessing,
        email,
        name,
        nip,
        password,
        phone,
        userPhone,
        regulationsAcceptance,
        surname,
        zipCode,
        country,
      } = values;
      if (chosenRole.value === "") {
        AlertHelper.show(alert, "Wybierz typ konta, aby przejść dalej", "", "error");
      } else {
        sendingForm.value = true;

        let obj = {};
        if (isInvited.value) {
          obj = {
            type: chosenRole.value,
            password,
            name,
            userPhone: userPhone,
            surname,
            regulationsAcceptance,
            dataProcessing,
          };
        } else {
          obj = {
            type: chosenRole.value,
            email,
            password,
            name,
            surname,
            userPhone: userPhone,
            regulationsAcceptance,
            dataProcessing,
            companyNip: nip,
            companyName: company,
            companyPhone: phone,
            withDepartment: withDepartment.value ? true : false,
            addresses: [
              {
                companyAddress: address,
                companyCity: city,
                companyZipCode: zipCode,
                companyCountry: country,
              },
            ],
          };
        }
        try {
          if (isInvited.value) userId.value = await authStore.registerFromInvite(obj, router.currentRoute.value.query.invitation);
          else userId.value = await authStore.register(obj, false, "");
          if (isInvited.value) {
            await users.acceptInvitation(router.currentRoute.value.query.invitation);
          }
          AlertHelper.show(alert, t("register.success"));
          sendingForm.value = false;
          registrationSuccess.value = true;
        } catch (err) {
          sendingForm.value = false;
          AlertHelper.show(alert, err, "", "error");
        }
      }
    });

    function showPassword(ev) {
      ev.currentTarget.classList.add("hidden");
      document.querySelector("#" + ev.currentTarget.dataset.controlId).type = "text";
      document.querySelector(".control__eye--hide[data-control-id=" + ev.currentTarget.dataset.controlId + "]").classList.remove("hidden");
    }

    function hidePassword(ev) {
      ev.currentTarget.classList.add("hidden");
      document.querySelector("#" + ev.currentTarget.dataset.controlId).type = "password";
      document.querySelector(".control__eye--show[data-control-id=" + ev.currentTarget.dataset.controlId + "]").classList.remove("hidden");
    }

    const resendEmail = async () => {
      sendingForm.value = true;
      const auth = useAuthStore();
      if (userId.value) {
        try {
          await auth.resendConfirmation(`${userId.value.userId}`);
          AlertHelper.show(alert, "E-mail z potwierdzeniem został wysłany ponownie!", "", "success");
        } catch (e) {
          sendingForm.value = false;
          AlertHelper.show(alert, e, "", "error");
        }
      }
      sendingForm.value = false;
    };

    const nipCorrect = (nip) => {
      if (nip !== undefined && nip.length === 10) return true;
      else return false;
    };

    const handleErros = () => {
      setTimeout(() => {
        if (Object.entries(errors.value).length > 0) AlertHelper.show(alert, t("register.validation.allRequired"), "", "error");
      }, 50);
    };
    const postalChanged = async () => {
      if (country.value === "Polska" && zipCode.value) {
        let parsedCode = zipCode.value.toString();
        let isnum = /^\d+$/.test(zipCode.value);
        if (isnum && parsedCode.length > 2) {
          zipCode.value = `${parsedCode.substring(0, 2)}-${parsedCode.substring(2, parsedCode.length)}`;
        }
      }

      let code = zipCode.value;
      if (code.charCodeAt(2) === 8209) {
        zipCode.value = `${code.substring(0, 2)}-${code.substring(3, 6)}`;
      }
      addresses.value = await checkPostal(zipCode.value);
    };

    const newAddressPickedFromList = (e) => {
      if (e.miejscowosc) city.value = e.miejscowosc;
      if (e.ulica) address.value = e.ulica;
      country.value = "Polska";
      addresses.value = [];
    };

    const getGusInfo = async (nip) => {
      if (nipCorrect(nip)) {
        const authStore = useAuthStore();
        const tmp = await authStore.getGusData(nip, alert);
        if (tmp !== undefined) {
          company.value = tmp.gusName ? tmp.gusName : "";
          city.value = tmp.gusPlace ? tmp.gusPlace : "";
          zipCode.value = tmp.gusZipCode ? tmp.gusZipCode : "";
          let addressString = `${tmp.gusStreet ? tmp.gusStreet : ""} ${tmp.gusPropertyNumber ? tmp.gusPropertyNumber : ""}${
            tmp.gusApartmentNumber ? "/" + tmp.gusApartmentNumber : ""
          }`;
          address.value = addressString;
        }
      } else {
        AlertHelper.show(alert, t("register.validation.enterNip"), "", "error");
      }
    };
    const handleNewCountryCode = (e) => {
      country.value = e.name;
      if (e.code === "PL" && zipCode.value) {
        let parsedCode = zipCode.value.toString();
        let isnum = /^\d+$/.test(zipCode.value);
        if (isnum && parsedCode.length > 2) {
          zipCode.value = `${parsedCode.substring(0, 2)}-${parsedCode.substring(2, parsedCode.length)}`;
        }
      }
    };

    return {
      email,
      handleNewCountryCode,
      name,
      surname,
      password,
      phone,
      userPhone,
      passwordConfirmation,
      dataProcessing,
      regulationsAcceptance,
      showPassword,
      hidePassword,
      sendingForm,
      onSubmit,
      errors,
      postalChanged,
      backendError,
      registrationSuccess,
      chosenRole,
      nip,
      company,
      address,
      city,
      zipCode,
      country,
      alert,
      isInvited,
      resendEmail,
      addresses,
      newAddressPickedFromList,
      nipCorrect,
      getGusInfo,
      countries,
      router,
      withDepartment,
    };
  },
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 15px;
}
.center-regulations {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-left: 15px;
  div {
    width: 450px;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 20px;
  }
  @media screen and (max-width: 600px) {
    margin-top: 20px;
    div {
      width: fit-content;
    }
  }
}

.block {
  width: 100px;
  height: 80px;
  background: rgba(162, 162, 162, 0.1);
  border-radius: 10px;
  cursor: pointer;
  display: grid;
  place-items: center;
  margin: 20px 5px;
  padding: 2px;
  padding-top: 6px;
  img {
    width: 45px;
    height: 45px;
    opacity: 0.5;
  }
  p {
    font-size: 10px;
    font-weight: 600;
    color: rgb(158, 156, 156);
  }
}
.active {
  background: rgba(17, 43, 175, 0.04);
  border: 1px solid #112baf;
  p {
    color: #112baf;
  }
  img {
    opacity: 1;
  }
}
.bold {
  font-weight: 600;
}

.disabled-input {
  background-color: rgb(128, 128, 128, 0.6);
}
.control {
  &--input {
    input {
      height: 62px;
      max-width: 100%;
    }
  }
  &--select {
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid #d4d1d1;
      border-radius: 5px;
      height: 62px;
      max-width: 100%;
      background-color: #fff;
      width: 100%;
      padding: 0 10px;
      font-size: 1.6rem;
      font-family: Inter, sans-serif;
      outline: none;
      color: #000;
    }
  }
}
.gus {
  font-weight: 600;
  font-size: 12px;
  margin: auto;
  margin: auto 50px;
  text-align: center;
  border: 1px solid #112baf;
  color: #112baf;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 10px;
  cursor: pointer;
  padding: 1px 10px;
  transition: 0.3s all;
  padding-top: 8px;
  margin-bottom: 25px;
  margin-top: 26px;

  &:hover {
    color: #fff;
    background: #112baf;
  }
}
.company-part {
  display: grid;
  grid-template-columns: 220px 220px;
  grid-gap: 0 20px;
  // @media screen and (max-width: 1000px) {
  //   grid-template-columns: 1fr 1fr;
  // }
  @media screen and (max-width: 550px) {
    grid-template-columns: 160px 160px;
  }
}
.span-column {
  grid-column: span 2;
}
.gus-disabled {
  cursor: pointer;
  opacity: 0.4;
  &:hover {
    background: #fff;
    color: #112baf;
  }
}
h1 {
  display: grid;
  place-items: center;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #112baf;
}
h2 {
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.5);
}
.align,
.first-align {
  display: flex;
  justify-content: center;
  gap: 20px 40px;
  margin-top: 20px;
  @media screen and (max-width: 1400px) {
    gap: 20px;
  }
  @media screen and (max-width: 1000px) {
    display: grid;
    place-items: center;
  }
}
.align {
  @media screen and (max-width: 1000px) {
    margin-bottom: 20px;
  }
}
.first-align {
  @media screen and (max-width: 1300px) {
    display: grid;
    place-items: center;
  }
}
.first-align {
  gap: 120px !important;
  @media screen and (max-width: 1500px) {
    gap: 20px !important;
  }
}
.auth__buttons,
.reminder {
  display: flex;
  justify-content: flex-start !important;
  width: fit-content;
  margin: auto;
}
.reminder {
  // margin-left: 120px;
  margin-top: 15px;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 12px;
  color: rgba(5, 24, 52, 0.6);

  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
.content-wrapper {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  place-items: center;
  padding: 180px 0;

  img {
    max-width: 250px;
  }
}
</style>
