<template>
  <div class="company-data-wrapper">
    <div
      class="box"
      style="padding-top: 30px; padding-bottom: 30px"
      :class="currCompany && !currCompany.verificationTransferConfirmed ? 'box-small' : ''"
    >
      <form>
        <div class="">
          <div class="">
            <div class="grid-it">
              <div class="control control--input span-col">
                <label for="company" class="control__label">{{ $t("profile.company.name") }}</label>
                <input
                  disabled
                  style="background: rgba(162, 162, 162, 0.05)"
                  type="text"
                  id="company"
                  :placeholder="$t('register.company_placeholder')"
                  v-model="company"
                />
                <div v-show="errors.company" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.company }}
                  </div>
                </div>
              </div>
              <div class="control control--input">
                <label for="address" class="control__label">{{ $t("profile.company.address") }}</label>
                <input
                  disabled
                  style="background: rgba(162, 162, 162, 0.05)"
                  type="text"
                  id="address"
                  :placeholder="$t('register.address_placeholder')"
                  v-model="address"
                />
                <div v-show="errors.address" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.address }}
                  </div>
                </div>
              </div>
              <div class="control control--input">
                <label for="city" class="control__label">{{ $t("profile.company.city") }}</label>
                <input
                  disabled
                  style="background: rgba(162, 162, 162, 0.05)"
                  type="text"
                  id="city"
                  :placeholder="$t('register.city_placeholder')"
                  v-model="city"
                />
                <div v-show="errors.city" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.city }}
                  </div>
                </div>
              </div>
              <div class="control control--input">
                <label for="country" class="control__label">{{ $t("register.country") }}</label>
                <input
                  disabled
                  style="background: rgba(162, 162, 162, 0.05)"
                  type="text"
                  id="country"
                  :placeholder="$t('register.country_placeholder')"
                  v-model="country"
                />
                <div v-show="errors.country" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.country }}
                  </div>
                </div>
              </div>
              <div class="control control--input">
                <label for="phone" class="control__label">{{ $t("register.phone") }}</label>
                <input
                  disabled
                  style="background: rgba(162, 162, 162, 0.05)"
                  type="text"
                  id="phone"
                  :placeholder="$t('register.phone_placeholder')"
                  v-model="phone"
                />

                <div v-show="errors.phone" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.phone }}
                  </div>
                </div>
              </div>
              <div class="control control--input">
                <label for="postCode" class="control__label">{{ $t("profile.company.zip_code") }}</label>
                <input
                  disabled
                  style="background: rgba(162, 162, 162, 0.05)"
                  type="text"
                  id="postCode"
                  pattern="^\d{2}-\d{3}$"
                  :placeholder="$t('register.zipCode_placeholder')"
                  v-model="zipCode"
                />
                <div v-show="errors.zipCode" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.zipCode }}
                  </div>
                </div>
              </div>
              <div class="control control--input">
                <label for="nip" class="control__label">{{ $t("profile.company.nip") }}</label>
                <input
                  type="text"
                  id="nip"
                  :placeholder="$t('register.nip_placeholder')"
                  style="background: rgba(162, 162, 162, 0.05)"
                  v-model="nip"
                  :disabled="true"
                />
                <div v-show="errors.nip" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.nip }}
                  </div>
                </div>
              </div>
            </div>
            <div class="checkbox-positioning">
              <phone-number-chekckbox @checkChange="checked = !checked" :checked="checked"></phone-number-chekckbox>
            </div>
            <div class="col-2">
              <div style="display: grid; place-items: center" class="">
                <h1 v-if="profilePictureToDisplay?.file || roleInCompany() === 'OWNER'">{{ $t("profile.company.avatar") }}</h1>
                <div v-if="profilePictureToDisplay && profilePictureToDisplay?.file" class="image-wrapper image-wrapper-small">
                  <div>
                    <img class="picture-measurement" :src="profilePictureToDisplay.file" alt="Profile picture" />
                    <span v-if="roleInCompany() === 'OWNER'" @click="deleteProfilePicture">X</span>
                  </div>
                </div>
                <div v-if="roleInCompany() === 'OWNER'" style="margin-top: 5px" class="control control--input">
                  <label for="avatar" class="control__label galery-custom-btn">
                    <div class="input-as-button">{{ $t("profile.add_documents.choose_file") }}</div>
                  </label>
                  <input type="file" id="avatar" accept=".jpg, .jpeg, .png" style="display: none" @change="handlePicture" />
                </div>
              </div>
              <div style="text-align: center" class="">
                <h1 v-if="imagesToDisplay.length > 0 || roleInCompany() === 'OWNER'">{{ $t("profile.company.galery") }}</h1>

                <div class="image-wrapper">
                  <div v-for="(image, index) in imagesToDisplay" :key="index">
                    <img :key="index" :src="image.file" alt="Galery image" />
                    <span v-if="roleInCompany() === 'OWNER'" @click="removeFromGalery(image.id)">X</span>
                  </div>
                </div>
                <div v-if="roleInCompany() === 'OWNER'" style="margin-top: 2px" class="control control--input">
                  <label for="galery" class="control__label galery-custom-btn">
                    <div class="input-as-button">{{ $t("profile.add_documents.choose_files") }}</div>
                  </label>
                  <input type="file" id="galery" accept=".jpg, .jpeg, .png" style="display: none" @change="addPicture($event.target.files[0])" />
                </div>
              </div>
              <!-- <div @click="saveCompanyColumn" class="save-galery">
                {{ authStore.isLoading ? "Zapisywanie..." : "Zapisz" }}
              </div> -->
            </div>
          </div>
        </div>
      </form>
      <div v-if="roleInCompany() === 'OWNER'" style="display: grid; place-items: center">
        <primary-button
          @click="saveCompanyColumn"
          :filled="true"
          :red="true"
          :title="authStore.isLoading ? $t('generals.saving') : $t('generals.save')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "@/css/company/company-data.scss";
import { inject, onMounted, ref, watch } from "vue";
import loader from "@/components/loader/index.vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { useI18n } from "vue-i18n";
import { useCompanyStore } from "@/stores/company.store";
import { useHelpersStore } from "@/stores/helpers.store";
import storeCache from "@/js/storeCache";
import alertHelper from "@/helpsers/alert.helper";
import i18n from "@/i18n";
import PhoneNumberChekckbox from "@/components/common/PhoneNumberChekckbox.vue";
import PrimaryButton from "@/components/common/PrimaryButton.vue";
import { checkFileType } from "@/js/utils";
import { roleInCompany, typeOfCompany } from "@/js/utils";
export default {
  name: "CompanyData",
  props: ["mock", "allowSms"],
  components: {
    loader,
    PhoneNumberChekckbox,
    PrimaryButton,
  },
  setup(props) {
    const authStore = props.mock;
    let alert = inject("alert");
    const { t } = useI18n();
    const userCompany = useCompanyStore();
    const helpersStore = useHelpersStore();
    const companyId = storeCache.get("currentCompany")?.id;
    const currCompany = ref(null);
    let fetched = ref(false);
    const companyAddressId = ref(null);
    const companyAvatar = ref("");
    const companyGalery = ref([]);
    const imagesToDisplay = ref([]);
    const profilePictureToDisplay = ref(null);
    const galeryCopy = ref([]);
    const galleryUploading = ref(false);
    let checked = ref(props.allowSms);
    const { handleSubmit, errors } = useForm({
      validationSchema: yup.object().shape({
        company: yup.string().trim(),
        address: yup.string().trim(),
        city: yup
          .string()
          .matches(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+(?:[\s-][A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+)*$/, i18n.global.t("register.validation.string"))
          .required(i18n.global.t("register.validation.required")),
        country: yup
          .string()
          .matches(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/, i18n.global.t("register.validation.string"))
          .required(i18n.global.t("register.validation.required")),
        zipCode: yup
          .string()
          .required(i18n.global.t("register.validation.required"))
          .matches(/^[a-zA-Z\d](?:[- ]*[a-zA-Z\d]){2,9}$/, t("profile.company.validation.zip_code"))
          .max(6, t("profile.company.validation.zip_code"))
          .trim(),
        nip: yup.string().min(10, t("profile.company.validation.max_nip")).max(10, t("profile.company.validation.max_nip")),
        phone: yup
          .string()
          .required(i18n.global.t("register.validation.required"))
          .matches(
            /^(?=(.{1,20})$)((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/,
            i18n.global.t("register.validation.phone")
          ),
      }),
      initialValues: {
        company: props.mock.company !== undefined ? props.mock.company.name : "",
        address: props.mock.company !== undefined ? props.mock.company.address : "",
        city: props.mock.company !== undefined ? props.mock.company.city : "",
        zipCode: props.mock.company !== undefined ? props.mock.company.zipCode : "",
        nip: props.mock.company !== undefined ? props.mock.company.nip : "",
        phone: "",
        country: "",
      },
    });

    onMounted(async () => {
      authStore.isLoading = false;
      if (companyId !== null) {
        currCompany.value = await userCompany.fetchOneCompany(companyId);
        const { companyName, addresses, companyNip, companyPhone, documents, companyAvatar } = currCompany.value;
        const { companyAddress, companyCity, companyZipCode, id, companyCountry } = addresses[0];
        company.value = companyName;
        address.value = companyAddress;
        city.value = companyCity;
        zipCode.value = companyZipCode;
        nip.value = companyNip;
        phone.value = companyPhone;
        country.value = companyCountry;
        companyAddressId.value = id;
        const fetchedImages = documents.filter((image) => image.type === "GALLERY" && image.isCompress);
        if (companyAvatar && companyAvatar?.id) {
          const file = await userCompany.getCompanyPicture(`${companyAvatar.id}`);
          profilePictureToDisplay.value = {
            id: companyAvatar?.id,
            file: URL.createObjectURL(file),
          };
        }
        for (let i = 0; i < fetchedImages.length; i++) {
          companyGalery.value.push({
            id: fetchedImages[i].id,
            file: await userCompany.getCompanyPicture(fetchedImages[i].id),
          });
        }
        companyGalery.value.reverse();
        galeryCopy.value = documents
          .filter((elem) => {
            if (elem.type === "GALLERY") return elem;
          })
          .map((elem) => {
            return elem.id;
          });
        mapImagesToDisplay();
        fetched.value = true;
      }
      galeryCopy.value.reverse();
      setTimeout(() => {
        helpersStore.adjustTasks();
      }, 1000);
    });

    const { value: company } = useField("company");
    const { value: address } = useField("address");
    const { value: city } = useField("city");
    const { value: zipCode } = useField("zipCode");
    const { value: nip } = useField("nip");
    const { value: phone } = useField("phone");
    const { value: country } = useField("country");

    const handlePicture = (file) => {
      if (checkFileType(file) && file.target.files[0].size <= 10_000_000) {
        companyAvatar.value = file.target.files[0];
        profilePictureToDisplay.value = {
          ...profilePictureToDisplay.value,
          file: URL.createObjectURL(companyAvatar.value),
        };
      } else if (file.target.files[0].size > 10_000_000) {
        alertHelper.show(alert, t("files.errors.size"), "", "error");
      } else {
        alertHelper.show(alert, t("profile.add_documents.file_type_error"), "", "error");
      }
    };

    const deleteProfilePicture = async () => {
      if (profilePictureToDisplay.value.id) {
        try {
          await userCompany.deleteCompanyFile(profilePictureToDisplay.value.id);
        } catch (e) {
          alertHelper.show(alert, t("profile.add_documents.delete_error"), "", "error");
        }
      }
      profilePictureToDisplay.value = null;
      companyAvatar.value = "";
    };

    const mapImagesToDisplay = () => {
      imagesToDisplay.value = companyGalery.value.map((image) => ({
        id: image.id,
        file: URL.createObjectURL(image.file),
      }));
    };

    const addPicture = (file) => {
      if (!checkFileType(file)) {
        alertHelper.show(alert, t("profile.add_documents.file_type_error"), "", "error");
        return;
      }

      if (file.size <= 10_000_000) {
        companyGalery.value.push({
          id: new Date().getTime(),
          file,
        });
        mapImagesToDisplay();
      } else if (file.target.files[0].size > 10_000_000) {
        alertHelper.show(alert, t("files.errors.size"), "", "error");
      }
    };

    const removeFromGalery = async (imageId) => {
      companyGalery.value = companyGalery.value.filter((elem) => elem.id !== imageId);
      if (galeryCopy.value.find((id) => id === imageId)) await userCompany.deleteCompanyFile(imageId);
      mapImagesToDisplay();
    };

    const saveCompanyColumn = handleSubmit(async () => {
      if (!authStore.isLoading) {
        authStore.isLoading = true;

        const data = {
          companyData: {
            companyName: company.value,
            companyNip: nip.value,
            companyPhone: phone.value,
            allowSms: checked.value,
            companyAddresses: [
              {
                id: companyAddressId.value,
                companyAddress: address.value,
                companyCity: city.value,
                companyZipCode: zipCode.value,
                companyCountry: country.value,
              },
            ],
          },
        };

        try {
          await userCompany.editCompany(currCompany.value.id, data);
          if (companyAvatar.value) {
            try {
              await userCompany.uploadCompanyPicture(companyAvatar.value, currCompany.value.id, "COMPANY_AVATAR");
            } catch (e) {
              alertHelper.show(alert, t("profile.add_documents.file_type_error"), "", "error");
            }
          }
          if (!galleryUploading.value) {
            galleryUploading.value = true;
            for (let i = 0; i < companyGalery.value.length; i++) {
              if (typeof companyGalery.value[i].id === "number")
                await userCompany.uploadCompanyPicture(companyGalery.value[i].file, currCompany.value.id, "GALLERY");
            }
            galleryUploading.value = false;
          }
          const tempCompanyData = await userCompany.fetchOneCompany(currCompany.value.id);
          let role = await userCompany.getRoleInCompany(currCompany.value.id);
          if(role) storeCache.set("roleInCurrentCompany", role);
          storeCache.set("currentCompany", tempCompanyData);
          authStore.isLoading = false;
          storeCache.remove("verificationShown");
          storeCache.remove("ratingsShown");

          location.reload();
        } catch (err) {
          authStore.isLoading = false;
          alertHelper.show(alert, err, "", "error");
        }
      }
    });

    return {
      saveCompanyColumn,
      authStore,
      errors,
      address,
      company,
      city,
      zipCode,
      nip,
      fetched,
      phone,
      country,
      companyAvatar,
      handlePicture,
      companyGalery,
      imagesToDisplay,
      removeFromGalery,
      addPicture,
      roleInCompany,
      checked,
      profilePictureToDisplay,
      deleteProfilePicture,
      currCompany,
    };
  },
};
</script>

<style lang="scss" scoped>
.save-galery {
  font-size: 18px;
  background: #f5f5f5;
  color: #fff;
  cursor: pointer;
  min-width: fit-content;
  padding: 5px 20px;
  border-radius: 5px;
}
.col-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  gap: 10px 200px;
  @media screen and (max-width: 1380px) {
    gap: 10px 100px;
  }
  @media screen and (max-width: 1180px) {
    gap: 10px 20px;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
}
.col-2 {
  display: flex;
  justify-content: flex-start;
  @media screen and (max-width: 1380px) {
    flex-direction: column;
  }
}
.galery-custom-btn {
  p {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
  }
  .input-as-button {
    margin: auto;
    color: #112baf;
    border: 1px solid #112baf;
    background: #fff;
    width: fit-content;
    padding: 5px 10px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    @media screen and (max-width: 1380px) {
      margin: 0;
    }
  }
  .no-margin {
    margin: 0;
  }
}
.grid-it {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
  }
}
.span-col {
  grid-column: span 2;
  @media screen and (max-width: 650px) {
    grid-column: span 1;
  }
  input {
    min-width: 590px;
    @media screen and (max-width: 650px) {
      min-width: 100px;
    }
  }
}

h1 {
  min-width: fit-content;
  font-weight: 600;
  font-size: 12px;
  color: #1f222f;
}
.picture-measurement {
  width: 120px;
  max-height: 120px;
  object-fit: contain;
}
</style>
