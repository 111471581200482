export default {
  verificationPayment: {
    companyConfirmed: "Ta firma jest już zweryfikowana",
    verification: "Weryfikacja przelewem",
    confirm: "Czy chcesz potwierdzić autoryzację firmy?",
    modal1: {
      title: "Wybierz sposób autoryzacji",
      internetTransfer: "Przelew internetowy",
      p24: "Szybki przelew online za pomocą Przelewów24",
      traditionalTransfer: "Przelew tradycyjny",
      traditionalInfo: "Weryfikacja do 24 godzin od momentu zaksięgowania środków",
      sum: "Przelew należy wykonać na sumę 1,00 zł na poniższe dane.",
      return: "Środki zostaną zwrócone w ciągu 14 dni roboczych od weryfikacji.",
    },
    transferInfo: {
      name: "Nazwa odbiorcy",
      nameValue: "ANOTHER INVEST sp. z o.o.",
      address: "Adres odbiorcy",
      addressValue: "ul. Mikołaja Kopernika 5/LU9 00-367 Warszawa",
      account: "Nr konta",
      accountValue: "28 1090 2590 0000 0001 4866 7707",
      title: "Tytuł przelewu",
      amount:"Kwota",
      titleValue: "Weryfikacja konta ID firmy",
      download: "Pobierz druk",
    },
  },
};
