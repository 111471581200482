<template>
  <div class="grid-wrapper">
    <div v-for="element in keys" :key="element" class="knob-box smaller-knob">
      <Chart
        :status="element === 'value' ? 'accepted' : element === 'waitingCompanies' ? 'pending' : ''"
        :value="chart[0][element]"
        :max="chart[0].max"
      />
      <div class="section-in-right">
        <p class="number-in-chart">{{ chart[0][element] }}</p>
        <h1>
          {{ $t(`admin.dashboard.${element}`) }}
        </h1>
      </div>
    </div>
  </div>
  <filters
    :chosenFilters="['search']"
    :noClear="true"
    :filtersDetails="[
      {
        name: 'search',
        labelShort: $t('filters.company_search').substring(0, 20) + '...',
        label: $t('filters.company_search'),
        preChosen: searchValue,
        auto: true,
      },
    ]"
    @search="searchWithFilters"
  />
  <div v-if="reloadSwitchers" class="switch-wrapper switch-wrapper-admin" style="margin-bottom: 10px">
    <div class="tabs-wrapper">
      <div class="tab" :class="type === 'ALL' ? 'tab__active' : 'tab__disabled'" @click="() => setType('ALL')">
        <div>
          {{ $t("generals.all") }}
        </div>
      </div>
    </div>
  </div>
  <div v-if="reloadSwitchers" class="switch-wrapper switch-wrapper-admin">
    <div class="tabs-wrapper">
      <div class="tab" :class="type === 'ACTIVATED' ? 'tab__active' : 'tab__disabled'" @click="() => setType('ACTIVATED')">
        <div v-if="width > 850">
          {{ $t("authorizations.authorized") }}
        </div>
        <div v-else>
          <img src="@/assets/images/check.svg" alt="Warehouse" style="height: 24px; width: 24px" />
        </div>
      </div>
      <div class="tab" :class="type === 'WAITING_FOR_ACCEPTANCE' ? 'tab__active' : 'tab__disabled'" @click="() => setType('WAITING_FOR_ACCEPTANCE')">
        <div v-if="width > 850">Oczekujący</div>
        <div v-else>
          <img src="@/assets/images/question-solid.svg" alt="Warehouse" style="height: 24px; width: 24px" />
        </div>
      </div>
      <div class="tab" :class="type === 'BLOCKED' ? 'tab__active' : 'tab__disabled'" @click="() => setType('BLOCKED')">
        <div v-if="width > 850">{{ $t("authorizations.rejected") }}</div>
        <div v-else>
          <img src="@/assets/images/cancel.svg" alt="Warehouse" style="height: 24px; width: 24px" />
        </div>
      </div>
    </div>
  </div>
  <type-switcher
    v-if="reloadSwitchers"
    :typesValues="
      type === 'WAITING_FOR_ACCEPTANCE'
        ? [
            { type: 'DRIVER', value: waitingDriver },
            { type: 'WAREHOUSE', value: waitingWarehouse },
            { type: 'FACTORY', value: waitingFactory },
          ]
        : []
    "
    :noneMarked="initialState"
    :isAdmin="true"
    @changed="changedType"
    :tabsArray="types"
    :typeChosen="status"
  >
    <template #content>
      <div v-if="companies && companies.length > 0" class="flex-col" :key="status">
        <companies-table
          v-if="!loading"
          @newRecordsNumber="newRecordsNumber"
          :onPage="onPage"
          @details="(companyId) => redirectToCompanyDetails(companyId)"
          @selected="newCompany"
          @next="changePage('next')"
          @prev="changePage('prev')"
          @refresh="refreshCompaniesList"
          :user-type="status"
          :go-further="true"
          :currentPage="page"
          :modalAction="true"
          :data="companies"
          :total-pages="Math.ceil(total / onPage)"
          @newTableSorting="newTableSorting"
        >
          <template #modal-content>
            <div v-if="!editingMode" class="action-modal-content">
              <h1 v-if="!isLoading" id="my-dialog-title" class="dialog__title bolded">
                {{ $t("tableData.modalConfirmationCompany") }}
              </h1>
              <div v-if="!isLoading" class="buttons-grid">
                <button
                  :disabled="currentCompany.status === 'ACTIVATED' || currentCompany.status === 'DELETED' ? true : false"
                  type="button"
                  style="cursor: pointer"
                  class="btn btn-success"
                  @click="changeStatus('ACTIVATED')"
                >
                  {{ $t("generals.authorize") }}
                </button>
                <div v-if="currentCompany.isAccountVerified && type === 'WAITING_FOR_ACCEPTANCE'" class="email-verified-info">
                  <img src="@/assets/images/admin/email-verified.svg" alt="E-mail verified" style="height: 20px; width: 20px" />
                  <p>{{ $t("tableData.email_verified") }}</p>
                </div>
                <div v-else-if="type === 'WAITING_FOR_ACCEPTANCE'" class="email-verified-info">
                  <img src="@/assets/images/admin/email-unverified.svg" alt="Email unverified" style="height: 20px; width: 20px" />
                  <p>{{ $t("tableData.email_unverified") }}</p>
                </div>
                <button
                  :disabled="currentCompany.status === 'DELETED' ? true : false"
                  type="button"
                  class="btn btn-primary"
                  @click="editingMode = true"
                >
                  {{ $t("generals.edit") }}
                </button>
                <button
                  @click="changeStatus('BLOCKED')"
                  :disabled="currentCompany.status === 'BLOCKED' || currentCompany.status === 'DELETED' ? true : false"
                  type="button"
                  class="btn btn-warning"
                >
                  {{ $t("generals.block") }}
                </button>
                <!-- <button
                  @click="changeStatus('DELETED')"
                  type="button"
                  class="btn btn-danger"
                >
                  {{ $t("generals.delete") }}
                </button> -->
              </div>
              <div class="" v-else>
                <loader-fitting-into-container />
              </div>
            </div>
            <div v-else class="action-modal-content">
              <h1 id="my-dialog-title" style="font-weight: 700; text-align: center; margin-bottom: 20px" class="dialog__title">
                {{ $t("profile.edit_company") }}
              </h1>
              <edit-company
                :initial="currentCompany"
                @finished="companyEdited"
                @close="editingMode = false"
                @open-chat="() => (openChatModal = true)"
                @company-edited="companyEditedWithoutClosing"
                @close-edit-company="refreshData"
              />
            </div>
          </template>
          <template #modal-content-note>
            <div class="modal-content-note" @selected="selected">
              <h1 id="my-dialog-title" class="dialog__title">
                {{ $t("tableData.messageTitle") }}
              </h1>
              <textarea maxlength="5000" v-model="note" />

              <button @click="changeNote" class="btn btn-danger">
                {{ $t("tableData.btnAccept") }}
              </button>
            </div>
          </template>
        </companies-table>
        <div class="" v-else>
          <loader-fitting-into-container></loader-fitting-into-container>
        </div>
      </div>
    </template>
  </type-switcher>
  <div :style="`${openChatModal ? 'display: flex' : 'display: none'}`" class="chats-modal-wrapper">
    <div class="chats-modal">
      <div class="return-button-col custom-return-btn" @click="hanldeHideChatsModal">
        <div class="ret-btn">
          <button class="return-col arrow arrow-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-arrow-left"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </button>
        </div>
      </div>
      <AdminChatsView></AdminChatsView>
    </div>
  </div>
  <div class="no-data" v-if="companies.length === 0">
    <h1>{{ $t("snackbars.no_companies") }}</h1>
    <primary-button :red="true" :filled="true" :title="$t('generals.refresh')" @click="refresh" />
  </div>
</template>

<script>
import "@/css/small-elements/knob-box.scss";
import "@/css/admin/admin-users.scss";
import "@/css/admin/admin-products.scss";
import "@/css/admin/custom-admin-users.scss";
import AdminLayout from "@/layouts/AdminLayout";
import CompaniesTable from "./CompaniesTable";
import { storeToRefs } from "pinia";
import moment from "moment";
import { computed, inject, onMounted, ref, onUnmounted, onBeforeMount } from "vue";
import { useUsersLogiplannerStore } from "@/stores/admin/users/logiplanner.store";
import { useHelpersStore } from "@/stores/helpers.store";
import router from "@/router";
import TypeSwitcher from "@/components/common/TypeSwitcher";
import { prevPage, nextPage, setCurrentQueryParamsString } from "@/js/pagination.utils";
import { companyTypesAdmin as types } from "@/js/types";
import { types as types2 } from "@/js/types";
import selectSearchVue from "@/components/fields/select/selectSearch.vue";
import Chart from "@/components/common/Chart.vue";
import Filters from "@/components/common/Filters.vue";
import EditCompany from "@/components/admin/EditCompany";
import AlertHelper from "@/helpsers/alert.helper";
import LoaderFittingIntoContainer from "@/components/common/LoaderFittingIntoContainer.vue";
import i18n from "@/i18n";
import AdminChatsView from "../chat/AdminChatsView.vue";
import breakpointsHelper from "@/helpsers/breakpoints.helper";
import PrimaryButton from "@/components/common/PrimaryButton.vue";
import storeCache from "@/js/storeCache";
export default {
  components: {
    AdminLayout,
    CompaniesTable,
    TypeSwitcher,
    Chart,
    Filters,
    EditCompany,
    LoaderFittingIntoContainer,
    AdminChatsView,
    PrimaryButton,
  },
  emits: ["selected"],
  setup() {
    const addUserModal = ref();
    const emitter = inject("emitter");
    const userStore = useUsersLogiplannerStore();
    const helpersStore = useHelpersStore();
    const confirmationDialog = ref("");
    let currentCompany = ref({});
    let searchValue = ref("");
    let status = ref("");
    let type = ref("WAITING_FOR_ACCEPTANCE");
    let alert = inject("alert");
    let note = ref("");
    let keys = ref([]);
    let refreshFilters = ref(false);
    let { chart } = storeToRefs(userStore);
    const vehicleTypes = ["DRIVER", "WAREHOUSE", "FACTORY"];
    const statusTypes = ["ACTIVATED", "WAITING_FOR_ACCEPTANCE", "BLOCKED"];
    let initialState = ref(true);
    let chosenSorting = ref("");
    let reloadSwitchers = ref(true);
    let editingMode = ref(false);
    let loading = ref(false);
    const openChatModal = ref(false);
    let { companies, page, onPage, total, isLoading, companiesTypeNumber, waitingCompanies, waitingDriver, waitingWarehouse, waitingFactory } =
      storeToRefs(userStore);
    const { width } = breakpointsHelper();

    let queryParams = router.currentRoute.value.query;
    if (queryParams) {
      for (let paramName in queryParams) {
        if (paramName === "page" && page.value !== queryParams[paramName]) page.value = queryParams[paramName];
        if (paramName === "onPage" && onPage.value !== queryParams[paramName]) onPage.value = queryParams[paramName];
        if (paramName === "status" && status.value !== queryParams[paramName]) status.value = queryParams[paramName];
        if (paramName === "sorting" && status.value !== queryParams[paramName]) chosenSorting.value = queryParams[paramName];
        if (paramName === "search" && status.value !== queryParams[paramName]) searchValue.value = queryParams[paramName];
      }
    }

    onMounted(async () => {
      reloadSwitchers.value = false;
      if (status.value !== "") initialState.value = false;
      if (router.currentRoute.value.query.status) status.value = router.currentRoute.value.query.status;
      if (router.currentRoute.value.query.type) type.value = router.currentRoute.value.query.type;
      router.push({
        query: {
          status: status.value,
          type: type.value,
          onPage: onPage.value,
          page: page.value,
          sorting: chosenSorting.value,
          search: searchValue.value,
        },
      });
      refreshFilters.value = false;
      await userStore.countAllCompaniesWaiting();
      await userStore.fetchCompanies(page.value, onPage.value, type.value, status.value, searchValue.value, chosenSorting.value);
      await userStore.fetchChartInfo();
      keys.value = Object.keys(chart.value[0]);
      keys.value.splice(keys.value.indexOf("max"), 1);
      keys.value.splice(keys.value.indexOf("name"), 1);
      refreshFilters.value = true;
      const chatsWrapper = document.querySelector(".chats-modal-wrapper");
      if (chatsWrapper) chatsWrapper.addEventListener("click", hanldeHideChatsModal);
      const chatsModal = document.querySelector(".chats-modal");
      if (chatsModal)
        chatsModal.addEventListener("click", (e) => {
          e.stopPropagation();
        });

      reloadSwitchers.value = true;
    });
    onBeforeMount(() => {
      if (storeCache.get("recordsNumber")) {
        newRecordsNumber(storeCache.get("recordsNumber"));
      }
    });
    onUnmounted(() => {
      const chatsModal = document.querySelector(".chats-modal");
      const chatsWrapper = document.querySelector(".chats-modal-wrapper");
      if (chatsWrapper) chatsWrapper.removeEventListener("click", hanldeHideChatsModal);
      if (chatsModal)
        chatsModal.removeEventListener("click", (e) => {
          e.stopPropagation();
        });
    });

    const pagesNumber = computed(() => {
      if (total.value && onPage.value) return Math.ceil(total.value / onPage.value);
      else return 1;
    });

    emitter.on("userUpdated", () => {
      refreshCompaniesList();
    });
    emitter.on("selected", (e) => {
      console.log(e);
    });

    async function refreshCompaniesList() {
      loading.value = true;
      let params = setCurrentQueryParamsString(page.value, onPage.value);
      if (params) history.pushState({}, null, `${router?.currentRoute?.value?.path}?${params}`);
      router.push({
        query: {
          status: status.value,
          type: type.value,
          onPage: onPage.value,
          page: page.value,
          sorting: chosenSorting.value,
          search: searchValue.value,
        },
      });
      await userStore.fetchCompanies(page.value, onPage.value, type.value, status.value, searchValue.value, chosenSorting.value);
      setTimeout(() => {
        loading.value = false;
      }, 300);
      await userStore.countAllCompaniesWaiting();
      if (companies.value.length === 1 && (status.value !== companies.value[0].type || type.value !== companies.value[0].status)) {
        reloadSwitchers.value = false;

        status.value = companies.value[0].type;
        type.value = companies.value[0].status;
        router.push({
          query: {
            status: status.value,
            type: type.value,
            onPage: onPage.value,
            page: page.value,
          },
        });
        setTimeout(() => {
          reloadSwitchers.value = true;
        }, 100);

        setTimeout(() => {
          document.getElementById("form1")?.focus();
        }, 200);
      }
    }

    const fetchCompaniess = (e) => {
      e.preventDefault();
      refreshCompaniesList();
    };

    const newCompany = (e) => {
      editingMode.value = false;
      currentCompany.value = e;
      note.value = currentCompany.value.note;
    };

    const refresh = async () => {
      searchValue.value = "";
      await userStore.fetchCompanies(page.value, onPage.value, type.value, status.value, searchValue.value, chosenSorting.value);
    };

    const changedType = (type) => {
      searchValue.value = "";
      initialState.value = false;
      status.value = type;
      page.value = 1;
      if (type === "ALL") {
        status.value = "";
        helpersStore.setTypeSwitcherValue("");
      }
      refreshCompaniesList();
    };
    const setType = (x) => {
      page.value = 1;
      searchValue.value = "";
      type.value = x;
      if (x === "ALL") {
        status.value = "";
        helpersStore.setTypeSwitcherValue("");
      }
      refreshCompaniesList();
    };
    const changePage = (direction) => {
      if (direction === "next") {
        page.value = nextPage(page.value, pagesNumber.value);
      } else if (direction === "prev") {
        page.value = prevPage(page.value);
      }
      refreshCompaniesList();
    };
    const changeStatus = async (status) => {
      const obj = {
        alert: alert,
        companyId: currentCompany.value.id,
        companyStatus: status,
      };
      await userStore.changeUserStatus(obj);
      helpersStore.hideModal();
      refreshCompaniesList();
    };
    const changeNote = async () => {
      await userStore.updateUserNote({
        id: currentCompany.value.id,
        note: note.value,
      });
      refreshCompaniesList();
      setTimeout(() => document.querySelector("#confirmation-dialog2 button").click(), 500);
    };

    const companyEdited = () => {
      AlertHelper.show(alert, i18n.global.t("snackbars.company_edit_success"), "", "success");
      refreshCompaniesList();
      helpersStore.hideModal();
    };

    const companyEditedWithoutClosing = () => {
      AlertHelper.show(alert, i18n.global.t("snackbars.company_edit_success"), "", "success");
    };

    const hanldeHideChatsModal = () => {
      openChatModal.value = false;
    };

    const refreshData = async () => {
      refreshCompaniesList();
      helpersStore.hideModal();
    };

    const searchWithFilters = (searchValues) => {
      page.value = 1;
      searchValue.value = searchValues.searchValue;
      chosenSorting.value = searchValues.chosenSorting;
      refreshCompaniesList();
    };
    const newRecordsNumber = async (records) => {
      if (records && records !== "" && records > 0) {
        onPage.value = records;
        page.value = 1;
        storeCache.set("recordsNumber", records);
        await refreshCompaniesList();
      }
    };

    const newTableSorting = async (sorting) => {
      chosenSorting.value = sorting;
      await refreshCompaniesList();
    };

    const redirectToCompanyDetails = (companyId) => {
      const found = companies.value.find((company) => company.id === companyId);
      searchValue.value = found?.companyName;
      router.push({
        query: {
          status: status.value,
          type: type.value,
          onPage: onPage.value,
          page: page.value,
          sorting: chosenSorting.value,
          search: searchValue.value,
        },
      });
      setTimeout(() => {
        router.push(`/admin/company-details?id=${companyId}`);
      }, 100);
    };
    return {
      companies,
      redirectToCompanyDetails,
      newTableSorting,
      refresh,
      page,
      companiesTypeNumber,
      searchWithFilters,
      onPage,
      total,
      pagesNumber,
      prevPage,
      nextPage,
      isLoading,
      addUserModal,
      confirmationDialog,
      newCompany,
      changePage,
      currentCompany,
      types,
      types2,
      changedType,
      setType,
      changeStatus,
      changeNote,
      status,
      router,
      type,
      note,
      width,
      searchValue,
      fetchCompaniess,
      waitingCompanies,
      chart,
      keys,
      waitingDriver,
      waitingWarehouse,
      waitingFactory,
      initialState,
      reloadSwitchers,
      editingMode,
      companyEdited,
      refreshFilters,
      chosenSorting,
      newRecordsNumber,
      openChatModal,
      hanldeHideChatsModal,
      companyEditedWithoutClosing,
      refreshData,
      loading,
      refreshCompaniesList,
    };
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  margin: auto;
  margin-top: 15px;
  width: fit-content;
  background: #fff;
  padding: 10px 0px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
}
.grid-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  place-items: center;
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 800px) {
  .wrapper {
    padding: 20px;
  }
}
.admin-products {
  background: #fff;
}
.icon-admin {
  width: 30px;
  height: 30px;
  transition: 0.2s all;
  margin-right: 10px;
  opacity: 0.8;
}
.icon-admin:hover {
  transform: translateY(-5px);
}
.details-table {
  margin-top: 20px;
  p {
    color: #f5f5f5;
    font-size: 14px;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .row {
    display: flex;
    flex-direction: row !important;
  }
}
.search-input-form {
  input {
    font-size: 14px;
  }
}
.buttons-grid {
  display: grid;
  place-items: center;
  margin-top: 20px;
  .btn {
    font-size: 20px;
    width: 60%;
    margin: 10px 0;
    color: #fff;
    padding: 10px 0;
  }
  button {
    cursor: pointer !important;
  }
  button:nth-of-type(1) {
    background-color: #1fe76f;
  }
  button:nth-of-type(2) {
    background-color: #2f55b9;
  }
  button:nth-of-type(3) {
    background-color: #ff3427;
  }
}
.title-with-underline {
  margin-top: 20px;
  margin-bottom: 10px !important;
  @media screen and (max-width: 1250px) {
    margin-left: 40px;
  }
}
.filters {
  margin: 10px 5px;
}
form {
  display: flex;
  justify-content: center;
  label {
    font-size: 14px;
    margin-top: 5px;
  }
}
@media (max-width: 500px) {
  input[type="search"] {
    max-width: 200px !important;
  }
  .tab {
    width: 80px !important;
  }
}
.modal-content-note {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 15px;
  font-size: 16px;
  textarea {
    min-height: 200px;
    min-width: 280px;
    width: 100%;
  }
  button {
    font-size: 16px;
  }
  h1 {
    font-size: 20px;
  }
}
.switch-wrapper {
  box-shadow: none !important;
  margin-bottom: 3px !important;
  margin-top: 3px !important;
  padding: 0 !important;
}
.no-data {
  display: grid;
  place-items: center;
  margin-top: 20px;
  button {
    max-width: 300px;
    font-weight: 500;
  }
}
.action-modal-content {
  padding: 30px 0;
}
button:disabled,
button[disabled] {
  opacity: 0.3;
}
.knob-box {
  cursor: auto !important;
}

.email-verified-info {
  display: flex;
  align-items: center;
  gap: 5px;
  p {
    font-size: 10px;
    font-weight: 700;
    margin: 0;
  }
}

.bolded {
  font-weight: 700;
  text-align: center;
}
.flex-col {
  display: grid;
  place-items: center;
}
.chats-modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999999999;
  background-color: rgba(0, 0, 0, 0.7);
  .chats-modal {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    .custom-return-btn {
      position: absolute;
      @media screen and (max-width: 450px) {
        position: static;
      }
    }
  }
}
</style>
