import UserLayout from "@/layouts/UserLayout.vue";
import GuestLayout from "@/layouts/GuestLayout.vue";
import { typeOfCompany } from "@/js/utils";
import UserView from "@/views/user/profile/UserView.vue";
import AddDocumentsView from "@/views/user/documents/AddDocumentsView.vue";
import AddUserView from "@/views/user/workers/AddUserView.vue";
import ListUsersView from "@/views/user/workers/ListUsersView.vue";
import LanguageView from "@/views/user/LanguageView.vue";
import CompanyEditView from "@/views/user/profile/CompanyEditView.vue";
import MyProfileEditView from "@/views/user/profile/MyProfileEditView.vue";
import ProfileSettingsView from "@/views/user/profile/ProfileSettingsView.vue";
import AddWarehouseView from "@/views/user/warehouses/AddWarehouseView.vue";
import ListWarehouses from "@/views/user/warehouses/ListWarehouses.vue";
import TestMonthly from "@/views/user/calendar/TestMonthly.vue";
import ChoseCalendarToFillView from "@/views/user/calendar/ChoseCalendarToFillView.vue";
import CalendarView from "@/views/user/calendar/CalendarView.vue";
import EditCalendarDayView from "@/views/user/calendar/EditCalendarDayView.vue";
import AddUserRequest from "@/views/user/workers/AddUserRequestView.vue";
import ListDepartmentsView from "@/views/user/departments/ListDepartmentsView.vue";
import DepartmentDetailsView from "@/views/user/departments/DepartmentDetailsView.vue";
import ListDocumentsView from "@/views/user/documents/ListDocumentsView.vue";
import AddWorkerToWarehouse from "@/views/user/warehouses/AddWorkerToWarehouse.vue";
import RecieveInvitaitonView from "@/views/user/departments/RecieveInvitaitonView.vue";
import ListAuthorizationsView from "@/views/user/authorizations/ListAuthorizationsView.vue";
import DocumentListView from "@/views/user/documents/DocumentListView.vue";
import AcceptAuthorizationView from "@/views/user/authorizations/AcceptAuthorizationView.vue";
import AddCarView from "@/views/user/cars/AddCarView.vue";
import ListCarsView from "@/views/user/cars/ListCarsView.vue";
import AddMonitaryNoteView from "@/views/user/monitary/AddMonitaryNoteView.vue";
import ListMonitaryNotesView from "@/views/user/monitary/ListMonitaryNotesView.vue";
import EditMonitaryNoteView from "@/views/user/monitary/EditMonitaryNoteView.vue";
import ListMonitaryArchive from "@/views/user/monitary/ListMonitaryArchive.vue";
import StockView from "@/views/user/stock/StockView.vue";
import WalletView from "@/views/user/payment/WalletView.vue";
import PaymentProcessingView from "@/views/user/payment/PaymentProcessingView.vue";
import ListInsurances from "@/views/user/cars/ListInsurances.vue";
import MonitaryAcceptance from "@/views/user/monitary/MonitaryAcceptance.vue";
import MonitaryFinish from "@/views/user/monitary/MonitaryFinish.vue";
import NotificationView from "@/views/user/notifications/NotificationView.vue";
import CarStatusesView from "@/views/user/monitary/CarStatusesView.vue";
import LeasingView from "@/views/user/LeasingView.vue";
import ChatListView from "@/views/user/chat/ChatListView.vue";
import ChatView from "@/views/user/chat/ChatView.vue";
import MyAuctionOffers from "@/views/user/stock/MyAuctionOffers.vue";
import ListWonAuctions from "@/views/user/stock/ListWonAuctions.vue";
import ListLostAuctions from "@/views/user/stock/ListLostAuctions.vue";
import IncludedTransactions from "@/views/user/stock/IncludedTransactions.vue";
import ListSurcharges from "@/views/user/stock/ListSurcharges.vue";
import AuctionsByCompanyView from "@/views/user/stock/AuctionsByCompanyView.vue";
import ListWonButCancelledAuctions from "@/views/user/stock/ListWonButCancelledAuctions.vue";
import EditableAuctionsView from "@/views/user/stock/EditableAuctionsView.vue";
import CancelledAuctionsView from "@/views/user/stock/CancelledAuctionsView.vue";
import ManageAgreements from "@/views/user/ManageAgreements.vue";
import CreateGroupView from "@/views/user/stock/CreateGroupView.vue";
import ListParticipants from "@/views/user/stock/ListParticipants.vue";
import MyGroup from "@/views/user/stock/MyGroup.vue";
import ListWarehousesForFactory from "@/views/user/warehouses/ListWarehousesForFactory.vue";
import InvoicesView from "@/views/user/payment/InvoicesView.vue";
import AuctionArchive from "@/views/user/stock/AuctionArchive.vue";
import FactoringList from "@/views/user/stock/FactoringList.vue";
import BlockedDepartments from "@/views/user/BlockedDepartments.vue";
import { Role } from "@/helpsers/role.helper";
import { createService } from "@/js/utils";
import i18n from "@/i18n";
import AddingMainPage from "@/views/user/AddingMainPage.vue";
import Assistant from "@/views/user/Assistant.vue";
import AddDriverPage from "@/views/user/drivers/AddDriverPage.vue";
import ListDriversPage from "@/views/user/drivers/ListDriversPage.vue";
import WalletViewSubscribe from "@/views/user/payment/WalletViewSubscribe.vue";
import AllInvoicesView from "@/views/user/payment/AllInvoicesView.vue";
import AddAuctionView from "@/views/user/stock/auction-adding/AddAuctionView.vue";
import AddAuctionThirdView from "@/views/user/stock/auction-adding/AddAuctionThirdView.vue";
import AddDepartmentView from "@/views/user/warehouses/AddDepartmentView.vue";
import AddAuctionSecondViewVue from "@/views/user/stock/auction-adding/AddAuctionSecondView.vue";
import ListOutsideDepartments from "@/views/user/outside-departments/ListOutsideDepartments.vue";
import AddOutsideDepartment from "@/views/user/outside-departments/AddOutsideDepartment.vue";
import { RouteLocationNormalized } from "vue-router";
import DashboardTimeWindows from "@/views/user/dashboards/DashboardTimeWindows.vue";
import DashboardAuctionsVue from "@/views/user/dashboards/DashboardAuctions.vue";
import DashboardWallet from "@/views/user/dashboards/DashboardWallet.vue";
import DashboardBank from "@/views/user/dashboards/DashboardBank.vue";
import Dashboard from "@/views/user/dashboards/Dashboard.vue";
const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.dashboard"),
      authorize: [Role.Driver, Role.Warehouse, Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/dashboard/okna-czasowe",
    name: "dashboard-timewindows",
    component: DashboardTimeWindows,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.dashboard"),
      authorize: [Role.Driver, Role.Warehouse, Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/dashboard/aukcje",
    name: "dashboard-auctions",
    component: DashboardAuctionsVue,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.dashboard"),
      authorize: [Role.Driver, Role.Warehouse, Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/dashboard/portfel",
    name: "dashboard-wallet",
    component: DashboardWallet,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.dashboard"),
      authorize: [Role.Driver, Role.Warehouse, Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/dashboard/bank",
    name: "dashboard-bank",
    component: DashboardBank,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.dashboard"),
      authorize: [Role.Driver, Role.Warehouse, Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/my-profile",
    component: UserView,
    beforeEnter: () => createService(),
    children: [
      {
        path: "edit",
        name: "/edit",
        component: MyProfileEditView,
        meta: {
          title: i18n.global.t("routes.user.profile"),
          authorize: [Role.Driver, Role.Warehouse, Role.Factory, Role.User],
          layout: UserLayout,
        },
      },
    ],
  },
  {
    path: "/company",
    component: UserView,
    beforeEnter: () => createService(),
    children: [
      {
        path: "edit",
        name: "/edit-company",
        component: CompanyEditView,
        meta: {
          title: i18n.global.t("routes.user.company"),
          authorize: [Role.Driver, Role.Warehouse, Role.Factory, Role.User],
          layout: UserLayout,
        },
      },
    ],
  },

  {
    path: "/profile-settings",
    name: "profile-settings",
    component: ProfileSettingsView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.settings"),
      authorize: [Role.Driver, Role.Warehouse, Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/add-documents",
    name: "add-documents",
    component: AddDocumentsView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.add_document"),
      authorize: [Role.Driver, Role.Warehouse, Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/add-user",
    name: "add-user",
    component: AddUserView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.add_worker"),
      authorize: [Role.Driver, Role.Warehouse, Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/add-warehouse",
    name: "add-warehouse",
    component: typeOfCompany() === "WAREHOUSE" || typeOfCompany() === "FACTORY" ? AddWarehouseView : AddDepartmentView,
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      createService();
      if ((typeOfCompany() === "WAREHOUSE" || typeOfCompany() === "FACTORY") && to.matched[0]?.components) {
        to.meta.title = i18n.global.t("routes.user.add_warehouse");
        to.matched[0].components.default = AddWarehouseView;
      }
    },
    meta: {
      title:
        typeOfCompany() === "WAREHOUSE" || typeOfCompany() === "FACTORY"
          ? i18n.global.t("routes.user.add_warehouse")
          : i18n.global.t("routes.user.add_department"),
      authorize: [Role.Warehouse, Role.User],
      layout: UserLayout,
    },
  },

  {
    path: "/pracownicy",
    name: "list-users",
    component: ListUsersView,
    beforeEnter: () => createService(),

    meta: {
      title: i18n.global.t("routes.user.workers"),
      authorize: [Role.Driver, Role.Warehouse, Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/list-warehouses",
    name: "list-warehouses",
    component: ListWarehouses,
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      if (typeOfCompany() === "WAREHOUSE" || typeOfCompany() === "FACTORY") {
        to.meta.title = i18n.global.t("routes.user.warehouses");
      }
      createService();
    },
    meta: {
      title:
        typeOfCompany() === "WAREHOUSE" || typeOfCompany() === "FACTORY"
          ? i18n.global.t("routes.user.warehouses")
          : i18n.global.t("routes.user.departments"),
      authorize: [Role.Warehouse, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/list-warehouses-factory",
    name: "list-warehouses-factory",
    component: ListWarehousesForFactory,
    beforeEnter: () => createService(),

    meta: {
      title: i18n.global.t("routes.user.warehouses"),
      authorize: [Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/calendar/:id",
    name: "calendar",
    component: CalendarView,
    beforeEnter: () => createService(),

    meta: {
      title: i18n.global.t("routes.user.calendar"),
      authorize: [Role.Warehouse, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/fill-callendar",
    name: "fill-callendar",
    // component: FillCallendarMonthlyView,
    component: TestMonthly,
    beforeEnter: () => createService(),

    meta: {
      title: i18n.global.t("routes.user.fill_calendar"),
      authorize: [Role.Warehouse, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/wybierz-kalendarz",
    name: "fill-callendar-choice",
    component: ChoseCalendarToFillView,
    beforeEnter: () => createService(),

    meta: {
      title: i18n.global.t("routes.user.pick_calendar"),
      authorize: [Role.Warehouse, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/edit-calendar-day/:id",
    name: "edit-calendar-day",
    component: EditCalendarDayView,
    beforeEnter: () => createService(),

    meta: {
      title: i18n.global.t("routes.user.edit_day"),
      authorize: [Role.Warehouse, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/zaproszenie/:invitationId?",
    name: "add-user-request",
    component: AddUserRequest,
    meta: {
      title: i18n.global.t("routes.user.add_user"),
      authorize: [Role.Admin, Role.Owner, Role.User],
      layout: UserLayout,
    },
  },

  {
    path: "/language",
    name: "language",
    component: LanguageView,
    beforeEnter: () => createService(),

    meta: {
      title: i18n.global.t("routes.user.language"),
      authorize: [Role.Driver, Role.Warehouse, Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/lista-magazynow",
    name: "departments-list",
    component: ListDepartmentsView,
    beforeEnter: (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      createService();
      if (typeOfCompany() === "WAREHOUSE" || typeOfCompany() === "FACTORY") {
        to.meta.title = i18n.global.t("routes.user.driver_departments");
      } else {
        to.meta.title = i18n.global.t("routes.user.warehouses");
      }
    },
    meta: {
      title: typeOfCompany() === "DRIVER" ? i18n.global.t("routes.user.warehouses") : i18n.global.t("routes.user.driver_departments"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/oddzial",
    name: "departments-details",
    component: DepartmentDetailsView,
    beforeEnter: () => createService(),

    meta: {
      title: i18n.global.t("routes.user.department_details"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/dokumenty",
    name: "documents",
    component: ListDocumentsView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.documents"),
      authorize: [Role.Owner, Role.User, Role.Warehouse, Role.Driver, Role.Factory],
      layout: UserLayout,
    },
  },
  {
    path: "/oddzial/pracownicy",
    name: "departments-workers",
    component: AddWorkerToWarehouse,
    beforeEnter: () => createService(),
    meta: {
      title:
        typeOfCompany() === "WAREHOUSE" || typeOfCompany() === "FACTORY"
          ? i18n.global.t("routes.user.warehouse_workers")
          : i18n.global.t("routes.user.department_workers"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/zaproszenie-do-oddzialu/:id",
    name: "department-invite",
    component: RecieveInvitaitonView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.department_invite"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/autoryzacja-oddzialu/:id",
    name: "auth-invite",
    component: AcceptAuthorizationView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.authorization"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/autoryzacje",
    name: "authorizations",
    component: ListAuthorizationsView,
    beforeEnter: () => createService(),
    meta: {
      title:
        i18n.global.t("routes.user.authorizations") +
        `${typeOfCompany() === "DRIVER" ? ` ${i18n.global.t("generals.ofDepartments")}` : ` ${i18n.global.t("generals.ofWarehouses")}`}`,
      authorize: [Role.Driver, Role.User, Role.Warehouse],
      layout: UserLayout,
    },
  },
  {
    path: "/dodaj-auto",
    name: "add-car",
    component: AddCarView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.add_car"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/auta",
    name: "list-cars",
    component: ListCarsView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.cars"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/ubezpieczyciele",
    name: "insurances",
    component: ListInsurances,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.insurances"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/awizacja",
    name: "add-monitary",
    component: AddMonitaryNoteView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.add_monitary"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/statusy-aut",
    name: "monitary-cars",
    component: CarStatusesView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.car_statuses"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/potwierdzenie-awizacji/:id",
    name: "accept-monitary",
    component: MonitaryAcceptance,
    meta: {
      title: i18n.global.t("routes.user.accept_monitary"),
      layout: GuestLayout,
    },
  },
  {
    path: "/zakonczenie-awizacji/:id",
    name: "finish-monitary",
    component: MonitaryFinish,
    meta: {
      title: i18n.global.t("routes.user.finish_monitary"),
      layout: GuestLayout,
    },
  },
  {
    path: "/lista-awizacji",
    name: "list-monitary",
    component: ListMonitaryNotesView,
    beforeEnter: () => createService(),
    meta: {
      title:
        i18n.global.t("routes.user.monitary_list") +
        `${typeOfCompany() === "DRIVER" ? ` ${i18n.global.t("generals.ofDepartments")}` : ` ${i18n.global.t("generals.ofWarehouses")}`}`,
      authorize: [Role.Driver, Role.Warehouse, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/archiwum-awizacji",
    name: "archive-monitary",
    component: ListMonitaryArchive,
    beforeEnter: () => createService(),
    meta: {
      title:
        i18n.global.t("routes.user.monitary_archive") +
        `${typeOfCompany() === "DRIVER" ? ` ${i18n.global.t("generals.ofDepartments")}` : ` ${i18n.global.t("generals.ofWarehouses")}`}`,
      authorize: [Role.Driver, Role.Warehouse, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/edytuj-awizacje/:id/:departmentId",
    name: "edit-monitary",
    component: EditMonitaryNoteView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.monitary_edit"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/gielda",
    name: "stock",
    component: StockView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.stock"),
      authorize: [Role.Driver, Role.User, Role.Warehouse],
      layout: UserLayout,
    },
  },
  {
    path: "/dodaj-aukcje",
    name: "add-auction",
    component: AddAuctionView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.add_auction"),
      authorize: [Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  // {
  //   path: "/dodaj-aukcje/2",
  //   name: "add-auction-second",
  //   component: AddAuctionSecondView,
  //   beforeEnter: () => createService(),
  //   meta: {
  //     title: i18n.global.t("routes.user.add_auction"),
  //     authorize: [Role.Factory, Role.User],
  //     layout: UserLayout,
  //   },
  // },
  {
    path: "/dodaj-aukcje/2",
    name: "add-auction-second",
    component: AddAuctionSecondViewVue,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.add_auction"),
      authorize: [Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/dodaj-aukcje/3",
    name: "add-auction-third",
    component: AddAuctionThirdView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.add_auction"),
      authorize: [Role.Factory, Role.User],
      layout: UserLayout,
    },
  },

  {
    path: "/moj-portfel-subskrypcje",
    name: "subscription-wallet",
    component: WalletViewSubscribe,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.wallet"),
      authorize: [Role.Driver, Role.User, Role.Warehouse],
      layout: UserLayout,
    },
  },
  {
    path: "/moj-portfel",
    name: "wallet",
    component: WalletView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.wallet"),
      authorize: [Role.Driver, Role.User, Role.Warehouse],
      layout: UserLayout,
    },
  },
  {
    path: "/przetwarzanie-platnosci",
    name: "payment-processing",
    component: PaymentProcessingView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.payment_processing"),
      authorize: [Role.Driver, Role.User, Role.Warehouse],
      layout: UserLayout,
    },
  },

  {
    path: "/powiadomienia",
    name: "notifications",
    component: NotificationView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.notifications"),
      authorize: [Role.Driver, Role.User, Role.Warehouse],
      layout: UserLayout,
    },
  },
  {
    path: "/leasing",
    name: "leasing",
    component: LeasingView,
    beforeEnter: () => createService(),
    meta: {
      title: "Leasing",
      authorize: [Role.Driver, Role.User, Role.Warehouse],
      layout: UserLayout,
    },
  },
  {
    path: "/lista-czatow",
    name: "chat-list",
    component: ChatListView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.chat_list"),
      authorize: [Role.Driver, Role.User, Role.Warehouse],
      layout: UserLayout,
    },
  },
  {
    path: "/chat",
    name: "chat-view",
    component: ChatView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.chat"),
      authorize: [Role.Driver, Role.User, Role.Warehouse],
      layout: UserLayout,
    },
  },
  {
    path: "/moje-oferty",
    name: "my-offers",
    component: MyAuctionOffers,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.offers"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/wygrane-aukcje",
    name: "won-auctions",
    component: ListWonAuctions,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.won_auctions"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/przegrane-aukcje",
    name: "lost-auctions",
    component: ListLostAuctions,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.lost_auctions"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/anulowane-transporty",
    name: "canceled-auctions",
    component: ListWonButCancelledAuctions,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.canceled_transports"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/uczestniczace-aukcje",
    name: "included-auctions",
    component: IncludedTransactions,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.current_auctions"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/doplaty",
    name: "surcharges",
    component: ListSurcharges,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.surcharges"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/moje-aukcje",
    name: "my-auctions",
    component: AuctionsByCompanyView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.my_auctions"),
      authorize: [Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/archiwum-aukcji",
    name: "archive-auctions",
    component: AuctionArchive,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.auction_archive"),
      authorize: [Role.Factory, Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/edytuj-aukcje",
    name: "list-auctions-to-edit",
    component: EditableAuctionsView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.edit_auction"),
      authorize: [Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/anulowane-aukcje",
    name: "cancelled-auctions",
    component: CancelledAuctionsView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.cancelled_auctions"),
      authorize: [Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/warunki-umowy",
    name: "manage-agreements",
    component: ManageAgreements,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.contract_terms"),
      authorize: [Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/tworzenie-grupy",
    name: "making-group",
    component: CreateGroupView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.group_making"),
      authorize: [Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/lista-uczestnikow",
    name: "list-participants",
    component: ListParticipants,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.participants_list"),
      authorize: [Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/moje-grupy",
    name: "my-groups",
    component: MyGroup,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.added_driver_groups"),
      authorize: [Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/faktury",
    name: "invoices",
    component: InvoicesView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.invoices"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/wszystkie-faktury",
    name: "invoices-all",
    component: AllInvoicesView,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.invoices"),
      authorize: [Role.Driver, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/zablokowani",
    name: "blocks",
    component: BlockedDepartments,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.blocked_users"),
      authorize: [Role.Driver, Role.Warehouse, Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/faktoring",
    name: "factoring-list",
    component: FactoringList,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("routes.user.factoring"),
      authorize: [Role.Factory, Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/dodawanie",
    name: "adding",
    component: AddingMainPage,
    beforeEnter: () => createService(),
    meta: {
      title: i18n.global.t("addings.adding"),
      authorize: [Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/asystent",
    name: "assistant",
    component: Assistant,
    beforeEnter: () => createService(),
    meta: {
      title: "Asystent",
      authorize: [Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/dodaj-kierowce",
    name: "add-driver",
    component: AddDriverPage,
    beforeEnter: () => createService(),
    meta: {
      title: "Dodawanie kierowcy",
      authorize: [Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/kierowcy",
    name: "list-drivers",
    component: ListDriversPage,
    beforeEnter: () => createService(),
    meta: {
      title: "Lista kierowców",
      authorize: [Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/magazyny-zewnetrzne",
    name: "list-outside-department",
    component: ListOutsideDepartments,
    beforeEnter: () => createService(),
    meta: {
      title: "Lista magazynów zewnętrznych",
      authorize: [Role.User],
      layout: UserLayout,
    },
  },
  {
    path: "/dodaj-magazyn-zewnetrzny",
    name: "add-outside-department",
    component: AddOutsideDepartment,
    beforeEnter: () => createService(),
    meta: {
      title: "Dodawanie magazynu zewnętrznego",
      authorize: [Role.User],
      layout: UserLayout,
    },
  },
];

export default routes;
