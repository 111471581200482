const generals = {
  timeFrames: "Ramy czasowe",
  main_page: "Strona główna",
  all: "Wszystkie",
  invitation_sent:"Wysłano zaproszenie",
  company_invitation:"Zaproszenie firmy",
  close: "Zamknij",
  see: "Zobacz",
  title: "Tytuł",
  content: "Treść",
  unBlock: "Odblokuj",
  wallet: "Portfel",
  error: "Błąd",
  return: "Powrót",
  fold: "Zwiń",
  operation: "Operacja",
  reserve: "Zarezerwuj",
  new: "Nowe",
  add_windows:"Doładuj okna czasowe",
  reservation_success:"Pomyślnie wysłano prośbę o awizację",
  delete: "Usuń",
  cmr: "Przejdź do dokumentu CMR",
  from_driver:"Ze strony przewoźnika",
  for_provision:"Za prowizję",
  "transporting-doc": "Dokument zlecenia transp.",
  edit: "Edytuj",
  loading: "Wczytywanie...",
  authorizations: "Autoryzacje",
  warehouse: "Magazyn",
  monitary_notes: "Awizacje",
  accepted: "Zaakceptowane",
  rejected: "Odrzucone",
  opening: "Otwarcie",
  closing: "Zamknięcie",
  save: "Zapisz",
  saving: "Zapisuje...",
  authorize: "Autoryzuj",
  reject: "Odrzuć",
  block: "Zablokuj",
  see_details: "Zobacz",
  add: "Dodaj",
  change_workers: "Zarządzaj pracownikami",
  add_pics: "Dodaj zdjęcia",
  add_avatar: "Dodaj zdjęcie",
  added_avatar: "Dodano awatar",
  department: "Oddział",
  actions: "",
  action: "Akcja",
  workers: "Pracownicy",
  fullName: "Imię i nazwisko",
  elements_visible: "Wierszy na stronie",
  notepad: "Notatnik",
  message: "Wiadomość",
  documents: "Dokumenty",
  true: "Prawda",
  false: "Fałsz",
  yes: "Tak",
  no: "Nie",
  success: "Sukces",
  successful_edit: "Pomyślnie edytowano",
  archive: "Zakończ",
  accept: "Akceptuj",
  added: "Dodano",
  step: "Krok",
  download: "Pobierz",
  go_further: "Przejdź dalej",
  closed: "Zamknięte",
  logout: "Wyloguj",
  language: "Język",
  agreement: "Umowa",
  invoices: "Faktury",
  from: "Od",
  to: "Do",
  back: "Cofnij",
  search: "Szukaj",
  retreat: "Wycofaj",
  phone: "phone",
  no_id: "brak identyfikatora",
  no_drivers: "Brak kierowców",
  ignore: "Pomiń",
  departments: "Oddziały",
  verify: "Weryfikuj",
  status: "Status",
  chat: "Czat",
  note: "Notatka",
  details: "Szczegóły",
  unfold: "Rozwiń",
  departments_count: "Liczba oddziałów",
  warehouse_count: "Liczba magazynów",
  change_number: "Edytuj numer",
  change_number_enter: "Wpisz nowy numer telefonu",
  refresh: "Odśwież",
  home: "Szczegóły",
  offer: "Złóż ofertę",
  my_offers: "Moje oferty",
  contact: "Kontakt",
  data: "Aukcja",
  auto: "Automatyczne przebijanie",
  diary: "Dziennik transakcji",
  changelog: "Protokół zmian",
  stock_chat: "Wyślij wiadomość",
  fullscreen: "Zmiana wielkości",
  for_day: "na dzień",
  out_of: "z",
  next: "Dalej",
  leave: "Wyjdź",
  confirm: "Potwierdź",
  auction_nr: "Aukcja nr",
  monitory_nr: "Awizacja nr",
  mine: "Moja",
  generally: "Ogólnie",
  reservation: "Rezerwacja",
  returning: "Zwrot",
  no_cars: "Brak aut",
  left: "Pozostało",
  minutes: "min.",
  group_message: "Wiadomość grupowa",
  to_group: "Do grup użytkowników",
  to_specific: "Do konkretnych oddziałów",
  chosen_departments: "Wybrane oddziały",
  docs: "Dokumenty firmy",
  data_refreshed: "Odświeżono dane",
  mark_read: "Oznacz",
  no_data: "Brak danych",
  creation_date: "Data utworzenia",
  preview: "Podgląd",
  change: "Zmień",
  danger: "Uwaga",
  copy_to_clipboard: "Kopiuj",
  copy_to_clipboard_success: "Skopiowano do schowka",
  copy_to_clipboard_error: "Skopiowanie do schowka nie powiodło się",
  open_chat: "Otwórz czat",
  confirm_action: "Potwierdź",
  date_expired: "Upłynął termin",
  days_helper: "{0} dni",
  load: "Ładunek",
  mark_unread: "Oznacz jako nieodczytaną",
  too_long_title: "Zbyt długi tytuł",
  month: "Miesiąc",
  year: "Rok",
  unavailable: "Niedostępne",
  lack: "Brak",
  downloadTimeout: "Musisz odczekać kilka sekund, zanim będziesz mógł znów pobierać pliki.",
  pick: "Wybierz",
  downloadableDocuments: "Dokumenty do pobrania",
  ofWarehouses: "magazynów",
  ofDepartments: "oddziałów",
  pay: "Opłać",
  setProvision: "Ustal prowizję dla licytacji",
  verificationConfirmation: "Czy na pewno chcesz odręcznie zweryfikować firmę?",
  rating: "Ocena",
  send: "Wyślij",
  countWorkers: "Liczba pracowników",
  verificationPendingTitle: "Autoryzacja w toku.",
  verificationDemandTitle: "Wymagana autoryzacja!",
  verificationPendingDesc: "Autoryzacja jest w trakcie przetwarzania.",
  verificationDemandDesc: "W celu uruchomienia pełnej funkcjonalności Platformy LOGIplanner.eu, dokonaj autoryzacji swojej firmy.",
  authorizePayment: "Dokonaj autoryzacji",
};
export default generals;
