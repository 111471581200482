import { defineStore } from "pinia";
import axios from "@/axios";
import i18n from "@/i18n";
import ApiHelper from "@/helpsers/api.helper";
import authHelper from "@/helpsers/auth.helper";
import { inject, ref } from "vue";
import AlertHelper from "@/helpsers/alert.helper";
import { parseError } from "@/js/error";

export const useFactoringStore = defineStore("factoring", () => {
  let alert = inject("alert");
  const factoringAdmin = ref([] as Array<any>);
  const factorings = ref([] as Array<any>);
  const currentCommission = ref(0);
  const onPage = ref(5);
  const page = ref(1);
  const total = ref(0);

  const fetchFactoring = async (companyId: string, sorting: string, query?: string) => {
    let url = `?limit=${onPage.value}&page=${page.value}${query ? `&query=${query}` : ""}`;
    if (sorting && sorting !== "") url += sorting;
    try {
      const response = await axios.get(ApiHelper.fetchAuctionsWithFactoring(companyId) + url, {
        headers: authHelper.authHeader(),
      });
      factorings.value = response.data.data;
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchFactoringAdmin = async (searchValue: string, sorting: string) => {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    if (searchValue !== "") url += `&query=${searchValue}`;
    if (sorting && sorting !== "") url += sorting;
    try {
      const response = await axios.get(ApiHelper.fetchAuctionsWithFactoringAdmin() + url, {
        headers: authHelper.authHeader(),
      });
      factoringAdmin.value = response.data.data;
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const markCompanyFactoring = async (companyId: string, factoring: any) => {
    try {
      await axios.put(
        ApiHelper.addFactoring(companyId),
        {
          factoringCommission: factoring,
        },
        {
          headers: authHelper.authHeader(),
        }
      );
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const removeCompanyFactoring = async (companyId: string) => {
    try {
      await axios.put(ApiHelper.deleteFactoring(companyId), {
        headers: authHelper.authHeader(),
      });
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const changeDefaultCommission = async (body: any) => {
    try {
      const response = await axios.put(ApiHelper.changeCommission(), body, {
        headers: authHelper.authHeader(),
      });
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const checkIfCompanyHasFactoring = async (companyId: string) => {
    try {
      const response = await axios.get(ApiHelper.checkFactoring(companyId), {
        headers: authHelper.authHeader(),
      });
      currentCommission.value = response.data.factoringCommission;
      return response.data.factoring;
    } catch (e) {
      // AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  return {
    factoringAdmin,
    factorings,
    currentCommission,
    onPage,
    page,
    total,
    fetchFactoring,
    fetchFactoringAdmin,
    markCompanyFactoring,
    removeCompanyFactoring,
    changeDefaultCommission,
    checkIfCompanyHasFactoring,
  };
});
