import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import AlertHelper from "@/helpsers/alert.helper";
import i18n from "@/i18n";
import { useCompanyStore } from "@/stores/company.store";
import { useDepartmentStore } from "@/stores/department.store";
import { convertDateToStandardFormat } from "@/js/date.utils";
import { inject, ref } from "vue";
import { parseError } from "@/js/error";

export const useRaportsStore = defineStore("raports", () => {
  const raports = ref([]);
  const acitonTypes = ref([]);
  const page = ref(1);
  const onPage = ref(5);
  const total = ref(0);
  const isLoading = ref(false);
  let alert = inject("alert");

  const fetchRaports = async (filtersObj: any) => {
    const companyStore = useCompanyStore();
    const departmentStore = useDepartmentStore();
    let url = `?onPage=${onPage.value}&pageNumber=${page.value}`;
    if (filtersObj.chosenOperation !== "ALL") {
      url += `&roles[0]=${filtersObj.chosenOperation}`;
    }
    if (filtersObj.chosenOperation2 !== "ALL") {
      url += `&actionType[0]=${filtersObj.chosenOperation2}`;
    }
    if (filtersObj.searchValue !== "") {
      url += `&query=${filtersObj.searchValue}`;
    }
    if (filtersObj.startDate !== "" && filtersObj.endDate) {
      url += `&dateFrom=${convertDateToStandardFormat(filtersObj.startDate)}&dateTo=${convertDateToStandardFormat(filtersObj.endDate)}`;
    }

    isLoading.value = true;
    try {
      const result = await axios.get(ApiHelper.fetchRaports() + url);
      total.value = result.data.meta.total;
      onPage.value = result.data.meta.onPage;
      page.value = result.data.meta.pageNumber;
      for (let i = 0; i < result.data.data.length; i++) {
        if (result.data.data[i].userId) {
          result.data.data[i].userObject = await fetchOneUser(result.data.data[i].userId);
        }
        if (result.data.data[i].actionType) {
          if (
            result.data.data[i].actionType.includes("COMPANY") ||
            result.data.data[i].actionType.includes("LEASING") ||
            result.data.data[i].actionType === "AUCTION_SUBMITTING_A_BID"
          ) {
            result.data.data[i].userObjectSecond = await companyStore.fetchOneCompany(result.data.data[i].user2Id);
          } else if (result.data.data[i].actionType.includes("DEPARTMENT") || result.data.data[i].actionType.includes("AUCTION")) {
            result.data.data[i].userObjectSecond = await departmentStore.fetchAndReturnSingleDepartment(result.data.data[i].user2Id);
          } else if (result.data.data[i].actionType.includes("PRICE_LIST_ITEM")) {
          } else {
            result.data.data[i].userObjectSecond = await fetchOneUser(result.data.data[i].user2Id);
          }
        }
      }
      const res = result.data.data;
      raports.value = res;
      isLoading.value = false;
    } catch (err) {
      AlertHelper.show(alert, parseError(err), "", "error");
      isLoading.value = false;
    }
  };

  const fetchActionTypes = async () => {
    try {
      const result = await axios.get(ApiHelper.fetchActionTypes());
      acitonTypes.value = result.data.map((type: any) => {
        type.name = i18n.global.t(`raport.types.${type.value.toLowerCase()}`);
        return type;
      });
      //@ts-ignore
      acitonTypes.value.push({ name: "Wszystkie", value: "ALL" });
    } catch (error) {
      AlertHelper.show(alert, parseError(error), "", "error");
    }
  };

  const fetchOneUser = async (id: string) => {
    try {
      const response = await axios.get(ApiHelper.fetchOneUser(id));
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  return {
    raports,
    acitonTypes,
    page,
    onPage,
    total,
    isLoading,
    fetchRaports,
    fetchActionTypes,
    fetchOneUser,
  };
});
