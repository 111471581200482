export const STATUS_COLORS = {
  waiting_for_reservation: "#FF3535",
  waiting_for_acceptance: "#ffc107",
  car_pending: " #FF3535",
  absent: "#FF3535",
  reservation_sent: "#ffc107",
  pending: "#ffc107",
  current_monitary: "#ffc107",
  active: "#2F55B9",
  accepted: "#2AB92A",
  reserved: "#2AB92A",
  won: "#2AB92A",
  car_ended: "#2AB92A",
  rejected: "#FF3535",
  current: "#2f55b9",
  available: "#2f55b9",
  finished: "#878e95",
  lost: "#878e95",
  waiting_for_payment:"#bd2222"
};
