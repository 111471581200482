const tableDetails = {
  vin: "VIN",
  statusAndDeadline: "Termin zakończenia",
  companyLowestOffer: "Najniższa zaproponowana oferta",
  customName: "Nazwa niestandardowa",
  created_at: "Data rejestracji",
  technicalReviewExpirationDateFrom: "Przegląd techniczny (od)",
  technicalReviewExpirationDateTo: "Przegląd techniczny (do)",
  insurancePolicyWillRunOutSoon: "Polisa wygasa",
  daysLeft: "Pozostało dni",
  note: "Notatka",
  userName: "Nazwa",
  paidDate: "Data płatności",
  isVerified: "Zweryfikowany",
  bidThreshold: "",
  Nr: "Numer",
  paid: "Opłacone",
  driverEmail: "E-mail kierowcy",
  userId: "Id użytkownika",
  carStatus: "Status",
  modalAddWorker: "Dodaj administratora",
  carRegistrationNumber: "Numer rejestracyjny",
  modalConfirmationWorkers: "Wybierz akcję, jaką chcesz wykonać na pracowniku:",
  btnAccept: "Zatwierdź",
  messageTitle: "Notatka",
  originalname: "Nazwa pliku",
  visible: "Widoczny publicznie",
  action: "",
  email: "Adres e-mail",
  firstName: "Imię",
  lastName: "Nazwisko",
  role: "Rola",
  admin: "Kierownik",
  worker: "Pracownik",
  owner: "Administrator",
  policyExpirationDate: "Polisa wygasa",
  carName: "Nazwa auta",
  administrator: "Kierownik",
  modalConfirmationUser: "Wybierz akcję, jaką chcesz wykonać na użytkowniku:",
  modalConfirmationCompany: "Wybierz akcję, jaką chcesz wykonać na firmie:",
  modalConfirmationDepartment: "Wybierz akcję, jaką chcesz wykonać:",
  modalConfirmationAuthorization: "Wybierz akcję, jaką chcesz wykonać na autoryzacji:",
  modalConfirmationCars: "Wybierz akcję, jaką chcesz wykonać na aucie:",
  modalConfirmationDriver: "Wybierz akcję, jaką chcesz wykonać na kierowcy:",
  nip: "Nip",
  description: "Opis",
  id: "ID",
  companyId: "ID",
  companyName: "Nazwa firmy",
  companyCity: "Miasto",
  companyAddress: "Adres",
  companyZipCode: "Kod pocztowy",
  companyNip: "NIP",
  companyPhone: "Numer telefonu",
  factoringCommission: "Prowizja faktoringu",
  userPhone: "Numer telefonu",
  userCompany: "Rola w firmie",
  undefined: "",
  startDate: "Data awizacji",
  endDate: "Data zakończenia",
  companyOwners: "Właściciele firmy",
  createdAt: "Utworzono",
  addresses: "Adresy firmy",
  departments: "Oddziały",
  warehouse: "Magazyn",
  driver: "Przewoźnik",
  factory: "Producent",
  templateName: "Nazwa szablonu",
  fullName: "Imię i nazwisko",
  documentNumber: "Numer dokumentu tożsamości",
  name: "Nazwa",
  policy_lasting: "Ubezpieczenie do",
  surname: "Nazwisko",
  address: "Adres",
  city: "Miasto",
  countryCode: "Kod kraju",
  isAutomatic: "Automatyczna autoryzacja",
  zipCode: "Kod pocztowy",
  phone: "Numer telefonu",
  managers: "Kierownicy",
  avatar: "Zdjęcie profilowe",
  ownerInCompanies: "Właściciel firm:",
  status: "Status",
  type: "Typ",
  departmentPhone: "Numer telefonu",
  departmentAvatar: "Zdjęcie",
  department: "Oddział autoryzujący",
  trailerNumber: "Numer przyczepy",
  semitrailerNumber: "Numer rejestracyjny naczepy",
  registrationNumber: "Numer rej.",
  docType: "Typ dokumentu",
  carType: "Rodzaj pojazdu",
  carPart: "Rodzaj części",
  isDeleted: "Czy usunięty",
  winningOffer: "Wygrana oferta",
  gusConfirmed: "Zgodność z GUS",
  hostIp: "IP komputera",
  subId: "ID",
  incoming: "Przychodzące",
  carrier: "Przewoźnik",
  monitaryDate: "Data i godzina rezerwacji",
  warehouseMonitoryNoteId: "Nr zlecenia LOGIplanner",
  monitoryNoteNumberInWarehouse: "Nr zlecenia LOGIplanner",
  company: "Firma",
  allowSms: "Zezwala na SMS",
  phoneNumberVerified: "Numer telefonu zweryfikowany",
  companyWarehouseName: "Zarządca magazynu",
  driverFullName: "Kierowca",
  modifiedAt: "Data i godzina potwierdzenia",
  lp: "Lp.",
  invoiceDate: "Data wystawienia",
  userDepartment: "Pracownik przypisany do:",
  policyNumber: "Numer polisy",
  carInsurer: "Ubezpieczyciel",
  shortName: "Nazwa siedziby",
  isMain: "Siedziba główna:",
  carNumber: "Rejestracja",
  dateStart: "Rozpoczęcie awizacji",
  dateEnd: "Zakończenie awizacji",
  monitoryNoteNumber: "Nr awizacji",
  driverName: "Kierowca",
  destination: "Magazyn",
  driverPhone: "Numer telefonu kierowcy",
  policyExpirationDateTo: "Ważność ubezpieczenia (do)",
  policyExpirationDateFrom: "Ważność ubezpieczenia (od)",
  factoring: "Faktoring",
  editTableData: "Edytuj dane",
  consignmentNumber: "Numer wysyłki",
  cargos: "Ładunki",
  length: "Liczba członków",
  orderNumber: "Numer zlecenia",
  monitoryNoteStatus: "Okna czasowe",
  cargoLoads: "Załadunek",
  cargoUnloads: "Rozładunek",
  companyLoader: "Zleceniodawca",
  isAccountVerified: "Weryfikacja e-mail",
  startingPrice: "Kwota wywoławcza",
  deadline: "Termin zakończenia",
  remarks: "Dodatkowe inf.",
  currency: "Waluta",
  participantsNumber: "Liczba uczestników",
  lastAuctionPost: "Data ostatniego wystawienia licytacji",
  numberOfCargo: "Miejsca ładunków",
  numberOfLoads: "Miejsca załadunków",
  numberOfUnoads: "Liczba rozładunków",
  departmentLoader: "Oddział wystawiający",
  auctionType: "Typ aukcji",
  defaultPaymentDeadlineDays: "Standardowy dzień zapłaty",
  email_verified: "Użytkownik potwierdził weryfikację konta w wiadomości e-mail",
  email_unverified: "Użytkownik nie potwierdził weryfikacji konta w wiadomości e-mail",
  firstDownloadedAt: "Pierwszy raz pobrano",
  lng: "Długość geograficzna",
  lat: "Szerokość geograficzna",
  rampsCreated: "Dodano rampy",
  startPoint: "Punkt początkowy",
  endPoint: "Punkt końcowy",
  numberOfUnloads: "Ilość rozładunków",
  isOutside: "Jest zewnętrzny",
  startHour: "Godzina rozpoczęcia",
  endHour: "Godzina zakończenia",
  date: "Data",
  driverDocument: "Numer dok. kierowcy",
  departmentsLength: "Członkowie",
  download: "",
  auctionOffers: "Liczba ofert",
  startAucDate: "Data rozpoczęcia",
  invoiceId: "Numer faktury",
  surcharges: "Dopłaty",
  auctionId: "ID aukcji",
  lowestOffer: "Najniższa oferta",
  verificationTransferConfirmed: "Transfer potwierdzony",
  verificationTransferData: "Dane weryfikacyjne z P24",
  departmentNameForDepartmentList: "Nazwa",
  workerName: "Imię",
  ramp: "Rampa",
  likes: "Pozytywne oceny",
  dislikes: "Negatywne oceny",
  verificationTransferDate: "Utworzono",
  policyStatus: "Status polisy",
  isFinished: "Jest zakończona",
};
export default tableDetails;
