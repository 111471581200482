import { defineStore } from "pinia";
import axios from "@/axios";
import i18n from "@/i18n";
import ApiHelper from "@/helpsers/api.helper";
import authHelper from "@/helpsers/auth.helper";
import storeCache from "@/js/storeCache";
import DepartmentService from "@/services/department.service";
import { inject, ref } from "vue";
import { Company, User } from "./company.store";
import { RegularGetResponse, RegularErrorResponse } from "./utils/interfaces";
import alertHelper from "@/helpsers/alert.helper";
import { isVerificationTransferError, parseError } from "@/js/error";

export interface Department {
  name: string;
  email: string;
  departmentPhone: string;
  isAutomatic: boolean;
  isOutside?: boolean;
  city: string;
  address: string;
  zipCode: string;
  countryCode: string;
  alert: any;
  type?: string;
  companyId?: string;
  modifiedAt?: string;
  createdAt?: string;
  departmentAvatar: DepartmentAvatar;
  avatar?: object;
  company?: Company;
  id: string;
  subId: string;
  departmentId?: string;
  allowSms?: boolean;
  authorizationStatus?: string;
  rampsCreated?: boolean;
  isMain?: boolean;
  shortName?: string;
  contractTerms?: string;
  files?: any[];
  defaultPaymentDeadlineDays?: number | string;
  availabilities?: any[];
  customPickerCounter?: number;
  filledAddress?: boolean;
  phone?: string;
  lat?: string;
  saveNewAddress?: boolean;
  lng?: string;
  userDepartment: any;
}

export interface DepartmentAvatar {
  id?: string;
  url?: string;
}

export const useDepartmentStore = defineStore("department", () => {
  const alert = inject("alert");
  const emitter = inject("emitter");
  const departments = ref([] as Department[]);
  const allDepartments = ref([] as Department[]);
  const currentWarehouses = ref([] as Department[]);
  const currentWarehousesForMenu = ref([] as Department[]);
  const auctionAddingDepartments = ref([] as Department[]);
  const otherDepartments = ref([] as Department[]);
  const currentDepartment = ref({} as Department);
  const currentBlockings = ref([]);
  const workers = ref([]);
  const returnUrl = ref(null);
  const addedDepartmentId = ref("");
  const otherOnPage = ref(4);
  const otherPage = ref(1);
  const otherTotal = ref(0);
  const page = ref(1);
  const onPage = ref(4);
  const onPageLarge = ref(6);
  const groupOnPage = ref(2);
  const total = ref(0);
  const isLoading = ref(false);

  const addDepartment = async (obj: Department) => {
    const company = storeCache.get("currentCompany");
    let { alert, ...object } = obj;
    isLoading.value = true;
    const res = await axios
      .post(ApiHelper.addDepartmentUrl(), object)
      .then((res: any) => {
        isLoading.value = false;
        addedDepartmentId.value = res.data.departmentId;
        if (company && company.type && (company.type === "WAREHOUSE" || obj.type === "WAREHOUSE")) {
          alertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("profile.add_warehouse.added_warehouse"), "success");
        } else {
          alertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("profile.add_warehouse.added_department"), "success");
        }
        return res;
      })
      .catch((err: any) => {
        isLoading.value = false;
        //@ts-ignore
        emitter.emit("showPopup", {
          message: parseError(err),
          type: "error",
          button: isVerificationTransferError(err)
            ? {
                label: "Wykonaj przelew",
                redirect: "VERIFICATION",
              }
            : undefined,
        });
      });
    return res;
  };

  const fetchBlocks = async (companyId: string, departmentId: string) => {
    try {
      const response = await axios.get(ApiHelper.getBlocked(companyId, departmentId));
      currentBlockings.value = response.data.data;
    } catch (e) {
      alertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchAllWarehouses = async (obj: { id: string }) => {
    try {
      const response = await axios.get(ApiHelper.fetchDepartmentsUrlShort(obj.id));

      currentWarehouses.value = response.data;
    } catch (e) {
      alertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchAllWarehousesForMenu = async (obj: any) => {
    let url = "";
    if (obj.type) url += `&type=${obj.type}`;
    try {
      const response = await axios.get(ApiHelper.fetchDepartmentsUrl(obj.id, 99, 1) + url);
      currentWarehousesForMenu.value = response.data.data;
    } catch (e) {
      alertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchDepartments = async (obj: any) => {
    let url = "";
    if (obj.type) url += `&type=${obj.type}`;
    if (obj.searchValue) url += `&query=${obj.searchValue}`;
    if (obj.sorting && obj.sorting !== "" && obj.sorting !== "NONE") url += obj.sorting;
    await axios
      .get(ApiHelper.fetchDepartmentsUrl(obj.id, obj.limit, obj.page) + url, {
        headers: authHelper.authHeader(),
      })
      .then((res: RegularGetResponse<Department>) => {
        if (res) {
          departments.value = res.data.data
            .map((department: Department) => {
              delete department.modifiedAt;
              delete department.createdAt;
              delete department.contractTerms;
              if (!department.isMain) {
                delete department.isMain;
                delete department.shortName;
              }
              return department;
            })
            .reverse();

          if (obj.other) {
            otherDepartments.value = departments.value;
            otherOnPage.value = res.data.meta.per_page;
            otherTotal.value = res.data.meta.total;
            otherPage.value = res.data.meta.current_page;
          }
          if (obj.isMainList) allDepartments.value = departments.value.reverse();
          total.value = res.data.meta.total;
          onPage.value = res.data.meta.per_page;
          page.value = res.data.meta.current_page;
        }
      })
      .catch((e: RegularErrorResponse) => {
        alertHelper.show(alert, parseError(e), "", "error");
      });
  };

  const fetchSingleDepartment = async (id: string) => {
    const departmentService = new DepartmentService();
    await axios
      .get(ApiHelper.fetchSingleDepartmentUrl(id))
      .then((res: any) => {
        if (res.data.departmentAvatar !== null && res.data.departmentAvatar !== undefined) {
          departmentService
            .getImageAndReturnUrl(res.data.departmentAvatar.id)
            .then((response: string) => {
              res.data.departmentAvatar.url = response;
            })
            .catch((e) => {
              alertHelper.show(alert, parseError(e), "", "error");
            });
        }
        currentDepartment.value = res.data;
      })
      .catch((e: RegularErrorResponse) => {
        alertHelper.show(alert, parseError(e), "", "error");
      });
  };

  const fetchAndReturnSingleDepartment = async (id: string) => {
    try {
      const response = await axios.get(ApiHelper.fetchSingleDepartmentUrl(id));
      return response.data;
    } catch (e) {
      // alertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const editContractTerms = async (id: string, body: any) => {
    try {
      const response = await axios.put(ApiHelper.editContractTerms(id), body);
      return response;
    } catch (e) {
      //@ts-ignore
      emitter.emit("showPopup", {
        message: parseError(e),
        type: "error",
        button: isVerificationTransferError(e)
          ? {
              label: "Wykonaj przelew",
              redirect: "VERIFICATION",
            }
          : undefined,
      });
      alertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchWorkersFromDepartment = async (id: string, sorting?: string) => {
    await axios
      .get(ApiHelper.fetchWorkersFromDepartment(id) + `?limit=99${sorting}`)
      .then((res: RegularGetResponse<User>) => {
        //@ts-ignore
        workers.value = res.data.data;
      })
      .catch((e: RegularErrorResponse) => {
        alertHelper.show(alert, parseError(e), "", "error");
      });
  };

  const fetchAllDepartments = async (obj: any) => {
    let url = "";
    if (obj.query) url = `&query=${obj.query}`;
    if (obj.sorting && obj.sorting !== "" && obj.sorting !== "NONE") {
      url += obj.sorting;
    }
    await axios
      .get(ApiHelper.fetchAllDepartmentsUrl(obj.type, obj.limit, obj.page) + url)
      .then((res: RegularGetResponse<Department>) => {
        allDepartments.value = res.data.data;
        auctionAddingDepartments.value = res.data.data;
        total.value = res.data.meta.total;
        onPageLarge.value = res.data.meta.per_page;
        page.value = res.data.meta.current_page;
      })
      .catch((e: RegularErrorResponse) => {
        alertHelper.show(alert, parseError(e), "", "error");
      });
  };

  const deleteDepartment = async (obj: any) => {
    const { alert, id } = obj;
    await axios
      .put(ApiHelper.deleteDepartmentUrl(id))
      .then(() => {
        alertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("profile.add_warehouse.delete_complete"), "success");
      })
      .catch((e: RegularErrorResponse) => {
        alertHelper.show(alert, parseError(e), "", "error");
      });
  };

  const editDepartment = async (obj: any) => {
    const { alert, id, ...object } = obj;
    isLoading.value = true;
    return await axios
      .put(ApiHelper.editDepartmentUrl(id), object)
      .then((response: any) => {
        alertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("profile.add_warehouse.edit_complete"), "success");
        isLoading.value = false;
        return response;
      })
      .catch((e: RegularErrorResponse) => {
        alertHelper.show(alert, parseError(e), "", "error");
      });
  };

  const addWorkerToDepartment = async (obj: any, noAlert?: boolean) => {
    const { alert, ...object } = obj;
    await axios
      .post(ApiHelper.addWorkerToDepartment(), object)
      .then(() => {
        if (noAlert) {
          return;
        }
        alertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("profile.add_warehouse.invitation_sent"), "success");
      })
      .catch((e: RegularErrorResponse) => {
        alertHelper.show(alert, parseError(e), "", "error");
      });
  };

  const removeWorkerFromDepartment = async (obj: any) => {
    const { alert, ...object } = obj;
    await axios
      .put(ApiHelper.removeWorkerFromDepartment(), object)
      .then((res: any) => {
        console.log(res);
      })
      .catch((e: RegularErrorResponse) => {
        alertHelper.show(alert, parseError(e), "", "error");
      });
  };

  const uploadDepartmentPicture = async (img: File, departmentId: string, type: string) => {
    const request = new FormData();
    const url = ApiHelper.getUploadProfileImageUrl();
    request.append("file", img);
    request.append("type", type);
    request.append("visibleToAll", "true");
    request.append("departmentId", departmentId);
    try {
      await axios.post(url, request);
    } catch (e) {
      alertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const deleteDepartmentPicture = async (id: string) => {
    try {
      await axios.delete(ApiHelper.deletePicture(id));
    } catch (e) {
      alertHelper.show(alert, parseError(e), "", "error");
    }
  };

  return {
    departments,
    allDepartments,
    currentWarehouses,
    currentWarehousesForMenu,
    auctionAddingDepartments,
    otherDepartments,
    currentDepartment,
    currentBlockings,
    workers,
    returnUrl,
    addedDepartmentId,
    otherOnPage,
    otherPage,
    otherTotal,
    page,
    onPage,
    onPageLarge,
    groupOnPage,
    total,
    isLoading,
    addDepartment,
    fetchBlocks,
    fetchAllWarehouses,
    fetchAllWarehousesForMenu,
    fetchDepartments,
    fetchSingleDepartment,
    fetchAndReturnSingleDepartment,
    editContractTerms,
    fetchWorkersFromDepartment,
    fetchAllDepartments,
    deleteDepartment,
    editDepartment,
    addWorkerToDepartment,
    removeWorkerFromDepartment,
    uploadDepartmentPicture,
    deleteDepartmentPicture,
  };
});
