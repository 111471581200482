<template>
  <div class="box">
    <div class="small-box">
      <h2 class="auth__heading">404</h2>
      <p class="auth__text auth__text--centered">
        {{ $t("page_not_found.desc") }}
      </p>
      <p class="auth__url auth__url--brown">
        <router-link to="/">{{ $t("page_not_found.back") }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import GuestLayout from "@/layouts/GuestLayout";
import { onMounted } from "@vue/runtime-core";
export default {
  name: "PageNotFound",
  components: { GuestLayout },
  setup() {
    onMounted(() => {
      // window.location.href = "/my-new-404-page.html"
    });
  },
};
</script>

<style scoped lang="scss">
.box {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
}
.small-box {
    text-align: center;
    h2{
        font-size:140px;
        color:#112BAF;
    }
    p{
        font-size: 20px;
    }
}
</style>
