import { Cargo } from "@/stores/stock.store";
import storeCache from "@/js/storeCache";
import router from "@/router";

class BlueprintService {
  countAllCargos = (operations: { cargos: Cargo[] }[]) => {
    let counter = 0;
    operations.forEach((operation: any) => {
      counter += operation.cargos?.length ?? 0;
    });

    return counter;
  };
  handleResignFromBlueprint(){
    storeCache.remove("addAuctionStepOne");
    storeCache.remove("addAuctionCargos");
    storeCache.remove("addAuctionLinks");
    storeCache.remove("freightType");
    storeCache.remove("addAuctionStepTwo");
    router.push({ name: "add-auction", query: { backing: "true" } });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}

export default BlueprintService;
