<template>
  <main class="admin-profile">
    <div class="admin-profile__inner">
      <!-- <div class="admin-profile__top">
                <a @click="$router.back()"><img src="@/assets/images/admin/arrow.svg" />{{$t('admin.profile.settings.back')}}</a>
            </div> -->

      <div class="admin-profile__nav">
        <router-link :to="{ name: 'admin.profile' }" class="">{{ $t("admin.profile.settings.my_profile") }}</router-link>
        <router-link :to="{ name: 'admin.settings' }" class="active">{{ $t("admin.profile.settings.settings") }}</router-link>
      </div>
      <div class="admin-profile__settings">
        <p>{{ $t("admin.profile.settings.manage_account") }}</p>
        <button @click="passwordDialog.show()">
          {{ $t("admin.profile.settings.change_password") }}
        </button>
      </div>

      <div class="admin-profile__settings">
        <p>{{ $t("admin.profile.settings.change_default") }}</p>
        <button @click="provisionDialog.show()">
          {{ $t("admin.profile.settings.change_provision") }}
        </button>
      </div>
    </div>
  </main>
  <div class="dialog" id="password-dialog" aria-hidden="true">
    <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
        <img src="@/assets/icons/dialog-close.svg" alt="Dialog close" />
      </button>

      <div class="dialog__name">
        {{ $t("admin.profile.settings.changing_password") }}
      </div>

      <form class="dialog-form">
        <div class="control control--input">
          <label for="oldPassword" class="control__label">{{ $t("admin.profile.settings.old_password") }}</label>
          <input type="password" id="oldPassword" :placeholder="$t('admin.profile.settings.old_password_placeholder')" v-model="oldPassword" />
          <div v-show="errors.oldPassword" class="control__error">
            <div class="control__error-inner">
              {{ errors.oldPassword }}
            </div>
          </div>
          <!--                        <div class="control__hint">-->
          <!--                            Nie pamiętasz hasła? <a href="">Przypomnij hasło przez e-mail</a>-->
          <!--                        </div>-->
        </div>
        <div class="control control--input">
          <label for="newPassword" class="control__label">{{ $t("admin.profile.settings.new_password") }}</label>
          <input type="password" id="newPassword" :placeholder="$t('admin.profile.settings.new_password_placeholder')" v-model="newPassword" />
          <div v-show="errors.newPassword" class="control__error">
            <div class="control__error-inner">
              {{ errors.newPassword }}
            </div>
          </div>
        </div>
        <div class="control control--input">
          <label for="confirmPassword" class="control__label">
            {{ $t("admin.profile.settings.confirm_password") }}
          </label>
          <input type="password" id="confirmPassword" :placeholder="$t('admin.profile.settings.confirm_password')" v-model="confirmPassword" />
          <div v-show="errors.confirmPassword" class="control__error">
            <div class="control__error-inner">
              {{ errors.confirmPassword }}
            </div>
          </div>
        </div>
      </form>

      <div class="dialog__buttons dialog__buttons--center">
        <button @click="confirmationDialog.hide()" data-a11y-dialog-hide class="dialog__button">
          {{ $t("admin.profile.settings.cancel") }}
        </button>
        <button class="dialog__button dialog__button--alt" @click="changePassword">
          {{ $t("admin.profile.settings.save") }}
        </button>
      </div>
    </div>
  </div>
  <div class="dialog" id="confirmation-dialog" aria-hidden="true">
    <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </button>

      <h1 id="my-dialog-title" class="dialog__title">
        {{ $t("admin.profile.settings.saving_changes_confirmation") }}
      </h1>

      <div class="dialog__buttons">
        <button class="dialog__button dialog__button--alt" @click="changePassword">
          {{ $t("admin.profile.settings.yes") }}
        </button>
        <button class="dialog__button" data-a11y-dialog-hide>
          {{ $t("admin.profile.settings.no") }}
        </button>
      </div>
    </div>
  </div>
  <div class="dialog" id="provision-dialog" aria-hidden="true">
    <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </button>

      <div style="display: grid; place-items: center; gap: 30px">
        <h1 id="my-dialog-title" class="dialog__title">
          {{ $t("admin.profile.settings.change_default") }}
        </h1>
        <input oninput="validity.valid||(value='');" v-model="user" class="provision-input" type="number" name="" id="" />
        <primary-button @click="saveNewCommission" :red="true" :title="$t('generals.save')" />
      </div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useField, useForm } from "vee-validate";
import { useFactoringStore } from "@/stores/factoring.store";
import * as yup from "yup";
import i18n from "@/i18n";
import axios from "axios";
import ApiHelper from "@/helpsers/api.helper";
import authHelper from "@/helpsers/auth.helper";
import AlertHelper from "@/helpsers/alert.helper";
import PrimaryButton from "@/components/common/PrimaryButton.vue";
import storeCache from "@/js/storeCache";
import { useUserStore } from "@/stores/user.store";
import { useAuthStore } from "@/stores/auth.store";

export default {
  name: "userProfileSettings",
  components: { PrimaryButton },
  setup() {
    const authStore = useAuthStore();
    const factoringStore = useFactoringStore();
    const userStore = useUserStore();
    const passwordDialog = ref("");
    const confirmationDialog = ref("");
    const provisionDialog = ref("");
    const { t } = useI18n();
    const confirmChangePassword = ref(false);
    let alert = inject("alert");
    let user = ref("");
    onMounted(() => {
      passwordDialog.value = new A11yDialog(document.querySelector("#password-dialog"));
      provisionDialog.value = new A11yDialog(document.querySelector("#provision-dialog"));
      confirmationDialog.value = new A11yDialog(document.querySelector("#confirmation-dialog"));
      let alert = inject("alert");
      user.value = storeCache.get("currentUser").defaultFactoringCommission;
    });

    const { handleSubmit, errors } = useForm({
      validationSchema: yup.object().shape({
        oldPassword: yup.string().required(t("register.validation.required")),
        newPassword: yup
          .string()
          .required(t("register.validation.required"))
          .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, i18n.global.t("register.validation.password")),
        confirmPassword: yup
          .string()
          .required(t("register.validation.required"))
          .oneOf([yup.ref("newPassword"), null], t("register.validation.passwordConfirmation")),
      }),
    });

    const { value: oldPassword } = useField("oldPassword");
    const { value: newPassword } = useField("newPassword");
    const { value: confirmPassword } = useField("confirmPassword");

    const changePassword = handleSubmit(async () => {
      const data = {
        currentPassword: oldPassword.value,
        newPassword: confirmPassword.value,
        alert: alert,
      };
      // confirmationDialog.value.show();

      try {
        await authStore.changePassword(data);
        AlertHelper.show(alert, t("admin.profile.settings.update_data_success"));
        passwordDialog.value.hide();
        confirmationDialog.value.hide();
        confirmChangePassword.value = false;
      } catch (errorResponse) {
        AlertHelper.show(alert, i18n.global.t("auth.wrong_password"), "", "error");
        confirmationDialog.value.hide();
        confirmChangePassword.value = false;
      }

      // if (confirmChangePassword.value)
      //   axios
      //     .put(ApiHelper.getChangePasswordUrl(), data, {
      //       headers: authHelper.authHeader(),
      //     })
      //     .then((r) => {
      //       AlertHelper.show(alert, t("admin.profile.settings.update_data_success"));
      //       passwordDialog.value.hide();
      //       confirmationDialog.value.hide();
      //       confirmChangePassword.value = false;
      //     })
      //     .catch((errorResponse) => {
      //       let errorMessage = "";
      //       if (errorResponse.response.data.errors) errorMessage = errorResponse.response.data.errors.join(" ");
      //       else if (errorResponse.response.data.code) errorMessage = ApiHelper.getResponseMessage(errorResponse.response?.data?.code);

      //       confirmationDialog.value.hide();
      //       AlertHelper.show(alert, errorMessage, "", "error");
      //       confirmChangePassword.value = false;
      //     });
    });

    const saveNewCommission = async () => {
      const res = await factoringStore.changeDefaultCommission({
        factoringCommission: user.value,
      });

      if (res) {
        await userStore.fetchCurrentUser();
        AlertHelper.show(alert, "Poprawnie zmieniono wysokość oprocentowania.");
      }
    };

    return {
      passwordDialog,
      confirmationDialog,
      oldPassword,
      newPassword,
      confirmPassword,
      changePassword,
      errors,
      confirmChangePassword,
      provisionDialog,
      user,
      saveNewCommission,
    };
  },
};
</script>

<style lang="scss" scoped>
.provision-input {
  font-size: 14px;
  padding: 5px 10px;
  background: #ffffff;
  border: 1px solid #a2a2a2;
  border-radius: 7px;
  &:focus {
    outline: none;
  }
}
</style>
