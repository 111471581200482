<template>
  <div class="" :class="!isAdmin ? 'wrapping-whole-menu-height-adjusted' : 'wrapping-whole-menu'" :key="key">
    <aside v-if="!isAdmin" :class="{ mobile: type == 's' }" class="additional-toolbar">
      <div class="icons">
        <div @click="unwrapMenu('company'), redirectToDashboard('COMPANY')" class="icon company">
          <li>
            <div class="icon-wrapper">
              <img v-if="menuType === 'company'" src="@/assets/icons/home-test-kopia.svg" alt="" />

              <img @click="redirectToDashboard('COMPANY')" v-else src="@/assets/icons/home-test.svg" alt="" />

              <!-- <div
                :class="menuType === 'company' ? 'visible' : 'hidden'"
                class="line"
              ></div> -->
              <p :style="menuType === 'company' ? 'font-weight:800; color:#112BAF;' : ''" class="section-title">
                {{ $t("menu.my_company") }}
              </p>
            </div>
          </li>
        </div>
        <div v-if="typeOfCompany() === 'DRIVER'" @click="unwrapMenu('wallet'), redirectToDashboard('WALLET')" class="icon">
          <li>
            <div class="icon-wrapper">
              <img v-if="menuType === 'wallet'" src="@/assets/icons/wallet-active.svg" alt="" />

              <img @click="redirectToDashboard('WALLET')" v-else src="@/assets/icons/wallet-inactive.svg" alt="" />

              <p :style="menuType === 'wallet' ? 'font-weight:800; color:#112BAF;' : ''" class="section-title">
                {{ $t("generals.wallet") }}
              </p>
            </div>
          </li>
        </div>

        <div @click="unwrapMenu('department'), redirectToDashboard('TIME_WINDOWS')" class="icon company">
          <li>
            <div>
              <img v-if="menuType === 'department'" src="@/assets/icons/clock-active.svg" alt="" />
              <img @click="redirectToDashboard('TIME_WINDOWS')" v-else src="@/assets/icons/clock.svg" alt="" />

              <!-- <div
                :class="menuType === 'department' ? 'visible' : 'hidden'"
                class="line"
              ></div> -->
              <p :style="menuType === 'department' ? 'font-weight:800; color:#112BAF;' : ''" class="section-title">
                {{ $t("profile.calendar.time_windows") }}
              </p>
            </div>
          </li>
        </div>
        <div v-if="companyType !== 'WAREHOUSE'" @click="unwrapMenu('stock'), redirectToDashboard('AUCTIONS')" class="icon stock">
          <li>
            <div>
              <img v-if="menuType === 'stock'" src="@/assets/icons/auction.svg" alt="" />
              <!-- <div v-if="menuType === 'stock'" class="line"></div> -->
              <img @click="redirectToDashboard('AUCTIONS')" v-else src="@/assets/icons/auction-active.svg" alt="" />
              <p :style="menuType === 'stock' ? 'font-weight:800; color:#112BAF;' : ''" class="section-title">
                {{ $t("menu.auctions") }}
              </p>
            </div>
          </li>
        </div>
        <!-- <div v-if="companyType !== 'WAREHOUSE'" @click="unwrapMenu('auction'), redirectToDashboard" class="icon auction">
          <li>
            <div>
              <img v-if="menuType === 'auction'" src="@/assets/icons/market-active.svg" alt="" />
              <div v-if="menuType === 'auction'" class="line"></div> 

              <img @click="redirectToDashboard" v-else src="@/assets/icons/market.svg" alt="" />
              <p :style="menuType === 'auction' ? 'font-weight:800; color:#112BAF;' : ''" class="section-title">
                {{ $t("menu.tenders") }}
              </p>
            </div>
          </li>
        </div> -->
        <div v-if="roleInCompany() === 'OWNER'" @click="unwrapMenu('bank'), redirectToDashboard('BANK')" class="icon bank">
          <li>
            <div>
              <img v-if="menuType === 'bank'" src="@/assets/icons/bank-active.svg" alt="" />
              <!-- <div v-if="menuType === 'bank'" class="line"></div> -->

              <img @click="redirectToDashboard('BANK')" v-else src="@/assets/icons/bank.svg" alt="" />
              <p :style="menuType === 'bank' ? 'font-weight:800; color:#112BAF;' : ''" class="section-title">
                {{ $t("bank.bank") }}
              </p>
            </div>
          </li>
        </div>
        <div class="other-icons">
          <div @click="unwrapMenu('adding'), router.push({ name: 'adding' })" class="icon add">
            <li>
              <div>
                <img v-if="menuType === 'adding'" src="@/assets/icons/add-active.png" alt="" />
                <!-- <div v-if="menuType === 'adding'" class="line"></div> -->

                <img v-else src="@/assets/icons/add.png" alt="" />
                <p class="section-title" :style="menuType === 'adding' ? 'font-weight:800; color:#112BAF;' : ''">
                  {{ $t("addings.adding") }}
                </p>
              </div>
            </li>
          </div>
          <div @click="helpDialog.show()" class="icon support">
            <li>
              <div>
                <img src="@/assets/icons/help.svg" alt="" />
                <p class="section-title">{{ $t("menu.help") }}</p>
              </div>
            </li>
          </div>
          <div @click="auth.logout" class="icon">
            <li>
              <div>
                <img src="@/assets/icons/logout.svg" alt="" />
                <p class="section-title">{{ $t("generals.logout") }}</p>
              </div>
            </li>
          </div>
        </div>
      </div>
      <div v-touch:swipe.left="swipeHandler" class="sidebar" id="menu-bar-left">
        <div v-if="unwrapped" class="sidebar__top">
          <div class="sidebar__inner">
            <div class="profile-pic">
              <img v-if="companyAvatar" style="max-width: 90px" id="profile_photo_blank" :src="companyAvatar" />
              <img v-else style="width: 90px; height: 90px; object-fit: cover" src="@/assets/images/empty-picture.png" alt="" />
              <div style="display: flex; flex-direction: column; justify-content: center; text-align: center">
                <p class="company-name" v-if="fetched">
                  {{ currentCompany?.companyName || localCurrentCompany?.companyName || $t("profile.company.emptyCompany") }}
                </p>
                <div style="display: flex; justify-content: center" class="">
                  <span class="id-section-in-left-menu" v-if="fetched && currentCompany?.subId"> ID: {{ currentCompany?.subId }}</span>
                  <span class="id-section-in-left-menu" v-else>{{ $t("profile.calendar.company_id") }}: <br />({{ $t("generals.no_id") }})</span>
                  <svg
                    v-if="userCompanies?.length > 1"
                    @click="confirmationDialog.show()"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#112BAF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-corner-right-down"
                  >
                    <polyline points="10 15 15 20 20 15"></polyline>
                    <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                  </svg>
                </div>
              </div>
            </div>
            <ul v-if="fetched">
              <company-section
                v-if="menuType === 'company'"
                :companyType="companyType"
                @icon-position="(value) => (popUpIcon = value)"
                @hide-icon="(value) => (popUpIcon = value)"
              />
              <department-section
                v-if="menuType === 'department'"
                :clearInterval="true"
                :companyType="companyType"
                :calendarOpened="calendarOpened"
                :departments="currentWarehousesForMenu"
                @icon-position="(value) => (popUpIcon = value)"
                @hide-icon="(value) => (popUpIcon = value)"
              />
              <adding-section
                v-if="menuType === 'adding'"
                :companyType="companyType"
                @icon-position="(value) => (popUpIcon = value)"
                @hide-icon="(value) => (popUpIcon = value)"
              />
              <stock-section
                v-if="menuType === 'stock'"
                :companyType="companyType"
                @icon-position="(value) => (popUpIcon = value)"
                @hide-icon="(value) => (popUpIcon = value)"
              />
              <wallet-section v-if="menuType === 'wallet'" :companyType="companyType"> </wallet-section>
              <!-- <auction-section
                v-if="menuType === 'auction'"
                :companyType="companyType"
                @icon-position="(value) => (popUpIcon = value)"
                @hide-icon="(value) => (popUpIcon = value)"
              /> -->

              <bank-section
                v-if="menuType === 'bank'"
                :companyType="companyType"
                @icon-position="(value) => (popUpIcon = value)"
                @hide-icon="(value) => (popUpIcon = value)"
              />
            </ul>
          </div>
        </div>
      </div>
    </aside>
    <div @click="unwrapMenu('admin')" v-if="isAdmin && width <= 850" class="habmurger-admin">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#6c757d"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-menu"
      >
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg>
    </div>

    <div v-if="currentU.role === 'ADMIN'" id="notification-bell" class="notification-bell">
      <svg
        @click="handleAdminNotificationToggle"
        id="notification-bell"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-bell"
      >
        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
      </svg>
      <div id="notification-bell" class="notifications-counter">
        {{ notificationsCounter }}
      </div>

      <div
        v-if="(isAdmin && unreadNotificationsCounter) || (notificationsCounter && notificationsCounter > 0)"
        id="notification-bell"
        class="notifications-counter"
      >
        {{ unreadNotificationsCounter === 0 || unreadNotificationsCounter > 0 ? unreadNotificationsCounter : notificationsCounter }}
      </div>
      <div v-if="!isAdmin && (unreadNotificationsCounter || (counter && counter > 0))" id="notification-bell" class="notifications-counter">
        {{ unreadNotificationsCounter === 0 || unreadNotificationsCounter > 0 ? unreadNotificationsCounter : counter }}
      </div>
    </div>
    <div v-if="toggleNotifications && currentU.role === 'ADMIN'" class="position-list">
      <notification-list :isAdmin="true" />
    </div>
    <aside v-if="isAdmin" :class="{ 'admin-sidebar': isAdmin }" id="menu-bar-left">
      <div class="sidebar__top">
        <div class="sidebar__inner">
          <ul>
            <li
              :class="{
                active: router.currentRoute.value.name === 'admin.companies',
              }"
            >
              <a @click="router.push({ name: 'admin.companies' })">
                <div>
                  <img src="@/assets/images/admin/office-building.png" alt="" />
                </div>
                <span>{{ $t("menu.companies") }}</span>
              </a>
            </li>
            <li
              :class="{
                active: router.currentRoute.value.name === 'admin.users.logiplanner',
              }"
            >
              <a @click="router.push({ name: 'admin.users.logiplanner' })">
                <div>
                  <img src="@/assets/images/admin/people.svg" alt="" />
                </div>
                <span>{{ $t("menu.admin.users") }}</span>
              </a>
            </li>

            <li
              :class="{
                opened: adminUsersOpened,
                active: router.currentRoute.value.name === 'admin.timeWindow',
              }"
            >
              <a @click="router.push({ name: 'admin.timeWindow' })">
                <div>
                  <img src="@/assets/images/admin/clock.png" alt="" />
                </div>
                <span>{{ $t("menu.timeWindow") }}</span>
              </a>
            </li>
            <li :class="{ opened: bankOpened }">
              <a
                class="unfoldable-row"
                :class="{ 'row-unfolded': bankOpened }"
                @click="bankOpened = !bankOpened"
                :style="bankOpened ? 'color:#112BAF;' : 'color:black;'"
              >
                <div>
                  <img src="@/assets/images/admin/bank.png" alt="" />
                </div>
                <span>{{ $t("menu.bank") }}</span>
              </a>
              <div class="submenu">
                <ul>
                  <li>
                    <router-link :to="{ name: 'admin.bank' }">{{ $t("bank.leasing") }}</router-link>
                    <router-link :to="{ name: 'admin.factoring' }">{{ $t("bank.factoring") }}</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li :class="{ opened: invoiceOpened }">
              <a
                class="unfoldable-row"
                :class="{ 'row-unfolded': invoiceOpened }"
                @click="invoiceOpened = !invoiceOpened"
                :style="invoiceOpened ? 'color:#112BAF;' : 'color:black;'"
              >
                <div>
                  <img src="@/assets/images/admin/hand.png" alt="" />
                </div>
                <span>{{ $t("generals.invoices") }}</span>
              </a>
              <div class="submenu">
                <ul>
                  <li>
                    <router-link :to="{ name: 'admin.invoices' }">{{ $t("menu.timeWindow") }}</router-link>
                    <!-- <router-link :to="{ name: 'login' }">{{
                    $t("menu.contracts")
                  }}</router-link> -->
                    <router-link :to="{ name: 'admin.invoices.subs' }">{{ $t("menu.subscription") }}</router-link>
                    <router-link :to="{ name: 'admin.invoices.all' }">{{ $t("statuses.all") }}</router-link>
                  </li>
                </ul>
              </div>
            </li>

            <li
              :class="{
                opened: adminUsersOpened,
                active: router.currentRoute.value.name === 'admin.workers',
              }"
            >
              <a
                @click="
                  router.push({
                    name: 'admin.workers',
                  })
                "
              >
                <div>
                  <img src="@/assets/images/admin/technician.png" alt="" />
                </div>
                <span>{{ $t("menu.workers") }}</span>
              </a>
            </li>

            <li :class="{ opened: priceOpened }">
              <a
                class="unfoldable-row"
                :class="{ 'row-unfolded': priceOpened }"
                @click="priceOpened = !priceOpened"
                :style="priceOpened ? 'color:#112BAF;' : 'color:black;'"
              >
                <div>
                  <img src="@/assets/images/admin/file.png" alt="" />
                </div>
                <span>{{ $t("menu.price") }}</span>
              </a>
              <div class="submenu">
                <ul>
                  <li>
                    <router-link :to="{ name: 'admin.price' }">{{ $t("menu.timeWindow") }}</router-link>
                    <router-link :to="{ name: 'admin.subscriptions' }">{{ $t("menu.subscriptions") }}</router-link>
                    <router-link :to="{ name: 'admin.auctionPayment' }">{{ $t("menu.auctionPayment") }}</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li :class="{ opened: chatOpened }">
              <a
                class="unfoldable-row"
                :class="{ 'row-unfolded': chatOpened }"
                @click="chatOpened = !chatOpened"
                :style="chatOpened ? 'color:#112BAF;' : 'color:black;'"
              >
                <div>
                  <img src="@/assets/images/admin/chat.png" alt="" />
                </div>
                <span>{{ $t("menu.chat") }}</span>
              </a>
              <div class="submenu">
                <ul>
                  <li>
                    <router-link :to="{ name: 'admin.chat' }">{{ $t("menu.mass_message") }}</router-link>
                    <router-link :to="{ name: 'admin.chatlist' }">{{ $t("menu.admin_chat") }}</router-link>
                    <router-link :to="{ name: 'admin.userschats' }">{{ $t("menu.user_chat") }}</router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li
              :class="{
                active: router.currentRoute.value.name === 'admin.auctions',
              }"
            >
              <a @click="router.push({ name: 'admin.auctions' })">
                <div>
                  <img src="@/assets/icons/stock.png" alt="" />
                </div>
                <span>{{ $t("menu.auctions") }}</span>
              </a>
            </li>
            <li
              :class="{
                active: router.currentRoute.value.name === 'admin.raports',
              }"
            >
              <a @click="router.push({ name: 'admin.raports' })">
                <div>
                  <img src="@/assets/icons/report.png" alt="" />
                </div>
                <span>{{ $t("menu.raports") }}</span>
              </a>
            </li>
            <li
              :class="{
                active: router.currentRoute.value.name === 'admin.adverts',
              }"
            >
              <a @click="router.push({ name: 'admin.adverts' })">
                <div>
                  <img src="@/assets/icons/megaphone.png" alt="" />
                </div>
                <span>{{ $t("menu.adverts") }}</span>
              </a>
            </li>
            <li
              :class="{
                active: router.currentRoute.value.name === 'admin.documents',
              }"
            >
              <a @click="router.push({ name: 'admin.documents' })">
                <div style="margin-right: -8px">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-folder"
                  >
                    <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
                  </svg>
                </div>
                <span>{{ $t("menu.documents") }}</span>
              </a>
            </li>
            <li
              :class="{
                active: router.currentRoute.value.name === 'admin.verifications',
              }"
            >
              <a @click="router.push({ name: 'admin.verifications' })">
                <div>
                  <img src="@/assets/images/admin/hand.png" alt="" />
                </div>
                <span>{{ $t("menu.verificationPayments") }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </aside>
    <div class="dialog" id="company-dialog" aria-hidden="true">
      <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
      <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
        <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
          <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
        </button>

        <custom-header
          :styling="'primary'"
          :text="$t('menu.change_company').substring(0, 13)"
          :secondary="$t('menu.change_company').substring(13, 20)"
        />
        <p class="p-tag">
          {{ $t("menu.pickCompanyToConnect") }}
        </p>
        <div v-if="userCompanies?.length > 1" class="picker-wrapper">
          <custom-picker
            :isCompany="true"
            :title="$t('menu.your_companies')"
            :chosenElementWithMostPriority="currentCompany?.companyName"
            :options="
              userCompanies?.length
                ? userCompanies
                    .map(({ companyName, id }) => ({
                      label: companyName,
                      id,
                    }))
                    .filter((company) => {
                      if (currentCompany && company.id !== currentCompany.id) return company;
                    })
                : []
            "
          />
        </div>
        <p class="no-data" v-else>{{ $t("menu.lack_of_other_companies") }}</p>
      </div>
    </div>
    <div class="dialog" id="help-dialog" aria-hidden="true">
      <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
      <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
        <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
          <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
        </button>

        <div class="help-modal-body">
          <h1>{{ $t("help.title") }}</h1>
          <p>{{ $t("help.text") }}</p>
          <div class="comunication-ways">
            <p class="title">{{ $t("help.phone") }}</p>
            <a style="font-size: 18px; font-weight: 700; color: black" href="tel:+48343435747">+48 343 435 747</a>
          </div>
          <div class="comunication-ways">
            <p class="title">{{ $t("help.email") }}</p>
            <a style="font-size: 18px; font-weight: 700; color: black" href="mailto:biuro@logiplanner.eu">biuro@logiplanner.eu</a>
          </div>
          <div class="help__buttons">
            <button @click="goToTutorial" class="button button--brown" type="submit">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z"
                  fill="currentColor"
                />
              </svg>

              Samouczek
            </button>
            <button @click="goToAdminChat" class="button button--brown" type="submit">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-mail"
              >
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>
              {{ $t("menu.chat_with_admin") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/css/menu.scss";
import { ref, onMounted, computed, onBeforeMount, inject, onBeforeUnmount } from "vue";
import { useAuthStore } from "@/stores/auth.store";
import router from "@/router";
import { Role } from "@/helpsers/role.helper";
import breakpointsHelper from "@/helpsers/breakpoints.helper";
import CustomHeader from "./common/CustomHeader.vue";
import CustomPicker from "./common/CustomPicker.vue";
import storeCache from "@/js/storeCache";
import { useUserStore } from "@/stores/user.store";
import { useCompanyStore } from "@/stores/company.store";
import { useDepartmentStore } from "@/stores/department.store";
import { useNotificationStore } from "@/stores/notification.store";
import { storeToRefs } from "pinia";
import { roleInCompany, typeOfCompany } from "@/js/utils";
import CompanySection from "@/components/menu/CompanySection.vue";
import DepartmentSection from "@/components/menu/DepartmentSection.vue";
import AddingSection from "@/components/menu/AddingSection.vue";
import StockSection from "@/components/menu/StockSection";
import BankSection from "./menu/BankSection.vue";
import AuctionSection from "@/components/menu/AuctionSection";
import WalletSection from "@/components/menu/WalletSection.vue";
import NotificationList from "@/components/notifications/NotificationList.vue";
import { useChatStore } from "@/stores/chat.store.ts";
import { useInitAdminStore } from "@/stores/admin/init/init.admin.store";

export default {
  name: "Menu",
  components: {
    CustomHeader,
    CustomPicker,
    CompanySection,
    DepartmentSection,
    AddingSection,
    StockSection,
    BankSection,
    AuctionSection,
    NotificationList,
    WalletSection,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    let user = ref({});
    const confirmationDialog = ref("");
    const helpDialog = ref("");
    const chatStore = useChatStore();
    const departmentStore = useDepartmentStore();
    let toggleNotifications = ref(false);
    const company = useCompanyStore();
    const currentCompany = computed(() => company.getCurrentCompany);
    const localCurrentCompany = ref("");
    const userCompanies = computed(() => users.getUserCompanies);
    let { currentWarehousesForMenu } = storeToRefs(departmentStore);
    let { unreadChatsCounter, unreadNotificationsCounter } = storeToRefs(chatStore);
    const notificationStore = useNotificationStore();
    const initStore = useInitAdminStore();
    const { notificationsArray, notificationsCounter } = storeToRefs(initStore);
    const { notifications, counter } = storeToRefs(notificationStore);
    const auth = useAuthStore();
    const users = useUserStore();
    const currentU = ref("");
    const fetched = ref(false);
    const companyType = ref("DRIVER");
    const companyAvatar = ref("");
    let unwrapped = ref(true);
    let menuType = ref("company");
    const popUpIcon = ref({});
    const emitter = inject("emitter");
    const key = ref(new Date().getTime());
    emitter.on("updateMenu", async () => {
      await departmentStore.fetchAllWarehousesForMenu({
        id: storeCache.get("currentCompany")?.id,
      });
      if (storeCache.get("currentMenuType") && router.currentRoute.value.name !== "payment-processing" && width.value > 850) {
        //key.value = new Date().getTime();
        unwrapMenu(storeCache.get("currentMenuType"));
      }
    });

    onBeforeMount(async () => {
      try {
        window.addEventListener("click", (e) => {
          if (
            e.target.localName !== "img" &&
            e.target.localName !== "svg" &&
            e.target.localName !== "line" &&
            e.target.localName !== "span" &&
            e.target.localName !== "path" &&
            e.target.id !== "menu-bar-left" &&
            e.target.localName !== "a" &&
            width.value <= 850 &&
            unwrapped.value
          ) {
            unwrapMenu(storeCache.get("currentMenuType"));
          }
        });

        if (storeCache.get("currentMenuType") && width.value > 850) {
          unwrapMenu(storeCache.get("currentMenuType"));
        } else unwrapMenu("company");
        if (!auth.user) await users.fetchCurrentUser();
        currentU.value = storeCache.get("currentUser");
        if (currentU.value && currentU.value.role !== "ADMIN") {
          await users.fetchUserCompanies();

          if (storeCache.get("currentCompany") === null && users.getUserCompanies.length > 0) {
            await users.setCurrentCompany(`${users.getUserCompanies.at(0).id}`);
          }
          localCurrentCompany.value = storeCache.get("currentCompany");
          if (localCurrentCompany.value && localCurrentCompany.value.type) {
            companyType.value = localCurrentCompany.value.type;
          }
        }

        if (storeCache.get("currentCompany")) {
          const tempCompanyData = storeCache.get("currentCompany");
          const currentCompany = await company.fetchOneCompany(tempCompanyData.id);
          if (currentCompany && currentCompany.companyAvatar && currentCompany.companyAvatar?.id) {
            const img = await company.getCompanyPicture(currentCompany.companyAvatar.id);
            companyAvatar.value = URL.createObjectURL(img);
          } else {
            companyAvatar.value = "";
          }
          if (storeCache.get("currentCompany")) {
            await departmentStore.fetchAllWarehousesForMenu({
              id: storeCache.get("currentCompany")?.id,
            });
          }
        }
        fetched.value = true;
      } catch (e) {
        fetched.value = true;
      }
    });
    onMounted(async () => {
      const companyId = storeCache.get("currentCompany")?.id;
      confirmationDialog.value = new A11yDialog(document.querySelector("#company-dialog"));
      helpDialog.value = new A11yDialog(document.querySelector("#help-dialog"));

      let currentU = storeCache.get("currentUser");
      if (currentU && currentU.role === "ADMIN") {
        try {
          if (companyId) {
            await notificationStore.fetchAllNotifications("ALL", companyId);
            await notificationStore.fetchNumberOfNotifications(companyId);
          }
        } catch (e) {
          console.log(e);
        }
        //CLOSE NOTIFICATION WINDOW ON CLICK
        window.addEventListener("click", (e) => {
          if (
            e.target.id !== "notification-bell" &&
            e.target.id !== "set-all-as-read" &&
            e.target.id !== "notifications-header" &&
            e.target.id !== "notification-select" &&
            e.target.id !== "notification-option" &&
            e.target.ownerSVGElement?.id !== "notification-bell" &&
            e.target.id !== "mark-as-read"
          ) {
            if (toggleNotifications.value === true) toggleNotifications.value = false;
          }
        });
        //CLOSE NOTIFICATION WINDOW ON CLICK
      }

      if (width.value <= 850) {
        unwrapped.value = false;
      }
    });

    const unwrapMenu = (type) => {
      if (type === "admin") {
        let element = document.getElementById("menu-bar-left");
        if (unwrapped.value) {
          element.style.left = "-500px";
          unwrapped.value = false;
        } else {
          element.style.left = "0px";
          unwrapped.value = true;
        }
        storeCache.set("currentMenuType", type);
        return;
      }
      if (type === "wallet" && !storeCache.get("sessionId")) {
        menuType.value = type;
        storeCache.set("currentMenuType", menuType.value);
      }
      if (width.value > 850) {
        menuType.value = type;
      } else {
        let element = document.getElementById("menu-bar-left");
        if (storeCache.get("leftside-menu")) {
          menuType.value = type;
          if (element) element.style.left = 0;
          unwrapped.value = true;
          storeCache.remove("leftside-menu");
          return;
        }
        if (type !== menuType.value) {
          menuType.value = type;
          if (!unwrapped.value) {
            if (element) {
              emitter.emit("openMenu");
              element.style.left = 0;
              unwrapped.value = true;
            }
          }
        } else {
          if (unwrapped.value) {
            if (element) {
              emitter.emit("closeMenu");
              element.style.left = "-500px";
              unwrapped.value = false;
            }
          } else {
            if (element) {
              emitter.emit("openMenu");
              element.style.left = "0px";
              unwrapped.value = true;
            }
          }
        }
      }
      if (!storeCache.get("sessionId")) {
        storeCache.set("currentMenuType", menuType.value);
      }
      //todo
    };

    const userStore = useAuthStore();

    //Pobieranie rozmiaru ekranu.
    const { width, type } = breakpointsHelper();

    let bankOpened = ref(false);
    let priceOpened = ref(false);
    if (router?.currentRoute?.value?.meta?.parent === "admin.bank") bankOpened.value = true;
    let invoiceOpened = ref(false);
    if (router?.currentRoute?.value?.meta?.parent === "admin.invoices") invoiceOpened.value = true;
    let adminUsersOpened = ref(false);
    if (router?.currentRoute?.value?.meta?.parent === "admin.users") adminUsersOpened.value = true;
    let calendarOpened = ref(false);
    if (router?.currentRoute?.value?.meta?.parent === "calendar") calendarOpened.value = true;
    let chatOpened = ref(false);
    if (router?.currentRoute?.value?.meta?.parent === "admin.chat") chatOpened.value = true;
    if (router?.currentRoute?.value?.meta?.parent === "admin.price") priceOpened.value = true;

    const redirectToDashboard = (type) => {
      if (width.value > 850) {
        if (type === "COMPANY") router.push({ name: "dashboard" });
        if (type === "AUCTIONS") router.push({ name: "dashboard-auctions" });
        if (type === "TIME_WINDOWS") router.push({ name: "dashboard-timewindows" });
        if (type === "WALLET") router.push({ name: "dashboard-wallet" });
        if (type === "BANK") router.push({ name: "dashboard-bank" });
      }
    };

    const goToAdminChat = () => {
      helpDialog.value.hide();
      router.push({
        name: "chat-list",
      });
    };

    const handleAdminNotificationToggle = () => {
      toggleNotifications.value = !toggleNotifications.value;
    };

    const goToTutorial = () => {
      helpDialog.value.hide();
      emitter.emit("openTutorial");
    };

    onBeforeUnmount(() => {
      //CLOSE NOTIFICATION WINDOW ON CLICK REMOVE LISTENER
      window.removeEventListener("click", (e) => {
        if (
          e.target.id !== "notification-bell" &&
          e.target.id !== "set-all-as-read" &&
          e.target.id !== "notifications-header" &&
          e.target.id !== "notification-select" &&
          e.target.id !== "notification-option" &&
          e.target.ownerSVGElement?.id !== "notification-bell" &&
          e.target.id !== "mark-as-read"
        ) {
          if (toggleNotifications.value === true) toggleNotifications.value = false;
        }
      });
      //CLOSE NOTIFICATION WINDOW ON CLICK REMOVE LISTENER

      window.removeEventListener("click", (e) => {
        if (e.target.localName !== "img" && e.target.id !== "menu-bar-left" && e.target.localName !== "a" && width.value <= 850 && unwrapped.value) {
          unwrapMenu(storeCache.get("currentMenuType"));
        }
      });
    });

    const swipeHandler = () => {
      if (width.value <= 850 && unwrapped.value) unwrapMenu(storeCache.get("currentMenuType"));
    };

    return {
      userStore,
      currentWarehousesForMenu,
      swipeHandler,
      adminUsersOpened,
      redirectToDashboard,
      Role,
      user,
      type,
      router,
      confirmationDialog,
      calendarOpened,
      currentCompany,
      userCompanies,
      localCurrentCompany,
      auth,
      fetched,
      companyType,
      companyAvatar,
      unwrapMenu,
      unwrapped,
      chatOpened,
      menuType,
      roleInCompany,
      helpDialog,
      popUpIcon,
      bankOpened,
      invoiceOpened,
      toggleNotifications,
      counter,
      currentU,
      width,
      goToAdminChat,
      priceOpened,
      goToTutorial,
      typeOfCompany,
      key,
      unreadChatsCounter,
      unreadNotificationsCounter,
      notificationsArray,
      notificationsCounter,
      handleAdminNotificationToggle,
    };
  },
};
</script>

<style scoped lang="scss">
// .admin-sidebar {
//   min-height: 100%;
// }

// .admin-sidebar {
//   top: 0px !important;
//   z-index: 90;
//   svg,
//   img {
//     margin-left: -5px;
//   }
//   a {
//     span {
//       margin-left: 10px;
//     }
//   }
//   .active {
//     background: #f5f7ff;
//     color: #112baf;
//     border-left: 3px solid #112baf;
//     a {
//       span {
//         font-weight: 600;
//       }
//     }
//   }
// }
.cross {
  text-decoration: line-through !important;
}
.submenu {
  ul {
    li {
      margin: 7px 0;
      a {
        &.router-link-active {
          color: #112baf !important;
          background-color: transparent !important;
          border-left: 0px solid #112baf !important;
          font-weight: 800;
          position: relative;
          &::after {
            content: ".";
            color: #112baf !important;
            position: absolute;
            left: -7px;
            bottom: 9px;
            font-weight: 900;
          }
        }
      }
    }
    .active {
      color: #112baf !important;
    }
  }
}
.help__buttons {
  display: flex;
  margin-top: 2rem;
  gap: 2rem;
  button {
    svg {
      margin-right: 1rem;
    }
  }
  @media screen and (max-width: 500px) {
    gap: 2px;
  }
}
.active {
  color: #112baf !important;
}
</style>
