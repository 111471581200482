<template>
  <!-- <div v-if="users && users.length > 0" class="flex-col"> -->
  <div v-if="chart && chart.length > 0" class="grid-charts">
    <div v-for="element in chart" :key="element" class="knob-box distinct-knob">
      <Chart
        :unclickable="true"
        :status="element.status === 'WAITING_FOR_ACCEPTANCE' ? 'pending' : element.status === 'BLOCKED' ? 'red' : 'accepted'"
        :value="element.value"
        :max="element.max"
      />
      <div class="section-in-right">
        <p class="number-in-chart">{{ element.value }}</p>
        <h1>
          {{ $t(`admin.dashboard.${element.status ? element.status.toLowerCase() : ""}`) }}
        </h1>
      </div>
    </div>
  </div>

  <filters
    :chosenFilters="['search']"
    :noClear="true"
    :filtersDetails="[
      {
        name: 'search',
        labelShort: $t('filters.user_search').substring(0, 20) + '...',
        label: $t('filters.user_search'),
        auto: true,
      },
    ]"
    @search="searchWithFilters"
  />
  <type-switcher @changed="changedType" :noShadow="true" :noMargin="true" :isAdmin="true" :typeChosen="currentUserType" :tabsArray="types" />
  <type-switcher
    @changed="changedCompanyType"
    :tabsArray="companyTypesAdmin"
    :noShadow="true"
    :isAdmin="true"
    :noMargin="true"
    :other="true"
    :typesValues="[
      { type: 'DRIVER', value: waitingAuthorizedUsers },
      { type: 'WAREHOUSE', value: waitingWaitingUsers },
      { type: 'FACTORY', value: waitingBlockedUsers },
    ]"
  >
    <template #content>
      <div v-if="users && users.length > 0" class="flex-col">
        <responsive-table
          @newRecordsNumber="newRecordsNumber"
          :onPage="onPage"
          v-if="!loading"
          :tableType="currentUserType === 'WAITING_FOR_ACCEPTANCE' ? 'EMAIL_VERIFY' : ''"
          @selected="newUser"
          @next="changePage('next')"
          @prev="changePage('prev')"
          :currentPage="page"
          :modalAction="true"
          :blockMockRow="true"
          :data="
            users.map((user) => {
              delete user.role;
              return user;
            })
          "
          :isAdmin="true"
          :filterColumns="true"
          @newTableSorting="addNewSorting"
          :total-pages="Math.ceil(total / onPage)"
          :show-email-verified="currentUserType === 'WAITING_FOR_ACCEPTANCE'"
        >
          <template #modal-content>
            <h1 id="my-dialog-title" class="dialog__title bolded">
              {{ $t("tableData.modalConfirmationUser") }}
            </h1>
            <div class="buttons">
              <button
                :disabled="currentUser.status === 'ACTIVATED' ? true : false"
                type="button"
                class="btn btn-success"
                @click="changeStatus('ACTIVATED')"
              >
                {{ $t("generals.authorize") }}
              </button>
              <div v-if="currentUser.isAccountVerified && currentUserType === 'WAITING_FOR_ACCEPTANCE'" class="email-verified-info">
                <img src="@/assets/images/admin/email-verified.svg" alt="E-mail verified" style="height: 20px; width: 20px" />
                <p>{{ $t("tableData.email_verified") }}</p>
              </div>
              <div v-else-if="currentUserType === 'WAITING_FOR_ACCEPTANCE'" class="email-verified-info">
                <img src="@/assets/images/admin/email-unverified.svg" alt="Email unverified" style="height: 20px; width: 20px" />
                <p>{{ $t("tableData.email_unverified") }}</p>
              </div>
              <button v-if="currentUser.status === 'ACTIVATED'" @click="confirmationDialog.show()" type="button" class="btn btn-primary">
                {{ $t("generals.edit") }}
              </button>
              <button
                @click="changeStatus('BLOCKED')"
                :disabled="currentUser.status === 'BLOCKED' ? true : false"
                type="button"
                class="btn btn-warning"
              >
                {{ $t("generals.block") }}
              </button>
              <!-- <button
                  @click="changeStatus('DELETED')"
                  type="button"
                  class="btn btn-danger"
                >
                  {{ $t("generals.delete") }}
                </button> -->
            </div>
          </template>
        </responsive-table>
        <loader-fitting-into-container v-else />
      </div>
    </template>
  </type-switcher>
  <div class="dialog" id="confirmation-dialog" aria-hidden="true">
    <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </button>
      <slot v-if="currentUser.name && refresh" name="modal-content">
        <h1 class="title-with-underline">{{ $t("snackbars.edit") }}</h1>
        <edit-user @finished="userEdited" @close="confirmationDialog.hide()" :initial="currentUser" />
      </slot>
    </div>
  </div>
</template>

<script>
import "@/css/small-elements/knob-box.scss";
import "@/css/admin/admin-users.scss";
import "@/css/admin/admin-products.scss";
import "@/css/admin/custom-admin-users.scss";
import AdminLayout from "@/layouts/AdminLayout";
import ResponsiveTable from "@/components/common/ResponsiveTable";
import TypeSwitcher from "@/components/common/TypeSwitcher.vue";
import { storeToRefs } from "pinia";
import { computed, inject, onMounted, ref, watch, onBeforeMount } from "vue";
import { useUsersLogiplannerStore } from "@/stores/admin/users/logiplanner.store";
import router from "@/router";
import i18n from "@/i18n";
import { prevPage, nextPage, setCurrentQueryParamsString } from "@/js/pagination.utils";
import LoaderFittingIntoContainer from "@/components/common/LoaderFittingIntoContainer";
import Filters from "@/components/common/Filters.vue";
import Chart from "@/components/common/Chart.vue";
import { companyTypesAdmin } from "@/js/types";
import EditUser from "@/components/admin/EditUser.vue";
import storeCache from "@/js/storeCache";
export default {
  name: "Logiplanner",
  components: {
    AdminLayout,
    ResponsiveTable,
    TypeSwitcher,
    LoaderFittingIntoContainer,
    Filters,
    Chart,
    EditUser,
  },
  setup() {
    const addUserModal = ref();
    const emitter = inject("emitter");
    const userStore = useUsersLogiplannerStore();
    const confirmationDialog = ref("");
    let alert = inject("alert");
    let currentUser = ref({});
    let currentUserType = ref("ACTIVATED");
    let currentCompanyType = ref("DRIVER");
    let queryParams = router.currentRoute.value.query;
    let searchValue = ref("");
    let sorting = ref("");
    let refresh = ref(false);
    let { users, page, onPage, total, isLoading, waitingAuthorizedUsers, waitingWaitingUsers, waitingBlockedUsers, chart } = storeToRefs(userStore);

    let loading = ref(true);
    const types = [
      {
        label: i18n.global.t("authorizations.authorized"),
        name: "ACTIVATED",
        type: "success",
      },
      {
        label: i18n.global.t("profile.statuses.pending"),
        name: "WAITING_FOR_ACCEPTANCE",
        type: "warning",
      },
      {
        label: i18n.global.t("profile.statuses.multiple_blocked"),
        name: "BLOCKED",
        type: "danger",
      },
    ];
    if (queryParams) {
      for (let paramName in queryParams) {
        if (paramName === "page" && page.value !== queryParams[paramName]) page.value = queryParams[paramName];
        if (paramName === "onPage" && onPage.value !== queryParams[paramName]) onPage.value = queryParams[paramName];
      }
    }

    onBeforeMount(() => {
      if (storeCache.get("recordsNumber")) {
        newRecordsNumber(storeCache.get("recordsNumber"));
      }
    });
    onMounted(async () => {
      await userStore.countAllUsers(searchValue.value, currentUserType.value);
      await userStore.fetchUserChartInfo();
      await userStore.fetchUsers(page.value, onPage.value, currentUserType.value, searchValue.value, currentCompanyType.value, sorting.value);
      setTimeout(() => {
        loading.value = false;
      }, 1000);
      confirmationDialog.value = new A11yDialog(document.querySelector("#confirmation-dialog"));
    });

    const pagesNumber = computed(() => {
      if (total.value && onPage.value) return Math.ceil(total.value / onPage.value);
      else return 1;
    });

    emitter.on("userUpdated", async () => {
      await refreshUsersList();
    });

    async function refreshUsersList() {
      loading.value = true;
      let params = setCurrentQueryParamsString(page.value, onPage.value);
      if (params) history.pushState({}, null, `${router?.currentRoute?.value?.path}?${params}`);
      await userStore.countAllUsers(searchValue.value, currentUserType.value);
      await userStore.fetchUsers(page.value, onPage.value, currentUserType.value, searchValue.value, currentCompanyType.value, sorting.value);
      setTimeout(() => {
        loading.value = false;
      }, 1000);
    }
    const searchWithFilters = async (filters) => {
      searchValue.value = filters.searchValue;
      await refreshUsersList();
    };

    const newUser = (e) => {
      currentUser.value = {};
      refresh.value = false;
      setTimeout(() => {
        currentUser.value = e;
      }, 500);
      refresh.value = true;
    };
    const userEdited = () => {
      confirmationDialog.value.hide();
      refreshUsersList();
    };
    const changeStatus = async (status) => {
      const obj = {
        alert: alert,
        userId: currentUser.value.id,
        userStatus: status,
      };
      await userStore.changeUserStatus(obj);
      await refreshUsersList();
    };
    const changedType = async (type) => {
      currentUserType.value = type;
      page.value = 1;
      await refreshUsersList();
    };
    const changedCompanyType = async (type) => {
      currentCompanyType.value = type;
      page.value = 1;
      await refreshUsersList();
    };
    const changePage = async (direction) => {
      if (direction === "next") {
        page.value = nextPage(page.value, pagesNumber.value);
      } else if (direction === "prev") {
        page.value = prevPage(page.value);
      }
      await refreshUsersList();
    };
    const newRecordsNumber = async (records) => {
      if (records && records !== "" && records > 0) {
        onPage.value = records;
        page.value = 1;
        storeCache.set("recordsNumber", records);
        await refreshUsersList();
      }
    };
    const addNewSorting = async (sort) => {
      sorting.value = sort;
      await refreshUsersList();
    };
    return {
      users,
      addNewSorting,
      newRecordsNumber,
      page,
      onPage,
      total,
      pagesNumber,
      prevPage,
      nextPage,
      isLoading,
      addUserModal,
      confirmationDialog,
      changedCompanyType,
      currentUser,
      newUser,
      changeStatus,
      types,
      companyTypesAdmin,
      changedType,
      changePage,
      loading,
      refreshUsersList,
      searchValue,
      waitingWaitingUsers,
      waitingAuthorizedUsers,
      waitingBlockedUsers,
      searchWithFilters,
      chart,
      refresh,
      userEdited,
      currentUserType,
    };
  },
};
</script>

<style scoped lang="scss">
.wrapping-col {
  background: #fff;
  padding: 20px 50px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  min-height: 83%;
  @media screen and (max-width: 550px) {
    padding: 20px 0px;
  }
}
.flex-col {
  margin-left: 30px;
  margin: auto;
}

.admin-products {
  background: #fff;
}
.icon-admin {
  width: 30px;
  height: 30px;
  transition: 0.2s all;
  margin-right: 10px;
  opacity: 0.8;
}
.icon-admin:hover {
  transform: translateY(-5px);
}
.details-table {
  margin-top: 20px;
  p {
    color: #f5f5f5;
    font-size: 14px;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .row {
    display: flex;
    flex-direction: row !important;
  }
}
.buttons {
  display: grid;
  place-items: center;
  margin-top: 0px;
  .btn {
    font-size: 20px;
    width: 60%;
    margin: 10px 0;
    color: #fff;
    padding: 10px 0;
  }
  button:nth-of-type(1) {
    background-color: #1fe76f;
  }
  button:nth-of-type(2) {
    background-color: #2f55b9;
  }
  button:nth-of-type(3) {
    background-color: #ff3427;
  }
}
.filters {
  max-width: 300px;
  margin: 10px auto;
  font-size: 14px;
  input {
    font-size: 16px;
  }
}
.grid-charts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 750px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.knob-box {
  cursor: auto !important;
}

.email-verified-info {
  display: flex;
  align-items: center;
  gap: 5px;
  p {
    font-size: 10px;
    font-weight: 700;
    margin: 0;
  }
}

.bolded {
  font-weight: 700;
  text-align: center;
}
</style>
