class StoreCache {
  constructor(prefix) {
    this.prefix = prefix ? prefix : "logiplanner_";
  }
  createFullKey(key) {
    return this.prefix + key;
  }
  get(key) {
    return JSON.parse(localStorage.getItem(this.createFullKey(key)));
  }
  set(key, value) {
    localStorage.setItem(this.createFullKey(key), JSON.stringify(value));
  }
  remove(key) {
    localStorage.removeItem(this.createFullKey(key));
  }
  clear() {
    let tmpLang = localStorage.getItem(this.prefix + "lang");
    let tmpTutorial = localStorage.getItem(this.prefix + "tutorial_done");

    localStorage.clear();
    localStorage.setItem(this.prefix + "lang", tmpLang);
    localStorage.setItem(this.prefix + "tutorial_done", tmpTutorial);
  }
}

export default new StoreCache();
