import auction_adding from "./auction-adding";
import enums from "./enums";
import generals from "./generals";
import infoIcons from "./infoIcons";
import ramps from "./ramps";
import routes from "./routes";
import tableDetails from "./tableData";
import ratings from "./ratings";
import widgets from "./widgets";
import verificationPayment from "./verificationPayment";

export default {
  enums: enums,
  verificationPayment: verificationPayment,
  auth: {
    register_title_button: "Kliknij, aby się zarejestrować",
    dont_have_account: "Nie masz konta?",
    forgot_password_label: "Zapomniałeś hasła?",
    remember_me_label: "Zapamiętaj mnie",
    log_in_label: "Zaloguj się",
    password_label: "Hasło",
    enter_password_placeholder: "Wpisz hasło",
    enter_email_placeholder: "Wpisz e-mail",
    enter_login_or_email_label: "Wpisz login lub e-mail",
    error_wrong_login_or_password: "Podany login lub hasło jest nieprawidłowe",
    log_in_progress_label: "Logowanie...",
    server_error_short: "Błąd serwera",
    server_error_try_again: "Błąd wewnętrzny serwera. Spróbuj ponownie później",
    email_is_required: "Pole adres e-mail jest wymagane",
    password_is_required: "Pole hasło jest wymagane",
    user_not_verified: "Konto nie zostało zweryfikowane. Na e-mail podany przy rejestracji wysłany został link weryfikacyjny.",
    wrong_password: "Nieprawidłowe hasło.",
    regulations_acceptance_first: "Zapoznałem się z polityką prywatności",
    regulations_acceptance_second: "Zapoznałem się z regulaminem",
    regulationsAcceptance: "Musisz zaakceptować regulamin",
    privacyAcceptance: "Musisz zaakceptować politykę prywatności",
    gus: "Uzupełnij dane firmy na podstawie danych GUS.",
    gus_correct: "Zgodne. Pokaż dane GUS",
    gus_incorrect: "Brak zgodności. Pokaż dane GUS",
    workers: "Pracownicy LOGIplanner",
    same_passwords: "Obecne hasło i nowe hasło muszą się różnić",
  },
  register: {
    message: "Treść wiadomości",
    inviteCompany: "Wyślij zaproszenie do LOGIplanner",
    inviteCompanyShort: "Wyślij zaproszenie",
    addWorker: "Dodaj pracownika",
    role: "Rola",
    admin: "Admin",
    worker: "Pracownik",
    account_type: "Typ konta",
    typeToChange: "Zmiana typu konta na:",
    empty_account_type_placeholder: "Brak typu konta",
    company_data: "Dane firmy",
    user_data: "Dane użytkownika",
    pick_type: "Wybierz typ konta",
    enter_company_data: "Wpisz dane firmy",
    enter_user_data: "Wpisz dane użytkownika",
    company: "Nazwa firmy",
    address_placeholder: "Wpisz adres firmy",
    city: "Miasto",
    city_placeholder: "Wpisz miasto",
    country: "Kraj",
    country_placeholder: "Wpisz nazwę kraju",
    zipCode: "Kod pocztowy",
    zipCode_placeholder: "Wpisz kod pocztowy",
    nip_placeholder: "Wpisz NIP",
    company_placeholder: "Wpisz nazwę firmy",
    address: "Adres firmy",
    zip_code: "Kod pocztowy",
    nip: "NIP",
    email: "E-mail",
    email_placeholder: "Wpisz E-mail",
    name: "Imię",
    name_placeholder: "Wpisz Imię",
    phone: "Numer telefonu",
    appellation: "Nazwa",
    phone_placeholder: "Wpisz numer telefonu",
    surname: "Nazwisko",
    surname_placeholder: "Wpisz Nazwisko",
    user_role: "Jestem",
    password: "Hasło",
    password_placeholder: "Wpisz Hasło",
    confirm_password: "Potwierdź hasło",
    confirm_password_placeholder: "Potwierdź hasło",
    regulations_acceptance_first: "Akceptuję warunki",
    regulations_acceptance_second: "regulaminu",
    regulations_acceptance_third: "oraz",
    regulations_acceptance_fourth: "polityki prywatności.",
    personal_data_processing_agreement: "Wyrażam zgodę na przechowywanie moich danych osobowych",
    other_processing_agreement:
      "W ramach świadczenia usług określonych niniejszą umową PayPro udostępni Akceptantowi dodatkowy mechanizm umożliwiający pozyskiwanie danych Płatnika (imię, nazwisko, adres, IBAN) na podstawie przelewu weryfikacyjnego (GeTPayerInfo). Dane osobowe, o których mowa w zdaniu poprzedzającym będą mogły zostać udostępnione tylko i wyłącznie po uzyskaniu dobrowolnej zgody Płatnika na ich udostępnienie i będą mogły być przetwarzane przez Akceptanta tylko i wyłącznie w celu i zakresie określonym niniejsza zgodą. Treść takiej zgody stanowi pkt 1 do niniejszego Załącznika:",
    other_processing_agreement2:
      "1. „Wyrażam zgodę na przekazanie przez Administratora Danych Osobowych PayPro SA z siedzibą w Poznaniu (60-198), przy ulicy Pastelowej 8 do ANOTHER INVEST Sp. z o.o. moich danych osobowych pochodzących z transakcji płatniczej dokonanej przeze mnie za pośrednictwem PayPro SA, w celu przeprowadzenia przez ANOTHER INVEST Sp. z o.o. mojej weryfikacji, w tym analizy zmierzającej do przeciwdziałania wyłudzeniom. Przekazanie danych nastąpi w zakresie: imię, nazwisko, adres, numer IBAN. Zgoda jest dobrowolna, jednakże jej brak może wiązać się z koniecznością dokonania przez klienta weryfikacyjnego przelewu z rachunku bankowego. Niniejsza zgoda może być cofnięta w dowolnym momencie. Cofnięcie zgody pozostaje jednak bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem''",
    cancel: "Anuluj",
    create_account: "Utwórz konto",
    creating_account_in_progress: "Tworzenie konta...",
    error_while_creating_user: "Podczas tworzenia konta wystąpił błąd. Sprawdź dane i spróbuj ponownie.",
    success: "Konto zostało dodane!",
    email_verification: "Na podany adres e-mail został wysłany link z potwierdzeniem adresu",
    verification_sent: "Dziękujemy za rejestrację. Twoje konto wkrótce zostanie zweryfikowane przez administratora.",
    verification_link_sent: "Na Twój adres e-mail został wysłany link weryfikacyjny. Kliknij w ten link, aby potwierdzić konto i móc się zalogować.",
    validation: {
      string: "Pole musi zawierać ciąg znaków",
      required: "Pole jest wymagane",
      email: "Pole musi zawierać poprawny adres e-mail",
      max: "Pole może zawierać maksymalnie {number} znaków",
      password: "Hasło powinno zawierać: min. 8 znaków, min. 1 duża litera, min. 1 mała litera, min. 1 znak specjalny, min. 1 liczba",
      passwordConfirmation: "Hasła muszą być takie same",
      regulationsAcceptance: "Musisz zaakceptować regulamin",
      additionalRegulations: "Musisz zaakceptować dodatkowe świadczenia",
      dataProcessing: "Wyraź zgodę na przetwarzanie danych osobowych",
      userRole: "Wybrano niepoprawną role",
      phone: "Niepoprawny numer telefonu",
      name: "Imię i nazwisko nie mogą być puste",
      wrongName: "Niepoprawne imię",
      wrongSurname: "Niepoprawne nazwisko",
      wrongCity: "Niepoprawne miasto",
      wrongCountry: "Niepoprawny kraj",
      allRequired: "Wypełnij wszystkie pola",
      onlyNumberInWeight: "Pole musi się składać z samych cyfr",
      onlyNumberInVolume: "Pole musi się składać z samych cyfr",
      onlyNumberInLength: "Pole musi się składać z samych cyfr",
      onlyNumberInPalltesAmount: "Pole musi się składać z samych cyfr",
      role: "Wybrana niepoprawna rola",
      enterNip: "Wprowadź poprawny NIP, aby pobrać dane.",
    },
  },
  forgot_password: {
    reset_password_title: "Zresetuj hasło",
    text: " Jeśli zapomniałeś/łaś hasła do swojego konta, podaj swój e-mail.\n" + "                Wyślemy Ci wiadomość, aby zresetować hasło.",
    enter_email_placeholder: "Wprowadź e-mail",
    sending: "Wysyłanie...",
    send_button: "Wyślij",
    back_button: "Wstecz",
    success_text:
      "Wysłaliśmy instrukcje dotyczące zresetowania hasła na twój adres e-mail. Jeśli nie otrzymałeś wiadomości, sprawdź folder ze spamem i upewnij się, że ten adres e-mail jest zarejestrowany na stronie internetowej LOGIplanner.",
    resend_email_again: "Wyślij wiadomość ponownie",
    still_no_email: "Nadal nie masz wiadomości?",
    success_send_again: "Pomyślnie wysłano wiadomość ponownie",
    fail_send_again: "Wiadomość została wysłana. Jeżeli ponownie jej nie otrzymałeś, upewnij się, że jesteś zarejestrowany w systemie.",
  },
  reset_password: {
    title: "Zresetuj hasło",
    verification_code: "Kod weryfikacyjny",
    verification_code_placeholder: "Wpisz kod weryfikacyjny",
    password: "Nowe hasło",
    password_placeholder: "Wpisz nowe hasło",
    confirm_password: "Potwierdź hasło",
    confirm_password_placeholder: "Potwierdź nowe hasło",
    change_password: "Zmień hasło",
    changing_password_in_progress: "Zmiana hasła...",
    ready: "Gotowe!",
    password_changed: "Hasło zostało poprawnie zmienione.",
    click_to_go_to_login: "Kliknij, aby przejść do okna logowania",
    invalid_code: "Link do zmiany hasła wygasł. Wygeneruj nowy i spróbuj ponownie.",
  },
  profile: {
    my_profile_title: "Mój profil",
    close_edit_title: "Zamknij edycję",
    change_image_title: "Zmień zdjęcie",
    upload_image_title: "Wgraj zdjęcie",
    remove_image: "Usuń",
    specjalizations: "Specjalizacje",
    add_new_tag: "Dodaj nowy tag",
    enter_tag_placeholder: "Wpisz tag",
    my_profile_edit_title: "Edytuj profil",
    specialization_title: "Specjalizacje",
    description_title: "Opis",
    basic_company_data_title: "Dane podstawowe firmy",
    button_label: "Zaktualizuj informacje",
    updating: "Aktualizowanie",
    enter_link_placeholder: "Wpisz link do profilu",
    choose_label: "Wybierz",
    your_profile: "Twój profil",
    edit_company: "Edycja danych firmy",
    remove_img: "Usuń zdjęcie",
    role: {
      client: "Klient",
      temp: "Zewnętrzny",
      admin: "Kierownik",
      driver: "Przewoźnik",
      warehouse: "Magazyn",
      factory: "Producent",
      user: "Uzytkownik",
      owner: "Administrator",
      worker: "Pracownik",
      car_policy: "Polisa pojazdu",
      administrator: "Kierownik",
      auta_solowe: "Auta solowe",
      autolaweta_laweta: "Autolaweta",
      wywrotka: "Wywrotka",
      ciągniki_siodłowe: "Ciągniki siodłowe",
      ciagniki_siodlowe: "Ciągniki siodłowe",
      hakowiec: "Hakowiec",
      walkingfloor: "Wywrotka walingfloor",
      bdf_system_przyczepa: "BDF System",
      dluzyca: "Dłużyca",
      dluzyca_przyczepa: "Dłużyca przyczepa",
      dluzyca_naczepa: "Dłużyca naczepa",
      plandeka_bde: "Plandeka BDE",
      plandeka_coilmulde: "Plandeka Coilmulde",
      plandeka_joloda: "Plandeka Joloda",
      plandeka_jumbo: "Plandeka Jumbo",
      plandeka_mega: "Plandeka Mega",
      plandeka_standard: "Plandeka standard",
      sztywna_zabudowa: "Sztywna zabudowa",
      sztywna_zabudowa_skrzynia: "Sztywna zabudowa skrzynia",
      chlodnia_hakowka: "Chłodnia hakówka",
      cysterna_inna: "Cysterna inna",
      cysterna_silos: "Cysterna silos",
      cysterna_spożywcza: "Cysterna spożywcza",
      podkontenerowa_20_cysterna: "Podkontenerowa 20' cysterna",
      podkontenerowa_40_cysterna: "Podkontenerowa 40' cysterna",
      podkontenerowa_20_standard: "Podkontenerowa 20' standard",
      podkontenerowa_40_standard: "Podkontenerowa 40' standard",
      podkontenerowa_45_standard: "Podkontenerowa 45' standard",
      podkontenerowa_swapbody: "Podkontenerowa Swapbody",
      niskopodwoziowy: "Niskopodwioziowy",
      niskopodwoziowy_naczepa: "Niskopodwioziowy",
      niskopodwoziowy_przyczepa: "Niskopodwioziowy przyczepa",
      bdf_system_naczepa: "BDF System",
      izoterma: "Izoterma",
      cysterna_chemiczna: "Cysterna chemiczna",
      cysterna_paliwowa: "Cysterna paliwowa",
      cysterna_gazowa: "Cysterna gazowa",
      chlodnia: "Chłodnia",
      platforma: "Platforma",
      tandem: "Tandem",
      samochod: "SAMOCHOD",
      przyczepa: "Przyczepa",
      przyczepy: "Przyczepy",
      naczepa: "Naczepa",
      naczepy: "Naczepy",
      niestandardowy: "Niestandardowy pojazd",
      custom: "Niestandardowy pojazd",
      furgon: "Furgon",
      loading: "Załadowanie",
      reloading: "Rozładowanie",
      unloading: "Rozładowanie",
      krs_cdig: "KRS/CEIDG",
      tilt_curtain: "Plandeka Firanka",
      plandeks_firanka: "Plandeka Firanka",
      auto: "Auto",
      regon: "Regon",
      nip: "Nip",
      file: "Inne",
      carrier_insurance: "Ubezpieczenie",
      transport_license: "Licencja",
      contract_terms: "Warunki umowy",
      npe_application: "Wniosek leasingowy",
      errors: {
        404: "Nie można zmienić roli pracownika, ponieważ firma jest nieaktywna",
        empty_company: "Nie znaleziono firmy",
        empty_user: "Nie znaleziono pracownika",
        documents_error: "Do wykonania akcji wymagane jest posiadanie zweryfikowanych dokumentów.",
      },
    },
    statuses: {
      lost: "Przegrana licytacja",
      waiting_for_acceptance: "Czeka na autoryzację",
      waiting_for_reservation: "Czeka na rezerwację",
      waiting_for_payment: "Czeka na płatność",
      reservation_sent: "Złożona",
      invitation_sent: "Czeka na autoryzację",
      activated: "Zautoryzowany",
      active: "Aktywny",
      blocked: "Zablokowany",
      deleted: "Usunięty",
      pending: "Oczekujący",
      absent: "Brak auta",
      current: "W trakcie",
      available: "Wolne",
      rejected: "Odrzucony",
      retreated: "Wycofany",
      accepted: "Zautoryzowany",
      all: "Wszystkie",
      finished: "W archiwum",
      reserved: "Zarezerwowane",
      multiple_blocked: "Zablokowani",
      multiple_active: "Aktywni",
      multiple_rejected: "Odrzuceni",
      loading: "Załadunek",
      reloading: "Rozładunek",
      unloading: "Rozładunek",
      cancelled: "Anulowany",
      car_pending: "Brak auta",
      car_available: "Auto wolne",
      current_monitary: "W trakcie",
      car_ended: "Zakończono",
      completed: "Zakończona pomyślnie",
      not_completed: "Nieukończona",
      waiting_to_start: "Czeka na rozpoczęcie",
      started: "Rozpoczęta",
      WAITING_FOR_VERIFICATION: "Oczekuje na weryfikację",
    },
    statuses_monitory: {
      rejected: "Odrzucona",
      cancelled: "Anulowana",
      finished: "W archiwum",
      accepted: "Potwierdzona",
      pending: "Oczekująca",
      current: "W trakcie",
      all: "Wszystkie",
      absent: "Brak auta",
      waiting_for_acceptance: "Oczekująca",
    },
    company: {
      company_data: "Dane firmy",
      my_company: "Moja firma",
      edit: "Edytuj dane firmy",
      basic_data: "Dane podstawowe firmy",
      name: "Nazwa firmy",
      address: "Adres firmy",
      city: "Miasto",
      zip_code: "Kod pocztowy",
      nip: "NIP",
      regon: "Regon",
      province: "Województwo",
      update_success: "Pomyślnie zaktualizowano dane",
      addWorker: "Pomyślnie dodano pracownika. Poczekaj na jego zatwierdzenie przez administratora systemu.",
      avatar: "Zdjęcie profilowe firmy",
      galery: "Galeria firmy",
      showGalery: "Kliknij, aby zobaczyć całą galerię",
      emptyCompany: "Nie jesteś zapisany do żadnej firmy",
      administrator_amount: "Liczba administratorów",
      department_amount: "Liczba oddziałów",
      you_dont_own_departments: "Nie posiadasz żadnych oddziałów",
      validation: {
        compnay: "Nazwa firmy musi być ciągiem znaków",
        address: "Adres musi byc ciągiem znaków",
        zip_code: "Niepoprawny kod pocztowy. Min. ilość znaków to 3. Maks. ilość znaków to 10.",
        zip_code_pl: "Niepoprawny kod pocztowy. Poprawny format to XX-XXX.",
        max_nip: "NIP musi składać się z 10 cyfr",
        regon_only_number: "Regon musi być ciągiem cyfr",
        max_regon: "Regon musi składać się z 9 cyft",
        authorizationError: "Brak uprawnień do edycji",
      },
    },
    personal_data: {
      basic_data: "Twoje dane podstawowe",
      firstname: "Imię",
      enter_firstname: "Wpisz imię",
      lastname: "Nazwisko",
      enter_lastname: "Wpisz nazwisko",
      email: "E-mail",
      enter_email: "Wpisz e-mail",
      phone: "Numer telefonu",
      enter_phone: "Wpisz numer telefonu",
      second_phone: "Numer telefonu 2",
      enter_second_phone: "Wpisz numer telefonu 2",
      update_info: "Zaktualizuj informacje",
      updating_info: "Aktualizowanie...",
    },
    validation: {
      wrong_url: "Niepoprawny adres url",
      weight: "Pole musi się składać z samych cyfr",
      volume: "Objętość musi się składać z samych cyfr",
      number_of_pallets: "Liczba palet musi się składać z samych cyfr",
    },
    add_user: {
      list: "Lista pracowników",
      title: "Dodawanie pracownika",
      added_worker: "Poprawnie dodano nowego pracownika",
      email_placeholder: "Wpisz adres e-mail pracownika",
      message: "Wiadomość (opcjonalne)",
      name: "Imię",
      name_placeholder: "Wpisz imię",
      surname: "Nazwisko",
      surname_placeholder: "Wpisz nazwisko",
      email: "Adres e-mail",
      phone: "Numer telefonu",
      phone_placeholder: "Wpisz numer telefonu",
      add: "Dodaj pracownika",
      add_driver: "Dodaj kierowcę",
      adding: "Dodawanie...",
      type: "Rodzaj użytkownika",
      choose_type: "Wybierz rodzaj użytkownika",
      enter_worker_data: "Wpisz dane pracownika",
      enter_driver_data: "Wpisz dane kierowcy",
      admin: "Kierownik",
      owner: "Administrator",
      status: "Status użytkownika",
      active: "Aktywny",
      worker: "Pracownik",
      edit_complete: "Poprawnie zmieniono dane pracownika",
      assign_department: "Przypisz do oddziału",
    },
    add_warehouse: {
      list: "Lista magazynów",
      added_warehouse: "Poprawnie dodano nowy magazyn",
      added_department: "Poprawnie dodano nowy oddział",
      title: "Dodawanie nowego magazynu",
      edit_title: "Edycja magazynu",
      name: "Nazwa",
      name_placeholder: "Wpisz nazwę magazynu",
      address: "Adres magazynu",
      city: "Miejscowość",
      city_placeholder: "Wpisz miasto",
      street: "Ulica i numer",
      street_placeholder: "Wpisz adres magazynu",
      zipCode: "Kod pocztowy",
      zipCode_placeholder: "Wpisz kod pocztowy",
      country_code: "Kod kraju",
      country_code_placeholder: "Wpisz kod kraju",
      email_placeholder: "Wpisz adres e-mail magazynu",
      phone_placeholder: "Wpisz numer telefonu magazynu",
      type: "Typ autoryzacji",
      automatic: "Automatyczna",
      add: "Dodaj magazyn",
      adding: "Dodawanie magazynu...",
      delete_complete: "Poprawnie usunięto magazyn",
      edit_complete: "Edycja zakończona pomyślnie.",
      invitation_sent: "Dodano pracownika do oddziału",
    },
    add_department: {
      list: "Lista oddziałów",
      added_warehouse: "Poprawnie dodano nowy oddział",
      title: "Dodawanie oddziału",
      edit_title: "Edycja oddziału",
      name: "Nazwa",
      name_placeholder: "Wpisz nazwę",
      address: "Adres oddziału",
      city: "Miejscowość",
      city_placeholder: "Wpisz miasto",
      street: "Ulica i numer",
      street_placeholder: "Wpisz adres",
      zipCode: "Kod pocztowy",
      zipCode_placeholder: "Wpisz kod pocztowy",
      country_code: "Kod kraju",
      country_code_placeholder: "Wpisz kod kraju",
      email_placeholder: "Wpisz adres e-mail",
      phone_placeholder: "Wpisz numer telefonu",
      type: "Typ autoryzacji",
      automatic: "Automatyczna",
      add: "Dodaj oddział ",
      adding: "Dodawanie oddziału...",
      delete_complete: "Poprawnie usunięto oddział",
      edit_complete: "Pomyślnie edytowano oddział",
      no_download: "Nie można pobrać danych dotyczących oddziału/magazynu.",
      authorizationHint:
        "Autoryzacja z Przewoźnikiem jest konieczna do przeprowadzania awizacji. Autoryzacji dokonujesz poprzez zaakceptowanie prośby wysłanej przez Przewoźnika. Po zaznaczeniu tej opcji wszystkie prośby wysłane do tego magazynu będą automatycznie akceptowane bez konieczności ręcznego potwierdzania.",
    },
    add_car: {
      policy: "Numer polisy",
      policy_from: "Ważność polisy (od)",
      policy_to: "Ważność polisy (do)",
      overview_from: "Ważność przeglądu (od)",
      overview_to: "Ważność przeglądu (do)",
      policy_placeholder: "Wpisz numer polisy",
      insurer: "Nazwa ubezpieczyciela",
      insurer_placeholder: "Wpisz nazwę ubezpieczyciela",
      title: "Dodawanie nowego auta",
      edit_title: "Edycja auta",
      name: "Marka",
      name_placeholder: "Wpisz markę samochodu",
      trailerNumber: "Numer rejestracyjny naczepy",
      trailerNumber_placeholder: "Wpisz numer naczepy",
      registrationNumber: "Numer rejestracyjny",
      registrationNumber_placeholder: "Wpisz numer rejestracyjny",
      type: "Rodzaj pojazdu",
      type_placeholder: "Wpisz rodzaj pojazdu",
      add: "Dodaj auto ",
      adding: "Dodawanie auta...",
      delete_complete: "Poprawnie usunięto auto",
      edit_complete: "Pomyślnie edytowano auto",
      add_complete: "Pomyślnie dodano",
      pick_car_type: "Wybierz podtyp pojazdu",
      go_to_cars: "Przejdź do pojazdów",
      pick_department_to_add_car: "Wybierz oddział",
    },
    add_samochod: {
      title: "Dodawanie nowego auta",
      edit_title: "Edycja auta",
      name: "Marka",
      name_placeholder: "Wpisz markę samochodu",
      trailerNumber: "Numer rejestracyjny naczepy",
      trailerNumber_placeholder: "Wpisz numer naczepy",
      registrationNumber: "Numer rejestracyjny",
      registrationNumber_placeholder: "Wpisz numer rejestracyjny",
      type: "Rodzaj pojazdu",
      type_placeholder: "Wpisz rodzaj pojazdu",
      add: "Dodaj auto ",
      adding: "Dodawanie auta...",
      delete_complete: "Poprawnie usunięto auto",
      edit_complete: "Pomyślnie edytowano auto",
      add_complete: "Pomyślnie dodano",
      pick_department_to_add_car: "Wybierz oddział",
    },
    add_solo_cars: {
      policy: "Dodaj polisę ubezpieczeniową",
      title: "Dodawanie nowego pojazdu",
      edit_title: "Edycja pojazdu",
      name: "Marka",
      name_placeholder: "Wpisz markę pojazdu ",
      vim: "VIN",
      vim_placeholder: "Wpisz VIN ",
      registrationNumber: "Numer rejestracyjny",
      registrationNumber_placeholder: "Wpisz numer rejestracyjny",
      add: "Dodaj pojazd ",
      adding: "Dodawanie pojazdu...",
      customName: "Niestandardowa nazwa",
    },

    list_vehicles: {
      auta_solowe: "Pokaż auta solowe dla: ",
      ciagniki_siodlowe: "Pokaż ciągniki siodłowe dla:",
      przyczepa: "Pokaż przyczepy dla: ",
      naczepa: "Pokaż naczepy dla: ",
      samochod: "Pokaż auta dla: ",
      all: "Pokaż wszystkie pojazdy dla:",
      custom: "Pokaż {0} {1} dla: ",
      pick_department: "Wybierz oddział",
    },
    list_auth: {
      accepted: "Zaakceptowane autoryzacje",
      pending: "Oczekujące autoryzacje",
      company: {
        name: "Nazwa firmy",
      },
      department: {
        count: "Liczba oddziałów",
        count_d: "Liczba magazynów",
        show: "Oddziały",
        name: "Nazwa oddziału",
        empty_departments: "Nie jesteś przypisany do żadnego oddziału",
      },
    },
    add_documents: {
      title: "Dodanie nowego dokumentu",
      select: "Wskaż plik do wysłania ( maks. 10  MB )",
      desc: "Krótki opis dokumentu ( maks. 200 znaków )",
      visible: "Dokument widoczny dla wszystkich (publiczny)",
      choose_file: "Wybierz plik",
      choose_files: "Wybierz pliki",
      no_files_chosen: "Nie wybrano pliku",
      adding: "Ładowanie dokumentu...",
      add: "Dodaj dokument",
      file_size_error: "Zbyt duży rozmiar pliku",
      no_file_error: "Nie zamieszczono żadnego pliku.",
      success: "Dokument został dodany",
      no_access: "Nie masz dostępu do tego typu dokumentu",
      file_type_error: "Nieodpowiedni format pliku",
      delete_error: "Wystąpił błąd przy próbie usunięcia zdjęcia",
      accept_doc_confirm: "Czy na pewno chcesz akceptować dokument?",
      reject_doc_confirm: "Czy na pewno chcesz odrzucić dokument?",
    },
    calendar: {
      ramps: ramps,
      warehouse: "Magazyn",
      name: "Nazwa magazynu",
      fill: "Uzupełnij kalendarz",
      fill_department_calendar: "Utwórz kalendarz",
      taken: "Zajęte",
      time_windows: "Okna czasowe",
      slots: "Liczba udostępnionych slotów",
      from: "Magazyn dostępny od:",
      to: "Magazyn dostępny do:",
      delete: "Usuń okno czasowe",
      deleteAll: "Usuń wszystkie okna czasowe",
      deleteAllQuestion: "Czy chcesz usunąć wszyskie okna czasowe z tego dnia?",
      show_monitary: "Pokaż awizacje",
      monitary_id: "ID awizacji",
      company_id: "ID Firmy",
      worker_id: "ID Pracownika",
      give_additional_monitory_note_id: "Nadaj dodatkowe ID awizacji (opcjonalne)",
      confirm_monitory_note_rejection: "Czy na pewno chcesz odrzucić awizację?",
      confirm_monitory_note_archive: "Czy na pewno chcesz zakończyć awizację?",
      confirm_authorization_rejection: "Czy na pewno chcesz odrzucić autoryzację?",
      monitary_time: "Czas awizacji",
      driver: "Dane kierowcy",
      wehicle: "Dane pojazdu",
      add: "Dodaj okno czasowe",
      enter_limit: "Wpisz limit załadunków i rozładunków na dzień",
      you_can_add_more: "Możesz utworzyć więcej okien czasowych",
      creating: "Tworzenie kalendarza",
      for_month: "Na miesiąc:",
      weekday: "Dzień tygodnia",
      interval: "Długość okna czasowego ",
      interval_short: "Interwał (minuty)",
      block_monitary: "Brak możliwości awizacji",
      limitless_monitary: "Nielimitowana możliwość awizacji",
      add_succes: "Poprawnie dodano kalendarz",
      edit_succes: "Poprawnie zedytowano okna czasowe",
      add_error: "Błąd podczas dodawania kalendarza",
      add_data_missing: "Błędnie uzupełnione dane",
      limit: "Wszystkie limity załadunków i rozładunków muszą być wypełnione.",
      empty_hours: "Godziny i minuty okien czasowych nie mogą być puste",
      empty_interval: "Długość okna czasowego nie może być pusta, ani trwać 0 minut.",
      wrong_hours: "Godziny długości okna czasowego są niepoprawnie uzupełnione.",
      same_hour_error: "Godzina rozpoczęcia i zakończenia okna muszą być różne.",
      fill_all_boxes: "Uzupełnij wszystkie pola",
      correctly_fill_all_boxes: "Poprawnie uzupełnij wszystkie pola",
      create_calendar: "Utwórz kalendarz",
      monitory_note_calendar: "Kalendarz awizacji",
      emptyCalendar: "Brak kalendarza na dany miesiąc",
      unlimited: "Nielimitowane załadunki/rozładunki",
      loadLimit: "Limit załadunków",
      unloadLimit: "Limit rozładunków",
      enter_load_nr: "Wpisz liczbę ładunków",
      date_error: "Akcje na oknach czasowych z dni minionych są zabronione",
      copyPreviousMonth: "Skopiuj poprzedni miesiąc",
      calendarHint: "Poproś o uzupełnienie",
      errors: {
        startHour: "Błędna godzina otwarcia.",
        endHour: "Błędna godzina zamknięcia.",
        interval: "Błędna długość okna.",
      },
    },
    document: {
      empty_company: "Wszystkie dodane dokumenty będą wyświetlane w tej zakładce",
      index: "Lp.",
      originalname: "Nazwa pliku",
      description: "Opis pliku",
      createdAt: "Dodany dnia",
      addedBy: "Dodany przez",
      visible: "Widoczny",
      action: "Akcja",
      title: "Dokumenty",
      emoty_document_type: "Brak dokumentów",
    },
    stock: {
      add_auction: auction_adding,
      types: {
        auction: "Licytacja",
        predetermined: "Przydzielony transport",
        group: "Dla grupy",
        public: "Publiczna",
      },

      signature: {
        loader: "Podpis nadawcy",
        carrier: "Podpis odbiorcy",
        redo: "Podpisz ponownie",
      },
      changelog: {
        cargodescription: "Opis ładunku",
        cargolength: "Długość ładunku",
        cargodocumentnumber: "Numer dokumentu",
        cargoremarks: "Dodatkowy opis",
        cargovolume: "Objętość ładunku",
        cargoweight: "Waga ładunku",
        cargonumberofpallets: "Ilość palet",
        surcharge: "Dopłata",
        surcharges: "Dopłata",
        date: "Zmiany z dnia:",
        treshold: "Próg przebijania",
        title: "Protokół zmian aukcji",
        no_data: "Brak zmian w protokole",
        startingprice: "Cena wywoławcza",
        numberdeliveries: "Liczba dostaw",
        idloader: "ID załadowcy",
        dateofloading: "Data załadunku",
        dateofoperation: "Data operacji",
        numberofitems: "Liczba sztuk",
        dateofunloading: "Data rozładunku",
        deadline: "Zakończenie",
        paymentdeadline: "Termin płatności",
        numberofpallets: "Liczba palet",
        volume: "Objętość",
        weight: "Waga",
        deliveriesnumber: "Liczba ładunków",
        documentnumber: "Numer dokumentu",
        description: "Opis",
        length: "Długość",
        ordernumber: "Numer zlecenia",
        loadingcity: "Miasto załadunku",
        loadingcityzipcode: "Kod pocztowy załadunku",
        loadingCountry: "Kraj załadunku",
        loadingcitycountry: "Kraj załadunku",
        unloadingcity: "Miasto rozładunku",
        unloadingcityzipcode: "Kod pocztowy załadunku",
        unloadingCountry: "Kraj rozładunku",
        unloadingcitycountry: "Kraj rozładunku",
        loadingweight: "Waga ładunku",
        loadingvolume: "Objętość ładunku",
        loadinglength: "Długość ładunku",
        remarks: "Komentarz do transportu",
        currency: "Waluta",
        ordertype: "Typ oferty",
        cartype: "Typ samochodu",
        loadingplace: "Miejsce załadunku",
        unloadingplace: "Miejsce rozładunku",
        additional: "Dodatkowe inf.",
        maxLoadings: "Maks. liczba załadunków",
        maxUnloadings: "Maks. liczba rozładunków",
        endDate: "Termin zakończenia",
      },
      transactions: {
        title: "Dziennik transakcji firmy",
        empty: "Brak transakcji na podany termin",
      },
      cant_add_offer: "Brak aktywnego abonamentu",
      buy_sub: "Zakup abonament",
      adding_offer: "Składanie oferty",
      auto_offering: {
        title: "Cena wywoławcza / automatyczne przebijanie",
        price: "Cena wywoławcza aukcji:",
        enabled: "Automatyczne przebijanie oferty jest ustawione.",
        title_short: "Automatyczne przebijanie oferty",
        min_price: "Minimalna kwota",
        save: "Zapisz zmiany",
        no_offers: "Nie złożyłeś jeszcze ofert na tej aukcji.",
      },
      car_part: "Rodzaj przyczepy / naczepy",
      auction_number_short: "Nr zlecenia",
      part: "Przyczepa / naczepa",
      load_warehouse: "Magazyn załadunku",
      unload_warehouse: "Magazyn rozładunku",
      deadlineNoCounter: "Data zakończenia licytacji",
      auction_details: "Szczegóły zlecenia",
      do_you_want_offer: "Czy na pewno chcesz przyjąć ofertę?",
      do_you_want_offer_reject: "Czy na pewno chcesz odrzucić ofertę?",
      document_error: "Nie możesz pobrać dokumentu",
      document_error_details: "Wszystkie okna czasowe muszą być zarezerwowane, aby pobrać dokument zlecenia.",
      included_transactions: "Złożone oferty",
      surcharges_to_transport: "Dopłaty do transportów",
      adding_ofer: "Składanie oferty",
      added_files: "Dodane pliki",
      no_files: "Brak załączników",
      loadingCountries: "Miejsca załadunków",
      informationAboutLoad: "Informacje o ładunku",
      loadingDates: "Daty  załadunków",
      unloadingCountries: "Miejsca rozładunków",
      unloadingDates: "Daty rozładunków",
      department_data: "Dane oddziału",
      department_name: "Nazwa oddziału",
      department_address: "Adres oddziału",
      offerType: "Typ oferty",
      participantsNumber: "Liczba uczestników",
      offer: "Oferta",
      priceFrom: "Wyliczona cena",
      bindingFrom: "Wiążące od",
      bindingTo: "Wiążące do",
      given: "Podano",
      givenFrom: "Podano przez",
      date: "Data",
      loadingType: "Typ załadunku",
      loading: "Załadunek",
      unloading: "Rozładunek",
      lowest_prices: "Najniższe zaproponowane ceny",
      numberOfCargo: "Liczba ładunków.",
      detailsOfCargo: "Szczegóły ładunku",
      companyName: "Nazwa firmy",
      location: "lokalizacja",
      loadingPoints: "Jednostki załadunkowe",
      loadingPlaces: "Poszczególne miejsca załadunku",
      price: "Cena",
      placeOfDisposal: "Miejsce dyspozycji",
      disposal: "Dysponent",
      car: "Auto",
      nrTransport: "Informacje o transporcie",
      lastModifiedDate: "Data ostatniej modyfikacji",
      idTransporeon: "ID LOGIplanner",
      idLogiPlanner: "ID LOGIplanner",
      idShipper: "ID załadowcy",
      category: "Kategoria wagowa (kg)",
      shipperName: "Nazwa załadowcy",
      shipper: "Załadowca",
      users: "Pracownik wystawiający",
      delivers: "Dostawy (liczba rozładunków)",
      deliversLoads: "Dostawy (liczba załadunków)",
      cargos: "Liczba ładunków ",
      typeOfOrder: "Rodzaj zlecenia",
      quot: "%quoty",
      loadingDate: "Data załadunku",
      unloadlingDate: "Data rozładunku",
      deadline: "Termin zakończenia licytacji",
      lastModification: "Data ostatniej modyfikacji",
      loadingCity: "Miasto załadunku",
      loadings: "Miejsca załadunków",
      unloadings: "Miejsca rozładunków",
      postCode: "Kod pocztowy",
      country: "Kraj",
      unLoadingCity: "Miasto rozładunku",
      carType: "Rodzaj pojazdu",
      typeOfCar: "Rodzaj auta",
      currentAuctionPrice: "Aktualna cena",
      deliverAmount: "Liczba dostaw",
      weight: "Waga załadunku",
      weightT: " Waga załadunku (t)",
      volumeM: "Objętość załadunku (m³)",
      volume: "Objętość",
      length: "Długość załadunku",
      lengthM: "Długość załadunku (m)",
      remarks: "Uwagi",
      canceled_transports: "Anulowane transporty",
      cancel_order: "Anuluj zlecenie",
      end_order: "Zakończ zlecenie",
      orders_archive: "Archiwum zleceń",
      cancelled_orders: "Licytacje anulowane",
      comment: "Komentarz do transportu",
      terms: "Warunki umowy",
      selected_transports: "Przydzielone transporty",
      rejected_transports: "Nieprzydzielone transporty",
      no_won_auctions: "Brak wygranych aukcji",
      no_selected_transports: "Brak przydzielonych transportów",
      no_canceled_transports: "Brak anulowanych transportów",
      no_selected_transports_lost: "Brak nieprzydzielonych transportów",
      unreceived_transports: "Nieotrzymane transporty",
      created_groups: "Utworzone grupy",
      extra_charges: "Dodatkowe dopłaty do zleceń",
      startingPrice: "Cena wywoławcza",
      no_attachment: "Brak załączników",
      dateAndHour: "Kiedy dodano",
      offerSum: "Kwota",
      status: "Status",
      my_offers: "Moje oferty na tej aukcji",
      all_offers: "Wszystkie oferty na tej aukcji",
      no_offers: "Nie złożyłeś jeszcze ofert na tej licytacji",
      my_waiting_offers: "Moje przydzielone transporty z licytacji",
      offer_details: "Szczegóły oferty",
      offer_placing_department: "Oddział, z którego została wystawiona oferta",
      offer_placing_user: "Użytkownik, który wystawił ofertę",
      offer_removal_confirmation: "Czy na pewno chcesz wycofać ofertę?",
      which_auction: "Aukcja, której tyczy się oferta",
      company_placing_auction: "Załadowca",
      additional_pays: "Dopłaty",
      winning_offer: "Wygrana oferta",
      templateName: "Nazwa szablonu",
      nameTemplate: "Nazwij szablon",
      auction_removal: "Czy na pewno chcesz anulować tę licytację?",
      loadReservation: "Rezerwuj godzinę załadunku",
      unloadReservation: "Rezerwuj godzinę rozładunku",
      startPoint: "Punkt początkowy",
      endPoint: "Punkt końcowy",
      statuses: {
        waiting_for_reservation: "Czeka na rezerwację",
        waiting_for_payment: "Czeka na płatność",
        reservation_sent: "Złożona",
        pending: "Oczekująca",
        outbid: "Przebita",
        accepted: "Przyjęta",
        delivery_accepted: "Przydzielony",
        delivery_canceled: "Anulowany",
        delivery_rejected: "Nieprzydzielony",
        reserved: "Zarezerwowana",
        canceled: "Anulowana",
        finished: "Zakończona",
        cancelled: "Anulowana",
        active: "Aktywna",
        rejected: "Odrzucona",
        won: "Wygrana",
        all: "Wszystkie",
      },
      bid: {
        referencePrice: "Autoprzebijanie oferty",
        autoConquerUntill: "Do jakiej kwoty",
        takeReferencePrice: "Użyj ceny referencyjnej",
        type: "Typ oferty",
        offer: "Oferta",
        price: "Wyliczona cena",
        dateRange: "Wiążące do",
        carId: "Wybierz auto",
        departmentId: "Oddział",
        comment: "Komentarz do oferty",
        commentPlaceholder: "Komentarz do oferty - widoczny dla przewoźnika i załadowcy",
        submit: "Złóż ofertę",
        cancel: "Anuluj",
        price_error: "Pole musi być liczbą dziesiętną, większą od zera z maksymalnie dwiema liczbami po przecinku.",
        capasity_error: "Pole musi być liczbą dziesiętną, większą od zera z maksymalnie dwiema liczbami po przecinku.",
        palletAndPiecesError: "Pole musi być większe od zera z maksymalnie dwiema liczbami po przecinku.",
        maxValue: "Maksymalna wartość to: ",
        full_number_error: "Pole musi być liczbą całkowitą",
      },
      deliverInfo: {
        loadingPlace: "Miejsce załadunku",
        map: "Mapa",
        weight: "Wymiary i wagi",
        unloadingPlace: "Miejsce rozładunku",
        other: "Inne",
        deliver: "Dostawa",
        weightWord: "Waga",
        lengthWord: "Długość",
        capasityWord: "Objętość",
        surcharge: "Dopłata",
      },
      contactDetails: {
        user_data: "Osoba wystawiająca licytację",
        user: "Osoba kontaktowa",
        phone: "Tel",
        cell_phone: "Telefon komórkowy",
      },
    },
    languages: {
      title: "Zmiana języka aplikacji",
      current: "Aktualnie wybrany język:",
    },
    listing_departments: {
      title: "Lista dostępnych magazynów",
      title_drivers: "Lista dostępnych przewoźników",
      authorize: "Wyślij prośbę o autoryzację",
      authorized: "Zautoryzowano",
    },
  },
  payments: {
    empty_wallet: {
      title: " Aby dokonać płatności za awizację, musisz najpierw doładować portfel",
      under_title: " Wybierz jeden z dostępnych pakietów okien czasowych, aby przejść do płatności:",
    },

    list: {
      status: "Status transakcji",
      date: "Data transakcji",
      doc: "Dokument awizacji",
      department: "Przewoźnik",
      value: "Kwota",
      time_windows: "Zakupione okna",
      company: "Firma",
      invoice: "Faktura",
      download: "Pobierz fakturę",
      no_invoice: "Faktura pojawi się na początku miesiąca",
      history: "Historia transakcji",
      history_short: "Historia",
    },
    spelling: {
      window: "okno czasowe",
      more_windows: "okna czasowe",
      multiple_windows: "okien czasowych",
      day: "dzień abonamentu",
      days: "dni abonamentu",
    },
    invoices: "Faktury",
    windows: "Okna czasowe",
    spent_windows: "Wydane okna",
    returned_windows: "Zwrócone okna",
    days: "Dni subskrypcji",
    sub: "Abonament",
    extend_sub: "Przedłuż abonament",
    buy_sub: "Wykup abonament",
    none: "Brak",
    no_active_sub: "Brak aktywnej subskrypcji",
    subscription_end: "Subskrypcja abonamentu aktywna do",
    success: "Płatność zakończona sukcesem",
    success_monitory: "Płatność zakończona sukcesem. Awizacja opłacona",
    confirmation: "Czy na pewno chcesz potwierdzić i opłacić awizację?",
    sum: "Aktualne saldo konta",
    sum_after: "Saldo konta po transakcji",
    currencyTime: "liczba okien czasowych",
    charge: "Doładuj konto",
    package_choice: "Wybierz pakiet do doładowania",
    sub_package: "Pakiety abonamentu",
    win_package: "Pakiety okien czasowych",
    admin: {
      add: "Dodaj pakiet",
      edit: "Edycja cennika",
      info1: "Wciśnij przycisk 'Dodaj pakiet', aby dodać nowy pakiet cenowy.",
      info2: "Aby usunąć istniejący pakiet, wciśnij znajdujący się przy nim krzyżyk.",
      price: "Cena",
      price_placeholder: "Wpisz cenę pakietu",
      monitoryNotesNumber: "Liczba okien czasowych",
      monitoryNotesNumber_placeholder: "Wpisz liczbę okien czasowych",
      daysNumber: "Liczba dni abonamentu",
      description: "Dodatkowy opis pakietu (opcjonalne)",
    },
    errors: {
      required: "Pole wymagane",
      price: "Błędna cena. Cena musi być dodatnia oraz składać się z samych cyfr. Ułamki przedzielane powinny być kropką",
      number: "Pole musi być liczbą dodatnią całkowitą.",
      monitoryNotesNumber: "Liczba musi się składać z samych cyfr",
      bad_payment: "Nie udało się przetworzyć płatności.",
      payment_too_long: "Przetwarzanie płatności trwało zbyt długo.",
    },
    currency: "zł",
  },
  profile_settings: {
    settings: "Zmiana hasła",
    password_change: "Zmiana hasła",
    password_change_success: "Pomyślnie zmieniono hasło",
    current_password: "Obecne hasło",
    current_password_placeholder: "Wpisz obecne hasło",
    new_password: "Nowe hasło",
    new_password_placeholder: "Wpisz nowe hasło",
    confirm_password: "Potwierdź hasło",
    confirm_password_placeholder: "Potwierdź hasło",
    update_information: "Zaktualizuj informacje",
    two_step_verification: "Weryfikacja dwuetapowa",
    two_step_verification_desc: "Dodatkowo zabezpiecz swoje konto dzięki drugiemu etapowi logowania.",
    two_step_verification_active: "Włączona",
    two_step_verification_inactive: "Wyłączona",
    select_verification_method: "Wybierz metodę weryfikacji",
    privacy_settings: "Ustawienia prywatności",
    receiving_notifications_about_forum_threads: "Nie chcę otrzymywać powiadomień o wątkach na forum",
    receiving_notifications_about_comments: "Nie chcę dostawać powiadomień o komentarzach do moich inspiracji",
    commenting_user_projects_by_others: "Nie zezwalam na komentowanie moich inspiracji przez innych użytkowników",
    receiving_notifications_of_ratings_by_others: "Nie chcę dostawać powiadomień o swojej ocenie przez innych użytkowników",
    receiving_messages_from_others_users: "Nie chcę otrzymywać wiadomości od innych użytkowników portalu (nie dotyczy administratora)",
    profile_indexing: "Nie zezwalam na indeksowanie mojego profilu, projektów, inspiracji przez wyszukiwarkę",
    add_user_request: "Czy chcesz zaakceptować zaproszenie?",
    invitation_accepted: "Zaproszenie zostało zaakceptowanie",
    invitation_rejected: "Zaproszenie zostało odrzucone",
    invitation_processed: "Zaproszenie przetworzone",
    click_to_go_to_main_page: "Kliknij, aby przejść do strony głównej",
  },
  monitary_notes: {
    title: "Kreator nowej awizacji",
    wrong_hours: "Niepoprawne godziny okna",
    step_one_title: "Wybór rodzaju i miejsca operacji",
    step_one_two: "Wybór daty i godziny podstawienia",
    step_one_three: "Podanie informacji o ładunku",
    operation: "Rodzaj operacji",
    department: "Wybierz oddział, z którego chcesz się awizować.",
    warehouse: "Wybierz magazyn, do którego chcesz się awizować",
    next: "Dalej",
    warehouse_chosen: "Wybrany magazyn",
    no_free_time_windows: "Brak wolnych okien czasowych",
    hour: "Wybierz godzinę podstawienia",
    save: "Zapisz awizację",
    cargoType: "Rodzaj ładunku",
    weight: "Waga ładunku (t)",
    length: "Długość ładunku (m)",
    capacity: "Objętość ładunku (m³)",
    numberOfPallets: "Liczba palet",
    numberOfPieces: "Liczba sztuk",
    numberOfDocument: "Numer dokumentu",
    identityDoc: "Nr dokumentu tożsamości",
    driverEmail: "E-mail kierowcy",
    additionalDescription: "Dodatkowy opis",
    tractorNumber: "Numer ciągnika",
    semitrailerNumber: "Numer rejestracyjny naczepy",
    name: "Imię i nazwisko kierowcy",
    driverDocument: "Numer dokumentu tożsamości kierowcy",
    driverDocumentShort: "Numer dokumentu tożsamości",
    driverPhone: "Numer telefonu kierowcy",
    car_registration_number: "Numer rejestracyjny auta",
    pick_car: "Wybierz auto",
    pick_trailor: "Wybierz przyczepę (opcjonalne)",
    pick_semitrailor: "Wybierz naczepę",
    monitary_note_added: "Dodano awizację",
    status_changed: "Zmieniono status",
    monitary_notes_archive: "Archiwum awizacji",
    car_status: "Statusy aut",
    monitary_note_changed: "Edytowano awizację",
    monitory_notes_list: "Lista awizacji na dane okno czasowe",
    empty_monitory_notes: "Brak awizacji",
    show_monitary_for: "Pokaż awizacje dla",
    driver_came: "Kierowca przyjechał",
    driver_didnt_come: "Brak kierowcy",
    confirm_adding: "Czy na pewno chcesz zaakceptować awizację?",
    load_details: "Szczegóły ładunku",
    load_number: "Ładunek {0}.",
    monitary_modal: {
      description: "Rodzaj rozładunku",
      numberOfPallets: "Liczba palet",
      consignmentNumber: "Numer dokumentu",
      registrationNumber: "Numer rejestracyjny pojazdu",
      date: "Numer dokumentu",
      volume: "Objętość ładunku (m³)",
      length: "Długość ładunku (m)",
      weight: "Waga ładunku (t)",
      driverFullName: "Imię i nazwisko kierowcy",
      driverPhone: "Numer telefonu kierowcy",
      driverEmail: "E-mail kierowcy",
      driverDocument: "Nr dok. tożsamości kierowcy",
      additionalDescription: "Dodatkowe informacje",
      carId: "Numer rejestracyjny pojazdu",
      semitralerId: "Numer rejestracyjny przyczepy",
      semitrailerNumber: "Numer rejestracyjny przyczepy",
      trailerNumber: "Numer rejestracyjny naczepy",
      tralerId: "Numer rejestracyjny naczepy",
      remarks: "Dodatkowe informacje",
    },
    errors: {
      no_hour: "Zaznacz godzinę podstawienia",
    },
  },
  auction_adding: {
    title: "Dodawanie aukcji",
    startingPrice: "Cena wywoławcza",
    numberDeliveries: "Dostawy (liczba rozładunków)",
    numberOfLoadings: "Dostawy (liczba załadunków)",
    dateOfLoading: "Data załadunku",
    dateOfUnloading: "Data rozładunku",
    deadline: "Data zakończenia aukcji",
    loadingCity: "Miasto załadunku",
    loadingCityZipCode: "Kod pocztowy załadunku",
    loadingCountry: "Kraj załadunku",
    unloadingCity: "Miasto rozładunku",
    unloadingCityZipCode: "Kod pocztowy rozładunku",
    unloadingCountry: "Kraj rozładunku",
    loadingAddress: "Adres załadunku",
    unloadingAddress: "Adres rozładunku",
    loadingWeight: "Waga ładunku (t)",
    loadingVolume: "Objętość ładunku ",
    loadingLength: "Długość ładunku",
    currency: "Waluta licytacji",
    currency_short: "Waluta",
    for_day: "Na dzień",
    calc: "Kalkulator",
    hide: "Ukryj",
    rest_of_currencies: "Pozostałe waluty",
    carType: "Rodzaj pojazdu",
    carPart: "Typ przyczepy/naczepy",
    departmentLoaderId: "Oddział wystawiający",
    recipients: "Odbiorcy",
    remarks: "Dodatkowe informacje",
    save: "Dodaj licytację",
    surcharge: "Dopłata (opcjonalne):",
  },
  tableData: tableDetails,
  validate: {
    email: "Podany e-mail jest nieprawidłowy",
    required: "Pole jest wymagane",
    empty: "Pole nie może być puste.",
    wrong_city: "Błędna nazwa miasta",
    password: "Hasło powinno zawierać: min. 8 znaków, min. 1 duża litera, min. 1 mała litera, min. 1 znak specjalny, min. 1 liczba",
    passwordConfirmation: "Hasła muszą być takie same",
    min_number: "Wartość musi być większa od {number}",
  },
  error_codes: {
    "000": "Użytkownik nie istnieje",
    "001": "Nieprawidłowe dane logowania",
    "002": "Podany e-mail istnieje już w systemie",
    "003": "Błędny token",
    "004": "Błędne zapytanie", //Bad request
    "005": "Użytkownik jest już zarejestrowany za pomocą platformy google",
    "006": "Użytkownik jest już zarejestrowany za pomocą platformy facebook",
    "007": "Błędny kod weryfikacyjny",
    "008": "Nie znaleziono koszyka",
    "009": "Konto niezweryfikowane. E-mail z kodem został wysłany.",
    "010": "Produkt został już dodany do ulubionych",
    "011": "Obiekt już istnieje",
    "012": "Błędne hasło",
    "013": "@TODO KOD DO UZUPELNIENIA",
    "014": "Na podany adres e-mail zostało już wysłane zaproszenie",
    401: "Brak autoryzacji",
    403: "Brak uprawnień do wykonania tej akcji",
    404: "Nie znaleziono obiektu",
    default: "Podczas przetważania żądania wystąpił błąd.",
  },
  verification: {
    title: "Weryfikacja konta",
    in_progress: "Prosimy o cierpliwość, trwa weryfikacja...",
    success: "Konto zostało zweryfikowane. Możesz się zalogować.",
    error_during_verification: "Podczas weryfikacji wystąpił błąd. Upewnij się, że link jest poprawny.",
  },

  page_not_found: {
    desc: "Niestety nie znaleziono strony, której szukasz. Sprawdź, czy wprowadziłeś poprawny adres.",
    back: "Wróć do strony głównej",
  },
  alert: {
    successfully_saved_data: "Pomyślnie zapisano dane",
    successfully_updated_data: "Pomyślnie zaktualizowano dane",
    error_during_saving_data: "Błąd zapisu danych",
    error_during_updating_data: "Błąd aktualizacji danych",
  },
  menu: {
    price: "Cennik",
    adverts: "Reklamy",
    mass_message: "Grupowa wiadomość",
    admin_chat: "Czaty z administratorem",
    user_chat: "Czaty użytkowników",
    chat: "Wiadomości",
    language: "Język",
    widgets: "Widżety",
    dashboard: "Pulpit",
    my_company: "Firma",
    my_profile: "Mój profil",
    my_drivers: "Kierowcy",
    my_warehouses: "Moje oddziały",
    my_warehouses_outside: "Magazyny zewnętrzne",
    list_warehouses: "Lista magazynów",
    my_factories: "Moje magazyny",
    my_cars: "Moje auta",
    time_windows: "Okna czasowe",
    monitory_notes: "Awizacje",
    authorizations: "Autoryzacje",
    archive: "Archiwum",
    calendar: "Kalendarz",
    settings: "Ustawienia",
    raports: "Raporty",
    loads: "Giełda ładunków",
    tenders: "Przetargi",
    workers: "Pracownicy",
    documents: "Dokumenty",
    warehouses: "Oddziały",
    contracts: "Zlecenia",
    subscription: "Abonament",
    subscriptions: "Abonament",
    warehouseList: "Lista magazynów",
    warehouse_menagers: "Pracownicy w magazynie",
    department_menagers: "Pracownicy w oddziale",
    admin_panel: "Panel administratora",
    change_company: "Zmień aktywną firmę",
    logout: "Wyloguj się",
    your_companies: "Twoje firmy",
    companies: "Firmy",
    timeWindow: "Okna czasowe",
    bid: "Licytacje",
    bank: "Bank",
    invoices: "Lista Płatności",
    drivers: "Przewoźnicy",
    drivers_groups: "Grupy przewoźników",
    drivers_list: "Lista przewoźników",
    job_list: "Wykaz zleceń",
    factoring_list: "Wykaz faktoringu",
    participants_list: "Wykaz uczestników",
    insurances: "Ubezpieczenia",
    auctions: "Licytacje",
    offers: "Oferty",
    lack_of_other_companies: "Brak innych firm",
    return: "Wróć",
    help: "Pomoc",
    chat_with_admin: "Czatuj z adminem",
    pickCompanyToConnect: "Z rozwijanej listy wybierz firmę, na którą chcesz się przełączyć.",
    auctionPayment: "Prowizja od licytacji",
    verificationPayments: "Przelewy weryf.",
    admin: {
      users: "Użytkownicy",
      users_logiplanner: "LOGIplanner",
      company: "Dane firmy",
    },
  },
  admin: {
    general: {
      back: "Wstecz",
      button_update_data_title: "Zaktualizuj informacje",
      add: "+Dodaj pracownika",
    },
    dashboard: {
      companies: "Firmy zarejestrowane",
      carriers: "Przewoźnicy",
      factories: "Producenci",
      warehouses: "Magazyny",
      waitingCompanies: "Oczekujący",
      value: "Ogółem",
      blocked: "Zablokowani",
      waiting_for_acceptance: "Oczekujący",
      activated: "Zaakceptowani",
    },

    profile: {
      my_profile_title: "Mój profil",
      my_profile_settings_title: "Ustawienia",
      personal_image: "Zdjęcie główne",
      update_success: "Pomyślnie zaktualizowano dane",
      img_update_success: "Pomyślnie zaktualizowano zdjęcie",
      img_delete_success: "Pomyślnie usunięto zdjęcie",
      showGallery: "Pokaż galerię",
      address_title: "Adres",
      department_phone: "Kontakt",
      department_email: "Adres e-mail",
      name: "Imię",
      enter_name: "Wpisz imię",
      surname: "Nazwisko",
      enter_surname: "Wpisz nazwisko",
      email: "E-mail",
      enter_email: "Wpisz e-mail",
      phone_number: `Numer Telefonu`,
      enter_phone_number: `Wpisz numer Telefonu`,
      street: "Ulica",
      enter_street: "Wpisz ulica",
      premises_number: "Numer lokalu",
      enter_premises_number: "Wpisz numer lokalu",
      apartment_number: "Numer mieszkania",
      enter_apartment_number: "Wpisz numer mieszkania",
      zip_code: "Kod pocztowy",
      enter_zip_code: "Wpisz kod pocztowy",
      city: "Miasto",
      enter_city: "Wpisz miasto",
      validation: {
        zip_code: "Niepoprawny kod pocztowy. Min. ilość znaków to 3. Maks. ilość znaków to 10.",
      },
      update_data_success: "Pomyślnie zaktualizowano dane profilowe",
      update_profile_img: {
        alert_success: "Pomyślnie zaktualizowano zdjęcie profilowe",
      },
      delete_profile_img: {
        alert_success: "Pomyślnie usunięto zdjęcie profilowe",
      },
      settings: {
        back: "Wstecz",
        my_profile: "Mój profil",
        settings: "Ustawienia",
        manage_account: "Zarządzaj swoim kontem",
        change_default: "Zmień domyślną prowizję za faktoring",
        set_provision: "Prowizja za faktoring:",
        change_provision: "Zmień prowizję",
        change_password: "Zmień hasło",
        close_dialog: "Zamknij",
        changing_password: "Zmiana hasła",
        old_password: "Stare hasło",
        old_password_placeholder: "Wpisz stare hasło",
        new_password: "Nowe hasło",
        new_password_placeholder: "Wpisz nowe hasło",
        confirm_password: "Potwierdź nowe hasło",
        cancel: "Anuluj",
        save: "Zapisz",
        yes: "Tak",
        no: "Nie",
        saving_changes_confirmation: "Czy na pewno chcesz zapisać zmiany?",
        update_data_success: "Pomyślnie zaktualizowano dane",
      },
    },
    users: {
      logiplanner: {
        title: "LOGIplanner",
        add_user: "Dodaj użytkownika",
        firstname: "Imię",
        surname: "Nazwisko",
        function: "Funkcja",
        email: "E-mail",
        phone: "Telefon",
        change_user_data: "Zmień dane i uprawnienia użytkownika",
        access_blocked: "Dostęp dla tego użytkownika jest zablokowany, kliknij aby odblokować dostęp",
        delete_user: "Usuń użytkownika",
        loading: "Ładowanie...",
        status_changed: "Poprawnie zmieniono status",
        empty_users: "Brak użytkowników",
        show_docs: "Dokumenty",
        show_workers: "Oddziały",
        show_gallery: "Zobacz galerię firmy",
        add_user_modal: {
          dialog_close: "Zamknij",
          edit_data: "Edytuj dane użytkownika",
          firstname: "Imię",
          firstname_placeholder: "Wpisz imię",
          email: "E-mail",
          email_placeholder: "Wpisz e-mail",
          surname: "Nazwisko",
          surname_placeholder: "Wpisz nazwisko",
          phone: "Telefon",
          phone_placeholder: "Wpisz telefon",
          cancel: "Anuluj",
          save: "Zapisz",
          saving: "Zapisywanie...",
        },
      },
    },
  },

  delete_client_modal: {
    dialog_close: "Zamknij",
    deleting_client: "Usuwanie Klienta",
    deleting_client_confirmation: "Czy na pewno chcesz usunąć klienta",
    cancel: "Anuluj",
    delete: "Usuń",
    deleting: "Usuwanie...",
    deleting_success: "Pomyślnie usunięto klienta",
    settings: "Przejdź do ustawień",
  },
  months: {
    JAN: "Styczeń",
    FEB: "Luty",
    MAR: "Marzec",
    APR: "Kwiecień",
    MAY: "Maj",
    JUN: "Czerwiec",
    JUL: "Lipiec",
    AUG: "Sierpień",
    SEP: "Wrzesień",
    OCT: "Październik",
    NOV: "Listopad",
    DEC: "Grudzień",
  },
  days: {
    MON: "Poniedziałek",
    TUE: "Wtorek",
    WEN: "Środa",
    THUR: "Czwartek",
    FRI: "Piątek",
    SAT: "Sobota",
    SUN: "Niedziela",
  },
  generals: generals,
  ratings: ratings,
  widgetsTitles: widgets,
  addings: {
    adding: "Dodawanie",
    worker: "Dodaj pracownika",
    docs: "Dodaj dokumenty",
    department: "Dodaj oddział",
    warehouse: "Dodaj magazyn",
    monitary: "Dodaj awizację",
    driver: "Dodaj przewoźnika",
    job: "Dodaj zlecenie",
    auctions: "Moje licytacje",
    adding_documents: "Dodawanie dokumentów",
    add_warehouse_menagers: "Pozostali pracownicy",
    add_department_menagers: "Pozostali pracownicy",
    hint: "Wpisz nr zlecenia lub nazwę firmy/oddziału",
    add_attachment: "Dodaj załącznik",
    doc: "Dodaj dokument",
    car: "Dodaj pojazd",
    group: "Dodaj grupę",
    show_participants_for: "Pokaż uczestników dla aukcji:",
    auction: "Dodaj licytację",
    add_to_group: "Dodaj do grupy",
    driver_person: "Dodaj kierowcę",
  },
  removings: {
    remove_documnet_question: "Czy na pewno chcesz usunąć dokument?",
    remove_warehouse_question: "Czy na pewno chcesz usunąć magazyn?",
    remove_admin_question: "Czy na pewno chcesz usunąć tego admina?",
    remove_worker_question: "Edycja pracownika",
    block_worker_question: "Czy na pewno chcesz zablokować pracownika?",
    unblock_worker_question: "Czy na pewno chcesz odblokować pracownika?",
  },
  missings: {
    form: "Formularz jest błędnie wypełniony. Uzupełnij wymagane pola oraz sprawdź ich poprawność.",
    departments: "Brak oddziałów w firmie",
    department_info: "Aby korzystać z pełnej funkcjonalności aplikacji, musisz posiadać przynajmniej jeden oddział.",
    warehouse_info: "Aby korzystać z pełnej funkcjonalności aplikacji, musisz posiadać przynajmniej jeden magazyn.",
    department_info_worker: "Aby korzystać z pełnej funkcjonalności aplikacji, musisz być przypisany do przynajmniej jednego oddziału.",
    warehouse_info_worker: "Aby korzystać z pełnej funkcjonalności aplikacji, musisz być przypisany do przynajmniej jednego magazynu.",
    insurances: "Ubezpieczenia można dodawać do pojazdów, przejdź do zakładki moje pojazdy i dodaj pierwszy pojazd",
    results: "Brak wyników dla podanych kategorii wyszukiwania",
    no_contract: "Brak warunków umowy",
    no_remarks: "Brak dodatkowych informacji",
    no_car_statuses: "Statusy aut będą wyświetlane po dodaniu pierwszego pojazdu",
    auction_finished: "Licytacja nie jest już aktywna",
    no_subscription: "Nie masz wykupionego abonamentu",
    no_cars: "Nie masz odpowiedniego typu pojazdu",
    notifications: "Brak powiadomień",
    invoices: "Brak faktur",
    invoicesMonthly: "Brak faktur miesięcznych",
    groups: "Wszystkie utworzone grupy będą wyświetlane na tej liście",
    auctions: "Brak wystawionych aukcji",
    participants: "Brak uczestników licytacji",
    missing_department: "Wybierz oddział, do którego chcesz przypisać pracownika.",
    missing_department_driver: "Wybierz oddział, do którego chcesz przypisać kierowcę.",
    missing_role: "Wybierz rolę pracownika.",
    enter_custom_name: "Wpisz niestandardową nazwę pojazdu.",
    no_authorized_warehouse:
      "Nie masz jeszcze zautoryzowanych magazynów. Przejdź do Listy magazynów znajdującej się w zakładce Okna czasowe, aby utworzyć pierwszą autoryzację",
    no_vehicle: "Brak pojazdów",
    no_vehicle_add: "Dodaj pojazd, aby wykonać awizację",
    driver: "Brak kierowców w podanym oddziale.",
    limit_reached: "Przepraszamy, ale wybrany przez ciebie dzień osiągnął limit awizacji. Wybierz inny dzień, aby dokończyć proces awizacji.",
    taken_window: "Przepraszamy, ale wybrane przez ciebie okno czasowe zostało zajęte. Wybierz inne okno czasowe, aby dokończyć proces awizacji.",
  },
  system: {
    system: "System",
    blocked: "Zablokowani",
    version: "Wersja systemu",
    client: "Numer klienta",
    company_name: "Nazwa firmy",
    name_surname: "Imię i nazwisko",
    operating_system: "System operacyjny",
    browser_version: "Wersja przeglądarki",
    browser_language: "Język przeglądarki",
    date: "Data",
  },
  car_types: {
    solo_cars: "Auto solowe",
    naczepa: "Naczepa",
    auta_solowe: "Auto solowe",
    dluzyca_przyczepa: "Dłużyca przyczepa",
    tractor: "Ciągnik siodłowy",
    ciagniki_siodlowe: "Ciągnik siodłowy",
    tandem: "Tandem",
    solo_car: "Auto solowe",
    plain_tractor: "Ciągnik",
    furgon: "Furgon",
    niskopodwoziowy_przyczepa: "Niskopodwoziowy (przyczepa)",
    autolaweta_laweta: "Autolaweta/laweta",
    wywrotka: "Wywrotka",
    hakowiec: "Hakowiec",
    walkingfloor: "Wywrotka Walkingfloor",
    custom: "Niestandardowy pojazd",
    custom_short: "Niestandardowe",
    bdf_system_przyczepa: "BDF System",
    bdf_system_naczepa: "BDF System",
    izoterma: "Izoterma",
    cysterna_chemiczna: "Cysterna chemiczna",
    cysterna_paliwowa: "Cysterna paliwowa",
    cysterna_gazowa: "Cysterna gazowa",
    chlodnia: "Chłodnia",
    platforma: "Platforma",
    przyczepa: "Przyczepa",
    plandeks_firanka: "Plandeka/Firanka",
    plandeka_firanka: "Plandeka/Firanka",
    dluzyca: "Dłużyca",
    plandeka_bde: "Plandeka BDE",
    plandeka_coilmulde: "Plandeka Coilmulde",
    plandeka_joloda: "Plandeka Joloda",
    plandeka_jumbo: "Plandeka Jumbo",
    plandeka_mega: "Plandeka Mega",
    plandeka_standard: "Plandeka standard",
    sztywna_zabudowa: "Sztywna zabudowa",
    sztywna_zabudowa_skrzynia: "Sztywna zabudowa skrzynia",
    chlodnia_hakowka: "Chłodnia hakówka",
    cysterna_inna: "Cysterna inna",
    cysterna_silos: "Cysterna silos",
    cysterna_spożywcza: "Cysterna spożywcza",
    podkontenerowa_20_cysterna: "Podkontenerowa 20' cysterna",
    podkontenerowa_40_cysterna: "Podkontenerowa 40' cysterna",
    podkontenerowa_20_standard: "Podkontenerowa 20' standard",
    podkontenerowa_40_standard: "Podkontenerowa 40' standard",
    podkontenerowa_45_standard: "Podkontenerowa 45' standard",
    podkontenerowa_swapbody: "Podkontenerowa Swapbody",
    niskopodwoziowy: "Niskopodwioziowy",
  },
  document_types: {
    all: "Wszystkie",
    file: "Inne",
    nip: "NIP",
    regon: "REGON",
    krs_cdig: "KRS/CEIDG",
    transport_license: "(Licencja transportowa)",
    carrier_insurance: "(Ubezpieczenie przewoźnika)",
    license: "Licencja",
    insurance: "Ubezpieczenie",
    policy: "Polisa",
    agreements: "Warunki umowy",
  },
  statuses: {
    waiting: "Oczekujące",
    rejected: "Odrzucone",
    accepted: "Zaakceptowane",
    retreated: "Wycofane",
    isWaiting: "Oczekuje",
    accepted_leasings: "Przyjęte wnioski",
    waiting_leasings: "Złożone wnioski",
    rejected_leasings: "Odrzucone wnioski",
    multiple_accepted: "Zaakceptowani",
    multiple_approved: "Przyjęte",
    all: "Wszystkie",
    read: "Odczytane",
    unread: "Nieodczytane",
    paid: "Opłacone",
    canceled: "Anulowane",
    initialized: "Rozpoczęte",
    all_multiple: "Wszyscy",
    confirmed: "Potwierdzone",
  },
  authorizations: {
    no_department: "Brak oddziałów",
    no_warehouse: "Brak magazynów",
    add_department: "Dodaj oddział",
    add_warehouse: "Dodaj magazyn",
    cant_authorize_warehouse: "Aby móc się autoryzować, musisz posiadać przynajmniej jeden magazyn",
    cant_authorize_department: "Aby móc się autoryzować, musisz posiadać przynajmniej oddział",
    cant_monitary_warehouse: "Aby móc się awizować, musisz posiadać przynajmniej jeden magazyn",
    cant_monitary_department: "Aby móc się awizować, musisz posiadać przynajmniej jeden oddział",
    pending: "Wykaz oczekujących autoryzacji",
    to_fill_calendar: "Aby uzupełnić kalendarz, musisz posiadać przynajmniej jeden magazyn",
    all: "Wykaz wszystkich autoryzacji",
    show_pending: "Zobacz oczekujące autoryzacje",
    show_all: "Zobacz wszystkie autoryzacje",
    authorization_request_send: "Wysłano prośbę o autoryzację",
    authorization_success: "Pomyślnie zautoryzowano",
    authorization_rejected: "Autoryzacja odrzucona",
    authorization_error: "Brak autoryzacji",
    authorization_status: "Status autoryzacji",
    authorized: "Zautoryzowani",
    waiting: "Oczekujący na akceptację",
    rejected: "Odrzuceni",
    show_authorizations_for: "Pokaż autoryzacje dla",
    show_agreements_for: "Pokaż warunki umowy dla",
    show_statuses_for: "Pokaż statusy aut dla",
    pick_department_to_authorization_w: "Wybierz magazyn, który chcesz autoryzować z przewoźnikiem",
    pick_department_to_authorization_d: "Wybierz oddział, który chcesz autoryzować z magazynem",
    show_details: "Pokaż szczegóły",
    retreat: "Czy na pewno chcesz wycofać autoryzację?",
    accept: "Czy na pewno chcesz zaakceptować autoryzację?",
    reject: "Czy na pewno chcesz odrzucić autoryzację?",
  },
  auctions: {
    cancled_auctions: "Anulowane licytacje",
    waiting_auctions: "Przydzielone licytacje",
    listed_auctions: "Wystawione licytacje",
    selected_auctions: "Przydzielone licytacje",
    current_auctions: "Uczestniczące licytacje",
    my_transactions: "Złożone oferty",
    my_auctions: "Moje licytacje",
    all_auctions: "Wszystkie",
  },
  demand: {
    accept_demand: "Przyjmij żądanie",
    reject_demand: "Odrzuć żądanie",
    cancel_demand: "Anuluj żądanie",
    send_demand: "Wyślij żądanie",
  },
  bank: {
    bank: "Bank",
    loan: "Kredyt",
    leasing: "Leasing",
    factoring: "Faktoring",
  },
  vehicle_alerts: {
    delete_vehicle_question: "Czy na pewno chcesz usunąć pojazd?",
    no_vehicles_for_category: "Dodaj pojazdy do danej kategorii, będą one wyświetlane w odpowiedniej zakładce.",
  },
  archive: {
    show_archive_for: "Pokaż archiwum dla",
  },
  filters: {
    clear_filters: "Wyczyść filtry",
    search: "Szukaj",
    sort: "Sortuj po",
    filter_by: "Filtruj po",
    search_by: "Szukaj po",
    no_sorting: "Brak sortowania",
    alph_driver: "Alfabetycznie (po nazwie przewoźnika)",
    alph_warehouse: "Alfabetycznie (po nazwie magazynu)",
    alph_asc: "Alfabetycznie A-z (po nazwie)",
    alph_desc: "Alfabetycznie Z-a (po nazwie)",
    newest: "Najwcześniej utworzone",
    oldest: "Najpóźniej utworzone",
    id_asc: "ID (rosnąco)",
    id_desc: "ID (malejąco)",
    id_warehouse_asc: "Nr zlecenia magazynów (rosnąco)",
    id_warehouse_desc: "Nr zlecenia magazynów (malejąco)",
    radius: "Promień (w kilometrach)",
    document_search: "Wpisz nazwę dokumentu",
    car_search: "Wpisz nazwę auta",
    department_search: "Wyszukaj po nazwie",
    worker_search: "Wpisz imię lub nazwisko pracownika",
    driver_search: "Wpisz imię lub nazwisko kierowcy",
    leasing_search: "Wpisz nazwę lub nr tel. firmy, aby wyszukać wniosek.",
    auction_search: "Wpisz NIP, ID firmy lub ID licytacji, aby wyszukać aukcję.",
    chats_search: "Wpisz nazwę oddziału, aby wyszukać czat.",
    chats_search2: "Wpisz nazwę oddziału, NIP firmy lub nr. awizacji, aby wyszukać czat.",
    by_department: "Wpisz nazwę oddziału, e-mail oddziału, nazwę lub NIP firmy.",
    company_search: "Wprowadź NIP, ID firmy lub jej nazwę.",
    company_search_detailed: "Wprowadź NIP, ID lub nazwę wyszukiwanej firmy",
    user_search: "Wprowadź ID, e-mail, imię lub nazwisko użytkownika.",
    invoice: "Wpisz nazwę firmy, aby wyszukać fakturę.",
    docs: "Wpisz nazwę firmy, aby wyszukać dokument.",
    raports: "Wpisz ID, nazwę lub NIP szukanego oddziału/firmy.",
    auctions: "Wpisz ID, nazwę lub NIP",
    auctions_detailed: "Wpisz ID, nazwę, NIP firmy lub ID licytacji, aby wyszukać aukcję",
    show_drivers_for: "Pokaż kierowców dla",
    auctions_by_id: "Wpisz ID licytacji, aby wyszukać aukcję",
    auctions_by_id_short: "Wpisz ID aukcji",
  },
  languages: {
    pl: "Polski",
    en: "Angielski",
  },
  widgets: {
    authorization_widget: "Widżety do autoryzacji",
    currency_widget: "Widżet do kursu walut",
    map: "Mapa funkcjonalności",
    cars: "Statusy aut",
    error: "Błąd przy aktualizowaniu widżetów",
    success: "Poprawnie zaktualizowano widżety",
    nbp_currencies: "Średni kurs banku NBP",
    available: "Dostępne widżety z pulpitu",
    currency: "Kursy walut",
    monitory: "Kalendarz awizacji",
    functionality_map: "Mapa funkcjonalności",
    my_cars: "Moje pojazdy",
    drivers: "Lista kierowców",
    advertisement: "Reklama",
    user_departments: "Moje oddziały",
    documents: "Dokumenty",
    workers: "Pracownicy",
    company: "Firma",
    carrier_groups: "Moje grupy",
    user_external_warehouses: "Magazyny zewnętrzne",
    user_warehouses: "Lista magazynów",
    monitory_notes_archive: "Archiwum awizacji",
    warehouses_list: "Moje magazyny",
    authorizations: "Autoryzacje",
    monitory_notes: "Awizacje",
    car_statuses: "Statusy aut",
    participants_list: "Członkowie licytacji",
    auctions_archive: "Archiwum licytacji",
    created_groups: "Utworzone grupy",
    auctions: "Licytacje",
    invoices: "Faktury",
    subscription: "Abonament",
    time_windows: "Okna czasowe",
    leasing: "Leasing",
    factoring: "Faktoring",
    surcharges: "Dopłaty",
    transports: "Transporty",
  },
  phone_verifications: {
    title: "Posiadasz niezweryfikowany numer telefonu",
    user: "użytkownika",
    department: "oddziału ",
    company: "firmy ",
    enter_code: "Wpisz kod wysłany na powyższy numer telefonu",
    resend: "Wyślij ponownie",
    verified: "Zweryfikowano numer telefonu",
    edited: "Edytowano numer telefonu",
    wrong_code: "Podany kod weryfikacyjny jest niepoprawny",
    wait: "Musisz odczekać chwilę, zanim ponownie wyślemy kod weryfikacyjny",
    wrong_phone: "Niepoprawny numer telefonu",
  },
  insurences: {
    title: "Ubezpieczenia",
    date: "Ważność polisy",
  },
  monitaryStatuses: {
    accept: "Potwierdzenie awizacji",
    finish: "Zakończenie awizacji",
    thanks: "Dziękujemy!",
    text1: "Awizacja ",
    text2: "została pomyślnie potwierdzona",
    text3: "została pomyślnie zakończona",
    list: "Zobacz listę awizacji",
  },
  help: {
    title: "Potrzebujesz pomocy?",
    text: "Jeśli wystąpiły problemy w trakcie korzystania z aplikacji lub potrzebujesz wsparcia w jej użyciu, skontaktuj się z nami za pośrednictwem poniższych danych:",
    phone: "Numer telefonu:",
    email: "Adres e-mail:",
  },
  notifications: {
    title: "Powiadomienia",
    created: "utworzone",
    markAsRead: "Oznacz jako przeczytaną",
    see_all: "Zobacz wszystkie",
    see_all_noti: "Zobacz wszystkie powiadomienia",
  },
  leasing: {
    title: "Leasingi",
    text1: "Procedura uproszczona leasingu pojazdów i maszyn",
    text2: "Decyzja Automatyczna w 15 minut - pojazdy",
    text3:
      "Przy pierwszej wpłacie początkowej na pojazdy w wysokości 10% - nie wymagamy żadnych dokumentów finansowych, tylko wypełnienie samego wniosku przy działalności powyżej 12 m-cy. Dla nowo założonych firm, procedura uproszczona przy pierwszej wpłacie 20% wartości pojazdu.",
    download: "Pobierz wniosek do wypełnienia",
    no_applications: "Brak wniosków",
    add_application: "Dodaj nowy wniosek",
    your_applications: "Twoje wnioski",
    confirm: "Czy na pewno chcesz usunąć ten wniosek?",
    action: "Przyjmij lub odrzuć ten wniosek",
    info: "Otrzymaliśmy Twój wniosek leasingowy. Powiadomimy Cię, gdy zostanie rozpatrzony.",
  },
  chat: {
    show_chats_for: "Pokaż czaty dla:",
    no_chats: "Brak aktywnych czatów",
    no_messages: "Brak wiadomości",
    go_to_chat: "Przejdź do czatu",
    chat_with: "Czat z:",
    chat_for: "Czat dla:",
    send: "Wyślij",
    create_chat: "Stwórz nowy czat",
    create_chat_with: "Chcę stworzyć czat z:",
    no_authorized: "Brak zautoryzowanych oddziałów do czatowania.",
    create: "Utwórz czat",
  },

  unfolded: {
    phone: "Telefon",
    created: "Stworzona",
    transaction: "Transakcja",
    from: "Skąd",
    to: "Dokąd",
    when: "Kiedy",
    offers: "Oferty",
    lp: "Lp",
    id_logi: "ID LOGIplanner",
    company_name: "Nazwa firmy",
    company_address: "Adres firmy",
    company_owner: "Właściciel firmy",
    details: "Zobacz szczegóły",
    doc: "Dokument",
    name: "Nazwa",
    when_added: "Kiedy dodano",
    action: "Akcja",
    auction: "Aukcja",
    status: "Status",
    amount: "Kwota",
    no_offers: "Brak ofert na tej aukcji",
  },
  snackbars: {
    error: "Wystąpił nieoczekiwany błąd. Spróbuj ponownie.",
    errorEmpty: "Pole nie może być puste.",
    invite_success: "Pomyślnie dodano firmę do listy zaproszeń",
    length_error: "Pole z nazwą jest za długie. Maksymalna długość to 30.",
    error50: "Pole musi mieć maksymalnie 50 znaków i nie może być puste.",
    not_found_company: "Nie znaleziono numeru NIP w bazie GUS",
    allowSms: "Zezwól na powiadomienia SMS",
    auto_offering: "Wybierz granicę auto-przebijania oferty",
    choose_car: "Wybierz auto",
    no_chats: "Brak czatów",
    no_message: "Brak wiadomości do wysłania",
    nip_wrong: "Nie znaleziono numeru NIP w bazie GUS",
    nip_taken: "Firma o tym numerze NIP już istnieje w aplikacji LOGIplanner.",
    no_group: "Nie wybrano grupy.",
    no_departments: "Nie wybrano oddziałów.",
    no_department: "Nie wybrano oddziału, do którego przypisać grupę.",
    pick_group: "Wybierz grupę docelową",
    no_name: "Nie nazwałeś grupy",
    chat_create: "Wybrana firma nie posiada oddziałów, z którymi można utworzyć czat.",
    no_address: "Brak adresu",
    no_companies: "Brak firm w podanych kategoriach wyszukiwania",
    company_edit_success: "Poprawnie edytowano firmę",
    department_edit: "Edycja danych oddziału",
    user_edit: "Edycja danych użytkownika",
    no_results: "Brak wyników wyszukiwania",
    delete_package: "Czy na pewno chcesz usunąć pakiet?",
    edit: "Edycja danych",
    no_authorizations:
      "Na tej liście pojawią się wszystkie Twoje autoryzacje z magazynami. Przejdź do Listy magazynów znajdującej się w zakładce Okna czasowe, aby utworzyć pierwszą autoryzację.",
    no_authorizations_warehouse:
      "Na tej liście pojawią się wszystkie Twoje autoryzacje z przewoźnikami. Otrzymasz powiadomienie, gdy nowa prośba zostanie wysłana przez przewoźnika.",
    to_add_car: "Aby dodać auto, najpierw musisz posiadać oddziały",
    no_offers_in_auction: "Brak ofert w tej aukcji",
    group_created: "Pomyślnie utworzono grupę.",
    group_empty_error: "Musisz dodać przynajmniej jeden istniejący oddział do grupy.",
    changes_saved: "Pomyślnie zapisano zmiany.",
    chat_redirect_error: "Nie znaleziono chatu",
    permission_error: "Brak uprawnień",
    permission_detailed: "Nie możesz zarządzać kontami innych administratorów, swoim własnym kontem, bądź jeśli sam nie jesteś administratorem.",
  },

  raport: {
    auction_over: "Koniec aukcji",
    action_type: "Rodzaj akcji",
    date: "Data wyk. akcji",
    creator: "Wykonujący",
    creator_short: "Wykonawca",
    about: "Dotyczy",
    ip: "Adres IP i miejscowość",
    details: "Szczegóły",
    before: "Przed zmianą",
    after: "Po zmianie",
    types: {
      department_remove_worker: "Usunięcie pracownika z oddziału",
      department_add_worker: "Dodanie pracownika do oddziału",
      company_remove_worker: "Usunięcie pracownika z firmy",
      company_add_worker: "Dodanie pracownika do firmy",
      user_data_changed: "Zmiana danych użytkownika",
      user_data_changed222: "Zmiana danych użytkownika",
      company_leasing_application_pending: "Dodano wniosek leasingowy",
      company_change_status_blocked: "Zablokowanie firmy",
      company_change_status_activated: "Autoryzowanie firmy",
      company_change_status: "Zmiana statusu firmy",
      change_company_status: "Zmiana statusu firmy",
      leasing_application: "Zmiana statusu leasingu",
      issue_auction: "Dodanie aukcji",
      user_change_status_blocked: "Zablokowanie pracownika",
      user_change_status_activated: "Autoryzowanie pracownika",
      user_change_status_deleted: "Usunięcie pracownika",
      admin_add_worker: "Dodanie pracownika LOGIplanner",
      admin_edit_user_profile: "Admin edytował profil użytkownika",
      admin_add_payment_price_list_item: "Dodanie pozycji do cennika",
      admin_leasing_status_rejected: "Odrzucenie wniosku leasingowego",
      admin_leasing_status_accepted: "Przyjęcie wniosku leasingowego",
      admin_edit_department_profile: "Admin edytował oddział",
      admin_edit_company_profile: "Admin edytował firmę",
      admin_edit_payment_price_list_item: "Edycja cennika",
      admin_delete_payment_price_list_item: "Usunięcie pozycji z cennika",
      auction_submitting_a_bid: "Dodanie oferty do aukcji",
      company_monitory_note_purchase: "Zakup okna czasowego",
      company_subscription_purchase: "Zakup subskrypcji",
      department_send_chat_message: "Wysłanie wiadomości w czacie",
      company_add_department: "Firma dodała oddział",
      company_krs_cdig_document_upload: "Firma dodała dokument KRS",
      department_send_chat_message_with_attachment: "Oddział wysłał wiadomość z załącznikiem",
      edit_auction: "Edycja aukcji",
    },
    keys: {
      companyId: "ID firmy",
      orderNumber: "Numer zlecenia",
      currency: "Waluta",
      remarks: "Dodatkowe inf.",
      amount: "Kwota",
      status: "Status",
      country: "Kraj",
      auctionSubId: "ID aukcji",
      userDepartment: "Pracownik usunięty",
      user: "Pracownik",
      auctionId: "Dodana aukcja",
      originalname: "Nazwa pliku",
      fileId: "Pobierz plik",
      subId: "Numer",
      email: "E-mail",
      name: "Imię/nazwa",
      message: "Treść wiadomości",
      surname: "Nazwisko",
      phone: "Telefon",
      userPhone: "Telefon",
      phoneNumberVerified: "Nr tel. zweryfikowany",
      isAccountVerified: "Konto zweryfikowane",
      allowSms: "Zezwala na kontakt SMS",
      price: "Cena",
      amountSubscriptionDays: "Liczba dni subskrypcji",
      departmentPhone: "Nr telefonu oddziału",
      address: "Adres",
      city: "Miasto",
      zipCode: "Kod pocztowy",
      countryCode: "Kod kraju",
      contractTerms: "Warunki umowy",
      contacTermsHelper: "Dodaj warunki umowy w pliku pdf",
      shortName: "Nazwa",
      companyAddresses: "Adres firmy",
      companyPhone: "Nr telefonu firmy",
      companyNip: "NIP",
      companyName: "Nazwa",
      addedUser: "Dodany pracownik",
      isDeleted: "Czy usunięta",
      monitoryNotesNumber: "Liczba okien czasowych",
    },
    statuses: {
      blocked: "Zablokowano",
      activated: "Aktywowano",
      deleted: "Usunięto",
      pending: "Oczekujący",
      accepted: "Przyjęty",
      rejected: "Odrzucony",
      initialized: "Rozpoczęta",
      waiting_for_acceptance: "Oczekuje",
    },
  },

  change_type_messages: {
    confirm_action: "Potwierdź wybór",
    change_account_type: "Zmień typ konta",
    general: "Zmiana konta spowoduje następujące zmiany:",
    confirmation_text: "Czy na pewno chcesz zmienić typ konta z {0} na {1}?",
    driver_to_warehouse:
      "Wszystkie oddziały przewoźnika zostaną zmienione w magazyny;Utracony zostanie dostęp do Portfela;Wszystkie autoryzacje zostaną usunięte;Utracone zostaną wszystkie zapisane pojazdy;Anulowane zostaną wszystkie złożone oferty w licytacjach oraz wszystkie przydzielone zlecenia i licytacje;Dane firmy, oddziałów pozostaną bez zmian",
    warehouse_to_driver:
      "Wszystkie magazyny zostaną zmienione w oddziały przewoźnika;Wszystkie zaplanowane i oczekujące awizacje zostaną anulowane;Wszystkie autoryzacje zostaną usunięte;Nie można zmienić typu konta, jeżeli w którymkolwiek z magazynów trwa właśnie awizacja;Dane firmy, oddziałów pozostaną bez zmian",
    driver_to_factory:
      "Wszystkie oddziały przewoźnika zostaną zmienione w oddziały producenta;Utracony zostanie dostęp do Portfela;Wszystkie autoryzacje zostaną usunięte;Utracone zostaną wszystkie zapisane pojazdy;Anulowane zostaną wszystkie złożone oferty w licytacjach oraz wszystkie przydzielone zlecenia i licytacje;Dane firmy, oddziałów pozostaną bez zmian",
    factory_to_warehouse:
      "Wszystkie oddziały i magazyny producenta zostaną zmienione w magazyny;Wszystkie autoryzacje zostaną usunięte;Anulowane zostaną wszystkie utworzone licytacje oraz zlecenia przydzielone przewoźnikom;Dane firmy, oddziałów pozostaną bez zmian",
    warehouse_to_factory:
      "Wszystkie magazyny zostaną zmienione w magazyny przewoźnika;Wszystkie zaplanowane i oczekujące awizacje zostaną anulowane;Wszystkie autoryzacje zostaną usunięte;Nie można zmienić typu konta, jeżeli w którymkolwiek z magazynów trwa właśnie awizacja;Dane firmy, oddziałów pozostaną bez zmian",
    factory_to_driver:
      "Wszystkie oddziały i magazyny producenta zostaną zmienione w oddziały przewoźnika;Wszystkie autoryzacje zostaną usunięte;Anulowane zostaną wszystkie utworzone licytacje oraz zlecenia przydzielone przewoźnikom;Dane firmy, oddziałów pozostaną bez zmian",
    errors: {
      404: "Nie znaleziono firmy",
      403: "Nie możesz zmienić typu konta, ponieważ trwają na nim awizacje",
    },
  },
  blocking: {
    title: "Czy chcesz zablokować oddział?",
    empty: "Tutaj będą wyświetlane zablokowane przez Ciebie oddziały.",
    how: "Oddział można zablokować z poziomu ekranu szczegółów oddziału, do którego można dostać się przykładowo z autoryzacji.",
    go_to_list: "Lista oddziałów",
    info: "Nie będziesz mógł tworzyć awizacji z zablokowanym oddziałem lub magazynem. Nie będziesz też widział licytacji tworzonych przez niego. Listę zablokowanych można edytować w Ustawieniach.",
    choose_department: "Wybierz swój oddział",
    choosing: "Wybierz oddział, dla którego chcesz zablokować",
    choosing_unblock: "Wybierz oddział, dla którego chcesz odblokować",
    choosing2: "Możesz wybrać kilka pozycji.",
    list_title: "Lista zablokowanych",
    return: "Przywróć",
    unblocked: "Oddział został przywrócony",
    unblock_title: "Czy na pewno chcesz przywrócić oddział?",
  },
  factoring: {
    do_you_want: "Czy chcesz faktoring?",
    commission: "Prowizja za faktoring wynosi ",
    list: "Wykaz faktoringu",
    search: "Szukaj po ID aukcji, firmy, nip...",
    searchLong: "Wpisz ID aukcji, firmy lub NIP, aby wyszukać faktoring",
  },
  groups: {
    name: "Nazwa grupy",
    members: "Liczba członków",
  },
  adverts: {
    add: "Dodaj reklamę",
    pic: "Zdjęcie reklamowe",
    link: "Link (opcjonalne)",
    save: "Zapisz reklamę",
    saving: "Zapisywanie...",
    deleting: "Na pewno chcesz usunąć reklamę?",
    pic_error: "Musisz dodać przynajmniej jedno zdjęcie",
  },
  confirmings: {
    driver_came: "Czy na pewno potwierdzasz przyjazd kierowcy?",
    driver_absent: "Czy na pewno potwierdzasz brak kierowcy?",
  },
  calendar_request_modal: {
    title: "Wyślij prośbę o uzupełnienie kalendarza",
    subtitle: "Wybierz formę otrzymania powiadomienia",
    checkbox1: "Chcę otrzymać powiadomienie SMSem",
    checkbox2: "Chcę otrzymać powiadomienie mailem",
    button: "Wyślij prośbę",
  },
  role_descriptions: {
    driver: {
      owner:
        "Posiada wszystkie uprawnienia (dodawanie oddziałów, autoryzowanie oddziałów,  dodawanie pracowników, edycja pracowników, dodawanie zdjęć, logo, dokumentów firmy, zamawianie awizacji, dodawanie pojazdów, składanie ofert w licytacji, składanie wniosków leasingowych).",
      admin:
        "Może dodawać dokumenty do firmy, dodawać pracowników do oddziału, do którego jest przypisany, autoryzować oddziały, dodawać pojazdy, zamawiać awizacje oraz składać oferty w imieniu oddziału, do którego jest przypisany.",
      worker:
        "Może dodawać pojazdy, autoryzować oddziały, zamawiać awizacje oraz składać oferty do aukcji w imieniu oddziału, do którego jest przypisany.",
    },
    warehouse: {
      owner:
        "Posiada wszystkie uprawnienia (dodawanie magazynów, autoryzowanie oddziałów, dodawanie pracowników, edycja pracowników, dodawanie zdjęć, logo, dokumentów firmy, tworzenie kalendarza awizacji, przeprowadzanie awizacji, składanie wniosków leasingowych).",
      admin:
        "Może dodawać dokumenty do firmy, dodawać pracowników do oddziału, do którego jest przypisany, tworzyć kalendarz awizacji, przeprowadzać awizacje.",
      worker: "Może tworzyć kalendarz awizacji, przeprowadzać awizacje oddziału, do którego jest przypisany.",
    },
    factory: {
      owner:
        "Posiada wszystkie uprawnienia (dodawanie magazynów, autoryzowanie magazynów,  dodawanie pracowników, edycja pracowników, dodawanie zdjęć, logo, dokumentów firmy, zamawianie awizacji, dodawanie licytacji, tworzenie grup, tworzenie kalendarza awizacji, przeprowadzanie awizacji, składanie wniosków leasingowych)",
      admin:
        "Może dodawać dokumenty do firmy, dodawać pracowników do magazynu, do którego jest przypisany, autoryzować magazyny, dodawać licytacje, dodawać grupy, tworzyć kalendarz awizacji, przeprowadzać awizacje magazynu, do którego jest przypisany.",
      worker: "Może autoryzować magazyny, przeprowadzać awizacje, tworzyć kalendarz w imieniu magazynu, do którego jest przypisany.",
    },
  },

  info_icons: infoIcons,
  transport_list: {
    list: "Lista transportów",
    pick_road: "Wybierz odcinek trasy i zobacz szczegóły oraz podpisy",
    signatures_word: "Podpisy",
    sign: "Podpisz",
    see: "Zobacz",
    sender: "Nadawca",
    particles: "Część przypisana do rozładunku",
    pallets: "L. palet",
    items: "L. sztuk",
    weight: "L. ton",
    type: "Typ",
    signatures: {
      driver: "Podpis kierowcy",
      sender: "Podpis nadawcy",
      receiver: "Podpis odbiorcy",
    },
  },
  roles: {
    OWNER: "Administrator",
    ADMINISTRATOR: "Kierownik",
    WORKER: "Pracownik",
  },
  routes: routes,
  files: {
    errors: {
      size: "Rozmiar pliku nie może przekraczać 10MB",
    },
  },
  reporting: {
    terms: {
      sendSuccess: "Wysłano zgłoszenie",
      sendError: "Wystąpił problem z wysłaniem zgłoszenia",
      send: "Zgłoś problem z warunkami umowy",
      enterMessage: "Wpisz wiadomość aby zgłosić problem z warunkami umowy:",
      sendShort: "Wyślij zgłoszenie",
    },
  },

  "missing-department-modal": {
    "title-warehouse": "Utwórz magazyn",
    "title-department": "Utwórz oddział",
    "warehouse-description":
      "Magazyny są podstawowym elementem LOGIplanner. Wszystkie najważniejsze czynności w aplikacji wykonujesz, przypisując magazyn, którego dotyczyć będzie planowana akcja. Dodaj pierwszy magazyn, aby móc w pełni korzystać z aplikacji.",
    "department-description":
      "Oddziały są podstawowym elementem LOGIplanner. Wszystkie najważniejsze czynności w aplikacji wykonujesz, przypisując oddział, którego dotyczyć będzie planowana akcja. Dodaj pierwszy oddział, aby móc w pełni korzystać z aplikacji.",
  },
};
