export const determineStartAndEndDateFromTimeFrame = (timeFrame: string) => {
  const now = new Date();
  const dateTo = new Date(now);
  let dateFrom;
  switch (timeFrame) {
    case "TODAY":
      dateFrom = new Date(now);
      break;
    case "YESTERDAY":
      dateFrom = new Date(now);
      dateFrom.setDate(now.getDate() - 1);
      dateTo.setDate(now.getDate() - 1);
      break;
    case "LAST_7":
      dateFrom = new Date(now);
      dateFrom.setDate(now.getDate() - 6);
      break;
    case "LAST_30":
      dateFrom = new Date(now);
      dateFrom.setDate(now.getDate() - 29);
      break;
    case "THIS_MONTH":
      dateFrom = new Date(now.getFullYear(), now.getMonth(), 1);
      break;
    case "LAST_MONTH":
      dateFrom = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      dateTo.setMonth(now.getMonth(), 0);
      break;
    case "THIS_YEAR":
      dateFrom = new Date(now.getFullYear(), 0, 1);
      break;
    case "LAST_YEAR":
      dateFrom = new Date(now.getFullYear() - 1, 0, 1);
      dateTo.setFullYear(now.getFullYear() - 1, 11, 31);
      break;
    default:
      throw new Error("Invalid time frame");
  }

  return { dateFrom, dateTo };
};
