import i18n from "@/i18n";

export const convertDateToStandardFormat = (date, removeGMT = false) => {
  if (date) {
    const d = new Date(date);
    if (removeGMT) {
      d.setHours(10);
    }
    return d.toISOString().split("T")[0] ;
  } else return "";
};
export const format = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day < 10 ? "0" : ""}${day}.${month < 10 ? "0" : ""}${month}.${year}`;
};
export const formatWithHour = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minutes = date.getMinutes();
  return `${day < 10 ? "0" : ""}${day}.${month < 10 ? "0" : ""}${month}.${year} ${hour < 10 ? "0" + hour : hour}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
};
export const convertLongStringToDateAndHour = (date) => {
  if (date === null || !date) return "-";
  return `${date.substring(8, 10)}.${date.substring(5, 7)}.${date.substring(0, 4)} ${date.substring(11, 16)}`;
};
export const convertLongStringToDate = (date) => {
  if (date === null || date === undefined || date === "Invalid date") return "-";
  return `${date.substring(8, 10)}.${date.substring(5, 7)}.${date.substring(0, 4)}`;
};
export const convertLongStringToHour = (date) => {
  if (date === null || !date) return "-";
  return date.substring(11, 16);
};
export const goDayBack = (date) => {
  return date.setDate(date.getDate() - 1);
};

export function getMinutesFromHHMMSS(hms) {
  var a = hms.toString().split(":");
  var minutes = +a[0] * 60 + +a[1];

  return minutes;
}

export const getTimeToNow = (date) => {
  const date_now = new Date();
  const date_future = new Date(date);
  if (date_future < date_now) return i18n.global.t("generals.date_expired");
  // get total seconds between the times
  var delta = Math.abs(date_future - date_now) / 1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  var seconds = delta % 60; // in theory the modulus is not required
  return {
    days,
    hours,
    minutes,
  };
  return `${days}dn.,     ${hours}godz.,     ${minutes}min.`;
  //  return `Dni: ${days}, godzin: ${hours}, minut: ${minutes}, sekund: ${Math.floor(
  //    seconds
  //  )}`;
  return `Dni: ${days}, godzin: ${hours}`;
};
