import { calculateYear } from "@/js/utils";
import { Day } from "@/stores/calendar.store";
class CalendarService {
  checkIfCalendarHasAtLeastOneDayAvailable(data: Day[]) {
    const found = data.find((day) => day.availabilitiesNumber && day.availabilitiesNumber > 0);
    return Boolean(found);
  }
  computeSlotCapacity(takenAvailabilitiesNumber: number, availabilitiesNumber: number) {
    if (takenAvailabilitiesNumber === availabilitiesNumber) return -1;
    if (takenAvailabilitiesNumber === 0 && availabilitiesNumber !== 0) return 1;
    return 2;
  }
  isPassedDay(day: Day, currentMonth: number) {
    const today = new Date();
    if (calculateYear(currentMonth) > today.getFullYear()) return false;
    today.setHours(0, 0, 0, 0);
    //@ts-ignore
    return day.date < today;
  }
  isPassedDate(day: string) {
    const today = new Date();
    const date = new Date(day);
    today.setHours(0, 0, 0, 0);
    return date < today;
  }
  isToday(day: Day) {
    const today = new Date();
    if (
      //@ts-ignore
      today.toDateString() === day.date?.toDateString()
    ) {

      return true;
    }

    return false;
  }
}

export default CalendarService;
