<template>
  <div class="legend-wrapper">
    <div class="legend-wrapper__element">
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="5" fill="#697FD7" />
      </svg>

      <span style="margin-left: 10px">{{ $t("profile.calendar.ramps.filled") }}</span>
    </div>
    <div class="legend-wrapper__element">
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_245_3790" fill="white">
          <path
            d="M9.34286 2.52219C8.90796 1.75994 8.28009 1.12558 7.52235 0.682855C6.76461 0.240134 5.90369 0.00465093 5.02611 6.81724e-05C4.14853 -0.00451458 3.2852 0.221965 2.52287 0.656747C1.76055 1.09153 1.12609 1.7193 0.683251 2.47697L2.40995 3.48618C2.67565 3.03158 3.05633 2.65492 3.51372 2.39405C3.97112 2.13318 4.48912 1.99729 5.01567 2.00004C5.54222 2.00279 6.05877 2.14408 6.51341 2.40971C6.96806 2.67535 7.34478 3.05596 7.60572 3.51331L9.34286 2.52219Z"
          />
        </mask>
        <path
          d="M9.34286 2.52219C8.90796 1.75994 8.28009 1.12558 7.52235 0.682855C6.76461 0.240134 5.90369 0.00465093 5.02611 6.81724e-05C4.14853 -0.00451458 3.2852 0.221965 2.52287 0.656747C1.76055 1.09153 1.12609 1.7193 0.683251 2.47697L2.40995 3.48618C2.67565 3.03158 3.05633 2.65492 3.51372 2.39405C3.97112 2.13318 4.48912 1.99729 5.01567 2.00004C5.54222 2.00279 6.05877 2.14408 6.51341 2.40971C6.96806 2.67535 7.34478 3.05596 7.60572 3.51331L9.34286 2.52219Z"
          stroke="#697FD7"
          stroke-width="4"
          mask="url(#path-1-inside-1_245_3790)"
        />
        <mask id="path-2-inside-2_245_3790" fill="white">
          <path
            d="M0.693662 2.45924C0.245666 3.21855 0.00640193 4.08281 0.000126663 4.96441C-0.00614861 5.84601 0.220788 6.71359 0.65793 7.4792C1.09507 8.24481 1.72687 8.88122 2.48928 9.32392C3.25169 9.76662 4.1176 9.99986 4.99922 10L4.99953 8C4.47056 7.99992 3.95102 7.85997 3.49357 7.59435C3.03612 7.32873 2.65704 6.94689 2.39476 6.48752C2.13247 6.02815 1.99631 5.5076 2.00008 4.97865C2.00384 4.44969 2.1474 3.93113 2.4162 3.47554L0.693662 2.45924Z"
          />
        </mask>
        <path
          d="M0.693662 2.45924C0.245666 3.21855 0.00640193 4.08281 0.000126663 4.96441C-0.00614861 5.84601 0.220788 6.71359 0.65793 7.4792C1.09507 8.24481 1.72687 8.88122 2.48928 9.32392C3.25169 9.76662 4.1176 9.99986 4.99922 10L4.99953 8C4.47056 7.99992 3.95102 7.85997 3.49357 7.59435C3.03612 7.32873 2.65704 6.94689 2.39476 6.48752C2.13247 6.02815 1.99631 5.5076 2.00008 4.97865C2.00384 4.44969 2.1474 3.93113 2.4162 3.47554L0.693662 2.45924Z"
          stroke="#B4BAC3"
          stroke-width="4"
          mask="url(#path-2-inside-2_245_3790)"
        />
        <mask id="path-3-inside-3_245_3790" fill="white">
          <path
            d="M5 10C5.87759 10 6.73973 9.76902 7.49977 9.33026C8.25982 8.8915 8.89099 8.26043 9.32987 7.50045C9.76874 6.74048 9.99986 5.87838 10 5.00079C10.0001 4.12319 9.76929 3.26102 9.33065 2.50091L7.59839 3.50054C7.86157 3.95661 8.00008 4.47391 8 5.00047C7.99992 5.52703 7.86125 6.04429 7.59792 6.50027C7.33459 6.95626 6.95589 7.3349 6.49986 7.59816C6.04384 7.86141 5.52656 8 5 8V10Z"
          />
        </mask>
        <path
          d="M5 10C5.87759 10 6.73973 9.76902 7.49977 9.33026C8.25982 8.8915 8.89099 8.26043 9.32987 7.50045C9.76874 6.74048 9.99986 5.87838 10 5.00079C10.0001 4.12319 9.76929 3.26102 9.33065 2.50091L7.59839 3.50054C7.86157 3.95661 8.00008 4.47391 8 5.00047C7.99992 5.52703 7.86125 6.04429 7.59792 6.50027C7.33459 6.95626 6.95589 7.3349 6.49986 7.59816C6.04384 7.86141 5.52656 8 5 8V10Z"
          stroke="#F28787"
          stroke-width="4"
          mask="url(#path-3-inside-3_245_3790)"
        />
      </svg>
      <span style="margin-left: 10px"> {{ $t("profile.calendar.ramps.current") }}</span>
    </div>
    <div class="legend-wrapper__element">
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="5" fill="#B4BAC3" />
      </svg>

      <span style="margin-left: 10px"> {{ $t("profile.calendar.ramps.empty") }}</span>
    </div>
    <div class="legend-wrapper__element">
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="5" fill="#F28787" />
      </svg>
      <span style="margin-left: 10px">{{ $t("profile.calendar.ramps.error") }}</span>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.legend-wrapper {
  svg {
    width: 12px;
    height: 12px;
  }
}
</style>
