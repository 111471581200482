export interface Ramp {
  id: string;
  name: string;
  calendar: {
    rampId: string;
    day: number;
    startHour: string;
    endHour: string;
    interval: string;
    blockMonitary: boolean;
    unlimitedMonitoryNotes: boolean;
  }[];
}
export interface RampAPI {
  createdAt: string;
  id: string;
  lp: number;
  modifiedAt: string;
  name: string;
  number: number;
}

class RampService {
  createInitRampsArray(initialRamps: RampAPI[]) {
    const initRamps = [];
    for (let i = 0; i < initialRamps.length; i++) {
      initRamps.push({
        id: initialRamps[i]?.id,
        name: initialRamps[i]?.name,
        calendar: [
          {
            rampId: `${i}-1`,
            day: 1,
            startHour: "",
            endHour: "",
            interval: "",
            blockMonitary: false,
            unlimitedMonitoryNotes: false,
          },
          {
            rampId: `${i}-2`,
            day: 2,
            startHour: "",
            endHour: "",
            interval: "",
            blockMonitary: false,
            unlimitedMonitoryNotes: false,
          },
          {
            rampId: `${i}-3`,
            day: 3,
            startHour: "",
            endHour: "",
            interval: "",
            blockMonitary: false,
            unlimitedMonitoryNotes: false,
          },
          {
            rampId: `${i}-4`,
            day: 4,
            startHour: "",
            endHour: "",
            interval: "",
            blockMonitary: false,
            unlimitedMonitoryNotes: false,
          },
          {
            rampId: `${i}-5`,
            day: 5,
            startHour: "",
            endHour: "",
            interval: "",
            blockMonitary: false,
            unlimitedMonitoryNotes: false,
          },
          {
            rampId: `${i}-6`,
            day: 6,
            startHour: "",
            endHour: "",
            interval: "",
            blockMonitary: false,
            unlimitedMonitoryNotes: false,
          },
          {
            rampId: `${i}-7`,
            day: 7,
            startHour: "",
            endHour: "",
            interval: "",
            blockMonitary: false,
            unlimitedMonitoryNotes: false,
          },
        ],
      });
    }
    return initRamps as Ramp[];
  }
  isRampChosen(rampId: string, rampArray: Ramp[]) {
    const found = rampArray.find((ramp) => ramp.id === rampId);
    return Boolean(found);
  }
  parseStepOneData(stepOne: { blockLimit: boolean }[], calendarData: Ramp[]) {
    const tmp = calendarData.map((ramp) => {
      ramp.calendar.map((day) => {
        if (stepOne[day.day - 1]?.blockLimit) day.blockMonitary = true;
        return day;
      });
      return ramp;
    });
    return tmp;
  }
  calculateRampInterval(timeString: string): number {
    const [hoursStr, minutesStr] = timeString.split(":");
    const hours = parseInt(hoursStr as string, 10);
    const minutes = parseInt(minutesStr as string, 10);
    return hours * 60 + minutes;
  }

  isRampEmpty(ramp: Ramp) {
    let flag = true;
    ramp.calendar.forEach((entry) => {
      if (
        entry.blockMonitary ||
        (entry.endHour !== "" && entry.startHour !== "" && entry.interval !== "") ||
        (entry.unlimitedMonitoryNotes && entry.endHour !== "" && entry.startHour !== "")
      ) {
        //row filled
      } else {
        //has unfilled row - therefore is empty
        flag = false;
      }
    });
    return !flag;
  }
  pickRampFromChosenRamps(chosenRamps: Ramp[], initialRamps: Ramp[]) {
    const found = initialRamps.find((ramp) => ramp.id === chosenRamps[0]?.id);
    if (!found) return 0;
    return initialRamps.indexOf(found) as number;
  }

  updateSeveralCalendars(
    chosenRamps: Ramp[],
    initialRamps: Ramp[],
    calendarData: {
      rampId: string;
      day: number;
      startHour: string;
      endHour: string;
      interval: string;
      blockMonitary: false;
      unlimitedMonitoryNotes: false;
    }[]
  ) {
    const tmpInitial = [...initialRamps];
    chosenRamps.forEach((ramp) => {
      tmpInitial.map((initialRamp) => {
        if (initialRamp.id === ramp.id) {
          initialRamp.calendar.forEach((calD, i) => {
            if (calendarData[i]) {
              calD.endHour = calendarData[i]?.endHour ?? "";
              calD.startHour = calendarData[i]?.startHour ?? "";
              calD.interval = calendarData[i]?.interval ?? "";
              calD.unlimitedMonitoryNotes = calendarData[i]?.unlimitedMonitoryNotes ?? false;
              calD.blockMonitary = calendarData[i]?.blockMonitary ?? false;
            }
          });
        }
      });
    });
    return tmpInitial;
  }
}

export default RampService;
