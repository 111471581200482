import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import authHelper from "@/helpsers/auth.helper";
import DepartmentService from "@/services/department.service";
import { inject, ref } from "vue";
import AlertHelper from "@/helpsers/alert.helper";
import { parseError } from "@/js/error";

export interface Advert {
  id: string;
  createdAt: string;
  files: Array<FileObject>;
  modifiedAt: string;
  imageUrl?: string;
  url?: string;
}
export interface FileObject {
  compressFileId: string;
  createdAt: string;
  modifiedAt: string;
  customName?: string;
  id: string;
  isCompress: boolean;
  visibleToAll: boolean;
  originalname: string;
  type: string;
}

export const useAdvertsStore = defineStore("advert-store", () => {
  const adverts = ref<Advert[]>([]);
  const loading = ref<boolean>(false);
  let alert = inject("alert");
  async function addAdvert(body: any) {
    try {
      await axios.post(ApiHelper.fetchAdverts(), body, {
        headers: authHelper.authHeader(),
      });
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function fetchAdverts() {
    const departmentService = new DepartmentService();
    loading.value = true;

    try {
      const res = await axios.get(ApiHelper.fetchAdverts(), {
        headers: authHelper.authHeader(),
      });

      if (res) {
        adverts.value = [];
        let tmp = res.data.map((advert: Advert) => {
          if (advert.files && advert.files[0] && advert.files[0].id) {
            departmentService
              .getImageAndReturnUrl(advert.files[0].id as string)
              .then((res) => {
                advert.imageUrl = res;
              })
              .catch((e) => {
                AlertHelper.show(alert, parseError(e), "", "error");
              });
          }
          return advert;
        });

        setTimeout(() => {
          adverts.value = tmp;
          loading.value = false;
        }, 3000);
      }
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function deleteAdvert(id: string) {
    try {
      await axios.delete(ApiHelper.deleteAdvert(id), {
        headers: authHelper.authHeader(),
      });
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  return {
    adverts,
    loading,
    addAdvert,
    fetchAdverts,
    deleteAdvert,
  };
});
