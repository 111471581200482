import { defineStore } from "pinia";
import { inject, ref } from "vue"; // Import ref from Vue
import axios from "@/axios";
import i18n from "@/i18n";
import { parseError } from "@/js/error";
import { Department } from "./department.store";
import ApiHelper from "@/helpsers/api.helper";
import authHelper from "@/helpsers/auth.helper";
import { RegularErrorResponse, RegularGetResponse } from "./utils/interfaces";
import AlertHelper from "@/helpsers/alert.helper";
import { CarType, isCarTypeCar } from "@/js/car-utils";

export interface Car {
  name: string;
  id?: string;
  alert?: any;
  departmentId?: string;
  type?: string;
  registrationNumber?: string;
  trailerNumber?: string;
  semitrailerNumber?: string;
  carId?: string;
  modifiedAt?: string;
  createdAt?: string;
  status?: string;
  carType?: string;
  isDeleted?: boolean;
  newPolicy?: boolean;
  carRegistrationNumber?: string;
  department?: Department;
  carInsurer?: any;
  technicalReviewExpirationDateFrom?: string;
  policyExpirationDateFrom?: string;
  customName?: string;
}

export interface ShortCar {
  id: string;
  name: string;
  registrationNumber: string | null;
  semitrailerNumber: string | null;
  trailerNumber: string | null;
  type: string;
  vin: string | null;
  carType: string | null;
}

export interface Insurer {
  name: string;
  modifiedAt?: string;
  createdAt?: string;
  id: string;
}

interface Meta {
  total: number;
  perPage: string;
  currentPage: number;
}

export const useCarStore = defineStore("car", () => {
  let alert = inject("alert");
  const cars = ref<Car[]>([]);
  const shortCars = ref<ShortCar[]>([]);
  const allCars = ref<Car[]>([]);
  const page = ref(1);
  const onPage = ref(4);
  const total = ref(0);
  const isLoading = ref(false);
  const insurers = ref<Insurer[]>([]);
  const carListMeta = ref(
    new Map<string, string | number>([
      ["total", 0],
      ["perPage", 4],
      ["currentPage", 1],
    ])
  );

  const addCar = async (obj: Car) => {
    let { alert, ...object } = obj;
    try {
      isLoading.value = true;
      const res = await axios.post(ApiHelper.addCar(), object);
      isLoading.value = false;
      return res;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
      isLoading.value = false;
    }
  };

  const addCarPolicy = async (file: File, carId: string, alert?: any) => {
    const fd = new FormData();
    fd.append("file", file);
    try {
      isLoading.value = true;
      const response = await axios.put(ApiHelper.addCarPolicy(carId), fd);
      isLoading.value = false;
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
      isLoading.value = false;
      throw e;
    }
  };

  const removeCar = async (id: string) => {
    return await axios
      .put(ApiHelper.removeCar(id))
      .then(() => {
        AlertHelper.show(alert, "Sukces", i18n.global.t("profile.add_car.delete_complete"), "success");
      })
      .catch((err: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(err), "", "error");
      });
  };

  const hasCarType = async (companyId: string, carType: string) => {
    try {
      const response = await axios.get(ApiHelper.checkType(companyId, carType));
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchInsurers = async (id: string, pageC: number, onPageC: number) => {
    try {
      const res = await axios.get(ApiHelper.listInsurers(id) + `?limit=${onPageC}&page=${pageC}`, {
        headers: authHelper.authHeader(),
      });
      total.value = res.data.meta.total;
      onPage.value = res.data.meta.per_page;
      page.value = res.data.meta.current_page;
      insurers.value = res.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchInsurersForCompany = async (id: string, pageC: number, onPageC: number) => {
    try {
      const res = await axios.get(ApiHelper.listInsurersForCompany(id) + `?limit=${onPageC}&page=${pageC}`, {
        headers: authHelper.authHeader(),
      });
      total.value = res.data.meta.total;
      onPage.value = res.data.meta.per_page;
      page.value = res.data.meta.current_page;
      insurers.value = res.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const editCar = async (obj: Car) => {
    let { alert, ...object } = obj;
    try {
      isLoading.value = true;
      const res = await axios.put(ApiHelper.updateCar(), object);
      isLoading.value = false;
      return res;
    } catch (e) {
      isLoading.value = false;
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const listCarsToPick = async (id: string, type: string) => {
    if (id == "-1" || !id || id == "0") return;
    return await axios
      .get(ApiHelper.listCars(id))
      .then((res: RegularGetResponse<Car>) => {
        cars.value = res.data.data;
      })
      .catch((e: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(e), "", "error");
      });
  };

  const listCarsToPickForDepartment = async (departmentId: string) => {
    if (parseInt(departmentId) === -1) return;
    try {
      const res = await axios.get(ApiHelper.listCarsForDepartmentUrl(departmentId));
      shortCars.value = res.data;
      return res.data;
    } catch (error) {
      AlertHelper.show(alert, parseError(error), "", "error");
    }
  };

  const listCarsToPickForCompany = async (id: string, type: string) => {
    return await axios
      .get(ApiHelper.listCarsCompany(id) + "?limit=999")
      .then((res: RegularGetResponse<Car>) => {
        cars.value = res.data.data;
      })
      .catch((e: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(e), "", "error");
      });
  };

  const listAllCarsForCompany = async (id: string) => {
    try {
      const url = ApiHelper.listCarsCompany(id) + "?limit=999";
      return await axios.get(url);
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const listCars = async (
    id: string,
    type: string | { type: CarType; subtype: string },
    meta: Map<string, string | number> | undefined,
    sorting: string,
    companyId?: string
  ) => {
    if (!id || id == "0" || id == "-1") return;
    let url = id === "ALL" ? ApiHelper.listCarsCompany(companyId) + "?" : ApiHelper.listCars(id) + "?";
    url += `page=${meta?.get("currentPage") ?? carListMeta.value.get("currentPage")}&`;
    url += `limit=${meta?.get("perPage") ?? carListMeta.value.get("perPage")}&`;

    if (typeof type === "object" && type.type !== "ALL") {
      if (isCarTypeCar(type.type)) {
        if (type.subtype === "NIESTANDARDOWY") {
          url += `type=NIESTANDARDOWY&`;
        } else {
          url += type.subtype ? `carType=${type.subtype}` : "";
        }
      } else {
        if (type.subtype) url += `type=${type.subtype}`;
        else url += `type=${type.type}`;
      }
    }
    if (sorting.length > 0 && sorting && sorting !== "") url += sorting;

    return await axios
      .get(url)
      .then((res: RegularGetResponse<Car>) => {
        carListMeta.value.set("total", res.data.meta.total);
        //@ts-ignore
        carListMeta.value.set("currentPage", parseInt(res.data.meta.current_page));
        cars.value = res.data.data;
      })
      .catch((e: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(e), "", "error");
      });
  };
  async function listCarsForCompany(id: string) {
    try {
      const response = await axios.get(ApiHelper.listCarsCompany(id) + "?limit=999&page=1");
      allCars.value = response.data.data;
    } catch (e) {
      throw e;
    }
  }
  return {
    cars,
    allCars,
    listCarsForCompany,
    page,
    onPage,
    total,
    isLoading,
    insurers,
    carListMeta,
    addCar,
    addCarPolicy,
    removeCar,
    hasCarType,
    fetchInsurers,
    fetchInsurersForCompany,
    editCar,
    listCarsToPick,
    listCarsToPickForCompany,
    listAllCarsForCompany,
    listCars,
    listCarsToPickForDepartment,
    shortCars,
  };
});
