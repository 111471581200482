import i18n from "@/i18n";
import { Department } from "./department.store";
import { Company, User } from "./company.store";
import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import AlertHelper from "@/helpsers/alert.helper";
import { convertDateToStandardFormat } from "@/js/date.utils";
import { Car } from "./cars.store";
import { Driver } from "./driver.store";
import { FileObject } from "./admin/adverts/adverts.store";
import { inject, ref } from "vue";
import { isCarMissingError, isVerificationTransferError, isWindowsMissingInWallet, parseError } from "@/js/error";
import { RegularErrorResponse, RegularGetResponse } from "./utils/interfaces";
import { Rating } from "./ratings.store";
export interface bodyAuctionI {
  offerBindingTo?: string;
  monetaryAmount: number;
  carId?: string;
  departmentId: string;
  auctionId: string;
  isAuto?: boolean;
  monetaryAmountLimit?: number;
  alert?: any;
  emitter?: any;
  semitrailerId?: string;
  trailerId?: string;
}
export interface AuctionOffer {
  id: string;
  createdAt: string;
  modifiedAt: string;
  monetaryAmount: number;
  offerBindingTo: string;
  status: string;
  details?: string;
  isAuto: boolean;
  currency?: string;
  monetaryAmountLimit?: number;
  departmentOfferingThePrice?: Department;
}
export interface Cargo {
  name?: string;
  loadingType?: string;
  createdAt: string;
  modifiedAt: string;
  description: string;
  documentNumber: string;
  weight: string | GLfloat | number;
  partWeight?: string | GLfloat | number;
  length: string | GLfloat | number;
  volume: string | GLfloat | number;
  capacity?: string | GLfloat | number;
  id: string;
  remarks: string;
  type?: string;
  numberOfPallets?: number;
  partNumberOfPallets?: number;
  numberOfPieces?: number;
  partNumberOfPieces?: number;
  isComingFromInitialObject?: boolean;
  numberOfItems?: number;
  dateDetailed?: string;
  index?: number;
}
export interface Operation {
  loading: any;
  id?: string;
  lp?: string;
  dateDetailed?: string;
  address: string;
  city: string;
  ramp?: { id: string };
  chosenDate?: string;
  country: string;
  cargos: Array<Cargo>;
  createdAt: string;
  modifiedAt: string;
  date: string;
  type: string;
  zipCode: string;
  lat?: string;
  lng?: string;
  deliveriesNumber: number;
  chosenHour?: string;
  warehouse: Department;
  unloadings?: Array<Operation>;
}
export interface Auction {
  id: string;
  lp?: string;
  createdAt: string;
  modifiedAt: string;
  carType: string;
  carPart?: string;
  companyLoader?: Company;
  carrier?: Company;
  companyLowestOffer?: AuctionOffer;
  contractTerms?: string;
  dateOfLoading: string;
  dateOfUnloading: string;
  deadline: string;
  departmentLoader?: Department;
  idLoader: string;
  isMarkedAsRead: boolean;
  loadingCity: string;
  loadingCityZipCode: string;
  loadingCountry: string;
  loadingLength: number;
  loadingVolume: number;
  loadingWeight: number;
  lowestOffer?: AuctionOffer;
  numberDeliveries: string;
  orderType: string;
  remarks?: string;
  startingPrice: number;
  currentPrice: string;
  status: string;
  subId: number;
  surcharges?: Array<any>;
  unloadingCity: string;
  unloadingCityZipCode: string;
  unloadingCountry: string;
  user?: User;
  currency?: any;
  files?: Array<any>;
  orderNumber: string;
  carrierId: string;
  cargoOperations?: Array<Operation>;
  rating: Rating;
}

export interface CurrentBid {
  amountInt: number;
  currency?: "EUR" | "GBP" | "PLN";
  dateDay?: string;
  dateHour?: string;
  deaprtmentId: string;
  autoConquering: boolean;
  autoIntC?: number;
  autoIntCCurrency?: string;
  comment?: string;
}

export interface CMRList {
  loading: {
    address: string;
    countryCode: string;
    city: string;
    country: string;
    createdAt: string;
    date: string;
    dateFrom: null;
    id: string;
    modifiedAt: string;
    order: number;
    type: string;
    warehouse: Department;
    zipCode: string;
  };
  unloading: {
    address: string;
    countryCode: string;
    city: string;
    country: string;
    createdAt: string;
    date: string;
    dateFrom: null;
    id: string;
    modifiedAt: string;
    order: number;
    type: string;
    warehouse: Department;
    zipCode: string;
  };
}
export interface CMRDetails {
  cargos: Cargo[];
  car: Car;
  semitrailer?: Car;
  trailer?: Car;
  carrier: Department;
  departmentLoader: Department;
  driver: Driver;
  files: FileObject[];
  loading: CMROperation;
  unloading: CMROperation;
}
interface CMROperation {
  createdAt: string;
  date: string;
  eCmrSignatureId?: string;
  id: string;
  modifiedAt: string;
  type: string;
  verificationUrl: string;
  warehouse?: Department;
}
export interface Group {
  createdAt: string;
  departments: Department[];
  id: string;
  lp: number;
  modifiedAt: string;
  name: string;
}

export interface AddAuctionReq {
  alert: any;
  auctionType: string;
  bidThreshold: 3;
  carPart: string;
  carType: string;
  cargoOperations: Operation[];
  carrierId?: string;
  companyLoaderId: string;
  currency: string;
  deadline: string;
  departmentLoaderId: string;
  emitter: any;
  groupId?: string;
  loadType: string;
  orderNumber: string;
  paymentDeadlineDays: number;
  remarks: string;
  startingPrice: number;
  surcharges: { title: string; value: number }[];
}
export const useStockStore = defineStore("stock", () => {
  let alert = inject("alert");
  const isLoading = ref<boolean>(false);
  const auctions = ref<Auction[]>([]);
  const auctionCMR = ref<CMRList[]>([]);
  //@ts-ignore
  const auctionCMRCurrentDetails = ref<CMRDetails>({});
  //@ts-ignore
  const currentAuction = ref<Auction>({});
  const auctionOffers = ref<AuctionOffer[]>([]);
  const myAuctionOffers = ref<AuctionOffer[]>([]);
  const finishedAuctions = ref<Auction[]>([]);
  const finishedAuctionsOther = ref<Auction[]>([]);
  const justAddedAuctionId = ref<string>("");
  const allMyAuctionOffers = ref<AuctionOffer[]>([]);
  //@ts-ignore
  const currentOffer = ref<AuctionOffer>({});
  const allTransactions = ref<Auction[]>([]);
  const allTransactionsOther = ref<Auction[]>([]);
  const auctionsArchive = ref<Auction[]>([]);
  const myAuctions = ref<Auction[]>([]);
  const blueprints = ref<Auction[]>([]);
  const diary = ref<any[]>([]);
  const surcharges = ref<AuctionOffer[]>([]);
  const changelog = ref<any[]>([]);
  const departmentsWithOffer = ref<Department[]>([]);
  const myGroups = ref<Group[]>([]);
  const allGroups = ref<Group[]>([]);
  const onPage = ref<number>(4);
  const onPageSmaller = ref<number>(2);
  const page = ref<number>(1);
  const total = ref<number>(0);
  const mainOnPage = ref<number>(4);
  const mainPage = ref<number>(1);
  const mainTotal = ref<number>(0);
  const auctionsCounter = ref<number>(0);
  //@ts-ignore
  const currentBid = ref<CurrentBid>({});

  const producerAuctionsCounter = ref({
    cancelled: 0,
    created: 0,
    participants: 0,
  });

  const driverAuctionsCounter = ref({
    active: 0,
    participating: 0,
    won: 0,
  });

  const driverTransportsCounter = ref({
    assigned: 0,
    cancelled: 0,
    lost: 0,
  });

  const auctionsCounterByStatus = ref({
    active: 0,
    reservationSent: 0,
    reserved: 0,
    waitingForReservation: 0,
    waitingForPayment: 0,
    total: 0,
  });

  async function getSingleAuction(id: string) {
    try {
      const response = await axios.get(ApiHelper.getSingleAuction(id));
      let tmp = response.data;
      tmp.cargoOperations.forEach((operation: Operation) => {
        operation.chosenHour = "";
      });
      currentAuction.value = tmp;
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getSingleAuctionShort(id: string) {
    try {
      const response = await axios.get(ApiHelper.getSingleAuctionShort(id));
      let tmp = response.data;
      tmp.cargoOperations.forEach((operation: Operation) => {
        operation.chosenHour = "";
      });
      currentAuction.value = tmp;
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAuctionDetailsForCMR(auctionToken: string) {
    try {
      const response = await axios.get(ApiHelper.getAuctionDetailsForCMR(auctionToken));
      auctionCMR.value = response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAuctionDetailsForCMROneOperation(auctionToken: string, loadingId: string, unloadingId: string) {
    try {
      const response = await axios.get(ApiHelper.getAuctionDetailsForCMROneOperation(auctionToken, loadingId, unloadingId));
      auctionCMRCurrentDetails.value = response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getCMR(fileID: string) {
    try {
      const response = await axios.get(ApiHelper.downloadCMRDocument(fileID), {
        responseType: "blob",
      });
      if (response.data.type === "application/pdf") {
        const fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/octetstream",
          })
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${fileID}.pdf`);
        fileLink.setAttribute("href", fileURL);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      }
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function sendSignature(auctionToken: string, formData: any) {
    try {
      const response = await axios.post(ApiHelper.sendSignature(auctionToken), formData);
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getSingleAuctionOffer(id: string) {
    try {
      const response = await axios.get(ApiHelper.getSingleAuctionOffer(id));
      currentOffer.value = response.data;
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function makeReservations(body: any) {
    const { alert, emitter, ...obj } = body;
    try {
      const response = await axios.post(ApiHelper.makeReservations(), obj);
      AlertHelper.show(alert, i18n.global.t('globals.reservation_success'), "", "success");
      return response;
    } catch (e) {
      //@ts-ignore
      emitter?.emit("showPopup", {
        message: parseError(e),
        type: "error",
        button: isWindowsMissingInWallet(e)
          ? {
              label: i18n.global.t('generals.add_windows'),
              redirect: "wallet",
            }
          : undefined,
      });
    }
  }
  async function changeOfferStatus(id: string, status: string) {
    try {
      const response = await axios.put(ApiHelper.changeOfferStatus(id), {
        status: status,
      });
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function countAllAuctions() {
    try {
      const response = await axios.get(ApiHelper.getCountedAuctionsUrl() + "?statuses=ACTIVE");
      auctionsCounter.value = response.data.number;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function countAllProducerAuctions(companyId: string) {
    try {
      const response = await axios.get(ApiHelper.getProducerAuctionsCounter(companyId));
      const { cancelled, created, participants } = response.data;
      producerAuctionsCounter.value = {
        cancelled,
        created,
        participants,
      };
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function countAllDriverAuctions(companyId: string) {
    try {
      const response = await axios.get(ApiHelper.getDriverAuctionsCounter(companyId));
      const { active, participating, won } = response.data;
      driverAuctionsCounter.value = {
        active,
        participating,
        won,
      };
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function countAllProducerTransports(companyId: string) {
    try {
      const response = await axios.get(ApiHelper.getProducerTransportsCounter(companyId));
      const { assigned, cancelled, lost } = response.data;
      driverTransportsCounter.value = {
        assigned,
        cancelled,
        lost,
      };
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function changeAuctionStatus(id: string, status: string) {
    try {
      const response = await axios.put(ApiHelper.changeAuctionStatus(id), {
        status: status,
      });
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAllAuctions(
    cityLoad: any,
    cityUnload: any,
    filtering: string,
    chosenVehicle: string,
    chosenDeadline: string,
    maxLoads: number,
    maxUnloads: number,
    sorting: string,
    minWeight: number,
    maxWeight: number,
    loadDate: string,
    countryLoad: string,
    countryUnload: string,
    origin?: string
  ) {
    let url: string = `?limit=${mainOnPage.value}&page=${mainPage.value}&statuses=ACTIVE`;
    if (cityLoad && cityLoad.lat && cityLoad.lng) url += `&loadingLat=${cityLoad.lat}&loadingLng=${cityLoad.lng}`;
    if (cityUnload && cityUnload.lat && cityUnload.lng) url += `&unloadingLat=${cityUnload.lat}&unloadingLng=${cityUnload.lng}`;
    if (countryLoad !== "") url += `&loadingCountry=${countryLoad}`;
    if (countryUnload !== "") url += `&unloadingCountry=${countryUnload}`;
    if (maxLoads !== 0) url += `&maxLoading=${maxLoads}`;
    if (maxUnloads !== 0) url += `&maxUnloading=${maxUnloads}`;
    if (minWeight !== 0) url += `&weightMin=${minWeight}`;
    if (maxWeight !== 0) url += `&weightMax=${maxWeight}`;
    if (chosenVehicle !== "" && chosenVehicle !== "ALL") {
      if (["AUTA_SOLOWE", "CIAGNIKI_SIODLOWE", "FURGON", "HAKOWIEC", "AUTOLAWETA_LAWETA", "WYWROTKA", "WALKINGFLOOR"].includes(chosenVehicle)) {
        url += `&carType=${chosenVehicle}`;
      } else {
        url += `&carPart=${chosenVehicle}`;
      }
    }
    if (chosenDeadline !== "") url += `&date=${convertDateToStandardFormat(chosenDeadline)}`;
    if (loadDate !== "") url += `&loadingDate=${convertDateToStandardFormat(loadDate)}`;
    if (filtering !== "ALL" && filtering !== "") url += `&loadType=${filtering}`;
    if (sorting && sorting !== "") url += sorting;
    if (origin && origin !== "ALL") url += `&origin=${origin}`;

    try {
      const response = await axios.get(ApiHelper.getAllAuctions() + url);
      mainTotal.value = response.data.meta.total;
      mainOnPage.value = response.data.meta.per_page;
      mainPage.value = response.data.meta.current_page;
      auctions.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getCompanySurcharges(companyId: string, query?: string) {
    let url = `?limit=${onPage.value}&page=${page.value}${query ? `&query=${query}` : ""}`;

    try {
      const response = await axios.get(ApiHelper.getCompanySurcharges(companyId) + url);
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      surcharges.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAuctionsByCompany(companyId: string, status: string, search: string) {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    if (search && search !== "") url += `&subId=${search}`;
    if (status !== "") {
      if (status === "ALL") {
        url += `&statuses=ACTIVE,WAITING_FOR_RESERVATION,RESERVATION_SENT,RESERVED,WAITING_FOR_PAYMENT`;
      } else {
        url += `&status=${status}`;
      }
    }
    try {
      const response = await axios.get(ApiHelper.getAuctionsMadeByCompany(companyId) + url);
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      myAuctions.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAuctionsCounter(companyId: string, status?: string, search?: string) {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    try {
      const response = await axios.get(ApiHelper.getAuctionsCounter(companyId) + url);
      const { active, reservationSent, reserved, waitingForReservation, waitingForPayment } = response.data;
      auctionsCounterByStatus.value = {
        active,
        reservationSent,
        reserved,
        waitingForReservation,
        waitingForPayment,
        total: active + reservationSent + reserved + waitingForReservation + waitingForPayment,
      };
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAuctionsBlueprint(companyId: string) {
    let url = `?limit=99`;
    try {
      const response = await axios.get(ApiHelper.getAuctionsBlueprint(companyId) + url);
      blueprints.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAllAuctionsByCompany(companyId: string, status: string) {
    let url = `?limit=9999`;
    if (status !== "") url += `&status=${status}`;
    try {
      const response = await axios.get(ApiHelper.getAuctionsMadeByCompanyIds(companyId) + url);
      myAuctions.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAllMyAuctionOffers(companyId: string, status: string) {
    try {
      let url = `?limit=${onPage.value}&page=${page.value}`;
      if (status) url += `&status=${status}`;
      const response = await axios.get(ApiHelper.getAllMyAuctionOffers(companyId) + url);
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      allMyAuctionOffers.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function createActionOffer(body: bodyAuctionI) {
    const { alert, emitter, ...object } = body;
    try {
      await axios.post(ApiHelper.createAuctionOffer(), object);
      AlertHelper.show(alert, "Pomyślnie dodano ofertę aukcji", "", "success");
    } catch (e) {
      emitter.emit("showPopup", {
        message: parseError(e),
        type: "error",
        button: isVerificationTransferError(e)
          ? {
              label: "Wykonaj przelew",
              redirect: "VERIFICATION",
            }
          : isCarMissingError(e)
          ? {
              label: "Lista pojazdów",
              redirect: "list-cars",
            }
          : undefined,
      });
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function markAuctionAsRead(auctionId: string) {
    try {
      await axios.post(ApiHelper.markAuctionAsRead(auctionId));
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function renameAuctionTemplate(auctionId: string, name: string) {
    try {
      const response = await axios.put(ApiHelper.renameAuctionTemplate(auctionId), { name });
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function markAuctionAsUnread(auctionId: string) {
    try {
      await axios.put(ApiHelper.markAuctionAsUnread(auctionId));
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function removeActionOffer(auctionId: string) {
    try {
      await axios.put(ApiHelper.removeAuctionOffer(auctionId));
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function editAuctionOffer(obj: { auctionId: string; isAuto: boolean; monetaryAmountLimit?: number }) {
    const { auctionId, ...body } = obj;
    try {
      const response = await axios.put(ApiHelper.editAuctionOffer(auctionId), body);
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function editAuction(obj: any) {
    const { auctionId, alert, emitter, ...body } = obj;
    try {
      const response = await axios.put(ApiHelper.editAuction(auctionId), body);
      return response;
    } catch (e) {
      emitter.emit("showPopup", {
        message: parseError(e),
        type: "error",
        button: isVerificationTransferError(e)
          ? {
              label: "Wykonaj przelew",
              redirect: "VERIFICATION",
            }
          : undefined,
      });
      AlertHelper.show(alert, parseError(e) || i18n.global.t("snackbars.error"), "", "error");
      return;
    }
  }
  async function createAuction(body: AddAuctionReq) {
    const { alert, emitter, ...object } = body;
    try {
      const response = await axios.post(ApiHelper.createAuction(), object);
      if (response.data) justAddedAuctionId.value = response.data.id;
      AlertHelper.show(alert, "Pomyślnie dodano aukcję", "", "success");
      return response;
    } catch (e) {
      emitter.emit("showPopup", {
        message: parseError(e),
        type: "error",
        button: isVerificationTransferError(e)
          ? {
              label: "Wykonaj przelew",
              redirect: "VERIFICATION",
            }
          : undefined,
      });
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function addAuctionFiles(data: any) {
    try {
      await axios.post(ApiHelper.addAuctionFiles(), data);
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function showAllOfferWithFilterStatus(status: string, auctionId: string | number) {
    try {
      const response = await axios.get(ApiHelper.showAllOfferWithFilterStatus(status, auctionId));
      auctionOffers.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function checkChangelogUnreadStatus(auctionId: string) {
    try {
      const response = await axios.get(ApiHelper.checkChangelogUnreadStatus(auctionId));
      return response?.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function showCertainOffersForAuctionInCompany(companyId: string, auctionId: string | number) {
    try {
      const response = await axios.get(ApiHelper.showCertainOffersForAuctionInCompany(companyId, auctionId));
      myAuctionOffers.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function fetchAllTransactions(companyId: string, sorting: string, other?: boolean, query?: string) {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    if (sorting && sorting !== "") url += sorting;
    if (query && query !== "") url += `&query=${query}`;
    try {
      const response = await axios.get(ApiHelper.getAllTransactions(companyId) + url);
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      if (other) {
        allTransactionsOther.value = response.data.data;
        return;
      }
      allTransactions.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function fetchFinishedAuctionsForCompanyOffering(
    companyId: string,
    won: boolean,
    auctionStatus: string,
    sorting: string,
    query?: string,
    cancelled?: boolean
  ) {
    let url = `?limit=${onPage.value}&page=${page.value}&status=${won ? (cancelled ? "ACCEPTED,CANCELED" : "ACCEPTED") : "REJECTED,OUTBID"}`;
    if (auctionStatus !== "none") url += `&auctionStatus=${auctionStatus}`;
    if (sorting && sorting !== "") url += sorting;
    if (query && query !== "") url += `&query=${query}`;
    try {
      const response = await axios.get(ApiHelper.getFinishedAuctions(companyId) + url);
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      finishedAuctions.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function fetchGivenTransports(
    id: string,
    type: string,
    query: string,
    department: string,
    filtering: string,
    sorting: string,
    other?: boolean
  ) {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    if (type) {
      url += `&types=${type}`;
    }
    if (query) {
      url += `&query=${query}`;
    }
    if (department && department !== "ALL") {
      url += `&department=${department}`;
    }
    if (filtering && filtering !== "ALL") {
      url += `&status=${filtering}`;
    }
    if (sorting !== "") {
      url += sorting;
    }
    try {
      const response = await axios.get(ApiHelper.getGivenTransports(id) + url);
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      if (other) {
        finishedAuctionsOther.value = response.data.data;
        return;
      }
      finishedAuctions.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function fetchCompanyAuctionDiary(companyId: string, date: string) {
    try {
      const response = await axios.get(ApiHelper.getCompanyAuctionDiary(companyId) + `?date=${date}&limit=${onPage.value}&page=${page.value}`);
      diary.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAuctionArchive(companyId: string, pageC: number, onPageC: number, sorting: string, search: string) {
    try {
      let url = `?limit=${onPageC}&page=${pageC}`;
      if (sorting && sorting !== "") url += sorting;
      if (search && search !== "") url += "&query=" + search;
      const response = await axios.get(ApiHelper.getAuctionArchive(companyId) + url);
      auctionsArchive.value = response.data.data;
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function fetchAuctionChangeLog(auctionId: string, sorting?: string) {
    try {
      const response = await axios.get(
        ApiHelper.getAuctionChangeLog(auctionId) + `?limit=${onPageSmaller.value}&page=${page.value}${sorting ? `&sort_by=${sorting}` : ""}`
      );
      total.value = response.data.meta.total;
      onPageSmaller.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      changelog.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function fetchAuctionChangeLogNumber(auctionId: string) {
    try {
      const response = await axios.get(ApiHelper.getCountedAuctionChangeLogUrl(auctionId));
      return response.data?.number;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAllDepartmentsWhoPlacedOffer(departmentId: string, id: string) {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    if (id !== "") url += `&auctionSubId=${id}`;
    try {
      const response = await axios.get(ApiHelper.getAllDepartmentsWhoPlacedOffer(departmentId) + url);
      total.value = response.data.meta.total;
      onPageSmaller.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      departmentsWithOffer.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAllDepartmentsWhoPlacedOfferForCompany(companyId: string, id: string) {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    if (id !== "") url += `&auctionSubId=${id}`;
    try {
      const response = await axios.get(ApiHelper.getAllDepartmentsWhoPlacedOfferForCompany(companyId) + url);
      total.value = response.data.meta.total;
      onPageSmaller.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      departmentsWithOffer.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAllGroups(filters: any) {
    isLoading.value = true;
    let url = `?limit=${onPage.value}&page=${page.value}`;
    if (filters.searchValue && filters.searchValue !== "") url += `&query=${filters.searchValue}`;

    if (filters.chosenSorting !== "ALL") {
      url += `&sort_by=${filters.chosenSorting.includes("DESC") ? "name_DESC" : "name_ASC"}`;
    }
    if (!filters.departmentId || filters.departmentId == "0") return;
    await axios
      .get(ApiHelper.getGroups(filters.departmentId) + url)
      .then(async (response: RegularGetResponse<Group>) => {
        total.value = response.data.meta.total;
        onPageSmaller.value = response.data.meta.per_page;
        page.value = response.data.meta.current_page;
        return response.data.data;
      })
      .then((res: any) => {
        myGroups.value = res;
        isLoading.value = false;
      })
      .catch((e: RegularErrorResponse) => {
        isLoading.value = false;
        AlertHelper.show(alert, parseError(e), "", "error");
      });
  }
  async function getAllGroupsByCompanyId(filters: any) {
    isLoading.value = true;
    let url = `?limit=${onPage.value}&page=${page.value}`;
    if (filters.searchValue && filters.searchValue !== "") url += `&query=${filters.searchValue}`;

    if (filters.chosenSorting !== "ALL") {
      url += `&sort_by=${filters.chosenSorting.includes("DESC") ? "name_DESC" : "name_ASC"}`;
    }
    if (!filters.companyId || filters.companyId == "0") return;
    await axios
      .get(ApiHelper.getGroupsCompany(filters.companyId) + url)
      .then(async (response: RegularGetResponse<Group>) => {
        total.value = response.data.meta.total;
        onPageSmaller.value = response.data.meta.per_page;
        page.value = response.data.meta.current_page;
        return response.data.data;
      })
      .then((res: any) => {
        myGroups.value = res;
        isLoading.value = false;
      })
      .catch((e: RegularErrorResponse) => {
        isLoading.value = false;
        AlertHelper.show(alert, parseError(e), "", "error");
      });
  }
  async function getAllGroupsCompany(companyId: string) {
    try {
      const response = await axios.get(ApiHelper.getGroupsCompany(companyId));
      allGroups.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function createGroup(body: any) {
    try {
      const response = await axios.post(ApiHelper.createGroup(), body);
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function sendInvitationToGroup(body: { nip: string; email: string; message: string; groupId: string }) {
    try {
      const response = await axios.post(ApiHelper.sendInvitationToGroup(), body);
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function withdrawInvitationToGroup(invitationId: string) {
    try {
      const response = await axios.patch(ApiHelper.withdrawInvitationToGroup(invitationId));
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function assignToGroup(body: any) {
    try {
      const response = await axios.post(ApiHelper.assignToGroup(), body);
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function removeFromGroup(body: any) {
    try {
      const response = await axios.patch(ApiHelper.removeFromGroup(), body);
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function editGroup(body: any) {
    try {
      const response = await axios.put(ApiHelper.editGroup(), body);
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function deleteGroup(departmentId: string, groupId: string) {
    try {
      const response = await axios.patch(ApiHelper.deleteGroup(departmentId, groupId));
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function fetchMembers(departmentId: string, groupId: string) {
    try {
      const response = await axios.get(ApiHelper.getGroupMembers(departmentId, groupId));
      return response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function reportContractTerms(departmentId: string, message: string) {
    try {
      const response = await axios.post(ApiHelper.reportContractTerms(departmentId), { message });
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getMonitoryNotesForAuction(auctionId: string) {
    try {
      const response = await axios.get(ApiHelper.getMonitoryNotesForAuction(auctionId));
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function downloadAuctionDocument(auctionId: string) {
    try {
      const response = await axios.get(ApiHelper.downloadAuctionDocument(auctionId), {
        responseType: "blob",
      });
      if (response.data.type === "application/pdf") {
        const fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/octetstream",
          })
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${auctionId}.pdf`);
        fileLink.setAttribute("href", fileURL);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      }
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function downloadTransportDocument(auctionId: string) {
    try {
      const response = await axios.get(ApiHelper.downloadAuctionTransportDocument(auctionId), {
        responseType: "blob",
      });
      const fileURL = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/octetstream",
        })
      );
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `${auctionId}.pdf`);
      fileLink.setAttribute("href", fileURL);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  function updateCurrentBid(key: keyof CurrentBid, value: string | number | boolean) {
    //@ts-ignore
    currentBid.value = {
      ...currentBid.value,
      [key]: value,
    };
  }
  function clearCurrentBid() {
    currentBid.value = {} as CurrentBid;
  }
  return {
    isLoading,
    getSingleAuction,
    clearCurrentBid,
    updateCurrentBid,
    downloadTransportDocument,
    downloadAuctionDocument,
    getMonitoryNotesForAuction,
    reportContractTerms,
    fetchMembers,
    deleteGroup,
    editGroup,
    removeFromGroup,
    assignToGroup,
    createGroup,
    getAllGroupsCompany,
    getAllGroupsByCompanyId,
    getAllGroups,
    getAllDepartmentsWhoPlacedOfferForCompany,
    getAllDepartmentsWhoPlacedOffer,
    fetchAuctionChangeLogNumber,
    fetchAuctionChangeLog,
    getAuctionArchive,
    fetchCompanyAuctionDiary,
    fetchGivenTransports,
    fetchFinishedAuctionsForCompanyOffering,
    fetchAllTransactions,
    showCertainOffersForAuctionInCompany,
    checkChangelogUnreadStatus,
    showAllOfferWithFilterStatus,
    addAuctionFiles,
    createAuction,
    editAuction,
    editAuctionOffer,
    removeActionOffer,
    markAuctionAsUnread,
    renameAuctionTemplate,
    markAuctionAsRead,
    createActionOffer,
    getAllMyAuctionOffers,
    getAllAuctionsByCompany,
    getAuctionsBlueprint,
    getAuctionsCounter,
    getAuctionsByCompany,
    getCompanySurcharges,
    getAllAuctions,
    changeAuctionStatus,
    countAllProducerTransports,
    countAllDriverAuctions,
    countAllProducerAuctions,
    countAllAuctions,
    changeOfferStatus,
    makeReservations,
    getSingleAuctionOffer,
    sendSignature,
    getCMR,
    getAuctionDetailsForCMROneOperation,
    getAuctionDetailsForCMR,
    getSingleAuctionShort,
    auctionsCounterByStatus,
    driverTransportsCounter,
    driverAuctionsCounter,
    producerAuctionsCounter,
    currentBid,
    auctionsCounter,
    mainTotal,
    mainPage,
    mainOnPage,
    total,
    page,
    onPageSmaller,
    onPage,
    allGroups,
    myGroups,
    departmentsWithOffer,
    changelog,
    surcharges,
    diary,
    blueprints,
    myAuctions,
    auctionsArchive,
    allTransactions,
    currentOffer,
    allMyAuctionOffers,
    justAddedAuctionId,
    finishedAuctions,
    myAuctionOffers,
    auctionOffers,
    currentAuction,
    auctionCMRCurrentDetails,
    auctionCMR,
    auctions,
    finishedAuctionsOther,
    sendInvitationToGroup,
    withdrawInvitationToGroup,
  };
});
