<template>
  <div class="content-wrap">
    <template v-if="!forgotPasswordLinkSent">
      <div
        style="color: rgba(5, 24, 52, 0.6); display: flex; align-items: center; font-weight: 600; font-size: 14px; cursor: pointer"
        class="return-btn"
        @click="router.push({ name: 'login' })"
      >
        <svg style="margin-right: 10px" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.27519 0.300026C6.47519 0.500026 6.57119 0.741692 6.56319 1.02503C6.55453 1.30836 6.45019 1.55003 6.25019 1.75003L3.4252 4.57503L14.5752 4.57502C14.8585 4.57502 15.0962 4.67102 15.2882 4.86302C15.4795 5.05436 15.5752 5.29169 15.5752 5.57502C15.5752 5.85836 15.4795 6.09602 15.2882 6.28802C15.0962 6.47936 14.8585 6.57502 14.5752 6.57502L3.4252 6.57503L6.27519 9.42503C6.47519 9.62503 6.5752 9.86269 6.5752 10.138C6.5752 10.4127 6.47519 10.65 6.2752 10.85C6.0752 11.05 5.83753 11.15 5.5622 11.15C5.28753 11.15 5.05019 11.05 4.85019 10.85L0.275196 6.27503C0.175195 6.17503 0.104194 6.06669 0.0621944 5.95003C0.0208612 5.83336 0.000194074 5.70836 0.000194062 5.57503C0.000194051 5.44169 0.0208611 5.31669 0.0621943 5.20003C0.104194 5.08336 0.175195 4.97503 0.275196 4.87503L4.87519 0.275026C5.05853 0.0916929 5.28753 2.47412e-05 5.56219 2.47172e-05C5.83753 2.46932e-05 6.07519 0.100026 6.27519 0.300026Z"
            fill="#051834"
            fill-opacity="0.6"
          />
        </svg>

        Wróć
      </div>
      <h2 class="auth__heading">
        {{ $t("forgot_password.reset_password_title") }}
      </h2>
      <p class="auth__text">
        {{ $t("forgot_password.text") }}
      </p>
      <form class="auth__form">
        <div class="control control--input">
          <input
            v-model="email"
            type="text"
            id="email"
            :placeholder="$t('forgot_password.enter_email_placeholder')"
            :class="{ errorEmail }"
            style="max-width: 430px; margin: auto"
          />
          <div class="control__error" v-if="errorEmail">
            <div class="control__error-inner">
              {{ errorEmail }}
            </div>
          </div>
        </div>
        <div class="auth__buttons">
          <button
            :class="{ disabled: !meta.dirty || errorEmail || sendingForm }"
            :disabled="!meta.dirty || errorEmail || sendingForm"
            @click.prevent="resetPassword"
            type="submit"
            style="font-weight: 600"
            class="button button--brown"
          >
            <template v-if="sendingForm">{{ $t("forgot_password.sending") }}</template>
            <template v-else>{{ $t("forgot_password.send_button") }}</template>
          </button>
        </div>
      </form>
      <!-- <router-link to="/login" class="auth__prev">{{
      $t("forgot_password.back_button")
    }}</router-link> -->
    </template>
    <template v-else>
      <div
        style="color: rgba(5, 24, 52, 0.6); display: flex; align-items: center; font-weight: 600; font-size: 14px; cursor: pointer"
        class="return-btn"
        @click="forgotPasswordLinkSent = false"
      >
        <svg style="margin-right: 10px" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.27519 0.300026C6.47519 0.500026 6.57119 0.741692 6.56319 1.02503C6.55453 1.30836 6.45019 1.55003 6.25019 1.75003L3.4252 4.57503L14.5752 4.57502C14.8585 4.57502 15.0962 4.67102 15.2882 4.86302C15.4795 5.05436 15.5752 5.29169 15.5752 5.57502C15.5752 5.85836 15.4795 6.09602 15.2882 6.28802C15.0962 6.47936 14.8585 6.57502 14.5752 6.57502L3.4252 6.57503L6.27519 9.42503C6.47519 9.62503 6.5752 9.86269 6.5752 10.138C6.5752 10.4127 6.47519 10.65 6.2752 10.85C6.0752 11.05 5.83753 11.15 5.5622 11.15C5.28753 11.15 5.05019 11.05 4.85019 10.85L0.275196 6.27503C0.175195 6.17503 0.104194 6.06669 0.0621944 5.95003C0.0208612 5.83336 0.000194074 5.70836 0.000194062 5.57503C0.000194051 5.44169 0.0208611 5.31669 0.0621943 5.20003C0.104194 5.08336 0.175195 4.97503 0.275196 4.87503L4.87519 0.275026C5.05853 0.0916929 5.28753 2.47412e-05 5.56219 2.47172e-05C5.83753 2.46932e-05 6.07519 0.100026 6.27519 0.300026Z"
            fill="#051834"
            fill-opacity="0.6"
          />
        </svg>

        Wróć
      </div>
      <h2 class="auth__heading">
        {{ $t("forgot_password.reset_password_title") }}
      </h2>
      <p class="auth__text">
        {{ $t("forgot_password.success_text") }}
      </p>

      <p style="margin-top: 20px" class="auth__forgotten">
        {{ $t("forgot_password.still_no_email") }}
        <template v-if="sendingForm">
          <strong>{{ $t("forgot_password.sending") }}</strong>
        </template>
        <template v-else>
          <a href="#" @click.prevent="resendEmail" :style="{ display: 'block' }">{{ $t("forgot_password.resend_email_again") }}</a>
        </template>
      </p>
      <p v-if="sendAgain" class="auth__forgotten--again">
        {{ sendAgainMessage }}
      </p>
    </template>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { useI18n } from "vue-i18n";
import axios from "axios";
import ApiHelper from "@/helpsers/api.helper";
import { useAuthStore } from "@/stores/auth.store";
import AlertHelper from "@/helpsers/alert.helper";
import router from "@/router";
export default {
  name: "ForgotPassword",
  setup() {
    const { t } = useI18n();
    const forgotPasswordLinkSent = ref(false);
    const sendAgain = ref(false);
    const sendAgainMessage = ref("");
    const sendingForm = ref(false);
    const userStore = useAuthStore();
    let alert = inject("alert");

    const { meta, values } = useForm({
      initialValues: {
        email: "",
      },
    });

    const { value: email, errorMessage: errorEmail } = useField("email", yup.string().required(t("validate.required")).email(t("validate.email")));

    const resetPassword = async () => {
      if (!errorEmail.value && !meta.dirty) {
        try {
          await userStore.remindPassword(email.value);
          sendingForm.value = true;
          forgotPasswordLinkSent.value = true;
          sendingForm.value = false;
        } catch (err) {
          AlertHelper.show(alert, err, "", "error");
          sendAgain.value = true;
          sendAgainMessage.value = t("forgot_password.fail_send_again");
          sendingForm.value = false;
        }
      } else {
        errorEmail.value = t("validate.required");
      }
    };

    const resendEmail = async () => {
      if (email.value) {
        sendAgain.value = false;
        AlertHelper.show(alert, t("forgot_password.success_send_again"));
        await resetPassword(email.value);
      } else {
        forgotPasswordLinkSent.value = false;
      }
    };

    return {
      email,
      resetPassword,
      resendEmail,
      errorEmail,
      meta,
      forgotPasswordLinkSent,
      sendAgain,
      sendAgainMessage,
      sendingForm,
      router,
    };
  },
};
</script>

<style scoped lang="scss">
.control {
  &--input {
    input {
      height: 62px;
      max-width: 100%;
    }
  }
}
.auth {
  .control {
    &__error {
      left: 0 !important;
    }
  }
  &__buttons {
    .disabled {
      cursor: not-allowed;
      background-color: #707070;
    }
  }
}
.auth__prev {
  font-size: 14px;
}
strong {
  font-weight: 500;
}
.content-wrap {
  margin-top: 100px;
  .auth__heading {
    font-weight: 700;
    font-size: 24px;
  }
  .auth__text {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }
}
</style>
