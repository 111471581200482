export default {
  timeFormats: {
    DATE:"Dokładny zakres dat",
    TODAY: "Dzisiaj",
    YESTERDAY: "Wczoraj",
    LAST_7:"Ostatnie 7 dni",
    LAST_30:"Ostatnie 30 dni",
    THIS_MONTH:"Ten miesiąc",
    LAST_MONTH:"Ostatni miesiąc",
    THIS_YEAR:"Ten rok",
    LAST_YEAR:"Ostatni rok"
  },
};
