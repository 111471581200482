const ratings = {
  title: "Oceń wykonawcę",
  info:"Twoja opinia jest dla nas ważna. Co sądzisz o wykonanym zleceniu?",
  unhappy:"Niezadowolony",
  happy:"Zadowolony",
  comment:"Dodatkowe informacje (opcjonalne)",
  error:"Wybierz ocenę, aby przejść dalej.",
  companyRatings:"Oceny firmy",
  noComment:"Brak komentarza",
  noRatings:"Brak ocen"
};

export default ratings;
