import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import AlertHelper from "@/helpsers/alert.helper";
import { parseError } from "@/js/error";
import { inject, ref } from "vue";
import { Department } from "./department.store";

export interface Rating {
  rating: "LIKE" | "DISLIKE";
  id: string;
  comment?: string;
  carrier?: Department;
  carrierCompany?: Department;
}
interface RateAuctionReq {
  auctionId: string;
  rating: "LIKE" | "DISLIKE";
  comment?: string;
}
interface RateAuctionUpdateReq {
  ratingId: string;
  rating: "LIKE" | "DISLIKE";
  comment?: string;
}
export const useRatingStore = defineStore("ratings", () => {
  let alert = inject("alert");
  const ratings = ref([] as Rating[]);
  const companyRatings = ref([] as Rating[]);
  const onPage = ref(5);
  const page = ref(1);
  const total = ref(0);

  async function rateAuction(body: RateAuctionReq) {
    try {
      const res = await axios.post(ApiHelper.rateAuction(), body);
      return res;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function rateAuctionUpdate(body: RateAuctionUpdateReq) {
    const { ratingId, ...obj } = body;
    try {
      const res = await axios.put(ApiHelper.updateAuctionRating(ratingId), obj);
      return res;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function fetchUnratedAuctions(id: string) {
    try {
      const res = await axios.get(ApiHelper.getAuctionsToRate(id));
      ratings.value = res.data.data.map((rating: Rating) => {
        rating.carrier = rating.carrierCompany;
        return rating;
      });
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function fetchCompanyRatingList(id: string) {
    try {
      const res = await axios.get(ApiHelper.getCompanyRatings(id), {
        params: {
          limit: onPage.value,
          page: page.value,
        },
      });
      total.value = res.data.meta.total;
      onPage.value = res.data.meta.per_page;
      page.value = res.data.meta.current_page;
      companyRatings.value = res.data.data;
      console.log(res, "RATINGS");
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  return { rateAuction, rateAuctionUpdate, fetchUnratedAuctions, ratings, fetchCompanyRatingList, companyRatings, page, onPage, total };
});
