<template>
    <p class="settings__box-heading">{{ $t('profile_settings.privacy_settings') }}</p>
    <div class="settings__privacy-wrapper">
        <div class="settings__privacy">
            <label for="privacy1">{{ $t('profile_settings.receiving_notifications_about_forum_threads') }}</label>
            <input type="checkbox" class="control-switch" id="privacy1" v-model="receivingNotificationsAboutForumThreads" />
        </div>
        <div class="settings__privacy">
            <label for="privacy2">{{ $t('profile_settings.receiving_notifications_about_comments') }}</label>
            <input type="checkbox" class="control-switch" id="privacy2" v-model="receivingNotificationsAboutComments" />
        </div>
        <div class="settings__privacy">
            <label for="privacy3">{{ $t('profile_settings.commenting_user_projects_by_others') }}</label>
            <input type="checkbox" class="control-switch" id="privacy3" v-model="commentingUserProjectsByOthers" />
        </div>
        <div class="settings__privacy">
            <label for="privacy4">{{ $t('profile_settings.receiving_notifications_of_ratings_by_others') }}</label>
            <input type="checkbox" class="control-switch" id="privacy4" v-model="receivingNotificationsOfRatingsByOthers" />
        </div>
        <div class="settings__privacy">
            <label for="privacy5">{{ $t('profile_settings.receiving_messages_from_others_users') }}</label>
            <input type="checkbox" class="control-switch" id="privacy5" v-model="receivingMessagesFromOthersUsers" />
        </div>
        <div class="settings__privacy">
            <label for="privacy6">{{ $t('profile_settings.profile_indexing') }}</label>
            <input type="checkbox" class="control-switch" id="privacy6" v-model="profileIndexing" />
        </div>
    </div>
    <button class="button-outline" @click="onSubmit">{{ $t('profile_settings.update_information') }}</button>
</template>

<script>
import {useField, useForm} from "vee-validate";
import * as yup from "yup";
import {inject, ref, watch} from "vue";
import axios from "axios";
import {useAuthStore} from "@/stores/auth.store";
import VueBasicAlert from "vue-basic-alert";

import AlertHelper from "@/helpsers/alert.helper";
import {useI18n} from "vue-i18n";
import authHelper from "@/helpsers/auth.helper";
import ApiHelper from "@/helpsers/api.helper";
import {storeToRefs} from "pinia";
import UserService from "@/services/user.service";

export default {
    name: "Privacy",
    components: {
        VueBasicAlert
    },
    setup() {
        const {t} = useI18n();
        const sendingForm = ref(false);
        let alert = inject('alert');
        const authStore = useAuthStore();
        const {userInfo} = storeToRefs(authStore);

        watch(userInfo, (newVal, oldVal) => {
            receivingNotificationsAboutForumThreads.value = userInfo?.value?.receivingNotificationsAboutForumThreads ?? false;
            receivingNotificationsAboutComments.value = userInfo?.value?.receivingNotificationsAboutComments ?? false;
            commentingUserProjectsByOthers.value = userInfo?.value?.commentingUserProjectsByOthers ?? false;
            receivingNotificationsOfRatingsByOthers.value = userInfo?.value?.receivingNotificationsOfRatingsByOthers ?? false;
            receivingMessagesFromOthersUsers.value = userInfo?.value?.receivingMessagesFromOthersUsers ?? false;
            profileIndexing.value = userInfo?.value?.profileIndexing ?? false;
        });

        const { handleSubmit, errors } = useForm({
            validationSchema: yup.object().shape({
                receivingNotificationsAboutForumThreads: yup.bool(),
                receivingNotificationsAboutComments: yup.bool(),
                commentingUserProjectsByOthers: yup.bool(),
                receivingNotificationsOfRatingsByOthers: yup.bool(),
                receivingMessagesFromOthersUsers: yup.bool(),
                profileIndexing: yup.bool(),
            }),
            initialValues: {
                receivingNotificationsAboutForumThreads: userInfo?.value?.receivingNotificationsAboutForumThreads ?? false,
                receivingNotificationsAboutComments: userInfo?.value?.receivingNotificationsAboutComments ?? false,
                commentingUserProjectsByOthers: userInfo?.value?.commentingUserProjectsByOthers ?? false,
                receivingNotificationsOfRatingsByOthers: userInfo?.value?.receivingNotificationsOfRatingsByOthers ?? false,
                receivingMessagesFromOthersUsers: userInfo?.value?.receivingMessagesFromOthersUsers ?? false,
                profileIndexing: userInfo?.value?.profileIndexing ?? false,
            }
        });

        let { value: receivingNotificationsAboutForumThreads } = useField('receivingNotificationsAboutForumThreads');
        let { value: receivingNotificationsAboutComments } = useField('receivingNotificationsAboutComments');
        let { value: commentingUserProjectsByOthers } = useField('commentingUserProjectsByOthers');
        let { value: receivingNotificationsOfRatingsByOthers } = useField('receivingNotificationsOfRatingsByOthers');
        let { value: receivingMessagesFromOthersUsers } = useField('receivingMessagesFromOthersUsers');
        let { value: profileIndexing } = useField('profileIndexing');

        const onSubmit = handleSubmit(async (values, actions) => {
            sendingForm.value = true;

            axios.put(ApiHelper.getChangePrivacySettingsUrl(), values, {
                headers: authHelper.authHeader()
            }).then(response => {
                AlertHelper.show(alert, t('alert.successfully_updated_data'));

                sendingForm.value = false;
            }).catch(errorResponse => {
                actions.resetForm();
                let errorMessage = ApiHelper.getResponseMessage(errorResponse.response?.data?.code);
                AlertHelper.show(alert, errorMessage, '', 'error');

                sendingForm.value = false;
            })
        });

        return {
            receivingNotificationsAboutForumThreads,
            receivingNotificationsAboutComments,
            commentingUserProjectsByOthers,
            receivingNotificationsOfRatingsByOthers,
            receivingMessagesFromOthersUsers,
            profileIndexing,
            sendingForm,
            onSubmit,
            errors,
            alert
        }
    }
}
</script>

<style scoped>

</style>