import i18n from "@/i18n";

export const isVerificationTransferError = (e: unknown) => {
  //@ts-ignore
  return e.response.data.verificationTransfer ? true : false;
};
export const isWindowsMissingInWallet = (e: unknown) => {
  //@ts-ignore
  return e.response.data.timeWindowPurchase ? true : false;
};
export const isCarMissingError = (e: unknown) => {
  //@ts-ignore
  return e.response.data.policy ? true : false;
};

export const parseError = (e: unknown) => {
  //@ts-ignore
  if (e && e.response && e.response.data && e.response.data.fields) {
    //@ts-ignore
    const errArr = parseNestedFields(e.response.data);
    const errArrStr = errArr.join(",\n");
    return new Error(errArrStr);
  }
  //@ts-ignore
  if (e && e.response && e.response.data && e.response.data.message) return e.response.data.message;
  return i18n.global.t("snackbars.error");
};

const parseNestedFields = (data: any): string[] => {
  const result: string[] = [];
  if (data && data.fields) {
    const nestedFields = data.fields;
    for (const key in nestedFields) {
      const value = nestedFields[key];
      if (Array.isArray(value)) {
        result.push(...value);
      }
    }
  }

  return result;
};
