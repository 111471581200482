import { onBeforeUnmount, onMounted } from "vue";

export function useClickOutside(el_target_click: any, callback: any) {
  if (!el_target_click) return;

  const listener = (e: any) => {
    if (e.target == el_target_click.value || e.composedPath().includes(el_target_click.value)) {
      //CLick inside element
      return;
    }

    if (typeof callback == "function") callback(e);
  };

  onMounted(() => {
    window.addEventListener("click", listener);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("click", listener);
  });
}
