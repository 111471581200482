import axios from "@/axios";
import { defineStore } from "pinia";
import storeCache from "@/js/storeCache";
import { roleInCompany, typeOfCompany } from "@/js/utils";
import router from "@/router";
import { useCarStore } from "@/stores/cars.store";
import { useCompanyStore } from "@/stores/company.store";
import { useDepartmentStore } from "@/stores/department.store";
import { useStockStore } from "@/stores/stock.store";
import { useUserStore } from "@/stores/user.store";
import { storeToRefs } from "pinia";
import { computed } from "vue";
export const useHelpersStore = defineStore({
  id: "Helpers store",
  state: () => ({
    alertt: null,
    tasks: [
      {
        name: "DOCUMENTS",
        description: "Dodaj podstawowe dokumenty (KRS)",
        done: false, //at least 1 document in company
        optional: false,
        type: "WAREHOUSE__DRIVER__FACTORY",
      },
      {
        name: "DEPARTMENT",
        description: "Dodaj magazyn",
        done: false, //at least 1 department except main
        optional: false,
        type: "WAREHOUSE",
      },
      {
        name: "DEPARTMENT",
        description: "Dodaj oddział.",
        done: false, //at least 1 department except main
        optional: false,
        type: "DRIVER__FACTORY",
      },

      {
        name: "LOGO",
        description: "Dodaj logo firmy",
        done: false, //if company logo added
        optional: true,
        type: "WAREHOUSE__DRIVER__FACTORY",
      },
      {
        name: "WORKER",
        description: "Dodaj profil pracownika i nadaj mu uprawnienia",
        done: false, //if at least one worker besides me
        optional: true,
        type: "WAREHOUSE__DRIVER__FACTORY",
      },
      {
        name: "GROUP",
        description: "Utwórz swoją zaufaną grupę przewoźników",
        done: false, //if at least group created
        optional: true,
        type: "FACTORY",
      },
      {
        name: "VEHICLE",
        description: "Dodaj pojazd i uzupełnij dane dotyczące ubezpieczenia",
        done: false, //if at least vehicle added
        optional: true,
        type: "DRIVER",
      },
    ],
    tasksDone: 0,
    goNextTypeSwitcher: false,
    typeSwitcherValue: 0,
    otherTypeSwitcherValue: 0,
    closeModalEmit: false,
    currentMonitoryId: "",
    currentMonitoryIdOnly: "",
    showConfirmation: false,
    currentOfferId: "",
  }),
  actions: {
    adjustTasks() {
      console.log("tasks computing...");
      const company = useCompanyStore();
      const userStore = useUserStore();
      const stockStore = useStockStore();
      const carStore = useCarStore();
      let departmentStore = useDepartmentStore();
      const workers = computed(() => userStore.getWorkers);
      const { allCars } = storeToRefs(carStore);
      let { currentWarehouses } = storeToRefs(departmentStore);
      const { taskDocuments } = storeToRefs(company);
      const { allGroups } = storeToRefs(stockStore);
      let id = storeCache.get("currentCompany")?.id;
      this.tasksDone = 0;
      try {
        if (id) {
          this.tasks.forEach(async (task) => {
            if (task.name === "DOCUMENTS") {
              await company.getCompanyDocuments(id, true);
              if (taskDocuments.value && taskDocuments.value.length > 0) task.done = true;
            }
            if (task.name === "DEPARTMENT") {
              await departmentStore.fetchAllWarehouses({
                id: id,
              });
              if (currentWarehouses.value && currentWarehouses.value.length > 1) task.done = true;
            }
            if (task.name === "NUMBER_VERIFIED") {
              if (storeCache.get("verificationShown")) task.done = true;
            }
            if (task.name === "LOGO") {
              let company = storeCache.get("currentCompany");
              if (company.companyAvatar && company.companyAvatar.id) task.done = true;
            }
            if (task.name === "WORKER") {
              const workersArray = await userStore.fetchCompanyWorkersAndReturn(id);
              if(workersArray && workersArray.length>1) task.done = true
            }
            if (task.name === "GROUP" && roleInCompany() === "OWNER") {
              //todo
              await stockStore.getAllGroupsCompany(id);
              if (allGroups.value && allGroups.value.length > 0) task.done = true;
            }
            if (task.name === "VEHICLE") {
              await carStore.listCarsForCompany(id);
              if (allCars.value && allCars.value.length > 0) task.done = true;
            }
          });
          storeCache.set("tasksComputed", this.tasks);
        }
      } catch (e) {
        console.log("task error... ", e);
      }
    },
    getDoneTasks() {
      let counter = 0;
      this.tasks.forEach((task) => {
        if (task.done && task.type.includes(typeOfCompany())) counter++;
      });

      return counter;
    },
    getTasksLength() {
      let counter = 0;
      this.tasks.forEach((task) => {
        if (task.type.includes(typeOfCompany())) counter++;
      });
      return counter;
    },
    saveAlert(alert: any) {
      this.alertt = alert;
    },
    goNext() {
      this.goNextTypeSwitcher = true;
    },
    stopGoNext() {
      this.goNextTypeSwitcher = false;
    },
    hideModal() {
      this.closeModalEmit = true;
    },
    stopHideModal() {
      this.closeModalEmit = false;
    },
    setTypeSwitcherValue(value: any) {
      this.typeSwitcherValue = value;
    },
    setOtherTypeSwitcherValue(value: any) {
      this.otherTypeSwitcherValue = value;
    },
    setMonitoryId(value: string) {
      this.currentMonitoryId = value;
    },
    showConfirmationModal() {
      this.showConfirmation = true;
    },
    hideConfirmationModal() {
      this.showConfirmation = false;
    },
  },
});
