import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import { inject, ref } from "vue";
import AlertHelper from "@/helpsers/alert.helper";

export interface DocToVerifyByAdmin {
  compressFileId: string | null;
  createdAt: string;
  customName: string | null;
  description: string;
  firstDownloadedAt: string;
  id: string;
  isCompress: boolean;
  modifiedAt: string;
  originalname: string;
  status: string;
  subId: string;
  type: string;
  visibleToAll: boolean;
}

export const useDocumentsStore = defineStore("doc-store", () => {
  const docsToVerify = ref<DocToVerifyByAdmin[]>([]);
  const loading = ref<boolean>(false);
  const onPage = ref<number>(10);
  const page = ref<number>(1);
  const total = ref<number>(0);
  let alert = inject("alert");

  async function fetchDocumentsToVerify(status?: string, search?: string) {
    try {
      loading.value = true;
      const response = await axios.get(ApiHelper.fetchDocsToVerify(), {
        params: {
          page: page.value,
          limit: onPage.value,
          search: search || undefined,
          status: status && status !== "ALL" ? status : undefined,
        },
      });
      docsToVerify.value = response.data.data;
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      throw e;
    }
  }

  async function changeDocumentStatus(fileId: string, status: string) {
    try {
      loading.value = true;
      await axios.put(ApiHelper.changeDocStatus(fileId), {
        status: status,
      });
      loading.value = false;
    } catch (e) {
      loading.value = false;
      throw e;
    }
  }

  return {
    docsToVerify,
    loading,
    onPage,
    page,
    total,
    fetchDocumentsToVerify,
    changeDocumentStatus,
  };
});
