import { RegularMetaObject } from "./utils/interfaces";
import storeCache from "@/js/storeCache";
import UserService from "@/services/user.service";
import router from "@/router";
import { defineStore, storeToRefs } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import authHelper from "@/helpsers/auth.helper";
import io from "socket.io-client";
import { Callback } from "yup/lib/types";
import { useInitAdminStore } from "./admin/init/init.admin.store";
let socket: any | null = null;

export interface Chat {
  chatReceiverAvatarId: string;
  chatReceiverId: string;
  otherSideName: string;
  createdDate: string;
  departmentId: string;
  hasUnseenMessage: boolean;
  id: string;
  lastMessageAuthorId: string;
  lastMessageAuthorName: string;
  lastMessageDate: string;
  lastMessageText: string;
  monitoryNoteId: string;
  otherSideId: string;
  chatAuthorName?: string;
  chatAuthorId?: string;
  auctionId?: string;
  monitoryNoteSubId?: string;
  monitoryNoteDate?: string;
  chatId?: string;
  authorUserName?: string;
}
export interface Message {
  authorUserAvatarId: string;
  authorUserId: string;
  authorUserName: string;
  chatId: string;
  chatMessageAttachments: Array<any>;
  createdAt: string;
  id: string;
  isMy: boolean;
  status: string;
  text: string;
  type: string;
}

export interface ChatEventResponse {
  data: Array<Chat>;
  meta: RegularMetaObject;
}

export interface MessageEventResponse {
  data: Array<Message>;
  meta: RegularMetaObject;
}

export interface CreateChatObject {
  departmentId: string;
  otherSideDepartmentId: string;
  textMessage: string;
  monitoryNoteId?: string;
  auctionId?: string;
  creating?: boolean;
}
export interface CreateMessageObject {
  departmentId: string;
  otherSideDepartmentId: string;
  textMessage: string;
  monitoryNoteId?: string;
  type: string;
  auctionId?: string;
}

export const useChatStore = defineStore({
  id: "chat",
  state: () => ({
    chats: [] as Array<Chat>,
    allChats: [] as Array<Chat>,
    allUserChats: [] as Array<Chat>,
    currentChat: "" as string,
    messages: [] as Array<Message>,
    allChatMessages: [] as Array<Message>,
    numberOfUnseen: 0,
    currentChatSenderAvatar: "",
    currentChatRecieverAvatar: "",
    companyVerificationMade: false,
    companyVerificationPending: false,
    connectCallback: null as unknown as Callback,
    onPage: 3,
    page: 1,
    total: 0,
    unreadNotificationsCounter: undefined as number | undefined,
    unreadChatsCounter: undefined as number | undefined,
  }),
  actions: {
    onConnected(callback: Callback) {
      if (socket?.connected) {
        //@ts-ignore
        callback && callback();
      } else {
        this.connectCallback = callback;
      }
    },

    connect(token: string, departmentId: string) {
      const connectionConfig: any = {
        jsonp: false,
        reconnection: true,
        reconnectionDelay: 100,
        reconnectionAttempts: 1000,
        transports: ["websocket"],
        pingTimeout: 30000,
        timeout: 30000,
        query: {
          authorization: token,
          departmentId: departmentId ?? undefined,
        },
      };
      socket?.close();
      socket = io(ApiHelper.returnWebsocketUrl(), connectionConfig);

      socket.on("connect", () => {
        console.log("connection...", socket.connected);
      });
      socket.on("unreadChatsCount", (response: { unreadChatsCount: number }) => {
        this.unreadChatsCounter = response.unreadChatsCount;
      });
      socket.on("verificationTransferConfirmed", () => {
        this.companyVerificationMade = true;
      });
      socket.on("verificationTransferPending", () => {
        this.companyVerificationPending = true;
      });
      socket.on("unreadNotificationsCount", (response: { unreadNotificationsCount: number }) => {
        this.unreadNotificationsCounter = response.unreadNotificationsCount;
      });
      socket.on("response", (response: string) => {
        console.log("server-side response event...", response);
      });
      socket.on("errorMessage", (response: string | unknown) => {
        console.log("ERROR...");
      });
      socket.on("disconnect", () => {
        // console.log("connection...");
        // console.log(socket.connected); // false
      });

      socket.on("allMessages", (data: MessageEventResponse) => {
        if (data.data.length > 0 && data.data[0]?.chatId === this.currentChat) {
          this.messages = this.handleMessages(data.data);
          this.adjustMessageContainer();
        }
      });
      socket.on("allChats", (data: ChatEventResponse) => {
        const initStore = useInitAdminStore();
        const { initDepartmentId } = storeToRefs(initStore);
        if (router.currentRoute.value.fullPath.includes("admin")) {
          this.fetchChatListWithPagination(initDepartmentId.value);
          this.allChats = data.data;
          if (storeCache.get("redirectToChat")) {
            let id = storeCache.get("redirectToChat");
            storeCache.remove("redirectToChat");
            data.data.forEach((chat: Chat) => {
              if (chat.otherSideId === id) {
                router.push(
                  `/admin/chat?id=${chat.id}&monitoryId=${chat.monitoryNoteId}&name=${chat.otherSideName}&departmentId=${chat.departmentId}&otherSideId=${chat.otherSideId}`
                );
              }
            });
          }
        } else {
          this.chats = data.data;
        }
      });
      socket.on("connect_error", (e: string | unknown) => {
        console.log("error connecting...");
        console.log(e);
      });

      socket.connect();
    },
    disconnect() {
      socket?.close();
    },
    isSocketConnected() {
      if (!socket) {
        return false;
      }
      return !socket.disconnected;
    },

    async getSocket(token: string | undefined, departmentId: string, reconnect = false) {
      if (socket === null || reconnect === true) {
        return this.connect(token as string, departmentId as string);
      }
      return socket;
    },
    emit(event: string, data: Record<string, any>) {
      data.token = storeCache.get("token");
      const json = JSON.stringify(data);
      socket?.emit(event, json);
    },

    async createChat(obj: any) {
      const { otherSideDepartmentId, textMessage, departmentId, monitoryNoteId, auctionId } = obj;
      const type = "TEXT";
      try {
        let data = {
          otherSideDepartmentId,
          departmentId,
          textMessage,
          type,
          monitoryNoteId,
          auctionId,
        };
        await socket?.emit("send_message", data);
        socket.on("errorMessage", (error: string | unknown) => console.log("CREATE CHAT ERROR:", error));
        socket.on("allChats", (chats: ChatEventResponse) => {
          if (storeCache.get("redirectToChat")) {
            let id = storeCache.get("redirectToChat");
            storeCache.remove("redirectToChat");
            chats.data.forEach((chat: Chat) => {
              if (chat.monitoryNoteId === id) {
                router.push(
                  `/chat?id=${chat.id}&monitoryId=${chat.monitoryNoteId}&name=${chat.otherSideName}&departmentId=${chat.departmentId}&otherSideId=${chat.otherSideId}`
                );
              }
            });
          }
        });
        // if(obj.creating){
        //   this.findChatId(chatReceiverId)
        // }
      } catch (e) {
        console.log(e);
      }
    },
    postMessage(obj: CreateMessageObject) {
      try {
        socket?.emit("send_message", obj);
        socket.on("errorMessage", (error: string) => console.log("CREATE MESSAGE ERROR:", error));
      } catch (e) {
        console.log(e);
      }
    },

    async fetchChatList(id: string) {
      try {
        const response = await axios.get(ApiHelper.getChatList(id) + "?limit=999", {
          headers: authHelper.authHeader(),
        });
        if (response.data) {
          this.chats = response.data.data;
          this.allChats = response.data.data;
        }
      } catch (e) {
        throw e;
      }
    },
    async fethAllChatsForCompany(id: string) {
      try {
        const response = await axios.get(ApiHelper.getAllCompanyChats(id) + "?limit=999", {
          headers: authHelper.authHeader(),
        });
        if (response.data) {
          this.chats = response.data.data;
          this.allChats = response.data.data;
        }
      } catch (e) {
        throw e;
      }
    },
    async fetchChatListWithPagination(obj: any) {
      let url = `?limit=${this.onPage}&page=${this.page}`;
      if (obj.searchValue) url += `&query=${obj.searchValue}`;
      if (obj.chosenOperation === "UNREAD") url += `&unreadOnly=true`;
      if (obj.chosenOperation === "READ") url += `&unreadOnly=false`;
      try {
        const response = await axios.get(ApiHelper.getChatList(obj.id) + url, {
          headers: authHelper.authHeader(),
        });
        console.log(response);

        if (response && response.data && response.data.meta && (response.data.meta.total || response.data.meta.total === 0)) {
          this.chats = response.data.data;
          this.total = response.data.meta.total;
          this.onPage = response.data.meta.per_page;
          this.page = response.data.meta.current_page;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    setCurrentChat(id: string) {
      this.currentChat = id;
    },

    async fetchMessages() {
      try {
        const response = await axios.get(ApiHelper.getMessages(this.currentChat), {
          headers: authHelper.authHeader(),
        });
        if (response && response.data) {
          this.messages = this.handleMessages(response.data.data);
        }
      } catch (e) {
        throw e;
      }
    },
    async fetchChatDetails(departmentId: string, chatId: string) {
      try {
        const response = await axios.get(ApiHelper.getChatDetails(departmentId, chatId), {
          headers: authHelper.authHeader(),
        });
        return response.data;
      } catch (e) {
        throw e;
      }
    },
    async fetchMessagesForAdmin(id: string) {
      try {
        const response = await axios.get(ApiHelper.getChatMessages(id), {
          headers: authHelper.authHeader(),
        });
        if (response && response.data) {
          this.messages = this.handleAdminMessages(response.data.data);
        }
      } catch (e) {
        throw e;
      }
    },
    handleAdminMessages(messages: Array<Message>) {
      const userService = new UserService();
      let reciever = null as null | string;
      let sender = null as null | string;
      return messages
        .map((message: Message) => {
          if (message.authorUserAvatarId && message.authorUserAvatarId !== null) {
            if (sender === null) {
              sender = message.authorUserId;
              message.isMy = true;
              userService
                .getUserProfileImageAndReturn(message.authorUserAvatarId)
                .then((res) => {
                  this.currentChatSenderAvatar = res;
                })
                .catch((e) => {
                  console.log(e);
                });
            } else if (reciever === null && message.authorUserId !== sender) {
              reciever = message.authorUserId;
              message.isMy = false;
              userService
                .getUserProfileImageAndReturn(message.authorUserAvatarId)
                .then((res) => {
                  this.currentChatRecieverAvatar = res;
                })
                .catch((e) => {
                  console.log(e);
                });
            } else if (message.authorUserId === sender) {
              message.isMy = true;
            } else if (message.authorUserId === reciever) {
              message.isMy = false;
            }
          }
          return message;
        })
        .filter((message: Message) => {
          if (message.text !== "INITIATION") return message;
        })
        .reverse();
    },
    handleMessages(messages: Array<Message>) {
      const userService = new UserService();
      let reciever = false;
      let sender = false;
      return messages.filter((message: Message) => {
        if (message.text !== "INITIATION") return message;
      });
    },
    adjustMessageContainer() {
      const selector = ".scroll .overflow-y-auto";
      const element = document.querySelector(selector);
      if (element) element.scrollTo(0, element.scrollHeight);
    },
    async getUnseenMessages() {
      try {
        const response = await axios.get(ApiHelper.getUnseen(), {
          headers: authHelper.authHeader(),
        });
        if (response && response.data && response.data.unseenChatsNumber) {
          this.numberOfUnseen = 0;
          this.numberOfUnseen = response.data.unseenChatsNumber;
        }
      } catch (e) {
        throw e;
      }
    },
    async getAllChatsAdmin(search: string) {
      try {
        let url = `?limit=${this.onPage}&page=${this.page}`;
        if (search !== "") url += `&query=${search}`;
        const response = await axios.get(ApiHelper.getAllChats() + url, {
          headers: authHelper.authHeader(),
        });
        if (response.data) {
          this.total = response.data.meta.total;
          this.onPage = response.data.meta.per_page;
          this.page = response.data.meta.current_page;
          this.allUserChats = response.data.data;
        }
      } catch (e) {
        throw e;
      }
    },
    async getAllChatsMessagesAdmin(body: any) {
      try {
        let url = `?limit=${this.onPage}&page=${this.page}`;
        if (body.searchValue && body.searchValue !== "") {
          url += `&query=${body.searchValue}`;
        }

        if (body.startDate && body.startDate !== "" && body.endDate && body.endDate !== "") {
          url += `&dateFrom=${body.startDate.toISOString()}&dateTo=${body.endDate.toISOString()}`;
        }

        const response = await axios.get(ApiHelper.getAllChatsMessages() + url, {
          headers: authHelper.authHeader(),
        });
        this.total = response.data.meta.total;
        this.onPage = response.data.meta.per_page;
        this.page = response.data.meta.current_page;
        this.allChatMessages = response.data.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async showAttachment(attachmentId: string) {
      try {
        const response = await axios.get(ApiHelper.showAttachment(attachmentId), {
          headers: authHelper.authHeader(),
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async addAttachment(obj: any) {
      try {
        const response = await axios.post(ApiHelper.postAttachment(), obj, {
          headers: authHelper.authHeader(),
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  },
});
