<template>
  <div class="wrapper" v-if="currentWarehouses && currentWarehouses.length > 0">
    <!-- <h1 class="title-with-underline">
      {{ $t("profile.add_user.title") }}
    </h1> -->
    <div class="inside">
      <div class="assign-department-wrapper">
        <h2 :class="isOwner ? 'make-invisible' : ''">
          {{ $t("profile.add_user.assign_department") }}
        </h2>
        <div :class="isOwner ? 'make-invisible' : ''" class="assign-department">
          <div v-for="d in currentWarehouses" :key="d.id" class="department">
            <input :disabled="isOwner" @change="toggleDepartment(d)" type="checkbox" :checked="isChecked(d)" />
            <p style="cursor: pointer" @click="toggleDepartment(d)" class="name-element">{{ d.name }}</p>
          </div>
        </div>
        <div v-show="showDepartmentError && chosenDepartments.length === 0 && !isOwner" class="error-departments">
          <div class="control__error-inner">{{ $t("missings.missing_department") }}</div>
        </div>

        <div class="toggles-box">
          <div class="flex-col control">
            <h3 style="margin-top: 0">
              {{ $t("profile.add_user.choose_type") }}
            </h3>

            <div class="flex checkbox-wrapper">
              <div class="form-check form-switch">
                <input
                  class="custom-radio-input"
                  type="radio"
                  id="flexSwitchCheckChecked1"
                  name="userRole"
                  @change="() => ((isAdmin = false), (isWorker = false), (isOwner = true))"
                />
                <label class="form-check-label" for="flexSwitchCheckChecked1"> {{ $t("profile.add_user.owner") }}</label>
              </div>
              <p class="role-description">{{ $t(`role_descriptions.${typeOfCompany().toLowerCase()}.owner`) }}</p>

              <div class="form-check form-switch">
                <input
                  class="custom-radio-input"
                  type="radio"
                  id="flexSwitchCheckChecked2"
                  name="userRole"
                  @change="() => ((isOwner = false), (isWorker = false), (isAdmin = true))"
                />
                <label class="form-check-label" for="flexSwitchCheckChecked2"> {{ $t("profile.add_user.admin") }}</label>
              </div>
              <p class="role-description">{{ $t(`role_descriptions.${typeOfCompany().toLowerCase()}.admin`) }}</p>

              <div class="form-check form-switch">
                <input
                  class="custom-radio-input"
                  type="radio"
                  id="flexSwitchCheckChecked3"
                  name="userRole"
                  @change="() => ((isAdmin = false), (isOwner = false), (isWorker = true))"
                />
                <label class="form-check-label" for="flexSwitchCheckChecked3"> {{ $t("profile.add_user.worker") }}</label>
              </div>
              <p class="role-description">{{ $t(`role_descriptions.${typeOfCompany().toLowerCase()}.worker`) }}</p>
            </div>
            <div v-show="showUsersError && !isAdmin && !isOwner && !isWorker" class="control__error">
              <div class="control__error-inner">{{ $t("missings.missing_role") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="large_box">
        <h4>{{ $t("profile.add_user.enter_worker_data") }}</h4>
        <form>
          <div class="">
            <div class="form-positioning">
              <div class="control_large control control--input">
                <label for="address" class="control__label">{{ $t("admin.profile.email") }}</label>
                <input type="text" id="address" v-model="email" />
                <div v-show="errors.email" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.email }}
                  </div>
                </div>
              </div>
              <div class="control_large control control--input">
                <label for="address" class="control__label">{{ $t("profile.add_user.phone") }}</label>
                <input @keyup="phoneNumber = phoneNumber.replace(/\D/g, '')" id="phone-number-input" type="text" v-model="phoneNumber" />
                <div v-show="errors.phoneNumber" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.phoneNumber }}
                  </div>
                </div>
              </div>
              <div class="control_large control control--input">
                <label for="address" class="control__label">{{ $t("profile.add_user.name") }}</label>
                <input type="text" id="address" v-model="name" />
                <div v-show="errors.name" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.name }}
                  </div>
                </div>
              </div>
              <div class="control_large control control--input">
                <label for="address" class="control__label">{{ $t("profile.add_user.surname") }}</label>
                <input type="text" id="address" v-model="surname" />
                <div v-show="errors.surname" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.surname }}
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleFormControlTextarea1">{{ $t("profile.add_user.message") }}</label>
                <textarea v-model="message" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                <div v-show="errors.message" class="control__error textarea-control-error" >
                  <div class="control__error-inner">
                    {{ errors.message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="buttons">
      <button class="button-outline" @click.prevent="addNewUser">
        <template v-if="!userStore.isLoading">
          {{ $t("profile.add_user.add") }}
        </template>
        <template v-else>
          {{ $t("profile.add_user.adding") }}
        </template>
      </button>
    </div>
  </div>
  <div class="wrapper" v-else>
    <NoDepartmentScreen v-if="!loading" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import UserLayout from "@/layouts/UserLayout";
import Menu from "@/components/Menu";
import ChangePassword from "@/components/settings/ChangePassword";
import Privacy from "@/components/settings/Privacy";
import { useUserStore } from "@/stores/user.store";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { useI18n } from "vue-i18n";
import { useHelpersStore } from "@/stores/helpers.store";
import { inject } from "vue";
import storeCache from "@/js/storeCache";
import AlertHelper from "@/helpsers/alert.helper";
import router from "@/router";
import { Department, useDepartmentStore } from "@/stores/department.store";
import { typeOfCompany } from "@/js/utils";
import { storeToRefs } from "pinia";
import NoDepartmentScreen from "@/components/common/NoDepartmentScreen.vue";

export default {
  name: "AddUserView",
  components: { Privacy, ChangePassword, Menu, UserLayout, NoDepartmentScreen },
  setup() {
    const userStore = useUserStore();
    const { justAddedWorker } = storeToRefs(userStore);
    const departmentStore = useDepartmentStore();
    const helpersStore = useHelpersStore();
    let { currentWarehouses } = storeToRefs(departmentStore);
    let width = ref(window.innerWidth);
    let loading = ref(false);
    let showDepartmentError = ref(false);
    let showUsersError = ref(false);
    let alert = inject("alert");
    let chosenDepartments = ref([]);
    onMounted(async () => {
      let id = storeCache.get("currentCompany")?.id;
      if (id) {
        await departmentStore.fetchAllWarehouses({
          id: id,
        });
      }
      var inputBox = document.getElementById("phone-number-input");
      inputBox.onchange = function () {
        inputBox.value = inputBox.value.replace(/[^0-9]/g, "");
      };
    });
    const { t } = useI18n();
    const { handleSubmit, errors, handleReset } = useForm({
      validationSchema: yup.object().shape({
        // name: yup.string().trim().required(t("register.validation.name")),
        email: yup.string().trim().required(t("register.validation.email")).email(t("register.validation.email")).max(50, t("register.validation.max", { number: 50 })),
        message: yup.string().max(500, t("register.validation.max", { number: 500 })),
        name: yup
          .string()
          .trim()
          .required(t("register.validation.required"))
          .matches(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/, t("register.validation.string"))
          .max(30, t("register.validation.max", { number: 30 })),
        surname: yup
          .string()
          .trim()
          .required(t("register.validation.required"))
          .matches(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/, t("register.validation.string"))
          .max(50, t("register.validation.max", { number: 50 })),
        phoneNumber: yup
          .string()
          .required(t("register.validation.required"))
          .matches(
            /^(?=(.{1,20})$)((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/,
            t("register.validation.phone")
          ),
        isAdmin: yup.boolean(),
        isOwner: yup.boolean(),
        isWorker: yup.boolean(),
      }),
      initialValues: {
        email: "",
        message: "",
        name: "",
        surname: "",
        phoneNumber: "",
        isAdmin: false,
        isOwner: false,
      },
    });
    const { value: name } = useField("name");
    const { value: surname } = useField("surname");
    const { value: phoneNumber } = useField("phoneNumber");
    const { value: email } = useField("email");
    const { value: isAdmin } = useField("isAdmin");
    const { value: isOwner } = useField("isOwner");
    const { value: isWorker } = useField("isWorker");
    const { value: message } = useField("message");

    const addNewUser = handleSubmit(async () => {
      {
        if (chosenDepartments.value.length > 0 || isOwner.value) {
          if (isOwner.value || isWorker.value || isAdmin.value) {
            userStore.isLoading = true;
            const data = {
              email: email.value,
              name: name.value,
              surname: surname.value,
              phoneNumber: phoneNumber.value,
              role: isAdmin.value ? "ADMINISTRATOR" : isOwner.value ? "OWNER" : isWorker.value ? "WORKER" : "",
              // message: message.value,
              companyId: storeCache.get("currentCompany")?.id,
            };
            if (message.value !== "") data.message = message.value;
            try {
              await userStore.addUser(data);
              helpersStore.adjustTasks();
              userStore.isLoading = false;
              if (!isOwner.value) {
                chosenDepartments.value.forEach(async (department) => {
                  const obj = {
                    alert,
                    email: email.value,
                    departmentId: department.id,
                    role: "ADMINISTRATOR",
                  };
                  await departmentStore.addWorkerToDepartment(obj, true);
                });
              }
               AlertHelper.show(alert, t("profile.company.addWorker"));
              handleReset();
              router.push({ name: "list-users" });
            } catch (e) {
              userStore.isLoading = false;
              console.log(e);
              AlertHelper.show(alert, e, "", "error");
            }
          } else {
            showUsersError.value = true;
          }
        } else {
          showDepartmentError.value = true;
          AlertHelper.show(alert, "Przypisz pracownika do oddziału", "Aby dodać pracownika do firmy, musisz przypisać go do oddziału.", "error");
        }
      }
    });
    const toggleDepartment = (department) => {
      if (chosenDepartments.value.includes(department)) {
        chosenDepartments.value.splice(chosenDepartments.value.indexOf(department), 1);
      } else {
        chosenDepartments.value.push(department);
      }
    };
    const isChecked = (department) => {
      return chosenDepartments.value.includes(department);
    };
    function validate(evt) {
      var theEvent = evt || window.event;

      // Handle paste
      if (theEvent.type === "paste") {
        key = event.clipboardData.getData("text/plain");
      } else {
        // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
      }
      var regex = /[0-9]|\./;
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
    return {
      loading,
      validate,
      email,
      isAdmin,
      errors,
      addNewUser,
      message,
      userStore,
      isOwner,
      isWorker,
      name,
      surname,
      phoneNumber,
      currentWarehouses,
      width,
      chosenDepartments,
      showDepartmentError,
      toggleDepartment,
      typeOfCompany,
      showUsersError,
      isChecked,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/css/other-screens/add-user-view.scss";

.textarea-control-error{
  left:unset;
  bottom: unset;
}
.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  max-width: unset !important;
  overflow-y: auto;
  padding-bottom: 50px;
  @media screen and (max-width: 2200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 850px) {
    padding: 0 20px;
  }
  overflow-x: hidden;
  .inside {
    display: flex;
    margin-top: 30px;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }
  }
  h2,
  h3,
  h4 {
    font-weight: 600;
    color: #112baf;
    font-size: 14px;
  }
}

.checkbox-wrapper {
  gap: 10px;
  flex-direction: column;
  align-items: start;
  margin-top: 20px;
  @media screen and (max-width: 575px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
.form-group label {
  font-size: 12px;
  font-weight: 400;
}

#exampleFormControlTextarea1 {
  max-width: 500px;
  font-size: 14px;
}
#exampleFormControlTextarea1:focus {
  outline: none !important;
}
.assign-department-wrapper {
  position: relative;
  top: 0px;
  width: 50%;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
  h2,
  h3,
  h4 {
    margin-top: 20px;
  }

  .assign-department {
    max-height: 250px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 1200px) {
      max-height: 150px;
    }

    .department {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin: 15px 0;
      @media screen and (max-width: 750px) {
        width: 300px;
        max-width: 300px;
        font-size: 10px;
      }
      .name-element {
        font-weight: 600;
      }
      p {
        margin: 0 0 0 10px;
      }
    }
  }
}
.toggles-box {
  margin-top: 30px;
  max-width: 500px;
  .flex-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.make-invisible {
  opacity: 0.1;
}

input[type="checkbox"],
input[type="radio"] {
  accent-color: #112baf;
}

.primary-label {
  font-weight: 600;
  color: #112baf;
  font-size: 14px;
}

.large_box {
  min-width: unset !important;
  max-width: unset !important;
  min-height: unset !important;
  max-width: unset !important;
  width: 50%;

  h2,
  h3,
  h4 {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 600px) {
    padding: 20px 0;
    width: 90%;
  }
}

.form-switch {
  padding: 0;
}

.form-switch .form-check-input {
  width: 16px;
  background-image: unset;
}

form .form-positioning label {
  font-weight: 600;
  font-size: 12px;
  color: rgba(5, 24, 52, 0.6);
}
.buttons {
  .button-outline {
    margin: 100px auto 0 auto;
    background-color: #112baf;
    color: white;

    @media screen and (max-width: 600px) {
      margin: 20px auto;
    }
  }
}

.control--input input {
  max-width: unset;
}

.return-btn-in-container {
  left: 0;
  border: 1px solid red;
}
.form-check-label {
  font-size: 14px;
  font-weight: 600;
}
.role-description {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-left: 30px;
  color: rgba(31, 34, 47, 0.6);
}
.error-departments {
  min-width: 200px;
  padding: 5px 15px;
  background-color: #f28787;
  color: #fff;
  font-size: 1.2rem;
  line-height: 15px;
  max-width: 327px;
  border-radius: 15px;
  width: 100%;
  transform: translateY(100%);
  margin-top: -20px;
  margin-bottom: 20px;
  z-index: 2;
  &-inner {
    position: relative;
  }
  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 10px 15px;
    border-color: transparent transparent #f28787 transparent;
    transform: translateX(-50%);
  }
}

textarea {
  background: rgba(17, 43, 175, 0.04) !important;
  border: none !important;
  border-radius: 10px;
  width: 100%;
  max-width: unset !important;
}
</style>
