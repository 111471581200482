const auction_adding = {
  doc_nr: "Numer zlecenia",
  settings: "Ustawienia licytacji",
  default: " Domyślny termin płatności",
  threshold: "Minimalny próg przebijania kwoty (opcjonalne)",
  default_threshold: "Domyślna wartość progu przebijania to 1",
  threshold_placeholder: "Wpisz próg przebijania",
  add: "Dodaj aukcję",
  pick_start_point: "Wybierz punkt początkowy",
  pick_end_point: "Wybierz punkt końcowy",
  add_middle_point: "Dodaj punkt pośredni",
  choose_middle_point: "Wybierz punkt pośredni",
  load_error_1: "Wymagany jest co najmniej 1 ładunek",
  load_error_2: "Uzupełnij rodzaj frachtu.",
  load_error_3: "Dane ładunku są niepoprawne.",
  warehouse_address_error: "Podany adres jest zbyt mało dokładny",
  dates_chronological: "Uwaga! Daty w trasie nie są ustawione w chronologiczny sposób. Aby dodać aukcję, ustaw daty tak, aby następowały po sobie.",
  cargos_empty: "Uwaga! Uzupełnione części ładunku nie mogą być puste ani nie mogą być zerem.",
  ramps_empty: "Uwaga! Musisz uzupełnić wszystkie wymagane rampy.",
  start_missing: "Wszystkie dane w punkcie początkowym muszą być wypełnione.",
  middle_missing: "Wszystkie dane w punktach pośrednich muszą być wypełnione.",
  end_missing: "Wszystkie dane w punkcie końcowym muszą być wypełnione.",
  operation_missing: "Wszystkie punkty muszą mieć dodaną operację.",
};
export default auction_adding;
