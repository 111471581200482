<template>
        <template v-if="!verificationSuccess">
            <h2 class="auth__heading">{{ $t('verification.title') }}</h2>
            <p v-if="!backendError" class="auth__text auth__text--centered">
                {{ $t('verification.in_progress') }}
            </p>
            <p v-else class="auth__text auth__text--centered">
                {{ backendError }}
            </p>
        </template>
        <template v-else>
            <h2 class="auth__heading">{{ $t('verification.title') }}</h2>
            <p class="auth__text auth__text--centered">
                {{ $t('verification.success') }}
            </p>
            <p class="auth__url auth__url--brown">
                <router-link to="/login">{{ $t('reset_password.click_to_go_to_login') }}</router-link>
            </p>
        </template>
</template>

<script>
import {ref} from "vue";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import axios from "axios";
import ApiHelper from "@/helpsers/api.helper";

export default {
    name: "VerificationView",
    setup() {
        const verificationSuccess = ref(false);
        const backendError = ref('');
        const route = useRoute();
        const {t} = useI18n();
        let verificationCode = route.query?.code;

        function verifyAccount() {
            axios.post(ApiHelper.verifyAccountUrl(), {
                verificationCode: verificationCode,
            }).then(response => {
                verificationSuccess.value = true;
            }).catch(error => {
                backendError.value = ApiHelper.getResponseMessage(error.response.data.code);
            });
        }

        if (!verificationCode) {
            router.push('/');
        } else {
            verifyAccount();
        }

        return {
            verificationCode,
            backendError,
            verificationSuccess
        }
    }
}
</script>

<style scoped>

</style>