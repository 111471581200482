<template>
  <div class="expand-wrap" :class="noPadding ? 'expand-wrap-no-pad' : ''">
    <div class="stockExpandTitle" @click="setExpanded">
      <span :style="noPadding? 'padding-left:10px;' : ''">{{ title }}</span>

      <img v-if="!expanded" src="@/assets/icons/expandDown.svg" alt="" />

      <img v-if="expanded" src="@/assets/icons/expandUp.svg" alt="" />
    </div>
    <div :class="{ stockExpanded: expanded === true }" class="stockExpand">
      <slot />
    </div>
  </div>
</template>

<script>
import { boolean } from "yup/lib/locale";
export default {
  name: "Expand",
  components: {},
  data() {
    return {
      expanded: this.preExpanded ? true : false,
    };
  },
  props: {
    title: String,
    preExpanded: boolean,
    noPadding: boolean,
  },
  methods: {
    setExpanded() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/stock/stockComponents.scss";

.expand-wrap {
  background: #fff;
  padding: 2px 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
}
.expand-wrap-no-pad {
  padding: 0;
  padding-top: 2px;
}
</style>
