<template>
  <div class="wrapper">
    <div class="title-with-underline">
      {{ $t("profile.calendar.time_windows") }} -
      {{ router.currentRoute.value.params.id }}
    </div>
    <h1
      v-if="rampsDailyCalendar && rampsDailyCalendar?.availabilities && rampsDailyCalendar?.availabilities?.length > 0"
      style="font-weight: 500; font-size: 14px; text-align: center; letter-spacing: 0.03em; color: #a2a2a2; margin-top: 20px"
    >
      {{ $t("profile.calendar.enter_limit") }}
    </h1>
    <h1 v-else style="font-weight: 500; font-size: 14px; text-align: center; letter-spacing: 0.03em; color: #a2a2a2; margin-top: 150px">
      Brak okien czasowych
    </h1>
    <div
      v-if="
        rampsDailyCalendar &&
        rampsDailyCalendar?.availabilities &&
        rampsDailyCalendar?.availabilities[0] &&
        !rampsDailyCalendar?.availabilities[0]?.unlimitedMonitoryNotes
      "
      class="edit-limits-section"
    >
      <div class="loading control control--input">
        <label for="">{{ $t("profile.calendar.loadLimit") }}</label>
        <input :placeholder="'Wpisz limit'" oninput="validity.valid||(value='');" v-model="loadingLimit" type="number" />
      </div>
      <div class="unloading control control--input">
        <label for="">{{ $t("profile.calendar.unloadLimit") }}</label>
        <input :placeholder="'Wpisz limit'" oninput="validity.valid||(value='');" v-model="unloadingLimit" type="number" />
      </div>
    </div>
    <div
      class="missing-elements-text"
      v-else-if="rampsDailyCalendar && rampsDailyCalendar?.availabilities && rampsDailyCalendar.availabilities.length > 0"
    >
      {{ $t("profile.calendar.unlimited") }}
    </div>
    <div class="dialog" id="delete-confirmation-dialog" aria-hidden="true">
      <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
      <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
        <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
          <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
        </button>
        <confirmation-modal-body
          @hide="deleteConfirmationDialog.hide()"
          @confirmed="deleteAllMonitary"
          :label="$t('profile.calendar.deleteAllQuestion')"
        />
      </div>
    </div>
    <primary-button
      v-if="rampsDailyCalendar?.availabilities?.length > 0"
      @click="deleteConfirmationDialog.show()"
      :title="$t('profile.calendar.deleteAll')"
      style="margin: 20px auto"
      :red="true"
      :filled="true"
    />

    <div class="headers-and-content-wrapper-edit">
      <div v-if="rampsDailyCalendar?.availabilities?.length > 0" class="table-headers">
        <!-- <div class="head">
          <p>{{ $t("profile.calendar.slots") }}</p>
        </div> -->
        <div class="head"></div>
        <div class="head">
          <p style="color: #a2a2a2">{{ $t("profile.calendar.from") }}</p>
        </div>
        <div class="head">
          <p style="color: #a2a2a2">{{ $t("profile.calendar.to") }}</p>
        </div>
        <div class="head remove-after"></div>
      </div>
      <div class="contents-wrapper">
        <div
          v-for="(window, index) in rampsDailyCalendar?.availabilities"
          :key="window"
          class="table-contents"
          :class="window.monitoryNotes && window.monitoryNotes.length > 0 ? 'with-notes' : checkDay(window) ? 'error-table-contests' : ''"
        >
          <div class="data" style="position: relative">
            <div style="font-weight: 700; font-size: 12px; line-height: 17px; color: #1f222f" class="content">Okno czasowe {{ index + 1 }}</div>
            <div class="content">
              <vue-timepicker
                :drop-direction="rampsDailyCalendar?.availabilities && index >= rampsDailyCalendar?.availabilities?.length / 2 ? 'up' : 'down'"
                :disabled="window.monitoryNotes && window.monitoryNotes.length > 0 ? true : !isEditingEnabled"
                v-model="window.startHour"
                :placeholder="$t('generals.opening')"
                @change="(e) => handleChangeHour(e, index, 'startHour', window)"
              ></vue-timepicker>
            </div>
            <div class="content">
              <vue-timepicker
                :drop-direction="rampsDailyCalendar?.availabilities && index >= rampsDailyCalendar?.availabilities?.length / 2 ? 'up' : 'down'"
                :disabled="window.monitoryNotes && window.monitoryNotes.length > 0 ? true : !isEditingEnabled"
                v-model="window.endHour"
                :placeholder="$t('generals.closing')"
                @change="(e) => handleChangeHour(e, index, 'endHour', window)"
              ></vue-timepicker>
            </div>
            <div class="content" :style="window.monitoryNotes && window.monitoryNotes.length > 0 ? 'visibility:hidden;' : 'visibility:visible;'">
              <div
                style="font-weight: 600; font-size: 12px; color: #112baf; cursor: pointer; display: flex; align-items: center; gap: 0 5px"
                @click="window.monitoryNotes && window.monitoryNotes.length > 0 ? '' : removeWindow(window)"
              >
                <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7.77778 0.888889H5.83333L5.27778 0.4375H2.5L1.94444 0.888889H0V1.79167H7.77778M0.555556 7.65972C0.555556 7.89915 0.672619 8.12878 0.880992 8.29808C1.08937 8.46739 1.37198 8.5625 1.66667 8.5625H6.11111C6.4058 8.5625 6.68841 8.46739 6.89679 8.29808C7.10516 8.12878 7.22222 7.89915 7.22222 7.65972V2.24306H0.555556V7.65972Z"
                    fill="#112BAF"
                  />
                </svg>
                Usuń okno
              </div>
            </div>
            <div class="content" v-if="width <= 1000 && window.monitoryNotes && window.monitoryNotes.length > 0">
              <div @click="currentMonitaryNotesToShow = window.monitoryNotes" class="show-monitary-notes-btn">
                {{ $t("profile.calendar.show_monitary") }}
              </div>
            </div>
            <div
              v-show="(window.startHour === window.endHour || window.startHour > window.endHour) && window.startHour && window.endHour"
              class="control__error"
              style="margin:auto; right:0;"
            >
              <div class="control__error-inner"> {{ $t("monitary_notes.wrong_hours") }}</div>
            </div>
          </div>
          <div class="" v-if="window.monitoryNotes !== undefined && width > 1000 && window.monitoryNotes.length > 0">
            <p class="monitory-notes-title">
              {{ `${$t("monitary_notes.monitory_notes_list")}:` }}
            </p>
            <div v-for="note in window.monitoryNotes" :key="note.id" class="monitory-notes">
              <div
                class="note-card"
                :class="note.status === 'ACCEPTED' ? 'accepted-note' : note.status === 'REJECTED' ? 'rejected-note' : 'pending-note'"
              >
                <div class="info">
                  <p class="title">{{ $t("profile.calendar.monitary_id") }}</p>
                  <p>{{ note.monitoryNoteNumberInWarehouse }}</p>
                </div>
                <div class="info">
                  <p class="title">
                    {{ $t("profile.calendar.monitary_time") }}
                  </p>
                  <p>{{ note.startHour }} - {{ note.endHour }}</p>
                </div>
                <div class="info">
                  <p class="title">{{ $t("profile.calendar.driver") }}</p>
                  <p>
                    {{ note.driverFullName + " " + note.driverPhone }}
                  </p>
                </div>
                <div @click="downloadMonitary(note)" class="info">
                  <primary-button :small="true" :red="true" @click="closeEditView" :title="$t('generals.see')" />
                </div>
                <div v-if="note.status === 'WAITING_FOR_ACCEPTANCE'" class="buttons-in-notes">
                  <button @click="confirmationDialog.show(), (chosenNote = note)" type="button" class="btn btn-success">
                    {{ $t("generals.accept") }}
                  </button>
                  <button @click="rejectMonitary(note)" type="button" class="btn btn-danger">
                    {{ $t("generals.reject") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog" id="confirmation-dialog" aria-hidden="true">
          <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
          <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
            <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
              <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
            </button>
            <h1 id="my-dialog-title" class="dialog__title">Czy na pewno chcesz zaakceptować awizację?</h1>
            <!-- <h1 id="my-dialog-title" class="dialog__title">
              {{
                `${$t("profile.calendar.give_additional_monitory_note_id")}:`
              }}
            </h1> -->
            <!-- <input v-model="additionalID" class="form-control" type="text" /> -->
            <div class="dialog__buttons">
              <button class="dialog__button dialog__button--alt" @click="acceptMonitary(note)">
                {{ $t("generals.go_further") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" v-if="currentMonitaryNotesToShow && currentMonitaryNotesToShow.length > 0 && width < 1000">
      <div v-for="note in currentMonitaryNotesToShow" :key="note.id" class="monitory-notes">
        <div class="note-card" :class="note.status === 'ACCEPTED' ? 'accepted-note' : 'pending-note'" style="position: relative">
          <div class="info">
            <p class="title">{{ $t("profile.calendar.monitary_id") }}</p>
            <p>{{ note.monitoryNoteNumberInWarehouse }}</p>
          </div>

          <div class="info">
            <p class="title">{{ $t("profile.calendar.driver") }}</p>
            <p>
              {{ note.driverFullName + " " + note.driverPhone }}
            </p>
          </div>
          <div class="info">
            <svg
              @click="downloadMonitary(note)"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="30.339px"
              height="30.339px"
              viewBox="0 0 414.339 414.339"
              stroke="#112BAF"
              fill="#112BAF"
              stroke-width="20"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <g>
                <path
                  d="M408.265,162.615L230.506,58.601c-3.795-2.222-8.485-2.241-12.304-0.057c-3.815,2.187-6.17,6.246-6.174,10.644
		l-0.036,46.966c-0.213,0.052-0.38,0.094-0.495,0.126c-4.442,1.239-9.163,2.603-14.854,4.294
		c-18.457,5.483-37.417,12.593-59.668,22.374c-19.309,8.487-38.201,19.442-56.15,32.559c-12.826,9.373-24.894,20.182-35.867,32.126
		C15.1,240.129-1.259,283.28,0.076,326.023c0.234,7.488,1.076,14.674,1.869,20.716c0.788,6.007,5.84,10.541,11.898,10.677
		c0.093,0.002,0.186,0.003,0.277,0.003c5.94,0,11.048-4.263,12.086-10.139c3.304-18.678,8.574-34.022,16.111-46.91
		c9.42-16.104,22.223-29.625,37.021-39.102c12.718-8.146,26.153-14.396,41.075-19.113c17.405-5.503,36.597-8.952,58.671-10.545
		c8.907-0.644,18.502-0.967,30.2-1.021c0.354,0,1.112,0.007,2.098,0.02l0.032,44.29c0.003,4.372,2.332,8.413,6.113,10.607
		c3.782,2.196,8.446,2.214,12.245,0.049l178.37-101.678c3.811-2.172,6.172-6.21,6.196-10.597
		C414.366,168.896,412.05,164.831,408.265,162.615z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </div>
          <div v-if="note.status === 'WAITING_FOR_ACCEPTANCE'" class="buttons-in-notes">
            <button @click="confirmationDialog.show(), (chosenNote = note)" type="button" class="btn btn-success">
              {{ $t("generals.accept") }}
            </button>
            <button @click="rejectMonitary(note)" type="button" class="btn btn-danger">
              {{ $t("generals.reject") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="add-new-window-section">
      <p @click="saveDay" style="color: #a2a2a2">{{ $t("profile.calendar.you_can_add_more") }}</p>
      <primary-button @click="addNewTimeWindow" :title="$t('profile.calendar.add')" :red="true" />
    </div>
    <div class="add-new-window-section">
      <primary-button @click="saveDay" :title="$t('generals.save')" :red="true" :filled="true" />
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, inject, onBeforeUnmount } from "vue";
import router from "@/router";
import { dayFromNumber } from "@/js/utils";
import VueTimepicker from "vue3-timepicker";
import "vue3-timepicker/dist/VueTimepicker.css";
import { useCalendarStore } from "@/stores/calendar.store";
import { useMonitaryStore } from "@/stores/monitary.store";
import { storeToRefs } from "pinia";
import AlertHelper from "@/helpsers/alert.helper";
import i18n from "@/i18n";
import PrimaryButton from "@/components/common/PrimaryButton.vue";
import breakpointsHelper from "@/helpsers/breakpoints.helper";
import ConfirmationModalBody from "@/components/common/ConfirmationModalBody.vue";
import { parseChangedHour } from "@/js/utils";
import { useRampsStore } from "@/stores/ramps.store";

export default defineComponent({
  components: { VueTimepicker, PrimaryButton, ConfirmationModalBody },
  setup() {
    let id = ref("");
    let day = ref({});
    let alert = inject("alert");
    const confirmationDialog = ref("");
    const deleteConfirmationDialog = ref("");
    let additionalID = ref("");
    let currentMonitaryNotesToShow = ref([]);
    const { width } = breakpointsHelper();
    let chosenNote = ref({});
    let loadingLimit = ref("0");
    let unloadingLimit = ref("0");
    const calendarStore = useCalendarStore();
    const monitaryStore = useMonitaryStore();
    const rampsStore = useRampsStore();
    const { timeWindows } = storeToRefs(calendarStore);
    const { rampsDailyCalendar } = storeToRefs(rampsStore);

    let submitted = ref(false);
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    const isEditingEnabled = date.getTime() <= new Date(router.currentRoute.value.params.id).getTime();

    onMounted(async () => {
      confirmationDialog.value = new A11yDialog(document.querySelector("#confirmation-dialog"));
      deleteConfirmationDialog.value = new A11yDialog(document.querySelector("#delete-confirmation-dialog"));
      await rampsStore.getDailyCalendarForRamp(router.currentRoute.value.query.rampId, router.currentRoute.value.params.id);
      if (router.currentRoute.value.query.loadingLimit) {
        loadingLimit.value = router.currentRoute.value.query.loadingLimit ?? rampsDailyCalendar.loadingLimit;
      }
      if (router.currentRoute.value.query.unloadingLimit) {
        unloadingLimit.value = router.currentRoute.value.query.unloadingLimit ?? rampsDailyCalendar.unloadingLimit;
      }
    });
    const acceptMonitary = async () => {
      if (isEditingEnabled) {
        let note = chosenNote.value;
        if (additionalID.value !== "") {
          let obj = {
            id: note.id,
            warehouseMonitoryNoteId: additionalID.value,
          };
          await monitaryStore.editMonitary(obj);
        }
        const object = {
          id: note.id,
          alert,
          status: "ACCEPTED",
        };
        await monitaryStore.changeMonitaryStatus(object);
        await calendarStore.getTimeWindows(router.currentRoute.value.params.id, router.currentRoute.value.query.department);
      } else {
        AlertHelper.show(alert, i18n.global.t("profile.calendar.date_error"), "", "error");
      }
    };
    const rejectMonitary = async (note) => {
      if (isEditingEnabled) {
        const obj = {
          id: note.id,
          alert,
          status: "REJECTED",
        };
        await monitaryStore.changeMonitaryStatus(obj);
        await calendarStore.getTimeWindows(router.currentRoute.value.params.id, router.currentRoute.value.query.department);
      } else {
        AlertHelper.show(alert, i18n.global.t("profile.calendar.date_error"), "", "error");
      }
    };
    const addNewTimeWindow = () => {
      if (isEditingEnabled) {
        rampsDailyCalendar.value.availabilities.push({ id: Math.floor(Math.random() * (99999 - 1) + 1) });
      } else {
        AlertHelper.show(alert, i18n.global.t("profile.calendar.date_error"), "", "error");
      }
    };
    const checkDay = (window) => {
      if (submitted.value) {
        if (window.startHour === "" || window.endHour === "" || !window.startHour || !window.endHour || window.endHour < window.startHour)
          return true;
      }
      return false;
    };
    const removeWindow = (window) => {
      if (isEditingEnabled) {
        if (window.monitoryNotes) {
          rampsDailyCalendar.value.availabilities.splice(rampsDailyCalendar.value.availabilities.indexOf(window), 1);
        } else {
          rampsDailyCalendar.value.availabilities.splice(rampsDailyCalendar.value.availabilities.indexOf(window), 1);
        }
      } else {
        AlertHelper.show(alert, i18n.global.t("profile.calendar.date_error"), "", "error");
      }
    };
    const downloadMonitary = async (note) => {
      router.push(
        `/lista-awizacji?type=ALL&monitoryNoteId=${note.monitoryNoteNumberInWarehouse}&department=${router.currentRoute.value.query.departmentId}`
      );
    };
    const deleteAllMonitary = async () => {
      const tmp = rampsDailyCalendar.value.availabilities;
      rampsDailyCalendar.value.availabilities = tmp.filter((note) => note.monitoryNotes.length > 0);
      deleteConfirmationDialog.value.hide();
    };
    const handleChangeHour = (date, index, key, window) => {
      // if (key === "startHour") {
      //   if (window.endHour === date.displayTime) {
      //     if (rampsDailyCalendar.value.availabilities[rampsDailyCalendar.value.availabilities.length - 1].startHour === date.displayTime) {
      //       rampsDailyCalendar.value.availabilities.pop();
      //       AlertHelper.show(alert, i18n.global.t("profile.calendar.same_hour_error"), "", "error");
      //     }
      //     return;
      //   }
      // }
      // if (key === "endHour") {
      //   if (window.startHour === date.displayTime) {
      //     if (rampsDailyCalendar.value.availabilities[rampsDailyCalendar.value.availabilities.length - 1].endHour === date.displayTime) {
      //       rampsDailyCalendar.value.availabilities.pop();
      //       AlertHelper.show(alert, i18n.global.t("profile.calendar.same_hour_error"), "", "error");
      //     }
      //     return;
      //   }
      // }
      // change rampsDailyCalendar only .availabilitiesif date object contains HH or mm
      if (date.displayTime?.includes("HH") || date.displayTime?.includes("mm")) {
        const parsed = parseChangedHour(date, index, key, rampsDailyCalendar.value.availabilities);

        rampsDailyCalendar.value.availabilities = parsed;
      }
    };

    const saveDay = async () => {
      if (isEditingEnabled) {
        let flag = true;
        submitted.value = true;
        rampsDailyCalendar.value.availabilities = rampsDailyCalendar.value.availabilities.map((window) => {
          // if (window.slots) window.slots = window.slots.toString();
          // if (window.monitory_notes) delete window.monitory_notes;
          // if (window.monitoryNotes) delete window.monitoryNotes;
          if (window.id) delete window.id;

          if (window.noMonitoryNotePossibility && window.unlimitedMonitoryNotes) {
            window.noMonitoryNotePossibility = window.noMonitoryNotePossibility === 0 ? false : true;
            window.unlimitedMonitoryNotes = window.unlimitedMonitoryNotes === 0 ? false : true;
          } else {
            window.noMonitoryNotePossibility = false;
            window.unlimitedMonitoryNotes = false;
          }

          if (window.startHour && window.startHour.HH && window.startHour.mm) {
            window.startHour = window.startHour.HH + ":" + window.startHour.mm;
          }
          if (window.endHour && window.endHour.HH && window.endHour.mm) {
            window.endHour = window.endHour.HH + ":" + window.endHour.mm;
          }

          if (window.endHour === "" || window.startHour === "" || window.endHour === undefined || window.startHour === undefined) {
            flag = false;
          }
          if (window.startHour > window.endHour || window.startHour === window.endHour) flag = false;
          // if (window.slots === "0" || window.slots === 0) flag = false;
          return window;
        });
        if (flag) {
          const obj = {
            alert,
            rampId: router.currentRoute.value.query.rampId,
            availabilities: rampsDailyCalendar.value.availabilities,
            date: router.currentRoute.value.params.id,
            loadingLimit: parseInt(loadingLimit.value),
            unloadingLimit: parseInt(unloadingLimit.value),
          };
          try {
            await rampsStore.updateRampDailyTimeWindows(obj);
            router.back();
          } catch (e) {
            console.log(e);
          }
        } else {
          AlertHelper.show(alert, i18n.global.t("generals.error"), i18n.global.t("profile.calendar.correctly_fill_all_boxes"), "error");
        }
      } else {
        AlertHelper.show(alert, i18n.global.t("profile.calendar.date_error"), "", "error");
      }
    };

    return {
      day,
      dayFromNumber,
      router,
      saveDay,
      width,
      currentMonitaryNotesToShow,
      timeWindows,
      acceptMonitary,
      rejectMonitary,
      confirmationDialog,
      additionalID,
      chosenNote,
      router,
      removeWindow,
      checkDay,
      downloadMonitary,
      unloadingLimit,
      loadingLimit,
      addNewTimeWindow,
      isEditingEnabled,
      deleteConfirmationDialog,
      deleteAllMonitary,
      handleChangeHour,
      rampsDailyCalendar,
    };
  },
});
</script>
<style lang="scss" scoped>
@use "@/css/calendar/fill-calendar.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px 0;
}

p {
  color: black;
}
.show-monitary-notes-btn {
  text-decoration: underline;
  font-weight: 500;
  color: #112baf;
  cursor: pointer;
}
.headers-and-content-wrapper-edit {
  display: flex;
  flex-direction: column;
  margin: auto;

  @media screen and (max-width: 1000px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: visible;
  }
}
.title-with-underline {
  margin-left: 30px;
}

.monitory-notes-title {
  margin-left: 80px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}
.monitory-notes {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  .note-card {
    background: #f5f7ff;
    margin: 7px 80px;
    border-radius: 10px;
    padding: 2px 0px;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    text-align: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    font-size: 14px;
    position: relative;
    @media screen and (max-width: 400px) {
      margin: 7px 30px;
    }
    @media screen and (max-width: 700px) {
      font-size: 10px;
    }
    cursor: pointer;
    .info {
      margin: 0 10px;
      .title {
        font-weight: 600;
        font-size: inherit;
      }
    }
    .buttons-in-notes {
      position: absolute;
      bottom: -40px;
      display: flex;
      button {
        height: 30px;
        font-size: 12px;
        width: 200px;
        margin: 0 2px;
        @media screen and (max-width: 900px) {
          width: 100px;
        }
      }
    }
  }
  .accepted-note {
    background: #b9e0cd !important;
  }
  .pending-note {
    background: #fff !important;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  }
  .rejected-note {
    background: #deb3b3 !important;
  }
}
.table-contents {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 12px;
  color: rgba(5, 24, 52, 0.6) !important;

  .data {
    display: flex;
  }
}
.error-table-contests {
  border-left: 1px solid #112baf;
  border-right: 1px solid #112baf;
  border-left: none;
  border-right: none;
  background: #ece4e6;
}
.with-notes {
  border: 1px solid rgb(221, 220, 220);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.delete-icon {
  background: #112baf;
  padding: 5px 40px;
  border-radius: 10px;
  cursor: pointer;
}
.disabled-delete-icon {
  background: #d5d1d0;
  padding: 5px 40px;
  border-radius: 10px;
}

.table-headers {
  @media screen and (max-width: 1350px) {
    flex-direction: row !important;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column !important;
  }
  .head {
    @media screen and (max-width: 1350px) {
      width: 150px;
    }
    @media screen and (max-width: 500px) {
      width: 100px;
    }
  }
  .content {
    @media screen and (max-width: 1000px) {
      width: 20px !important;
    }
  }
}
.table-contents {
  .data {
    @media screen and (max-width: 1000px) {
      flex-direction: column !important;
    }
  }
}
.return-to-calendar {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.contents-wrapper {
  @media screen and (max-width: 1350px) {
    flex-direction: column !important;
  }
  @media screen and (max-width: 1350px) {
    max-width: 800px;
  }
  @media screen and (max-width: 1110px) {
    max-width: 800px;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: row !important;
    max-width: 400px;
  }
  @media screen and (max-width: 900px) {
    max-width: 300px;
  }
  @media screen and (max-width: 767px) {
    max-width: 400px;
  }
  @media screen and (max-width: 650px) {
    max-width: 300px;
  }
  @media screen and (max-width: 550px) {
    max-width: 200px;
  }
}
.form-control {
  font-size: 18px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.add-new-window-section {
  display: grid;
  place-items: center;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.03em;
  margin-top: 50px;
}

.disable-editing {
  pointer-events: none;
}
</style>
