import { Message } from "./chat.store";
import { Department } from "./department.store";
import storeCache from "@/js/storeCache";
import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import AlertHelper from "@/helpsers/alert.helper";
import i18n from "@/i18n";
import authHelper from "@/helpsers/auth.helper";
import { formatDate } from "@/js/utils";
import { RegularDataObject, RegularErrorResponse, RegularGetResponse } from "./utils/interfaces";
import { Car } from "./cars.store";
import router from "@/router";
import { Driver } from "./driver.store";
import { inject, ref } from "vue";
import { isVerificationTransferError, parseError } from "@/js/error";
export interface MonitaryNote {
  startHour: string;
  endHour: string;
  type: string;
  date: string;
  driverFullName: string;
  driverDocument: string;
  driverPhone: number;
  driverEmail: string;
  cargoWeight: number;
  cargoVolume: number;
  cargoLength: number;
  numberOfPallets: number;
  cargoDescription: string;
  additionalDescription: string;
  consignmentNumber: string;
  departmentId: string;
  carrierId: string;
  warehouseManagerId: string;
  carId: string;
  trailerId?: string;
  semitrailerId?: string;
  semitralerId?: string;
  alert?: any;
  emitter?: any;
  status?: string;
  warehouseMonitoryNoteId?: string;
  monitoryNoteNumberInWarehouse?: string;
  carrier: Department;
  warehouseId: string;
  id?: string;
  warehouse: Department;
  monitaryDate?: any;
  cargos: any;
  car?: any;
  trailer?: any;
  semitrailer?: any;
  wallet?: boolean;
  driverId: string;
  chosenDriver?: Driver;
}
export interface ListMonitaryData {
  id: string;
  status: string;
  alert?: any;
  type?: string;
  sorting?: string;
  additionalFilters?: Array<Filter>;
  startDate?: string;
  endDate?: string;
}
export interface Filter {
  key: string;
  value: string;
}

export const useMonitaryStore = defineStore("monitary", () => {
  let alert = inject("alert");
  const isLoading = ref(false);
  const notes = ref([] as MonitaryNote[]);
  const notesForCounter = ref([] as MonitaryNote[]);
  const note = ref({} as MonitaryNote);
  const page = ref(1);
  const onPage = ref(4);
  const onPageCars = ref(7);
  const total = ref(0);
  const carStatuses = ref([]);
  const monitaryCounter = ref(0);
  const currentWaitingMonitary = ref(0);
  const errorResponseMessage = ref({ statusCode: 0, message: "" });

  const addNewMonitary = async (obj: MonitaryNote) => {
    isLoading.value = true;
    let { alert, emitter, ...object } = obj;
    try {
      const response = await axios.post(ApiHelper.addReservation(), object);
      if (response) {
        storeCache.set("monitoryAdded", true);
        if (obj.wallet) {
          storeCache.set("paymentProcessed", true);
          router.push({ name: "wallet" });
        } else {
          storeCache.remove("paymentProcessed");
        }
        isLoading.value = false;
        return response;
      }
    } catch (e: any) {
      emitter.emit("showPopup", {
        message: e.response.data.message,
        type: "error",
        button: isVerificationTransferError(e)
          ? {
              label: "Wykonaj przelew",
              redirect: "VERIFICATION",
            }
          : undefined,
      });
      errorResponseMessage.value = e.response.data;
      AlertHelper.show(alert, parseError(e), "", "error");
      isLoading.value = false;
      throw e;
    }
  };

  const changeMonitaryStatus = async (obj: ListMonitaryData) => {
    let { alert, id, ...object } = obj;
    return await axios
      .put(ApiHelper.changeMonitaryStatus(id), object)
      .then(() => {
        if (object.status === "FINISHED") {
          AlertHelper.show(
            alert,
            i18n.global.t("generals.success"),
            "Awizacja została zakończona pomyślnie. Zmieni swój status na koniec dnia.",
            "success"
          );
        } else {
          AlertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("monitary_notes.status_changed"), "success");
        }
      })
      .catch((err: RegularErrorResponse) => {
        AlertHelper.show(alert, i18n.global.t("generals.error"), err.response.data.message, "error");
      });
  };

  const editMonitary = async (obj: any) => {
    isLoading.value = true;
    let { alert, id, ...object } = obj;
    return await axios
      .put(ApiHelper.editMonitary(id), object)
      .then(() => {
        if (alert) {
          AlertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("monitary_notes.monitary_note_changed"), "success");
          isLoading.value = false;
        }
      })
      .catch((e: RegularErrorResponse) => {
        isLoading.value = false;
        AlertHelper.show(alert, parseError(e), "", "error");
      });
  };

  const listMonitary = async (obj: ListMonitaryData) => {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    let { id, status, additionalFilters, sorting, startDate, endDate } = obj;
    if (id === "0") return;
    if (status === "PENDING") url += `&status[0]=WAITING_FOR_ACCEPTANCE`;
    if (status === "CANCELLED") url += `&status[0]=REJECTED&status[1]=CANCELLED`;
    if (status === "CURRENT") url += `&status[0]=PENDING`;
    if (status === "ACCEPTED") url += `&status[0]=ACCEPTED`;
    if (startDate) url += `&startDate=${startDate}`;
    if (endDate) url += `&endDate=${endDate}`;
    if (sorting !== "") url += sorting;
    if (obj.type !== "") url += `&type=${obj.type === "LOADING" ? "LOADING" : "UNLOADING"}`;
    additionalFilters?.forEach((filter) => {
      if (filter.key !== "") {
        url = url + `&${filter.key}=${filter.value}`;
      }
    });
    return await axios
      .get(ApiHelper.listReservations(id) + url)
      .then((res: RegularGetResponse<MonitaryNote>) => {
        total.value = res.data.meta.total;
        onPage.value = res.data.meta.per_page;
        page.value = res.data.meta.current_page;
        notes.value = res.data.data.map((monitary: any) => {
          if (monitary.warehouseMonitoryNoteId === null || monitary.warehouseMonitoryNoteId === 0) monitary.warehouseMonitoryNoteId = "-";
          monitary.monitaryDate = {
            startHour: monitary.startHour,
            endHour: monitary.endHour,
            date: monitary.date,
          };
          return monitary;
        });
      })
      .catch((err: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(err), "", "error");
      });
  };

  async function listMonitaryNotesByCompanyId(obj: ListMonitaryData) {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    let { id, status, additionalFilters, sorting, startDate, endDate } = obj;
    if (status === "PENDING") url += `&status[0]=WAITING_FOR_ACCEPTANCE`;
    if (status === "CANCELLED") url += `&status[0]=REJECTED&status[1]=CANCELLED`;
    if (status === "CURRENT") url += `&status[0]=PENDING`;
    if (status === "FINISHED") url += `&status[0]=FINISHED`;
    if (status === "ACCEPTED") url += `&status[0]=ACCEPTED`;
    if (startDate) url += `&startDate=${startDate}`;
    if (endDate) url += `&endDate=${endDate}`;
    if (sorting !== "") url += sorting;
    if (obj.type !== "") url += `&type=${obj.type === "LOADING" ? "LOADING" : "UNLOADING"}`;
    additionalFilters?.forEach((filter) => {
      if (filter.key !== "") {
        url = url + `&${filter.key}=${filter.value}`;
      }
    });
    return await axios
      .get(ApiHelper.listMointaryByBompanyId(id) + url)
      .then((res: RegularGetResponse<MonitaryNote>) => {
        total.value = res.data.meta.total;
        onPage.value = res.data.meta.per_page;
        page.value = res.data.meta.current_page;
        notes.value = res.data.data.map((monitary: any) => {
          if (monitary.warehouseMonitoryNoteId === null || monitary.warehouseMonitoryNoteId === 0) monitary.warehouseMonitoryNoteId = "-";
          monitary.monitaryDate = {
            startHour: monitary.startHour,
            endHour: monitary.endHour,
            date: monitary.date,
          };
          return monitary;
        });
      })
      .catch((err: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(err), "", "error");
      });
  }
  async function listMonitaryForCounter(obj: ListMonitaryData) {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    let { id, status, additionalFilters, sorting } = obj;
    if (id === "0") return;
    if (status === "PENDING") url += `&status[0]=WAITING_FOR_ACCEPTANCE`;
    if (status === "CANCELLED") url += `&status[0]=REJECTED&status[1]=CANCELLED`;
    if (status === "CURRENT") url += `&status[0]=PENDING`;
    if (status === "ACCEPTED") url += `&status[0]=ACCEPTED`;
    if (sorting !== "") url += sorting;
    if (obj.type !== "") url += `&type=${obj.type === "LOADING" ? "LOADING" : "UNLOADING"}`;
    additionalFilters?.forEach((filter) => {
      if (filter.key !== "") {
        url = url + `&${filter.key}=${filter.value}`;
      }
    });
    return await axios
      .get(ApiHelper.listReservations(id) + url)
      .then((res: RegularGetResponse<MonitaryNote>) => {
        notesForCounter.value = res.data.data;
      })
      .catch((err: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(err), "", "error");
      });
  }
  async function getCurrentWaitingMonitory(id: string) {
    let url = `?status=WAITING_FOR_ACCEPTANCE`;
    if (id === "0") return;
    try {
      const response = await axios.get(ApiHelper.getCountedWaitingMonitoriesUrl(id) + url);
      currentWaitingMonitary.value = response.data.number;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getCurrentWaitingMonitoryForCompany(id: string) {
    try {
      const res = await axios.get(ApiHelper.listAll(id) + "?status=WAITING_FOR_ACCEPTANCE", {
        headers: authHelper.authHeader(),
      });
      if (res && res.data) {
        currentWaitingMonitary.value = res.data?.monitoryNotesNumber;
      }
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function listMonitaryArchived(obj: ListMonitaryData) {
    let { id, status, additionalFilters, sorting } = obj;
    if (id === "0") return;
    if (id !== undefined) {
      let url = `?status=FINISHED`;
      additionalFilters?.forEach((filter) => {
        url = url + `&${filter.key}=${filter.value}`;
      });
      url = url + `&limit=${onPage.value}&page=${page.value}`;
      if (sorting && sorting !== "") url += sorting;
      return await axios
        .get(ApiHelper.listReservations(id) + url)
        .then((res: RegularGetResponse<MonitaryNote>) => {
          total.value = res.data.meta.total;
          onPage.value = res.data.meta.per_page;
          page.value = res.data.meta.current_page;
          notes.value = res.data.data.map((monitary: any) => {
            if (monitary.warehouseMonitoryNoteId === "") monitary.warehouseMonitoryNoteId = "-";
            monitary.monitaryDate = {
              startHour: monitary.startHour,
              endHour: monitary.endHour,
              date: monitary.date,
            };
            return monitary;
          });
        })
        .catch((err: RegularErrorResponse) => {
          AlertHelper.show(alert, parseError(err), "", "error");
        });
    }
  }
  async function listSingleMonitary(obj: any) {
    let { id, departmentId } = obj;
    return await axios
      .get(ApiHelper.listDetails(id, departmentId))
      .then((res: { data: MonitaryNote }) => {
        note.value = res.data;
      })
      .catch((err: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(err), "", "error");
      });
  }
  async function checkByDay(departmentId: string, day: string) {
    try {
      const response = await axios.get(ApiHelper.checkByDay(departmentId, day));
      return response.data.number;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
      throw e;
    }
  }
  async function checkCompanyByDay(companyId: string, day: string) {
    try {
      const response = await axios.get(ApiHelper.checkCompanyByDay(companyId, day));
      if (!response || !response?.data) return;
      return response.data?.number;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
      throw e;
    }
  }
  async function listCarStatuses(
    id: string,
    onPage: number,
    pageC: number,
    operation: string,
    sorting: string,
    departmentName?: string,
    query?: string
  ) {
    try {
      const res = await axios.get(ApiHelper.listStatuses(id), {
        headers: authHelper.authHeader(),
        params: {
          limit: onPage || undefined,
          page: pageC || undefined,
          query: query !== "" ? query : undefined,
          carStatus: operation && operation !== "ALL" ? operation : undefined,
          sort_by: sorting?.replace("&sort_by=", "").replace("carName", "name") || undefined,
          departmentName: departmentName || undefined,
        },
      });
      total.value = res.data.meta.total;
      onPageCars.value = res.data.meta.per_page;
      page.value = res.data.meta.current_page;
      carStatuses.value = res.data.data.map((obj: any) => {
        if (obj.status === "CANCELLED") obj.status = "CAR_PENDING";
        if (obj.status === "PENDING") obj.status = "CURRENT_MONITARY";
        if (obj.status === "FINISHED") obj.status = "CAR_ENDED";
        return obj;
      });
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAllMonitaryForCompany(id: string) {
    try {
      const res = await axios.get(ApiHelper.listAllNumber(id) + "?status=WAITING_FOR_ACCEPTANCE", {
        headers: authHelper.authHeader(),
      });
      if (res && res.data && res.data.number) {
        monitaryCounter.value = res.data.number;
        return res.data;
      }
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function acceptMonitary(obj: any) {
    const { id, alert } = obj;
    try {
      const res = await axios.put(ApiHelper.acceptMonitary(id), {
        headers: authHelper.authHeader(),
      });
      return res;
    } catch (e: any) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function changeMonitaryStatusToAccepted(obj: { id: string; rampId?: string; alert: any; status?: string }) {
    const { id, rampId, alert } = obj;
    try {
      const res = await axios.put(ApiHelper.acceptMonitaryStatusChange(id), {
        rampId: rampId ?? undefined,
      });
      return res;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
      throw e;
    }
  }
  async function changeMonitaryStatusToRevoked(obj: { id: string; alert: any }) {
    const { id, alert } = obj;
    try {
      const res = await axios.patch(ApiHelper.revokeMonitaryStatusChange(id));
      return res;
    } catch (e: any) {
      AlertHelper.show(alert, parseError(e), "", "error");
      throw e;
    }
  }
  async function finishMonitary(id: string) {
    try {
      await axios.put(ApiHelper.finishMonitary(id), {
        headers: authHelper.authHeader(),
      });
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function changeMonitaryStatusToRejected(obj: { id: string; alert: any }) {
    const { id, alert } = obj;
    try {
      const res = await axios.put(ApiHelper.rejectMonitaryStatusChange(id));
      return res;
    } catch (e: any) {
      AlertHelper.show(alert, parseError(e), "", "error");
      throw e;
    }
  }
  async function changeMonitaryStatusToFinished(obj: { id: string; alert: any }) {
    const { id, alert } = obj;
    try {
      await axios.put(ApiHelper.finishMonitaryStatusChange(id));
      AlertHelper.show(
        alert,
        i18n.global.t("generals.success"),
        "Awizacja została zakończona pomyślnie. Zmieni swój status na koniec dnia.",
        "success"
      );
    } catch (e: any) {
      AlertHelper.show(alert, parseError(e), "", "error");
      throw e;
    }
  }
  async function sendReminder(object: any) {
    const { id, alert } = object;
    isLoading.value = true;
    try {
      await axios.post(
        ApiHelper.sendReminder(id),
        {},
        {
          headers: authHelper.authHeader(),
        }
      );
      AlertHelper.show(alert, "Wysłano przypomnienie do kierowcy", "", "success");
      isLoading.value = false;
    } catch (e: any) {
      isLoading.value = false;
      if (!e.response.data.message) return;
      AlertHelper.show(alert, e.response.data.message, "", "error");
    }
  }
  async function downloadMonitaryDocument(departmentId: string, monitaryId: string) {
    try {
      const response = await axios.get(ApiHelper.downloadDocument(departmentId, monitaryId), {
        responseType: "blob",
      });

      if (response.data.type === "application/pdf") {
        const fileURL = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/octetstream",
          })
        );
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${monitaryId}.pdf`);
        fileLink.setAttribute("href", fileURL);
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      }
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  return {
    isLoading,
    notes,
    notesForCounter,
    note,
    page,
    onPage,
    onPageCars,
    total,
    carStatuses,
    monitaryCounter,
    currentWaitingMonitary,
    errorResponseMessage,
    addNewMonitary,
    changeMonitaryStatus,
    editMonitary,
    listMonitary,
    listMonitaryNotesByCompanyId,
    listMonitaryForCounter,
    getCurrentWaitingMonitory,
    getCurrentWaitingMonitoryForCompany,
    listMonitaryArchived,
    listSingleMonitary,
    checkByDay,
    checkCompanyByDay,
    listCarStatuses,
    getAllMonitaryForCompany,
    acceptMonitary,
    changeMonitaryStatusToAccepted,
    changeMonitaryStatusToRevoked,
    finishMonitary,
    changeMonitaryStatusToRejected,
    changeMonitaryStatusToFinished,
    sendReminder,
    downloadMonitaryDocument,
  };
});
