<template>
        <main class="admin-profile">
            <div class="admin-profile__inner">
                <!-- <div class="admin-profile__top">
                    <a @click="$router.back()"><img src="@/assets/images/admin/arrow.svg" />{{$t('admin.general.back')}}</a>
                </div> -->
                <div class="admin-profile__nav">
                    <router-link :to="{name: 'admin.profile'}" class="active">{{$t('admin.profile.my_profile_title')}}</router-link> 
                    <router-link :to="{name: 'admin.settings'}" class="">{{$t('admin.profile.my_profile_settings_title')}}</router-link>
                </div>
               <user-profile-component v-if="authStore.userInfo"/>
            </div>
        </main>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import UserProfileComponent from "@/components/admin/userProfile";
import {useAuthStore} from "@/stores/auth.store";
import AlertHelper from "@/helpsers/alert.helper";

export default {
    name: "MyProfile",
    components: {UserProfileComponent, AdminLayout},
    setup() {
        const authStore = useAuthStore();
        return {
            authStore,
        }
    }
}
</script>

<style scoped>

</style>