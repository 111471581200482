<template>
  <div class="admin-profile__box">
    <div class="admin-profile__box-left">
      <p>{{ $t("admin.profile.personal_image") }}</p>
      <!--            <img src="https://via.placeholder.com/232" alt="" />-->
      <img v-show="authStore.userInfo.profilePhotoBlob" class="" id="profile_photo" :src="authStore.userInfo.profilePhotoBlob" />
      <img v-show="!authStore.userInfo.profilePhotoBlob" class="" id="profile_photo_blank" src="../../assets/images/blank_person.jpg" />

      <!-- <label for="upload">
        <span class="glyphicon glyphicon-folder-open" aria-hidden="true"></span>
        <input @change="uploadImage" type="file" id="upload" style="display: none" />
        <div class="button-uploader">dupa</div>
         <primary-button style="margin-top: 10px" :red="true" :filled="true" :title="$t('profile.change_image_title')"> </primary-button>
      </label> -->
      <div style="margin-top: 10px" class="control control--input">
        <label for="galery" class="control__label galery-custom-btn">
          <div class="button-uploader">{{ $t("profile.change_image_title") }}</div>
        </label>
        <input type="file" id="galery" accept=".jpg, .jpeg, .png" style="display: none" @change="uploadImage($event)" />
      </div>

      <input
        v-show="uploadImageButton && !imageDuringUpload"
        id="file"
        ref="image"
        @input="uploadImage"
        type="file"
        accept=".jpg, .jpeg, .png"
        name="profile_image"
      />
      <div class="upload-file-loader">
        <loader v-show="imageDuringUpload" />
      </div>
      <!-- <div>
        <img
          v-show="!uploadImageButton"
          @click="uploadImageButton = true"
          src="@/assets/icons/admin/create-icon.svg"
        />
        <img src="@/assets/icons/admin/delete-icon.svg" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth.store";
import { inject, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import axios from "axios";
import ApiHelper from "@/helpsers/api.helper";
import authHelper from "@/helpsers/auth.helper";
import loader from "@/components/loader";
import UserService from "@/services/user.service";
import { A11yDialog } from "vue-a11y-dialog";
import AlertHelper from "@/helpsers/alert.helper";
import PrimaryButton from "../common/PrimaryButton.vue";
import { resizeImage } from "@/js/resize.utils";
import storeCache from "@/js/storeCache";
import { useUserStore } from "@/stores/user.store";
export default {
  name: "UserProfileComponent",
  components: {
    loader,
    PrimaryButton,
  },
  setup() {
    const authStore = useAuthStore();
    let alert = inject("alert");
    const { t } = useI18n();
    const imageDuringUpload = ref(false);
    const uploadImageButton = ref(false);
    const userService = new UserService();
    const users = useUserStore();

    const { handleSubmit, errors } = useForm({
      validationSchema: yup.object().shape({
        name: yup.string().trim(),
        surname: yup.string().trim(),
        email: yup.string().trim(),
        phone_number: yup.string().trim(),
        street: yup.string().trim(),
        premises_number: yup.string().trim(),
        apartment_number: yup.string().trim(),
        zip_code: yup
          .string()
          // .matches(/^[0-9]{2}-[0-9]{3}/,t('admin.profile.validation.zip_code'))
          .trim(),
        city: yup.string().trim(),
      }),
      initialValues: {
        name: authStore.userInfo.firstName,
        surname: authStore.userInfo.lastName,
        email: authStore.userInfo.email,
        phone_number: authStore.userInfo.phone,
        street: authStore.userInfo.street,
        premises_number: authStore.userInfo.streetNumber,
        apartment_number: authStore.userInfo.flatNumber,
        zip_code: authStore.userInfo.postcode,
        city: authStore.userInfo.city,
      },
    });

    const { value: name } = useField("name");
    const { value: surname } = useField("surname");
    const { value: email } = useField("email");
    const { value: phone_number } = useField("phone_number");
    const { value: street } = useField("street");
    const { value: premises_number } = useField("premises_number");
    const { value: apartment_number } = useField("apartment_number");
    const { value: zip_code } = useField("zip_code");
    const { value: city } = useField("city");

    const saveProfileData = handleSubmit(async () => {
      authStore.isLoading = true;

      const data = {
        name: name.value,
        lastname: surname.value,
        // email: email.value,
        phone: phone_number.value,
        street: street.value,
        streetNumber: premises_number.value,
        flatNumber: apartment_number.value,
        postcode: zip_code.value,
        city: city.value,
      };

      axios
        .put(ApiHelper.getUpdateUserUrl(), data, {
          headers: authHelper.authHeader(),
        })
        .then((response) => {
          //Dodac alerty globalnie.
          //if(response.status == 200)
          authStore.isLoading = false;
          AlertHelper.show(alert, t("admin.profile.update_data_success"));
        })
        .catch((errorResponse) => {
          authStore.isLoading = false;
          let errorMessage = "";
          if (errorResponse.response.data.errors) errorMessage = errorResponse.response.data.errors.join(" ");
          else if (errorResponse.response.data.code) errorMessage = ApiHelper.getResponseMessage(errorResponse.response.data.code);

          AlertHelper.show(alert, errorMessage, "", "error");
        });
    });

    async function uploadImage(e) {
      let photo = e.target.files[0];
      let size = photo.size;
      if (size <= 10_000_000) {
        let formData = new FormData();
        const config = {
          file: photo,
          maxSize: 500,
        };
        const resizedImage = await resizeImage(config);
        var file = new File([resizedImage], "name", { type: "image/jpeg" });
        let user = storeCache.get("currentUser");
        formData.append("file", file);
        formData.append("visibleToAll", "true");
        formData.append("type", "USER_AVATAR");
        formData.append("userId", user.id);

        userService
          .setUserProfileImage(formData)
          .then(async (r) => {
            await users.fetchCurrentUser();
            user = storeCache.get("currentUser");
            await userService
              .getUserProfileImage(user.avatar.id)
              .then((r) => {
                console.log(r);
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        AlertHelper.show(alert, t("files.errors.size"), "", "error");
      }
    }

    // document.querySelector("#profile_photo").src = objectURL;

    function removeImage() {
      userService
        .removeUserProfileImage()
        .then(() => {
          AlertHelper.show(alert, t("admin.profile.delete_profile_img.alert_success"));
        })
        .catch((e) => {
          let errorMessage = ApiHelper.getResponseMessage(e.response?.data?.code);
          AlertHelper.show(alert, errorMessage, "", "error");
        });
    }

    return {
      name,
      surname,
      email,
      phone_number,
      street,
      premises_number,
      apartment_number,
      zip_code,
      city,
      saveProfileData,
      uploadImage,
      authStore,
      imageDuringUpload,
      uploadImageButton,
      removeImage,
    };
  },
};
</script>

<style lang="scss">
.buttons {
  width: 100%;
}
.disabled {
  cursor: not-allowed;
  background-color: #d4d1d1 !important;
}
.button-uploader {
  background: #112baf;
  color: #fff;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  cursor: pointer;
  border-radius: 10px;
  font-weight: 600;
}
</style>
