<template>
  <template v-if="!resetPasswordSuccess">
    <h2 class="auth__heading">{{ $t("reset_password.title") }}</h2>
    <form class="auth__form" @submit="onSubmit">
      <div class="auth__error-message" v-if="backendError">
        {{ backendError }}
      </div>
      <div class="control control--input control--password">
        <button
          class="control__eye control__eye--show"
          data-control-id="password"
          type="button"
          @click.prevent="showPassword"
        >
          <img src="@/assets/images/eye.svg" />
        </button>
        <button
          class="control__eye control__eye--hide hidden"
          data-control-id="password"
          type="button"
          @click.prevent="hidePassword"
        >
          <img src="@/assets/images/eye-line.svg" />
        </button>
        <label for="password" class="control__label">{{
          $t("reset_password.password")
        }}</label>
        <input
          type="password"
          id="password"
          :placeholder="$t('reset_password.password_placeholder')"
          v-model="password"
          :class="{ error: errors.password }"
        />
        <div v-show="errors.password" class="control__error">
          <div class="control__error-inner">
            {{ errors.password }}
          </div>
        </div>
      </div>
      <div class="control control--input control--password">
        <button
          class="control__eye control__eye--show"
          data-control-id="repeatPassword"
          type="button"
          @click.prevent="showPassword"
        >
          <img src="@/assets/images/eye.svg" />
        </button>
        <button
          class="control__eye control__eye--hide hidden"
          data-control-id="repeatPassword"
          type="button"
          @click.prevent="hidePassword"
        >
          <img src="@/assets/images/eye-line.svg" />
        </button>
        <label for="repeatPassword" class="control__label">{{
          $t("reset_password.confirm_password")
        }}</label>
        <input
          type="password"
          id="repeatPassword"
          :placeholder="$t('reset_password.confirm_password_placeholder')"
          v-model="passwordConfirmation"
          :class="{ error: errors.passwordConfirmation }"
        />
        <div v-show="errors.passwordConfirmation" class="control__error">
          <div class="control__error-inner">
            {{ errors.passwordConfirmation }}
          </div>
        </div>
      </div>
      <div class="auth__buttons">
        <button
          class="button button--brown button--wide"
          type="submit"
          :disabled="sendingForm"
          :class="{ disabled: sendingForm }"
        >
          <template v-if="!sendingForm">
            {{ $t("reset_password.change_password") }}
          </template>
          <template v-else>
            {{ $t("reset_password.changing_password_in_progress") }}
          </template>
        </button>
      </div>
    </form>
  </template>
  <template v-else>
    <h2 class="auth__heading">{{ $t("reset_password.ready") }}</h2>
    <p class="auth__text auth__text--centered">
      {{ $t("reset_password.password_changed") }}
    </p>
    <p class="auth__url auth__url--brown">
      <router-link to="/login">{{
        $t("reset_password.click_to_go_to_login")
      }}</router-link>
    </p>
  </template>
</template>

<script>
import { ref, inject } from "vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import i18n from "@/i18n";
import router from "@/router";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import GuestLayout from "@/layouts/GuestLayout";
import axios from "axios";
import ApiHelper from "@/helpsers/api.helper";
import { useAuthStore } from "@/stores/auth.store";
import AlertHelper from "@/helpsers/alert.helper";

export default {
  name: "ResetPasswordView",
  components: {
    GuestLayout,
  },
  setup() {
    const resetPasswordSuccess = ref(false);
    const backendError = ref("");
    const sendingForm = ref(false);
    const route = useRoute();
    const { t } = useI18n();
    const userStore = useAuthStore();
    let verificationCode = route.params.token;
    let alert = inject("alert");

    if (!verificationCode) {
      router.push("/");
    }

    const { handleSubmit, errors } = useForm({
      validationSchema: yup.object().shape({
        password: yup
          .string()
          .required(i18n.global.t("register.validation.required"))
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            i18n.global.t("register.validation.password")
          ),
        passwordConfirmation: yup
          .string()
          .required(i18n.global.t("register.validation.required"))
          .oneOf(
            [yup.ref("password"), null],
            i18n.global.t("register.validation.passwordConfirmation")
          ),
      }),
    });

    const { value: password } = useField("password");
    const { value: passwordConfirmation } = useField("passwordConfirmation");

    const onSubmit = handleSubmit(async (values) => {
      try {
        sendingForm.value = true;
        const { password } = values;
        await userStore.resetPassword({
          password,
          token: verificationCode,
        });
        resetPasswordSuccess.value = true;
      } catch (err) {
        AlertHelper.show(alert, t("reset_password.invalid_code"));
        sendingForm.value = false;
      }
    });

    function showPassword(ev) {
      ev.currentTarget.classList.add("hidden");
      document.querySelector("#" + ev.currentTarget.dataset.controlId).type =
        "text";
      document
        .querySelector(
          ".control__eye--hide[data-control-id=" +
            ev.currentTarget.dataset.controlId +
            "]"
        )
        .classList.remove("hidden");
    }

    function hidePassword(ev) {
      ev.currentTarget.classList.add("hidden");
      document.querySelector("#" + ev.currentTarget.dataset.controlId).type =
        "password";
      document
        .querySelector(
          ".control__eye--show[data-control-id=" +
            ev.currentTarget.dataset.controlId +
            "]"
        )
        .classList.remove("hidden");
    }

    return {
      verificationCode,
      password,
      passwordConfirmation,
      showPassword,
      hidePassword,
      sendingForm,
      onSubmit,
      errors,
      backendError,
      resetPasswordSuccess,
    };
  },
};
</script>

<style scoped lang="scss">
.control {
  &--input {
    input {
      height: 62px;
      max-width: 100%;
    }
  }
}

.auth {
  &__buttons {
    .disabled {
      cursor: not-allowed;
      background-color: #707070;
    }
  }
}
</style>
