import axios from "axios";
import ApiHelper from "@/helpsers/api.helper";
import AuthHelper from "@/helpsers/auth.helper";
import { useAuthStore } from "@/stores/auth.store";
import authHelper from "@/helpsers/auth.helper";
import StoreCache from "@/js/storeCache";
import storeCache from "@/js/storeCache";

class DepartmentService {

  async setDepartmentAvatar(formData:any) {
    let result = null;
    const url = ApiHelper.getUploadProfileImageUrl();
    result = await axios.post(url, formData, {
      headers: authHelper.authHeader(),
    });
    return result;
  }

  async getUserProfileImage(id:string) {
    const authStore = useAuthStore();
    if (typeof id !== "object") {
      await axios
        .get(ApiHelper.getImage(id), {
          headers: authHelper.authHeader(),
          responseType: "blob",
        })
        .then((result) => {
          const objectURL = URL.createObjectURL(result.data);
          if (authStore.userInfo) {
            //@ts-ignore
            authStore.userInfo.profilePhotoBlob = objectURL;
          }
          return Promise.resolve("Success get profile image");
        })
        .catch((e) => {
          console.log(e);
          return Promise.reject("Error get profile image");
        });

  
    }
  }
  async getImageAndReturnUrl(id:string) {
    let url = "";
    if (typeof id !== "object") {
      await axios
        .get(ApiHelper.getImage(id), {
          headers: authHelper.authHeader(),
          responseType: "blob",
        })
        .then((result) => {
          url = URL.createObjectURL(result.data);
        })
        .catch((e) => {
          console.log(e);
          return Promise.reject("Error get profile image");
        });
    }
    return url;
  }

}

export default DepartmentService;
