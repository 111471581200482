import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import authHelper from "@/helpsers/auth.helper";
import AlertHelper from "@/helpsers/alert.helper";
import i18n from "@/i18n";
import UserService from "@/services/user.service";
import { RegularDataObject, RegularErrorResponse } from "@/stores/utils/interfaces";
import { ref } from "vue";
import { Company } from "@/stores/company.store";
import { parseError } from "@/js/error";

export const useUsersLogiplannerStore = defineStore("logiplanner", () => {
  const prices = ref([]);
  const chart = ref([]);
  const notes = ref([]);
  const payments = ref([]);
  const leasings = ref([]);
  const auctions = ref([]);
  const invoiceSumCounter = ref(0);
  const chartInfo = ref([
    {
      name: "name",
      value: 30,
      max: 100,
    },
    {
      name: "name2",
      value: 40,
      max: 100,
    },
  ]);
  const companiesTypeNumber = ref({
    companies: {
      activated: "",
      waiting_for_acceptance: "",
      blocked: "",
    },
    users: {
      activated: "",
      waiting_for_acceptance: "",
      blocked: "",
    },
  });
  const users = ref([]);
  const companies = ref([]);
  const departments = ref([]);
  const page = ref(1);
  const onPage = ref(5);
  const total = ref(0);
  const isLoading = ref(false);
  const waitingCompanies = ref(0);
  const waitingDriver = ref(0);
  const waitingWarehouse = ref(0);
  const waitingFactory = ref(0);
  const waitingAcceptedMonitary = ref(0);
  const waitingWaitingMonitary = ref(0);
  const waitingRejectedMonitary = ref(0);
  const waitingArchivedMonitary = ref(0);
  const waitingAuthorizedUsers = ref(0);
  const waitingWaitingUsers = ref(0);
  const waitingBlockedUsers = ref(0);
  const waitingAcceptedLeasings = ref(0);
  const waitingWaitingLeasings = ref(0);
  const waitingRejectedLeasings = ref(0);

  const fetchTypesNumber = async () => {
    try {
      const res = await axios.get(ApiHelper.getTypesNumber(), {
        headers: authHelper.authHeader(),
      });
      companiesTypeNumber.value = res.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const postGroupMessage = async (obj: any) => {
    const { alert, ...object } = obj;
    try {
      const response = await axios.post(ApiHelper.sendGroupMessage(), object, {
        headers: authHelper.authHeader(),
      });
      AlertHelper.show(alert, "Poprawnie wysłano wiadomość.", "", "success");
    } catch (e) {
      AlertHelper.show(alert, "Wystąpił błąd podczas wysyłania wiadomości.", "", "error");
    }
  };

  const postGroupMessageNoAlerts = async (obj: any) => {
    const { alert, ...object } = obj;
    try {
      await axios.post(ApiHelper.sendGroupMessage(), object, {
        headers: authHelper.authHeader(),
      });
    } catch (e) {
      // AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchUsers = async (
    pageC: string | number,
    onPageC: string | number,
    status: string,
    query: string,
    companyType: string,
    sorting: string
  ) => {
    isLoading.value = true;
    const userService = new UserService();
    let url = `?limit=${onPageC}&page=${pageC}&status=${status}`;
    if (query !== "") url += `&query=${query}`;
    if (companyType !== "") url += `&companyType[0]=${companyType}`;
    if (sorting && sorting !== "") url += sorting;
    try {
      const result = await axios.get(ApiHelper.getAdminUsersLogiplannerUrl() + url, {
        headers: authHelper.authHeader(),
      });
      users.value = result.data.data.map((worker: any) => {
        delete worker.modifiedAt;
        delete worker.createdAt;
        delete worker.invitations;
        return worker;
      });
      total.value = result.data.meta.total;
      onPage.value = result.data.meta.per_page;
      page.value = result.data.meta.current_page;
      isLoading.value = false;
    } catch (errorResponse) {
      isLoading.value = false;
    }
  };

  const countAllUsers = async (query: string, status: string) => {
    let companyTypes = ["DRIVER", "WAREHOUSE", "FACTORY"];
    companyTypes.forEach(async (type) => {
      let url = `?status=${status}&type=${type}`;
      url += query ? `&query=${query}` : "";
      try {
        const response = await axios.get(ApiHelper.getCountedLogiplannerUsersUrl() + url, {
          headers: authHelper.authHeader(),
        });
        if (type === "DRIVER") waitingAuthorizedUsers.value = response.data.number;
        if (type === "WAREHOUSE") waitingWaitingUsers.value = response.data.number;
        if (type === "FACTORY") waitingBlockedUsers.value = response.data.number;
      } catch (e) {
        AlertHelper.show(alert, parseError(e), "", "error");
      }
    });
  };

  const fetchCompanies = async (pageC: string | number, onPageC: string | number, status: string, type: string, search: string, sorting: string) => {
    let url = `?limit=${onPageC !== "" ? onPageC : "10"}&page=${pageC}`;
    if (status && status !== "ALL") url += `&companyStatus=${status}`;
    if (type && status !== "ALL") url += `&companyType=${type}`;
    if (search) url += `&search=${search}`;
    if (sorting && sorting !== "NONE") url += sorting;
    isLoading.value = true;
    try {
      const result = await axios.get(ApiHelper.getAdminCompaniesLogiplannerUrl() + url, {
        headers: authHelper.authHeader(),
      });
      companies.value = result.data.data.map((company: any, index: number) => {
        delete company.modifiedAt;
        delete company.leasings;
        return company;
      });
      total.value = result.data.meta.total;
      onPage.value = result.data.meta.per_page;
      page.value = result.data.meta.current_page;
      isLoading.value = false;
    } catch (errorResponse) {
      isLoading.value = false;
    }
  };

  const countAllCompaniesWaiting = async () => {
    try {
      ["DRIVER", "WAREHOUSE", "FACTORY"].forEach(async (type) => {
        try {
          const res = await axios.get(ApiHelper.getCountedLogiplannerWaitingCompaniesUrl(), {
            headers: authHelper.authHeader(),
            params: {
              type,
            },
          });
          if (type === "DRIVER") waitingDriver.value = res.data.number;
          if (type === "WAREHOUSE") waitingWarehouse.value = res.data.number;
          if (type === "FACTORY") waitingFactory.value = res.data.number;
        } catch (error) {
          AlertHelper.show(alert, parseError(error), "", "error");
        }
      });
    } catch (errorResponse) {
      AlertHelper.show(alert, parseError(errorResponse), "", "error");
    }
  };

  const changeUserStatus = async (obj: any) => {
    isLoading.value = true;
    let { alert, ...object } = obj;
    try {
      const result = await axios.put(ApiHelper.getAdminChangeUserStatusUrl(), object, {
        headers: authHelper.authHeader(),
      });
      AlertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("admin.users.logiplanner.status_changed"));
      isLoading.value = false;
    } catch (errorResponse) {
      AlertHelper.show(alert, parseError(errorResponse), "", "error");
      isLoading.value = false;
    }
  };

  const updateUserNote = async (object: any) => {
    try {
      const res = await axios.put(
        ApiHelper.updateUserNote(object.id),
        { note: object.note },
        {
          headers: authHelper.authHeader(),
        }
      );
      console.log(res);
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchPrices = async () => {
    try {
      const result = await axios.get(ApiHelper.getAdminPrices(), {
        headers: authHelper.authHeader(),
      });
      prices.value = result.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchLeasings = async (type: string, search: string, sorting: string) => {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    if (type !== "") url += `&status[0]=${type}`;
    if (search !== "") url += `&query=${search}`;
    if (sorting !== "") url += sorting;
    try {
      const response = await axios.get(ApiHelper.getAdminLeasings() + url, {
        headers: authHelper.authHeader(),
      });
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      leasings.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const countAllLeasings = async () => {
    let statuses = ["ACCEPTED", "PENDING", "REJECTED"];
    try {
      statuses.forEach(async (status) => {
        let url = `?status=${status}`;
        const response = await axios.get(ApiHelper.getCountedAdminLeasingsUrl() + url, {
          headers: authHelper.authHeader(),
        });
        console.log(response);
        if (status === "ACCEPTED") waitingAcceptedLeasings.value = response.data.number;
        if (status === "PENDING") waitingWaitingLeasings.value = response.data.number;
        if (status === "REJECTED") waitingRejectedLeasings.value = response.data.number;
      });
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const changeLeasingStatus = async (obj: any) => {
    let { alert, ...body } = obj;
    try {
      const response = await axios.patch(ApiHelper.changeLeasingStatus(), body, {
        headers: authHelper.authHeader(),
      });
      if (response) {
        AlertHelper.show(alert, "Zmieniono status leasingu", "", "success");
      }
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchChartInfo = async () => {
    try {
      const result = await axios.get(ApiHelper.getAdminChartInfo(), {
        headers: authHelper.authHeader(),
      });
      chart.value = result.data;
      chartInfo.value = result.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchUserChartInfo = async () => {
    try {
      const result = await axios.get(ApiHelper.getAdminUserChartInfo(), {
        headers: authHelper.authHeader(),
      });
      chart.value = result.data.filter((element: { status: string }) => element.status !== "INVITATION_SENT");
    } catch (r) {
      AlertHelper.show(alert, parseError(r), "", "error");
    }
  };

  const fetchDepartments = async (obj: { searchValue?: string; chosenOperation?: string }) => {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    if (obj.searchValue) {
      url += `&query=${obj.searchValue}`;
    }
    if (obj.chosenOperation) {
      url += `&type=${obj.chosenOperation}`;
    }
    try {
      const result = await axios.get(ApiHelper.getAdminDepartments() + url, {
        headers: authHelper.authHeader(),
      });
      total.value = result.data.meta.total;
      onPage.value = result.data.meta.per_page;
      page.value = result.data.meta.current_page;
      departments.value = result.data.data;
    } catch (r) {
      AlertHelper.show(alert, parseError(r), "", "error");
    }
  };

  const fetchAuctions = async (query: string, status: string, sorting: string) => {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    if (query !== "") url += `&query=${query}`;
    if (sorting && sorting !== "") url += sorting;
    if (status !== "ALL" && status !== "") url += `&status=${status}`;
    try {
      const response = await axios.get(ApiHelper.fetchAuctions() + url, {
        headers: authHelper.authHeader(),
      });
      auctions.value = response.data.data;
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchPayments = async (
    pageC: string | number,
    onPageC: string | number,
    status: string,
    query: string,
    type: string,
    sorting: string,
    from: string,
    to: string
  ) => {
    let url = `?limit=${onPageC}&page=${pageC}`;
    if (status !== "") url += `&status=${status}`;
    if (query !== "") url += `&query=${query}`;
    if (type !== "") url += `&category[0]=${type}`;
    if (sorting !== "") url += sorting;
    if (from !== "" && from !== undefined) url += `&dateStart=${from}`;
    if (to !== "" && to !== undefined) url += `&dateEnd=${to}`;
    try {
      const result = await axios.get(ApiHelper.getPayments() + url, {
        headers: authHelper.authHeader(),
      });
      invoiceSumCounter.value = result.data.amountSum ? result.data.amountSum : 0;
      total.value = result.data.meta.total;
      onPage.value = result.data.meta.per_page;
      page.value = result.data.meta.current_page;
      payments.value = result.data.data.map((payment: any) => {
        payment.sum = payment.amount;
        payment.date = payment.createdAt.substring(0, 10);
        payment.type = payment.type === "TOPUP" ? "WALLET_CHARGE" : "WALLET_CHARGE";
        return payment;
      });
    } catch (r) {
      AlertHelper.show(alert, parseError(r), "", "error");
    }
  };

  const fetchMonitoryNotes = async (obj: { status: string; additionalFilters: { key: string; value: string }[]; chosenSorting: string }) => {
    let { status, additionalFilters, chosenSorting } = obj;
    //@ts-ignore
    let url = `?limit=${onPage.value !== "" ? onPage.value : "9999999999"}&page=${page.value}`;
    if (status !== "") {
      url = url + `&status=${status}`;
    }
    if (chosenSorting !== "" && chosenSorting !== "NONE") {
      url += chosenSorting;
    }
    additionalFilters?.forEach((filter) => {
      if (filter.key === "sorting" && filter.value !== "NONE" && filter.value !== "") {
        url += filter.value;
      } else {
        url = url + `&${filter.key}=${filter.value}`;
      }
    });
    try {
      const res = await axios.get(ApiHelper.getMonitoryNotes() + url, {
        headers: authHelper.authHeader(),
      });
      total.value = res.data.meta.total;
      onPage.value = res.data.meta.per_page;
      page.value = res.data.meta.current_page;
      notes.value = res.data.data.map((monitary: any) => {
        if (monitary.warehouseMonitoryNoteId === "") monitary.warehouseMonitoryNoteId = "-";
        monitary.monitaryDate = {
          startHour: monitary.startHour,
          endHour: monitary.endHour,
          date: monitary.date,
        };
        monitary.companyWarehouseName = monitary.warehouse.company.companyName;

        return monitary;
      });
    } catch (r) {
      AlertHelper.show(alert, parseError(r), "", "error");
    }
  };

  const countAllMonitaryNotes = async (additionalFilters: { key: string; value: string }[]) => {
    let url = "?";
    additionalFilters?.forEach((filter) => {
      url = url + `&${filter.key}=${filter.value}`;
    });
    ["WAITING_FOR_ACCEPTANCE", "ACCEPTED", "REJECTED", "FINISHED"].forEach(async (status) => {
      try {
        const res = await axios.get(ApiHelper.getCountedMonitoryNotesUrl() + url, {
          params: {
            status,
          },
        });
        if (status === "REJECTED") waitingRejectedMonitary.value = res.data.number;
        if (status === "WAITING_FOR_ACCEPTANCE") waitingWaitingMonitary.value = res.data.number;
        if (status === "FINISHED") waitingArchivedMonitary.value = res.data.number;
        if (status === "ACCEPTED") waitingAcceptedMonitary.value = res.data.number;
      } catch (error) {
        AlertHelper.show(alert, parseError(error), "", "error");
      }
    });
  };

  return {
    prices,
    chart,
    notes,
    payments,
    leasings,
    auctions,
    chartInfo,
    companiesTypeNumber,
    users,
    companies,
    departments,
    onPage,
    page,
    total,
    isLoading,
    fetchTypesNumber,
    postGroupMessage,
    postGroupMessageNoAlerts,
    fetchUsers,
    countAllUsers,
    fetchCompanies,
    countAllCompaniesWaiting,
    changeUserStatus,
    updateUserNote,
    fetchPrices,
    fetchLeasings,
    countAllLeasings,
    changeLeasingStatus,
    fetchChartInfo,
    fetchUserChartInfo,
    fetchDepartments,
    fetchAuctions,
    fetchPayments,
    fetchMonitoryNotes,
    countAllMonitaryNotes,
    waitingAcceptedLeasings,
    waitingWaitingLeasings,
    waitingRejectedLeasings,
    waitingAcceptedMonitary,
    waitingRejectedMonitary,
    waitingWaitingMonitary,
    waitingArchivedMonitary,
    invoiceSumCounter,
    waitingBlockedUsers,
    waitingWaitingUsers,
    waitingAuthorizedUsers,
    waitingDriver,
    waitingFactory,
    waitingWarehouse,
  };
});
