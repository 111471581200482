<template>
  <table class="responsive-table">
    <div v-if="data.length > 0" class="flex">
      <thead id="table-head">
        <tr class="">
          <th
            v-for="key in keys"
            :key="key"
            style="position: relative"
            :class="{
              'hide-col': key === 'email' && hideMail,
              'smaller-heading': key === 'lp',
              'medium-heading': key === 'subId' || key === 'factoring',
              'filter-wrapper': props.filterColumns,
              'padd-more': unfolded && !disablePad,
            }"
          >
            {{ $t(`tableData.${key}`) }}
            <div v-if="key !== 'lp'" style="position: absolute; display: flex; flex-direction: column; top: -20px; right: 0">
              <img
                src="@/assets/icons/admin/dropdown.svg"
                alt="Filter1"
                :id="`arrow_${key}_ascending`"
                style="transform: rotate(180deg); bottom: -4px; left: 0; cursor: pointer"
                :style="hiddenId === `arrow_${key}_ascending` ? 'visibility:hidden' : ''"
                @click="sortDataByKey(key, 'ascending')"
              />
              <img
                src="@/assets/icons/admin/dropdown.svg"
                :id="`arrow_${key}_descending`"
                alt="Filter2"
                style="top: -4px; left: 0; cursor: pointer"
                :style="hiddenId === `arrow_${key}_descending` ? 'visibility:hidden' : ''"
                @click="sortDataByKey(key, 'descending')"
              />
            </div>
          </th>
          <th class="actionField" v-if="modalAction">
            {{ $t("generals.action") }}
          </th>
        </tr>
      </thead>
      <tbody :class="unfolded ? 'make-distinct' : ''">
        <div>
          <tr v-for="user in unfolded ? [currentObject] : data" :key="user">
            <td
              v-for="key in keys"
              :key="key"
              :class="{
                'smaller-data-field': key === 'lp',
                'medium-data-field': key === 'subId' || key === 'factoring',
                'larger-column': largerColumn,
                'unfolded-col': unfolded,
                'allow-overflow': key === 'monitoryNoteStatus',
              }"
            >
              <div class="flex-col" v-if="key === 'gusConfirmed'">
                <p
                  @click="(gusToDisplay = user.gus), (companyDataInitial = user), gusDialog.show()"
                  :class="user[key] === true ? 'gus-confirmed' : 'gus-not-confirmed'"
                >
                  {{ user[key] === true ? $t("auth.gus_correct") : $t("auth.gus_incorrect") }}
                </p>
              </div>
              <div v-else-if="key === 'factoring'" class="flex-col center-col">
                <input type="checkbox" :checked="user[key]" @change="newFactoringRequest(user, !user[key])" />
              </div>
              <div v-else-if="key === 'companyOwners'" class="flex-col">
                <p v-for="company in user[key]" :key="company.id">
                  {{ company.user.name + " " + company.user.surname }}
                  <br />
                  {{ company.user.email }}
                </p>
              </div>
              <div v-else-if="key === 'createdAt' && user[key] !== null && user[key]" class="flex-col">
                <p>
                  {{ user[key] ? user[key].substring(0, 10) : "" }}
                  {{ user[key] ? user[key].substring(11, 19) : "" }}
                </p>
              </div>
              <div v-else-if="key === 'companyName' && user[key] !== null && user[key]" class="flex-col">
                <p>
                  {{ !unfolded && user[key].length > 30 ? parseLongString(user[key]).substring(0, 30) + "..." : parseLongString(user[key]) }}
                </p>
              </div>
              <div :class="user[key] ? 'underline' : ''" @click="userSelected2(user)" v-else-if="key === 'note'" class="flex-col">
                <p>
                  {{ user[key] ? "UWAGA" : "-" }}
                </p>
              </div>
              <div v-else-if="key === 'type'" class="flex-col">
                <p>
                  {{ $t(`profile.role.${user[key].toLowerCase()}`) }}
                </p>
              </div>
              <div class="" v-else>
                {{
                  key === "role"
                    ? $t(`tableData.${user[key].toLowerCase()}`)
                    : `${user[key]}` === "true"
                    ? $t("admin.profile.settings.yes")
                    : user[key] !== ""
                    ? user[key]
                    : "-"
                }}
              </div>
            </td>
            <td v-if="modalAction" class="actionField double-col-show">
              <action-buttons
                :companyType="user.type"
                :has-unfold="true"
                :remove-status="!!!userType"
                @userSelected="userSelected(user)"
                @userSelected2="userSelected2(user)"
                @goToDetails="goToDetails(user)"
                @unfold="unfoldDetails(user)"
                @startChat="goToChat(user)"
                @showRating="handleShowCompanyRating(user)"
                :unfolded="unfolded"
              />
            </td>
          </tr>
        </div>
        <div id="unfolded-wrapper">
          <div class="unfolded-part" v-if="unfolded">
            <div class="grid-cols">
              <div v-for="field in Object.keys(currentObject)" :key="field">
                <div
                  v-if="
                    field !== 'type' &&
                    field !== 'status' &&
                    field !== 'companyOwners' &&
                    field !== 'documents' &&
                    field !== 'managers' &&
                    field !== 'addresses' &&
                    field !== 'userCompany' &&
                    field !== 'avatar' &&
                    field !== 'departmentAvatar' &&
                    field !== 'ownerInCompanies' &&
                    field !== 'department' &&
                    field !== 'action' &&
                    field !== 'registerDate' &&
                    field !== 'note' &&
                    field !== 'gus' &&
                    field !== 'gusConfirmed' &&
                    field !== 'hostIp' &&
                    field !== 'id' &&
                    field !== 'subId' &&
                    field !== 'createdAt' &&
                    field !== 'mainDepartmentId' &&
                    field !== 'lp'
                  "
                  class="flex-col"
                >
                  <p class="title">{{ $t(`tableData.${field}`) }}</p>
                  <p class="content">
                    {{ currentObject[field] === true ? "TAK" : currentObject[field] === false ? "NIE" : currentObject[field] }}
                  </p>
                </div>

                <div v-if="field === 'id'" class="flex-col">
                  <p class="title">{{ $t(`tableData.${field}`) }}</p>
                  <p>
                    {{ currentObject.subId }}
                  </p>
                </div>
                <div v-if="field === 'createdAt'" class="flex-col">
                  <p class="title">{{ $t(`tableData.${field}`) }}</p>
                  <p>{{ currentObject[field].substring(0, 10) }} <br />{{ currentObject[field].substring(11, 19) }}</p>
                </div>
                <div v-if="field === 'status'" class="flex-col">
                  <p class="title">{{ $t(`tableData.${field}`) }}</p>
                  <p>
                    {{ $t(`profile.statuses.${currentObject[field].toLowerCase()}`) }}
                  </p>
                </div>
                <div v-if="field === 'type'" class="flex-col">
                  <p class="title">{{ $t(`tableData.${field}`) }}</p>
                  <p>
                    {{ $t(`profile.role.${currentObject[field].toLowerCase()}`) }}
                  </p>
                </div>
                <div v-if="field === 'userCompany'" class="flex-col">
                  <p class="title">{{ $t(`tableData.${field}`) }}</p>
                  <p>
                    {{ $t(`tableData.${currentObject[field][0].role.toLowerCase()}`) }}
                  </p>
                </div>
                <div v-if="field === 'addresses'" class="flex-col">
                  <p class="title">{{ $t(`tableData.${field}`) }}</p>
                  <div class="content">
                    1:{{ currentObject[field][0].companyCity }} {{ currentObject[field][0].companyAddress }},
                    {{ currentObject[field][0].companyZipCode }}
                  </div>
                </div>
                <div v-if="field === 'companyOwners'" class="flex-col company-owners">
                  <p style="font-size: 15px; font-weight: 600" class="title">
                    {{ $t(`tableData.${field}`) }}
                  </p>
                  <div class="content">
                    <p v-for="owner in currentObject[field]" :key="owner">
                      {{ owner.user ? owner.user.name + " " + owner.user.surname : "-" }}
                      <br />
                      {{ owner.user ? owner.user.email : "-" }}<br />
                    </p>
                  </div>
                </div>
                <div class="flex-col" v-if="field === 'ownerInCompanies'">
                  <p class="title">{{ $t(`tableData.${field}`) }}</p>
                  <div class="content">
                    <p v-for="company in currentObject[field]" :key="company.id">
                      {{ company.company.companyName }}
                    </p>
                  </div>
                </div>
                <div v-if="field === 'registerDate'" class="flex-col">
                  <p class="title">{{ $t(`tableData.${field}`) }}</p>
                  <p>
                    {{ company.company.registerDate }}
                  </p>
                </div>
                <div v-if="field === 'department'" class="flex-col">
                  <p class="title">{{ $t(`tableData.${field}`) }}</p>
                  <div class="content">
                    <p>{{ currentObject[field].name }}</p>
                  </div>
                </div>

                <div v-if="field === 'managers'" class="flex-col">
                  <p class="title">{{ $t(`tableData.${field}`) }}</p>
                  <div class="managers">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-users"
                    >
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <p>
                      {{ currentObject[field].length }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="return-button-col">
              <div @click="unfolded = false" class="ret-btn">
                <button class="arrow arrow-left">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#112baf"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-arrow-left"
                  >
                    <line x1="19" y1="12" x2="5" y2="12"></line>
                    <polyline points="12 19 5 12 12 5"></polyline>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </tbody>
    </div>
    <div class="" v-else></div>
  </table>
  <nav v-if="!noPagination" class="pagination">
    <ul style="width: 200px" class="pagination">
      <li style="width: 50px" @click="goToPreviousPage()" class="page-item">
        <a class="page-link" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li style="width: 100px" class="page-item">
        <a style="display: flex; justify-content: center; align-items: flex-end" class="page-link"
          >{{ currentPage }} <span style="margin-bottom: 2px" class="full-pages" v-if="totalPages">z {{ totalPages }}</span></a
        >
      </li>
      <li style="width: 50px" @click="goToNextPage()" class="page-item">
        <a class="page-link" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
  <div v-if="onPage" class="choose-number-of-records">
    <p>Wierszy na stronie</p>
    <div class="input-wrapper">
      <input @keyup="emit('newRecordsNumber', records)" type="text" v-model="records" />
      <img style="width: 10px; height: 10px; background: #112baf" src="@/assets/icons/pen-white.svg" alt="" />
    </div>
  </div>
  <div class="dialog" id="confirmation-dialog" aria-hidden="true">
    <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </button>
      <slot name="modal-content"></slot>
    </div>
  </div>
  <div class="dialog" id="confirmation-dialog2" aria-hidden="true">
    <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </button>

      <slot name="modal-content-note"></slot>
    </div>
  </div>
  <div class="dialog" id="gus-dialog" aria-hidden="true">
    <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </button>
      <div class="content gus-data">
        <div v-if="gusToDisplay === null" class="gus-table">Brak danych GUS</div>
        <div v-else>
          <div class="gus-table">
            <h1>Dane GUS</h1>
            <div class="table-row">
              <div class="category">Nazwa firmy:</div>
              <div class="value">
                {{ gusToDisplay.gusName }}
              </div>
            </div>
            <div class="table-row">
              <div class="category">Adres:</div>
              <div class="value">
                {{ gusToDisplay.gusPlace }} {{ gusToDisplay.gusStreet }}
                {{ gusToDisplay.gusPropertyNumber }}
                {{ gusToDisplay.gusApartmentNumber }}
              </div>
            </div>
            <div class="table-row">
              <div class="category">Kod pocztowy:</div>
              <div class="value">
                {{ gusToDisplay.gusZipCode }}
              </div>
            </div>
            <div class="table-row">
              <div class="category">NIP:</div>
              <div class="value">
                {{ gusToDisplay.gusNip }}
              </div>
            </div>
          </div>
          <div class="gus-table">
            <h1>Dane Firmy</h1>
            <div class="table-row">
              <div class="category">Nazwa firmy:</div>
              <div class="value">
                {{ companyDataInitial.companyName }}
              </div>
            </div>
            <div class="table-row">
              <div class="category">Adres:</div>
              <div class="value">
                {{ companyDataInitial.addresses[0].companyCity }}
                {{ companyDataInitial.addresses[0].companyAddress }}
              </div>
            </div>
            <div class="table-row">
              <div class="category">Kod pocztowy:</div>
              <div class="value">
                {{ companyDataInitial.addresses[0].companyZipCode }}
              </div>
            </div>
            <div class="table-row">
              <div class="category">NIP:</div>
              <div class="value">
                {{ gusToDisplay.gusNip }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog" id="provision-dialog" aria-hidden="true">
    <div @click="emit('refresh')" class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <button @click="emit('refresh')" data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </button>

      <div style="display: grid; place-items: center; gap: 30px">
        <h1 id="my-dialog-title" class="dialog__title">
          {{ $t("admin.profile.settings.set_provision") }}
        </h1>
        <div class="" style="display: flex; align-items: center; gap: 10px">
          <input oninput="validity.valid||(value='');" v-model="commission" class="provision-input" type="number" name="" id="" />
          <span style="font-size: 20px">%</span>
        </div>
        <primary-button @click="saveNewCommission" :red="true" :title="$t('generals.save')" />
      </div>
    </div>
  </div>
  <div class="dialog" id="ratings-dialog" aria-hidden="true">
    <div @click="emit('refresh')" class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <button  data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </button>

      <company-ratings-view v-if="selectedCompanyToShowRating" :company="selectedCompanyToShowRating"/>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use "@/css/small-elements/list.scss";

.return-col-wrapper {
  position: relative;
  width: 100px;
  height: 100px;
}

.return-col {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #112baf;
  width: 40px;
  height: 40px;
  border: none !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.field {
  position: relative;

  .edit-icon {
    width: 30px;
    height: 30px;
  }
  .center-icon {
    margin-left: 40px;
  }
  .hint {
    cursor: pointer;
    position: absolute;
    background: #112baf;
    color: #fff;
    padding: 2px 10px;
    font-weight: 600;
    border-radius: 5px;
    font-size: 12px;
    left: -85px;
    top: 0px;
    width: 90px;
  }
}
.full-pages {
  font-size: 10px;
}
.responsive-table {
  tbody {
    width: 100% !important;
    // display: flex !important;
    tr {
      width: 100%;
      td {
        width: 100%;
      }
      td div {
        overflow: visible !important;
      }
    }
  }
}
.actionField {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 30px;
    height: 30px;
    margin: 0 5px;
  }
}
.grid-cols {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important ;
  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr !important ;
  }
  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr !important ;
  }
  div {
    font-size: 12px;
    overflow-x: hidden !important;
    div {
      overflow-x: hidden !important;
      overflow-y: scroll;
      p {
        min-height: fit-content;
      }
      @media screen and (max-width: 500px) {
        width: 180px !important;
      }
    }
    @media screen and (max-width: 960px) {
      font-size: 12px;
    }
  }
}
svg {
  cursor: pointer;
}
.gus-confirmed {
  font-size: 13px;
  color: green;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}
.gus-not-confirmed {
  font-size: 13px;
  color: rgb(195, 61, 61);
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.gus-data {
  font-size: 20px;
}
.gus-table {
  margin-top: 10px;
  h1 {
    font-weight: 600;
    color: #112baf;
  }
  .table-row {
    display: flex;
    text-align: left;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
    padding: 10px 0;
    .category {
      width: 150px;
    }
    .value {
      font-weight: 600;
    }
  }
}
.company-owners {
  div {
    min-height: fit-content !important;
  }
}
.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
}
.control input {
  position: absolute;
  z-index: 2000;
  opacity: 0;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000000;
  border-radius: 0px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: #112baf;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #112baf;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}

.choose-number-of-records {
  width: 199px;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
  padding: 9px 7px;
  border-radius: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: #fff;
  margin-bottom: 15px;
  p {
    padding-right: 10px;
    padding-top: 5px;
  }
  .input-wrapper {
    background: #112baf;
    padding: 5px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input {
    max-width: 30px;
    color: #112baf;
    border: none;
    outline: none;
    padding: 1px 5px;
    font-weight: 500;
    border-radius: 5px;
    margin-right: 4px;
  }
}
.provision-input {
  font-size: 14px;
  padding: 5px 10px;
  background: #ffffff;
  border: 1px solid #a2a2a2;
  border-radius: 7px;
  &:focus {
    outline: none;
  }
}
</style>
<script>
import "@/css/small-elements/custom-checkbox.scss";
import { ref } from "vue";
import { computed, onMounted, inject, watchEffect, onBeforeUnmount } from "@vue/runtime-core";
import { useCompanyStore } from "@/stores/company.store";
import alertHelper from "@/helpsers/alert.helper";
import { useHelpersStore } from "@/stores/helpers.store";
import { storeToRefs } from "pinia";
import ActionButtons from "@/components/admin/ActionButtons.vue";
import { useChatStore } from "@/stores/chat.store";
import ApiHelper from "@/helpsers/api.helper";
import { useUsersLogiplannerStore } from "@/stores/admin/users/logiplanner.store";
import router from "@/router";
import storeCache from "@/js/storeCache";
import i18n from "@/i18n";
import PhoneNumberChekckbox from "@/components/common/PhoneNumberChekckbox.vue";
import PrimaryButton from "@/components/common/PrimaryButton.vue";
import { useFactoringStore } from "@/stores/factoring.store";
import { useInitAdminStore } from "@/stores/admin/init/init.admin.store";
import CompanyRatingsView from '@/components/admin/CompanyRatingsView'
export default {
  components: { ActionButtons, PhoneNumberChekckbox, PrimaryButton, CompanyRatingsView },
  props: [
    "data",
    "modalAction",
    "editField",
    "workers",
    "currentPage",
    "noPagination",
    "goFurther",
    "ratio",
    "isDocument",
    "actionButtons",
    "totalPages",
    "userType",
    "newTableSorting",
    "onPage",
  ],
  emits: ["edit", "next", "prev", "selected", "details", "downloadFile", "removeFile", "newRecordsNumber", "refresh"],
  setup(props, { emit }) {
    const factoringStore = useFactoringStore();
    const company = useCompanyStore();
    const store = useUsersLogiplannerStore();
    let chatStore = useChatStore();
    let records = ref(props.onPage);
    let { allChats } = storeToRefs(chatStore);
    let keys = ref([]);
    let dataCut = ref([]);
    let alert = inject("alert");
    let screenWidth = ref(window.innerWidth);
    let currentObject = ref({});
    let unfolded = ref(false);
    let commission = ref(0);
    let hiddenId = ref("");
    let columnsOverload = ref(false);
    const confirmationDialog = ref("");
    const confirmationDialog2 = ref("");
    const gusDialog = ref("");
    const ratingsDialog = ref("");
    const provisionDialog = ref("");
    let loading = ref(false);
    const helpersStore = useHelpersStore();
    const { closeModalEmit } = storeToRefs(helpersStore);
    let gusToDisplay = ref(null);
    let companyDataInitial = ref(null);
    let companyForFactoring = ref("");
    let hover = ref([false, false, false, false, false]);
    const documentData = ref(props.data.isDocument ? [...props.data.data] : []);
    const initStore = useInitAdminStore();
    const { initDepartmentId } = storeToRefs(initStore);
    let selectedCompanyToShowRating = ref()
    onMounted(() => {
      if (storeCache.get("hiddenArrowId")) {
        hiddenId.value = storeCache.get("hiddenArrowId");
        storeCache.remove("hiddenArrowId");
      }
      adaptKeys();
      ratingsDialog.value = new A11yDialog(document.querySelector("#ratings-dialog"));
      provisionDialog.value = new A11yDialog(document.querySelector("#provision-dialog"));
      confirmationDialog.value = new A11yDialog(document.querySelector("#confirmation-dialog"));
      confirmationDialog2.value = new A11yDialog(document.querySelector("#confirmation-dialog2"));
      gusDialog.value = new A11yDialog(document.querySelector("#gus-dialog"));
      commission.value = storeCache.get("currentUser").defaultFactoringCommission;
    });
    watchEffect(async () => {
      if (closeModalEmit.value) {
        confirmationDialog.value.hide();
        helpersStore.stopHideModal();
      }
    });
    const adaptKeys = () => {
      if (props.data) {
        keys.value = Object.keys(props.data[0]);
        if (props.data[0].company) keys.value.splice(keys.value.indexOf("company"), 1);

        if (props.data[0].companyId) keys.value.splice(keys.value.indexOf("companyId"), 1);

        if (props.data[0].companyOwner) {
          columnsOverload.value = true;
          keys.value.splice(keys.value.indexOf("companyOwner"), 1);
        }
        if (props.data[0].ownerInCompanies) {
          columnsOverload.value = true;
          keys.value.splice(keys.value.indexOf("ownerInCompanies"), 1);
        }
        if (props.data[0].type && !props.userType) {
          columnsOverload.value = true;
          keys.value.splice(keys.value.indexOf("type"), 1);
        }
        //TUTAJ TRZEBA BEDZIE DODAC WARUNEK NA ADMINA
        if (props.data[0].id) {
          keys.value.splice(keys.value.indexOf("id"), 1);
        }
        keys.value.splice(keys.value.indexOf("gus"), 1);
      }
      if (keys.value.length > 5) {
        let keysLeft = 5;
        let tmp = [];
        tmp.push("lp");
        keysLeft--;
        tmp.push("companyName");
        keysLeft--;
        tmp.push("subId");
        keysLeft--;
        if (props.userType) {
          tmp.push("createdAt");
          keysLeft--;
        }
        tmp.push("gusConfirmed");
        keysLeft--;
        if (!props.userType) {
          tmp.push("type");
          keysLeft--;
        }
        tmp.push("note");
        keysLeft--;
        if (props.userType === "FACTORY") {
          tmp.push("factoring");
          keysLeft--;
        }

        for (let i = 0; i < keysLeft; i++) {
          if (tmp.includes(keys.value[i])) keysLeft++;
          else tmp.push(keys.value[i]);
        }
        keys.value = tmp;
        columnsOverload.value = true;
      }

      if (screenWidth.value < 480) {
        let tmp = "";
        if (keys.value.includes("email")) {
          tmp = "email";
        } else if (keys.value.includes("name")) {
          tmp = "name";
        } else if (keys.value.includes("companyName")) {
          tmp = "companyName";
        } else if (keys.value.includes("city")) {
          tmp = "city";
        } else {
          tmp = keys.value[0];
        }
        keys.value = [];
        keys.value.push(tmp);
      } else if (screenWidth.value < 1000) {
        let tmp = keys.value[0];
        let tmp2 = keys.value[keys.value.length - 1];
        keys.value = [];
        keys.value.push(tmp);
        keys.value.push(tmp2);
      } else if (screenWidth.value < 1300) {
        let tmp = keys.value[0];
        let tmp2 = keys.value[keys.value.length - 1];
        let tmp3 = keys.value[keys.value.length - 3];
        keys.value = [];
        keys.value.push(tmp);
        keys.value.push(tmp2);
        keys.value.push(tmp3);
      } else if (screenWidth.value < 1320) {
        keys.value = keys.value.filter((elem) => elem !== "type");
      }
    };

    window.addEventListener("resize", () => {
      screenWidth.value = window.innerWidth;
      adaptKeys();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", () => {
        screenWidth.value = window.innerWidth;
        adaptKeys();
      });
    });

    const unfoldDetails = (user) => {
      if (unfolded.value === true) {
        unfolded.value = false;
      } else {
        currentObject.value = user;
        unfolded.value = true;
      }
      let head = window.document.getElementById("table-head");
      let body = window.document.getElementById("unfolded-wrapper");
      body.style.maxWidth = head.offsetWidth.toString() + "px";
    };

    const onEditField = (id) => {
      emit("edit", id);
    };
    const goToPreviousPage = () => {
      emit("prev");
    };
    const goToNextPage = () => {
      emit("next");
    };
    const userSelected = (user) => {
      confirmationDialog.value.show();
      emit("selected", user);
    };
    const userSelected2 = (user) => {
      confirmationDialog2.value.show();
      emit("selected", user);
    };
    const goToDetails = (obj) => {
      if (obj.department) {
        emit("details", obj.department.id);
      } else {
        emit("details", obj.id);
      }
    };
    const downloadFile = (fileId, fileName) => {
      emit("downloadFile", {
        id: fileId,
        name: fileName,
      });
    };
    const removeFile = (fileId) => {
      confirmationDialog.value.show();
      emit("removeFile", fileId);
    };

    const getAllKeysExceptId = (obj) => {
      let k = Object.keys(obj);
      if (k.includes("id")) {
        k.splice(k.indexOf("id"), 1);
      }
      keys.value.forEach((key) => {
        if (k.includes(key)) {
          k.splice(k.indexOf(key), 1);
        }
      });
      return k;
    };
    const newFactoringRequest = async (company, add) => {
      if (add) {
        companyForFactoring.value = company;
        provisionDialog.value.show();
      } else {
        await factoringStore.removeCompanyFactoring(company.id);
        emit("refresh");
      }
    };
    const saveNewCommission = async () => {
      await factoringStore.markCompanyFactoring(companyForFactoring.value.id, commission.value);
      emit("refresh");
      provisionDialog.value.hide();
    };

    const parseLongString = (string) => {
      return string;
    };
    const goToChat = async (user) => {
      if (!initDepartmentId.value) return;
      await chatStore.fetchChatList(initDepartmentId.value);
      if (allChats.value) {
        const found = allChats.value.find((chat) => chat?.otherSideId === user.mainDepartmentId);
        if (found) {
          router.push(
            `/admin/chat?id=${found.id}&monitoryId=${found.monitoryNoteId}&name=${found.otherSideName}&departmentId=${found.departmentId}&otherSideId=${found.otherSideId}`
          );
          return;
        }
      }
      if (user.mainDepartmentId) {
        const obj = {
          alert: alert,
          messageType: "TEXT",
          message: "INITIATION",
          departmentsIds: [user.mainDepartmentId],
        };
        storeCache.set("redirectToChat", user.mainDepartmentId);
        await store.postGroupMessageNoAlerts(obj);
        await chatStore.fetchChatList(ApiHelper.returnAdminChatId());
        if (allChats.value) {
          const found = allChats.value.find((chat) => chat.otherSideId === user.mainDepartmentId);
          if (found) {
            router.push(
              `/admin/chat?id=${found.id}&monitoryId=${found.monitoryNoteId}&name=${found.otherSideName}&departmentId=${found.departmentId}&otherSideId=${found.otherSideId}`
            );
            return;
          }
        }
      } else {
        alertHelper.show(alert, i18n.global.t("generals.error"), i18n.global.t("snackbars.chat_create"), "error");
      }
    };
    const sortDataByKey = (key, type) => {
      hiddenId.value = `arrow_${key}_${type}`;
      storeCache.set("hiddenArrowId", hiddenId.value);
      if (key === "lp") {
        tableData.value = tableData.value.reverse();
      } else {
        let result = key;
        if (key === "monitoryNoteStatus") result = "status";

        emit("newTableSorting", `&sort_by=${result}_${type === "ascending" ? "ASC" : "DESC"}`);
      }
    };

    const handleShowCompanyRating = (company) =>{
      selectedCompanyToShowRating.value = company;
      ratingsDialog.value.show()
    }

    return {
      keys,
      sortDataByKey,
      dataCut,
      userSelected,
      userSelected2,
      unfoldDetails,
      unfolded,
      currentObject,
      screenWidth,
      columnsOverload,
      onEditField,
      goToPreviousPage,
      goToNextPage,
      goToDetails,
      loading,
      props,
      documentData,
      downloadFile,
      removeFile,
      gusDialog,
      gusToDisplay,
      confirmationDialog2,
      hover,
      companyDataInitial,
      goToChat,
      router,
      parseLongString,
      getAllKeysExceptId,
      emit,
      records,
      newFactoringRequest,
      commission,
      saveNewCommission,
      hiddenId,
      handleShowCompanyRating,
      selectedCompanyToShowRating
    };
  },
};
</script>
