<template>
  <div v-if="!loader" id="step" class="step step-2">
    <!-- <img v-else @mouseenter="show = true" @mouseleave="show = false" class="info-icon" src="@/assets/icons/inf.png" alt="" /> -->

    <!-- <h1 class="title-with-underline">
      {{ $t("monitary_notes.step_one_title") }}
    </h1> -->
    <div v-if="width>1000 && calendarService.checkIfCalendarHasAtLeastOneDayAvailable(calendarData)" class="department-name-info"> <span style="color:#A1A1A1;"> {{ $t("tableData.warehouse") }}:</span> <br/>{{ pickedWarehouse.name }}</div>
    <div v-if="show" class="for-warehouse">
      <p class="bolder">{{ `${$t("monitary_notes.warehouse_chosen")}:` }}</p>
      <p class="underline">{{ pickedWarehouse.name }}</p>
      <p>{{ pickedWarehouse.city }}</p>
      <p>{{ pickedWarehouse.address }}</p>
      <p>{{ pickedWarehouse.zipCode }}</p>
  </div>
    <string-switcher
    style="margin-top: 30px;"
      @next="nextMonth"
      @previous="previousMonth"
      :string="$t(`months.${monthFromNumber(currentMonth)}`) + ' ' + calculateYear(currentMonth)"
    />
    <v-calendar
      v-if="calendarService.checkIfCalendarHasAtLeastOneDayAvailable(calendarData)"
      class="custom-calendar custom-calendar-smaller max-w-full custom-calendar-driver"
      is-expanded
      trim-weeks
      ref="calendar"
      :first-day-of-week="2"
      :locale="locale"
      :masks="{
          weekdays:'WWW'
        }"
    >
      <template v-slot:day-content="{ day }">
        <div
          class="custom-day custom-day-smaller"
          :class="
            calendarService.isToday(day)
              ? 'today-day'
              : calendarService.isPassedDate(day.id)
              ? 'passed-day turn-cursor-off'
              : 'custom-day'
          "
        >
        <!-- <div  v-if="pickedDay?.id === day.id && pickedHour"></div> -->

        <span class="number" :class="pickedDay?.id === day.id && pickedHour ? 'number-picked' : ''">{{ day.day }}</span>

        <span v-if="calendarData[day.day - 1] && (pickedDay?.id !== day.id || !pickedHour)" class="description description-steps" style="justify-content: center">
            <div
              @click="requestCalendar(day)"
              v-if="
                limitReached(calendarData[day.day - 1]) ||
                (calendarService.computeSlotCapacity(calendarData[day.day - 1] && calendarData[day.day - 1].takenAvailabilitiesNumber, calendarData[day.day - 1].availabilitiesNumber) === -1 &&
                  !calendarData[day.day - 1].unlimitedMonitoryNotes)
              "
   
            >
             
            </div>
            <p v-else class="hours" style="font-weight: 700; font-size:10px; margin-bottom:10px">
              {{ calendarData[day.day - 1] &&  dayjs(calendarData[day.day - 1]?.startHour).format("HH:mm")  + "-" +   dayjs(calendarData[day.day - 1]?.endHour).format("HH:mm")  }}
            </p>
            <p class="description-slots">
              <div
                v-if="
                  limitReached(calendarData[day.day - 1]) ||
                  (calendarService.computeSlotCapacity(calendarData[day.day - 1] && calendarData[day.day - 1].takenAvailabilitiesNumber, calendarData[day.day - 1].availabilitiesNumber) ===
                    -1 &&
                    !calendarData[day.day - 1].unlimitedMonitoryNotes)
                "
                :disabled="true"
                type="div"
                class="unavailable-day"
                style="margin-bottom:10px"
              >
                {{ $t("generals.unavailable").toUpperCase() }}
              </div>
              <button
                v-else
                @click="(pickedHour = ''), (chosenTmpHour =''), dayPicked(day)"
                :disabled="calendarService.isPassedDate(day)"
                type="button"
                class="btn btn-success calendar-button"
              >
                {{ $t("generals.add") }}
              </button>
              <div
              @click="requestCalendar(day)"
              v-if="
                (limitReached(calendarData[day.day - 1]) ||
                (calendarService.computeSlotCapacity(calendarData[day.day - 1] && calendarData[day.day - 1].takenAvailabilitiesNumber, calendarData[day.day - 1].availabilitiesNumber) === -1 &&
                  !calendarData[day.day - 1].unlimitedMonitoryNotes) )&& !calendarService.isPassedDate(day)
              "
              class="additional-day-action"
            >
            <button
                type="button"
                style="background: #E5E8F6; color:#808080;"
                class="btn  calendar-button calendar-button--large"
              >
                {{ $t('profile.calendar.calendarHint') }}
              </button>
          
            </div>
            </p>
          </span>
          <span  v-else>
            <div class="unavailable-day" style="color:black">WYBRANO</div>
            <div class="unavailable-day" style="color:black; margin-bottom:5px">{{ pickedHour}}</div>
            <button
                type="button"
                @click="dayPicked(day)"
                style="background: #112BAF; color:#fff; margin-bottom:10px;"
                class="btn  calendar-button "
              >
                {{  $t('generals.change') }}
              </button>
          </span>
        
        </div>
      </template>
    </v-calendar>
    <div v-else style="padding-top: 20px;">
      <div class="missing-elements-text">
        {{ $t("profile.calendar.emptyCalendar") }}
        <primary-button
          :noSubmit="true"
          @click="handleShowCompleteMonthModal"
          style="margin-top: 30px"
          :title="'Wyślij prośbę o uzupełnienie'"
          :red="true"
          :filled="true"
        ></primary-button>
      </div>
    </div>
    <!-- <div id="pick-hour-section" v-if="timeWindowsAvailable.length > 0" class="picker-wrapper-monitory-two">
      <p class="picker-label">{{ $t("monitary_notes.hour") }}</p>
      <custom-picker @changedOption="(e) => (pickedHour = e)" :options="timeWindowsAvailable" :prePicked="pickedHour" :isTime="true" />
    </div> -->
    <div  class="buttons" >
      <primary-button @click="emit('back')" :red="true" :filled="false" :title="'Wstecz'" />
      <primary-button v-if="timeWindowsAvailable.length > 0" @click="onSubmit" :red="true" :filled="true" :title="$t('monitary_notes.next')" />
    </div>
  </div>
  <div class="" v-if="loader">
  
  <loader-fitting-into-container></loader-fitting-into-container>
  </div>
  <div class="dialog" id="confirmation-dialog" aria-hidden="true">
    <div @click="monthly = false" class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <button @click="monthly = false" data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </button>
      <div class="calendar-request-modal">
        <div class="calendar-request-modal__title">{{ $t("calendar_request_modal.title") }}</div>
        <div class="calendar-request-modal__subtitle">{{ $t("calendar_request_modal.subtitle") }}</div>
        <div class="calendar-request-modal__checkboxes">
          <phone-number-chekckbox
            @checkChange="smsNotification = !smsNotification"
            :checked="smsNotification"
            :otherTitle="$t('calendar_request_modal.checkbox1')"
          />
          <phone-number-chekckbox
            @checkChange="mailNotification = !mailNotification"
            :checked="mailNotification"
            :otherTitle="$t('calendar_request_modal.checkbox2')"
          />
        </div>

        <primary-button @click="sendRequest" :title="$t('calendar_request_modal.button')" :red="true" :filled="true" />
      </div>
    </div>
  </div>
  <div class="dialog" id="choose-hours-dialog" aria-hidden="true">
    <div @click="monthly = false" class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <button @click="monthly = false" data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </button>
      <div class="">
        <div class="calendar-request-modal__title">{{$t("monitary_notes.hour") }}</div>
        <div  class="hours-wrapper">  <div v-for="timeWindow in timeWindowsAvailable" :key="timeWindow"><hour-choosable-button  @chosen-hour="(e) => onNewTmpHourChosen(e)" :chosen="chosenTmpHour == timeWindow" :data="timeWindow" :cropped="true"></hour-choosable-button></div></div>
        <div class="missing-elements-text" style="margin-bottom: 50px;" v-if="timeWindowsAvailable.length === 0">{{ $t("monitary_notes.no_free_time_windows") }}</div>
        <primary-button style="margin-top:auto; margin-left:auto;" @click="saveNewTimeWindow" :title="$t('generals.pick')" :red="true" :filled="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, inject, onBeforeMount } from "vue";
import UserLayout from "@/layouts/UserLayout";
import Menu from "@/components/Menu";
import { useCalendarStore } from "@/stores/calendar.store";
import CustomPicker from "@/components/common/CustomPicker.vue";
import Stepper from "@/components/common/Stepper.vue";
import { storeToRefs } from "pinia";
import i18n from "@/i18n";
import storeCache from "@/js/storeCache";
import AlertHelper from "@/helpsers/alert.helper";
import StringSwitcher from "@/components/common/StringSwitcher";
import router from "@/router";
import { monthFromNumber, calculateYear, calculateYearAccurate } from "@/js/utils";
import { convertDateToStandardFormat } from "@/js/date.utils";
import PrimaryButton from "@/components/common/PrimaryButton";
import PhoneNumberChekckbox from "@/components/common/PhoneNumberChekckbox.vue";
import UniversalIconWithHint from "../common/UniversalIconWithHint.vue";
import { useI18n } from "vue-i18n";
import HourChoosableButton from "./HourChoosableButton.vue";
import breakpointsHelper from "@/helpsers/breakpoints.helper";
import dayjs from "dayjs";
import CalendarService from '@/services/calendar/calendar.service'
import LoaderFittingIntoContainer from "../common/LoaderFittingIntoContainer.vue";
import { parseError } from "@/js/error";
import { useCompanyStore } from "@/stores/company.store";
export default {
  name: "StepTwo",
  emits: ["scroll"],
  components: {
    Menu,
    UserLayout,
    CustomPicker,
    Stepper,
    StringSwitcher,
    PrimaryButton,
    PhoneNumberChekckbox,
    UniversalIconWithHint,
    HourChoosableButton,
    LoaderFittingIntoContainer
  },
  emits: ["next", "back"],
  setup(props, { emit }) {
    let loader = ref(true);
    const calendar = ref(null);
    const calendarService = new CalendarService()
    const calendarStore = useCalendarStore();
    const confirmationDialog = ref("");
    const chooseHourDialog = ref("");
    let monthly = ref(false);
    let pickedDay = ref({});
    let pickedHour = ref("");
    let pickedWarehouse = ref({});
    let currentMonth = ref(0);
    const emitter = inject("emitter");
    let alert = inject("alert");
    let show = ref(false);
    let timeWindowsAvailable = ref([]);
    let smsNotification = ref(false);
    let mailNotification = ref(false);
    let chosenRequestDay = ref("");
    let chosenTmpHour = ref('')
    const companyStore = useCompanyStore();
    const { width, } = breakpointsHelper();
    const { locale } = useI18n();
    const { calendarData, timeWindows } = storeToRefs(calendarStore);
    onBeforeMount(async () => {
      router.replace({ query: { step: "2" } });
      if (storeCache.get("monitaryDate")) {
        let initial = storeCache.get("monitaryDate");
        pickedDay.value = initial.day;
        if(initial.pickedHour)pickedHour.value = initial.pickedHour;
        if (storeCache.get("monitaryData")) {
          if (initial.day.month) {
            pickedWarehouse.value = storeCache.get("monitaryData").warehouse;
            let now = new Date();
            currentMonth.value = now.getMonth() + 1;
            currentMonth.value = initial.day.month;
            await fetchCalendar();
            loader.value = false;
            setTimeout(async () => {
              await adjustCalendar();
            }, 200);
            chosenTmpHour.value = pickedHour.value;
            dayPicked(pickedDay.value);
          }
        }
      }
    });
    onMounted(async () => {
      // @ts-ignore
      confirmationDialog.value = new A11yDialog(document.querySelector("#confirmation-dialog"));
      // @ts-ignore
      chooseHourDialog.value = new A11yDialog(document.querySelector("#choose-hours-dialog"));

      if (storeCache.get("monitaryData") && !storeCache.get("monitaryDate")) {
        pickedWarehouse.value = storeCache.get("monitaryData").warehouse;
        let now = new Date();
        currentMonth.value = now.getMonth() + 1;
        await fetchCalendar();
        loader.value = false;
      }
    });

    const adjustCalendar = async () => {
      if (calendar.value !== null) {
        let month = currentMonth.value > 12 ? currentMonth.value - 12 : currentMonth.value;
        let year = parseInt(month) >= new Date().getMonth() + 1 ? new Date().getFullYear() : new Date().getFullYear() + 1;
        await calendar.value.move({
          month: parseInt(month),
          year: parseInt(year),
        });
      }
    };

    const fetchCalendar = async () => {
    try{
      loader.value= true;
      await calendarStore.getCalendarMonthly(
        pickedWarehouse.value.id,
        currentMonth.value > 12 ? currentMonth.value - 12 : currentMonth.value,
        calculateYear(currentMonth.value)
      );
      loader.value= false;
    }catch(e){
      console.log(e)
      loader.value= false;
     
    }

    };

    const nextMonth = async () => {
      currentMonth.value++;
      await fetchCalendar();
      await adjustCalendar();
    };
    const previousMonth = async () => {
      if (currentMonth.value === 1) {
        currentMonth.value = 12;
      } else currentMonth.value--;
      await fetchCalendar();
      await adjustCalendar();
    };

 
    const dayPicked = async (day) => {
      const found = calendarData.value.find((data)=> day.id === data.date)
      if(!found) return;
      timeWindowsAvailable.value = [];
      let excludedDay = storeCache.get("excludedDay");
      pickedDay.value = day;
      const currentTime = dayjs().format('HH:mm')
      const monitaryData = storeCache.get("monitaryData");
      const av=  await calendarStore.getAvailableTimewindowsForADay( pickedWarehouse.value.id, day.id, monitaryData?.department?.id);
      let tmpTimeWindows = av;

      tmpTimeWindows = tmpTimeWindows
        ?.filter((window) => {
          if (!excludedDay) return window;
          if (day.id !== excludedDay.day || window.startHour !== excludedDay.hour) return window;
        });

      let tmp = tmpTimeWindows
        ?.filter((window) => {
          if (pickedDay.value.id === convertDateToStandardFormat(new Date())) {
            if (dayjs(window.startHour).format('HH:mm') > currentTime) return window;
          } else return window;
        })
        ?.map((window) => {
          return dayjs(window.startHour).format("HH:mm")  + "-" + dayjs(window.endHour).format("HH:mm");
        })
        ?.filter((window) => {
          if (window !== undefined) return window;
        });
      timeWindowsAvailable.value = tmp;
      if (excludedDay) pickedHour.value = "";
      chooseHourDialog.value.show()
      // setTimeout(() => {
      //   storeCache.remove("excludedDay");
      //   emit("scroll", document.getElementById("pick-hour-section"));
      // }, 100);
    };
    const onSubmit = () => {
      if (pickedHour.value !== "") {
        let obj = {
          pickedHour: pickedHour.value,
          day: pickedDay.value,
        };
        storeCache.set("monitaryDate", obj);
        emit("next");
      } else {
        AlertHelper.show(alert, i18n.global.t("generals.error"), i18n.global.t("monitary_notes.errors.no_hour"), "error");
      }
    };
    const requestCalendar = async (day) => {
      const res = await companyStore.checkCompanyDocuments(storeCache.get("currentCompany")?.id);
      if(res){
        chosenRequestDay.value = day;
        confirmationDialog.value.show();
      }
    };
    const sendRequest = async () => {
      let response;
      if (monthly.value) {
        let formattedDay = `${calculateYearAccurate(currentMonth.value > 12 ? currentMonth.value - 12 : currentMonth.value)}-${
          currentMonth.value > 12 ? `0${currentMonth.value - 12}` : currentMonth.value < 10 ? "0" + currentMonth.value : currentMonth.value
        }-01`;
        response = await calendarStore.requestMonthFill(pickedWarehouse.value.id, {
          date: formattedDay,
          smsNotification: smsNotification.value,
          mailNotification: mailNotification.value,
        });
        monthly.value = false;
      } else {
        response = await calendarStore.requestDayFill(pickedWarehouse.value.id, {
          date: chosenRequestDay.value.id,
          smsNotification: smsNotification.value,
          mailNotification: mailNotification.value,
        });
      }

      if (response) {
        smsNotification.value = false;
        mailNotification.value = false;
        AlertHelper.show(alert, i18n.global.t("generals.success"), "Wysłano prośbę o uzupełnienie kalendarza", "success");
        confirmationDialog.value.hide();
      } else {
        AlertHelper.show(alert, i18n.global.t("generals.error"), "Wysłano już prośbę o uzupełnienie kalendarza na ten dzień/miesiąc.", "error");
      }
    };
    const sendMonthlyRequest = async (month) => {
      const response = await calendarStore.requestMonthFill(pickedWarehouse.value.id, {
        date: chosenRequestDay.value.id,
        smsNotification: smsNotification.value,
        mailNotification: mailNotification.value,
      });
      if (response) {
        AlertHelper.show(alert, i18n.global.t("generals.success"), "Wysłano prośbę o uzupełnienie kalendarza", "success");
        confirmationDialog.value.hide();
      } else {
        AlertHelper.show(alert, i18n.global.t("generals.error"), "Wysłano już prośbę o uzupełnienie kalendarza na ten dzień.", "error");
      }
    };

    const limitReached = (day) => {
      if (day.unloadingLimit || day.loadingLimit) {
        let type = storeCache.get("monitaryData").operationType.value;

        let filteredNotes = day.monitoryNotes.filter((note) => note.type === type);

        if (type === "LOADING") {
          return filteredNotes.length === parseInt(day.loadingLimit);
        }
        if (type === "UNLOADING") {
          return filteredNotes.length === parseInt(day.unloadingLimit);
        }
      }

      return false;
    };

    const onNewTmpHourChosen = (e) =>{
      chosenTmpHour.value = e;
    }
    const saveNewTimeWindow = () =>{
      pickedHour.value = chosenTmpHour.value;
      chooseHourDialog.value.hide()
    }

    const handleShowCompleteMonthModal = async () => {
      const res = await companyStore.checkCompanyDocuments(storeCache.get("currentCompany")?.id);
      if(res){
        monthly.value = true;
        confirmationDialog.value.show()
      }
    }
    return {
      calendar,
      limitReached,
      saveNewTimeWindow,
      onNewTmpHourChosen,
      sendMonthlyRequest,
      sendRequest,
      requestCalendar,
      onSubmit,
      dayPicked,
      timeWindowsAvailable,
      pickedHour,
      pickedWarehouse,
      pickedDay,
      currentMonth,
      monthFromNumber,
      calculateYear,
      calendarData,
      nextMonth,
      calendarService,
      previousMonth,
      loader,
      show,
      emit,
      width,
      smsNotification,
      mailNotification,
      confirmationDialog,
      monthly,
      locale,
      chooseHourDialog,
      chosenTmpHour,
      dayjs,
      handleShowCompleteMonthModal
    };
  },
};
</script>
<style lang="scss" scoped>
@use "@/css/monitary-notes/step-two.scss";
@use "@/css/monitary-notes/steps.scss";
@use "@/css/small-elements/calendar-request-modal.scss";
.step {
  display: grid;
  place-items: center;
  margin: auto;
  width: fit-content;
}

.turn-cursor-off {
  pointer-events: none !important;
}
.buttons {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 100px;
  justify-content: center;
  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
}
.btn-grey {
  background: #d3e0f4;
  color: black;
  @media screen and (max-width: 960px) {
    font-size: 5px !important;
  }
}
.calendar-button-unavailable {
  @media screen and (max-width: 600px) {
    display: none;
  }
}
.unavailable-day{
  color: var(--grey-neutrals-400, #A1A1A1);
  font-size: 10px;
  font-weight: 700;
  @media screen and (max-width: 960px) {
    font-size: 7px;
    font-weight: 700;
  }
  @media screen and (max-width: 600px) {
    display:none;
  }
}
.hours-wrapper{
  display: flex;
  flex-wrap: wrap;
  gap:20px;
  justify-content: center;
  margin: 40px 0;
}
.department-name-info {
    font-weight: 700;
    font-size: 18px;
    text-align: right;
    color: var(--grey-neutrals-900, #1F222F);
    position: absolute;
    right: 15px;
    top: -20px;
    @media screen and (max-width: 800px) {
      top: -40px;
      right: -50px;
    }
  }
</style>
