export function prevPage(page:any) {
  if (page > 1) {
    page = page - 1;
  }
  return page;
}

export function nextPage(page:any, pagesNumber:any) {
  if (page < pagesNumber) {
    page = parseInt(page) + 1;
  }
  return page;
}

export function setCurrentQueryParamsString(page:any, onPage:any) {
  let params = "";
  if (page >= 1) {
    if (params) params += "&";
    params += `page=${encodeURIComponent(page)}`;
  }
  if (onPage != 25 && onPage > 0) {
    if (params) params += "&";
    params += `onPage=5`;
  }

  return params;
}
