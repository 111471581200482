import RegisterView from "@/views/auth/RegisterView.vue";
import LoginView from "@/views/auth/LoginView.vue";
import ForgotPasswordView from "@/views/auth/ForgotPasswordView.vue";
import ResetPasswordView from "@/views/auth/ResetPasswordView.vue";
import VerificationView from "@/views/auth/VerificationView.vue";
import RegisterConfirmationView from "@/views/auth/RegisterConfirmationView.vue";
import TestView from "@/views/user/TestView.vue";
import { useAuthStore } from "@/stores/auth.store";
import GuestLayout from "@/layouts/GuestLayout.vue";
import i18n from "@/i18n";
import WebsiteLayout from "@/layouts/WebsiteLayout.vue";
import Test from "@/views/auth/Test.vue";
const routes = [
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: {
      title: i18n.global.t("routes.auth.register"),
      layout: {
        component: GuestLayout,
        props: {
          displayLogo: true,
          additionalClass: "large",
        },
      },
    },
  },
  {
    path: "/dodaj-do-firmy",
    name: "register-only-user",
    component: RegisterView,
    meta: {
      title: i18n.global.t("routes.auth.register"),
      layout: {
        component: GuestLayout,
        props: {
          displayLogo: true,
        },
      },
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: i18n.global.t("routes.auth.login"),
      layout: {
        component: GuestLayout,
        props: {
          displayLogo: true,
        },
      },
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
    meta: {
      title: i18n.global.t("routes.auth.forgot_password"),
      layout: {
        component: GuestLayout,
        props: {
          additionalClass: "auth__box--reset",
          displayLogo: false,
        },
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: {
      beforeRouteEnter(to:any, from:any, next:any) {
        const authStore = useAuthStore();
        authStore.logout();
      },
    },
  },
  {
    path: "/resetuj-haslo/:token",
    name: "reset-password",
    component: ResetPasswordView,
    meta: {
      title: i18n.global.t("routes.auth.reset_password"),
      layout: {
        component: GuestLayout,
        props: {
          additionalClass: "auth__box--reset",
          displayLogo: false,
        },
      },
    },
  },
  {
    path: "/verification",
    name: "verification",
    component: VerificationView,
    meta: {
      title: i18n.global.t("routes.auth.verification"),
      layout: {
        component: GuestLayout,
        props: {
          additionalClass: "auth__box--reset",
          displayLogo: false,
        },
      },
    },
  },
  {
    path: "/potwierdzenie-rejestracji/:token",
    name: "confirm-registration",
    component: RegisterConfirmationView,
    meta: {
      title: i18n.global.t("routes.auth.confirm_registration"),
      layout: {
        component: GuestLayout,
        props: {
          displayLogo: true,
        },
      },
    },
  },
  {
    path: "/szczegoly-aukcji/:auctionToken",
    name: "test",
    component: TestView,
    meta: {
      title: "Szczegóły aukcji",
      layout: WebsiteLayout,
    },
  },
];

export default routes;
