import axios from "axios";
import AlertHelper from "@/helpsers/alert.helper";
import UserService from "@/services/user.service";
import { inject } from "vue";
import ApiHelper from "@/helpsers/api.helper";
import storeCache from "@/js/storeCache";
export const API_URL = ApiHelper.returnBackendUrl();
const token = storeCache.get("token");
let alert = inject("alert");

const instance: any = axios.create({
  baseURL: API_URL,
});

instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

instance.interceptors.response.use(
  (response: any) => {
    try {
      if (response.data && response.data.data) {
        response.data.data = response.data.data.map((element: any, index: number) => {
          if (response.data.meta) {
            element.lp = (response.data.meta.current_page - 1) * response.data.meta.per_page + (index + 1);
          }
          return element;
        });
      }
    } catch (e) {}
    return response;
  },
  (error: any) => {
    if (error && error.response && error.response.status && error.response.status === 401) {
      try {
        let user = storeCache.get("currentUser");
        if (user && user.role !== "ADMIN" && !storeCache.get("initial_reload")) {
          const userService = new UserService();
          userService.logout();
          if (error.response.data.type && error.response.data.type === "blocked") {
            storeCache.set("showOtherAlert", error.response.data.message);
          } else {
            storeCache.set("showAuthAlert", true);
          }
        }
      } catch (e) {
        console.log(e);
      }
    } else if (error && error.response && error.response.status && error.response.status === 500) {
      window.dispatchEvent(new CustomEvent("show-500-error"));
      return error;
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default instance;
