<template>
        <user-profile-settings />

        <!--        <a11y-dialog-->
<!--            id="passwordDialog"-->
<!--            @dialog-ref="assignDialogRef"-->
<!--            role="dialog"-->
<!--        >-->
<!--&lt;!&ndash;            @dialog-ref="dialog => assignDialogRef('passwordDialog', dialog)"&ndash;&gt;-->
<!--            <template v-slot:title>-->
<!--                <button-->
<!--                    class="dialog-close"-->
<!--                    aria-label="Close this dialog window"-->
<!--                    @click="closeDialog"-->
<!--                >-->
<!--                    <img src="@/assets/icons/dialog-close.svg" alt="Dialog close" />-->
<!--                </button>-->
<!--                <div class="dialog__name">Zmiana hasła</div>-->

<!--            </template>-->
<!--                <form class="dialog-form">-->
<!--                    <div class="control control&#45;&#45;input">-->
<!--                        <label for="oldPassword" class="control__label">Stare hasło</label>-->
<!--                        <input-->
<!--                            type="password"-->
<!--                            id="oldPassword"-->
<!--                            placeholder="Wpisz stare hasło"-->
<!--                        />-->
<!--                        <div class="control__hint">-->
<!--                            Nie pamiętasz hasła? <a href="">Przypomnij hasło przez e-mail</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="control control&#45;&#45;input">-->
<!--                        <label for="newPassword" class="control__label">Nowe hasło</label>-->
<!--                        <input-->
<!--                            type="password"-->
<!--                            id="newPassword"-->
<!--                            placeholder="Wpisz nowe hasło"-->
<!--                        />-->
<!--                    </div>-->
<!--                    <div class="control control&#45;&#45;input">-->
<!--                        <label for="confirmPassword" class="control__label"-->
<!--                        >Potwierdź nowe hasło</label-->
<!--                        >-->
<!--                        <input-->
<!--                            type="password"-->
<!--                            id="confirmPassword"-->
<!--                            placeholder="Potwierdź nowe hasło"-->
<!--                        />-->
<!--                    </div>-->
<!--                </form>-->
<!--                <div class="dialog__buttons dialog__buttons&#45;&#45;center">-->
<!--                    <button @click="closeDialog" class="dialog__button">Anuluj</button>-->
<!--                    <button-->
<!--                        class="dialog__button dialog__button&#45;&#45;alt"-->
<!--                        data-a11y-dialog-show="confirmation-dialog"-->
<!--                    >-->
<!--                        Zapisz-->
<!--                    </button>-->
<!--                </div>-->
<!--        </a11y-dialog>-->



</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import {ref, onMounted, inject, watch} from "vue";
import axios from "axios";
import authHelper from "@/helpsers/auth.helper";
import {useField, useForm} from "vee-validate";
import * as yup from "yup";
import i18n from "@/i18n";
import {useI18n} from "vue-i18n";
import ApiHelper from "@/helpsers/api.helper";
import UserProfileSettings from "@/components/admin/userProfileSettings";

export default {
    name: "MyProfile",
    components: {
        UserProfileSettings,
        AdminLayout,
    },
    setup() {

    }

}
</script>

<style scoped>

</style>