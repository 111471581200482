<template>
  <div class="wrapper" v-if="currentWarehouses && currentWarehouses.length > 0">
    <!-- <h1 class="title-with-underline">
        {{ $t("profile.add_user.title") }}
      </h1> -->
    <div class="inside">
      <div class="assign-department-wrapper">
        <h2>
          {{ $t("profile.add_user.assign_department") }}
        </h2>
        <div class="assign-department">
          <div v-for="d in currentWarehouses" :key="d.id" class="department">
            <input @change="toggleDepartment(d)" type="checkbox" :checked="checkIfDepartmentAdded(d)" />
            <p class="name-element" @click="toggleDepartment(d)" style="cursor: pointer">{{ d.name }}</p>
          </div>
        </div>
        <div v-show="showDepartmentError && chosenDepartments.length === 0" class="error-departments">
          <div class="control__error-inner">{{ $t("missings.missing_department_driver") }}</div>
        </div>
      </div>
      <div class="large_box">
        <h4>{{ $t("profile.add_user.enter_driver_data") }}</h4>
        <form>
          <div class="">
            <div class="form-positioning">
              <div class="control_large control control--input">
                <label for="address" class="control__label">{{ $t("generals.fullName") }}</label>
                <input type="text" id="address" v-model="name" />
                <div v-show="errors.name" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.name }}
                  </div>
                </div>
              </div>
              <div class="control_large control control--input">
                <label for="address" class="control__label">{{ $t("admin.profile.email") }}</label>
                <input type="text" id="address" v-model="email" />
                <div v-show="errors.email" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.email }}
                  </div>
                </div>
              </div>
              <div class="control_large control control--input">
                <label for="address" class="control__label">{{ $t("profile.add_user.phone") }}</label>
                <input @keyup="phoneNumber = phoneNumber.replace(/[^0-9+]+/g, '')" id="phone-number-input" type="text" v-model="phoneNumber" />
                <div v-show="errors.phoneNumber" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.phoneNumber }}
                  </div>
                </div>
              </div>
              <div class="control_large control control--input">
                <label for="address" class="control__label">{{ $t("monitary_notes.driverDocumentShort") }}</label>
                <input type="text" id="address" v-model="document" />
                <div v-show="errors.document" class="control__error">
                  <div class="control__error-inner">
                    {{ errors.document }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="buttons">
      <button class="button-outline" @click.prevent="addNewUser">
        <template v-if="!router.currentRoute.value.query.edit">
          {{ $t("profile.add_user.add_driver") }}
        </template>
        <template v-else>
          {{ $t("generals.save") }}
        </template>
      </button>
    </div>
  </div>
  <div class="wrapper" v-else>
    <NoDepartmentScreen v-if="!loading" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import UserLayout from "@/layouts/UserLayout";
import Menu from "@/components/Menu";
import ChangePassword from "@/components/settings/ChangePassword";
import Privacy from "@/components/settings/Privacy";
import { useUserStore } from "@/stores/user.store";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { useI18n } from "vue-i18n";
import { useHelpersStore } from "@/stores/helpers.store";
import { inject } from "vue";
import storeCache from "@/js/storeCache";
import AlertHelper from "@/helpsers/alert.helper";
import router from "@/router";
import { Department, useDepartmentStore } from "@/stores/department.store";
import { typeOfCompany } from "@/js/utils";
import { storeToRefs } from "pinia";
import { useDriverStore } from "@/stores/driver.store";
import NoDepartmentScreen from "@/components/common/NoDepartmentScreen.vue";

export default {
  name: "AddUserView",
  components: { Privacy, ChangePassword, Menu, UserLayout, NoDepartmentScreen },
  setup() {
    const userStore = useUserStore();
    const { justAddedWorker } = storeToRefs(userStore);
    const departmentStore = useDepartmentStore();
    const driverStore = useDriverStore();
    const helpersStore = useHelpersStore();
    let { currentWarehouses } = storeToRefs(departmentStore);
    let width = ref(window.innerWidth);
    let loading = ref(false);
    let showDepartmentError = ref(false);
    let showUsersError = ref(false);
    let alert = inject("alert");

    let chosenDepartments = ref([]);
    onMounted(async () => {
      let id = storeCache.get("currentCompany")?.id;
      if (id) {
        await departmentStore.fetchAllWarehouses({
          id: id,
        });
      }
      let initial = storeCache.get("driver");
      if (initial && router.currentRoute.value.query.edit) {
        email.value = initial.email;
        name.value = initial.fullName;
        document.value = initial.documentNumber;
        phoneNumber.value = initial.phone.replace("+48", "");
        chosenDepartments.value = initial.departments;
      } else {
        storeCache.remove("driver");
      }
    });
    const { t } = useI18n();
    const { handleSubmit, errors, handleReset } = useForm({
      validationSchema: yup.object().shape({
        // name: yup.string().trim().required(t("register.validation.name")),
        email: yup
          .string()
          .trim()
          .required(t("register.validation.email"))
          .max(50, t("register.validation.max", { number: 50 }))
          .email(t("register.validation.email")),
        name: yup
          .string()
          .trim()
          .required(t("register.validation.required"))
          .matches(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-\s]+$/, t("register.validation.string"))
          .max(30, t("register.validation.max", { number: 50 })),
        phoneNumber: yup
          .string()
          .required(t("register.validation.required"))
          .matches(
            /^(?=(.{1,20})$)((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/,
            t("register.validation.phone")
          ),
        document: yup
          .string()
          .required(t("register.validation.required"))
          .max(30, t("register.validation.max", { number: 30 })),
      }),
      initialValues: {
        email: "",
        name: "",
        document: "",
        phoneNumber: "",
      },
    });
    const { value: name } = useField("name");
    const { value: document } = useField("document");
    const { value: phoneNumber } = useField("phoneNumber");
    const { value: email } = useField("email");

    const addNewUser = handleSubmit(async () => {
      {
        if (chosenDepartments.value.length > 0) {
          userStore.isLoading = true;
          const data = {
            email: email.value.trim(),
            fullName: name.value.trim(),
            documentNumber: document.value.trim(),
            phone: phoneNumber.value.trim(),
            departmentIds: chosenDepartments.value.map((department) => department.id),
          };
          if (!router.currentRoute.value.query.edit) {
            try {
              const res = await driverStore.addDriver(data);
              if (res === "409") {
                return;
              } else if (res) {
                AlertHelper.show(alert, "Kierowca dodany", "", "success");
                if (storeCache.get("returnToMonitary")) {
                  storeCache.remove("returnToMonitary");
                  router.push("/awizacja?step=3");
                } else if (router.currentRoute.value.query.withReturn) {
                  router.back();
                } else {
                  router.push({ name: "list-drivers" });
                }
              }
            } catch (e) {
              AlertHelper.show(alert, parseError(e), "", "error");
              userStore.isLoading = false;
            }
          } else {
            let initialObj = storeCache.get("driver");
            let driverId = initialObj.id;
            let departmentsToRemove = [];
            let departmentsToAdd = [];

            initialObj.departments.forEach((dep) => {
              if (!data.departmentIds.includes(dep.id)) {
                departmentsToRemove.push(dep.id);
              }
            });

            data.departmentIds.forEach((depId) => {
              let flag = true;
              initialObj.departments.forEach((dep) => {
                if (depId === dep.id) {
                  flag = false;
                }
              });

              if (flag) {
                departmentsToAdd.push(depId);
              }
            });

            if (departmentsToRemove.length > 0) {
              // //REMOVE DRIVER FROM PREVIOUS DEPARTMENTS
              await driverStore.removeDriverFromDepartments(departmentsToRemove, driverId);
              // //REMOVE DRIVER FROM PREVIOUS DEPARTMENTS
            }
            if (departmentsToAdd.length > 0) {
              // //ADD DRIVER TO NEW DEPARTMENTS
              await driverStore.addDriverToDepartments(departmentsToAdd, driverId);
              // //ADD DRIVER TO NEW DEPARTMENTS
            }

            delete data.departmentIds;
            data.id = driverId;
            if (initialObj.email === data.email) delete data.email;
            if (initialObj.fullName === data.fullName) delete data.fullName;
            if (initialObj.phone.replace("+48", "") === data.phone) delete data.phone;
            if (initialObj.documentNumber === data.documentNumber) delete data.documentNumber;

            //EDIT DRIVER DATA
            const response = await driverStore.editDriver(data);
            //EDIT DRIVER DATA
            if (response) {
              router.push({ name: "list-drivers" });
              storeCache.remove("driver");
            }
          }
        } else {
          showDepartmentError.value = true;
          AlertHelper.show(alert, "Przypisz kierowcę do oddziału", "Aby dodać kierowcę do firmy, musisz przypisać go do oddziału.", "error");
        }
      }
    });
    const toggleDepartment = (department) => {
      let currentD;
      let flag = false;
      chosenDepartments.value.forEach((dep) => {
        if (dep.id === department.id) {
          flag = true;
          currentD = dep;
        }
      });
      if (flag) {
        chosenDepartments.value.splice(chosenDepartments.value.indexOf(currentD), 1);
      } else {
        chosenDepartments.value.push(department);
      }
    };
    function validate(evt) {
      var theEvent = evt || window.event;

      // Handle paste
      if (theEvent.type === "paste") {
        key = event.clipboardData.getData("text/plain");
      } else {
        // Handle key press
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
      }
      var regex = /[0-9]|\./;
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }

    const checkIfDepartmentAdded = (d) => {
      let flag = false;
      chosenDepartments.value.forEach((department) => {
        if (department.id === d.id) flag = true;
      });
      return flag;
    };

    return {
      loading,
      checkIfDepartmentAdded,
      validate,
      email,
      errors,
      addNewUser,
      userStore,
      name,
      phoneNumber,
      currentWarehouses,
      width,
      chosenDepartments,
      showDepartmentError,
      toggleDepartment,
      typeOfCompany,
      showUsersError,
      router,
      document,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/css/other-screens/add-user-view.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  max-width: unset !important;
  overflow-y: auto;
  padding-bottom: 50px;
  @media screen and (max-width: 2200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 850px) {
    padding: 0 20px;
  }
  overflow-x: hidden;
  .inside {
    display: flex;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    margin: auto;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }
  }
  h2,
  h3,
  h4 {
    font-weight: 600;
    color: #112baf;
    font-size: 14px;
  }
}

.checkbox-wrapper {
  gap: 10px;
  flex-direction: column;
  align-items: start;
  margin-top: 20px;
  @media screen and (max-width: 575px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
.form-group label {
  font-size: 12px;
  font-weight: 400;
}

#exampleFormControlTextarea1 {
  max-width: 500px;
  font-size: 14px;
}
#exampleFormControlTextarea1:focus {
  outline: none !important;
}
.assign-department-wrapper {
  position: relative;
  top: 0px;
  width: 40%;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
  h2,
  h3,
  h4 {
    margin-top: 20px;
  }

  .assign-department {
    max-height: 250px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 1200px) {
      max-height: 150px;
    }

    .department {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin: 15px 0;
      @media screen and (max-width: 750px) {
        width: 300px;
        max-width: 300px;
        font-size: 10px;
      }
      .name-element {
        font-weight: 600;
      }
      p {
        margin: 0 0 0 10px;
      }
    }
  }
}
.toggles-box {
  margin-top: 30px;
  max-width: 500px;
  .flex-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.make-invisible {
  opacity: 0.1;
}

input[type="checkbox"],
input[type="radio"] {
  accent-color: #112baf;
}

.primary-label {
  font-weight: 600;
  color: #112baf;
  font-size: 14px;
}

.large_box {
  max-width: unset !important;
  min-height: unset !important;
  max-width: unset !important;
  width: 60%;
  min-width: 500px !important;
  h2,
  h3,
  h4 {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 1000px) {
    min-width: 400px !important;
  }
  @media screen and (max-width: 750px) {
    min-width: unset !important;
  }
  @media screen and (max-width: 630px) {
    padding: 20px 0;
    width: 90%;
  }
}

.form-switch {
  padding: 0;
}

.form-switch .form-check-input {
  width: 16px;
  background-image: unset;
}

form .form-positioning label {
  font-weight: 600;
  font-size: 12px;
  color: rgba(5, 24, 52, 0.6);
}
.buttons {
  .button-outline {
    margin: 100px auto 0 auto;
    background-color: #112baf;
    color: white;

    @media screen and (max-width: 600px) {
      margin: 20px auto;
    }
  }
}

.control--input input {
  max-width: unset;
}

.return-btn-in-container {
  left: 0;
  border: 1px solid red;
}
.form-check-label {
  font-size: 14px;
  font-weight: 600;
}
.role-description {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-left: 30px;
  color: rgba(31, 34, 47, 0.6);
}
.error-departments {
  position: absolute;
  padding: 5px 15px;
  background-color: #f28787;
  color: #fff;
  font-size: 1.2rem;
  line-height: 15px;
  max-width: 327px;
  border-radius: 15px;
  width: 100%;
  margin-bottom: 20px;
  z-index: 2;
  &-inner {
    position: relative;
  }
  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 10px 15px;
    border-color: transparent transparent #f28787 transparent;
    transform: translateX(-50%);
  }
}
</style>
