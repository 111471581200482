import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth.store";

import AuthRoutes from "@/router/authRoutes";
import UserRoutes from "@/router/userRoutes";
import AdminRoutes from "@/router/adminRoutes";
import notFoundRoutes from "@/router/notFoundRoutes";
import { Role } from "@/helpsers/role.helper";
import storeCache from "@/js/storeCache";

let allRoutes: any = [];
allRoutes = allRoutes.concat(AuthRoutes, UserRoutes, AdminRoutes, notFoundRoutes);

const routes = allRoutes;

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach(async (to) => {
  const { authorize, globalAccess } = to.meta;
  const auth: any = useAuthStore();
  let token = storeCache.get("token");
  if (to.path === "/") {
    if (authorize && auth.user) {
      if (auth.user.role === Role.Admin) {
        auth.returnUrl = "/admin";
        return "/admin";
      } else {
        auth.returnUrl = "/dashboard";
        return "/dashboard";
      }
    } else {
      auth.returnUrl = "/dashboard";
      return "/dashboard";
    }
  }
  //@ts-ignore
  if (auth.user && authorize && !authorize.includes(auth.user.role)) {
    if (auth.user.role === Role.Admin) {
      auth.returnUrl = "/admin";
      return "/admin";
    } else {
      auth.returnUrl = "/dashboard";
      return "/dashboard";
    }
  }
  if (authorize && token === null) {
    auth.returnUrl = to.fullPath;
    return "/login";
  }

  let pageTitle = "LOGIplanner";
  document.title = to.meta?.title ? to.meta?.title + " | " + pageTitle : pageTitle;
});

export default router;
