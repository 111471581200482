class AlertHelper {
  public alert;
  constructor() {
    this.alert = alert;
  }

  show(alert: any, title: string, desc = "", type = "success", autoClosing = true) {
    if (alert !== undefined && alert !== null && alert.value) {
      alert.value.showAlert(type, desc, title, {
        iconSize: 35,
        iconType: "solid",
        position: "top right",
      });
    }

    if (autoClosing) this.close(alert);
  }

  close(alert: any) {
    setTimeout(() => {
      if (alert && alert.value) alert.value.closeAlert();
    }, 10000);
  }
}

export default new AlertHelper();
