import { defineStore } from "pinia";
// import router from "@/router";
// import axios from "axios";
import authHelper from "@/helpsers/auth.helper";
import AlertHelper from "@/helpsers/alert.helper";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import storeCache from "@/js/storeCache";
import UserService from "@/services/user.service";
import StoreCache from "@/js/storeCache";
import i18n from "@/i18n";
import { AnyMessageParams } from "yup/lib/types";
import { computed, inject, ref } from "vue";
import { parseError } from "@/js/error";
import { RegularDataObject, RegularErrorResponse, RegularGetResponse } from "./utils/interfaces";
import { Company, User, useCompanyStore } from "./company.store";
import { Department } from "./department.store";

export const useUserStore = defineStore("user", () => {
  let alert = inject("alert");
  const user = ref(storeCache.get("currentUser"));
  const userInfo = ref("");
  const userCompanies = ref([]);
  const companyWorkers = ref([]);
  const returnUrl = ref(null);
  const isLoading = ref(false);
  const currentCompany = ref("" as "" | { type: "DRIVER" | "FACTORY" | "WAREHOUSE"; id: string });
  const justAddedWorker = ref({});
  const page = ref(1);
  const onPage = ref(5);
  const total = ref(0);

  async function fetchCurrentUser() {
    const userService = new UserService();
    if (storeCache.get("token")) {
      await axios
        .get(ApiHelper.getCurrentUrl(), { headers: authHelper.authHeader() })
        .then(async (res: { data: { avatar: { id: string } | null } }) => {
          if (!storeCache.get("profile_pic") && res.data.avatar !== null) {
            await userService.getUserProfileImage(res.data.avatar.id).catch((e) => {
              AlertHelper.show(alert, parseError(e), "", "error");
            });
          }
          return res.data;
        })
        .then((res: any) => StoreCache.set("currentUser", res))
        .catch((err: RegularErrorResponse) => {
          if (err.response && err.response.data.statusCode === 500) {
            userService.logout();
          }
        });
    }
  }

  async function updateUser(obj: AnyMessageParams) {
    let url = "";
    const { alert, ...object } = obj;
    if (obj.userId) url += "?userId=" + obj.userId;
    await axios
      .put(ApiHelper.getUpdateUserUrl() + url, { userData: object }, { headers: authHelper.authHeader() })
      .catch((e: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(e), "", "error");
      });
  }

  async function editUserRole(obj: AnyMessageParams) {
    try {
      await axios.put(ApiHelper.editUserRole(), { ...obj }, { headers: authHelper.authHeader() });
    } catch (e: any) {
      AlertHelper.show(alert, parseError(e), "", "error");
      throw e.response.status;
    }
  }

  async function addUser(obj: AnyMessageParams) {
    isLoading.value = true;
    const { email, role, companyId, message, name, surname, phoneNumber } = obj;
    await axios
      .post(
        ApiHelper.addToCompanyUrl(),
        {
          email,
          role,
          companyId,
          message,
          name,
          surname,
          phoneNumber,
        },
        { headers: authHelper.authHeader() }
      )
      .then((response: { data: User }) => {
        if (response.data) {
          justAddedWorker.value = response.data;
        }
      })
      .catch((err: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(err), "", "error");
        isLoading.value = false;
        throw err.response.data.message;
      });
    isLoading.value = false;
  }
  async function fetchUserCompanies() {
    await axios
      .get(ApiHelper.getUserCompaniesUrl(), {
        headers: authHelper.authHeader(),
      })
      .then((res: any) => {
        userCompanies.value = res.data.data;
      })
      .catch((err: any) => {
        AlertHelper.show(alert, parseError(err), "", "error");
      });
  }
  async function editWorker(obj: AnyMessageParams) {
    const { companyId, userId, alert, ...object } = obj;
    try {
      await axios.put(ApiHelper.editWorkers(companyId, userId), object, {
        headers: authHelper.authHeader(),
      });
      AlertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("profile.add_user.edit_complete"), "success");
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function fetchCompanyWorkersAndReturn(id: string) {
    const res = await axios.get(ApiHelper.getWorkers(id), {
      headers: authHelper.authHeader(),
    });
    return res?.data?.data;
  }
  async function fetchCompanyWorkers(obj: AnyMessageParams) {
    const { id, status, departmentId, query } = obj;
    const statusParam = obj.status && obj.status !== "ALL" ? `status=${obj.status}` : "";
    // @ts-ignore
    let url = ApiHelper.getWorkers(id) + `?limit=${this.onPage !== "" ? this.onPage : "99"}&page=${this.page}&${statusParam}`;
    if (departmentId) url += `&departmentId=${departmentId}`;
    if (query) url += `&query=${query}`;
    if (obj.sorting && obj.sorting !== "") url += obj.sorting;
    await axios
      .get(url, {
        headers: authHelper.authHeader(),
      })
      .then((response: RegularGetResponse<User>) => {
        total.value = response.data.meta.total;
        onPage.value = response.data.meta.per_page;
        page.value = response.data.meta.current_page;

        const requests = response.data.data.map((worker: any) => {
          let url = `${ApiHelper.getWorkerDepartments(id)}?`;
          url += response.data?.meta?.per_page ? `limit=${response.data.meta.per_page}&` : "";
          url += worker.id ? `query=${worker.id}&` : "";

          return axios
            .get(url, {
              headers: authHelper.authHeader(),
            })
            .then((res: RegularGetResponse<Department>) => {
              worker.userDepartment =
                res && res.data && res.data.data && res.data.data.length > 0 && res.data.data[0]?.userDepartment
                  ? res.data.data[0]?.userDepartment
                  : null;

              delete worker.modifiedAt;
              delete worker.createdAt;
              delete worker.invitations;

              return worker;
            });
        });

        Promise.all(requests)
          .then((updatedWorkers) => {
            //@ts-ignore
            companyWorkers.value = updatedWorkers;
          })
          .catch((error) => {
            AlertHelper.show(alert, parseError(error), "", "error");
          });
      })
      .catch((err: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(err), "", "error");
      });
  }

  async function setCurrentCompany(companyId: string) {
    const userCompany = useCompanyStore();

    await axios(`${ApiHelper.getCompany()}${companyId}`, {
      headers: {
        Authorization: `Bearer ${storeCache.get("token")}`,
      },
    })
      .then((res: RegularGetResponse<Company>) => res.data)
      .then(async (res: any) => {
        let role = await userCompany.getRoleInCompany(res?.id);
        if (role) storeCache.set("roleInCurrentCompany", role);
        storeCache.set("currentCompany", res);
        currentCompany.value = res;
      })
      .catch((err: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(err), "", "error");
      });
  }

  async function acceptInvitation(invitationId: string) {
    try {
      await axios.put(`${ApiHelper.acceptInvitationUrl()}${invitationId}`);
    } catch (e: any) {
      throw e.response.data.message;
    }
  }

  async function rejectInvitation(invitationId: string) {
    try {
      await axios.put(`${ApiHelper.rejectInvitationUrl()}${invitationId}`).catch((err: any) => {
        throw err.response.data.message;
      });
    } catch (e) {
      throw e;
    }
  }

  async function changeUserStatus(companyId: string, userId: string, status: string) {
    try {
      await axios.put(ApiHelper.editUserStatus(companyId, userId), {
        status,
      });
    } catch (err: any) {
      throw err.response;
    }
  }

  const getUserCompanies = computed(() => userCompanies.value);
  const getCurrentCompany = computed(() => currentCompany.value);
  const getWorkers = computed(() => companyWorkers.value);

  return {
    user,
    getWorkers,
    getCurrentCompany,
    getUserCompanies,
    userInfo,
    userCompanies,
    companyWorkers,
    returnUrl,
    isLoading,
    currentCompany,
    justAddedWorker,
    page,
    onPage,
    total,
    fetchCurrentUser,
    updateUser,
    editUserRole,
    addUser,
    fetchUserCompanies,
    editWorker,
    fetchCompanyWorkers,
    fetchCompanyWorkersAndReturn,
    setCurrentCompany,
    acceptInvitation,
    rejectInvitation,
    changeUserStatus,
  };
});
