<template>
  <div class="wrapper-with-scroller">
    <div class="absolute-positioned-button">
      <Settings style="cursor:pointer;" @click="() => confirmationDialog.show()"/>

      <!-- <primary-button style="max-width:50px" @click="() => confirmationDialog.show()" red="true" small="true" title="" :settings="true"></primary-button> -->
    </div>
    <div v-if="loaded" class="flex-col" :class="currentCompany && currentCompany.ververificationTransferConfirmed ? '' : 'flex-col-smaller'">
      <draggable style="display: flex; flex-wrap: wrap; align-items: flex-start; gap: 10px 50px; justify-content: center" v-model="items">
        <template v-slot:item="{ item }">
          <div>
            <div style="width: fit-content; margin: auto" v-if="item.widgetType === 'COMPANY'">
              <h2 class="small-title">{{ $t("widgetsTitles.widgets.companyData") }}</h2>
              <div class="knob knob-fill knob-center" style="min-height: 400px; max-height: 400px">
                <CompanyDataWidget />
              </div>
            </div>
            <div style="width: fit-content; margin: auto" v-if="item.widgetType === 'USER_DEPARTMENTS' || item.widgetType === 'USER_WAREHOUSES'">
              <h2 class="small-title">
                {{ typeOfCompany() === "DRIVER" ? $t("widgetsTitles.widgets.departmentList") : $t("widgetsTitles.widgets.warehouseList") }}
              </h2>
              <div class="knob knob-fill knob-center" style="min-height: 400px; max-height: 400px">
                <DepartmentListWidget />
              </div>
            </div>
            <div style="width: fit-content; margin: auto" v-if="item.widgetType === 'WORKERS'">
              <h2 class="small-title">{{ $t("widgetsTitles.widgets.workerList") }}</h2>
              <div class="knob knob-fill knob-center" style="min-height: 400px; max-height: 400px">
                <WorkersListWidget />
              </div>
            </div>
            <div style="width: fit-content; margin: auto" v-if="item.widgetType === 'USER_EXTERNAL_WAREHOUSES'">
              <h2 class="small-title">{{ $t("widgetsTitles.widgets.outsideWarehouse") }}</h2>
              <div class="knob knob-fill knob-center" style="min-height: 400px; max-height: 400px">
                <OutsideDepartmentListWidget />
              </div>
            </div>
            <div style="width: fit-content; margin: auto" v-if="item.widgetType === 'CARRIER_GROUPS'">
              <h2 class="small-title">{{ $t("widgetsTitles.widgets.groupList") }}</h2>
              <div class="knob knob-fill knob-center" style="min-height: 400px; max-height: 400px">
                <GroupListWidget />
              </div>
            </div>
            <div style="width: fit-content; margin: auto" v-if="item.widgetType === 'DOCUMENTS'">
              <h2 class="small-title">{{ $t("widgetsTitles.widgets.documentList") }}</h2>
              <div class="knob knob-fill knob-center" style="min-height: 400px; max-height: 400px">
                <DocumentListWidget />
              </div>
            </div>
            <div style="width: fit-content; margin: auto" v-if="item.widgetType === 'DRIVERS'">
              <h2 class="small-title">{{ $t("widgetsTitles.widgets.driverList") }}</h2>
              <div class="knob knob-fill knob-center" style="min-height: 400px; max-height: 400px">
                <DriverListWidget />
              </div>
            </div>

            <div style="max-width: fit-content" v-else-if="item.widgetType === 'MAP'">
              <h2 class="small-title">
                {{ $t("widgets.functionality_map") }}
              </h2>
              <div class="knob knob-center" style="min-height: 400px; display: flex">
                <functionality-map />
              </div>
            </div>

            <div style="max-width: fit-content; height: 400px" v-else-if="item.widgetType === 'ADVERTISEMENT'">
              <h2 class="small-title">Reklama</h2>
              <div v-if="adverts.length > 0">
                <banner :adverts="adverts" />
              </div>
              <div v-else>
                <banner :adverts="adverts" />
              </div>
            </div>
            <div style="max-width: fit-content; min-height: 220px" v-else-if="item.widgetType === 'CARS' && typeOfCompany() === 'DRIVER'">
              <h2 class="small-title">{{ $t("widgets.my_cars") }}</h2>
              <div class="knob knob-center" style="min-height: 400px">
                <car-statuses />
              </div>
            </div>

            <div v-else-if="item.widgetType === 'CURRENCY' && !isLoading">
              <h2 class="small-title">{{ $t("widgets.currency") }}</h2>
              <div
                style="min-height: 400px; width: fit-content"
                class="knob knob-fill knob-center"
                :class="{ 'currency-widget-top': showCurrencies || showCalculator }"
              >
                <currency-widget
                  id="side-currency-modal"
                  @newCurrency="fetchNewCurrency"
                  @newDate="fetchNewData"
                  @displayCalculator="(value) => (showCalculator = value)"
                  @displayCurrencies="(value) => (showCurrencies = value)"
                  :currencies="exchangeRates"
                  :initialDate="pickedDate"
                  :calcValue="value"
                />
                <div v-if="showCurrencies || showCalculator" :class="isToTheRight() ? 'right-side-modal left-side-modal' : 'right-side-modal'">
                  <div v-if="showCalculator" class="currency-header">
                    <p>{{ $t("auction_adding.calc") }}</p>
                    <input @keyup="value = value.replace(/[^0-9.]/g, '')" class="currency-input" type="text" v-model="value" />
                  </div>
                  <div v-if="showCurrencies">
                    <p>{{ $t("auction_adding.rest_of_currencies") }}</p>
                    <div v-for="item in exchangeRates" :key="item" class="currency-item">
                      <div class="left-side">
                        <div class="price-item"></div>
                      </div>
                      <div style="margin: 5px 0" class="right-side">
                        <div class="price-item">
                          <span>{{ item.code !== "PLN" ? (value / item.mid).toFixed(3) : (value * item.midd).toFixed(3) }}</span>
                          <span>{{ item.code }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </draggable>
    </div>
    <div class="flex-col" v-if="!loaded"><loader-fitting-into-container></loader-fitting-into-container></div>
    <div class="dialog" id="confirmation-dialog" aria-hidden="true">
      <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
      <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
        <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
          <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
        </button>
        <div class="widget-settings-wrapper">
          <h1 style="font-weight: 600; font-size: 18px; line-height: 17px; margin-bottom: 40px">{{ $t("widgets.available") }}</h1>
          <div
            v-for="(widget, i) in widgets"
            :key="widget.id"
            class="widget-item"
            :style="widget?.widgetType === 'ADVERTISEMENT' ? 'display:none' : ''"
          >
            <div v-if="widget.widgetType !== 'SCATTER_CHART' || typeOfCompany() === 'DRIVER'" class="form-check form-switch">
              <input class="form-check-input" type="checkbox" :checked="toggleTable[i]" id="flexSwitchCheckChecked" v-model="toggleTable[i]" />
            </div>
            <p class="label">
              {{ $t(`widgets.${widget.widgetType.toLowerCase()}`) }}
            </p>
          </div>
          <primary-button @click="save" style="margin-top: 40px" :title="$t('generals.save')" :red="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import UserLayout from "@/layouts/UserLayout";
import { useUserStore } from "@/stores/user.store";
import { useCurrencyStore } from "@/stores/currency.store";
import { useWidgetStore } from "@/stores/widgets.store";
import { useAuthorizationStore } from "@/stores/authorization.store";
import { useHelpersStore } from "@/stores/helpers.store";
import { storeToRefs } from "pinia";
import { computed, inject, onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import AlertHelper from "@/helpsers/alert.helper";
import router from "@/router";
import storeCache from "@/js/storeCache";
import Chart from "@/components/common/Chart.vue";
import BarChart from "@/components/common/BarChart.vue";
import RadarChart from "@/components/common/RadarChart.vue";
import PolarChart from "@/components/common/PolarChart.vue";
import ScatterChart from "@/components/common/ScatterChart.vue";
import CurrencyWidget from "@/components/common/CurrencyWidget.vue";
import { convertDateToStandardFormat, goDayBack } from "@/js/date.utils";
import i18n from "@/i18n";
import FunctionalityMap from "@/components/widgets/FunctionalityMap.vue";
import Banner from "@/components/widgets/Banner.vue";
import CarStatuses from "@/components/widgets/CarStatuses.vue";
import { typeOfCompany } from "@/js/utils";
import { useAdvertsStore } from "@/stores/admin/adverts/adverts.store";
import Draggable from "vue3-draggable";
import PrimaryButton from "@/components/common/PrimaryButton.vue";
import LoaderFittingIntoContainer from "@/components/common/LoaderFittingIntoContainer.vue";
import DepartmentListWidget from "@/components/widgets/company-tab/DepartmentListWidget.vue";
import DocumentListWidget from "@/components/widgets/company-tab/DocumentListWidget.vue";
import OutsideDepartmentListWidget from "@/components/widgets/company-tab/OutsideDepartmentListWidget.vue";
import DriverListWidget from "@/components/widgets/company-tab/DriverListWidget.vue";
import WorkersListWidget from "@/components/widgets/company-tab/WorkersListWidget.vue";
import GroupListWidget from "@/components/widgets/company-tab/GroupListWidget";
import { Department, useDepartmentStore } from "@/stores/department.store";
import CompanyDataWidget from "@/components/widgets/company-tab/CompanyDataWidget";
import Settings from "@/components/icons/Settings.vue";
export default {
  name: "Dashboard",
  components: {
    UserLayout,
    Settings,
    LoaderFittingIntoContainer,
    DocumentListWidget,
    Menu,
    Chart,
    BarChart,
    RadarChart,
    PolarChart,
    ScatterChart,
    CurrencyWidget,
    FunctionalityMap,
    Banner,
    CarStatuses,
    Draggable,
    PrimaryButton,
    DepartmentListWidget,
    OutsideDepartmentListWidget,
    DriverListWidget,
    WorkersListWidget,
    GroupListWidget,
    CompanyDataWidget,
  },
  setup() {
    let waiting = ref(0);
    let accepted = ref(0);
    let rejected = ref(0);
    let items = ref([]);
    const store = useAdvertsStore();
    let currencyStore = useCurrencyStore();
    let widgetStore = useWidgetStore();
    const departmentStore = useDepartmentStore();
    const userStore = useUserStore();
    const authStore = useAuthorizationStore();
    const { userWidgets, widgets } = storeToRefs(widgetStore);
    const { userCompanies } = storeToRefs(userStore);
    let { exchangeRates } = storeToRefs(currencyStore);
    const { adverts, loading } = storeToRefs(store);
    let pickedDate = ref("");
    const confirmationDialog = ref("");
    let currentCompany = storeCache.get("currentCompany");
    let isLoading = ref(true);
    const showCurrencies = ref(false);
    const showCalculator = ref(false);
    let value = ref(1);
    let all = computed(() => {
      return waiting.value + accepted.value + rejected.value;
    });
    let chosenCurrency = ref("EUR");
    let width = ref(window.innerWidth);
    let toggleTable = ref([]);
    onBeforeMount(async () => {
      if (user.value && user.value.role === "ADMIN") router.push({ name: "admin.companies" });
      await userStore.fetchCurrentUser();
      await store.fetchAdverts();
    });

    let currentU = ref(storeCache.get("currentUser"));

    onMounted(async () => {
      loaded.value = false;
      confirmationDialog.value = new A11yDialog(document.querySelector("#confirmation-dialog"));

      //INITIAL PAGE RELOAD
      if (storeCache.get("initial_reload")) {
        storeCache.remove("initial_reload");
        window.location.reload();
      }
      //INITIAL PAGE RELOAD
      await userStore.fetchUserCompanies();

      if (user.value && user.value.role !== "ADMIN" && storeCache.get("token") && typeOfCompany() !== "ADMIIN") {
        pickedDate.value = goDayBack(new Date());
        let earliestDataFound = false;
        let day = "";
        while (!earliestDataFound) {
          try {
            if (pickedDate.value.includes("-")) {
              await currencyStore.getExchangeRates(pickedDate.value, chosenCurrency.value);
              if (exchangeRates.value && exchangeRates.value.length > 0) {
                earliestDataFound = true;
                isLoading.value = false;
              }
            }
          } catch (e) {
            day = new Date(pickedDate.value);
            day = goDayBack(day);
            pickedDate.value = convertDateToStandardFormat(day);
          }
        }
        await widgetStore.fetchUserWidgets("COMPANY");
        await widgetStore.fetchWidgets(typeOfCompany(), "COMPANY");

        widgets.value.forEach((widget) => {
          if (userHasWidget(widget)) {
            toggleTable.value.push(true);
          } else toggleTable.value.push(false);
        });

        if (storeCache.get("invitationChoice")) {
          await userStore.fetchUserCompanies();
          AlertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("profile_settings.invitation_processed"), "success");
          storeCache.remove("invitationChoice");
        }

        if (currentU.value && currentU.value.role !== "ADMIN") {
          await userStore.fetchUserCompanies();
          if (storeCache.get("currentCompany") === null && userStore.getUserCompanies.length > 0) {
            await userStore.setCurrentCompany(`${userStore.getUserCompanies.at(0).id}`);
          }
        }

        let id = "";
        id = storeCache.get("currentCompany")?.id;

        items.value = userWidgets.value;
        if (id) {
          await departmentStore.fetchAllWarehouses({
            id: id,
          });
        }

        setTimeout(() => {
          loaded.value = true;
        }, 300);
      }
    });

    const userHasWidget = (widget) => {
      let flag = false;
      userWidgets.value.forEach((w) => {
        if (w.widgetType === widget.widgetType) flag = true;
      });
      return flag;
    };

    const save = async () => {
      const tableToSave = [];
      toggleTable.value.forEach((bool, index) => {
        if (bool) tableToSave.push(widgets.value[index]);
      });
      loaded.value = false;
      items.value = items.value.filter((item) => {
        const found = tableToSave.find((it) => it.widgetType === item.widgetType);
        if (found) return item;
      });
      tableToSave.forEach((item) => {
        const found = items.value.find((it) => it.widgetType === item.widgetType);
        if (!found) {
          items.value.push(item);
        }
      });
      await widgetStore.updateUserWidgets(items.value, "COMPANY", alert);
      await widgetStore.fetchUserWidgets("COMPANY");
      items.value = userWidgets.value;
      setTimeout(() => {
        loaded.value = true;
      }, 1000);
      confirmationDialog.value.hide();
    };

    const fetchNewData = async (date) => {
      pickedDate.value = date;
      try {
        await currencyStore.getExchangeRates(pickedDate.value, chosenCurrency.value);
      } catch (e) {
        AlertHelper.show(alert, parseError(e), "", "error");
      }
    };
    const fetchNewCurrency = async (curr) => {
      chosenCurrency.value = curr;
      try {
        await currencyStore.getExchangeRates(pickedDate.value, chosenCurrency.value);
      } catch (e) {
        AlertHelper.show(alert, parseError(e), "", "error");
      }
    };

    const hasWidget = (widget) => {
      let flag = false;
      userWidgets.value.forEach((w) => {
        if (w.widgetType === widget) flag = true;
      });

      return flag;
    };

    onBeforeUnmount(async () => {
      if (items.value && items.value.length > 0 && typeOfCompany() !== "ADMIIN") {
        await widgetStore.updateUserWidgets(items.value, "COMPANY", null);
      }
    });

    let user = ref(storeCache.get("currentUser"));
    let statuses = ["ACCEPTED", "PENDING", "REJECTED"];

    const alert = inject("alert");
    let loaded = ref(false);

    const isToTheRight = () => {
      const div = document.getElementById("side-currency-modal");
      if (div.offsetParent.offsetLeft > 200) return true;
      return false;
    };
    return {
      userStore,
      confirmationDialog,
      isToTheRight,
      user,
      router,
      waiting,
      accepted,
      rejected,
      loaded,
      all,
      fetchNewData,
      pickedDate,
      exchangeRates,
      fetchNewCurrency,
      userWidgets,
      hasWidget,
      isLoading,
      typeOfCompany,
      width,
      showCurrencies,
      showCalculator,
      value,
      currentCompany,
      adverts,
      items,
      save,
      userHasWidget,
      toggleTable,
      widgets,
    };
  },
};
</script>

<style scoped lang="scss">
@use "@/css/small-elements/knob-box.scss";
@use "@/css/small-elements/currency-widget.scss";
@use "@/css/widgets/dashboard.scss";
</style>
