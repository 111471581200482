<template>
  <div>
    <vue-basic-alert ref="alert" />
    <div class="auth auth-wrapper">
      <div :class="'auth__box ' + additionalClass">
        <!-- <div @click="expanded = !expanded" class="language-box">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#F5F5F5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-globe"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="2" y1="12" x2="22" y2="12"></line>
            <path
              d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
            ></path>
          </svg>
          <p>{{ $t(`languages.${lang}`) }}</p>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#F5F5F5"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-arrow-down"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <polyline points="19 12 12 19 5 12"></polyline>
          </svg>
        </div> -->
        <div class="select" v-if="expanded">
          <p v-for="lan in languages" :key="lan.value" class="noselect" @click="selectNewLanguage(lan.value)">
            {{ lan.label }}
          </p>
          <!-- <p class="noselect" @click="openInfoModal('German')">Niemiecki</p>
      <p class="noselect" @click="openInfoModal('Russian')">Rosyjski</p>
      <p class="noselect" @click="openInfoModal('Ukrainian')">Ukraiński</p> -->
          <div class="shape"></div>
        </div>
        <div v-show="displayLogo" class="auth__box-logo">
          <img src="../assets/images/logo.png" alt="Logo" @click="redirectToLandingPage" style="cursor: pointer" />
        </div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, provide, ref } from "vue";
import VueBasicAlert from "vue-basic-alert";
import storeCache from "@/js/storeCache";
import { setLangConfig } from "@/i18n";
import router from "@/router";
export default {
  name: "GuestLayout",
  components: { VueBasicAlert },
  props: {
    additionalClass: {
      type: String,
      default: "",
    },
    displayLogo: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    onMounted(() => {
      if (router.currentRoute.value.name === "register") {
        largeBox.value = true;
      } else {
        largeBox.value = false;
      }
    });
    let largeBox = ref(false);
    let lang = ref(storeCache.get("lang") || "pl");
    let languages = ref([
      { label: "Polski", value: "pl" },
      { label: "Angielski", value: "en" },
    ]);
    let expanded = ref(false);
    const alert = ref();
    provide("alert", alert);

    const selectNewLanguage = (e) => {
      setLangConfig(e);
      lang.value = storeCache.get("lang");
      expanded.value = false;
    };
    const redirectToLandingPage = () => {
      window.location.href = "https://www.logiplanner.eu/";
    };
    return {
      alert,
      lang,
      languages,
      expanded,
      selectNewLanguage,
      largeBox,
      redirectToLandingPage,
    };
  },
};
</script>

<style scoped lang="scss">
.auth-wrapper {
  overflow-y: auto !important;
  @media screen and (max-width: 400px) {
    padding: 0;
    margin: 0;
  }
}
::v-deep(.auth) {
  background: #f0f0f0;
  background-position: center;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;

  @media screen and (max-width: 400px) {
    padding: 0;
    margin: 0;
  }
  .auth__heading {
    font-size: 3rem;
    line-height: 37px;
    margin-bottom: 30px;
    text-align: center;
    font-weight: 500;
  }

  .auth__text {
    font-size: 2rem;
    line-height: 24px;
    margin-bottom: 25px;

    &--centered {
      text-align: center;
    }
  }

  .auth__url {
    margin-top: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      font-size: 1.8rem;
      line-height: 22px;
      text-align: center;
      font-weight: 500;
    }

    &--brown {
      a {
        color: #112baf;
      }
    }
  }

  .auth__box {
    background-color: #fff;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
    padding: 22px 70px;
    width: 100%;
    max-width: 540px;
    height: 85vh;
    overflow-y: auto;
    position: relative;
    border-radius: 20px;
    @media screen and (max-width: 600px) {
      padding: 22px 60px;
      margin: 0;
    }
    @media screen and (max-width: 400px) {
      padding: 22px 40px;
      margin: 0;
    }
    .language-box {
      font-size: 15px;
      display: flex;
      position: absolute;
      top: 20px;
      right: 20px;
      color: #f5f5f5;
      cursor: pointer;
      p {
        margin: 0 5px;
        margin-right: 20px;
      }
      svg {
        opacity: 0.7;
        margin-top: 2px;
      }
    }
    &--reset {
      max-width: 696px;
    }

    &--login {
      max-width: 507px;
    }

    &-logo {
      margin: 40px auto 40px;
      text-align: center;
      margin-bottom: 0px;
      img {
        max-width: 270px;
        @media screen and (max-width: 500px) {
          max-width: 300px;
        }
        @media screen and (max-width: 400px) {
          max-width: 250px;
        }
      }
    }
  }
  .large {
    max-width: none !important;
    height: 95vh;
    margin-top: -50px;
    padding: 0px 30px !important;
    overflow-x: hidden;
  }
  .auth__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .auth__prev {
    display: block;
    color: #112baf;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 40px;
  }

  .auth__line {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto;

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #8a8a8a;
      position: absolute;
      left: 0;
      top: 7px;
    }

    span {
      position: relative;
      color: #8a8a8a;
      padding: 0 7px;
      z-index: 2;
      background-color: #f2f2f2;
    }
  }

  .auth__remember {
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a {
      color: rgba(5, 24, 52, 0.6);
      text-decoration: none;
    }
  }

  .auth__forgotten {
    margin-top: 20px;
    font-size: 12px;
    color: rgba(5, 24, 52, 0.6);
    text-align: center;
    a {
      font-weight: 400;
      text-decoration: underline;
      color: rgba(5, 24, 52, 0.6);
    }

    &--again {
      margin-top: 15px;
      font-style: italic;
      color: #112baf;
    }
  }

  .auth__error-message {
    margin-bottom: 15px;
    text-align: center;
    color: #112baf;
    font-weight: 500;
  }
}

.select {
  background: #ffffff;
  border-radius: 15px;
  height: 120px;
  width: 100px;
  position: absolute;
  top: 70px;
  right: 20px;
  padding: 1rem;
  padding-bottom: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: default;
  text-align: center;
}
.select p {
  font-size: 14px;
  margin-top: 5px;
  padding: 1rem;
  width: 100%;
  color: #86898b;
  cursor: pointer;
}
.select p:hover {
  background: #f8f8f8;
}
.shape {
  top: -1.8rem;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 30px solid #ffffff;
  position: absolute;
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%);
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
