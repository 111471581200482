import { ref, computed, inject } from "vue";
import axios from "@/axios";
import i18n from "@/i18n";
import storeCache from "@/js/storeCache";
import { defineStore } from "pinia";
import ApiHelper from "@/helpsers/api.helper";
import { parseError } from "@/js/error";
import AlertHelper from "@/helpsers/alert.helper";

const initValues = {
  p24_merchant_id: "",
  p24_pos_id: "",
  p24_session_id: "",
  p24_amount: "",
  p24_currency: "",
  p24_description: "",
  p24_email: "",
  p24_country: "",
  p24_url_return: "",
  p24_url_status: "",
  p24_api_version: "",
  p24_sign: "",
};

export interface PriceListElement {
  monitoryNotesNumber: number;
  price: string;
  id: string;
  createdAt?: string;
  modifiedAt?: string;
}

export const usePaymentStore = defineStore("payment", () => {
  let alert = inject("alert");
  const priceList = ref([] as PriceListElement[]);
  const walletAmount = ref(0);
  const paymentHistory = ref([]);
  const isLoading = ref(false);
  const transaction_message = ref(null);
  const transaction_error = ref(null);
  const transaction_info = ref(initValues);
  const transaction_show = ref(false);
  const invoiceList = ref([]);
  const page = ref(1);
  const onPage = ref(5);
  const total = ref(0);

  const fetchPriceList = async (priceType?: string) => {
    try {
      const response = await axios.get(ApiHelper.fetchPriceList() + `?type[0]=MONITORY_NOTE&${priceType ? `priceType=${priceType}` : ""}`);
      if (response && response.data && response.data.data) {
        priceList.value = response.data.data.sort((price: PriceListElement, nextPrice: PriceListElement) => {
          return nextPrice.monitoryNotesNumber - price.monitoryNotesNumber;
        });
      }
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchSubscriptionList = async (priceType?: string) => {
    try {
      const response = await axios.get(ApiHelper.fetchPriceList() + `?type[0]=SUBSCRIPTION&${priceType ? `priceType=${priceType}` : ""}`);
      priceList.value = response.data.data.sort((price: PriceListElement, nextPrice: PriceListElement) => {
        return nextPrice.monitoryNotesNumber - price.monitoryNotesNumber;
      });
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };
  const fetchAuctionPaymentList = async (priceType?: string) => {
    try {
      const response = await axios.get(ApiHelper.fetchPriceList() + `?type[0]=AUCTION`);
      return response.data?.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const getSessionStatus = async (sessionId: string) => {
    try {
      const response = await axios.get(ApiHelper.sessionStatus(sessionId));
      return response.data.status;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchInvoice = async (paymentId: string, companyId: string) => {
    try {
      const response = await axios.get(ApiHelper.downloadInvoice(paymentId, companyId));
      location.href = response.data.publicLink;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchSummaryInvoice = async (invoiceId: string) => {
    try {
      const response = await axios.get(ApiHelper.downloadSummaryInvoice(invoiceId));
      location.href = response.data.publicLink;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchSummaryInvoices = async (companyId: string) => {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    try {
      const response = await axios.get(ApiHelper.getSummaryInvoices(companyId) + url);
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      invoiceList.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchAllInvoices = async (companyId: string, dayFrom?: string, dayTo?: string) => {
    let url = `?limit=${onPage.value}&page=${page.value}`;
    try {
      const response = await axios.get(ApiHelper.getAllInvoices(companyId) + url, {
        params: {
          dateStart: dayFrom || undefined,
          dateEnd: dayTo || undefined,
        },
      });
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
      invoiceList.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const fetchPaymentList = async (companyId: string, status: string, onPageC: string, pageC: string, isMonitory: boolean, sorting: string) => {
    try {
      let url = `?limit=${onPageC}&page=${pageC}&category[0]=${isMonitory ? "MONITORY_NOTE" : "SUBSCRIPTION"}`;
      if (sorting && sorting !== "") url += sorting;
      const res = await axios.get(ApiHelper.fetchPayments(companyId, status) + url);
      total.value = res.data.meta.total;
      onPage.value = res.data.meta.per_page;
      page.value = res.data.meta.current_page;
      walletAmount.value = res.data.data.monitoryNotesNumber;
      paymentHistory.value = res.data.data.history.map((payment: any) => {
        payment.sum = payment.amount;
        delete payment.amount;
        payment.date = payment.createdAt.substring(0, 10);
        payment.hour = payment.createdAt.substring(11, 16);
        delete payment.createdAt;
        payment.type = payment.type === "TOPUP" ? "WALLET_CHARGE" : payment.type === "MONITORY_NOTE_PURCHASE" ? "RESERVATION_PAYMENT" : payment.type;
        return payment;
      });
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const startTransactionForTimeWindows = async (obj: any, alertNew:any) => {
    const { companyId, packageId } = obj;
    let body = {
      type: "TOPUP",
      paymentPriceListId: packageId,
    };
    try {
      await axios.post(ApiHelper.newPayment(companyId), body).then((response: any) => {
        storeCache.set("sessionId", response.data.sessionId);
        location.href = response.data.link;
      });
    } catch (e) {
      AlertHelper.show(alertNew, parseError(e), "", "error");
    }
  };

  const startTransactionForVerification = async (companyId: string) => {
    let body = {
      type: "VERIFICATION_TRANSFER",
    };

    try {
      await axios.post(ApiHelper.newPayment(companyId), body).then((response: any) => {
        storeCache.set("sessionId", response.data.sessionId);
        location.href = response.data.link;
      });
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };
  const startTransactionForAuction = async (body: { type: string; auctionId: string; paymentPriceListId: string; companyId: string }) => {
    const { companyId, ...obj } = body;
    try {
      await axios.post(ApiHelper.newPayment(companyId), obj).then((response: any) => {
        storeCache.set("sessionId", response.data.sessionId);
        location.href = response.data.link;
      });
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  const getTransInfo = computed(() => {
    return {
      message: transaction_message.value,
      error: transaction_error.value,
      data: transaction_info.value,
      show: transaction_show.value,
    };
  });

  return {
    priceList,
    walletAmount,
    paymentHistory,
    isLoading,
    invoiceList,
    page,
    onPage,
    total,
    fetchPriceList,
    fetchSubscriptionList,
    getSessionStatus,
    fetchInvoice,
    fetchSummaryInvoice,
    fetchSummaryInvoices,
    fetchAllInvoices,
    fetchPaymentList,
    startTransactionForTimeWindows,
    startTransactionForVerification,
    getTransInfo,
    fetchAuctionPaymentList,
    startTransactionForAuction,
  };
});
