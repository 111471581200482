import storeCache from "@/js/storeCache";
import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import AlertHelper from "@/helpsers/alert.helper";
import i18n from "@/i18n";
import { parseError } from "@/js/error";
import { inject, ref } from "vue";
enum WidgetTypes {
  "COMPANY",
  "CARS",
  "ADVERTISEMENT",
  "CURRENCY",
  "USER_DEPARTMENTS",
  "WORKERS",
  "ADMINS",
  "DOCUMENTS",
  "MAP",
  "INSURANCE",
  "DRIVERS",
  "USER_WAREHOUSES",
  "USER_EXTERNAL_WAREHOUSES",
  "CARRIER_GROUPS",
  "ADD_CALENDAR",
  "CALENDAR",
  "MONITORY_NOTES",
  "MONITORY_NOTES_ARCHIVE",
  "AUTHORIZATIONS",
  "AUCTIONS",
  "CREATED_GROUPS",
  "AUCTIONS_ARCHIVE",
  "PARTICIPANTS_LIST",
}
export interface Widget {
  widgetType: Enumerator<WidgetTypes>;
  widgetId: string;
}

export const useWidgetStore = defineStore("widget", () => {
  let alert = inject("alert");
  const widgets = ref<Array<Widget>>([]);
  const userWidgets = ref<Array<Widget>>([]);

  async function fetchWidgets(account: "FACTORY" | "DRIVER" | "WAREHOUSE", tab: "COMPANY" | "TIME_WINDOWS" | "AUCTIONS" | "BANK" | "WALLET") {
    //@ts-ignore
    if (account === "ADMIN") return;
    try {
      if (storeCache.get("token")) {
        const response = await axios.get(ApiHelper.getAllWidgets(account, tab));
        widgets.value = response.data
          .map((widget: { type: string; id: string }) => {
            return {
              widgetType: widget.type,
              widgetId: widget.id,
            };
          })
          .filter(
            (widget: { widgetType: string }) =>
              widget.widgetType !== "INSURANCE" && widget.widgetType !== "ADD_CALENDAR" && widget.widgetType !== "CALENDAR"
          );
      }
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function fetchUserWidgets(tab: "COMPANY" | "TIME_WINDOWS" | "AUCTIONS" | "BANK" | "WALLET") {
    try {
      const response = await axios.get(ApiHelper.getUserWidgets(tab));
      userWidgets.value = response.data
        .map((widget: { type: string; id: string }) => {
          return {
            widgetType: widget.type,
            widgetId: widget.id,
          };
        })
        .filter(
          (widget: { widgetType: string }) =>
            widget.widgetType !== "INSURANCE" && widget.widgetType !== "ADD_CALENDAR" && widget.widgetType !== "CALENDAR"
        );
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function updateUserWidgets(array: Array<Widget>, tab: string, alerto: any) {
    try {
      await axios.put(ApiHelper.updateWidgets(), {
        tab,
        widgets: array.map((widget) => {
          return {
            widgetId: widget.widgetId,
          };
        }),
      });
      AlertHelper.show(alerto, i18n.global.t("widgets.success"), "", "success");
    } catch (e) {
      AlertHelper.show(alerto, parseError(e) || i18n.global.t("widgets.error"), "", "error");
    }
  }
  return { fetchWidgets, fetchUserWidgets, updateUserWidgets, widgets, userWidgets };
});
