<template>
  <div class="table-headers">
    <div class="head medium-head">{{ $t("profile.calendar.weekday") }}</div>
    <div class="head">
      <p>{{ $t("profile.calendar.from") }}</p>
    </div>
    <div class="head">
      <p>{{ $t("profile.calendar.to") }}</p>
    </div>
    <div class="head">
      <p>{{ $t("profile.calendar.interval") }}</p>
    </div>
    <div class="small-head">
      <p>{{ $t("profile.calendar.block_monitary") }}</p>
    </div>
    <div class="small-head">
      <p>{{ $t("profile.calendar.limitless_monitary") }}</p>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
<style scoped lang="scss">
@use "@/css/calendar/fill-calendar.scss";
</style>