<template>
  <header class="header">
    <div @click="toggleFullScreen" class="full-screen-icon">
      <img src="@/assets/icons/make-fullscreen.svg" alt="" />
    </div>
    <div class="user-settings-icon">
      <expandable-icon @action="redirectAction" :options="userProfileOptions" url="@/assets/icons/settings.svg" />
    </div>
    <div class="header__image">
      <router-link :to="{ name: 'dashboard' }">
        <img class="navbar-logo" src="@/assets/images/logo.png" alt="" />
        <img class="navbar-logo-small" src="@/assets/images/icon-small.png" alt="" />
      </router-link>
    </div>
    <div
      v-if="!hideAssistant"
      @click="router.push({ name: 'assistant' })"
      class="configurartion-assistant"
      :class="router.currentRoute.value.name === 'assistant' ? 'configuration-active' : ''"
    >
      <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.74305 9.03355C10.3176 9.03355 12.2598 7.09134 12.2598 4.51678C12.2598 1.94221 10.3176 0 7.74305 0C5.16848 0 3.22627 1.94221 3.22627 4.51678C3.22627 7.09134 5.16848 9.03355 7.74305 9.03355ZM9.03355 10.257H6.45254C2.89461 10.257 0 13.1516 0 16.7095V18H15.4861V16.7095C15.4861 13.1516 12.5915 10.257 9.03355 10.257ZM23.6085 4.18124L18.0594 9.7201L16.392 8.05277L14.5672 9.87755L18.0594 13.3671L25.4307 6.0086L23.6085 4.18124Z"
          :fill="router.currentRoute.value.name === 'assistant' ? '#112baf' : '#A2A2A2'"
        />
      </svg>
      <p>Uzupełnij profil</p>
      <span>{{ helpersStore.getDoneTasks() }}/{{ helpersStore.getTasksLength() }}</span>
    </div>

    <div class="additional-wrapper">
      <div class="user-card-wrapper">
        <div class="user-card">
          <div class="date-and-hour">
            <p>
              {{ hourNow }} <br />{{ dateNow }}<br />
              <span v-if="mousemoveTimeoutSeconds < 540" style="color: #a1a1a1">
                Do końca sesji: {{ formatTimeSimple(mousemoveTimeoutSeconds) }}</span
              >
            </p>
          </div>

          <div @click="router.push({ name: 'chat-list' })" class="messages-wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-mail"
            >
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </svg>
            <div
              v-if="(numberOfUnseen && numberOfUnseen > 0 && !unreadChatsCounter) || (unreadChatsCounter && unreadChatsCounter > 0)"
              class="messages-counter"
            >
              {{ unreadChatsCounter > 0 ? unreadChatsCounter : numberOfUnseen }}
            </div>
          </div>

          <div id="notification-bell" @click="toggleNotifications = !toggleNotifications" class="notification-bell">
            <svg
              id="notification-bell"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-bell"
            >
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
            <div
              v-if="
                (unreadNotificationsCounter && unreadNotificationsCounter > 0) ||
                (counter && counter > 0 && !unreadNotificationsCounter && unreadNotificationsCounter !== 0)
              "
              id="notification-bell"
              class="notifications-counter"
            >
              {{ unreadNotificationsCounter === 0 || unreadNotificationsCounter > 0 ? unreadNotificationsCounter : counter }}
            </div>
          </div>

          <img class="profile__left-photo" id="profile_photo_placeholder" src="../assets/images/blank_person.jpg" />
          <div class="flex flex-col">
            <p v-if="currentU && currentU.name" class="name" :class="currentU.surname.length > 15 ? 'smaller-name' : ''">
              {{ currentU ? currentU.name + " " + currentU.surname : "Jan Kowalski" }}
            </p>
            <p @click="router.push({ name: '/edit' })" v-else class="no-data-button">
              {{ $t("generals.no_data") }}
            </p>
            <p class="role">ID: {{ currentU?.subId ?? "" }}</p>
            <p v-if="roleInCompany()" class="role">
              {{ $t(`profile.role.${roleInCompany().toLowerCase()}`) }}
            </p>
            <p v-else>-</p>
          </div>
        </div>
        <div v-if="toggleNotifications" class="position-list">
          <notification-list />
        </div>
      </div>
    </div>
  </header>
  <VerificationBar
    @startVerification="startCompanyVerificationPayment"
    v-if="
      currentCompany &&
      !currentCompany.verificationTransferConfirmed &&
      refreshBar &&
      !companyVerificationMade &&
      !currentCompany.verificationTransferPending
    "
  ></VerificationBar>

  <VerificationBar
    :pending="true"
    v-if="(currentCompany && currentCompany?.verificationTransferPending) || (companyVerificationPending && !companyVerificationMade)"
  ></VerificationBar>

  <div @mousemove="mouseMoved" class="wrapper whole-thing">
    <Menu></Menu>
    <div class="place-left">
      <vue-basic-alert ref="alert" />
      <div
        v-if="
          router.currentRoute.value.name !== 'dashboard' &&
          router.currentRoute.value.name !== 'dashboard-auctions' &&
          router.currentRoute.value.name !== 'dashboard-timewindows' &&
          router.currentRoute.value.name !== 'dashboard-bank' &&
          router.currentRoute.value.name !== 'stock' &&
          router.currentRoute.value.name !== 'calendar' &&
          router.currentRoute.value.name !== '/edit-company'
        "
        class="contain normal-container"
        :class="{
          'no-padding':
            router.currentRoute.value.name === 'list-cars' ||
            router.currentRoute.value.name === 'won-auctions' ||
            router.currentRoute.value.name === 'chat-view' ||
            router.currentRoute.value.name === 'my-offers',
          'container-with-background':
            router.currentRoute.value.name === 'add-driver' ||
            router.currentRoute.value.name === 'chat-list' ||
            router.currentRoute.value.name === 'add-car' ||
            router.currentRoute.value.name === 'add-user' ||
            router.currentRoute.value.name === 'adding' ||
            router.currentRoute.value.name === 'edit-calendar-day' ||
            router.currentRoute.value.name === 'add-warehouse' ||
            router.currentRoute.value.name === 'add-outside-department' ||
            router.currentRoute.value.name === 'assistant' ||
            router.currentRoute.value.name === 'widgets' ||
            router.currentRoute.value.name === 'fill-callendar' ||
            router.currentRoute.value.name === 'fill-callendar-choice' ||
            router.currentRoute.value.name === 'profile-settings' ||
            router.currentRoute.value.name === 'add-monitary' ||
            router.currentRoute.value.name === 'departments-workers' ||
            router.currentRoute.value.name === 'edit-monitary' ||
            router.currentRoute.value.name === 'departments-details' ||
            router.currentRoute.value.name === 'add-auction-second' ||
            router.currentRoute.value.name === 'add-auction-third' ||
            router.currentRoute.value.name === 'add-auction-fourth' ||
            router.currentRoute.value.name === 'add-auction-test' ||
            router.currentRoute.value.name === 'add-auction',
          'smaller-container': currentCompany && !currentCompany.verificationTransferConfirmed,
        }"
      >
        <div @click="returnInRouter" class="return-btn-in-container">
          <button class="remove-btn-classes">
            <svg
              v-if="router.currentRoute.value.name !== 'wallet' && !router.currentRoute.value.fullPath.includes('dodaj-aukcje/4')"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#112BAF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-arrow-left"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </button>
          <span @click.stop class="style-title">{{
            router.currentRoute.value.name === "add-warehouse" ? customTitle() : router.currentRoute.value.meta.title
          }}</span>
        </div>
        <div
          v-if="
            router.currentRoute.value.name === 'add-auction' ||
            router.currentRoute.value.name === 'add-auction-second' ||
            router.currentRoute.value.name === 'add-auction-third' ||
            router.currentRoute.value.name === 'add-auction-test' ||
            router.currentRoute.value.name === 'add-auction-fourth'
          "
          class="stepper-in-auction-adding"
        >
          <p v-if="width > 500" class="stepper-in-auction-adding__title">Krok</p>
          <p
            class="stepper-in-auction-adding__step"
            :class="router.currentRoute.value.name === 'add-auction' ? 'stepper-in-auction-adding__step--active' : ''"
          >
            1
          </p>
          <!-- <p
            class="stepper-in-auction-adding__step"
            :class="router.currentRoute.value.name === 'add-auction-second' ? 'stepper-in-auction-adding__step--active' : ''"
          >
            2
          </p> -->
          <p
            class="stepper-in-auction-adding__step"
            :class="router.currentRoute.value.name === 'add-auction-second' ? 'stepper-in-auction-adding__step--active' : ''"
          >
            2
          </p>
          <p
            class="stepper-in-auction-adding__step"
            :class="router.currentRoute.value.name === 'add-auction-third' ? 'stepper-in-auction-adding__step--active' : ''"
          >
            3
          </p>
        </div>
        <router-view />
      </div>
      <div class="very-small-container" v-else>
        <router-view />
      </div>
    </div>
  </div>
  <div class="dialog" id="info-dialog" aria-hidden="true">
    <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </button>
      <slot name="modal-content ">
        <div style="padding: 50px 0">
          <table class="table table-bordered system-info-table">
            <tbody>
              <tr>
                <th scope="row">{{ $t("system.version") }}</th>
                <td>1.0</td>
              </tr>
              <tr>
                <th scope="row">{{ $t("system.client") }}</th>
                <td>
                  {{ currentCompany && currentCompany.id ? currentCompany.id : "-" }}
                </td>
              </tr>
              <tr>
                <th scope="row">{{ $t("system.company_name") }}</th>
                <td>
                  {{ currentCompany && currentCompany.companyName ? currentCompany.companyName : "-" }}
                </td>
              </tr>
              <tr>
                <th scope="row">{{ $t("system.name_surname") }}</th>
                <td>
                  {{ currentU && currentU.name && currentU.surname ? currentU.name + " " + currentU.surname : "-" }}
                </td>
              </tr>
              <tr>
                <th scope="row">E-mail</th>
                <td>{{ currentU && currentU.email ? currentU.email : "-" }}</td>
              </tr>
              <tr>
                <th scope="row">{{ $t("system.operating_system") }}</th>
                <td>{{ navigator.oscpu ?? navigator.platform }}</td>
              </tr>
              <tr>
                <th scope="row">{{ $t("system.browser_version") }}</th>
                <td>{{ navigator.userAgent }}</td>
              </tr>
              <tr>
                <th scope="row">{{ $t("system.browser_language") }}</th>
                <td>{{ navigator.language }}</td>
              </tr>
              <tr>
                <th scope="row">{{ $t("system.date") }}</th>
                <td>{{ new Date().toLocaleDateString("pl") }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </slot>
    </div>
  </div>
  <div class="dialog" id="ratings-dialog" aria-hidden="true">
    <div
      v-if="storeCache.get('verificationShown') && !storeCache.get('ratingsShown')"
      class="dialog-overlay"
      tabindex="-1"
      data-a11y-dialog-hide
    ></div>
    <div
      v-if="storeCache.get('verificationShown') && !storeCache.get('ratingsShown')"
      role="dialog"
      class="dialog-content"
      aria-labelledby="my-dialog-title"
      aria-describedby="my-dialog-description"
      style="max-width: 450px"
    >
      <button
        @click="storeCache.set('ratingsShown', true)"
        data-a11y-dialog-hide
        class="dialog-close"
        :aria-label="$t('admin.profile.settings.close_dialog')"
      >
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </button>
      <slot name="modal-content ">
        <rating-modal @refetch="handleRatingPlaced" v-if="ratings && ratings.length > 0" :auction="ratings[0]" />
      </slot>
    </div>
  </div>
  <div class="dialog" id="verification-dialog" aria-hidden="true">
    <!-- ADD data-a11y-dialog-hide next line-->
    <div class="dialog-overlay" tabindex="-1"></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <slot v-if="verificationsLeft > 0 && numbersArray.length > 0" name="modal-content ">
        <div class="verification-modal-content">
          <div style="font-weight: 700; font-size: 24px; text-align: center; letter-spacing: 0.03em; color: #1f222f">Weryfikacja</div>
          <h1 style="margin-bottom: 50px" class="phone-verification-title">
            {{ $t("phone_verifications.title") }}
          </h1>
          <p class="phone-number">
            {{
              `Numer telefonu ${
                numbersArray[verificationsLeft - 1].type === "USER"
                  ? $t("phone_verifications.user")
                  : numbersArray[verificationsLeft - 1].type === "COMPANY"
                  ? $t("phone_verifications.company") + numbersArray[verificationsLeft - 1].name
                  : $t("phone_verifications.department") + numbersArray[verificationsLeft - 1].name
              } `
            }}
            - <span style="color: black">{{ numbersArray[verificationsLeft - 1].phone }}</span>
          </p>
          <p @click="editingMode = !editingMode" class="change-number">
            {{ !editingMode ? $t("generals.change_number") : $t("generals.return") }}
          </p>
          <div v-if="showCodeInput && !editingMode" class="code-input control control--input">
            <label style="font-weight: 500; font-size: 12px" class="basic-label">{{ $t("phone_verifications.enter_code") }}</label>
            <input placeholder="Wpisz kod" v-model="code" type="text" />
            <div class="send-again-counter">
              <p @click="sendVerificationCode(numbersArray[verificationsLeft - 1])">
                {{ $t("phone_verifications.resend") }}
                {{ secondsLeft > 0 ? formatTime(secondsLeft) : "" }}
              </p>
            </div>
          </div>
          <div v-if="editingMode" class="code-input control control--input">
            <label style="font-weight: 500; font-size: 12px" class="basic-label">{{ $t("generals.change_number_enter") }}</label>
            <input v-model="newNumber" type="text" />
          </div>

          <div v-if="!showCodeInput && !editingMode" style="margin: 0 auto; width: fit-content; margin-top: 50px; display: flex; gap: 10px">
            <!-- <button
            @click="skipThrough"
            class="dialog__button dialog__button--alt"
          >
            {{ $t("generals.ignore") }}
          </button> -->
            <primary-button @click="userService.logout()" :medium="true" :title="$t('generals.logout')" />
            <primary-button
              @click="sendVerificationCode(numbersArray[verificationsLeft - 1])"
              :medium="true"
              :red="true"
              :filled="true"
              :title="$t('generals.verify')"
            />
            <!-- <button @click="userService.logout()" class="dialog__button dialog__button--alt blacked-out">
              {{ $t("generals.logout") }}
            </button>
            <button @click="sendVerificationCode(numbersArray[verificationsLeft - 1])" class="dialog__button dialog__button--alt">
              {{ $t("generals.verify") }}
            </button> -->
          </div>
          <div v-else-if="editingMode" style="margin: 0 auto; width: fit-content; margin-top: 50px; display: flex; gap: 10px">
            <primary-button @click="userService.logout()" :medium="true" :title="$t('generals.logout')" />

            <primary-button
              @click="changeNumber(numbersArray[verificationsLeft - 1])"
              :medium="true"
              :red="true"
              :filled="true"
              :title="$t('generals.edit')"
            />
          </div>
          <div v-else style="margin: 0 auto; width: fit-content; margin-top: 50px; display: flex; gap: 10px">
            <primary-button @click="userService.logout()" :medium="true" :title="$t('generals.logout')" />
            <primary-button
              @click="verify(numbersArray[verificationsLeft - 1])"
              :medium="true"
              :red="true"
              :filled="true"
              :title="$t('generals.verify')"
            />
          </div>
        </div>
      </slot>
    </div>
  </div>
  <verification-payment-modal
    @handleClose="
      () => {
        showVerificationModal = false;
      }
    "
    :show="showVerificationModal"
  />
  <department-missing v-if="showDepartmentMissingModal && dialogsMounted && verificationsLeft === 0"></department-missing>
  <tutorial-modal v-if="dialogsMounted && storeCache.get('verificationShown')" />
</template>

<script>
import "@/css/user-layout-styles.scss";
import "@/css/navbar.scss";
import { ref, provide, onMounted, onBeforeMount, computed, onBeforeUnmount, inject, watch } from "vue";
import Menu from "@/components/Menu";
import VueBasicAlert from "vue-basic-alert";
import { useAuthStore } from "@/stores/auth.store";
import { useUserStore } from "@/stores/user.store";
import { useVerificationStore } from "@/stores/phone.verification.store";
import { useCompanyStore } from "@/stores/company.store";
import { useDepartmentStore } from "@/stores/department.store";
import { useChatStore } from "@/stores/chat.store";
import { useNotificationStore } from "@/stores/notification.store";
import { useHelpersStore } from "@/stores/helpers.store";
import { redirect } from "./../js/utils";
import CustomPicker from "@/components/common/CustomPicker.vue";
import storeCache from "@/js/storeCache";
import UserService from "@/services/user.service";
import ExpandableIcon from "@/components/common/ExpandableIcon.vue";
import i18n from "@/i18n";
import router from "@/router";
import { typeOfCompany } from "@/js/utils";
import AlertHelper from "@/helpsers/alert.helper";
import { storeToRefs } from "pinia";
import NotificationList from "@/components/notifications/NotificationList.vue";
import { roleInCompany } from "@/js/utils";
import PrimaryButton from "@/components/common/PrimaryButton.vue";
import TutorialModal from "@/components/tutorial/TutorialModal.vue";
import breakpointsHelper from "@/helpsers/breakpoints.helper";
import VerificationBar from "@/components/VerificationBar.vue";
import { usePaymentStore } from "@/stores/payment.store";
import DepartmentMissing from "@/components/DepartmentMissing.vue";
import { useInitAdminStore } from "@/stores/admin/init/init.admin.store";
import { useRatingStore } from "@/stores/ratings.store";
import RatingModal from "@/components/stock/RatingModal.vue";
import VerificationPaymentModal from "@/components/verification/VerificationPaymentModal.vue";
export default {
  name: "UserLayout",
  components: {
    Menu,
    VueBasicAlert,
    CustomPicker,
    ExpandableIcon,
    PrimaryButton,
    NotificationList,
    TutorialModal,
    VerificationBar,
    RatingModal,
    DepartmentMissing,
    VerificationPaymentModal,
  },
  setup() {
    const alert = ref();
    provide("alert", alert);
    let options = ref([]);
    const { width } = breakpointsHelper();
    let toggleNotifications = ref(false);
    let refreshBar = ref(true);
    const emitter = inject("emitter");
    const userStore = useAuthStore();
    const companyStore = useCompanyStore();
    const paymentStore = usePaymentStore();
    const departmentStore = useDepartmentStore();
    const userService = new UserService();
    const notificationStore = useNotificationStore();
    const chatStore = useChatStore();
    const store = useUserStore();
    const helpersStore = useHelpersStore();
    const ratingsStore = useRatingStore();

    const { tasks } = storeToRefs(helpersStore);
    let navigator = window.navigator;
    const currentCompany = ref();
    const infoDialog = ref("");
    const ratingsDialog = ref("");
    const verificationDialog = ref("");
    const users = useUserStore();
    const verificationStore = useVerificationStore();
    const { numbersArray, verificationCompleted } = storeToRefs(verificationStore);
    const { ratings } = storeToRefs(ratingsStore);
    let { unreadChatsCounter, unreadNotificationsCounter } = storeToRefs(chatStore);
    const { counter, notifications } = storeToRefs(notificationStore);
    const { numberOfUnseen, companyVerificationMade, companyVerificationPending } = storeToRefs(chatStore);
    let currentU = ref(storeCache.get("currentUser"));
    let profilePic = ref({ pic: storeCache.get("profile_pic") });
    let image = ref(window.document.getElementById("profile_photo_placeholder"));
    let currentTime = ref("");
    let verificationsLeft = ref(0);
    let showCodeInput = ref(false);
    let code = ref("");
    let secondsLeft = ref(0);
    let editingMode = ref(false);
    let showVerificationModal = ref(false);
    let newNumber = ref("");
    let notificationInterval = ref("");
    let hideAssistant = ref(false);
    let showDepartmentMissingModal = ref(false);
    let mousemoveTimeout = ref("");
    let mousemoveInterval = ref("");
    let mousemoveTimeoutSeconds = ref(600);
    const initStore = useInitAdminStore();

    function getTutorialStorage() {
      const storeData = storeCache.get("tutorial_done");
      if (!storeData) {
        return {};
      }
      return storeData;
    }
    const tutorialsDone = getTutorialStorage();
    emitter.on("closeMenu", () => {
      hideAssistant.value = true;
    });
    emitter.on("openMenu", () => {
      hideAssistant.value = false;
    });
    emitter.on("refreshDepartmentModal", () => {
      showDepartmentMissingModal.value = false;
    });
    emitter.on("updateVerificationTransfer", async () => {
      setTimeout(async () => {
        refreshBar.value = false;
        let tmp = await companyStore.fetchOneCompany(currentCompany.value.id);
        let role = await companyStore.getRoleInCompany(currentCompany.value.id);
        if (role) storeCache.set("roleInCurrentCompany", role);
        storeCache.set("currentCompany", tmp);
        currentCompany.value = tmp;
        refreshBar.value = true;
      }, 2000);
    });
    watch(companyVerificationMade, async () => {
      setTimeout(async () => {
        refreshBar.value = false;
        if (!currentCompany.value?.id) {
          return;
        }
        let tmp = await companyStore.fetchOneCompany(currentCompany.value.id);
        let role = await companyStore.getRoleInCompany(currentCompany.value.id);
        if (role) storeCache.set("roleInCurrentCompany", role);
        storeCache.set("currentCompany", tmp);
        currentCompany.value = tmp;
        refreshBar.value = true;
      }, 2000);
    });
    watch(companyVerificationPending, async () => {
      setTimeout(async () => {
        refreshBar.value = false;
        let tmp = await companyStore.fetchOneCompany(currentCompany.value.id);
        let role = await companyStore.getRoleInCompany(currentCompany.value.id);
        if (role) storeCache.set("roleInCurrentCompany", role);
        storeCache.set("currentCompany", tmp);
        currentCompany.value = tmp;
        refreshBar.value = true;
      }, 2000);
    });

    let hourNow = computed(() => {
      var today = currentTime.value;
      if (today) {
        var minutes = parseInt(today.getMinutes()) < 10 ? "0" + today.getMinutes() : today.getMinutes();
        var hours = parseInt(today.getHours()) < 10 ? "0" + today.getHours() : today.getHours();
        var time = hours + ":" + minutes;
      }
      return time;
    });
    let dateNow = computed(() => {
      var today = currentTime.value;
      if (today) {
        var day = today.getDate() > 9 ? today.getDate() : "0" + today.getDate();
        var month = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : "0" + (today.getMonth() + 1);

        var date = day + "-" + month + "-" + today.getFullYear();
      }

      return date;
    });
    let userProfileOptions = ref([
      {
        src: "profile",
        label: i18n.global.t("menu.my_profile"),
        path: "/edit",
      },
      // {
      //   src: "language",
      //   label: i18n.global.t("menu.language"),
      //   path: "language",
      // },
      {
        src: "settings",
        label: i18n.global.t("profile_settings.settings"),
        path: "profile-settings",
      },
      {
        src: "info",
        label: i18n.global.t("system.system"),
        path: "",
      },
      {
        src: "blocks",
        label: i18n.global.t("system.blocked"),
        path: "blocks",
      },
      // {
      //   src: "agreement",
      //   label: i18n.global.t("generals.agreement"),
      //   path: "",
      // },
      {
        src: "file",
        label: i18n.global.t("generals.invoices"),
        path: "invoices",
      },
      // {
      //   src: "wallet",
      //   label: "Portfel",
      //   path: "wallet",
      // },
      // {
      //   src: "widgets",
      //   label: i18n.global.t("menu.widgets"),
      //   path: "widgets",
      // },
    ]);

    let numbers = [
      {
        phone: "123123123",
        type: "DEPARTMENT",
        departmentName: "Jakis tam",
      },
      {
        phone: "838929838",
        type: "COMPANY",
      },
      {
        phone: "727838929",
        type: "USER",
      },
    ];

    onBeforeMount(async () => {
      if (storeCache.get("token")) {
        chatStore.connect(storeCache.get("token"), null);
      }
      //SETTING INITIAL RECORDS PER PAGE NUMBER
      if (!storeCache.get("recordsNumber")) {
        storeCache.set("recordsNumber", 20);
      }
      //SETTING INITIAL RECORDS PER PAGE NUMBER
      if (window.innerWidth < 850) hideAssistant.value = true;
      window.addEventListener("show-500-error", () => {
        AlertHelper.show(alert, i18n.global.t("auth.server_error_short"), i18n.global.t("auth.server_error_try_again"), "error");
      });
      currentU.value = storeCache.get("currentUser");
      //IF ADMIN THEN REDIRECT
      if (currentU.value && currentU.value.role === "ADMIN") router.push({ name: "admin.companies" });
      //IF ADMIN THEN REDIRECT

      if (currentU.value && currentU.value.role !== "ADMIN") {
        await chatStore.getUnseenMessages();
        //CLOSE NOTIFICATION WINDOW ON CLICK
        window.addEventListener("click", (e) => {
          if (
            e.target.id !== "notification-bell" &&
            e.target.id !== "set-all-as-read" &&
            e.target.id !== "mark-as-read" &&
            e.target.id !== "notifications-header" &&
            e.target.id !== "notification-select" &&
            e.target.id !== "notification-option" &&
            e.target.ownerSVGElement?.id !== "notification-bell" &&
            (e.target.innerText !== e.target.id) !== "mark-as-read"
          ) {
            if (toggleNotifications.value === true) toggleNotifications.value = false;
          }
        });
        //CLOSE NOTIFICATION WINDOW ON CLICK

        //CLOCK INTERVAL
        window.setInterval(() => {
          currentTime.value = new Date();
        }, 1000);
        //CLOCK INTERVAL
        //INITIAL PAGE RELOAD
        if (storeCache.get("initial_reload")) {
          storeCache.remove("initial_reload");
          window.location.reload();
        }
        //INITIAL PAGE RELOAD
        await initStore.fetchInitDepartment();
        await initStore.fetchInitCompany();

        if (currentU.value && currentU.value.role !== "ADMIN") {
          await users.fetchUserCompanies();
        }

        if (currentU.value && currentU.value.avatar === null) {
          storeCache.remove("profile_pic");
        }

        if (image.value !== null) {
          setTimeout(() => {
            image.value.src = storeCache.get("profile_pic");
          }, 500);
        } else await users.fetchCurrentUser();
        infoDialog.value = new A11yDialog(document.querySelector("#info-dialog"));
        ratingsDialog.value = new A11yDialog(document.querySelector("#ratings-dialog"));
        currentCompany.value = storeCache.get("currentCompany");
        if (currentCompany.value && currentCompany.value.type === "FACTORY" && !storeCache.get("ratingsShown")) {
          await ratingsStore.fetchUnratedAuctions(currentCompany.value?.id);
          if (ratings.value.length > 0) {
            ratingsDialog.value.show();
          }
        }
        if (currentCompany.value?.withDepartment === false) {
          setTimeout(() => {
            showDepartmentMissingModal.value = true;
          }, 1500);
        }

        //MAKE SURE WALLET WONT SHOW TO WAREHOUSE
        if (typeOfCompany() !== "DRIVER") {
          userProfileOptions.value = userProfileOptions.value.filter((o) => {
            if (o && o.src && o.src !== "wallet" && o.src !== "file") return o;
          });
        }
        // if (typeOfCompany() === "FACTORY") {
        //   userProfileOptions.value.push({
        //     src: "agreement",
        //     path: "manage-agreements",
        //     label: "Warunki",
        //   });
        // }
        //MAKE SURE WALLET WONT SHOW TO WAREHOUSE
      }
      const companyId = storeCache.get("currentCompany")?.id;

      if (companyId) {
        await notificationStore.fetchNumberOfNotifications(companyId);
      }
      setTimeout(() => {
        helpersStore.adjustTasks();
      }, 1000);
    });

    // some hack to avoid layout shift when displaying tutorial modal
    const dialogsMounted = ref(false);
    onMounted(async () => {
      currentCompany.value = storeCache.get("currentCompany");

      currentU.value = storeCache.get("currentUser");
      if (currentU.value && currentU.value.role !== "ADMIN") {
        verificationDialog.value = new A11yDialog(document.querySelector("#verification-dialog"));
        if (storeCache.get("verificationSnackbar")) {
          AlertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("phone_verifications.verified"), "success");
          storeCache.remove("verificationSnackbar");
        }
        if (storeCache.get("numberChanged")) {
          AlertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("phone_verifications.edited"), "success");
          storeCache.remove("numberChanged");
        }
        if (!storeCache.get("verificationShown")) {
          await verificationStore.fetchUnverifiedNumbers();
          setTimeout(() => {
            verificationsLeft.value = numbersArray.value.length;
            if (verificationsLeft.value > 0) {
              verificationDialog.value.show();
            } else {
              storeCache.set("verificationShown", true);
            }
          }, 1500);
        }
      }

      dialogsMounted.value = true;
    });

    emitter.on("companyChange", () => {
      dialogsMounted.value = false;
    });
    const skipThrough = () => {
      verificationsLeft.value--;
      if (verificationsLeft.value === 0) {
        storeCache.set("verificationShown", true);
        verificationDialog.value.hide();
      }
    };
    const verify = async (phone) => {
      const obj = {
        id: phone.id,
        type: phone.type,
        code: code.value,
      };
      await verificationStore.verifyNumber(obj);
      if (verificationCompleted.value) {
        verificationDialog.value.hide();
        secondsLeft.value = 0;
        showCodeInput.value = false;
        code.value = "";
        storeCache.set("verificationSnackbar", true);
        window.location.reload();

        // setTimeout(() => {
        //   console.log("NEW NUMBERS ~~~~~~~~~");
        //   console.log(numbersArray.value);
        //   verificationsLeft.value = numbersArray.value.length;
        //   if (verificationsLeft.value > 0) {
        //     verificationDialog.value.show();
        //   } else {
        //     storeCache.set("verificationShown", true);
        //     verificationDialog.value.hide();
        //   }
        // }, 1500);

        // verificationsLeft.value--;

        // if (verificationsLeft.value === 0) {
        //   storeCache.set("verificationShown", true);
        //   verificationDialog.value.hide();
        // }
      } else {
        AlertHelper.show(alert, i18n.global.t("generals.error"), i18n.global.t("phone_verifications.wrong_code"), "error");
      }
    };
    const sendVerificationCode = async (e) => {
      showCodeInput.value = true;

      if (secondsLeft.value === 0) {
        // Get a reference to the last interval + 1
        const interval_id = window.setInterval(function () {}, Number.MAX_SAFE_INTEGER);
        // Clear any timeout/interval up to that id
        for (let i = 1; i < interval_id; i++) {
          window.clearInterval(i);
        }
        let obj = {
          type: e.type,
          alert,
        };
        if (obj.type !== "USER") obj.id = e.id;
        await verificationStore.generateCode(obj);
        secondsLeft.value = 60;
        setInterval(() => {
          if (secondsLeft.value > 0) secondsLeft.value--;
        }, 1000);
      } else {
        AlertHelper.show(alert, i18n.global.t("generals.error"), i18n.global.t("phone_verifications.wait"), "error");
      }
    };
    const formatTime = (counter) => {
      let minutes = Math.floor(counter / 60);
      let seconds = counter - minutes * 60;
      let string = " (za " + minutes + ":" + (seconds >= 10 ? seconds : "0" + seconds) + ")";
      return string;
    };
    const formatTimeSimple = (counter) => {
      let minutes = Math.floor(counter / 60);
      let seconds = counter - minutes * 60;
      let string = (minutes >= 10 ? minutes : "0" + minutes) + ":" + (seconds >= 10 ? seconds : "0" + seconds);
      return string;
    };

    const redirectAction = (e) => {
      if (e === "info") infoDialog.value.show();
      else {
        userProfileOptions.value.forEach((option) => {
          if (option.src === e) redirect(option.path);
        });
      }
    };
    const toggleFullScreen = () => {
      if (!document.fullscreenElement) {
        var element = document.querySelector("body");
        element
          .requestFullscreen()
          .then(function () {
            // element has entered fullscreen mode successfully
          })
          .catch(function (error) {
            // element could not enter fullscreen mode
          });
      } else {
        document.exitFullscreen();
      }
    };

    const changeNumber = async (e) => {
      let regex = /^(?=(.{1,20})$)((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

      if (regex.test(newNumber.value)) {
        try {
          if (e.type === "COMPANY") {
            const data = {
              companyData: {
                companyPhone: newNumber.value,
              },
            };
            await companyStore.editCompany(e.id, data);
            storeCache.set("numberChanged", true);
            location.reload();
          }
          if (e.type === "DEPARTMENT") {
            const data = {
              departmentPhone: newNumber.value,
              id: e.id,
            };
            const res = await departmentStore.editDepartment(data);
            if (res) {
              storeCache.set("numberChanged", true);
              location.reload();
            }
          }
          if (e.type === "USER") {
            const data = {
              userPhone: newNumber.value,
            };
            await store.updateUser(data);
            storeCache.set("numberChanged", true);
            location.reload();
          }
        } catch (e) {
          AlertHelper.show(alert, i18n.global.t("generals.error"), e, "error");
        }
      } else {
        AlertHelper.show(alert, i18n.global.t("phone_verifications.wrong_phone"), "", "error");
      }
    };
    const startCompanyVerificationPayment = () => {
      //something else !!
      showVerificationModal.value = true;
      //paymentStore.startTransactionForVerification(currentCompany.value.id);
    };
    onBeforeUnmount(() => {
      window.removeEventListener("show-500-error", () => {
        AlertHelper.show(alert, i18n.global.t("auth.server_error_short"), i18n.global.t("auth.server_error_try_again"), "error");
      });
      window.removeEventListener("click", (e) => {
        if (
          e.target.id !== "notification-bell" &&
          e.target.id !== "set-all-as-read" &&
          e.target.id !== "mark-as-read" &&
          e.target.id !== "notifications-header" &&
          e.target.id !== "notification-select" &&
          e.target.id !== "notification-option" &&
          e.target.ownerSVGElement?.id !== "notification-bell" &&
          e.target.id !== "mark-as-read"
        ) {
          if (toggleNotifications.value === true) toggleNotifications.value = false;
        }
      });
    });

    const customTitle = () => {
      return typeOfCompany() === "WAREHOUSE" || typeOfCompany() === "FACTORY"
        ? i18n.global.t("routes.user.add_warehouse")
        : i18n.global.t("routes.user.add_department");
    };
    const mouseMoved = () => {
      // mousemoveTimeoutSeconds.value = 600;
      // clearTimeout(mousemoveTimeout.value);
      // clearInterval(mousemoveInterval.value);
      //mousemoveTimeout.value = setTimeout(() => {
      //   userService.logout();
      // }, 10 * 60 * 1000);
      //  mousemoveInterval.value = setInterval(() => {
      //   mousemoveTimeoutSeconds.value = mousemoveTimeoutSeconds.value - 1;
      //   }, 1000);
    };
    const handleRatingPlaced = () => {
      storeCache.set("ratingsShown", true);
      location.reload();
    };
    const returnInRouter = () => {
      if (router.currentRoute.value.name === "calendar") {
        router.go(-1);
      }
      router.go(-1);
    };
    return {
      returnInRouter,
      toggleNotifications,
      handleRatingPlaced,
      mousemoveTimeoutSeconds,
      mouseMoved,
      alert,
      customTitle,
      refreshBar,
      userStore,
      redirect,
      storeCache,
      profilePic,
      showVerificationModal,
      currentU,
      roleInCompany,
      options,
      userProfileOptions,
      redirectAction,
      hourNow,
      dateNow,
      toggleFullScreen,
      currentCompany,
      navigator,
      width,
      router,
      verificationsLeft,
      numbers,
      skipThrough,
      sendVerificationCode,
      showCodeInput,
      code,
      secondsLeft,
      formatTimeSimple,
      formatTime,
      verify,
      numbersArray,
      userService,
      editingMode,
      newNumber,
      changeNumber,
      counter,
      numberOfUnseen,
      dialogsMounted,
      storeCache,
      helpersStore,
      tasks,
      tutorialsDone,
      hideAssistant,
      showDepartmentMissingModal,
      startCompanyVerificationPayment,
      unreadNotificationsCounter,
      unreadChatsCounter,
      ratings,
      companyVerificationMade,
      companyVerificationPending,
    };
  },
};
</script>
<style lang="scss" scoped>
.table {
  font-size: 14px;
}
.phone-number {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  text-align: center;

  padding: 0 10px;
  color: rgba(31, 34, 47, 0.5);
  @media screen and (max-width: 500px) {
    text-align: center;
  }
}
.change-number {
  font-size: 18px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  color: #112baf;
}
.phone-verification-title {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #1f222f;
  @media screen and (max-width: 500px) {
    font-size: 14px;
    text-align: center;
  }
}

.blacked-out {
  background: rgb(220, 218, 218);
}
.code-input {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 20px;
  margin: 10px 0;
  margin: 0 auto;
  input {
    margin: 0 auto;

    border-radius: 5px;
    padding: 2px 10px;
  }
  label {
    margin: 0 auto;

    font-size: 16px;
    font-weight: 400;
  }
  .send-again-counter {
    font-size: 14px;
    text-decoration: underline;
    color: #112baf;
    font-weight: 500;
    justify-self: flex-end;
    text-align: right;
    margin-right: 30px;
    p {
      cursor: pointer;
      width: fit-content;
      margin-left: auto;
    }
  }
}
.system-info-table {
  padding: 50px 0;
  th {
    max-width: 150px;
    @media screen and (max-width: 400px) {
      max-width: 100px;
    }
  }
}
.notification-bell,
.messages-wrapper {
  position: relative;
  cursor: pointer;
  margin: 0 5px;
  transition: 0.5s all;
  &:hover {
    transform: scale(1.2);
  }
  .notifications-counter,
  .messages-counter {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    text-align: center;
    top: -5px;
    right: 0;
    background: #112baf;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 999px;
    font-weight: 600;
    font-size: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: grid;
    place-items: center;
  }
}
.very-small-container {
  margin-top: 74px;
}
.contain {
  margin: auto;
  margin-top: 72px;
  padding: 0px 0px;
  height: fit-content;
  border-radius: 10px;
  position: relative;
  min-height: 83%;
  min-width: 1800px;
  overflow-x: hidden;
  padding-top: 30px;
  max-width: 1800px;
  @media screen and (max-width: 2300px) {
    min-width: 70vw;
    max-width: unset;
    width: 100% !important;
  }
  // @media screen and (max-width: 1300px) {
  //   min-height: 100%;
  // }
  @media screen and (max-width: 850px) {
    min-height: unset;
    max-height: unset;
    overflow: hidden;
  }
  .return-btn-in-container {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0px;
    left: 4px;
    z-index: 1;
    @media screen and (max-width: 850px) {
      top: 10px;
    }
    svg {
      width: 20px;
      height: 20px;
    }
    button {
      outline: none;
      border: none;
    }
  }

  @media screen and (max-width: 1350px) {
    padding: 16px;
    padding-top: 60px;
  }
  @media screen and (max-width: 850px) {
    padding: 16px 15px;
    padding-top: 60px;
    margin: auto;
    margin-top: 62px;
    min-width: 100vw;
  }
  @media screen and (max-width: 450px) {
    padding: 16px 10px;
    padding-top: 60px;
  }
  @media screen and (max-width: 400px) {
    padding: 16px 0px;
    padding-top: 60px;
  }
}
.container-with-background {
  background: #fff;
  margin-top: 74px !important;
  padding: 30px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
  @media screen and (max-width: 500px) {
    padding: 30px 15px;
  }
  .return-btn-in-container {
    top: 10px;
  }
}

.no-padding {
  padding: 0px 10px !important;
  padding-top: 30px !important;
}
.container-transparent {
  height: 100%;
}
.remove-btn-classes {
  background: none;
}
.style-title {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.03em;
  color: #112baf;
}
.verification-modal-content {
  padding: 20px 30px;
}
.basic-label {
  margin-top: 20px;
  font-weight: 500;
  font-size: 12px;
  color: #1f222f;
}
.navbar-logo {
  display: block;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
}
.navbar-logo-small {
  display: none;
  margin-left: 0px;
  @media screen and (max-width: 600px) {
    display: block;
  }
  @media screen and (max-width: 450px) {
    display: none;
  }
}
.configurartion-assistant {
  transition: 0.5s all;
  position: fixed;
  cursor: pointer;
  bottom: 0px;
  left: 0px;
  padding-bottom: 10px;
  padding-left: 31px;
  padding-right: 40px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: rgba(165, 165, 165, 0.8);
  p {
    margin: 0;
  }
}
.configuration-active {
  background: rgba(53, 97, 255, 0.05);
  color: #112baf;
  span {
    color: rgba(165, 165, 165, 0.8);
  }
}
.normal-container {
  height: 83% !important;
  min-height: 83% !important;
  @media screen and (max-height: 850px) {
    height: 80% !important;
    min-height: 80% !important;
  }
  // @media screen and (max-width: 1300px) {
  //   height: 100% !important;
  //   min-height: 100% !important;
  // }
  @media screen and (max-width: 850px) {
    height: unset !important;
    min-height: unset !important;
    max-height: unset !important;
    overflow: hidden !important;
  }
}
.smaller-container {
  height: 85% !important;
  max-height: 85% !important;
  min-height: 85% !important;

  @media screen and (max-height: 1500px) {
    height: 80% !important;
    max-height: 80% !important;
    min-height: 80% !important;
  }
  @media screen and (max-height: 1100px) {
    height: 75% !important;
    max-height: 75% !important;
    min-height: 75% !important;
  }
  @media screen and (max-height: 850px) {
    height: 73% !important;
    max-height: 73% !important;
    min-height: 73% !important;
  }
  @media screen and (max-height: 800px) {
    height: 72% !important;
    max-height: 72% !important;
    min-height: 72% !important;
  }
  @media screen and (max-height: 750px) {
    height: 70% !important;
    max-height: 70% !important;
    min-height: 70% !important;
  }
  // @media screen and (max-width: 1300px) {
  //   height: 100% !important;
  //   max-height: 100% !important;
  //   min-height: 100% !important;
  // }
  @media screen and (max-width: 850px) {
    height: unset !important;
    min-height: unset !important;
    max-height: unset !important;
    overflow: hidden !important;
  }
}
</style>
