class SignatureService {
  findDriverSignatureId = (files: { id: string; type: string }[]) => {
    if (!files) return;
    const found = files.find((file) => file.type === "DRIVER_SIGNATURE");

    if (found) return found;
    return null;
  };
  findSenderSignatureId = (files: { id: string; type: string }[]) => {
    if (!files) return;
    const found = files.find((file) => file.type === "SENDER_SIGNATURE");

    if (found) return found;
    return null;
  };
  findReceiverSignatureId = (files: { id: string; type: string }[]) => {
    if (!files) return;
    const found = files.find((file) => file.type === "RECEIVER_SIGNATURE");

    if (found) return found;
    return null;
  };
}

export default SignatureService;
