const routes = {
    admin: {
      auctionPayment:"Prowizja od licytacji",
      documents:"Dokumenty do weryfikacji",
      profile: "Profil",
      adverts: "Reklamy",
      profile_settings: "Ustawienia profilu",
      companies: "Firmy",
      time_window: "Awizacje",
      workers: "Pracownicy",
      invoices: "Faktury",
      tenders: "Przetargi",
      price: "Cennik",
      subscription: "Abonament",
      company: "Firma",
      department: "Oddział",
      group_chat: "Grupowa wiadomość",
      group_chats: "Czaty",
      chats: "Czaty",
      chat: "Czat",
      raports: "Raporty",
      add_worker: "Dodawanie pracownika",
      add_wroker_to_department: "Dodawanie pracownika do oddziału",
      add_vehicle: "Dodawanie pojazdu",
      add_documents: "Dodawanie dokumentów",
      add_department: "Dodawanie oddziału",
      factoring: "Wykaz faktoringu",
      auctions: "Licytacje",
      users: "Użytkownicy",
      verificationPayments:"Przelewy weryfikacyjne"
    },
    auth: {
      register: "Rejestracja",
      login: "Logowanie",
      forgot_password: "Przypomnij hasło",
      reset_password: "Zmiana hasła",
      verification: "Weryfikacja konta",
      confirm_registration: "Potwierdzenie rejestracji",
    },
    not_found: "Nie znaleziono strony",
    user: {
      dashboard: "Pulpit",
      profile: "Mój profil",
      company: "Firma",
      settings: "Ustawienia",
      add_document: "Dodaj dokument",
      add_worker: "Dodaj pracownika",
      add_warehouse: "Dodaj magazyn",
      add_department: "Dodaj oddział",
      workers: "Pracownicy",
      warehouses: "Magazyny",
      departments: "Oddziały",
      calendar: "Kalendarz",
      fill_calendar: "Uzupełnij kalendarz",
      pick_calendar: "Wybierz kalendarz",
      edit_day: "Edytuj dzień",
      add_user: "Dodaj użytkownika",
      language: "Język",
      driver_departments: "Lista przewoźników",
      department_details: "Szczegóły oddziału",
      documents: "Lista dokumentów",
      department_workers: "Pracownicy oddziału",
      warehouse_workers: "Pracownicy magazynu",
      department_invite: "Zaproszenie do oddziału",
      authorization: "Autoryzacja",
      authorizations: "Autoryzacje",
      add_car: "Dodaj auto",
      cars: "Auta",
      insurances: "Ubezpieczenia",
      add_monitary: "Awizacja",
      car_statuses: "Statusy aut",
      accept_monitary: "Potwierdzenie awizacji",
      finish_monitary: "Zakończenie awizacji",
      monitary_list: "Lista awizacji",
      monitary_archive: "Archiwum awizacji",
      monitary_edit: "Edycja awizacji",
      stock: "Giełda",
      add_auction: "Dodaj licytację",
      wallet: "Mój portfel",
      payment_processing: "Przetwarzanie płatności",
      widgets_settings: "Ustawienia widżetów",
      notifications: "Powiadomienia",
      chat_list: "Wiadomości",
      chat: "Czat",
      offers: "Przydzielone licytacje",
      won_auctions: "Przydzielone transporty",
      lost_auctions: "Nieotrzymane transporty",
      canceled_transports: "Anulowane transporty",
      current_auctions: "Uczestniczące licytacje",
      surcharges: "Aktualne dopłaty",
      my_auctions: "Moje licytacje",
      auction_archive: "Archiwum zleceń",
      edit_auction: "Edytuj aukcję",
      cancelled_auctions: "Anulowane licytacje",
      contract_terms: "Warunki umowy",
      group_making: "Tworzenie grupy",
      participants_list: "Wykaz uczestników",
      added_driver_groups: "Utworzone grupy przewoźników",
      invoices: "Faktury",
      blocked_users: "Zablokowani",
      factoring: "Faktoring",
    },
  };
  export default routes;
  