import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import authHelper from "@/helpsers/auth.helper";
import storeCache from "@/js/storeCache";
import { computed, inject, ref } from "vue";
import { Department } from "./department.store";
import AlertHelper from "@/helpsers/alert.helper";
import { parseError } from "@/js/error";
import { RegularErrorResponse } from "./utils/interfaces";

export interface UploadFile {
  file: File;
  description: string;
  visibleToAll: boolean;
  type?: string;
  mainPageId?: string;
}

export interface Address {
  companyAddress: string;
  companyCity: string;
  companyCountry: string;
  companyZipCode: string;
  id: string;
  createdAt?: string;
  modifiedAt?: string;
}

export interface Avatar {
  id: string;
  originalname: string;
  isCompress: boolean;
  createdAt: string;
  modifiedAt: string;
  compressFileId: string;
  customName: string | null;
  visibleToAll: boolean;
  description: string | null;
  type: string;
}

export interface User {
  id: string;
  subId: string | null;
  name: string;
  surname: string;
  avatar: Avatar | null;
  createdAt: string;
  modifiedAt: string;
  userPhone: string;
  email?: string;
  allowSms?: boolean;
  userCompany?: any[];
}

export interface UserCompany {
  id: string;
  createdAt: string;
  modifiedAd: string;
  role: "OWNER" | "WORKER" | "ADMINISTRATOR";
  user: User;
}

export interface Documents {
  id: string;
  originalname: string;
  isCompress: boolean;
  createdAt: string;
  modifiedAt: string;
  compressFileId: string;
  customName: string | null;
  visibleToAll: boolean;
  mainPageId?: string;
  description: string | null;
  type: string;
  lp?: number;
}

export interface Company {
  id: string;
  isAccountVerified?: boolean;
  status?: string;
  mainDepartmentId?: string;
  note?: string;
  subId: string | null;
  createdAt: string;
  modifiedAt: string;
  verificationTransferConfirmed?: boolean;
  companyName: string;
  companyNip: string;
  companyPhone: string;
  type: string;
  monitoryNotes: any[];
  authorizations: any[];
  allowSms?: boolean;
  addresses?: Address[];
  userCompany: UserCompany[];
  documents: Documents[];
  companyAvatar: Avatar;
  avatar?: Avatar;
  companyCity?: string;
  companyZipCode?: string;
  companyAddress?: string;
  departments?: Array<Department>;
  likes?: number;
  dislikes?: number;
}

export const useCompanyStore = defineStore("company", () => {
  const userCompanies = ref<Array<Company>>([]);
  const currentCompany = ref<Company>();
  const documents = ref([]);
  const taskDocuments = ref([]);
  const workerCounter = ref<number>(0);
  const ownerCounter = ref<number>(0);
  const page = ref<number>(1);
  const onPage = ref<number>(5);
  const total = ref<number>(0);
  const isLoading = ref(false);
  const emitter = inject("emitter");
  const alert = inject("alert");

  async function fetchUserCompanies() {
    if (storeCache.get("token") && storeCache.get("token") !== "null") {
      try {
        const res = await axios.get(ApiHelper.getUserCompaniesUrl());
        userCompanies.value = res.data.data;
      } catch (err) {
        AlertHelper.show(alert, parseError(err), "", "error");
      }
    }
  }

  async function getCompanyDocuments(companyId: string, isTask?: boolean, query?: string) {
    const url2 = `?limit=${onPage.value}&page=${
      page.value
    }&onlyForMembers=true&fileType[0]=KRS_CDiG&fileType[1]=FILE&fileType[2]=TRANSPORT_LICENSE&fileType[3]=CARRIER_INSURANCE&fileType[4]=CAR_POLICY${
      query ? `&query=${query}` : ""
    }`;
    const url = ApiHelper.getDocuments(companyId) + url2;
    try {
      const response = await axios.get(url);
      if (response.data && response.data.data) {
        total.value = response.data.meta.total;
        onPage.value = response.data.meta.per_page;
        page.value = response.data.meta.current_page;
        if (isTask) {
          taskDocuments.value = response.data.data;
        } else {
          documents.value = response.data.data.map((doc: any) => {
            delete doc.isCompress;
            delete doc.createdAt;
            delete doc.modifiedAt;
            delete doc.compressFileId;
            delete doc.customName;
            delete doc.visibleToAll;
            return doc;
          });
        }
      }
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function hasKRSFile(companyId: string) {
    const url2 = `?limit=${onPage.value}&page=${page.value}&onlyForMembers=true&fileType[0]=KRS_CDiG`;
    const url = ApiHelper.getDocuments(companyId) + url2;
    try {
      const response = await axios.get(url);
      if (response && response.data.data && response.data.data.length > 0 && response.data.data[0]?.isVerified) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  async function getCompanyDocumentsPublic(companyId: string, urlIncoming?: string) {
    const url2 = `?limit=${onPage.value}&page=${page.value}&fileType[0]=KRS_CDiG&fileType[1]=FILE&fileType[2]=TRANSPORT_LICENSE&fileType[3]=CARRIER_INSURANCE&fileType[4]=CAR_POLICY`;
    const url = `${ApiHelper.getDocuments(companyId)}${urlIncoming ?? url2}`;

    try {
      const response = await axios.get(url);
      if (response.data && response.data.data) {
        total.value = response.data.meta.total;
        onPage.value = response.data.meta.per_page;
        page.value = response.data.meta.current_page;
        documents.value = response.data.data.map((doc: any) => {
          delete doc.isCompress;
          delete doc.createdAt;
          delete doc.modifiedAt;
          delete doc.compressFileId;
          delete doc.customName;
          delete doc.visibleToAll;
          return doc;
        });
      }
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function fetchOneCompany(companyId: number | string) {
    if (!companyId || companyId === "undefined") return;
    try {
      const response = await axios.get(`${ApiHelper.getCompany()}${companyId}`);
      if (!storeCache.get("blockCompanySetting")) currentCompany.value = response.data;
      else storeCache.remove("blockCompanySetting");
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function editCompany(companyId: number, obj: any) {
    try {
      const response = await axios.put(
        `${ApiHelper.updateCompany()}${companyId}`,
        {
          ...obj,
        },
        { headers: authHelper.authHeader() }
      );
      return response.data;
    } catch (err: any) {
      AlertHelper.show(alert, parseError(err), "", "error");
      throw err.response.data.message;
    }
  }
  async function checkCompanyDocuments(companyId: string) {
    try {
      const response = await axios.get(ApiHelper.checkCompanyDocuments(companyId));
      return response.data.status;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
      //@ts-ignore
      emitter.emit("showPopup", {
        message: parseError(e),
        type: "error",
        button: {
          label: "Lista dokumentów",
          redirect: "documents",
        },
      });
    }
  }

  async function uploadCompanyPicture(img: File, companyId: string, type: string) {
    const request = new FormData();
    const url = ApiHelper.getUploadProfileImageUrl();

    request.append("file", img);
    request.append("type", type);
    request.append("visibleToAll", "true");
    request.append("companyId", companyId);

    try {
      await axios.post(url, request);
    } catch (err) {
      AlertHelper.show(alert, parseError(err), "", "error");
      throw err;
    }
  }
  async function getCompanyPicture(fileId: string) {
    if (!fileId) return;
    const url = ApiHelper.getImage(fileId);
    const response = await axios
      .get(url, {
        responseType: "blob",
      })
      .then(
        (res: any) =>
          new File([res.data], `picture${new Date().getTime()}.jpg`, {
            type: "image/jpg",
          })
      )
      .catch((err: RegularErrorResponse) => {
        AlertHelper.show(alert, parseError(err), "", "error");
      });
    return response;
  }

  async function getCompanyFIle(fileId: string) {
    const url = ApiHelper.getImage(fileId);
    try {
      const response = await axios.get(url, {
        responseType: "blob",
      });
      return response.data;
    } catch (err) {
      AlertHelper.show(alert, parseError(err), "", "error");
    }
  }

  async function deleteCompanyFile(fileId: string) {
    const url = ApiHelper.deletePicture(fileId);
    try {
      await axios.delete(url);
    } catch (err) {
      throw err;
    }
  }
  async function checkNip(nip: string) {
    const url = ApiHelper.checkNip(nip);
    try {
      const res = await axios.get(url);
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async function addCompanyFile(obj: UploadFile, companyId: string) {
    const { description, file, visibleToAll, type } = obj;
    const request = new FormData();
    const url = ApiHelper.getUploadProfileImageUrl();
    request.append("file", file);
    request.append("type", type || "FILE");
    request.append("visibleToAll", `${visibleToAll}`);
    request.append("companyId", companyId);
    request.append("description", description);
    if (obj.mainPageId) request.append("mainPageId", obj.mainPageId);
    try {
      await axios.post(url, request);
    } catch (err) {
      throw err;
    }
  }

  async function editCompanyType(companyId: string, newType: string) {
    try {
      await axios.put(ApiHelper.editCompanyType(companyId), {
        companyData: {
          type: newType,
        },
      });
    } catch (err) {
      //@ts-ignore
      throw err.response;
    }
  }
  async function getOwnersNumber(companyId: string) {
    try {
      const res = await axios.get(ApiHelper.getOwnersNumber(companyId));
      ownerCounter.value = res.data.number;
    } catch (err) {
      AlertHelper.show(alert, parseError(err), "", "error");
    }
  }
  async function getWorkersNumber(companyId: string) {
    try {
      const res = await axios.get(ApiHelper.getWorkersNumber(companyId));
      workerCounter.value = res.data.number;
    } catch (err) {
      AlertHelper.show(alert, parseError(err), "", "error");
    }
  }
  async function getRoleInCompany(companyId: string) {
    try {
      const response = await axios.get(ApiHelper.getUserRole(companyId));
      return response.data?.role;
    } catch (err) {
      AlertHelper.show(alert, parseError(err), "", "error");
    }
  }
  const getCurrentCompany = computed(() => {
    return currentCompany.value;
  });
  const getUserCompanies = computed(() => {
    return userCompanies.value;
  });

  return {
    userCompanies,
    getRoleInCompany,
    getOwnersNumber,
    workerCounter,
    ownerCounter,
    getWorkersNumber,
    getCurrentCompany,
    getUserCompanies,
    currentCompany,
    documents,
    taskDocuments,
    page,
    onPage,
    total,
    isLoading,
    fetchUserCompanies,
    getCompanyDocuments,
    getCompanyDocumentsPublic,
    fetchOneCompany,
    editCompany,
    uploadCompanyPicture,
    getCompanyPicture,
    getCompanyFIle,
    deleteCompanyFile,
    addCompanyFile,
    editCompanyType,
    checkCompanyDocuments,
    hasKRSFile,
    checkNip,
  };
});
