<template>
  <form class="auth__form" @submit="onSubmit">
    <div class="control control--input">
      <label for="email" class="control__label">{{ $t("register.email") }}</label>
      <input type="text" id="email" :placeholder="$t('register.email_placeholder')" v-model="email" :class="{ error: errors.email }" />
      <div v-show="errors.email" class="control__error">
        <div class="control__error-inner">
          {{ errors.email }}
        </div>
      </div>
    </div>
    <div class="control control--input">
      <label for="name" class="control__label">{{ $t("register.name") }}</label>
      <input type="text" id="name" :placeholder="$t('register.name_placeholder')" v-model="name" :class="{ error: errors.name }" />
      <div v-show="errors.name" class="control__error">
        <div class="control__error-inner">
          {{ errors.name }}
        </div>
      </div>
    </div>
    <div class="control control--input">
      <label for="surname" class="control__label">{{ $t("register.surname") }}</label>
      <input type="text" id="surname" :placeholder="$t('register.surname_placeholder')" v-model="surname" :class="{ error: errors.surname }" />
      <div v-show="errors.surname" class="control__error">
        <div class="control__error-inner">
          {{ errors.surname }}
        </div>
      </div>
    </div>
    <div class="control control--input">
      <label for="phone" class="control__label">{{ $t("register.phone") }}</label>
      <input
        @keyup="phone = phone.replace(/\D/g, '')"
        type="text"
        id="phone"
        :placeholder="$t('register.phone_placeholder')"
        v-model="phone"
        :class="{ error: errors.phone }"
        autofocus
      />
      <div v-show="errors.phone" class="control__error">
        <div class="control__error-inner">
          {{ errors.phone }}
        </div>
      </div>
    </div>
    <primary-button
      style="width: fit-content; margin: 0 auto; margin-top: 30px"
      @click="onSubmit"
      :no-submit="true"
      :title="sendingForm ? $t('register.creating_account_in_progress') : $t('register.create_account')"
      :red="true"
      :filled="true"
    />
  </form>
</template>

<style scoped>
select {
  max-width: 294px;
  width: 100%;
  height: 32px;
  border-radius: 5px;
  border: 1px solid #d4d1d1;
}
</style>
<script>
import { computed, onMounted, ref, inject } from "vue";
import { useField, useForm } from "vee-validate";
import { useWorkersLogiplannerStore } from "@/stores/admin/workers/workersAdmin.store";
import * as yup from "yup";
import i18n from "@/i18n";
import { useAuthStore } from "@/stores/auth.store";
import { useI18n } from "vue-i18n";
import AlertHelper from "@/helpsers/alert.helper";
import PrimaryButton from "@/components/common/PrimaryButton.vue";
export default {
  components: { PrimaryButton },
  name: "AddWorker",
  emits: ["finished"],
  setup(props, { emit }) {
    const store = useWorkersLogiplannerStore();
    const registrationSuccess = ref(false);
    const backendError = ref("");
    let alert = inject("alert");
    const { t } = useI18n();
    const sendingForm = ref(false);
    const handleValidators = () => {
      return {
        validationSchema: yup.object().shape({
          email: yup.string().required(i18n.global.t("register.validation.required")).email(i18n.global.t("register.validation.email")),
          phone: yup
            .string()
            .required(i18n.global.t("register.validation.required"))
            .matches(
              /^(?=(.{1,20})$)((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/,
              i18n.global.t("register.validation.phone")
            ),
          name: yup
            .string()
            .trim()
            .required(i18n.global.t("register.validation.required"))
            .matches(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/, i18n.global.t("register.validation.string"))
            .max(50, i18n.global.t("register.validation.max", { number: 50 })),
          surname: yup
            .string()
            .trim()
            .required(i18n.global.t("register.validation.required"))
            .matches(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/, i18n.global.t("register.validation.string"))
            .max(50, i18n.global.t("register.validation.max", { number: 50 })),
        }),
      };
    };

    const { handleSubmit, errors, resetForm } = useForm(handleValidators());
    const { value: surname } = useField("surname");
    const { value: name } = useField("name");
    const { value: phone } = useField("phone");
    const { value: email } = useField("email");
    const onSubmit = handleSubmit(async () => {
      const obj = {
        name: name.value,
        surname: surname.value,
        phoneNumber: phone.value,
        email: email.value,
        alert,
      };
      await store.addWorker(obj);
      resetForm();
      emit("finished");
    });
    return {
      backendError,
      name,
      surname,
      errors,
      onSubmit,
      sendingForm,
      phone,
      registrationSuccess,
      email,
    };
  },
};
</script>
