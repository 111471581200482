import { defineStore } from "pinia";
import axios from "@/axios";
import { inject, ref } from "vue";
import ApiHelper from "@/helpsers/api.helper";
import authHelper from "@/helpsers/auth.helper";
import { parseError } from "@/js/error";
import AlertHelper from "@/helpsers/alert.helper";

export const useLeasingStore = defineStore("leasing", () => {
  const leasings = ref([] as Array<any>);
  let alert = inject("alert");

  const fetchLeasings = async (id: string) => {
    try {
      const response = await axios.get(ApiHelper.getCompanyLeasings(id) + "?limit=99", {
        headers: authHelper.authHeader(),
      });
      leasings.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  };

  return {
    leasings,
    fetchLeasings,
  };
});
