import i18n from "@/i18n";

import PageNotFound from "@/views/PageNotFound.vue";

const routes = [
  {
    path: "/not-found",
    name: "not-found",
    component: PageNotFound,
    meta: {
      title: i18n.global.t("routes.not_found"),
    },
  },
 
];

export default routes;
