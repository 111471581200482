<template>
  <form class="auth__form">
    <div class="auth__error-message" v-if="error">
      {{ errorMessage }}
    </div>
    <div class="control control--input">
      <label for="email" class="control__label">{{ $t("auth.enter_login_or_email_label") }}</label>
      <input
        type="text"
        id="email"
        :placeholder="$t('auth.enter_email_placeholder')"
        v-model="email"
        name="email"
        @input="validEmail"
        tabindex="1"
        autofocus
      />
      <div class="control__error" v-if="emailError">
        <div class="control__error-inner">
          {{ emailError }}
        </div>
      </div>
    </div>
    <div class="control control--input control--password">
      <button
        class="control__eye control__eye--show control_eye_large_input"
        data-control-id="password"
        type="button"
        @click.prevent="showPassword"
        tabindex="2"
      >
        <img src="../../assets/images/eye.svg" />
      </button>
      <button
        class="control__eye control__eye--hide hidden control_eye_large_input"
        data-control-id="password"
        type="button"
        @click.prevent="hidePassword"
        tabindex="2"
      >
        <img src="../../assets/images/eye-line.svg" />
      </button>
      <label for="password" class="control__label">{{ $t("auth.password_label") }}</label>
      <input
        type="password"
        id="password"
        v-model="password"
        :placeholder="$t('auth.enter_password_placeholder')"
        @input="validPassword"
        tabindex="1"
      />
      <div class="control__error" v-if="passwordError">
        <div class="control__error-inner">
          {{ passwordError }}
        </div>
      </div>
    </div>
    <!-- <div class="control control--check">
      <input type="checkbox" v-model="isPrivacyPolicyChecked" id="check1" />
      <div
        v-if="privacyError && !isPrivacyPolicyChecked"
        class="control__error"
      >
        <div class="control__error-inner">{{ privacyError }}</div>
      </div>
      <label for="check1" class="control__label"
        >{{ $t("auth.regulations_acceptance_first") }}
      </label>
    </div>
    <div class="control control--check">
      <input type="checkbox" v-model="isRegulationChecked" id="check2" />
      <div
        v-if="regulationError && !isRegulationChecked"
        class="control__error"
      >
        <div class="control__error-inner">{{ regulationError }}</div>
      </div>
      <label for="check2" class="control__label">{{
        $t("auth.regulations_acceptance_second")
      }}</label>
    </div> -->
    <div class="auth__remember">
      <!-- <div class="control control--check">
        <input type="checkbox" id="remember" v-model="rememberUser" />
        <label for="remember" class="control__label">{{
          $t("auth.remember_me_label")
        }}</label>
      </div> -->
      <router-link to="/forgot-password">{{ $t("auth.forgot_password_label") }}</router-link>
    </div>
    <div class="auth__buttons">
      <button
        type="submit"
        @click.prevent="logIn"
        :class="{ disabled: loginStore.isLoading }"
        class="button button--brown button--wide"
        tabindex="3"
        style="font-weight: 500; margin-top: 50px"
      >
        <template v-if="!loginStore.isLoading">
          {{ $t("auth.log_in_label") }}
        </template>
        <template v-if="loginStore.isLoading">
          {{ $t("auth.log_in_progress_label") }}
        </template>
      </button>
    </div>

    <p class="auth__forgotten">
      {{ $t("auth.dont_have_account") }}
      <router-link to="/register">{{ $t("auth.register_title_button") }}</router-link>
    </p>
  </form>
</template>

<script>
import GuestLayout from "@/layouts/GuestLayout";
import { useAuthStore } from "@/stores/auth.store";
import { ref, inject, onBeforeMount, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import AlertHelper from "@/helpsers/alert.helper";
import storeCache from "@/js/storeCache";
import { useRouter } from "vue-router";

export default {
  name: "LoginView",
  components: {
    GuestLayout,
  },
  setup() {
    const error = ref(false),
      loginStore = useAuthStore(),
      password = ref(""),
      passwordError = ref(""),
      email = ref(""),
      emailError = ref(""),
      rememberUser = ref(false),
      errorMessage = ref(""),
      isRegulationChecked = ref(false),
      isPrivacyPolicyChecked = ref(false),
      regulationError = ref(""),
      privacyError = ref("");

    let alert = inject("alert");
    const { t } = useI18n();
    const router = useRouter();

    onBeforeMount(() => {
      if (storeCache.get("token")) {
        router.push("/");
      }
    });

    onMounted(() => {
      loginStore.isLoading = false;
      if (storeCache.get("showAuthAlert")) {
        AlertHelper.show(alert, "Brak dostępu", "Nie posiadasz uprawnień do wykonania tej czynności", "error");
        storeCache.remove("showAuthAlert");
      }
      if (storeCache.get("showOtherAlert")) {
        AlertHelper.show(alert, "Brak dostępu", storeCache.get("showOtherAlert"), "error");
        storeCache.remove("showOtherAlert");
      }
    });

    function validEmail() {
      if (email.value != "") emailError.value = "";
      else emailError.value = t("auth.email_is_required");
    }

    function validPassword() {
      if (password.value != "") passwordError.value = "";
      else passwordError.value = t("auth.password_is_required");
    }

    function validCheckboxes() {
      // let isRegulation = false;
      // let isPrivacy = false;
      // if (isRegulationChecked.value) {
      //   isRegulation = true;
      // } else {
      //   regulationError.value = t("auth.regulationsAcceptance");
      // }
      // if (isPrivacyPolicyChecked.value) {
      //   isPrivacy = true;
      // } else {
      //   privacyError.value = t("auth.privacyAcceptance");
      // }
      // return isRegulation && isPrivacy;
      return true;
    }

    async function logIn() {
      emailError.value = "";
      passwordError.value = "";
      try {
        if (validCheckboxes() && email.value != "" && password.value != "") {
          await loginStore.login(email.value.trim(), password.value.trim());
          router.push("/dashboard");
        }
        validEmail();
        validPassword();
      } catch (e) {
        AlertHelper.show(alert, e, "", "error");
      }
    }

    function showPassword(ev) {
      ev.currentTarget.classList.add("hidden");
      document.querySelector("#" + ev.currentTarget.dataset.controlId).type = "text";
      document.querySelector(".control__eye--hide[data-control-id=" + ev.currentTarget.dataset.controlId + "]").classList.remove("hidden");
    }

    function hidePassword(ev) {
      ev.currentTarget.classList.add("hidden");
      document.querySelector("#" + ev.currentTarget.dataset.controlId).type = "password";
      document.querySelector(".control__eye--show[data-control-id=" + ev.currentTarget.dataset.controlId + "]").classList.remove("hidden");
    }

    function handleSdkInit({ FB, scope }) {}

    return {
      email,
      error,
      loginStore,
      password,
      logIn,
      showPassword,
      hidePassword,
      errorMessage,
      passwordError,
      emailError,
      validEmail,
      validPassword,
      rememberUser,
      handleSdkInit,
      isRegulationChecked,
      isPrivacyPolicyChecked,
      regulationError,
      privacyError,
    };
  },
};
</script>

<style scoped lang="scss">
button {
  cursor: pointer;
}
#remember {
  cursor: pointer;
}
.auth__remember {
  font-size: 12px;
  text-decoration: underline;
}
.auth__form {
  margin-top: 130px;
}
</style>
