import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";
import AlertHelper from "@/helpsers/alert.helper";
import { parseError } from "@/js/error";
import { UpdateTimeWindows } from "./calendar.store";
import { RampAPI } from "@/services/calendar/ramp.service";
import { inject, ref } from "vue";

export interface RampCalendarDayMonthly {
  availabilitiesNumber: number;
  createdAt: string;
  dateClose: string;
  dateOpen: string;
  id: string;
  loadingLimit: number;
  lp: number;
  modifiedAt: string;
  noMonitoryNotePossibility: boolean;
  takenAvailabilitiesNumber: number;
  unlimitedMonitoryNotes: boolean;
  unloadingLimit: number;
}
export interface RampCalendarDayDaily {
  availabilities: {
    createdAt: string;
    endHour: string;
    id: string;
    modifiedAt: string;
    noMonitoryNotePossibility: boolean;
    startHour: string;
    unlimitedMonitoryNotes: boolean;
  }[];
  createdAt: string;
  dateClose: string;
  dateOpen: string;
  id: string;
  loadingLimit: number;
  modifiedAt: string;
  unloadingLimit: number;
}

export interface AddRampsReq {
  alert: any;
  departmentId: string;
  ramps: { name: string }[];
}

export const useRampsStore = defineStore("ramps", () => {
  let alert = inject("alert");
  const ramps = ref([] as Array<RampAPI>);
  const rampsAvailable = ref([] as Array<RampAPI>);
  const rampsAvailableForDay = ref([] as Array<RampAPI>);
  const rampsMonthlyCalendar = ref([] as Array<RampCalendarDayMonthly>);
  const rampsDailyCalendar = ref([] as Array<RampCalendarDayDaily>);

  async function addRamps(body: AddRampsReq) {
    const { alert, ...obj } = body;
    try {
      await axios.post(ApiHelper.addRamps(), obj);
    } catch (e) {
      throw e;
    }
  }
  async function deleteRamp(rampId: string, departmentId: string, alert: any) {
    try {
      await axios.delete(ApiHelper.deleteRamp(rampId, departmentId));
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function updateRampDailyTimeWindows(body: UpdateTimeWindows) {
    const { alert, rampId, ...obj } = body;
    try {
      await axios.put(ApiHelper.updateRampDailyTimeWindows(rampId), obj);
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
      throw e;
    }
  }
  async function getRamps(departmentId: string) {
    try {
      const response = await axios.get(ApiHelper.getRamps(departmentId) + "?limit=99");
      ramps.value = response.data.data;
    } catch (e) {
      throw e;
    }
  }
  async function getMonthlyCalendarForRamp(rampId: string, month: number, year: number) {
    try {
      const response = await axios.get(ApiHelper.getCalendarMonthlyForRamp(rampId, year, month) + "?limit=31");
      rampsMonthlyCalendar.value = response.data.data;
      return response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getMonthlyCalendarForRampAndReturnOnly(rampId: string, month: number, year: number) {
    try {
      const response = await axios.get(ApiHelper.getCalendarMonthlyForRamp(rampId, year, month) + "?limit=31");
      return response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getDailyCalendarForRamp(rampId: string, day: string) {
    try {
      const response = await axios.get(ApiHelper.listDailyTimeWindowsForRamp(day, rampId));
      rampsDailyCalendar.value = response.data;
      return response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAvailableRampsForHourAndDay(departmentId: string, date: string, startHour: string, endHour: string) {
    try {
      const response = await axios.get(ApiHelper.getAvailableRampsForHourAndDay(departmentId, date, startHour, endHour) + "?limit=99");
      rampsAvailable.value = response.data.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  async function getAvailableRampsForDay(departmentId: string, date: string) {
    try {
      const response = await axios.get(ApiHelper.getAvailableRampsForDay(departmentId, date));
      return response.data;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }
  return {
    ramps,
    rampsAvailable,
    rampsMonthlyCalendar,
    rampsDailyCalendar,
    addRamps,
    deleteRamp,
    updateRampDailyTimeWindows,
    getRamps,
    getMonthlyCalendarForRamp,
    getMonthlyCalendarForRampAndReturnOnly,
    getDailyCalendarForRamp,
    getAvailableRampsForHourAndDay,
    getAvailableRampsForDay,
  };
});
