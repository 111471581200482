import i18n from "@/i18n";

export const types = [
  {
    label: i18n.global.t("authorizations.authorized"),
    name: "ACTIVATED",
    type: "success",
  },
  {
    label: i18n.global.t("profile.statuses.pending"),
    name: "WAITING_FOR_ACCEPTANCE",
    type: "warning",
  },
  {
    label: i18n.global.t("profile.statuses.multiple_blocked"),
    name: "BLOCKED",
    type: "danger",
  },
];

export const commonTypes = [
  {
    label: i18n.global.t("statuses.multiple_accepted"),
    name: "ACCEPTED",
    type: "success",
  },
  {
    label: i18n.global.t("profile.statuses.pending"),
    name: "PENDING",
    type: "warning",
  },
  {
    label: i18n.global.t("profile.statuses.multiple_rejected"),
    name: "REJECTED",
    type: "danger",
  },
];

export const authorizationTypes = [
  {
    label: i18n.global.t("statuses.all"),
    name: "ALL",
    type: "success",
  },
  {
    label: i18n.global.t("statuses.multiple_approved"),
    name: "ACCEPTED",
    type: "success",
  },
  {
    label: i18n.global.t("statuses.waiting"),
    name: "PENDING",
    type: "warning",
  },
  {
    label: `${i18n.global.t("statuses.rejected")}`,
    name: "REJECTED",
    type: "danger",
  },
];
export const transportTypes = [
  {
    label: i18n.global.t("profile.stock.selected_transports"),
    name: "ASSIGNED",
    type: "primary",
  },
  {
    label: i18n.global.t("profile.stock.canceled_transports"),
    name: "CANCELLED",
    type: "primary",
  },
  {
    label: i18n.global.t("profile.stock.unreceived_transports"),
    name: "LOST",
    type: "primary",
  },
];
export const auctionDriverTabtypes = [
  {
    label: i18n.global.t("auctions.all_auctions"),
    name: "ALL",
    type: "primary",
  },
  {
    label: i18n.global.t("auctions.current_auctions"),
    name: "PARTICIPATING",
    type: "primary",
  },
  {
    label: i18n.global.t("auctions.selected_auctions"),
    name: "WON",
    type: "primary",
  },
];
export const auctionsTypes = [
  {
    label: i18n.global.t("profile.stock.statuses.all"),
    name: "ALL",
    type: "primary",
  },
  {
    label: i18n.global.t("profile.stock.statuses.active"),
    name: "ACTIVE",
    type: "primary",
  },
  {
    label: i18n.global.t("profile.stock.statuses.waiting_for_reservation"),
    name: "WAITING_FOR_RESERVATION",
    type: "primary",
  },
  {
    label: i18n.global.t("profile.stock.statuses.waiting_for_payment"),
    name: "WAITING_FOR_PAYMENT",
    type: "primary",
  },
  {
    label: i18n.global.t("profile.stock.statuses.reservation_sent"),
    name: "RESERVATION_SENT",
    type: "primary",
  },
  {
    label: i18n.global.t("profile.stock.statuses.reserved"),
    name: "RESERVED",
    type: "primary",
  },
  // {
  //   label: i18n.global.t("profile.stock.statuses.finished"),
  //   name: "FINISHED",
  //   type: "primary",
  // },
];
export const leasingTypes = [
  {
    label: i18n.global.t("statuses.accepted_leasings"),
    name: "ACCEPTED",
    type: "success",
  },
  {
    label: i18n.global.t("statuses.waiting_leasings"),
    name: "PENDING",
    type: "warning",
  },
  {
    label: i18n.global.t("statuses.rejected_leasings"),
    name: "REJECTED",
    type: "danger",
  },
];
export const commonTypesNoWaiting = [
  {
    label: i18n.global.t("profile.statuses.multiple_active"),
    name: "ACTIVE",
    type: "success",
  },
  {
    label: i18n.global.t("profile.statuses.multiple_blocked"),
    name: "BLOCKED",
    type: "danger",
  },
  {
    label: i18n.global.t("statuses.all_multiple"),
    name: "ALL",
    type: "primary",
  },
];

export const driverDocs = [
  {
    label: i18n.global.t("document_types.krs_cdig"),
    name: "KRS_CDiG",
    type: "primary",
  },
  {
    label: i18n.global.t("document_types.license"),
    name: "TRANSPORT_LICENSE",
    type: "primary",
  },
  {
    label: i18n.global.t("document_types.insurance"),
    name: "CARRIER_INSURANCE",
    type: "primary",
  },
  {
    label: i18n.global.t("profile.stock.deliverInfo.other"),
    name: "FILE",
    type: "primary",
  },
];
export const warehouseDocs = [
  {
    label: i18n.global.t("document_types.krs_cdig"),
    name: "KRS_CDiG",
    type: "primary",
  },

  {
    label: i18n.global.t("profile.stock.deliverInfo.other"),
    name: "FILE",
    type: "primary",
  },
];

export const companyTypes = [
  {
    label: i18n.global.t("profile.role.driver"),
    name: "DRIVER",
    type: "driver",
  },
  {
    label: i18n.global.t("profile.role.warehouse"),
    name: "WAREHOUSE",
    type: "warehouse",
  },
  {
    label: i18n.global.t("profile.role.factory"),
    name: "FACTORY",
    type: "factory",
  },
  {
    label: i18n.global.t("profile.statuses.pending"),
    name: "WAITING_FOR_ACCEPTANCE",
    type: "warning",
  },
];
export const loadingTypes = [
  {
    label: i18n.global.t("profile.statuses.loading"),
    name: "LOADING",
  },
  {
    label: i18n.global.t("profile.statuses.reloading"),
    name: "UNLOADING",
  },
];

export const filteringAuctionTypes = [
  {
    label: i18n.global.t("statuses.all"),
    name: "ALL",
    type: "primary",
  },
  {
    label: i18n.global.t("profile.statuses.waiting_for_reservation"),
    name: "WAITING_FOR_RESERVATION",
    type: "danger",
  },
  {
    label: i18n.global.t("profile.statuses.reservation_sent"),
    name: "RESERVATION_SENT",
    type: "warning",
  },
  {
    label: i18n.global.t("profile.statuses.reserved"),
    name: "RESERVED",
    type: "success",
  },
];

export const companyTypesAdmin = [
  {
    label: i18n.global.t("tableData.driver"),
    name: "DRIVER",
    type: "driver",
  },
  {
    label: i18n.global.t("tableData.warehouse"),
    name: "WAREHOUSE",
    type: "warehouse",
  },
  {
    label: i18n.global.t("tableData.factory"),
    name: "FACTORY",
    type: "factory",
  },
];

export const availableCurrencies = [
  {
    name: "PLN",
    value: "PLN",
  },
  {
    name: "EUR",
    value: "EUR",
  },
  {
    name: "GBP",
    value: "GBP",
  },
  {
    name: "USD",
    value: "USD",
  },
];
export const availableCarTypes = [
  {
    name: i18n.global.t("car_types.solo_car"),
    value: "AUTA_SOLOWE",
  },
  {
    name: i18n.global.t("car_types.tractor"),
    value: "CIAGNIKI_SIODLOWE",
  },
  {
    name: i18n.global.t("car_types.furgon"),
    value: "FURGON",
  },
  {
    name: i18n.global.t("car_types.hakowiec"),
    value: "HAKOWIEC",
  },
  {
    name: i18n.global.t("car_types.autolaweta_laweta"),
    value: "AUTOLAWETA_LAWETA",
  },
  {
    name: i18n.global.t("car_types.wywrotka"),
    value: "WYWROTKA",
  },
  {
    name: i18n.global.t("car_types.walkingfloor"),
    value: "WALKINGFLOOR",
  },
];
export const availableCarParts = [
  {
    name: i18n.global.t("profile.role.bdf_system_przyczepa") + " (przyczepa)",
    value: "BDF_SYSTEM_PRZYCZEPA",
  },
  {
    name: i18n.global.t("profile.role.dluzyca"),
    value: "DLUZYCA_PRZYCZEPA",
  },
  {
    name: i18n.global.t("profile.role.niskopodwoziowy"),
    value: "NISKOPODWOZIOWY_PRZYCZEPA",
  },
  {
    name: i18n.global.t("profile.role.przyczepa"),
    value: "PRZYCZEPA",
  },

  {
    name: i18n.global.t("profile.role.plandeka_bde"),
    value: "PLANDEKA_BDE",
  },
  {
    name: i18n.global.t("profile.role.plandeka_coilmulde"),
    value: "PLANDEKA_COILMULDE",
  },
  {
    name: i18n.global.t("profile.role.plandeka_joloda"),
    value: "PLANDEKA_JOLODA",
  },
  {
    name: i18n.global.t("profile.role.plandeka_jumbo"),
    value: "PLANDEKA_JUMBO",
  },
  {
    name: i18n.global.t("profile.role.plandeka_mega"),
    value: "PLANDEKA_MEGA",
  },
  {
    name: i18n.global.t("profile.role.plandeka_standard"),
    value: "PLANDEKA_STANDARD",
  },
  {
    name: i18n.global.t("profile.role.tilt_curtain"),
    value: "PLANDEKA_FIRANKA",
  },
  {
    name: i18n.global.t("profile.role.platforma"),
    value: "PLATFORMA",
  },
  {
    name: i18n.global.t("profile.role.bdf_system_naczepa") + " (naczepa)",
    value: "BDF_SYSTEM_NACZEPA",
  },
  {
    name: i18n.global.t("profile.role.dluzyca"),
    value: "DLUZYCA_NACZEPA",
  },
  {
    name: i18n.global.t("profile.role.niskopodwoziowy"),
    value: "NISKOPODWOZIOWY_NACZEPA",
  },
  {
    name: i18n.global.t("profile.role.sztywna_zabudowa"),
    value: "SZTYWNA_ZABUDOWA",
  },
  {
    name: i18n.global.t("profile.role.sztywna_zabudowa_skrzynia"),
    value: "SZTYWNA_ZABUDOWA_SKRZYNIA",
  },
  {
    name: i18n.global.t("profile.role.chlodnia_hakowka"),
    value: "CHLODNIA_HAKOWKA",
  },
  {
    name: i18n.global.t("profile.role.izoterma"),
    value: "IZOTERMA",
  },
  {
    name: i18n.global.t("profile.role.chlodnia"),
    value: "CHLODNIA",
  },
  {
    name: i18n.global.t("profile.role.cysterna_inna"),
    value: "CYSTERNA_INNA",
  },
  {
    name: i18n.global.t("profile.role.cysterna_silos"),
    value: "CYSTERNA_SILOS",
  },
  {
    name: i18n.global.t("profile.role.cysterna_spożywcza"),
    value: "CYSTERNA_SPOZYWCZA",
  },
  {
    name: i18n.global.t("profile.role.cysterna_chemiczna"),
    value: "CYSTERNA_CHEMICZNA",
  },
  {
    name: i18n.global.t("profile.role.cysterna_gazowa"),
    value: "CYSTERNA_GAZOWA",
  },
  {
    name: i18n.global.t("profile.role.cysterna_paliwowa"),
    value: "CYSTERNA_PALIWOWA",
  },
  {
    name: i18n.global.t("profile.role.podkontenerowa_20_cysterna"),
    value: "PODKONTENEROWA_20_CYSTERNA",
  },
  {
    name: i18n.global.t("profile.role.podkontenerowa_40_cysterna"),
    value: "PODKONTENEROWA_40_CYSTERNA",
  },
  {
    name: i18n.global.t("profile.role.podkontenerowa_20_standard"),
    value: "PODKONTENEROWA_20_STANDARD",
  },
  {
    name: i18n.global.t("profile.role.podkontenerowa_40_standard"),
    value: "PODKONTENEROWA_40_STANDARD",
  },
  {
    name: i18n.global.t("profile.role.podkontenerowa_45_standard"),
    value: "PODKONTENEROWA_45_STANDARD",
  },
  {
    name: i18n.global.t("profile.role.podkontenerowa_swapbody"),
    value: "PODKONTENEROWA_SWAPBODY",
  },
];

export const adminCompanyDetailsTypes = [
  {
    label: i18n.global.t("generals.workers"),
    name: "WORKERS",
  },
  {
    label: i18n.global.t("generals.departments"),
    name: "DEPARTMENTS",
  },
];

export const adminCompanyDocumentTypes = [
  {
    label: i18n.global.t("document_types.krs_cdig"),
    name: "KRS_CDiG",
  },
  {
    label: i18n.global.t("document_types.license"),
    name: "TRANSPORT_LICENSE",
  },
  {
    label: i18n.global.t("document_types.insurance"),
    name: "CARRIER_INSURANCE",
  },
  {
    label: i18n.global.t("document_types.policy"),
    name: "CAR_POLICY",
  },
  {
    label: i18n.global.t("profile.stock.deliverInfo.other"),
    name: "FILE",
  },
];

export const paymentDeadlineOptions = [
  {
    name: i18n.global.t("generals.days_helper", [7]),
    value: "7_DAYS",
  },
  {
    name: i18n.global.t("generals.days_helper", [14]),
    value: "14_DAYS",
  },
  {
    name: i18n.global.t("generals.days_helper", [21]),
    value: "21_DAYS",
  },
  {
    name: i18n.global.t("generals.days_helper", [30]),
    value: "30_DAYS",
  },
  {
    name: i18n.global.t("generals.days_helper", [45]),
    value: "45_DAYS",
  },
  {
    name: i18n.global.t("generals.days_helper", [60]),
    value: "60_DAYS",
  },
  {
    name: i18n.global.t("generals.days_helper", [90]),
    value: "90_DAYS",
  },
];

export const vehicleTypes = [
  {
    name: i18n.global.t("car_types.solo_car"),
    value: "AUTA_SOLOWE",
  },
  {
    name: i18n.global.t("car_types.tractor"),
    value: "CIAGNIKI_SIODLOWE",
  },
  {
    name: i18n.global.t("car_types.furgon"),
    value: "FURGON",
  },
  {
    name: i18n.global.t("car_types.hakowiec"),
    value: "HAKOWIEC",
  },
  {
    name: i18n.global.t("car_types.autolaweta_laweta"),
    value: "AUTOLAWETA_LAWETA",
  },
  {
    name: i18n.global.t("car_types.wywrotka"),
    value: "WYWROTKA",
  },
  {
    name: i18n.global.t("car_types.walkingfloor"),
    value: "WALKINGFLOOR",
  },
];
export const trailerTypes = [
  {
    name: i18n.global.t("profile.role.bdf_system_przyczepa"),
    value: "BDF_SYSTEM",
  },
  {
    name: i18n.global.t("profile.role.dluzyca"),
    value: "DLUZYCA_PRZYCZEPA",
  },
  {
    name: i18n.global.t("profile.role.niskopodwoziowy"),
    value: "NISKOPODWOZIOWY_PRZYCZEPA",
  },
  {
    name: i18n.global.t("profile.role.przyczepa"),
    value: "PRZYCZEPA",
  },
];
export const semitrailerTypes = [
  {
    name: i18n.global.t("profile.role.plandeka_bde"),
    value: "PLANDEKA_BDE",
  },
  {
    name: i18n.global.t("profile.role.plandeka_coilmulde"),
    value: "PLANDEKA_COILMULDE",
  },
  {
    name: i18n.global.t("profile.role.plandeka_joloda"),
    value: "PLANDEKA_JOLODA",
  },
  {
    name: i18n.global.t("profile.role.plandeka_jumbo"),
    value: "PLANDEKA_JUMBO",
  },
  {
    name: i18n.global.t("profile.role.plandeka_mega"),
    value: "PLANDEKA_MEGA",
  },
  {
    name: i18n.global.t("profile.role.plandeka_standard"),
    value: "PLANDEKA_STANDARD",
  },
  {
    name: i18n.global.t("profile.role.tilt_curtain"),
    value: "PLANDEKA_FIRANKA",
  },
  {
    name: i18n.global.t("profile.role.platforma"),
    value: "PLATFORMA",
  },
  {
    name: i18n.global.t("profile.role.bdf_system_naczepa"),
    value: "BDF_SYSTEM",
  },
  {
    name: i18n.global.t("profile.role.dluzyca"),
    value: "DLUZYCA_NACZEPA",
  },
  {
    name: i18n.global.t("profile.role.niskopodwoziowy"),
    value: "NISKOPODWOZIOWY_NACZEPA",
  },
  {
    name: i18n.global.t("profile.role.sztywna_zabudowa"),
    value: "SZTYWNA_ZABUDOWA",
  },
  {
    name: i18n.global.t("profile.role.sztywna_zabudowa_skrzynia"),
    value: "SZTYWNA_ZABUDOWA_SKRZYNIA",
  },
  {
    name: i18n.global.t("profile.role.chlodnia_hakowka"),
    value: "CHLODNIA_HAKOWKA",
  },
  {
    name: i18n.global.t("profile.role.izoterma"),
    value: "IZOTERMA",
  },
  {
    name: i18n.global.t("profile.role.chlodnia"),
    value: "CHLODNIA",
  },
  {
    name: i18n.global.t("profile.role.cysterna_inna"),
    value: "CYSTERNA_INNA",
  },
  {
    name: i18n.global.t("profile.role.cysterna_silos"),
    value: "CYSTERNA_SILOS",
  },
  {
    name: i18n.global.t("profile.role.cysterna_spożywcza"),
    value: "CYSTERNA_SPOŻYWCZA",
  },
  {
    name: i18n.global.t("profile.role.cysterna_chemiczna"),
    value: "CYSTERNA_CHEMICZNA",
  },
  {
    name: i18n.global.t("profile.role.cysterna_gazowa"),
    value: "CYSTERNA_GAZOWA",
  },
  {
    name: i18n.global.t("profile.role.cysterna_paliwowa"),
    value: "CYSTERNA_PALIWOWA",
  },
  {
    name: i18n.global.t("profile.role.podkontenerowa_20_cysterna"),
    value: "PODKONTENEROWA_20_CYSTERNA",
  },
  {
    name: i18n.global.t("profile.role.podkontenerowa_40_cysterna"),
    value: "PODKONTENEROWA_40_CYSTERNA",
  },
  {
    name: i18n.global.t("profile.role.podkontenerowa_20_standard"),
    value: "PODKONTENEROWA_20_STANDARD",
  },
  {
    name: i18n.global.t("profile.role.podkontenerowa_40_standard"),
    value: "PODKONTENEROWA_40_STANDARD",
  },
  {
    name: i18n.global.t("profile.role.podkontenerowa_45_standard"),
    value: "PODKONTENEROWA_45_STANDARD",
  },
  {
    name: i18n.global.t("profile.role.podkontenerowa_swapbody"),
    value: "PODKONTENEROWA_SWAPBODY",
  },
];

export const semitrailerTypesString =
  "PLANDEKA_BDE, PLANDEKA_COILMULDE, PLANDEKA_JOLODA, PLANDEKA_JUMBO, PLANDEKA_MEGA, PLANDEKA_STANDARD, PLANDEKA_FIRANKA, PLATFORMA, BDF_SYSTEM_naczepa, DLUZYCA_NACZEPA, NISKOPODWOZIOWY_NACZEPA, SZTYWNA_ZABUDOWA, SZTYWNA_ZABUDOWA_SKRZYNIA, CHLODNIA_HAKOWKA, IZOTERMA, CHLODNIA, CYSTERNA_INNA, CYSTERNA_SILOS, CYSTERNA_SPOŻYWCZA, CYSTERNA_CHEMICZNA, CYSTERNA_GAZOWA, CYSTERNA_PALIWOWA, PODKONTENEROWA_20_CYSTERNA, PODKONTENEROWA_40_CYSTERNA, PODKONTENEROWA_20_STANDARD, PODKONTENEROWA_40_STANDARD, PODKONTENEROWA_45_STANDARD, PODKONTENEROWA_SWAPBODY";

export const trailerTypesString = "BDF_SYSTEM_PRZYCZEPA, DLUZYCA_PRZYCZEPA, NISKOPODWOZIOWY_PRZYCZEPA, PRZYCZEPA";

export const vehicleTypesString = "AUTA_SOLOWE, CIAGNIKI_SIODLOWE, FURGON, HAKOWIEC, CUSTOM";
