import { defineStore } from "pinia";
import axios from "@/axios";
import { Department } from "./department.store";
import { inject, ref } from "vue";
import { parseError } from "@/js/error";
import i18n from "@/i18n";
import AlertHelper from "@/helpsers/alert.helper";
import ApiHelper from "@/helpsers/api.helper";

export const useOutsideDepartmentStore = defineStore("outside-department", () => {
  let alert = inject("alert") as any;
  const outsideDepartments = ref<Department[]>([]);
  const onPage = ref<number>(5);
  const page = ref<number>(1);
  const total = ref<number>(0);

  async function fetchOutsideDepartments(companyId: string, query?: string) {
    let url = `?limit=${onPage.value}&page=${page.value}${query ? `&query=${query}` : ""}`;
    try {
      const response = await axios.get(ApiHelper.fetchOutsideDepartments(companyId) + url);
      outsideDepartments.value = response.data.data.map((department: Department) => {
        department.isOutside = true;
        return department;
      });
      total.value = response.data.meta.total;
      onPage.value = response.data.meta.per_page;
      page.value = response.data.meta.current_page;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  async function addOutsideDepartment(body: Department) {
    try {
      const response = await axios.post(ApiHelper.addOutsideDepartment(), body);
      AlertHelper.show(alert, i18n.global.t("generals.success"), i18n.global.t("profile.add_warehouse.added_warehouse"), "success");
      return response;
    } catch (e) {
      AlertHelper.show(alert, parseError(e), "", "error");
    }
  }

  return { outsideDepartments, onPage, page, total, addOutsideDepartment, fetchOutsideDepartments };
});
