<template>
  <template id="app" v-if="ready">
    <component :is="layout" v-bind="propsData" />
    <div v-if="showPopup" class="fixed-popup">
      <div class="fixed-popup--body">
        <div class="fixed-popup--title" :style="popupType === 'error' ? 'color:#FF3535;' : popupType === 'error' ? 'color:#2AB92A;' : null">
          Uwaga
        </div>
        <div class="fixed-popup--content">
          {{ popupText }}
        </div>
        <div class="" style="display: flex; gap: 0px; flex-direction: column; margin-top: 10px">
          <PrimaryButton
            v-if="popupButton"
            @click="popupButton.redirect === 'VERIFICATION' ? handleVerification() : router.push({ name: popupButton.redirect }, (showPopup = false))"
            style="width: fit-content; margin: 0px auto"
            red="true"
            :filled="true"
            :title="popupButton.label"
          />

          <PrimaryButton @click="showPopup = false" style="width: fit-content; margin: 20px auto" red="true" :title="$t('generals.close')" />
        </div>
      </div>

      <div @click="showPopup = false" class="fixed-popup--closer">
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </div>
    </div>
    <verification-payment-modal :show="showVerificationModal" />
  </template>
</template>

<script>
import { onMounted, ref, watch, watchEffect, inject } from "vue";
import { useRoute } from "vue-router";
import DefaultLayout from "@/layouts/default";
import { useChatStore } from "@/stores/chat.store.ts";
import storeCache from "./js/storeCache";
import UserService from "./services/user.service";
import PrimaryButton from "./components/common/PrimaryButton.vue";
import router from "./router";
import { usePaymentStore } from "./stores/payment.store";
import VerificationPaymentModal from '@/components/verification/VerificationPaymentModal'
export default {
  name: "App",
  setup(props) {
    const showPopup = ref(false);
    const popupText = ref("");
    const popupType = ref("");
    const popupButton = ref();
    const layout = ref("");
    const route = useRoute();
    let propsData = ref({});
    const ready = ref(false);
    const showVerificationModal = ref(false);
    const chatStore = useChatStore();
    const emitter = inject("emitter");
    let alert = inject("alert");
    const paymentStore = usePaymentStore();
    // @ts-ignore
    emitter.on("showPopup", (e) => {
      popupText.value = e.message;
      popupType.value = e.type;
      if (e.button) popupButton.value = e.button;
      showPopup.value = true;
    });

    Date.prototype.ddmmyyyy = function () {
      var mm = this.getMonth() + 1;
      var dd = this.getDate();
      return [(dd > 9 ? "" : "0") + dd, (mm > 9 ? "" : "0") + mm, this.getFullYear()].join(".");
    };
    const userService = new UserService();
    onMounted(() => {
      // A GUARD IF OLD STORECACHE USED
      //here maybe
      let user = storeCache.get("currentUser");
      if (user && !user.id) {
        userService.logout();
      }
      // A GUARD IF OLD STORECACHE USED
    });

    const handleVerification = () => {
      const id = storeCache.get("currentCompany")?.id;
      if (!id) return;
      showVerificationModal.value = true;
      showPopup.value = false;

      // paymentStore.startTransactionForVerification(id);
    };
    watch(route, (curr, old) => {
      if (curr.meta.layout !== undefined && curr.meta.layout.component !== undefined) {
        layout.value = curr.meta.layout.component;
        propsData.value = curr.meta.layout.props;
      } else if (curr.meta.layout !== undefined) {
        layout.value = curr.meta.layout;
      } else {
        layout.value = DefaultLayout; // this is default layout if route meta is not set
      }
      ready.value = true;
    });
    return {
      layout,
      propsData,
      ready,
      showPopup,
      popupText,
      popupType,
      popupButton,
      router,
      showVerificationModal,
      handleVerification,
    };
  },
  components: { PrimaryButton, VerificationPaymentModal },
};
</script>

<style lang="scss">
/* body:fullscreen,
body:-ms-fullscreen,
body:-webkit-full-screen,
body:-moz-full-screen {
  overflow: scroll !important;
  &.scroll-lock {
    overflow: hidden;
    height: 100vh;
  }
  &.bg-alt {
    background-color: #eef4f4 !important;
  }
} */
/* width */

::-webkit-scrollbar {
  width: 3.5px;
  height: 3.5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #112baf;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#app {
  scrollbar-color: #ebeaea #f1f1f1;
  scrollbar-width: thin !important;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: auto;
  background-color: #f5f5f5 !important;
  @media screen and (max-width: 1200px) {
    overflow-y: auto;
  }
}
.wrapper,
.large_box {
  min-height: calc(100vh - 175px);
  max-height: calc(100vh - 175px);
  // min-width: 1000px;
  // max-width: 1300px !important;
  max-width: unset !important;

  @media screen and (max-width: 2300px) {
    min-width: 100% !important;
    max-width: unset !important;
  }

  @media screen and (max-width: 850px) {
    max-height: none;
    overflow: hidden;
  }
}
.wrapper {
  max-height: unset;
  min-height: unset;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  @media screen and (max-width: 850px) {
    max-height: none;
    overflow: hidden;
  }
}
.wrapper-with-scroller {
  min-height: calc(100vh - 175px);
  max-height: calc(100vh - 175px);
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: 2300px) {
    min-width: 100% !important;
    max-width: unset !important;
  }

  @media screen and (max-width: 850px) {
    max-height: none;
    overflow: hidden;
  }
}

.alert-close-button {
  width: 30px !important;
  height: 30px !important;
}

.dp__pointer {
  margin: 0 !important;
  font-size: 14px !important;
  border-radius: 7px;
  border: none;
}
.vue__time-picker .controls .char {
  padding-right: 5px;
}
.success {
  color: #10b416;
}
.danger {
  color: #112baf;
}
.warning {
  color: #ffc107;
}
.missing-elements-text {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #a2a2a2;
  display: grid;
  place-items: center;
  margin-top: 30px;
  padding: 0 10px;
}
textarea {
  outline: none !important;
  &::placeholder {
    color: rgba(162, 162, 162, 0.7);
    opacity: 1;
    font-weight: 400;
    font-size: 12px;
  }
}
input[type="checkbox"] {
  accent-color: #112baf;
}
.vue-map-container,
.map,
.map-container {
  min-width: 100%;
  height: 100%;
  min-height: 300px;
}
.vc-header {
  display: none !important;
  height: 0px !important;
}

.dp__cell_disabled {
  font-size: 14px !important;
}
.basic-label {
  font-size: 12px;
  color: #a2a2a2;
}
.dp__instance_calendar {
  font-size: 12px !important;
}
.fixed-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 30vw;
  height: fit-content;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  z-index: 999999999999;
  @media screen and (max-width: 1200px) {
    width: 80vw;
  }
  &--closer {
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 40px;
    cursor: pointer;
  }
  &--body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  &--content {
    font-size: 20px;
    text-align: center;
  }
  &--title {
    font-size: 30px;
    font-weight: 600;
    margin: 20px 0;
    color: #112baf;
    text-align: center;
  }
}
</style>
