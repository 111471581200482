import { defineStore } from "pinia";
import axios from "@/axios";
import ApiHelper from "@/helpsers/api.helper";

export interface PricePackage {
  price: number;
  monitoryNotesNumber: number;
  amountSubscriptionDays: number;
  type?: string;
  description?: string;
}

export const useInitAdminStore = defineStore({
  id: "init",
  state: () => ({
    initDepartmentId: "" as string,
    initCompanyId: "" as string,
    notificationsArray: [] as Notification[],
    notificationsCounter: 0 as number,
    onPage: 7,
    page: 1,
    total: 0,
  }),

  actions: {
    async fetchInitDepartment() {
      try {
        const res = await axios.get(ApiHelper.getAdminDepartment());
        this.initDepartmentId = res.data.id ?? undefined;
        return res;
      } catch (e) {
        throw e;
      }
    },
    async fetchInitCompany() {
      try {
        const res = await axios.get(ApiHelper.getAdminCompanyId());
        this.initCompanyId = res.data.id ?? undefined;
        return res;
      } catch (e) {
        throw e;
      }
    },
    async fetchAdminNotifications(all: boolean) {
      let url = "";
      if (all) url += "?limit=99&order=DESC";
      else url += `?limit=${this.onPage}&page=${this.page}&order=DESC`;
      try {
        const res = await axios.get(ApiHelper.getAdminNotifications() + url);
        this.notificationsArray = res.data.data;
        return res;
      } catch (e) {
        throw e;
      }
    },
    async fetchAdminNotificationsCounter() {
      try {
        const res = await axios.get(ApiHelper.getAdminNotificationsCounter());
        this.notificationsCounter = res?.data?.unreadNotificationsNumber;
        return res;
      } catch (e) {
        throw e;
      }
    },
  },
});
