<template>
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_694_2087)">
      <path
        d="M5 0.9375C4.19652 0.9375 3.41107 1.17576 2.743 1.62215C2.07492 2.06855 1.55422 2.70302 1.24674 3.44535C0.93926 4.18767 0.858809 5.00451 1.01556 5.79255C1.17231 6.5806 1.55923 7.30447 2.12738 7.87262C2.69553 8.44077 3.4194 8.82769 4.20745 8.98444C4.9955 9.14119 5.81233 9.06074 6.55465 8.75326C7.29698 8.44578 7.93145 7.92508 8.37785 7.257C8.82424 6.58893 9.0625 5.80349 9.0625 5C9.06136 3.92291 8.63299 2.89025 7.87137 2.12863C7.10975 1.36701 6.07709 0.938637 5 0.9375ZM6.4711 6.02891C6.50013 6.05794 6.52316 6.09241 6.53887 6.13034C6.55459 6.16828 6.56268 6.20894 6.56268 6.25C6.56268 6.29106 6.55459 6.33172 6.53887 6.36966C6.52316 6.40759 6.50013 6.44206 6.4711 6.47109C6.44206 6.50013 6.40759 6.52316 6.36966 6.53887C6.33172 6.55459 6.29106 6.56267 6.25 6.56267C6.20894 6.56267 6.16828 6.55459 6.13035 6.53887C6.09241 6.52316 6.05794 6.50013 6.02891 6.47109L5 5.4418L3.9711 6.47109C3.94206 6.50013 3.90759 6.52316 3.86966 6.53887C3.83172 6.55459 3.79106 6.56267 3.75 6.56267C3.70894 6.56267 3.66828 6.55459 3.63035 6.53887C3.59241 6.52316 3.55794 6.50013 3.52891 6.47109C3.49987 6.44206 3.47684 6.40759 3.46113 6.36966C3.44542 6.33172 3.43733 6.29106 3.43733 6.25C3.43733 6.20894 3.44542 6.16828 3.46113 6.13034C3.47684 6.09241 3.49987 6.05794 3.52891 6.02891L4.55821 5L3.52891 3.97109C3.47027 3.91246 3.43733 3.83293 3.43733 3.75C3.43733 3.66707 3.47027 3.58754 3.52891 3.52891C3.58755 3.47027 3.66708 3.43733 3.75 3.43733C3.83293 3.43733 3.91246 3.47027 3.9711 3.52891L5 4.5582L6.02891 3.52891C6.05794 3.49987 6.09241 3.47684 6.13035 3.46113C6.16828 3.44541 6.20894 3.43733 6.25 3.43733C6.29106 3.43733 6.33172 3.44541 6.36966 3.46113C6.40759 3.47684 6.44206 3.49987 6.4711 3.52891C6.50013 3.55794 6.52316 3.59241 6.53887 3.63034C6.55459 3.66828 6.56268 3.70894 6.56268 3.75C6.56268 3.79106 6.55459 3.83172 6.53887 3.86966C6.52316 3.90759 6.50013 3.94206 6.4711 3.97109L5.4418 5L6.4711 6.02891Z"
        fill="#CF002D"
      />
    </g>
    <defs>
      <clipPath id="clip0_694_2087">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
