<template>
  <header class="header header--admin">
    <div class="header__image">
      <router-link :to="{ name: 'admin.homepage' }">
        <img class="navbar-logo" src="@/assets/images/logo.png" alt="" />
        <img class="navbar-logo-small" src="@/assets/images/icon-small.png" alt="" />
      </router-link>
    </div>
    <div @click="toggleFullScreen" class="fullscreen">
      <img style="width: 20px; height: 20px; margin-bottom: 7px; opacity: 0.6" src="@/assets/icons/make-fullscreen.svg" alt="" />
    </div>
    <div @click="router.push({ name: 'admin.settings' })" class="settings">
      <img style="width: 25px; height: 25px; margin-bottom: 9px" src="@/assets/icons/settings.png" alt="" />
    </div>
    <div style="color: black; font-weight: 400; font-size: 10px; position: absolute; right: 118px; top: 50px" class="">
      {{ user && user.name && user.surname ? user.name + " " + user.surname : "-" }}
    </div>
    <div style="color: black; font-weight: 400; font-size: 10px; position: absolute; right: 118px; top: 63px" class="">
      ID:{{ user && user.subId ? user.subId : "Brak ID" }}
    </div>

    <label for="upload">
      <span class="glyphicon glyphicon-folder-open" aria-hidden="true"></span>
      <input @change="newProfilePic" type="file" id="upload" style="display: none" />
      <div class="header__right">
        <div class="header__name">
          <img class="admin-profile-pic" id="profile_photo_placeholder" src="../assets/images/blank_person.jpg" />
        </div>
      </div>
    </label>
    <div @click="router.push({ name: 'logout' })" class="logout">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#1F222F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-log-out"
      >
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
        <polyline points="16 17 21 12 16 7"></polyline>
        <line x1="21" y1="12" x2="9" y2="12"></line>
      </svg>
    </div>
  </header>

  <div class="wrapper">
    <Menu :is-admin="true"></Menu>
    <div class="place-lefto">
      <div
        :class="{
          'container-with-background':
            router.currentRoute.value.name === 'admin.add.department' ||
            router.currentRoute.value.name === 'admin.company' ||
            router.currentRoute.value.name === 'admin.add.user' ||
            router.currentRoute.value.name === 'department-admin' ||
            router.currentRoute.value.name === 'admin.add.user.department' ||
            router.currentRoute.value.name === 'admin.add.vehicle',
        }"
        class="container"
      >
        <div @click="router.go(-1)" class="admin-return-btn">
          <button class="return-col arrow arrow-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#112BAF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-arrow-left"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
          </button>
          <span class="title-style">{{ router.currentRoute.value.meta.title }}</span>
        </div>
        <vue-basic-alert ref="alert" />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import { onBeforeMount, onMounted, provide, ref } from "vue";
import VueBasicAlert from "vue-basic-alert";
import storeCache from "@/js/storeCache";
import { useChatStore } from "@/stores/chat.store";
import router from "@/router";
import { resizeImage } from "@/js/resize.utils";
import AlertHelper from "@/helpsers/alert.helper";
import { useUserStore } from "@/stores/user.store";
import { useInitAdminStore } from "@/stores/admin/init/init.admin.store";

import UserService from "@/services/user.service";
import alertHelper from "@/helpsers/alert.helper";
import { storeToRefs } from "pinia";

export default {
  name: "AdminLayout",
  components: { Menu, VueBasicAlert },
  setup() {
    const users = useUserStore();
    const chatStore = useChatStore();
    const userService = new UserService();
    const initStore = useInitAdminStore();
    const { initDepartmentId, initCompanyId } = storeToRefs(initStore);
    const { userCompanies } = storeToRefs(users);
    const allLoaded = ref(false);
    const alert = ref();
    let user = ref(null);
    let token = storeCache.get("token");
    provide("alert", alert);
    let currentU = ref(storeCache.get("currentUser"));

    onBeforeMount(async () => {
      //INITIAL PAGE RELOAD
      if (storeCache.get("initial_reload")) {
        storeCache.remove("initial_reload");
        window.location.reload();
      }
      //INITIAL PAGE RELOAD
      await initStore.fetchInitDepartment();
      await initStore.fetchInitCompany();
      await initStore.fetchAdminNotificationsCounter();
      await initStore.fetchAdminNotifications(true);

      currentU.value = storeCache.get("currentUser");
      if (currentU.value && currentU.value.role !== "ADMIN") {
        await users.fetchUserCompanies();
      }
    });

    onMounted(async () => {
      if (storeCache.get("token")) {
        chatStore.connect(storeCache.get("token"), null);
      }
      let currentU = storeCache.get("currentUser");
      if (currentU) user.value = currentU;
      if (storeCache.get("token") && initDepartmentId.value) {
        await chatStore.getSocket(storeCache.get("token"), initDepartmentId.value, true);
      }
    });

    const newProfilePic = async (e) => {
      let photo = e.target.files[0];
      let size = photo.size;
      if (size <= 10_000_000) {
        let formData = new FormData();
        const config = {
          file: photo,
          maxSize: 500,
        };
        const resizedImage = await resizeImage(config);
        var file = new File([resizedImage], "name", { type: "image/jpeg" });

        formData.append("file", file);
        formData.append("visibleToAll", "true");
        formData.append("type", "USER_AVATAR");
        formData.append("userId", user.value.id);

        userService
          .setUserProfileImage(formData)
          .then(async (r) => {
            await users.fetchCurrentUser();
            let user = storeCache.get("currentUser");
            await userService
              .getUserProfileImage(user.avatar.id)
              .then((r) => {
                console.log(r);
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        alertHelper.show(alert, t("files.errors.size"), "", "error");
      }
    };
    const toggleFullScreen = () => {
      if (!document.fullscreenElement) {
        var element = document.querySelector("#app");
        element
          .requestFullscreen()
          .then(function () {
            // element has entered fullscreen mode successfully
          })
          .catch(function (error) {
            // element could not enter fullscreen mode
          });
      } else {
        document.exitFullscreen();
      }
    };
    return {
      alert,
      allLoaded,
      toggleFullScreen,
      router,
      newProfilePic,
      user,
    };
  },
};
</script>

<style scoped lang="scss">
.header {
  &__name {
    &-list {
      a {
        img {
          margin-right: 5px;
        }
      }
    }
  }
}
.nav-image {
  width: 270px;
  margin-left: -20px;
  background: #fff;
  padding: 2px 10px;
  border-radius: 10px;
}
.wrapper {
  margin-top: 0px !important;
  min-height: 100vh !important;
}
.container {
  margin: auto;
  margin-top: 5px;
  width: fit-content;
  // background: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  height: 85vh;
  overflow-y: auto;
  width: 84vw;
  position: relative;
  padding-top: 30px;
  @media screen and (max-width: 850px) {
    width: 100vw;
    min-height: 100vh;
    height: fit-content;
  }
  @media screen and (max-width: 1100px) {
    padding: 10px;
    padding-top: 30px;
  }
  @media screen and (max-width: 450px) {
    padding: 15px 0;
    padding-top: 30px;
  }
}
.container-with-background {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.08);
}
.admin-return-btn {
  display: flex;
  align-items: center;
  z-index: 9;
  position: absolute;
  top: 5px;
  left: 10px;
  button {
    background: transparent;
    color: #112baf;
  }
  .title-style {
    padding-left: 40px;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.03em;
    color: #112baf;
    padding-top: 4px;
  }
}
.settings {
  position: absolute;
  right: 150px;
  cursor: pointer;
}
.fullscreen {
  position: absolute;
  right: 185px;
  cursor: pointer;
}
.logout {
  position: absolute;
  right: 10px;
  top: 23px;
  cursor: pointer;
  @media screen and (max-width: 850px) {
    right: 210px;
  }
}
.header__right {
  cursor: pointer;
}
.admin-profile-pic {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 999px;
}
.return-col {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #112baf;
  width: 30px;
  height: 30px;
  border: none !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: grid;
  place-items: center;
}
.place-lefto {
  width: calc(100vw - 250px);
  position: absolute;
  right: 25px;
  @media screen and (max-width: 850px) {
    width: 100vw !important;
    height: 100%;
    right: 0;
  }
}
.navbar-logo {
  display: block;
  @media screen and (max-width: 850px) {
    display: none;
  }
}
.navbar-logo-small {
  display: none;
  @media screen and (max-width: 850px) {
    display: block;
  }
}
</style>
