<template>
  <div class="stockDeliverBox">
    <span>{{ title }}</span>
    <!-- <img v-if="avatar"  class="avatar-photo" id="profile_photo_blank" :src="avatar" />
    <img v-else class="avatar-photo" src="@/assets/images/blank_person.jpg" alt="" /> -->
    <div class="stockDeliverInnerBox">
      <div class="stockDeliverFrame">
        <span>
          <p>
            {{ $t("profile.stock.idTransporeon") }}
          </p>

          <span>{{ data.subId }}</span>
        </span>

        <span>
          <p>{{ $t("profile.stock.car") }}</p>
          <span>{{ data.carType ? $t(`car_types.${data.carType.toLowerCase()}`) : "Dowolny" }}</span>
        </span>
        <span>
          <p>{{ $t("profile.stock.part") }}</p>
          <span>{{ data.carPart ? $t(`car_types.${data.carPart.toLowerCase()}`) : "Dowolny" }}</span>
        </span>
        <span>
          <p>
            {{ $t("profile.stock.lastModifiedDate") }}
          </p>

          <span>{{ data.modifiedAt ? convertLongStringToDateAndHour(data.modifiedAt) : "-" }}</span>
        </span>
        <span>
          <p>{{ $t("profile.stock.deadline") }}</p>
          <span>{{ data.deadline ? convertLongStringToDateAndHour(data.deadline) : "-" }}</span>
        </span>
      </div>
      <div class="stockDeliverFrame">
        <span>
          <p>
            {{ $t("profile.stock.shipper") }}
          </p>

          <span>{{ data.companyLoader ? data.companyLoader.companyName : "-" }}</span>
        </span>

        <span>
          <p>{{ $t("profile.stock.disposal") }}</p>
          <span> {{ data.departmentLoader ? data.departmentLoader.name : "-" }}</span>
        </span>
        <span>
          <p>
            {{ $t("profile.stock.placeOfDisposal") }}
          </p>

          <span> {{ data.departmentLoader ? data.departmentLoader.address + " " + data.departmentLoader.city : "-" }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { convertLongStringToDateAndHour } from "@/js/date.utils";
import { useCompanyStore } from "@/stores/company.store";
import router from "@/router";
import { ref, onMounted } from "vue";
export default {
  name: "OrderInfo",
  props: {
    title: String,
    data: Object,
    avatar: String,
  },
  components: {},
  setup(props) {
    return { convertLongStringToDateAndHour, router };
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/stock/stockComponents.scss";

.stockDeliverBox {
  background: #fff;
  border-radius: 20px;
  position: relative;
}

.stockDeliverInnerBox {
  @media screen and (max-width: 500px) {
    margin: 0;
    padding: 0;
  }
}
.place-info {
  display: flex;
  padding: 10px 3px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  text-align: left !important;
  .title {
    font-size: 16px;
    font-weight: 600;
    color: rgb(158, 155, 155);
    width: 100px;
  }
  .data {
    font-size: 16px;
    font-weight: 400;
    margin-left: 20px;
  }
}
.avatar-photo {
  width: 110px !important;
  justify-self: flex-start;
  margin-right: auto;
}
</style>
