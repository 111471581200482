<template>
  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.2957 5.99968C10.5609 5.99968 10.8153 5.89433 11.0028 5.70679C11.1903 5.51925 11.2957 5.2649 11.2957 4.99968C11.2957 4.73447 11.1903 4.48011 11.0028 4.29258C10.8153 4.10504 10.5609 3.99968 10.2957 3.99968H7.79568V2.20668C7.79568 0.869683 6.17968 0.199683 5.23568 1.14668L0.43968 5.93768C0.30029 6.07698 0.189715 6.24238 0.114273 6.42444C0.0388305 6.60649 0 6.80162 0 6.99868C0 7.19575 0.0388305 7.39088 0.114273 7.57293C0.189715 7.75498 0.30029 7.92038 0.43968 8.05968L5.23368 12.8527C6.17868 13.7977 7.79368 13.1277 7.79368 11.7917V9.99968H14.7957C15.0609 9.99968 15.3153 9.89433 15.5028 9.70679C15.6903 9.51925 15.7957 9.2649 15.7957 8.99968V7.41268L19.3817 10.9997L15.7957 14.5857V12.9997C15.7957 12.7345 15.6903 12.4801 15.5028 12.2926C15.3153 12.105 15.0609 11.9997 14.7957 11.9997H11.2957C11.0305 11.9997 10.7761 12.105 10.5886 12.2926C10.401 12.4801 10.2957 12.7345 10.2957 12.9997C10.2957 13.2649 10.401 13.5193 10.5886 13.7068C10.7761 13.8943 11.0305 13.9997 11.2957 13.9997H13.7957V15.7927C13.7957 17.1287 15.4107 17.7977 16.3557 16.8527L21.1497 12.0597C21.4309 11.7784 21.5889 11.3969 21.5889 10.9992C21.5889 10.6014 21.4309 10.22 21.1497 9.93868L16.3557 5.14568C15.4107 4.19968 13.7957 4.86968 13.7957 6.20568V7.99968H6.79568C6.53046 7.99968 6.27611 8.10504 6.08857 8.29258C5.90104 8.48011 5.79568 8.73447 5.79568 8.99968V10.5857L2.20868 6.99968L5.79568 3.41368V4.99968C5.79568 5.2649 5.90104 5.51925 6.08857 5.70679C6.27611 5.89433 6.53046 5.99968 6.79568 5.99968H10.2957Z"
      fill="white"
    />
  </svg>
</template>
