import { Cargo } from "@/stores/stock.store";
import { Department } from "@/stores/department.store";

export interface TrackPointElementObj {
  id: string;
  cargos: Cargo[] | [];
  date: string;
  dateDetailed: string;
  warehouse: Department;
  chosenRamp?: { name: string; value: string };
  ramp?: { name: string; id: string };
  type?: string;
  allowedDates?: string[];
}
class AddingAuctionService {
  handleChangeOrder(direction: "up" | "down", index: number, addedIntermediatePoints: Array<any>) {
    //TODO error
    let arrayCopy = [...addedIntermediatePoints];
    const elementChanging = addedIntermediatePoints[index];
    const elementToSwitchWith = direction === "up" ? addedIntermediatePoints[index - 1] : addedIntermediatePoints[index + 1];
    if (direction === "up") {
      arrayCopy[index - 1] = elementChanging;
      arrayCopy[index] = elementToSwitchWith;
    }
    if (direction === "down") {
      arrayCopy[index + 1] = elementChanging;
      arrayCopy[index] = elementToSwitchWith;
    }
    return arrayCopy;
  }
  tableHasCargo(cargoTable: Cargo[], cargo: Cargo) {
    const found = cargoTable.find((cargoFound) => cargoFound?.type === cargo?.type && cargoFound.documentNumber === cargo.documentNumber);
    return Boolean(found);
  }
  hasAtLeastOneCustomWarehouse(trackPointsTable: { warehouse: { id: string; filledAddress: boolean } }[]) {
    const found = trackPointsTable.find((point) => point && point?.warehouse?.id === "NEW_DEPARTMENT" && !point?.warehouse?.filledAddress);
    if (found) return true;
    return false;
  }
  returnDuplicatedWarehouses(trackPointsTable: { warehouse: { id: string } }[]) {
    const idCountMap: { [id: string]: number } = {};
    const duplicateIds: string[] = [];

    for (const item of trackPointsTable) {
      if (!item) return;
      const id = item.warehouse?.id;
      if (!id) return;
      if (idCountMap[id]) {
        if (idCountMap[id] === 1 && id !== "NEW_DEPARTMENT") {
          duplicateIds.push(id);
        }
        idCountMap[id]++;
      } else {
        idCountMap[id] = 1;
      }
    }
    return duplicateIds;
  }
  returnDuplicatedCargos(trackPointsTable: { cargos: Cargo[]; type: string }[]) {
    const cargoMap: Record<string, number> = {}; // A map to store cargo types and their counts
    const duplicateCargos: string[] = [];
    // Iterate through the trackPointElements
    for (const trackPoint of trackPointsTable) {
      if (trackPoint && trackPoint?.cargos && trackPoint?.type === "LOADING") {
        for (const cargo of trackPoint.cargos) {
          if (cargo?.type) {
            // If cargo type exists, check for duplicates
            const cargoType = cargo?.type;
            if (cargoMap[cargoType]) {
              if (cargoMap[cargoType] === 1) {
                // This cargo type is already added to duplicates
                duplicateCargos.push(cargoType);
              }
              // Increment the count for this cargo type
              cargoMap[cargoType]++;
            } else {
              // First occurrence of this cargo type
              cargoMap[cargoType] = 1;
            }
          }
        }
      }
    }
    return duplicateCargos;
  }
  checkIfDatesAreChronological(trackPointsTable: { date: string }[]) {
    for (let i = 1; i < trackPointsTable.length; i++) {
      const currentDate = new Date(trackPointsTable[i]?.date as string);
      const previousDate = new Date(trackPointsTable[i - 1]?.date as string);

      if (currentDate < previousDate) {
        return false;
      }
    }
    return true;
  }
  checkIfCargosFilled(trackPointsTable: TrackPointElementObj[]) {
    for (let i = 0; i < trackPointsTable.length; i++) {
      const found = trackPointsTable[i]?.cargos.find(
        (cargo: Cargo) =>
          //@ts-ignore
          cargo.partNumberOfPallets === "" ||
          //@ts-ignore
          cargo.partNumberOfPallets === "0" ||
          //@ts-ignore
          cargo.partNumberOfPieces === "" ||
          //@ts-ignore
          cargo.partNumberOfPieces === "0" ||
          cargo.partWeight === "" ||
          cargo.partWeight === "0"
      );
      if (found) return false;
    }
    return true;
  }
  checkIfRampsFilled(trackPointsTable: TrackPointElementObj[]) {
    for (let i = 0; i < trackPointsTable.length; i++) {
      const found = trackPointsTable.find(
        (trackPoint) => !trackPoint.chosenRamp && !trackPoint.warehouse.isOutside && trackPoint.warehouse.id !== "NEW_DEPARTMENT"
      );
      if (found) return false;
    }
    return true;
  }
  parseInitialCargosForEdit(type: String, cargos: Cargo[], cargosInitial: Cargo[]) {
    return cargos.map((cargo) => {
      if (type === "LOADING") {
        const found = cargosInitial.find((cargoSource) => cargoSource?.type === cargo?.type);
        if (found) {
          //@ts-ignore
          cargo.id = found.index as number;
          return cargo;
        }
      }
      if (type === "UNLOADING") {
        let index;
        cargosInitial.forEach((sourceCargo, sourceI: number) => {
          if (sourceCargo?.type === cargo?.type) index = sourceI + 1;
        });
        if (!index) return cargo;
        cargo.id = index;
        return cargo;
      }
    });
  }
  parseCargoOperationObject(
    type: "LOADING" | "UNLOADING",
    point: {
      type: string;
      date: string;
      dateDetailed?: string;
      chosenRamp?: { value: string; name: string };
      warehouse: { id: string; saveNewAddress?: boolean; isOutside?: boolean };
      cargos: Cargo[];
    }
  ) {
    return {
      type: type,
      rampId: point.chosenRamp && point.warehouse.id !== "NEW_DEPARTMENT" && !point.warehouse.isOutside ? point.chosenRamp.value : undefined,
      date: point?.date ?? point.dateDetailed ?? undefined,
      dateFrom: point && (point.warehouse.isOutside || point.warehouse.id === "NEW_DEPARTMENT") ? point.dateDetailed : undefined,
      newAddress: point && point.warehouse.id === "NEW_DEPARTMENT" ? true : false,
      saveNewAddress: point && point.warehouse.id === "NEW_DEPARTMENT" ? point.warehouse.saveNewAddress : undefined,
      warehouseId: point && point.warehouse.id === "NEW_DEPARTMENT" ? undefined : point.warehouse.id,
      warehouse: point && point.warehouse.id === "NEW_DEPARTMENT" ? point.warehouse : undefined,
      cargosLoading:
        type === "LOADING"
          ? point.cargos
              .filter((cargo: Cargo) => !cargo.loadingType || cargo.loadingType === "LOADING")
              .map((cargo: Cargo, i: number) => {
                return {
                  id: cargo.id,
                  description: cargo.type,
                  remarks: cargo.remarks === "" ? undefined : cargo.remarks,
                  numberOfPallets: cargo.numberOfPallets,
                  numberOfItems: cargo.numberOfPieces,
                  documentNumber: cargo.documentNumber,
                  weight: parseFloat(cargo.weight as string),
                  volume: parseInt(cargo.capacity as string),
                  length: parseInt(cargo.length as string),
                };
              })
          : undefined,
      cargosUnloading:
        type === "UNLOADING"
          ? point.cargos
              .filter((cargo: Cargo) => !cargo.loadingType || cargo.loadingType === "UNLOADING")
              .map((cargo: Cargo, i: number) => {
                return {
                  id: i + 1,
                  cargoLoadingId: cargo.id,
                  numberOfPallets: cargo.partNumberOfPallets ? parseInt(cargo.partNumberOfPallets.toString()) : undefined,
                  numberOfItems: cargo.partNumberOfPieces ? parseInt(cargo.partNumberOfPieces.toString()) : undefined,
                  documentNumber: cargo.documentNumber,
                  weight: cargo.partWeight ? parseFloat(cargo.partWeight.toString()) : undefined,
                };
              })
          : undefined,
    };
  }
}

export default AddingAuctionService;
