import axios from "axios";
import { defineStore } from "pinia";


export interface Currency{
    code?:string;
    currency?:string;
    mid?:any;
    midd?:any;
}



export const useCurrencyStore = defineStore({
  id: "currency.store",
  state: () => ({
    exchangeRates: [] as Array<Currency>,
  }),
  actions: {
    async getExchangeRates(date:string | Date, currencyData:string) {
      if (true) {
        try {
          await axios.get(`https://api.nbp.pl/api/exchangerates/tables/a/${date}?format=json`).then((response) => {
            this.exchangeRates = response.data[0].rates.filter((currency:Currency) => {
              if (currency.code === "USD" || currency.code === "EUR" || currency.code === "GBP" || currency.code === "CHF") return currency;
            });

            if (currencyData !== "PLN") {
              let obj:Currency = {code:'',mid:0};
              let divider = 0;
              this.exchangeRates.forEach((c:Currency) => {
                if (c.code === currencyData) {
                  divider = c.mid;
                  obj = {
                    code: "PLN",
                    midd: divider,
                    currency: "Złótówka Polska",
                  };
                }
              });
              this.exchangeRates.push(obj);
              this.exchangeRates = this.exchangeRates
                .map((c:Currency) => {
                  c.mid = divider / c.mid;
                  return c;
                })
                .filter((c) => {
                  if (c.code !== currencyData) return c;
                })
                .reverse();
            }
          });
        } catch (e) {
          throw e;
        }
      }
    },
  },
});
