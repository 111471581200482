<template>
  <div v-if="users !== null && !loading">
    <div v-if="users.length > 0" class="user-list-content">
      <responsive-table
        @newRecordsNumber="newRecordsNumber"
        :onPage="onPage"
        @selected="newUserPicked"
        @next="changePage('next')"
        @prev="changePage('prev')"
        :currentPage="page"
        :tableType="''"
        :modalAction="true"
        :data="users"
        :isAdmin="true"
        :block-mock-row="true"
        :filterColumns="true"
        @newTableSorting="addNewSorting"
        :total-pages="Math.ceil(total / onPage)"
      >
        <template #modal-content>
          <div v-if="!isConfirmingDeleting" class="modal-content">
            <h1 id="my-dialog-title" class="dialog__title">
              {{ $t("tableData.modalConfirmationWorkers") }}
            </h1>
            <div class="buttons">
              <button v-if="pickedUser.status !== 'ACTIVATED'" type="button" class="btn btn-success" @click="changeStatus('ACTIVATED')">
                {{ $t("generals.unBlock") }}
              </button>
              <button v-if="pickedUser.status !== 'BLOCKED'" @click="changeStatus('BLOCKED')" type="button" class="btn btn-warning">
                {{ $t("generals.block") }}
              </button>
              <button v-if="!isMe(pickedUser)" @click="isConfirmingDeleting = true" type="button" class="btn btn-danger">
                {{ $t("generals.delete") }}
              </button>
            </div>
          </div>
          <div v-else class="modal-content">
            <div style="text-align:center; width:fit-content: margin:0 auto; margin-bottom:100px;" class="title-with-underline">
              {{ $t("removings.remove_admin_question") }}
            </div>
            <primary-button
              @click="changeStatus('DELETED')"
              :red="true"
              :filled="true"
              style="width: fit-content; margin: 0 auto; margin-top: 20px"
              :title="$t('generals.yes')"
            />
          </div>
        </template>
      </responsive-table>
      <primary-button
        @click="confirmationDialog.show()"
        :red="true"
        :filled="true"
        style="width: fit-content; margin: 0 auto; margin-top: 20px"
        :title="$t('admin.general.add')"
      />
    </div>
  </div>
  <div v-if="loading" class="wrapper">
    <loader-fitting-into-container></loader-fitting-into-container>
  </div>
  <div class="dialog" id="confirmation-dialog" aria-hidden="true">
    <div class="dialog-overlay" tabindex="-1" data-a11y-dialog-hide></div>
    <div role="dialog" class="dialog-content" aria-labelledby="my-dialog-title" aria-describedby="my-dialog-description">
      <button data-a11y-dialog-hide class="dialog-close" :aria-label="$t('admin.profile.settings.close_dialog')">
        <img src="@/assets/icons/dialog-close.svg" :alt="$t('admin.profile.settings.close_dialog')" />
      </button>
      <div class="modal-content">
        <h1 id="my-dialog-title" class="dialog__title">
          {{ $t("tableData.modalAddWorker") }}
        </h1>
        <addWorker @finished="addedNewAdmin" />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, inject, onBeforeMount } from "vue";
import UserLayout from "@/layouts/UserLayout";
import { useWorkersLogiplannerStore } from "@/stores/admin/workers/workersAdmin.store";
import { useUsersLogiplannerStore } from "@/stores/admin/users/logiplanner.store";
import { useHelpersStore } from "@/stores/helpers.store";
import TypeSwitcher from "@/components/common/TypeSwitcher.vue";
import storeCache from "@/js/storeCache";
import Loader from "@/components/common/Loader";
import LoaderFittingIntoContainer from "@/components/common/LoaderFittingIntoContainer";
import AddWorker from "./AddWorker";
import { storeToRefs } from "pinia";
import { prevPage, nextPage, setCurrentQueryParamsString } from "@/js/pagination.utils";
import ResponsiveTable from "@/components/common/ResponsiveTable.vue";
import router from "@/router";
import PrimaryButton from "@/components/common/PrimaryButton.vue";
export default {
  name: "ListUsersView",
  components: {
    UserLayout,
    AddWorker,
    TypeSwitcher,
    Loader,
    ResponsiveTable,
    LoaderFittingIntoContainer,
    PrimaryButton,
  },

  setup() {
    const store = useWorkersLogiplannerStore();
    const userStore = useUsersLogiplannerStore();
    const helpersStore = useHelpersStore();
    const currentUserType = ref("ACCEPTED");
    let loading = ref(true);
    let pickedUser = ref("");
    let sorting = ref("");
    let isConfirmingDeleting = ref(false);
    const confirmationDialog = ref("");
    let alert = inject("alert");
    let { users, page, onPage, total } = storeToRefs(store);
    let queryParams = router.currentRoute.value.query;

    if (queryParams) {
      for (let paramName in queryParams) {
        if (paramName === "page" && page.value !== queryParams[paramName]) page.value = queryParams[paramName];
        if (paramName === "onPage" && onPage.value !== queryParams[paramName]) onPage.value = queryParams[paramName];
      }
    }
    onBeforeMount(() => {
      if (storeCache.get("recordsNumber")) {
        newRecordsNumber(storeCache.get("recordsNumber"));
      }
    });
    onMounted(async () => {
      confirmationDialog.value = new A11yDialog(document.querySelector("#confirmation-dialog"));
      await fetchWorkers();
    });
    const fetchWorkers = async () => {
      let params = setCurrentQueryParamsString(page.value, onPage.value);
      if (params) history.pushState({}, null, `${router?.currentRoute?.value?.path}?${params}`);
      await store.fetchAdminWorkers(page.value, onPage.value, sorting.value);
      loading.value = false;
    };
    const pagesNumber = computed(() => {
      if (total.value && onPage.value) return Math.ceil(total.value / onPage.value);
      else return 1;
    });
    const changePage = async (direction) => {
      if (direction === "next") {
        page.value = nextPage(page.value, pagesNumber.value);
      } else if (direction === "prev") {
        page.value = prevPage(page.value);
      }
      await fetchWorkers();
    };
    const usersToList = computed(() => {
      let tmp = [];
      if (users.value !== undefined) {
        tmp = users.value.filter((user) => {
          if (user.status === currentUserType.value) {
            delete user.id;
            return user;
          }
        });
      }
      return tmp;
    });
    const changedType = async (type) => {
      currentUserType.value = type;
      await fetchWorkers();
    };
    const changeStatus = async (e) => {
      const obj = {
        alert: alert,
        userId: pickedUser.value.id,
        userStatus: e,
      };
      await userStore.changeUserStatus(obj);
      await fetchWorkers();
      helpersStore.hideModal();
    };

    const addedNewAdmin = async () => {
      confirmationDialog.value.hide();
      await fetchWorkers();
    };

    const newUserPicked = (e) => {
      pickedUser.value = e;
      isConfirmingDeleting.value = false;
    };

    const newRecordsNumber = async (records) => {
      if (records && records !== "" && records > 0) {
        onPage.value = records;
        page.value = 1;
        storeCache.set("recordsNumber", records);
        await fetchWorkers();
      }
    };
    const addNewSorting = async (sort) => {
      sorting.value = sort;
      await fetchWorkers();
    };

    const isMe = (user) => {
      let current = storeCache.get("currentUser");
      return current.id === user.id;
    };
    return {
      currentUserType,
      isMe,
      addNewSorting,
      changedType,
      users,
      changePage,
      usersToList,
      loading,
      confirmationDialog,
      addedNewAdmin,
      page,
      changeStatus,
      pickedUser,
      isConfirmingDeleting,
      newUserPicked,
      total,
      onPage,
      newRecordsNumber,
      sorting,
    };
  },
};
</script>

<style scoped lang="scss">
.grid-wrapper {
  width: 60vw;
  height: 700px;
  display: grid;
  place-items: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  .buttons {
    display: grid;
    place-items: center;
    margin-top: 20px;
    .btn {
      font-size: 20px;
      width: 50%;
      margin: 10px 0;
      color: #fff;
    }
  }
}
</style>
