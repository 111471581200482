export default {
  widgets: {
    auctions: "Licytacje",
    departmentList: "Moje oddziały",
    warehouseList: "Moje magazyny",
    documentList: "Moje dokumenty",
    leasingList: "Leasingi",
    driverList: "Kierowcy",
    workerList: "Pracownicy",
    groupList: "Moje grupy",
    monitoryArchive: "Archiwum awizacji",
    companyData: "Firma",
    outsideWarehouse: "Magazyny zewnętrzne",
    auctionParticipants: "Członkowie licytacji",
    auctionArchive: "Archiwum licytacji",
    walletTimeWindows: "Okna czasowe",
    walletSub: "Abonament",
    invoicesList: "Faktury",
    factoring: "Faktoring",
    transports: "Transporty",
    surcharges: "Dopłaty",
  },
};
